import portalPicker from './utils/portalPicker'
const environment = process.env.NODE_ENV

let apiUrl

if (process.env.REACT_APP_API_URL) {
    apiUrl = process.env.REACT_APP_API_URL
} else {
    apiUrl = environment === 'production' ? 'https://api.ageras.com' : 'https://api.staging.ageras.com'
}

export default {
    environment,
    debug: environment === 'development',
    serviceUrl: apiUrl + '/v2',
    agerasServices: apiUrl + '/services',
    userflowToken: process.env.REACT_APP_PARTNER_PORTAL_USER_FLOW_TOKEN,
    hideMobileNumber: geoCode => [ 'de' ].includes(geoCode),
    hideGeneralTerms: geoCode => ![ 'de' ].includes(geoCode),
    segmentKey: () => {
        const portal = portalPicker()
        let segmentKey = ''

        if (environment === 'production') {
            if (portal === 'partners') {
                // Segment's 'JS new client portal production' key
                segmentKey = 'EGsrF9nwgV1DXVNg4Ek9xSClx8tzNm3R'
            }

            if (portal === 'clients') {
                segmentKey = 'sl76GvhYmA6bm9bAyyV7pnO8yDDs2x6T'
            }
        }

        if (environment === 'development') {
            if (portal === 'partners') {
                // Segment's 'JS new client portal staging' key
                segmentKey = 'U64lkVRDJ6g47Uo1jXbULfjvUf8hC0WM'
            }

            if (portal === 'clients') {
                segmentKey = 'sac5he91cgqFAfJP4qSunueBTwud0AiA'
            }
        }

        return segmentKey
    },
    hideSubsequentYearFees: {
        dk: {
            accounting: true,
        },
        se: {
            accounting: true,
        },
        no: {
            accounting: true,
        },
        nl: {
            accounting: true,
        },
        uk: {
            accounting: true,
        },
        de: { },
        us: {
            accounting: true,
        },
    },
    showRequiredTermsPage: {
        dk: {
            accounting: true,
            termId: 51,
        },
        se: {
            accounting: true,
            termId: 48,
        },
        no: {
            accounting: true,
            termId: 49,
        },
        nl: {
            accounting: true,
            termId: 47,
        },
        uk: {
            termId: 50,
        },
        de: { },
        us: { },
    },
    publicProfileUrls: {
        dk: 'https://www.ageras.dk/profil',
        se: 'https://www.ageras.se/profil',
        no: 'https://www.ageras.no/profil',
        nl: 'https://nl.ageras.com/profiel',
        de: 'https://www.ageras.de/profil',
        us: 'https://www.ageras.com/profile',
    },
    dixa: {
        dk: 'ae724469-1336-4562-b00d-a3807dec9354',
        se: '6bc49b85-c901-4a6d-baab-ac01ed6f63d8',
        no: '31a30bef-2590-4b93-b344-b1cae5bd1c76',
        nl: '75a8fa2a-0250-4d7f-9c6b-eee037bb1216',
        de: '1936cf99-5df1-4c65-866c-6d443438a8f2',
        us: '80658c13-8f22-4583-a107-cd9cdcf989f5',
    },
    refundAmountThreshold: {
        no: 4000,
        dk: 3000,
        se: 4000,
        de: 400,
        nl: 400,
        uk: 300,
        us: 400,
    },
    exclusivePartnersId: {
        meneto: 221150,
        tellow: 224211,
    },
    currency: {
        dk: 'DKK',
        se: 'SEK',
        no: 'NOK',
        de: 'EUR',
        uk: 'GBP',
        nl: 'EUR',
        us: 'USD',
    },
    hiddenIndustriesForRefusal: [ 'law' ],
    minimumMarketplacePrePaymentAmount: {
        us: 100,
        dk: 1,
    },
    marketsQuoteWithoutPrice: [ 'no', 'de' ]
}
