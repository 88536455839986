import { decamelizeKeys } from 'humps'
import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const GET_CV = 'cv/GET_CV'
export const GET_CV_SUCCESS = 'cv/GET_CV_SUCCESS'
export const GET_CV_FAIL = 'cv/GET_CV_FAIL'
export const UPDATE_DESCRIPTION = 'cv/UPDATE_DESCRIPTION'
export const UPDATE_DESCRIPTION_SUCCESS = 'cv/UPDATE_DESCRIPTION_SUCCESS'
export const UPDATE_DESCRIPTION_FAIL = 'cv/UPDATE_DESCRIPTION_FAIL'

export const CREATE_EXPERTISE = 'cv/CREATE_EXPERTISE'
export const CREATE_EXPERTISE_SUCCESS = 'cv/CREATE_EXPERTISE_SUCCESS'
export const CREATE_EXPERTISE_FAIL = 'cv/CREATE_EXPERTISE_FAIL'

export const UPDATE_EXPERTISE = 'cv/UPDATE_EXPERTISE'
export const UPDATE_EXPERTISE_SUCCESS = 'cv/UPDATE_EXPERTISE_SUCCESS'
export const UPDATE_EXPERTISE_FAIL = 'cv/UPDATE_EXPERTISE_FAIL'

export const DELETE_EXPERTISE = 'cv/DELETE_EXPERTISE'
export const DELETE_EXPERTISE_SUCCESS = 'cv/DELETE_EXPERTISE_SUCCESS'
export const DELETE_EXPERTISE_FAIL = 'cv/DELETE_EXPERTISE_FAIL'

export const CREATE_EXPERIENCE = 'cv/CREATE_EXPERIENCE'
export const CREATE_EXPERIENCE_SUCCESS = 'cv/CREATE_EXPERIENCE_SUCCESS'
export const CREATE_EXPERIENCE_FAIL = 'cv/CREATE_EXPERIENCE_FAIL'

export const UPDATE_EXPERIENCE = 'cv/UPDATE_EXPERIENCE'
export const UPDATE_EXPERIENCE_SUCCESS = 'cv/UPDATE_EXPERIENCE_SUCCESS'
export const UPDATE_EXPERIENCE_FAIL = 'cv/UPDATE_EXPERIENCE_FAIL'

export const DELETE_EXPERIENCE = 'cv/DELETE_EXPERIENCE'
export const DELETE_EXPERIENCE_SUCCESS = 'cv/DELETE_EXPERIENCE_SUCCESS'
export const DELETE_EXPERIENCE_FAIL = 'cv/DELETE_EXPERIENCE_FAIL'

export const CREATE_BADGE = 'partners/CREATE_BADGE'
export const CREATE_BADGE_SUCCESS = 'partners/CREATE_BADGE_SUCCESS'
export const CREATE_BADGE_FAIL = 'partners/CREATE_BADGE_FAIL'

export const REMOVE_BADGE = 'partners/REMOVE_BADGE'
export const REMOVE_BADGE_SUCCESS = 'partners/REMOVE_BADGE_SUCCESS'
export const REMOVE_BADGE_FAIL = 'partners/REMOVE_BADGE_FAIL'

export const CREATE_EDUCATION = 'cv/CREATE_EDUCATION'
export const CREATE_EDUCATION_SUCCESS = 'cv/CREATE_EDUCATION_SUCCESS'
export const CREATE_EDUCATION_FAIL = 'cv/CREATE_EDUCATION_FAIL'

export const UPDATE_EDUCATION = 'cv/UPDATE_EDUCATION'
export const UPDATE_EDUCATION_SUCCESS = 'cv/UPDATE_EDUCATION_SUCCESS'
export const UPDATE_EDUCATION_FAIL = 'cv/UPDATE_EDUCATION_FAIL'

export const DELETE_EDUCATION = 'cv/DELETE_EDUCATION'
export const DELETE_EDUCATION_SUCCESS = 'cv/DELETE_EDUCATION_SUCCESS'
export const DELETE_EDUCATION_FAIL = 'cv/DELETE_EDUCATION_FAIL'

export const CREATE_COURSE = 'cv/CREATE_COURSE'
export const CREATE_COURSE_SUCCESS = 'cv/CREATE_COURSE_SUCCESS'
export const CREATE_COURSE_FAIL = 'cv/CREATE_COURSE_FAIL'

export const UPDATE_COURSE = 'cv/UPDATE_COURSE'
export const UPDATE_COURSE_SUCCESS = 'cv/UPDATE_COURSE_SUCCESS'
export const UPDATE_COURSE_FAIL = 'cv/UPDATE_COURSE_FAIL'

export const DELETE_COURSE = 'cv/DELETE_COURSE'
export const DELETE_COURSE_SUCCESS = 'cv/DELETE_COURSE_SUCCESS'
export const DELETE_COURSE_FAIL = 'cv/DELETE_COURSE_FAIL'

export const CREATE_AWARD = 'cv/CREATE_AWARD'
export const CREATE_AWARD_SUCCESS = 'cv/CREATE_AWARD_SUCCESS'
export const CREATE_AWARD_FAIL = 'cv/CREATE_AWARD_FAIL'

export const UPDATE_AWARD = 'cv/UPDATE_AWARD'
export const UPDATE_AWARD_SUCCESS = 'cv/UPDATE_AWARD_SUCCESS'
export const UPDATE_AWARD_FAIL = 'cv/UPDATE_AWARD_FAIL'

export const DELETE_AWARD = 'cv/DELETE_AWARD'
export const DELETE_AWARD_SUCCESS = 'cv/DELETE_AWARD_SUCCESS'
export const DELETE_AWARD_FAIL = 'cv/DELETE_AWARD_FAIL'

export const CREATE_LANGUAGE = 'cv/CREATE_LANGUAGE'
export const CREATE_LANGUAGE_SUCCESS = 'cv/CREATE_LANGUAGE_SUCCESS'
export const CREATE_LANGUAGE_FAIL = 'cv/CREATE_LANGUAGE_FAIL'

export const DELETE_LANGUAGE = 'cv/DELETE_LANGUAGE'
export const DELETE_LANGUAGE_SUCCESS = 'cv/DELETE_LANGUAGE_SUCCESS'
export const DELETE_LANGUAGE_FAIL = 'cv/DELETE_LANGUAGE_FAIL'

export const DELETE_SOCIAL_MEDIA = 'cv/DELETE_SOCIAL_MEDIA'
export const DELETE_SOCIAL_MEDIA_SUCCESS = 'cv/DELETE_SOCIAL_MEDIA_SUCCESS'
export const DELETE_SOCIAL_MEDIA_FAIL = 'cv/DELETE_SOCIAL_MEDIA_FAIL'

export const getCv = (partnerId, partnerUserId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ GET_CV, GET_CV_SUCCESS, GET_CV_FAIL ],
        endpoint: `/cv/${partnerUserId}`,
        schema: schemas.cv,
    },
})

export const saveDescription = (partnerUserId, data) => dispatch => dispatch({
    [CALL_API]: {
        types: [ UPDATE_DESCRIPTION, UPDATE_DESCRIPTION_SUCCESS, UPDATE_DESCRIPTION_FAIL ],
        endpoint: `/cv/${partnerUserId}/description`,
        schema: schemas.cv,
        options: {
            method: 'put',
            body: decamelizeKeys(data),
        },
    },
})

export const saveSocialMedia = (partnerUserId, data) => dispatch => dispatch({
    [CALL_API]: {
        types: [ UPDATE_DESCRIPTION, UPDATE_DESCRIPTION_SUCCESS, UPDATE_DESCRIPTION_FAIL ],
        endpoint: `/cv/${partnerUserId}/social`,
        schema: schemas.cv,
        options: {
            method: 'put',
            body: decamelizeKeys(data),
        },
    },
})

export const deleteSocialMedia = (partnerUserId, socialMediaId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ DELETE_SOCIAL_MEDIA, DELETE_SOCIAL_MEDIA_SUCCESS, DELETE_SOCIAL_MEDIA_FAIL ],
        endpoint: `/cv/${partnerUserId}/social/${socialMediaId}`,
        options: {
            method: 'delete',
        },
    },
})

// Expertises
export const createExpertise = (partnerUserId, data) => dispatch => dispatch({
    [CALL_API]: {
        types: [ CREATE_EXPERTISE, CREATE_EXPERTISE_SUCCESS, CREATE_EXPERTISE_FAIL ],
        endpoint: `/cv/${partnerUserId}/expertise`,
        schema: schemas.cv,
        options: {
            method: 'post',
            body: decamelizeKeys(data),
        },
    },
})

export const updateExpertise = (partnerUserId, data) => dispatch => dispatch({
    [CALL_API]: {
        types: [ UPDATE_EXPERTISE, UPDATE_EXPERTISE_SUCCESS, UPDATE_EXPERTISE_FAIL ],
        endpoint: `/cv/${partnerUserId}/expertise`,
        schema: schemas.cv,
        options: {
            method: 'put',
            body: decamelizeKeys(data),
        },
    },
})

export const deleteExpertise = (partnerUserId, expertiseId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ DELETE_EXPERTISE, DELETE_EXPERTISE_SUCCESS, DELETE_EXPERTISE_FAIL ],
        endpoint: `/cv/${partnerUserId}/expertise/${expertiseId}`,
        options: {
            method: 'delete',
        },
    },
})
// Expertises end

// Experiences
export const createExperience = (partnerUserId, data) => dispatch => dispatch({
    [CALL_API]: {
        types: [ CREATE_EXPERIENCE, CREATE_EXPERIENCE_SUCCESS, CREATE_EXPERIENCE_FAIL ],
        endpoint: `/cv/${partnerUserId}/experience`,
        schema: schemas.cv,
        options: {
            method: 'post',
            body: decamelizeKeys(data),
        },
    },
})

export const updateExperience = (partnerUserId, data) => dispatch => dispatch({
    [CALL_API]: {
        types: [ UPDATE_EXPERIENCE, UPDATE_EXPERIENCE_SUCCESS, UPDATE_EXPERIENCE_FAIL ],
        endpoint: `/cv/${partnerUserId}/experience`,
        schema: schemas.cv,
        options: {
            method: 'put',
            body: decamelizeKeys(data),
        },
    },
})

export const deleteExperience = (partnerUserId, experienceId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ DELETE_EXPERIENCE, DELETE_EXPERIENCE_SUCCESS, DELETE_EXPERIENCE_FAIL ],
        endpoint: `/cv/${partnerUserId}/experience/${experienceId}`,
        options: {
            method: 'delete',
        },
    },
})
// Experiences end

export const addEducationToPartnerUser = (partnerUserId, data) => dispatch => dispatch({
    [CALL_API]: {
        types: [ CREATE_EDUCATION, CREATE_EDUCATION_SUCCESS, CREATE_EDUCATION_FAIL ],
        endpoint: `/cv/${partnerUserId}/education`,
        schema: schemas.cv,
        options: {
            method: 'post',
            body: decamelizeKeys(data),
        },
    },
})

export const updateEducation = (partnerUserId, data) => dispatch => dispatch({
    [CALL_API]: {
        types: [ UPDATE_EDUCATION, UPDATE_EDUCATION_SUCCESS, UPDATE_EDUCATION_FAIL ],
        endpoint: `/cv/${partnerUserId}/education`,
        schema: schemas.cv,
        options: {
            method: 'put',
            body: decamelizeKeys(data),
        },
    },
})

export const deleteEducation = (partnerUserId, educationId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ DELETE_EDUCATION, DELETE_EDUCATION_SUCCESS, DELETE_EDUCATION_FAIL ],
        endpoint: `/cv/${partnerUserId}/education/${educationId}`,
        options: {
            method: 'delete',
        },
    },
})

export const addCourseToPartnerUser = (partnerUserId, data) => dispatch => dispatch({
    [CALL_API]: {
        types: [ CREATE_COURSE, CREATE_COURSE_SUCCESS, CREATE_COURSE_FAIL ],
        endpoint: `/cv/${partnerUserId}/course`,
        schema: schemas.cv,
        options: {
            method: 'post',
            body: decamelizeKeys(data),
        },
    },
})

export const updateCourse = (partnerUserId, data) => dispatch => dispatch({
    [CALL_API]: {
        types: [ UPDATE_COURSE, UPDATE_COURSE_SUCCESS, UPDATE_COURSE_FAIL ],
        endpoint: `/cv/${partnerUserId}/course`,
        schema: schemas.cv,
        options: {
            method: 'put',
            body: decamelizeKeys(data),
        },
    },
})

export const deleteCourse = (partnerUserId, courseId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ DELETE_COURSE, DELETE_COURSE_SUCCESS, DELETE_COURSE_FAIL ],
        endpoint: `/cv/${partnerUserId}/course/${courseId}`,
        options: {
            method: 'delete',
        },
    },
})

export const addAwardToPartnerUser = (partnerUserId, data) => dispatch => dispatch({
    [CALL_API]: {
        types: [ CREATE_AWARD, CREATE_AWARD_SUCCESS, CREATE_AWARD_FAIL ],
        endpoint: `/cv/${partnerUserId}/award`,
        schema: schemas.cv,
        options: {
            method: 'post',
            body: decamelizeKeys(data),
        },
    },
})

export const updateAward = (partnerUserId, data) => dispatch => dispatch({
    [CALL_API]: {
        types: [ UPDATE_AWARD, UPDATE_AWARD_SUCCESS, UPDATE_AWARD_FAIL ],
        endpoint: `/cv/${partnerUserId}/award`,
        schema: schemas.cv,
        options: {
            method: 'put',
            body: decamelizeKeys(data),
        },
    },
})

export const deleteAward = (partnerUserId, awardId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ DELETE_AWARD, DELETE_AWARD_SUCCESS, DELETE_AWARD_FAIL ],
        endpoint: `/cv/${partnerUserId}/award/${awardId}`,
        options: {
            method: 'delete',
        },
    },
})

export const addBadgeToPartnerUser = (partnerId, badgeId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ CREATE_BADGE, CREATE_BADGE_SUCCESS, CREATE_BADGE_FAIL ],
        endpoint: `/cv/${partnerId}/badge`,
        schema: schemas.certification,
        options: {
            method: 'post',
            body: {
                id: badgeId,
            },
        },
    },
})

export const removeBadgeToPartnerUser = (partnerId, badgeId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ REMOVE_BADGE, REMOVE_BADGE_SUCCESS, REMOVE_BADGE_FAIL ],
        endpoint: `/cv/${partnerId}/badge/${badgeId}`,
        schema: schemas.cv,
        options: {
            method: 'delete',
            body: {
            },
        },
    },
})

export const createLanguage = (partnerUserId, data) => dispatch => dispatch({
    [CALL_API]: {
        types: [ CREATE_LANGUAGE, CREATE_LANGUAGE_SUCCESS, CREATE_LANGUAGE_FAIL ],
        endpoint: `/cv/${partnerUserId}/language`,
        schema: schemas.cv,
        options: {
            method: 'post',
            body: decamelizeKeys(data),
        },
    },
})

export const deleteLanguage = (partnerUserId, languageId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ DELETE_LANGUAGE, DELETE_LANGUAGE_SUCCESS, DELETE_LANGUAGE_FAIL ],
        endpoint: `/cv/${partnerUserId}/language/${languageId}`,
        schema: schemas.cv,
        options: {
            method: 'delete',
        },
    },
})
