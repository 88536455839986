/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Ageras.com API
 * this is the Ageras API
 *
 * OpenAPI spec version: 2.0
 * Contact: cto@ageras.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
const portableFetch = fetch;
import { Configuration } from "./configuration";

const BASE_PATH = "https://api.ageras.com/v2".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration?: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 *
 * @export
 * @interface ActionerResource
 */
export interface ActionerResource {
    /**
     * Actioner's ID.
     * @type {number}
     * @memberof ActionerResource
     */
    actionerId?: number;
    /**
     * Actioner's type, e.g. 'employee'.
     * @type {string}
     * @memberof ActionerResource
     */
    actionerType?: ActionerResource.ActionerTypeEnum;
    /**
     * Date of the action.
     * @type {string}
     * @memberof ActionerResource
     */
    actionDate?: string;
}

/**
 * @export
 * @namespace ActionerResource
 */
export namespace ActionerResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionerTypeEnum {
        Unknown = <any> 'unknown',
        Employee = <any> 'employee',
        Partner = <any> 'partner',
        PartnerUser = <any> 'partner_user',
        Client = <any> 'client'
    }
}

/**
 *
 * @export
 * @interface ActivityActionResource
 */
export interface ActivityActionResource {
    /**
     * @var string *_/
     * @type {string}
     * @memberof ActivityActionResource
     */
    action?: ActivityActionResource.ActionEnum;
}

/**
 * @export
 * @namespace ActivityActionResource
 */
export namespace ActivityActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        Unknown = <any> 'unknown',
        Pin = <any> 'pin',
        Unpin = <any> 'unpin'
    }
}

/**
 *
 * @export
 * @interface ActivityBehalfResource
 */
export interface ActivityBehalfResource {
    /**
     *
     * @type {number}
     * @memberof ActivityBehalfResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ActivityBehalfResource
     */
    type?: ActivityBehalfResource.TypeEnum;
    /**
     *
     * @type {ActivityEmployeeResource}
     * @memberof ActivityBehalfResource
     */
    employee?: ActivityEmployeeResource;
    /**
     *
     * @type {ActivityPartnerUserResource}
     * @memberof ActivityBehalfResource
     */
    partneruser?: ActivityPartnerUserResource;
}

/**
 * @export
 * @namespace ActivityBehalfResource
 */
export namespace ActivityBehalfResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Unknown = <any> 'unknown',
        Employee = <any> 'employee'
    }
}

/**
 *
 * @export
 * @interface ActivityCallResource
 */
export interface ActivityCallResource {
    /**
     *
     * @type {number}
     * @memberof ActivityCallResource
     */
    id?: number;
    /**
     *
     * @type {number}
     * @memberof ActivityCallResource
     */
    duration?: number;
    /**
     *
     * @type {string}
     * @memberof ActivityCallResource
     */
    phoneNumber?: string;
    /**
     *
     * @type {string}
     * @memberof ActivityCallResource
     */
    direction?: string;
}

/**
 *
 * @export
 * @interface ActivityClientResource
 */
export interface ActivityClientResource {
    /**
     *
     * @type {number}
     * @memberof ActivityClientResource
     */
    id?: number;
}

/**
 *
 * @export
 * @interface ActivityEmployeeResource
 */
export interface ActivityEmployeeResource {
    /**
     *
     * @type {number}
     * @memberof ActivityEmployeeResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ActivityEmployeeResource
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof ActivityEmployeeResource
     */
    profilePicture?: string;
}

/**
 *
 * @export
 * @interface ActivityInvoiceResource
 */
export interface ActivityInvoiceResource {
    /**
     *
     * @type {number}
     * @memberof ActivityInvoiceResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ActivityInvoiceResource
     */
    type?: string;
    /**
     *
     * @type {number}
     * @memberof ActivityInvoiceResource
     */
    punches?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof ActivityInvoiceResource
     */
    amountInclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof ActivityInvoiceResource
     */
    amountExclVat?: AmountResource;
}

/**
 *
 * @export
 * @interface ActivityLeadMeetingResource
 */
export interface ActivityLeadMeetingResource {
    /**
     *
     * @type {number}
     * @memberof ActivityLeadMeetingResource
     */
    price?: number;
    /**
     *
     * @type {string}
     * @memberof ActivityLeadMeetingResource
     */
    description?: string;
}

/**
 *
 * @export
 * @interface ActivityLeadResource
 */
export interface ActivityLeadResource {
    /**
     *
     * @type {number}
     * @memberof ActivityLeadResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ActivityLeadResource
     */
    title?: string;
    /**
     *
     * @type {ActivityLeadMeetingResource}
     * @memberof ActivityLeadResource
     */
    meeting?: ActivityLeadMeetingResource;
}

/**
 *
 * @export
 * @interface ActivityNoteResource
 */
export interface ActivityNoteResource {
    /**
     *
     * @type {number}
     * @memberof ActivityNoteResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ActivityNoteResource
     */
    text?: string;
}

/**
 *
 * @export
 * @interface ActivityPartnerLeadOfferResource
 */
export interface ActivityPartnerLeadOfferResource {
    /**
     *
     * @type {number}
     * @memberof ActivityPartnerLeadOfferResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ActivityPartnerLeadOfferResource
     */
    method?: string;
    /**
     *
     * @type {number}
     * @memberof ActivityPartnerLeadOfferResource
     */
    punchPrice?: number;
}

/**
 *
 * @export
 * @interface ActivityPartnerResource
 */
export interface ActivityPartnerResource {
    /**
     *
     * @type {number}
     * @memberof ActivityPartnerResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ActivityPartnerResource
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof ActivityPartnerResource
     */
    profilePicture?: string;
}

/**
 *
 * @export
 * @interface ActivityPartnerUserResource
 */
export interface ActivityPartnerUserResource {
    /**
     *
     * @type {number}
     * @memberof ActivityPartnerUserResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ActivityPartnerUserResource
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof ActivityPartnerUserResource
     */
    profilePicture?: string;
}

/**
 *
 * @export
 * @interface ActivityRatingResource
 */
export interface ActivityRatingResource {
    /**
     *
     * @type {number}
     * @memberof ActivityRatingResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ActivityRatingResource
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof ActivityRatingResource
     */
    comment?: string;
    /**
     *
     * @type {number}
     * @memberof ActivityRatingResource
     */
    ratingQuality?: number;
    /**
     *
     * @type {number}
     * @memberof ActivityRatingResource
     */
    ratingContact?: number;
    /**
     *
     * @type {number}
     * @memberof ActivityRatingResource
     */
    ratingExpertise?: number;
    /**
     *
     * @type {number}
     * @memberof ActivityRatingResource
     */
    ratingSchedule?: number;
    /**
     *
     * @type {number}
     * @memberof ActivityRatingResource
     */
    ratingBudget?: number;
    /**
     *
     * @type {number}
     * @memberof ActivityRatingResource
     */
    rating?: number;
}

/**
 *
 * @export
 * @interface ActivityResource
 */
export interface ActivityResource {
    /**
     * id for activity.
     * @type {number}
     * @memberof ActivityResource
     */
    id?: number;
    /**
     * Date and time of the creation of the activity.
     * @type {string}
     * @memberof ActivityResource
     */
    createdAt?: string;
    /**
     * Geo Code for the activity.
     * @type {string}
     * @memberof ActivityResource
     */
    geoCode?: string;
    /**
     * Type of activity.
     * @type {string}
     * @memberof ActivityResource
     */
    type?: ActivityResource.TypeEnum;
    /**
     * Whom did initiate the activity.
     * @type {string}
     * @memberof ActivityResource
     */
    invoker?: string;
    /**
     *
     * @type {ActivityEmployeeResource}
     * @memberof ActivityResource
     */
    employee?: ActivityEmployeeResource;
    /**
     *
     * @type {ActivityLeadResource}
     * @memberof ActivityResource
     */
    lead?: ActivityLeadResource;
    /**
     *
     * @type {ActivityPartnerResource}
     * @memberof ActivityResource
     */
    partner?: ActivityPartnerResource;
    /**
     *
     * @type {ActivityPartnerResource}
     * @memberof ActivityResource
     */
    partnerContract?: ActivityPartnerResource;
    /**
     *
     * @type {ActivityPartnerUserResource}
     * @memberof ActivityResource
     */
    partneruser?: ActivityPartnerUserResource;
    /**
     *
     * @type {ActivityCallResource}
     * @memberof ActivityResource
     */
    call?: ActivityCallResource;
    /**
     *
     * @type {ActivityNoteResource}
     * @memberof ActivityResource
     */
    note?: ActivityNoteResource;
    /**
     *
     * @type {ActivitySatisfactionResource}
     * @memberof ActivityResource
     */
    satisfaction?: ActivitySatisfactionResource;
    /**
     *
     * @type {ActivityInvoiceResource}
     * @memberof ActivityResource
     */
    invoice?: ActivityInvoiceResource;
    /**
     *
     * @type {ActivityRatingResource}
     * @memberof ActivityResource
     */
    rating?: ActivityRatingResource;
    /**
     *
     * @type {ActivityBehalfResource}
     * @memberof ActivityResource
     */
    onBehalfOf?: ActivityBehalfResource;
    /**
     *
     * @type {ActivityPartnerLeadOfferResource}
     * @memberof ActivityResource
     */
    partnerLeadOffer?: ActivityPartnerLeadOfferResource;
    /**
     *
     * @type {ActivityTaskResource}
     * @memberof ActivityResource
     */
    task?: ActivityTaskResource;
    /**
     *
     * @type {ActivityClientResource}
     * @memberof ActivityResource
     */
    client?: ActivityClientResource;
    /**
     * Is this activity pinned to mark its importance.
     * @type {boolean}
     * @memberof ActivityResource
     */
    isPinned?: boolean;
}

/**
 * @export
 * @namespace ActivityResource
 */
export namespace ActivityResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Unknown = <any> 'unknown',
        FrontendFieldsCreate = <any> 'frontend_fields_create',
        OrganisationJobsCreate = <any> 'organisation_jobs_create',
        LocationsCreate = <any> 'locations_create',
        CouponsTransactionsCreate = <any> 'coupons_transactions_create',
        FrontendPagesCreate = <any> 'frontend_pages_create',
        FrontendRedirectsCreate = <any> 'frontend_redirects_create',
        FrontendResourcesCreate = <any> 'frontend_resources_create',
        FrontendRoutingCreate = <any> 'frontend_routing_create',
        FrontendSettingsCreate = <any> 'frontend_settings_create',
        FrontendFieldsDelete = <any> 'frontend_fields_delete',
        OrganisationJobsDelete = <any> 'organisation_jobs_delete',
        LocationsDelete = <any> 'locations_delete',
        FrontendPagesDelete = <any> 'frontend_pages_delete',
        PartnersRatingsDeleted = <any> 'partners_ratings_deleted',
        FrontendRedirectsDelete = <any> 'frontend_redirects_delete',
        FrontendResourcesDelete = <any> 'frontend_resources_delete',
        FrontendRoutingDelete = <any> 'frontend_routing_delete',
        FrontendFieldsUpdate = <any> 'frontend_fields_update',
        OrganisationJobsUpdate = <any> 'organisation_jobs_update',
        LocationsUpdate = <any> 'locations_update',
        FrontendPagesUpdate = <any> 'frontend_pages_update',
        PartnersUpdate = <any> 'partners_update',
        PartnersRatingsUpdate = <any> 'partners_ratings_update',
        FrontendRedirectsUpdate = <any> 'frontend_redirects_update',
        FrontendResourcesUpdate = <any> 'frontend_resources_update',
        FrontendRoutingUpdate = <any> 'frontend_routing_update',
        FrontendSettingsUpdate = <any> 'frontend_settings_update',
        FrontendSnippetsUpdate = <any> 'frontend_snippets_update',
        InvoicesCreate = <any> 'invoices_create',
        PartnersNotesCreate = <any> 'partners_notes_create',
        PhonecallsCreate = <any> 'phonecalls_create',
        PartnersRatingsCreate = <any> 'partners_ratings_create',
        PartnersLeadOffersCreate = <any> 'partners_lead_offers_create',
        PartnersEmployeeNotesCreate = <any> 'partners_employee_notes_create',
        TaskCreate = <any> 'task_create',
        TaskAssign = <any> 'task_assign',
        TaskUpdate = <any> 'task_update',
        TaskComplete = <any> 'task_complete',
        LeadWon = <any> 'lead_won',
        LeadSentOut = <any> 'lead_sent_out',
        LeadValidated = <any> 'lead_validated',
        LeadNoteCreated = <any> 'lead_note_created',
        LeadMarkedTest = <any> 'lead_marked_test',
        LeadMarkedDuplicate = <any> 'lead_marked_duplicate',
        LeadMarkedSentToAdvisor = <any> 'lead_marked_sent_to_advisor',
        LeadMarkedSpam = <any> 'lead_marked_spam',
        LeadMarkedNotSerious = <any> 'lead_marked_not_serious',
        LeadRejectAllQuotes = <any> 'lead_reject_all_quotes',
        LeadMarkedCompleted = <any> 'lead_marked_completed',
        LeadNoAnswer = <any> 'lead_no_answer',
        LeadPostponed = <any> 'lead_postponed',
        LeadMarkedUntreated = <any> 'lead_marked_untreated',
        LeadInfoUpdated = <any> 'lead_info_updated',
        LeadCall = <any> 'lead_call',
        LeadGivenUp = <any> 'lead_given_up',
        LeadSentAsMail = <any> 'lead_sent_as_mail',
        PartnerRefillPreferenceCreated = <any> 'partner_refill_preference_created',
        PartnerLeadQuoteAccepted = <any> 'partner_lead_quote_accepted',
        PartnerLeadQuoteRefused = <any> 'partner_lead_quote_refused',
        PartnerContractCreate = <any> 'partner_contract_create',
        PartnerContractAccept = <any> 'partner_contract_accept',
        PartnerContractCancelNormally = <any> 'partner_contract_cancel_normally',
        PartnerContractCancelImmediately = <any> 'partner_contract_cancel_immediately',
        PartnerContractUncancelNormalCancellation = <any> 'partner_contract_uncancel_normal_cancellation',
        PartnerContractDelete = <any> 'partner_contract_delete'
    }
}

/**
 *
 * @export
 * @interface ActivityResult
 */
export interface ActivityResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof ActivityResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof ActivityResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof ActivityResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof ActivityResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<ActivityResource>}
     * @memberof ActivityResult
     */
    data?: Array<ActivityResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof ActivityResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface ActivitySatisfactionResource
 */
export interface ActivitySatisfactionResource {
    /**
     *
     * @type {string}
     * @memberof ActivitySatisfactionResource
     */
    identifier?: string;
    /**
     *
     * @type {string}
     * @memberof ActivitySatisfactionResource
     */
    label?: string;
    /**
     *
     * @type {number}
     * @memberof ActivitySatisfactionResource
     */
    score?: number;
}

/**
 *
 * @export
 * @interface ActivityTaskResource
 */
export interface ActivityTaskResource {
    /**
     *
     * @type {number}
     * @memberof ActivityTaskResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ActivityTaskResource
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof ActivityTaskResource
     */
    description?: string;
    /**
     *
     * @type {number}
     * @memberof ActivityTaskResource
     */
    statusId?: number;
    /**
     *
     * @type {number}
     * @memberof ActivityTaskResource
     */
    priorityId?: number;
    /**
     *
     * @type {ActivityEmployeeResource}
     * @memberof ActivityTaskResource
     */
    assignedEmployee?: ActivityEmployeeResource;
    /**
     *
     * @type {ActivityEmployeeResource}
     * @memberof ActivityTaskResource
     */
    completedByEmployee?: ActivityEmployeeResource;
}

/**
 *
 * @export
 * @interface AffiliateCampaignResource
 */
export interface AffiliateCampaignResource {
    /**
     * Campaign id. @var int
     * @type {number}
     * @memberof AffiliateCampaignResource
     */
    id?: number;
    /**
     * Campaign key. @var string
     * @type {string}
     * @memberof AffiliateCampaignResource
     */
    key?: string;
    /**
     * Campaign ID. @var string
     * @type {string}
     * @memberof AffiliateCampaignResource
     */
    utmCampaign?: string;
    /**
     * Campaign name @var string
     * @type {string}
     * @memberof AffiliateCampaignResource
     */
    name?: string;
    /**
     *
     * @type {AffiliateResource}
     * @memberof AffiliateCampaignResource
     */
    affiliate?: AffiliateResource;
}

/**
 *
 * @export
 * @interface AffiliateCampaignResult
 */
export interface AffiliateCampaignResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof AffiliateCampaignResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof AffiliateCampaignResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof AffiliateCampaignResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof AffiliateCampaignResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<AffiliateCampaignResource>}
     * @memberof AffiliateCampaignResult
     */
    data?: Array<AffiliateCampaignResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof AffiliateCampaignResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface AffiliateResource
 */
export interface AffiliateResource {
    /**
     * Id for the affiliate.
     * @type {number}
     * @memberof AffiliateResource
     */
    id?: number;
    /**
     * Company name
     * @type {string}
     * @memberof AffiliateResource
     */
    companyName?: string;
}

/**
 *
 * @export
 * @interface AffiliateResult
 */
export interface AffiliateResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof AffiliateResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof AffiliateResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof AffiliateResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof AffiliateResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<AffiliateResource>}
     * @memberof AffiliateResult
     */
    data?: Array<AffiliateResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof AffiliateResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface AggregationItemResource
 */
export interface AggregationItemResource {
    /**
     *
     * @type {string}
     * @memberof AggregationItemResource
     */
    key?: string;
    /**
     * Partner found in the search.
     * @type {number}
     * @memberof AggregationItemResource
     */
    hits?: number;
    /**
     * Sub-items for aggregation item resource
     * @type {Array<AggregationSubItemResource>}
     * @memberof AggregationItemResource
     */
    subItems?: Array<AggregationSubItemResource>;
}

/**
 *
 * @export
 * @interface AggregationResource
 */
export interface AggregationResource {
    /**
     *
     * @type {string}
     * @memberof AggregationResource
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof AggregationResource
     */
    type?: AggregationResource.TypeEnum;
    /**
     *
     * @type {number}
     * @memberof AggregationResource
     */
    value?: number;
    /**
     *
     * @type {Array<AggregationItemResource>}
     * @memberof AggregationResource
     */
    items?: Array<AggregationItemResource>;
    /**
     *
     * @type {AmountResource}
     * @memberof AggregationResource
     */
    amount?: AmountResource;
}

/**
 * @export
 * @namespace AggregationResource
 */
export namespace AggregationResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Unknown = <any> 'unknown',
        Facets = <any> 'facets',
        Range = <any> 'range',
        Value = <any> 'value',
        Amount = <any> 'amount',
        Percentage = <any> 'percentage'
    }
}

/**
 *
 * @export
 * @interface AggregationResult
 */
export interface AggregationResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof AggregationResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof AggregationResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof AggregationResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof AggregationResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<AggregationResource>}
     * @memberof AggregationResult
     */
    data?: Array<AggregationResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof AggregationResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface AggregationSubItemResource
 */
export interface AggregationSubItemResource {
    /**
     * typically the id of a given resource
     * @type {string}
     * @memberof AggregationSubItemResource
     */
    key?: string;
    /**
     * value
     * @type {string}
     * @memberof AggregationSubItemResource
     */
    value?: string;
    /**
     * Used for URL friendly names
     * @type {string}
     * @memberof AggregationSubItemResource
     */
    slug?: string;
}

/**
 *
 * @export
 * @interface AmountResource
 */
export interface AmountResource {
    /**
     * Currency the given amount is represented as.
     * @type {string}
     * @memberof AmountResource
     */
    currency?: string;
    /**
     * Amount.
     * @type {number}
     * @memberof AmountResource
     */
    amount?: number;
}

/**
 *
 * @export
 * @interface AttachmentResource
 */
export interface AttachmentResource {
    /**
     *
     * @type {number}
     * @memberof AttachmentResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof AttachmentResource
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof AttachmentResource
     */
    content?: string;
    /**
     *
     * @type {string}
     * @memberof AttachmentResource
     */
    url?: string;
    /**
     *
     * @type {string}
     * @memberof AttachmentResource
     */
    type?: string;
    /**
     *
     * @type {number}
     * @memberof AttachmentResource
     */
    size?: number;
    /**
     *
     * @type {string}
     * @memberof AttachmentResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof AttachmentResource
     */
    ownerName?: string;
    /**
     *
     * @type {number}
     * @memberof AttachmentResource
     */
    conversationId?: number;
}

/**
 *
 * @export
 * @interface AttachmentResult
 */
export interface AttachmentResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof AttachmentResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof AttachmentResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof AttachmentResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof AttachmentResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<AttachmentResource>}
     * @memberof AttachmentResult
     */
    data?: Array<AttachmentResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof AttachmentResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface AuthResource
 */
export interface AuthResource {
    /**
     * Token provided the by the Ageras API, used to access.
     * @type {string}
     * @memberof AuthResource
     */
    token?: string;
    /**
     * The date that the token expires.
     * @type {string}
     * @memberof AuthResource
     */
    expiresAt?: string;
    /**
     * Id for the given user. @deprecated See $employee, $partner_user, $client
     * @type {string}
     * @memberof AuthResource
     */
    userId?: string;
    /**
     * The type of user that currently is associated to the token.
     * @type {string}
     * @memberof AuthResource
     */
    userType?: string;
    /**
     *
     * @type {EmployeeResource}
     * @memberof AuthResource
     */
    employee?: EmployeeResource;
    /**
     *
     * @type {PartnerUserResource}
     * @memberof AuthResource
     */
    partnerUser?: PartnerUserResource;
    /**
     *
     * @type {ClientResource}
     * @memberof AuthResource
     */
    client?: ClientResource;
    /**
     *
     * @type {PartnerSignUpResource}
     * @memberof AuthResource
     */
    partnerSignUp?: PartnerSignUpResource;
    /**
     *
     * @type {ClientSignUpResource}
     * @memberof AuthResource
     */
    clientSignUp?: ClientSignUpResource;
    /**
     * Email for the given user associated with the token.
     * @type {string}
     * @memberof AuthResource
     */
    email?: string;
    /**
     * Password used to post when logging in, will always be blank when resource is retrieved from server.
     * @type {string}
     * @memberof AuthResource
     */
    password?: string;
    /**
     * Available permissions
     * @type {Array<string>}
     * @memberof AuthResource
     */
    permissions?: Array<string>;
}

/**
 *
 * @export
 * @interface BrowserPushSubscriptionResource
 */
export interface BrowserPushSubscriptionResource {
    /**
     * @var int|null *_/
     * @type {number}
     * @memberof BrowserPushSubscriptionResource
     */
    id?: number;
    /**
     * @var string *_/
     * @type {string}
     * @memberof BrowserPushSubscriptionResource
     */
    endpoint?: string;
    /**
     * @var string *_/
     * @type {string}
     * @memberof BrowserPushSubscriptionResource
     */
    authToken?: string;
    /**
     * @var string *_/
     * @type {string}
     * @memberof BrowserPushSubscriptionResource
     */
    publicKey?: string;
    /**
     * @var Carbon|null *_/
     * @type {string}
     * @memberof BrowserPushSubscriptionResource
     */
    expiresAt?: string;
}

/**
 *
 * @export
 * @interface BusinessModelResource
 */
export interface BusinessModelResource {
    /**
     *
     * @type {number}
     * @memberof BusinessModelResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BusinessModelResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BusinessModelResource
     */
    identifier?: string;
    /**
     *
     * @type {string}
     * @memberof BusinessModelResource
     */
    description?: string;
    /**
     *
     * @type {string}
     * @memberof BusinessModelResource
     */
    abbreviation?: string;
    /**
     *
     * @type {string}
     * @memberof BusinessModelResource
     */
    geoCode?: string;
}

/**
 *
 * @export
 * @interface BusinessModelResult
 */
export interface BusinessModelResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof BusinessModelResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof BusinessModelResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof BusinessModelResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof BusinessModelResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<BusinessModelResource>}
     * @memberof BusinessModelResult
     */
    data?: Array<BusinessModelResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof BusinessModelResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface CardClientResource
 */
export interface CardClientResource {
    /**
     *
     * @type {number}
     * @memberof CardClientResource
     */
    id?: number;
}

/**
 *
 * @export
 * @interface CashierPaymentMethodResource
 */
export interface CashierPaymentMethodResource {
    /**
     *
     * @type {string}
     * @memberof CashierPaymentMethodResource
     */
    pmType?: string;
    /**
     *
     * @type {string}
     * @memberof CashierPaymentMethodResource
     */
    pmLastFour?: string;
}

/**
 *
 * @export
 * @interface CashierSubscriptionResource
 */
export interface CashierSubscriptionResource {
    /**
     *
     * @type {number}
     * @memberof CashierSubscriptionResource
     */
    id?: number;
    /**
     *
     * @type {StripeProductResource}
     * @memberof CashierSubscriptionResource
     */
    product?: StripeProductResource;
    /**
     *
     * @type {string}
     * @memberof CashierSubscriptionResource
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof CashierSubscriptionResource
     */
    endsAt?: string;
}

/**
 *
 * @export
 * @interface CashierSubscriptionResult
 */
export interface CashierSubscriptionResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof CashierSubscriptionResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof CashierSubscriptionResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof CashierSubscriptionResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof CashierSubscriptionResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<CashierSubscriptionResource>}
     * @memberof CashierSubscriptionResult
     */
    data?: Array<CashierSubscriptionResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof CashierSubscriptionResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface CertificationResource
 */
export interface CertificationResource {
    /**
     * Certification ID.
     * @type {number}
     * @memberof CertificationResource
     */
    id?: number;
    /**
     * Industry ID.
     * @type {number}
     * @memberof CertificationResource
     */
    industryId?: number;
    /**
     * Geo code.
     * @type {string}
     * @memberof CertificationResource
     */
    geoCode?: string;
    /**
     * Certification name.
     * @type {string}
     * @memberof CertificationResource
     */
    title?: string;
    /**
     * Description of the certifications.
     * @type {string}
     * @memberof CertificationResource
     */
    description?: string;
}

/**
 *
 * @export
 * @interface CertificationResult
 */
export interface CertificationResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof CertificationResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof CertificationResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof CertificationResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof CertificationResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<CertificationResource>}
     * @memberof CertificationResult
     */
    data?: Array<CertificationResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof CertificationResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface ChargeResource
 */
export interface ChargeResource {
    /**
     * Id of correspondence (content_archive).
     * @type {number}
     * @memberof ChargeResource
     */
    id?: number;
    /**
     * Date of creation.
     * @type {string}
     * @memberof ChargeResource
     */
    createdAt?: string;
    /**
     * Date of last update.
     * @type {string}
     * @memberof ChargeResource
     */
    updatedAt?: string;
    /**
     * Language code.
     * @type {string}
     * @memberof ChargeResource
     */
    lang?: string;
    /**
     * Description.
     * @type {string}
     * @memberof ChargeResource
     */
    description?: string;
    /**
     *
     * @type {AmountResource}
     * @memberof ChargeResource
     */
    amountInclVat?: AmountResource;
    /**
     * External payment id.
     * @type {string}
     * @memberof ChargeResource
     */
    extPaymentId?: string;
    /**
     * Status.
     * @type {string}
     * @memberof ChargeResource
     */
    status?: string;
}

/**
 *
 * @export
 * @interface ChargeResult
 */
export interface ChargeResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof ChargeResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof ChargeResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof ChargeResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof ChargeResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<ChargeResource>}
     * @memberof ChargeResult
     */
    data?: Array<ChargeResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof ChargeResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface CheckoutClientResource
 */
export interface CheckoutClientResource {
    /**
     * Client id.
     * @type {number}
     * @memberof CheckoutClientResource
     */
    id?: number;
    /**
     * Client First Name
     * @type {string}
     * @memberof CheckoutClientResource
     */
    firstName?: string;
    /**
     * Clients Last Name
     * @type {string}
     * @memberof CheckoutClientResource
     */
    lastName?: string;
    /**
     * Clients company name
     * @type {string}
     * @memberof CheckoutClientResource
     */
    companyName?: string;
}

/**
 *
 * @export
 * @interface CheckoutConsumerResource
 */
export interface CheckoutConsumerResource {
    /**
     * Type of consumer for the checkout
     * @type {string}
     * @memberof CheckoutConsumerResource
     */
    type?: CheckoutConsumerResource.TypeEnum;
    /**
     *
     * @type {CheckoutPartnerResource}
     * @memberof CheckoutConsumerResource
     */
    partner?: CheckoutPartnerResource;
    /**
     *
     * @type {CheckoutPartnerUserResource}
     * @memberof CheckoutConsumerResource
     */
    partnerUser?: CheckoutPartnerUserResource;
    /**
     *
     * @type {CheckoutClientResource}
     * @memberof CheckoutConsumerResource
     */
    client?: CheckoutClientResource;
    /**
     *
     * @type {CheckoutEmployeeResource}
     * @memberof CheckoutConsumerResource
     */
    employee?: CheckoutEmployeeResource;
}

/**
 * @export
 * @namespace CheckoutConsumerResource
 */
export namespace CheckoutConsumerResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Unknown = <any> 'unknown',
        Partner = <any> 'partner',
        PartnerUser = <any> 'partner_user',
        Employee = <any> 'employee',
        Client = <any> 'client'
    }
}

/**
 *
 * @export
 * @interface CheckoutDeliverableLineResource
 */
export interface CheckoutDeliverableLineResource {
    /**
     * Package Id
     * @type {number}
     * @memberof CheckoutDeliverableLineResource
     */
    id?: number;
    /**
     * Name of the Deliverable
     * @type {string}
     * @memberof CheckoutDeliverableLineResource
     */
    name?: string;
}

/**
 *
 * @export
 * @interface CheckoutDeliverableResource
 */
export interface CheckoutDeliverableResource {
    /**
     * Package Id
     * @type {number}
     * @memberof CheckoutDeliverableResource
     */
    id?: number;
    /**
     * Name of the Deliverable
     * @type {string}
     * @memberof CheckoutDeliverableResource
     */
    name?: string;
}

/**
 *
 * @export
 * @interface CheckoutDiscountResource
 */
export interface CheckoutDiscountResource {
    /**
     * Discount Id
     * @type {number}
     * @memberof CheckoutDiscountResource
     */
    id?: number;
    /**
     * Name of the Discount
     * @type {string}
     * @memberof CheckoutDiscountResource
     */
    name?: string;
}

/**
 *
 * @export
 * @interface CheckoutEmployeeResource
 */
export interface CheckoutEmployeeResource {
    /**
     * Employee id.
     * @type {number}
     * @memberof CheckoutEmployeeResource
     */
    id?: number;
    /**
     * Employee First Name
     * @type {string}
     * @memberof CheckoutEmployeeResource
     */
    firstName?: string;
    /**
     * Employee Last Name
     * @type {string}
     * @memberof CheckoutEmployeeResource
     */
    lastName?: string;
}

/**
 *
 * @export
 * @interface CheckoutItemResource
 */
export interface CheckoutItemResource {
    /**
     * Type of consumer for the checkout
     * @type {string}
     * @memberof CheckoutItemResource
     */
    type?: CheckoutItemResource.TypeEnum;
    /**
     *
     * @type {CheckoutPartnerLeadQuoteResource}
     * @memberof CheckoutItemResource
     */
    quote?: CheckoutPartnerLeadQuoteResource;
    /**
     *
     * @type {CheckoutPackageResource}
     * @memberof CheckoutItemResource
     */
    _package?: CheckoutPackageResource;
    /**
     *
     * @type {CheckoutDiscountResource}
     * @memberof CheckoutItemResource
     */
    discount?: CheckoutDiscountResource;
    /**
     *
     * @type {CheckoutDeliverableResource}
     * @memberof CheckoutItemResource
     */
    deliverable?: CheckoutDeliverableResource;
    /**
     *
     * @type {CheckoutDeliverableLineResource}
     * @memberof CheckoutItemResource
     */
    deliverableLine?: CheckoutDeliverableLineResource;
}

/**
 * @export
 * @namespace CheckoutItemResource
 */
export namespace CheckoutItemResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Unknown = <any> 'unknown',
        Quote = <any> 'quote',
        Package = <any> 'package',
        Discount = <any> 'discount',
        Deliverable = <any> 'deliverable',
        DeliverableLine = <any> 'deliverable_line'
    }
}

/**
 *
 * @export
 * @interface CheckoutLineResource
 */
export interface CheckoutLineResource {
    /**
     * id for the line
     * @type {number}
     * @memberof CheckoutLineResource
     */
    id?: number;
    /**
     *
     * @type {CheckoutConsumerResource}
     * @memberof CheckoutLineResource
     */
    seller?: CheckoutConsumerResource;
    /**
     *
     * @type {CheckoutItemResource}
     * @memberof CheckoutLineResource
     */
    item?: CheckoutItemResource;
    /**
     * Quantity per price.
     * @type {number}
     * @memberof CheckoutLineResource
     */
    quantity?: number;
    /**
     * Title for the product
     * @type {string}
     * @memberof CheckoutLineResource
     */
    itemName?: string;
    /**
     * Vat percent on item
     * @type {number}
     * @memberof CheckoutLineResource
     */
    itemVatPercent?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof CheckoutLineResource
     */
    itemVatAmount?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof CheckoutLineResource
     */
    itemPriceInclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof CheckoutLineResource
     */
    itemPriceExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof CheckoutLineResource
     */
    vatAmount?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof CheckoutLineResource
     */
    priceInclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof CheckoutLineResource
     */
    priceExclVat?: AmountResource;
}

/**
 *
 * @export
 * @interface CheckoutPackageResource
 */
export interface CheckoutPackageResource {
    /**
     * Package Id
     * @type {number}
     * @memberof CheckoutPackageResource
     */
    id?: number;
    /**
     * Name of the Package
     * @type {string}
     * @memberof CheckoutPackageResource
     */
    name?: string;
}

/**
 *
 * @export
 * @interface CheckoutPartnerLeadQuoteResource
 */
export interface CheckoutPartnerLeadQuoteResource {
    /**
     * Lead quote id id.
     * @type {number}
     * @memberof CheckoutPartnerLeadQuoteResource
     */
    id?: number;
    /**
     * Name of the quote
     * @type {string}
     * @memberof CheckoutPartnerLeadQuoteResource
     */
    name?: string;
}

/**
 *
 * @export
 * @interface CheckoutPartnerResource
 */
export interface CheckoutPartnerResource {
    /**
     * Partner id.
     * @type {number}
     * @memberof CheckoutPartnerResource
     */
    id?: number;
    /**
     * Partner company name
     * @type {string}
     * @memberof CheckoutPartnerResource
     */
    companyName?: string;
}

/**
 *
 * @export
 * @interface CheckoutPartnerUserResource
 */
export interface CheckoutPartnerUserResource {
    /**
     * Employee id.
     * @type {number}
     * @memberof CheckoutPartnerUserResource
     */
    id?: number;
    /**
     * Employee First Name
     * @type {string}
     * @memberof CheckoutPartnerUserResource
     */
    firstName?: string;
    /**
     * Employee Last Name
     * @type {string}
     * @memberof CheckoutPartnerUserResource
     */
    lastName?: string;
}

/**
 *
 * @export
 * @interface CheckoutPaymentEventResource
 */
export interface CheckoutPaymentEventResource {
    /**
     * payment event identifier
     * @type {string}
     * @memberof CheckoutPaymentEventResource
     */
    identifier?: string;
    /**
     * callback for
     * @type {string}
     * @memberof CheckoutPaymentEventResource
     */
    callback?: string;
    /**
     *
     * @type {CheckoutPaymentProviderResource}
     * @memberof CheckoutPaymentEventResource
     */
    provider?: CheckoutPaymentProviderResource;
    /**
     *
     * @type {AmountResource}
     * @memberof CheckoutPaymentEventResource
     */
    fee?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof CheckoutPaymentEventResource
     */
    vatAmount?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof CheckoutPaymentEventResource
     */
    priceInclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof CheckoutPaymentEventResource
     */
    priceExclVat?: AmountResource;
}

/**
 *
 * @export
 * @interface CheckoutPaymentInstallmentResource
 */
export interface CheckoutPaymentInstallmentResource {
    /**
     *
     * @type {string}
     * @memberof CheckoutPaymentInstallmentResource
     */
    type?: CheckoutPaymentInstallmentResource.TypeEnum;
    /**
     *
     * @type {AmountResource}
     * @memberof CheckoutPaymentInstallmentResource
     */
    fee?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof CheckoutPaymentInstallmentResource
     */
    vatAmount?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof CheckoutPaymentInstallmentResource
     */
    priceInclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof CheckoutPaymentInstallmentResource
     */
    priceExclVat?: AmountResource;
}

/**
 * @export
 * @namespace CheckoutPaymentInstallmentResource
 */
export namespace CheckoutPaymentInstallmentResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Unknown = <any> 'unknown',
        Discount = <any> 'discount',
        Payment = <any> 'payment',
        UpFront = <any> 'up_front',
        OnDelivery = <any> 'on_delivery',
        Recurring = <any> 'recurring'
    }
}

/**
 *
 * @export
 * @interface CheckoutPaymentProviderResource
 */
export interface CheckoutPaymentProviderResource {
    /**
     * provider id
     * @type {number}
     * @memberof CheckoutPaymentProviderResource
     */
    id?: number;
    /**
     * Identifier for Provider
     * @type {string}
     * @memberof CheckoutPaymentProviderResource
     */
    identifier?: string;
    /**
     * List of values exposed for the given provider
     * @type {string}
     * @memberof CheckoutPaymentProviderResource
     */
    value?: string;
    /**
     * Geo Code for payment provider
     * @type {string}
     * @memberof CheckoutPaymentProviderResource
     */
    geoCode?: string;
}

/**
 *
 * @export
 * @interface CheckoutPaymentResource
 */
export interface CheckoutPaymentResource {
    /**
     *
     * @type {Array<CheckoutPaymentInstallmentResource>}
     * @memberof CheckoutPaymentResource
     */
    installments?: Array<CheckoutPaymentInstallmentResource>;
    /**
     *
     * @type {Array<CheckoutPaymentEventResource>}
     * @memberof CheckoutPaymentResource
     */
    events?: Array<CheckoutPaymentEventResource>;
}

/**
 *
 * @export
 * @interface CheckoutResource
 */
export interface CheckoutResource {
    /**
     * Client id.
     * @type {string}
     * @memberof CheckoutResource
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof CheckoutResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof CheckoutResource
     */
    updatedAt?: string;
    /**
     *
     * @type {string}
     * @memberof CheckoutResource
     */
    geoCode?: string;
    /**
     *
     * @type {string}
     * @memberof CheckoutResource
     */
    status?: CheckoutResource.StatusEnum;
    /**
     *
     * @type {string}
     * @memberof CheckoutResource
     */
    scope?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof CheckoutResource
     */
    validationSummary?: Array<CheckoutResource.ValidationSummaryEnum>;
    /**
     *
     * @type {CheckoutPaymentResource}
     * @memberof CheckoutResource
     */
    payments?: CheckoutPaymentResource;
    /**
     *
     * @type {CheckoutConsumerResource}
     * @memberof CheckoutResource
     */
    creator?: CheckoutConsumerResource;
    /**
     *
     * @type {CheckoutConsumerResource}
     * @memberof CheckoutResource
     */
    buyer?: CheckoutConsumerResource;
    /**
     *
     * @type {Array<CheckoutLineResource>}
     * @memberof CheckoutResource
     */
    lines?: Array<CheckoutLineResource>;
    /**
     *
     * @type {AmountResource}
     * @memberof CheckoutResource
     */
    totalVatAmount?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof CheckoutResource
     */
    totalPriceInclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof CheckoutResource
     */
    totalPriceExclVat?: AmountResource;
}

/**
 * @export
 * @namespace CheckoutResource
 */
export namespace CheckoutResource {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Unknown = <any> 'unknown',
        Created = <any> 'created',
        Invalid = <any> 'invalid',
        Valid = <any> 'valid',
        InProgress = <any> 'in_progress',
        PaymentInProgress = <any> 'payment_in_progress',
        Paid = <any> 'paid',
        Failed = <any> 'failed',
        Completed = <any> 'completed'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum ValidationSummaryEnum {
        Unknown = <any> 'unknown',
        MissingBuyer = <any> 'missing_buyer',
        NoCheckoutLines = <any> 'no_checkout_lines',
        InvalidItem = <any> 'invalid_item',
        InvalidQuantity = <any> 'invalid_quantity',
        ItemNotInStock = <any> 'item_not_in_stock',
        ItemAlreadyPurchased = <any> 'item_already_purchased',
        InvalidChainedItem = <any> 'invalid_chained_item',
        MoreThanOneSeller = <any> 'more_than_one_seller'
    }
}

/**
 *
 * @export
 * @interface CheckoutResult
 */
export interface CheckoutResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof CheckoutResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof CheckoutResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof CheckoutResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof CheckoutResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<CheckoutResource>}
     * @memberof CheckoutResult
     */
    data?: Array<CheckoutResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof CheckoutResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface ClientAllocationSuggestionActionResource
 */
export interface ClientAllocationSuggestionActionResource {
    /**
     *
     * @type {string}
     * @memberof ClientAllocationSuggestionActionResource
     */
    action?: ClientAllocationSuggestionActionResource.ActionEnum;
    /**
     *
     * @type {Array<number>}
     * @memberof ClientAllocationSuggestionActionResource
     */
    allocationSuggestionIds?: Array<number>;
}

/**
 * @export
 * @namespace ClientAllocationSuggestionActionResource
 */
export namespace ClientAllocationSuggestionActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        Unknown = <any> 'unknown',
        AcceptLeadPartnerAllocationSuggestions = <any> 'accept_lead_partner_allocation_suggestions'
    }
}

/**
 *
 * @export
 * @interface ClientAllocationSuggestionLeadResource
 */
export interface ClientAllocationSuggestionLeadResource {
    /**
     *
     * @type {number}
     * @memberof ClientAllocationSuggestionLeadResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ClientAllocationSuggestionLeadResource
     */
    title?: string;
}

/**
 *
 * @export
 * @interface ClientAllocationSuggestionPartnerResource
 */
export interface ClientAllocationSuggestionPartnerResource {
    /**
     *
     * @type {number}
     * @memberof ClientAllocationSuggestionPartnerResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ClientAllocationSuggestionPartnerResource
     */
    companyName?: string;
    /**
     *
     * @type {PartnerRatingResource}
     * @memberof ClientAllocationSuggestionPartnerResource
     */
    rating?: PartnerRatingResource;
    /**
     *
     * @type {string}
     * @memberof ClientAllocationSuggestionPartnerResource
     */
    logo?: string;
    /**
     *
     * @type {string}
     * @memberof ClientAllocationSuggestionPartnerResource
     */
    companyInformation?: string;
    /**
     *
     * @type {Array<PartnerBadgeResource>}
     * @memberof ClientAllocationSuggestionPartnerResource
     */
    badges?: Array<PartnerBadgeResource>;
    /**
     *
     * @type {PartnerGeoResource}
     * @memberof ClientAllocationSuggestionPartnerResource
     */
    geo?: PartnerGeoResource;
}

/**
 *
 * @export
 * @interface ClientAllocationSuggestionPartnerUserResource
 */
export interface ClientAllocationSuggestionPartnerUserResource {
    /**
     *
     * @type {number}
     * @memberof ClientAllocationSuggestionPartnerUserResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ClientAllocationSuggestionPartnerUserResource
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof ClientAllocationSuggestionPartnerUserResource
     */
    profilePicture?: string;
}

/**
 *
 * @export
 * @interface ClientAllocationSuggestionResource
 */
export interface ClientAllocationSuggestionResource {
    /**
     *
     * @type {number}
     * @memberof ClientAllocationSuggestionResource
     */
    id?: number;
    /**
     *
     * @type {ClientAllocationSuggestionLeadResource}
     * @memberof ClientAllocationSuggestionResource
     */
    lead?: ClientAllocationSuggestionLeadResource;
    /**
     *
     * @type {ClientAllocationSuggestionPartnerResource}
     * @memberof ClientAllocationSuggestionResource
     */
    partner?: ClientAllocationSuggestionPartnerResource;
    /**
     *
     * @type {string}
     * @memberof ClientAllocationSuggestionResource
     */
    acceptedAt?: string;
    /**
     *
     * @type {ClientAllocationSuggestionPartnerUserResource}
     * @memberof ClientAllocationSuggestionResource
     */
    partnerUser?: ClientAllocationSuggestionPartnerUserResource;
}

/**
 *
 * @export
 * @interface ClientAllocationSuggestionResult
 */
export interface ClientAllocationSuggestionResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof ClientAllocationSuggestionResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof ClientAllocationSuggestionResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof ClientAllocationSuggestionResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof ClientAllocationSuggestionResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<ClientAllocationSuggestionResource>}
     * @memberof ClientAllocationSuggestionResult
     */
    data?: Array<ClientAllocationSuggestionResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof ClientAllocationSuggestionResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface ClientGeoResource
 */
export interface ClientGeoResource {
    /**
     * Code for the given location.
     * @type {string}
     * @memberof ClientGeoResource
     */
    code?: string;
}

/**
 *
 * @export
 * @interface ClientResource
 */
export interface ClientResource {
    /**
     * Client id.
     * @type {number}
     * @memberof ClientResource
     */
    id?: number;
    /**
     * Client token
     * @type {string}
     * @memberof ClientResource
     */
    accessToken?: string;
    /**
     *
     * @type {EmployeeResource}
     * @memberof ClientResource
     */
    assignedEmployee?: EmployeeResource;
    /**
     * Phone number
     * @type {string}
     * @memberof ClientResource
     */
    phone?: string;
    /**
     * Email
     * @type {string}
     * @memberof ClientResource
     */
    email?: string;
    /**
     * Client's leads.
     * @type {Array<LeadResource>}
     * @memberof ClientResource
     */
    leads?: Array<LeadResource>;
    /**
     * First name
     * @type {string}
     * @memberof ClientResource
     */
    firstName?: string;
    /**
     * Last name
     * @type {string}
     * @memberof ClientResource
     */
    lastName?: string;
    /**
     *
     * @type {ClientGeoResource}
     * @memberof ClientResource
     */
    geo?: ClientGeoResource;
}

/**
 *
 * @export
 * @interface ClientSignUpActionResource
 */
export interface ClientSignUpActionResource {
    /**
     *
     * @type {string}
     * @memberof ClientSignUpActionResource
     */
    action?: ClientSignUpActionResource.ActionEnum;
    /**
     *
     * @type {number}
     * @memberof ClientSignUpActionResource
     */
    clientSignUpId?: number;
}

/**
 * @export
 * @namespace ClientSignUpActionResource
 */
export namespace ClientSignUpActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        ConvertToClient = <any> 'convert_to_client'
    }
}

/**
 *
 * @export
 * @interface ClientSignUpResource
 */
export interface ClientSignUpResource {
    /**
     *
     * @type {number}
     * @memberof ClientSignUpResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ClientSignUpResource
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof ClientSignUpResource
     */
    password?: string;
    /**
     *
     * @type {string}
     * @memberof ClientSignUpResource
     */
    phoneNumber?: string;
    /**
     *
     * @type {string}
     * @memberof ClientSignUpResource
     */
    geoCode?: string;
    /**
     *
     * @type {string}
     * @memberof ClientSignUpResource
     */
    signupToken?: string;
    /**
     *
     * @type {string}
     * @memberof ClientSignUpResource
     */
    expiresAt?: string;
    /**
     *
     * @type {string}
     * @memberof ClientSignUpResource
     */
    processedAt?: string;
    /**
     *
     * @type {string}
     * @memberof ClientSignUpResource
     */
    createdAt?: string;
    /**
     *
     * @type {boolean}
     * @memberof ClientSignUpResource
     */
    isAlreadyCreated?: boolean;
}

/**
 *
 * @export
 * @interface CompanyLookUpActionResource
 */
export interface CompanyLookUpActionResource {
    /**
     *
     * @type {string}
     * @memberof CompanyLookUpActionResource
     */
    action?: CompanyLookUpActionResource.ActionEnum;
    /**
     *
     * @type {string}
     * @memberof CompanyLookUpActionResource
     */
    phrase?: string;
    /**
     *
     * @type {string}
     * @memberof CompanyLookUpActionResource
     */
    identifier?: string;
    /**
     *
     * @type {string}
     * @memberof CompanyLookUpActionResource
     */
    geoCode?: string;
}

/**
 * @export
 * @namespace CompanyLookUpActionResource
 */
export namespace CompanyLookUpActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        Search = <any> 'search',
        GetDetails = <any> 'get_details'
    }
}

/**
 *
 * @export
 * @interface CompanyResource
 */
export interface CompanyResource {
    /**
     *
     * @type {string}
     * @memberof CompanyResource
     */
    identifier?: string;
    /**
     *
     * @type {string}
     * @memberof CompanyResource
     */
    companyName?: string;
    /**
     *
     * @type {string}
     * @memberof CompanyResource
     */
    affiliateCampaign?: string;
    /**
     *
     * @type {LeadTypeResource}
     * @memberof CompanyResource
     */
    type?: LeadTypeResource;
    /**
     *
     * @type {LeadGeoResource}
     * @memberof CompanyResource
     */
    geo?: LeadGeoResource;
    /**
     *
     * @type {Array<LeadAttributeResource>}
     * @memberof CompanyResource
     */
    attributes?: Array<LeadAttributeResource>;
    /**
     *
     * @type {SectorResource}
     * @memberof CompanyResource
     */
    sector?: SectorResource;
    /**
     *
     * @type {LeadIndustriesResource}
     * @memberof CompanyResource
     */
    industries?: LeadIndustriesResource;
    /**
     *
     * @type {LeadClientResource}
     * @memberof CompanyResource
     */
    client?: LeadClientResource;
}

/**
 *
 * @export
 * @interface CompanySizeResource
 */
export interface CompanySizeResource {
    /**
     *
     * @type {number}
     * @memberof CompanySizeResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof CompanySizeResource
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof CompanySizeResource
     */
    geoCode?: string;
}

/**
 *
 * @export
 * @interface CompanySizeResult
 */
export interface CompanySizeResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof CompanySizeResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof CompanySizeResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof CompanySizeResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof CompanySizeResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<CompanySizeResource>}
     * @memberof CompanySizeResult
     */
    data?: Array<CompanySizeResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof CompanySizeResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface CompanyTypeResource
 */
export interface CompanyTypeResource {
    /**
     *
     * @type {number}
     * @memberof CompanyTypeResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof CompanyTypeResource
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof CompanyTypeResource
     */
    geoCode?: string;
    /**
     *
     * @type {number}
     * @memberof CompanyTypeResource
     */
    typeGroupId?: number;
}

/**
 *
 * @export
 * @interface CompanyTypeResult
 */
export interface CompanyTypeResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof CompanyTypeResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof CompanyTypeResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof CompanyTypeResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof CompanyTypeResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<CompanyTypeResource>}
     * @memberof CompanyTypeResult
     */
    data?: Array<CompanyTypeResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof CompanyTypeResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface ContactArchiveActionResource
 */
export interface ContactArchiveActionResource {
    /**
     * Contact archive action
     * @type {string}
     * @memberof ContactArchiveActionResource
     */
    action?: ContactArchiveActionResource.ActionEnum;
    /**
     * Contact archive id
     * @type {number}
     * @memberof ContactArchiveActionResource
     */
    contactArchiveId?: number;
}

/**
 * @export
 * @namespace ContactArchiveActionResource
 */
export namespace ContactArchiveActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        Unknown = <any> 'unknown',
        Resend = <any> 'resend'
    }
}

/**
 *
 * @export
 * @interface ContactArchiveChannelResource
 */
export interface ContactArchiveChannelResource {
    /**
     * Type of contacting channel.
     * @type {string}
     * @memberof ContactArchiveChannelResource
     */
    type?: ContactArchiveChannelResource.TypeEnum;
    /**
     * Status of message.
     * @type {string}
     * @memberof ContactArchiveChannelResource
     */
    status?: string;
    /**
     * When the status was updated.
     * @type {string}
     * @memberof ContactArchiveChannelResource
     */
    statusUpdatedAt?: string;
    /**
     * Title of message.
     * @type {string}
     * @memberof ContactArchiveChannelResource
     */
    title?: string;
    /**
     * Email address of the receiver.
     * @type {string}
     * @memberof ContactArchiveChannelResource
     */
    receiverEmailAddress?: string;
    /**
     * Email address of the receiver.
     * @type {string}
     * @memberof ContactArchiveChannelResource
     */
    receiverPhoneNumber?: string;
}

/**
 * @export
 * @namespace ContactArchiveChannelResource
 */
export namespace ContactArchiveChannelResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Unknown = <any> 'unknown',
        Email = <any> 'email',
        TextMessage = <any> 'text_message'
    }
}

/**
 *
 * @export
 * @interface ContactArchiveParticipantResource
 */
export interface ContactArchiveParticipantResource {
    /**
     * Participant ID.
     * @type {number}
     * @memberof ContactArchiveParticipantResource
     */
    id?: number;
    /**
     * Display name.
     * @type {string}
     * @memberof ContactArchiveParticipantResource
     */
    displayName?: string;
}

/**
 *
 * @export
 * @interface ContactArchiveResource
 */
export interface ContactArchiveResource {
    /**
     * Id of correspondence (content_archive).
     * @type {number}
     * @memberof ContactArchiveResource
     */
    id?: number;
    /**
     * Date of creation.
     * @type {string}
     * @memberof ContactArchiveResource
     */
    createdAt?: string;
    /**
     * Date of last update.
     * @type {string}
     * @memberof ContactArchiveResource
     */
    updatedAt?: string;
    /**
     * Date of sending.
     * @type {string}
     * @memberof ContactArchiveResource
     */
    sentAt?: string;
    /**
     * Short geo code.
     * @type {string}
     * @memberof ContactArchiveResource
     */
    geoCode?: string;
    /**
     * Contacting channels
     * @type {Array<ContactArchiveChannelResource>}
     * @memberof ContactArchiveResource
     */
    channels?: Array<ContactArchiveChannelResource>;
    /**
     * Language code.
     * @type {string}
     * @memberof ContactArchiveResource
     */
    lang?: string;
    /**
     *
     * @type {ContactArchiveParticipantResource}
     * @memberof ContactArchiveResource
     */
    employee?: ContactArchiveParticipantResource;
    /**
     *
     * @type {ContactArchiveParticipantResource}
     * @memberof ContactArchiveResource
     */
    lead?: ContactArchiveParticipantResource;
    /**
     *
     * @type {ContactArchiveParticipantResource}
     * @memberof ContactArchiveResource
     */
    partnerUser?: ContactArchiveParticipantResource;
}

/**
 *
 * @export
 * @interface ContactArchiveResult
 */
export interface ContactArchiveResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof ContactArchiveResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof ContactArchiveResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof ContactArchiveResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof ContactArchiveResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<ContactArchiveResource>}
     * @memberof ContactArchiveResult
     */
    data?: Array<ContactArchiveResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof ContactArchiveResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface ContentPageFieldResource
 */
export interface ContentPageFieldResource {
    /**
     * Id for the Lead.
     * @type {number}
     * @memberof ContentPageFieldResource
     */
    id?: number;
    /**
     * Date the Lead was created.
     * @type {string}
     * @memberof ContentPageFieldResource
     */
    createdAt?: string;
    /**
     * Date the Lead was updated.
     * @type {string}
     * @memberof ContentPageFieldResource
     */
    updatedAt?: string;
    /**
     * Name of the given field
     * @type {string}
     * @memberof ContentPageFieldResource
     */
    name?: string;
    /**
     * The contents of the field
     * @type {string}
     * @memberof ContentPageFieldResource
     */
    value?: string;
    /**
     * What the field is used for
     * @type {string}
     * @memberof ContentPageFieldResource
     */
    description?: string;
}

/**
 *
 * @export
 * @interface ContentPageResource
 */
export interface ContentPageResource {
    /**
     * Id for the Lead.
     * @type {number}
     * @memberof ContentPageResource
     */
    id?: number;
    /**
     * Date the Lead was created.
     * @type {string}
     * @memberof ContentPageResource
     */
    createdAt?: string;
    /**
     * Date the Lead was updated.
     * @type {string}
     * @memberof ContentPageResource
     */
    updatedAt?: string;
    /**
     *
     * @type {ContentPageTypeResource}
     * @memberof ContentPageResource
     */
    type?: ContentPageTypeResource;
    /**
     * Name of the page
     * @type {string}
     * @memberof ContentPageResource
     */
    name?: string;
    /**
     * Letter used to identify the category of the page
     * @type {string}
     * @memberof ContentPageResource
     */
    letter?: string;
    /**
     * Slug to prefix the url
     * @type {string}
     * @memberof ContentPageResource
     */
    slug?: string;
    /**
     * The different content fields for the page
     * @type {Array<ContentPageFieldResource>}
     * @memberof ContentPageResource
     */
    fields?: Array<ContentPageFieldResource>;
    /**
     * Geo code for the location of the page
     * @type {string}
     * @memberof ContentPageResource
     */
    geoCode?: string;
}

/**
 *
 * @export
 * @interface ContentPageResult
 */
export interface ContentPageResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof ContentPageResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof ContentPageResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof ContentPageResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof ContentPageResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<ContentPageResource>}
     * @memberof ContentPageResult
     */
    data?: Array<ContentPageResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof ContentPageResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface ContentPageTypeResource
 */
export interface ContentPageTypeResource {
    /**
     * Id for the Lead.
     * @type {number}
     * @memberof ContentPageTypeResource
     */
    id?: number;
    /**
     * Date the Lead was created.
     * @type {string}
     * @memberof ContentPageTypeResource
     */
    createdAt?: string;
    /**
     * Date the Lead was updated.
     * @type {string}
     * @memberof ContentPageTypeResource
     */
    updatedAt?: string;
}

/**
 *
 * @export
 * @interface ContentPageTypeResult
 */
export interface ContentPageTypeResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof ContentPageTypeResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof ContentPageTypeResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof ContentPageTypeResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof ContentPageTypeResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<ContentPageTypeResource>}
     * @memberof ContentPageTypeResult
     */
    data?: Array<ContentPageTypeResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof ContentPageTypeResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface ContractBusinessModelParametersResource
 */
export interface ContractBusinessModelParametersResource {
    /**
     * When the quota or limit becomes effective.
     * @type {string}
     * @memberof ContractBusinessModelParametersResource
     */
    effectiveAt?: string;
    /**
     *
     * @type {AmountResource}
     * @memberof ContractBusinessModelParametersResource
     */
    price?: AmountResource;
    /**
     * Count of allocations for the quota or limit.
     * @type {number}
     * @memberof ContractBusinessModelParametersResource
     */
    allocationCount?: number;
}

/**
 *
 * @export
 * @interface ContractBusinessModelResource
 */
export interface ContractBusinessModelResource {
    /**
     * Identifier of the business model.
     * @type {string}
     * @memberof ContractBusinessModelResource
     */
    identifier?: ContractBusinessModelResource.IdentifierEnum;
    /**
     *
     * @type {ContractBusinessModelParametersResource}
     * @memberof ContractBusinessModelResource
     */
    parameters?: ContractBusinessModelParametersResource;
}

/**
 * @export
 * @namespace ContractBusinessModelResource
 */
export namespace ContractBusinessModelResource {
    /**
     * @export
     * @enum {string}
     */
    export enum IdentifierEnum {
        Unknown = <any> 'unknown',
        LeadBudget = <any> 'lead_budget',
        PayPerSpot = <any> 'pay_per_spot',
        Exclusive = <any> 'exclusive'
    }
}

/**
 *
 * @export
 * @interface ContractComponentResource
 */
export interface ContractComponentResource {
    /**
     * ID of the component.
     * @type {number}
     * @memberof ContractComponentResource
     */
    id?: number;
    /**
     * Display name of the compnent.
     * @type {string}
     * @memberof ContractComponentResource
     */
    name?: string;
}

/**
 *
 * @export
 * @interface ContractPartnerBusinessUnitResource
 */
export interface ContractPartnerBusinessUnitResource {
    /**
     * Primary ID of the business unit.
     * @type {number}
     * @memberof ContractPartnerBusinessUnitResource
     */
    id?: number;
    /**
     * Is this business unit created, updated or deleted?
     * @type {string}
     * @memberof ContractPartnerBusinessUnitResource
     */
    action?: ContractPartnerBusinessUnitResource.ActionEnum;
    /**
     *
     * @type {ContractComponentResource}
     * @memberof ContractPartnerBusinessUnitResource
     */
    partnerUser?: ContractComponentResource;
    /**
     * Partner business unit grouping ID.
     * @type {number}
     * @memberof ContractPartnerBusinessUnitResource
     */
    partnerBusinessUnitGroupingId?: number;
    /**
     * Does the business unit accept digial leads?
     * @type {boolean}
     * @memberof ContractPartnerBusinessUnitResource
     */
    disableDigitalLeads?: boolean;
    /**
     * Display name.
     * @type {string}
     * @memberof ContractPartnerBusinessUnitResource
     */
    displayName?: string;
    /**
     *
     * @type {PartnerBusinessUnitGeoResource}
     * @memberof ContractPartnerBusinessUnitResource
     */
    geo?: PartnerBusinessUnitGeoResource;
    /**
     *
     * @type {PartnerBusinessUnitRevenueRangeResource}
     * @memberof ContractPartnerBusinessUnitResource
     */
    revenues?: PartnerBusinessUnitRevenueRangeResource;
    /**
     * Desired sectors.
     * @type {Array<SectorResource>}
     * @memberof ContractPartnerBusinessUnitResource
     */
    sectors?: Array<SectorResource>;
    /**
     * Desired industries.
     * @type {Array<IndustryResource>}
     * @memberof ContractPartnerBusinessUnitResource
     */
    industries?: Array<IndustryResource>;
    /**
     * Desired client types.
     * @type {Array<LeadTypeResource>}
     * @memberof ContractPartnerBusinessUnitResource
     */
    clientTypes?: Array<LeadTypeResource>;
    /**
     * Desired geo regions.
     * @type {Array<PartnerBusinessUnitSegmentationGeoRegionResource>}
     * @memberof ContractPartnerBusinessUnitResource
     */
    geoRegions?: Array<PartnerBusinessUnitSegmentationGeoRegionResource>;
    /**
     * Business models to be applied to the business unit.
     * @type {Array<ContractBusinessModelResource>}
     * @memberof ContractPartnerBusinessUnitResource
     */
    businessModels?: Array<ContractBusinessModelResource>;
}

/**
 * @export
 * @namespace ContractPartnerBusinessUnitResource
 */
export namespace ContractPartnerBusinessUnitResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        Unknown = <any> 'unknown',
        Create = <any> 'create',
        Update = <any> 'update',
        Delete = <any> 'delete',
        ContinueUnchanged = <any> 'continue_unchanged'
    }
}

/**
 *
 * @export
 * @interface ConversationActionResource
 */
export interface ConversationActionResource {
    /**
     * Conversation action
     * @type {string}
     * @memberof ConversationActionResource
     */
    action?: ConversationActionResource.ActionEnum;
}

/**
 * @export
 * @namespace ConversationActionResource
 */
export namespace ConversationActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        Unknown = <any> 'unknown',
        MarkAllAsRead = <any> 'mark_all_as_read'
    }
}

/**
 *
 * @export
 * @interface ConversationResource
 */
export interface ConversationResource {
    /**
     *
     * @type {number}
     * @memberof ConversationResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ConversationResource
     */
    subject?: string;
    /**
     *
     * @type {Array<ParticipantResource>}
     * @memberof ConversationResource
     */
    participants?: Array<ParticipantResource>;
    /**
     *
     * @type {Array<MessageResource>}
     * @memberof ConversationResource
     */
    messages?: Array<MessageResource>;
    /**
     *
     * @type {Array<AttachmentResource>}
     * @memberof ConversationResource
     */
    attachments?: Array<AttachmentResource>;
    /**
     *
     * @type {number}
     * @memberof ConversationResource
     */
    projectId?: number;
    /**
     *
     * @type {string}
     * @memberof ConversationResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof ConversationResource
     */
    updatedAt?: string;
}

/**
 *
 * @export
 * @interface ConversationResult
 */
export interface ConversationResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof ConversationResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof ConversationResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof ConversationResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof ConversationResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<ConversationResource>}
     * @memberof ConversationResult
     */
    data?: Array<ConversationResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof ConversationResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface CreditSystemActionResource
 */
export interface CreditSystemActionResource {
    /**
     *
     * @type {string}
     * @memberof CreditSystemActionResource
     */
    action?: CreditSystemActionResource.ActionEnum;
    /**
     *
     * @type {number}
     * @memberof CreditSystemActionResource
     */
    leadId?: number;
    /**
     *
     * @type {number}
     * @memberof CreditSystemActionResource
     */
    partnerId?: number;
}

/**
 * @export
 * @namespace CreditSystemActionResource
 */
export namespace CreditSystemActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        OpenQuote = <any> 'open_quote',
        RevealPhoneNumber = <any> 'reveal_phone_number',
        CallPartner = <any> 'call_partner',
        RequestMeeting = <any> 'request_meeting',
        AcceptQuote = <any> 'accept_quote'
    }
}

/**
 *
 * @export
 * @interface CurrencyResource
 */
export interface CurrencyResource {
    /**
     * Symbol used to shorthand the currencies name
     * @type {string}
     * @memberof CurrencyResource
     */
    symbol?: string;
    /**
     * Code used to identify the given currency
     * @type {string}
     * @memberof CurrencyResource
     */
    code?: string;
}

/**
 *
 * @export
 * @interface DataFieldGeoResource
 */
export interface DataFieldGeoResource {
    /**
     * Code for the given location, e.g. 'dk'
     * @type {string}
     * @memberof DataFieldGeoResource
     */
    code?: string;
}

/**
 *
 * @export
 * @interface DataFieldResource
 */
export interface DataFieldResource {
    /**
     *
     * @type {number}
     * @memberof DataFieldResource
     */
    id?: number;
    /**
     *
     * @type {DataFieldGeoResource}
     * @memberof DataFieldResource
     */
    geo?: DataFieldGeoResource;
    /**
     *
     * @type {string}
     * @memberof DataFieldResource
     */
    identifier?: string;
    /**
     *
     * @type {string}
     * @memberof DataFieldResource
     */
    label?: string;
    /**
     *
     * @type {string}
     * @memberof DataFieldResource
     */
    type?: DataFieldResource.TypeEnum;
    /**
     *
     * @type {boolean}
     * @memberof DataFieldResource
     */
    required?: boolean;
    /**
     *
     * @type {number}
     * @memberof DataFieldResource
     */
    priority?: number;
    /**
     *
     * @type {boolean}
     * @memberof DataFieldResource
     */
    isInternal?: boolean;
    /**
     *
     * @type {Array<DataFieldValueResource>}
     * @memberof DataFieldResource
     */
    values?: Array<DataFieldValueResource>;
    /**
     *
     * @type {boolean}
     * @memberof DataFieldResource
     */
    isActive?: boolean;
    /**
     *
     * @type {string}
     * @memberof DataFieldResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof DataFieldResource
     */
    updatedAt?: string;
    /**
     *
     * @type {string}
     * @memberof DataFieldResource
     */
    category?: DataFieldResource.CategoryEnum;
    /**
     *
     * @type {string}
     * @memberof DataFieldResource
     */
    tag?: string;
    /**
     *
     * @type {boolean}
     * @memberof DataFieldResource
     */
    isMultiple?: boolean;
}

/**
 * @export
 * @namespace DataFieldResource
 */
export namespace DataFieldResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Unknown = <any> 'unknown',
        Text = <any> 'text',
        Number = <any> 'number',
        Checkbox = <any> 'checkbox',
        Textbox = <any> 'textbox',
        Dropdown = <any> 'dropdown'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum CategoryEnum {
        Unknown = <any> 'unknown',
        Personality = <any> 'personality',
        Requirement = <any> 'requirement',
        Value = <any> 'value'
    }
}

/**
 *
 * @export
 * @interface DataFieldResult
 */
export interface DataFieldResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof DataFieldResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof DataFieldResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof DataFieldResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof DataFieldResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<DataFieldResource>}
     * @memberof DataFieldResult
     */
    data?: Array<DataFieldResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof DataFieldResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface DataFieldValueResource
 */
export interface DataFieldValueResource {
    /**
     * Key.
     * @type {string}
     * @memberof DataFieldValueResource
     */
    id?: string;
    /**
     * Value.
     * @type {string}
     * @memberof DataFieldValueResource
     */
    title?: string;
}

/**
 *
 * @export
 * @interface DateSpanResource
 */
export interface DateSpanResource {
    /**
     * Start date of the date span.
     * @type {string}
     * @memberof DateSpanResource
     */
    startsAt?: string;
    /**
     * End date of the date span.
     * @type {string}
     * @memberof DateSpanResource
     */
    endsAt?: string;
}

/**
 *
 * @export
 * @interface EmployeeActionResource
 */
export interface EmployeeActionResource {
    /**
     * Action identifier.
     * @type {string}
     * @memberof EmployeeActionResource
     */
    action?: EmployeeActionResource.ActionEnum;
    /**
     * Eanted geo code.
     * @type {string}
     * @memberof EmployeeActionResource
     */
    wantedGeoCode?: EmployeeActionResource.WantedGeoCodeEnum;
    /**
     * Notification IDs.
     * @type {Array<number>}
     * @memberof EmployeeActionResource
     */
    markAsReadNotificationIds?: Array<number>;
}

/**
 * @export
 * @namespace EmployeeActionResource
 */
export namespace EmployeeActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        Unknown = <any> 'unknown',
        ChangeWantedGeoCode = <any> 'change_wanted_geo_code',
        MarkNotificationsAsRead = <any> 'mark_notifications_as_read'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum WantedGeoCodeEnum {
        Empty = <any> '',
        Dk = <any> 'dk',
        No = <any> 'no',
        De = <any> 'de',
        Se = <any> 'se',
        Uk = <any> 'uk',
        Nl = <any> 'nl',
        Us = <any> 'us',
        Com = <any> 'com',
        Ascending = <any> 'ascending',
        Descending = <any> 'descending'
    }
}

/**
 *
 * @export
 * @interface EmployeeCurrentCallActivityResource
 */
export interface EmployeeCurrentCallActivityResource {
    /**
     * Seconds on phone.
     * @type {number}
     * @memberof EmployeeCurrentCallActivityResource
     */
    secondsOnPhone?: number;
    /**
     * Incoming calls.
     * @type {number}
     * @memberof EmployeeCurrentCallActivityResource
     */
    incomingCallCount?: number;
    /**
     * Outgoing calls.
     * @type {number}
     * @memberof EmployeeCurrentCallActivityResource
     */
    outgoingCallCount?: number;
}

/**
 *
 * @export
 * @interface EmployeeKpiResource
 */
export interface EmployeeKpiResource {
    /**
     * Employee ID.
     * @type {number}
     * @memberof EmployeeKpiResource
     */
    employeeId?: number;
    /**
     * Employee's name.
     * @type {string}
     * @memberof EmployeeKpiResource
     */
    name?: string;
    /**
     * Employee's image URL.
     * @type {string}
     * @memberof EmployeeKpiResource
     */
    image?: string;
    /**
     *
     * @type {KpiResource}
     * @memberof EmployeeKpiResource
     */
    kpis?: KpiResource;
}

/**
 *
 * @export
 * @interface EmployeeKpiResult
 */
export interface EmployeeKpiResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof EmployeeKpiResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof EmployeeKpiResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof EmployeeKpiResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof EmployeeKpiResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<EmployeeKpiResource>}
     * @memberof EmployeeKpiResult
     */
    data?: Array<EmployeeKpiResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof EmployeeKpiResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface EmployeeLeadPredictionResource
 */
export interface EmployeeLeadPredictionResource {
    /**
     * Lead the predictions are for.
     * @type {number}
     * @memberof EmployeeLeadPredictionResource
     */
    leadId?: number;
    /**
     * Employee who made the prediction.
     * @type {number}
     * @memberof EmployeeLeadPredictionResource
     */
    employeeId?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof EmployeeLeadPredictionResource
     */
    quoteFee?: AmountResource;
    /**
     * Prediction of when the match will be made by.
     * @type {string}
     * @memberof EmployeeLeadPredictionResource
     */
    matchBy?: string;
    /**
     * Estimate of probability that a match will be made.
     * @type {number}
     * @memberof EmployeeLeadPredictionResource
     */
    probabilityOfMatch?: number;
}

/**
 *
 * @export
 * @interface EmployeeNotificationResource
 */
export interface EmployeeNotificationResource {
    /**
     * Notification ID.
     * @type {number}
     * @memberof EmployeeNotificationResource
     */
    id?: number;
    /**
     * Notification message.
     * @type {string}
     * @memberof EmployeeNotificationResource
     */
    message?: string;
    /**
     * When the notification was seen.
     * @type {string}
     * @memberof EmployeeNotificationResource
     */
    seenAt?: string;
}

/**
 *
 * @export
 * @interface EmployeeNotificationResult
 */
export interface EmployeeNotificationResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof EmployeeNotificationResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof EmployeeNotificationResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof EmployeeNotificationResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof EmployeeNotificationResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<EmployeeNotificationResource>}
     * @memberof EmployeeNotificationResult
     */
    data?: Array<EmployeeNotificationResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof EmployeeNotificationResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface EmployeeResource
 */
export interface EmployeeResource {
    /**
     * Id for the Employee.
     * @type {number}
     * @memberof EmployeeResource
     */
    id?: number;
    /**
     * Sort the employee.
     * @type {string}
     * @memberof EmployeeResource
     */
    sort?: string;
    /**
     * Employees name.
     * @type {string}
     * @memberof EmployeeResource
     */
    name?: string;
    /**
     * Title for the employee.
     * @type {string}
     * @memberof EmployeeResource
     */
    title?: string;
    /**
     * Job title shown to clients and partners.
     * @type {string}
     * @memberof EmployeeResource
     */
    externalJobTitle?: string;
    /**
     * Location for the employee.
     * @type {string}
     * @memberof EmployeeResource
     */
    geoCode?: string;
    /**
     * Wanted geo code.
     * @type {string}
     * @memberof EmployeeResource
     */
    wantedGeoCode?: string;
    /**
     * Photo of the employee.
     * @type {string}
     * @memberof EmployeeResource
     */
    image?: string;
    /**
     * Email for the employee.
     * @type {string}
     * @memberof EmployeeResource
     */
    email?: string;
    /**
     * Work Phone Number for the employee.
     * @type {string}
     * @memberof EmployeeResource
     */
    phoneWork?: string;
    /**
     * Private Phone Number for employee.
     * @type {string}
     * @memberof EmployeeResource
     */
    phonePrivate?: string;
    /**
     *
     * @type {EmployeeSignatureResource}
     * @memberof EmployeeResource
     */
    signature?: EmployeeSignatureResource;
    /**
     * The list of roles that the employee is member of.
     * @type {Array<EmployeeRoleResource>}
     * @memberof EmployeeResource
     */
    roles?: Array<EmployeeRoleResource>;
    /**
     * Employee Initials.
     * @type {string}
     * @memberof EmployeeResource
     */
    initials?: string;
    /**
     * Is the Employee active ?
     * @type {boolean}
     * @memberof EmployeeResource
     */
    isActive?: boolean;
    /**
     *
     * @type {EmployeeCurrentCallActivityResource}
     * @memberof EmployeeResource
     */
    currentCallActivity?: EmployeeCurrentCallActivityResource;
    /**
     *
     * @type {EmployeeSocialResource}
     * @memberof EmployeeResource
     */
    social?: EmployeeSocialResource;
    /**
     *
     * @type {string}
     * @memberof EmployeeResource
     */
    profilePicture?: string;
}

/**
 *
 * @export
 * @interface EmployeeResult
 */
export interface EmployeeResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof EmployeeResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof EmployeeResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof EmployeeResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof EmployeeResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<EmployeeResource>}
     * @memberof EmployeeResult
     */
    data?: Array<EmployeeResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof EmployeeResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface EmployeeRoleResource
 */
export interface EmployeeRoleResource {
    /**
     * Id for the Employee Role.
     * @type {number}
     * @memberof EmployeeRoleResource
     */
    id?: number;
    /**
     * Name of the Employee Role.
     * @type {string}
     * @memberof EmployeeRoleResource
     */
    title?: string;
}

/**
 *
 * @export
 * @interface EmployeeSignatureResource
 */
export interface EmployeeSignatureResource {
    /**
     * Address.
     * @type {string}
     * @memberof EmployeeSignatureResource
     */
    address?: string;
    /**
     * Zip code.
     * @type {string}
     * @memberof EmployeeSignatureResource
     */
    zipCode?: string;
    /**
     * City.
     * @type {string}
     * @memberof EmployeeSignatureResource
     */
    city?: string;
    /**
     * Phone Number.
     * @type {string}
     * @memberof EmployeeSignatureResource
     */
    phoneNumber?: string;
    /**
     * Cellphone number.
     * @type {string}
     * @memberof EmployeeSignatureResource
     */
    cellPhoneNumber?: string;
    /**
     * Web Address.
     * @type {string}
     * @memberof EmployeeSignatureResource
     */
    webAddress?: string;
    /**
     * Blog description.
     * @type {string}
     * @memberof EmployeeSignatureResource
     */
    blogDescription?: string;
}

/**
 *
 * @export
 * @interface EmployeeSocialResource
 */
export interface EmployeeSocialResource {
    /**
     * Facebook.
     * @type {string}
     * @memberof EmployeeSocialResource
     */
    facebook?: string;
    /**
     * Linkedin.
     * @type {string}
     * @memberof EmployeeSocialResource
     */
    linkedin?: string;
    /**
     * Twitter.
     * @type {string}
     * @memberof EmployeeSocialResource
     */
    twitter?: string;
    /**
     * Xing.
     * @type {string}
     * @memberof EmployeeSocialResource
     */
    xing?: string;
}

/**
 *
 * @export
 * @interface FeedbackResource
 */
export interface FeedbackResource {
    /**
     *
     * @type {string}
     * @memberof FeedbackResource
     */
    type?: FeedbackResource.TypeEnum;
    /**
     *
     * @type {Array<string>}
     * @memberof FeedbackResource
     */
    body?: Array<string>;
}

/**
 * @export
 * @namespace FeedbackResource
 */
export namespace FeedbackResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        ClientExitPage = <any> 'client_exit_page'
    }
}

/**
 *
 * @export
 * @interface FormQuestionAnswerResource
 */
export interface FormQuestionAnswerResource {
    /**
     *
     * @type {string}
     * @memberof FormQuestionAnswerResource
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof FormQuestionAnswerResource
     */
    label?: string;
}

/**
 *
 * @export
 * @interface FormQuestionResource
 */
export interface FormQuestionResource {
    /**
     *
     * @type {string}
     * @memberof FormQuestionResource
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof FormQuestionResource
     */
    geoCode?: string;
    /**
     *
     * @type {string}
     * @memberof FormQuestionResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FormQuestionResource
     */
    updatedAt?: string;
    /**
     *
     * @type {string}
     * @memberof FormQuestionResource
     */
    archivedAt?: string;
    /**
     *
     * @type {string}
     * @memberof FormQuestionResource
     */
    identifier?: string;
    /**
     *
     * @type {string}
     * @memberof FormQuestionResource
     */
    displayText?: string;
    /**
     *
     * @type {string}
     * @memberof FormQuestionResource
     */
    isRequired?: string;
    /**
     *
     * @type {string}
     * @memberof FormQuestionResource
     */
    answerType?: FormQuestionResource.AnswerTypeEnum;
    /**
     *
     * @type {Array<FormQuestionAnswerResource>}
     * @memberof FormQuestionResource
     */
    possibleAnswers?: Array<FormQuestionAnswerResource>;
    /**
     *
     * @type {number}
     * @memberof FormQuestionResource
     */
    parentQuestionId?: number;
    /**
     *
     * @type {number}
     * @memberof FormQuestionResource
     */
    order?: number;
    /**
     *
     * @type {string}
     * @memberof FormQuestionResource
     */
    placeholder?: string;
}

/**
 * @export
 * @namespace FormQuestionResource
 */
export namespace FormQuestionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum AnswerTypeEnum {
        Text = <any> 'text',
        Integer = <any> 'integer',
        Float = <any> 'float',
        Checkbox = <any> 'checkbox',
        Select = <any> 'select',
        Multiselect = <any> 'multiselect'
    }
}

/**
 *
 * @export
 * @interface FormResource
 */
export interface FormResource {
    /**
     *
     * @type {string}
     * @memberof FormResource
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof FormResource
     */
    geoCode?: string;
    /**
     *
     * @type {string}
     * @memberof FormResource
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof FormResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FormResource
     */
    updatedAt?: string;
    /**
     *
     * @type {string}
     * @memberof FormResource
     */
    archivedAt?: string;
    /**
     *
     * @type {number}
     * @memberof FormResource
     */
    industryId?: number;
    /**
     *
     * @type {number}
     * @memberof FormResource
     */
    typeId?: number;
    /**
     *
     * @type {Array<FormQuestionResource>}
     * @memberof FormResource
     */
    questions?: Array<FormQuestionResource>;
}

/**
 *
 * @export
 * @interface FormResponseAnswerResource
 */
export interface FormResponseAnswerResource {
    /**
     *
     * @type {number}
     * @memberof FormResponseAnswerResource
     */
    questionId?: number;
    /**
     *
     * @type {string}
     * @memberof FormResponseAnswerResource
     */
    answer?: string;
}

/**
 *
 * @export
 * @interface FormResponseResource
 */
export interface FormResponseResource {
    /**
     *
     * @type {string}
     * @memberof FormResponseResource
     */
    id?: string;
    /**
     *
     * @type {number}
     * @memberof FormResponseResource
     */
    leadId?: number;
    /**
     *
     * @type {Array<FormResponseAnswerResource>}
     * @memberof FormResponseResource
     */
    answers?: Array<FormResponseAnswerResource>;
}

/**
 *
 * @export
 * @interface FormResult
 */
export interface FormResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof FormResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof FormResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof FormResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof FormResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<FormResource>}
     * @memberof FormResult
     */
    data?: Array<FormResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof FormResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface FrontendEmployeeResource
 */
export interface FrontendEmployeeResource {
    /**
     *
     * @type {number}
     * @memberof FrontendEmployeeResource
     */
    id?: number;
    /**
     *
     * @type {EmployeeResource}
     * @memberof FrontendEmployeeResource
     */
    employee?: EmployeeResource;
    /**
     *
     * @type {string}
     * @memberof FrontendEmployeeResource
     */
    geoCode?: string;
    /**
     *
     * @type {string}
     * @memberof FrontendEmployeeResource
     */
    jobTitle?: string;
    /**
     *
     * @type {string}
     * @memberof FrontendEmployeeResource
     */
    jobDescription?: string;
    /**
     *
     * @type {number}
     * @memberof FrontendEmployeeResource
     */
    sort?: number;
}

/**
 *
 * @export
 * @interface FrontendEmployeeResult
 */
export interface FrontendEmployeeResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof FrontendEmployeeResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof FrontendEmployeeResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof FrontendEmployeeResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof FrontendEmployeeResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<FrontendEmployeeResource>}
     * @memberof FrontendEmployeeResult
     */
    data?: Array<FrontendEmployeeResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof FrontendEmployeeResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface FrontendEmployeeTeamResource
 */
export interface FrontendEmployeeTeamResource {
    /**
     *
     * @type {number}
     * @memberof FrontendEmployeeTeamResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FrontendEmployeeTeamResource
     */
    identifier?: string;
    /**
     *
     * @type {number}
     * @memberof FrontendEmployeeTeamResource
     */
    sort?: number;
    /**
     *
     * @type {Array<FrontendEmployeeResource>}
     * @memberof FrontendEmployeeTeamResource
     */
    frontendEmployees?: Array<FrontendEmployeeResource>;
}

/**
 *
 * @export
 * @interface FrontendEmployeeTeamResult
 */
export interface FrontendEmployeeTeamResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof FrontendEmployeeTeamResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof FrontendEmployeeTeamResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof FrontendEmployeeTeamResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof FrontendEmployeeTeamResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<FrontendEmployeeTeamResource>}
     * @memberof FrontendEmployeeTeamResult
     */
    data?: Array<FrontendEmployeeTeamResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof FrontendEmployeeTeamResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface IndustryGroupResource
 */
export interface IndustryGroupResource {
    /**
     *
     * @type {number}
     * @memberof IndustryGroupResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof IndustryGroupResource
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof IndustryGroupResource
     */
    geoCode?: string;
    /**
     *
     * @type {Array<IndustryResource>}
     * @memberof IndustryGroupResource
     */
    industries?: Array<IndustryResource>;
}

/**
 *
 * @export
 * @interface IndustryGroupResult
 */
export interface IndustryGroupResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof IndustryGroupResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof IndustryGroupResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof IndustryGroupResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof IndustryGroupResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<IndustryGroupResource>}
     * @memberof IndustryGroupResult
     */
    data?: Array<IndustryGroupResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof IndustryGroupResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface IndustryResource
 */
export interface IndustryResource {
    /**
     * Id for the industry.
     * @type {number}
     * @memberof IndustryResource
     */
    id?: number;
    /**
     * Added industry code to interface
     * @type {string}
     * @memberof IndustryResource
     */
    code?: string;
    /**
     * Name of the industry.
     * @type {string}
     * @memberof IndustryResource
     */
    name?: string;
    /**
     * Uri frinedly name of the industry.
     * @type {string}
     * @memberof IndustryResource
     */
    slug?: string;
    /**
     * Industry Geo Code. @var string
     * @type {string}
     * @memberof IndustryResource
     */
    geoCode?: string;
    /**
     * Parent industry ID. @var int
     * @type {number}
     * @memberof IndustryResource
     */
    parentIndustryId?: number;
    /**
     * industry identifier
     * @type {string}
     * @memberof IndustryResource
     */
    identifier?: string;
    /**
     * Industry group ID. @var int
     * @type {number}
     * @memberof IndustryResource
     */
    industryGroupId?: number;
}

/**
 *
 * @export
 * @interface IndustryResult
 */
export interface IndustryResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof IndustryResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof IndustryResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof IndustryResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof IndustryResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<IndustryResource>}
     * @memberof IndustryResult
     */
    data?: Array<IndustryResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof IndustryResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface InvoiceLineResource
 */
export interface InvoiceLineResource {
    /**
     * Resource ID.
     * @type {number}
     * @memberof InvoiceLineResource
     */
    id?: number;
    /**
     * Quantity per price.
     * @type {number}
     * @memberof InvoiceLineResource
     */
    quantity?: number;
    /**
     * Name for billable.
     * @type {string}
     * @memberof InvoiceLineResource
     */
    itemName?: string;
    /**
     *
     * @type {AmountResource}
     * @memberof InvoiceLineResource
     */
    itemPrice?: AmountResource;
    /**
     * Var percentage.
     * @type {number}
     * @memberof InvoiceLineResource
     */
    vatPercent?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof InvoiceLineResource
     */
    lineTotalExcludingVat?: AmountResource;
    /**
     * Vat amount.
     * @type {number}
     * @memberof InvoiceLineResource
     */
    vatAmount?: number;
}

/**
 *
 * @export
 * @interface InvoicePartnerResource
 */
export interface InvoicePartnerResource {
    /**
     * Id of the suggested partner.
     * @type {number}
     * @memberof InvoicePartnerResource
     */
    id?: number;
    /**
     * Company name of the suggested partner.
     * @type {string}
     * @memberof InvoicePartnerResource
     */
    companyName?: string;
}

/**
 *
 * @export
 * @interface InvoicePartnerUserResource
 */
export interface InvoicePartnerUserResource {
    /**
     * Id of the suggested partnerUser.
     * @type {number}
     * @memberof InvoicePartnerUserResource
     */
    id?: number;
}

/**
 *
 * @export
 * @interface InvoiceResource
 */
export interface InvoiceResource {
    /**
     * Invoice ID.
     * @type {number}
     * @memberof InvoiceResource
     */
    id?: number;
    /**
     * Parent ID.
     * @type {number}
     * @memberof InvoiceResource
     */
    parentId?: number;
    /**
     * Payment status.
     * @type {string}
     * @memberof InvoiceResource
     */
    status?: string;
    /**
     * Invoice number.
     * @type {number}
     * @memberof InvoiceResource
     */
    invoiceNumber?: number;
    /**
     * Invoice type.
     * @type {string}
     * @memberof InvoiceResource
     */
    type?: string;
    /**
     * geo code for invoice.
     * @type {string}
     * @memberof InvoiceResource
     */
    geoCode?: string;
    /**
     * Invoice created at date.
     * @type {string}
     * @memberof InvoiceResource
     */
    createdAt?: string;
    /**
     * Invoice last update.
     * @type {string}
     * @memberof InvoiceResource
     */
    updatedAt?: string;
    /**
     * Invoice due date.
     * @type {string}
     * @memberof InvoiceResource
     */
    dueAt?: string;
    /**
     * Invoice has been credited at date.
     * @type {string}
     * @memberof InvoiceResource
     */
    creditedAt?: string;
    /**
     * Invoice was paid at date.
     * @type {string}
     * @memberof InvoiceResource
     */
    paidAt?: string;
    /**
     * Invoice created by employee (ID).
     * @type {number}
     * @memberof InvoiceResource
     */
    employeeId?: number;
    /**
     * Invoice amount.
     * @type {string}
     * @memberof InvoiceResource
     */
    amounts?: string;
    /**
     * Invoice for partner ID.
     * @type {number}
     * @memberof InvoiceResource
     */
    partnerId?: number;
    /**
     * Invoice un mutable data.
     * @type {string}
     * @memberof InvoiceResource
     */
    partnerData?: string;
    /**
     * Invoice was created in behalf of another employee.
     * @type {number}
     * @memberof InvoiceResource
     */
    onBehalfOfEmployeeId?: number;
    /**
     * Billing address.
     * @type {string}
     * @memberof InvoiceResource
     */
    address?: string;
    /**
     * Billing city.
     * @type {string}
     * @memberof InvoiceResource
     */
    cityName?: string;
    /**
     * Billing code.
     * @type {string}
     * @memberof InvoiceResource
     */
    code?: string;
    /**
     * Billing post code.
     * @type {string}
     * @memberof InvoiceResource
     */
    zipCode?: string;
    /**
     * Invoice lines.
     * @type {Array<InvoiceLineResource>}
     * @memberof InvoiceResource
     */
    invoiceLines?: Array<InvoiceLineResource>;
    /**
     * Invoice entered in dunning one at.
     * @type {string}
     * @memberof InvoiceResource
     */
    dunning1?: string;
    /**
     * Invoice entered in dunning two at.
     * @type {string}
     * @memberof InvoiceResource
     */
    dunning2?: string;
    /**
     * Invoice entered in dunning three at.
     * @type {string}
     * @memberof InvoiceResource
     */
    dunning3?: string;
    /**
     * Invoice entered in dunning four at.
     * @type {string}
     * @memberof InvoiceResource
     */
    dunning4?: string;
    /**
     * Invoice entered in dunning five at.
     * @type {string}
     * @memberof InvoiceResource
     */
    dunning5?: string;
    /**
     * Invoice entered in dunning six at.
     * @type {string}
     * @memberof InvoiceResource
     */
    dunning6?: string;
}

/**
 *
 * @export
 * @interface InvoiceResult
 */
export interface InvoiceResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof InvoiceResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof InvoiceResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof InvoiceResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof InvoiceResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<InvoiceResource>}
     * @memberof InvoiceResult
     */
    data?: Array<InvoiceResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof InvoiceResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface InvoicingPartnerResource
 */
export interface InvoicingPartnerResource {
    /**
     * ID of the partner.
     * @type {number}
     * @memberof InvoicingPartnerResource
     */
    id?: number;
    /**
     * Company name of the partner.
     * @type {string}
     * @memberof InvoicingPartnerResource
     */
    companyName?: string;
}

/**
 *
 * @export
 * @interface InvoicingResource
 */
export interface InvoicingResource {
    /**
     * When the invoicing is processed into an invoice.
     * @type {string}
     * @memberof InvoicingResource
     */
    processAt?: string;
    /**
     * When the invoicing was processed into an invoice.
     * @type {string}
     * @memberof InvoicingResource
     */
    processedAt?: string;
    /**
     *
     * @type {InvoicingSourceResource}
     * @memberof InvoicingResource
     */
    source?: InvoicingSourceResource;
    /**
     *
     * @type {InvoicingVoucherResource}
     * @memberof InvoicingResource
     */
    voucher?: InvoicingVoucherResource;
    /**
     *
     * @type {AmountResource}
     * @memberof InvoicingResource
     */
    amountExclVat?: AmountResource;
    /**
     *
     * @type {InvoicingPartnerResource}
     * @memberof InvoicingResource
     */
    partner?: InvoicingPartnerResource;
}

/**
 *
 * @export
 * @interface InvoicingResult
 */
export interface InvoicingResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof InvoicingResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof InvoicingResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof InvoicingResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof InvoicingResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<InvoicingResource>}
     * @memberof InvoicingResult
     */
    data?: Array<InvoicingResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof InvoicingResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface InvoicingSourceResource
 */
export interface InvoicingSourceResource {
    /**
     * ID of the source object.
     * @type {number}
     * @memberof InvoicingSourceResource
     */
    id?: number;
    /**
     * Type of the source object.
     * @type {string}
     * @memberof InvoicingSourceResource
     */
    type?: string;
}

/**
 *
 * @export
 * @interface InvoicingVoucherResource
 */
export interface InvoicingVoucherResource {
    /**
     * Voucher generated from this invoicing.
     * @type {number}
     * @memberof InvoicingVoucherResource
     */
    id?: number;
    /**
     * When the invoicing is processed into an invoice.
     * @type {string}
     * @memberof InvoicingVoucherResource
     */
    paidAt?: string;
}

/**
 *
 * @export
 * @interface KPIAmountResource
 */
export interface KPIAmountResource {
    /**
     *
     * @type {AmountResource}
     * @memberof KPIAmountResource
     */
    month?: AmountResource;
    /**
     * Month Count
     * @type {number}
     * @memberof KPIAmountResource
     */
    monthCount?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof KPIAmountResource
     */
    lastMonth?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof KPIAmountResource
     */
    today?: AmountResource;
    /**
     * Today Count
     * @type {number}
     * @memberof KPIAmountResource
     */
    todayCount?: number;
}

/**
 *
 * @export
 * @interface KPICreditsNewSalesResource
 */
export interface KPICreditsNewSalesResource {
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPICreditsNewSalesResource
     */
    actual?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPICreditsNewSalesResource
     */
    actualNet?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPICreditsNewSalesResource
     */
    forecast?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPICreditsNewSalesResource
     */
    target?: KPINumberResource;
}

/**
 *
 * @export
 * @interface KPICreditsRefillsResource
 */
export interface KPICreditsRefillsResource {
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPICreditsRefillsResource
     */
    actual?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPICreditsRefillsResource
     */
    target?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPICreditsRefillsResource
     */
    actualNet?: KPINumberResource;
    /**
     * Target Net
     * @type {string}
     * @memberof KPICreditsRefillsResource
     */
    targetNet?: string;
}

/**
 *
 * @export
 * @interface KPICreditsRefundedResource
 */
export interface KPICreditsRefundedResource {
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPICreditsRefundedResource
     */
    actual?: KPINumberResource;
}

/**
 *
 * @export
 * @interface KPICreditsResource
 */
export interface KPICreditsResource {
    /**
     *
     * @type {KPICreditsNewSalesResource}
     * @memberof KPICreditsResource
     */
    newSales?: KPICreditsNewSalesResource;
    /**
     *
     * @type {KPICreditsRefundedResource}
     * @memberof KPICreditsResource
     */
    refunded?: KPICreditsRefundedResource;
    /**
     *
     * @type {KPICreditsRefillsResource}
     * @memberof KPICreditsResource
     */
    refills?: KPICreditsRefillsResource;
    /**
     *
     * @type {KPICreditsSoldResource}
     * @memberof KPICreditsResource
     */
    sold?: KPICreditsSoldResource;
    /**
     *
     * @type {KPICreditsUsedResource}
     * @memberof KPICreditsResource
     */
    used?: KPICreditsUsedResource;
    /**
     *
     * @type {KPICreditsWithoutRefillResource}
     * @memberof KPICreditsResource
     */
    withoutRefill?: KPICreditsWithoutRefillResource;
}

/**
 *
 * @export
 * @interface KPICreditsSoldResource
 */
export interface KPICreditsSoldResource {
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPICreditsSoldResource
     */
    actual?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPICreditsSoldResource
     */
    actualNet?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPICreditsSoldResource
     */
    target?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPICreditsSoldResource
     */
    forecast?: KPINumberResource;
}

/**
 *
 * @export
 * @interface KPICreditsUsedResource
 */
export interface KPICreditsUsedResource {
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPICreditsUsedResource
     */
    actual?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPICreditsUsedResource
     */
    actualNet?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPICreditsUsedResource
     */
    target?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPICreditsUsedResource
     */
    forecast?: KPINumberResource;
}

/**
 *
 * @export
 * @interface KPICreditsWithoutRefillResource
 */
export interface KPICreditsWithoutRefillResource {
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPICreditsWithoutRefillResource
     */
    actual?: KPINumberResource;
}

/**
 *
 * @export
 * @interface KPILeadsActionTimeResource
 */
export interface KPILeadsActionTimeResource {
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPILeadsActionTimeResource
     */
    actual?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPILeadsActionTimeResource
     */
    actualNet?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPILeadsActionTimeResource
     */
    targetNet?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPILeadsActionTimeResource
     */
    forecastNet?: KPINumberResource;
}

/**
 *
 * @export
 * @interface KPILeadsCCRResource
 */
export interface KPILeadsCCRResource {
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPILeadsCCRResource
     */
    actual?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPILeadsCCRResource
     */
    target?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPILeadsCCRResource
     */
    actualNet?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPILeadsCCRResource
     */
    targetNet?: KPINumberResource;
}

/**
 *
 * @export
 * @interface KPILeadsCompletionRatioResource
 */
export interface KPILeadsCompletionRatioResource {
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPILeadsCompletionRatioResource
     */
    actual?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPILeadsCompletionRatioResource
     */
    target?: KPINumberResource;
}

/**
 *
 * @export
 * @interface KPILeadsCompletionTimeResource
 */
export interface KPILeadsCompletionTimeResource {
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPILeadsCompletionTimeResource
     */
    actual?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPILeadsCompletionTimeResource
     */
    target?: KPINumberResource;
}

/**
 *
 * @export
 * @interface KPILeadsResource
 */
export interface KPILeadsResource {
    /**
     *
     * @type {KPILeadsActionTimeResource}
     * @memberof KPILeadsResource
     */
    actionTime?: KPILeadsActionTimeResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPILeadsResource
     */
    validated?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPILeadsResource
     */
    total?: KPINumberResource;
    /**
     *
     * @type {KPILeadsValidationRatioResource}
     * @memberof KPILeadsResource
     */
    validationRatio?: KPILeadsValidationRatioResource;
    /**
     *
     * @type {KPILeadsCompletionRatioResource}
     * @memberof KPILeadsResource
     */
    completionRatio?: KPILeadsCompletionRatioResource;
    /**
     *
     * @type {KPILeadsCompletionTimeResource}
     * @memberof KPILeadsResource
     */
    completionTime?: KPILeadsCompletionTimeResource;
    /**
     *
     * @type {KPILeadsCCRResource}
     * @memberof KPILeadsResource
     */
    ccr?: KPILeadsCCRResource;
}

/**
 *
 * @export
 * @interface KPILeadsValidationRatioResource
 */
export interface KPILeadsValidationRatioResource {
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPILeadsValidationRatioResource
     */
    actual?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPILeadsValidationRatioResource
     */
    target?: KPINumberResource;
}

/**
 *
 * @export
 * @interface KPIMatchesRatioResource
 */
export interface KPIMatchesRatioResource {
    /**
     * Actual Ratio
     * @type {number}
     * @memberof KPIMatchesRatioResource
     */
    actual?: number;
    /**
     * Target Ratio
     * @type {number}
     * @memberof KPIMatchesRatioResource
     */
    target?: number;
}

/**
 *
 * @export
 * @interface KPIMatchesResource
 */
export interface KPIMatchesResource {
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPIMatchesResource
     */
    actual?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPIMatchesResource
     */
    past?: KPINumberResource;
    /**
     *
     * @type {KPIMatchesRatioResource}
     * @memberof KPIMatchesResource
     */
    ratio?: KPIMatchesRatioResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPIMatchesResource
     */
    quoteMatches?: KPINumberResource;
}

/**
 *
 * @export
 * @interface KPINewSalesAvgPackageSizeResource
 */
export interface KPINewSalesAvgPackageSizeResource {
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPINewSalesAvgPackageSizeResource
     */
    actual?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPINewSalesAvgPackageSizeResource
     */
    target?: KPINumberResource;
}

/**
 *
 * @export
 * @interface KPINewSalesAvgPriceResource
 */
export interface KPINewSalesAvgPriceResource {
    /**
     *
     * @type {KPIAmountResource}
     * @memberof KPINewSalesAvgPriceResource
     */
    actual?: KPIAmountResource;
    /**
     *
     * @type {KPIAmountResource}
     * @memberof KPINewSalesAvgPriceResource
     */
    target?: KPIAmountResource;
}

/**
 *
 * @export
 * @interface KPINewSalesAvgThresholdResource
 */
export interface KPINewSalesAvgThresholdResource {
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPINewSalesAvgThresholdResource
     */
    actual?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPINewSalesAvgThresholdResource
     */
    target?: KPINumberResource;
}

/**
 *
 * @export
 * @interface KPINewSalesResource
 */
export interface KPINewSalesResource {
    /**
     *
     * @type {KPINewSalesAvgPriceResource}
     * @memberof KPINewSalesResource
     */
    avgPrice?: KPINewSalesAvgPriceResource;
    /**
     *
     * @type {KPINewSalesAvgThresholdResource}
     * @memberof KPINewSalesResource
     */
    avgThreshold?: KPINewSalesAvgThresholdResource;
    /**
     *
     * @type {KPINewSalesAvgPackageSizeResource}
     * @memberof KPINewSalesResource
     */
    avgPackageSize?: KPINewSalesAvgPackageSizeResource;
}

/**
 *
 * @export
 * @interface KPINumberResource
 */
export interface KPINumberResource {
    /**
     * Month
     * @type {number}
     * @memberof KPINumberResource
     */
    month?: number;
    /**
     * Month
     * @type {number}
     * @memberof KPINumberResource
     */
    lastMonth?: number;
    /**
     * Today
     * @type {number}
     * @memberof KPINumberResource
     */
    today?: number;
    /**
     * Dictionary Based values
     * @type {Array<number>}
     * @memberof KPINumberResource
     */
    values?: Array<number>;
}

/**
 *
 * @export
 * @interface KPIPartnersAvgCreditsUsedResource
 */
export interface KPIPartnersAvgCreditsUsedResource {
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPIPartnersAvgCreditsUsedResource
     */
    actual?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPIPartnersAvgCreditsUsedResource
     */
    target?: KPINumberResource;
}

/**
 *
 * @export
 * @interface KPIPartnersNewSalesResource
 */
export interface KPIPartnersNewSalesResource {
    /**
     * Actual
     * @type {string}
     * @memberof KPIPartnersNewSalesResource
     */
    actual?: string;
    /**
     * Target
     * @type {string}
     * @memberof KPIPartnersNewSalesResource
     */
    target?: string;
}

/**
 *
 * @export
 * @interface KPIPartnersResource
 */
export interface KPIPartnersResource {
    /**
     *
     * @type {KPIPartnersAvgCreditsUsedResource}
     * @memberof KPIPartnersResource
     */
    avgCreditsUsed?: KPIPartnersAvgCreditsUsedResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPIPartnersResource
     */
    calls?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPIPartnersResource
     */
    churn?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPIPartnersResource
     */
    refills?: KPINumberResource;
    /**
     *
     * @type {KPIPartnersNewSalesResource}
     * @memberof KPIPartnersResource
     */
    newSales?: KPIPartnersNewSalesResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPIPartnersResource
     */
    cancellations?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPIPartnersResource
     */
    satisfactions?: KPINumberResource;
    /**
     *
     * @type {KPIPartnersSuccessRatioResource}
     * @memberof KPIPartnersResource
     */
    successRatio?: KPIPartnersSuccessRatioResource;
    /**
     *
     * @type {KPIPartnersUniqueSpendingResource}
     * @memberof KPIPartnersResource
     */
    uniqueSpending?: KPIPartnersUniqueSpendingResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPIPartnersResource
     */
    activePartnerPackages?: KPINumberResource;
}

/**
 *
 * @export
 * @interface KPIPartnersSuccessRatioResource
 */
export interface KPIPartnersSuccessRatioResource {
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPIPartnersSuccessRatioResource
     */
    actual?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPIPartnersSuccessRatioResource
     */
    target?: KPINumberResource;
}

/**
 *
 * @export
 * @interface KPIPartnersUniqueSpendingResource
 */
export interface KPIPartnersUniqueSpendingResource {
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPIPartnersUniqueSpendingResource
     */
    actual?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPIPartnersUniqueSpendingResource
     */
    target?: KPINumberResource;
}

/**
 *
 * @export
 * @interface KPIRefillsAvgPackageSizeResource
 */
export interface KPIRefillsAvgPackageSizeResource {
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPIRefillsAvgPackageSizeResource
     */
    actual?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPIRefillsAvgPackageSizeResource
     */
    target?: KPINumberResource;
}

/**
 *
 * @export
 * @interface KPIRefillsAvgPriceResource
 */
export interface KPIRefillsAvgPriceResource {
    /**
     *
     * @type {KPIAmountResource}
     * @memberof KPIRefillsAvgPriceResource
     */
    actual?: KPIAmountResource;
    /**
     *
     * @type {KPIAmountResource}
     * @memberof KPIRefillsAvgPriceResource
     */
    target?: KPIAmountResource;
}

/**
 *
 * @export
 * @interface KPIRefillsAvgThresholdResource
 */
export interface KPIRefillsAvgThresholdResource {
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPIRefillsAvgThresholdResource
     */
    actual?: KPINumberResource;
    /**
     *
     * @type {KPINumberResource}
     * @memberof KPIRefillsAvgThresholdResource
     */
    target?: KPINumberResource;
}

/**
 *
 * @export
 * @interface KPIRefillsResource
 */
export interface KPIRefillsResource {
    /**
     *
     * @type {KPIRefillsAvgPriceResource}
     * @memberof KPIRefillsResource
     */
    avgPrice?: KPIRefillsAvgPriceResource;
    /**
     *
     * @type {KPIRefillsAvgThresholdResource}
     * @memberof KPIRefillsResource
     */
    avgThreshold?: KPIRefillsAvgThresholdResource;
    /**
     *
     * @type {KPIRefillsAvgPackageSizeResource}
     * @memberof KPIRefillsResource
     */
    avgPackageSize?: KPIRefillsAvgPackageSizeResource;
}

/**
 *
 * @export
 * @interface KPIResource
 */
export interface KPIResource {
    /**
     * Id for the given kpi
     * @type {string}
     * @memberof KPIResource
     */
    id?: string;
    /**
     * When the kpi was calculated
     * @type {string}
     * @memberof KPIResource
     */
    createdAt?: string;
    /**
     * Geo Location Geo
     * @type {string}
     * @memberof KPIResource
     */
    geoCode?: string;
    /**
     *
     * @type {IndustryResource}
     * @memberof KPIResource
     */
    industry?: IndustryResource;
    /**
     *
     * @type {EmployeeResource}
     * @memberof KPIResource
     */
    employee?: EmployeeResource;
    /**
     *
     * @type {PartnerSuggestResource}
     * @memberof KPIResource
     */
    partner?: PartnerSuggestResource;
    /**
     *
     * @type {KPILeadsResource}
     * @memberof KPIResource
     */
    leads?: KPILeadsResource;
    /**
     *
     * @type {KPICreditsResource}
     * @memberof KPIResource
     */
    credits?: KPICreditsResource;
    /**
     *
     * @type {KPIMatchesResource}
     * @memberof KPIResource
     */
    matches?: KPIMatchesResource;
    /**
     *
     * @type {KPIPartnersResource}
     * @memberof KPIResource
     */
    partners?: KPIPartnersResource;
    /**
     *
     * @type {KPIRefillsResource}
     * @memberof KPIResource
     */
    refills?: KPIRefillsResource;
    /**
     *
     * @type {KPINewSalesResource}
     * @memberof KPIResource
     */
    newSales?: KPINewSalesResource;
    /**
     *
     * @type {KPISalesResource}
     * @memberof KPIResource
     */
    sales?: KPISalesResource;
}

/**
 *
 * @export
 * @interface KPIResult
 */
export interface KPIResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof KPIResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof KPIResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof KPIResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof KPIResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<KPIResource>}
     * @memberof KPIResult
     */
    data?: Array<KPIResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof KPIResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface KPISalesAvgPriceResource
 */
export interface KPISalesAvgPriceResource {
    /**
     *
     * @type {KPIAmountResource}
     * @memberof KPISalesAvgPriceResource
     */
    actual?: KPIAmountResource;
    /**
     *
     * @type {KPIAmountResource}
     * @memberof KPISalesAvgPriceResource
     */
    target?: KPIAmountResource;
}

/**
 *
 * @export
 * @interface KPISalesNewSalesResource
 */
export interface KPISalesNewSalesResource {
    /**
     *
     * @type {KPIAmountResource}
     * @memberof KPISalesNewSalesResource
     */
    actual?: KPIAmountResource;
    /**
     *
     * @type {KPIAmountResource}
     * @memberof KPISalesNewSalesResource
     */
    target?: KPIAmountResource;
    /**
     *
     * @type {KPIAmountResource}
     * @memberof KPISalesNewSalesResource
     */
    actualNet?: KPIAmountResource;
    /**
     *
     * @type {KPIAmountResource}
     * @memberof KPISalesNewSalesResource
     */
    targetNet?: KPIAmountResource;
}

/**
 *
 * @export
 * @interface KPISalesRefillsResource
 */
export interface KPISalesRefillsResource {
    /**
     *
     * @type {KPIAmountResource}
     * @memberof KPISalesRefillsResource
     */
    actual?: KPIAmountResource;
    /**
     *
     * @type {KPIAmountResource}
     * @memberof KPISalesRefillsResource
     */
    target?: KPIAmountResource;
    /**
     *
     * @type {KPIAmountResource}
     * @memberof KPISalesRefillsResource
     */
    actualNet?: KPIAmountResource;
    /**
     *
     * @type {KPIAmountResource}
     * @memberof KPISalesRefillsResource
     */
    targetNet?: KPIAmountResource;
}

/**
 *
 * @export
 * @interface KPISalesResource
 */
export interface KPISalesResource {
    /**
     *
     * @type {KPISalesAvgPriceResource}
     * @memberof KPISalesResource
     */
    avgPrice?: KPISalesAvgPriceResource;
    /**
     *
     * @type {KPISalesNewSalesResource}
     * @memberof KPISalesResource
     */
    newSales?: KPISalesNewSalesResource;
    /**
     *
     * @type {KPISalesRefillsResource}
     * @memberof KPISalesResource
     */
    refills?: KPISalesRefillsResource;
    /**
     *
     * @type {KPISalesTotalResource}
     * @memberof KPISalesResource
     */
    total?: KPISalesTotalResource;
    /**
     *
     * @type {KPITotalNetFeeResource}
     * @memberof KPISalesResource
     */
    totalNetFee?: KPITotalNetFeeResource;
}

/**
 *
 * @export
 * @interface KPISalesTotalResource
 */
export interface KPISalesTotalResource {
    /**
     * Actual
     * @type {string}
     * @memberof KPISalesTotalResource
     */
    sold?: string;
    /**
     *
     * @type {KPIAmountResource}
     * @memberof KPISalesTotalResource
     */
    target?: KPIAmountResource;
    /**
     *
     * @type {KPIAmountResource}
     * @memberof KPISalesTotalResource
     */
    forecast?: KPIAmountResource;
}

/**
 *
 * @export
 * @interface KPITotalNetFeeResource
 */
export interface KPITotalNetFeeResource {
    /**
     * Actual
     * @type {string}
     * @memberof KPITotalNetFeeResource
     */
    sold?: string;
    /**
     *
     * @type {KPIAmountResource}
     * @memberof KPITotalNetFeeResource
     */
    target?: KPIAmountResource;
    /**
     *
     * @type {KPIAmountResource}
     * @memberof KPITotalNetFeeResource
     */
    forecast?: KPIAmountResource;
}

/**
 *
 * @export
 * @interface KeyValueResource
 */
export interface KeyValueResource {
    /**
     * Key.
     * @type {string}
     * @memberof KeyValueResource
     */
    key?: string;
    /**
     * Value.
     * @type {string}
     * @memberof KeyValueResource
     */
    value?: string;
}

/**
 *
 * @export
 * @interface KpiResource
 */
export interface KpiResource {
    /**
     * Number of calls made.
     * @type {number}
     * @memberof KpiResource
     */
    callsMade?: number;
    /**
     * Number of actions taken by the employee.
     * @type {number}
     * @memberof KpiResource
     */
    actionsTaken?: number;
    /**
     * Emplyee-level validation ratio for long term, e.g. last 60 days.
     * @type {number}
     * @memberof KpiResource
     */
    validationEfficiencyLongTerm?: number;
    /**
     * Emplyee-level validation ratio for short term, e.g. last 10 days.
     * @type {number}
     * @memberof KpiResource
     */
    validationEfficiencyShortTerm?: number;
    /**
     * Number of validated leads this month.
     * @type {number}
     * @memberof KpiResource
     */
    validatedLeads?: number;
}

/**
 *
 * @export
 * @interface KpiTargetResource
 */
export interface KpiTargetResource {
    /**
     * ID of the target.
     * @type {number}
     * @memberof KpiTargetResource
     */
    id?: number;
    /**
     * Identifyier of the target, e.g. 'match_ratio'
     * @type {string}
     * @memberof KpiTargetResource
     */
    kpiIdentifier?: KpiTargetResource.KpiIdentifierEnum;
    /**
     * Employee ID the target is for.
     * @type {number}
     * @memberof KpiTargetResource
     */
    employeeId?: number;
    /**
     * Year the target is for.
     * @type {number}
     * @memberof KpiTargetResource
     */
    year?: number;
    /**
     * Month the target is for.
     * @type {number}
     * @memberof KpiTargetResource
     */
    month?: number;
    /**
     * Day the target is for.
     * @type {number}
     * @memberof KpiTargetResource
     */
    day?: number;
    /**
     * Value of the target.
     * @type {number}
     * @memberof KpiTargetResource
     */
    value?: number;
    /**
     * Industry the KPI belongs to.
     * @type {number}
     * @memberof KpiTargetResource
     */
    industryId?: number;
    /**
     * Geo code the KPI belongs to.
     * @type {string}
     * @memberof KpiTargetResource
     */
    geoCode?: string;
}

/**
 * @export
 * @namespace KpiTargetResource
 */
export namespace KpiTargetResource {
    /**
     * @export
     * @enum {string}
     */
    export enum KpiIdentifierEnum {
        Unknown = <any> 'unknown',
        ValidationEfficiency = <any> 'validation_efficiency',
        MatchRatio = <any> 'match_ratio',
        CompletionRatio = <any> 'completion_ratio',
        Completions = <any> 'completions',
        CompletionsForecast = <any> 'completions_forecast',
        ExclusiveFee = <any> 'exclusive_fee',
        ExclusiveFeeForecast = <any> 'exclusive_fee_forecast',
        ExclusiveLeads = <any> 'exclusive_leads',
        ExclusiveMatchRatio = <any> 'exclusive_match_ratio',
        Matches = <any> 'matches',
        MatchesForecast = <any> 'matches_forecast',
        Validations = <any> 'validations',
        ValidationsForecast = <any> 'validations_forecast',
        AverageFee = <any> 'average_fee',
        TotalFee = <any> 'total_fee',
        TotalFeeForecast = <any> 'total_fee_forecast'
    }
}

/**
 *
 * @export
 * @interface KpiTargetResult
 */
export interface KpiTargetResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof KpiTargetResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof KpiTargetResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof KpiTargetResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof KpiTargetResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<KpiTargetResource>}
     * @memberof KpiTargetResult
     */
    data?: Array<KpiTargetResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof KpiTargetResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface KpiValueResource
 */
export interface KpiValueResource {
    /**
     * @var string
     * @type {string}
     * @memberof KpiValueResource
     */
    kpiIdentifier?: string;
    /**
     * @var int
     * @type {number}
     * @memberof KpiValueResource
     */
    employeeId?: number;
    /**
     * @var int
     * @type {number}
     * @memberof KpiValueResource
     */
    year?: number;
    /**
     * @var int
     * @type {number}
     * @memberof KpiValueResource
     */
    month?: number;
    /**
     * @var int
     * @type {number}
     * @memberof KpiValueResource
     */
    day?: number;
    /**
     * @var float
     * @type {number}
     * @memberof KpiValueResource
     */
    value?: number;
}

/**
 *
 * @export
 * @interface KpiValueResult
 */
export interface KpiValueResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof KpiValueResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof KpiValueResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof KpiValueResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof KpiValueResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<KpiValueResource>}
     * @memberof KpiValueResult
     */
    data?: Array<KpiValueResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof KpiValueResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface LanguageResource
 */
export interface LanguageResource {
    /**
     * Name of the language, e.g. English.
     * @type {string}
     * @memberof LanguageResource
     */
    name?: string;
    /**
     * Identifying key of the language, typically the two-letter ISO 639-1 code or the region-specific language code from the combination of ISO 639-1 and ISO 3166-1, e.g. 'en-US'.
     * @type {string}
     * @memberof LanguageResource
     */
    identifier?: string;
}

/**
 *
 * @export
 * @interface LanguageResult
 */
export interface LanguageResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof LanguageResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof LanguageResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof LanguageResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof LanguageResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<LanguageResource>}
     * @memberof LanguageResult
     */
    data?: Array<LanguageResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof LanguageResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface LeadAcceptedResource
 */
export interface LeadAcceptedResource {
    /**
     * Id for the Accepted.
     * @type {number}
     * @memberof LeadAcceptedResource
     */
    acceptedId?: number;
    /**
     * Foreign offer?
     * @type {number}
     * @memberof LeadAcceptedResource
     */
    foreignOffer?: number;
    /**
     * The reason.
     * @type {string}
     * @memberof LeadAcceptedResource
     */
    foreignOfferReason?: string;
}

/**
 *
 * @export
 * @interface LeadActionQuoteReasonResource
 */
export interface LeadActionQuoteReasonResource {
    /**
     * Quote ID
     * @type {number}
     * @memberof LeadActionQuoteReasonResource
     */
    quoteId?: number;
    /**
     * Reason for the action.
     * @type {string}
     * @memberof LeadActionQuoteReasonResource
     */
    reason?: string;
}

/**
 *
 * @export
 * @interface LeadActionResource
 */
export interface LeadActionResource {
    /**
     * Lead action
     * @type {string}
     * @memberof LeadActionResource
     */
    action?: LeadActionResource.ActionEnum;
    /**
     * Date
     * @type {string}
     * @memberof LeadActionResource
     */
    date?: string;
    /**
     * Quote id
     * @type {number}
     * @memberof LeadActionResource
     */
    quoteId?: number;
    /**
     * Reasons for each quote's rejection
     * @type {Array<LeadActionQuoteReasonResource>}
     * @memberof LeadActionResource
     */
    rejectionReasons?: Array<LeadActionQuoteReasonResource>;
    /**
     * The reason for a particular action
     * @type {string}
     * @memberof LeadActionResource
     */
    reason?: LeadActionResource.ReasonEnum;
    /**
     * @var The reason to complete lead
     * @type {string}
     * @memberof LeadActionResource
     */
    reasonCompleted?: LeadActionResource.ReasonCompletedEnum;
    /**
     * Mark a lead with a status.
     * @type {string}
     * @memberof LeadActionResource
     */
    discardStatus?: LeadActionResource.DiscardStatusEnum;
    /**
     * Reason the status was set to what it is.
     * @type {string}
     * @memberof LeadActionResource
     */
    discardStatusReason?: string;
    /**
     * Note the status was set to what it is.
     * @type {string}
     * @memberof LeadActionResource
     */
    discardStatusNote?: string;
    /**
     * Reason for the postponement.
     * @type {string}
     * @memberof LeadActionResource
     */
    clientDecisionPostponedReason?: string;
    /**
     *
     * @type {number}
     * @memberof LeadActionResource
     */
    partnerId?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadActionResource
     */
    customFee?: AmountResource;
    /**
     *
     * @type {number}
     * @memberof LeadActionResource
     */
    quotesMax?: number;
}

/**
 * @export
 * @namespace LeadActionResource
 */
export namespace LeadActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        Unknown = <any> 'unknown',
        Call = <any> 'call',
        NoAnswer = <any> 'no_answer',
        AcceptQuote = <any> 'accept_quote',
        PublishQuote = <any> 'publish_quote',
        RejectAllQuotes = <any> 'reject_all_quotes',
        UnrejectAllQuotes = <any> 'unreject_all_quotes',
        PostponeClientMatchDecision = <any> 'postpone_client_match_decision',
        MarkAsCompleted = <any> 'mark_as_completed',
        Unmatch = <any> 'unmatch',
        RecordRejectionReasons = <any> 'record_rejection_reasons',
        Discard = <any> 'discard',
        Validate = <any> 'validate',
        InvokeRightToBeForgotten = <any> 'invoke_right_to_be_forgotten',
        GivenUp = <any> 'given_up',
        Note = <any> 'note',
        PostponeValidation = <any> 'postpone_validation',
        SendToPartner = <any> 'send_to_partner',
        ReserveQuote = <any> 'reserve_quote',
        QueueFeedbackEmail = <any> 'queue_feedback_email',
        CopyToNew = <any> 'copy_to_new',
        BuyFromLeadMarketplace = <any> 'buy_from_lead_marketplace',
        MarkLeadAsUntreated = <any> 'mark_lead_as_untreated',
        UpdateQuoteMax = <any> 'update_quote_max'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum ReasonEnum {
        Unknown = <any> 'unknown',
        Duplicate = <any> 'duplicate',
        Test = <any> 'test',
        NotSerious = <any> 'not_serious',
        SendToAdvisor = <any> 'send_to_advisor',
        Spam = <any> 'spam',
        Validated = <any> 'validated',
        Untreated = <any> 'untreated'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum ReasonCompletedEnum {
        Unknown = <any> 'unknown',
        NoRelevantPartnersExpertise = <any> 'no_relevant_partners_expertise',
        NoRelevantPartnersGeography = <any> 'no_relevant_partners_geography',
        ClientBudget = <any> 'client_budget',
        ClientNotSerious = <any> 'client_not_serious',
        ClientSatisfied = <any> 'client_satisfied',
        ClientCaseTooSmall = <any> 'client_case_too_small'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum DiscardStatusEnum {
        Unknown = <any> 'unknown',
        Duplicate = <any> 'duplicate',
        Test = <any> 'test',
        NotSerious = <any> 'not_serious',
        SendToAdvisor = <any> 'send_to_advisor',
        Spam = <any> 'spam',
        Untreated = <any> 'untreated'
    }
}

/**
 *
 * @export
 * @interface LeadActionsInfoResource
 */
export interface LeadActionsInfoResource {
    /**
     * Date
     * @type {string}
     * @memberof LeadActionsInfoResource
     */
    latestCreatedAt?: string;
    /**
     * Date
     * @type {string}
     * @memberof LeadActionsInfoResource
     */
    latestCalledAt?: string;
}

/**
 *
 * @export
 * @interface LeadAllocationQuotaResource
 */
export interface LeadAllocationQuotaResource {
    /**
     * Quota ID.
     * @type {number}
     * @memberof LeadAllocationQuotaResource
     */
    id?: number;
    /**
     * Business Unit ID.
     * @type {number}
     * @memberof LeadAllocationQuotaResource
     */
    partnerBusinessUnitId?: number;
    /**
     * When this quota is effective from.
     * @type {string}
     * @memberof LeadAllocationQuotaResource
     */
    effectiveAt?: string;
    /**
     * Who created this quota.
     * @type {number}
     * @memberof LeadAllocationQuotaResource
     */
    createdByEmployeeId?: number;
    /**
     * Number of leads available for allocation per refill, e.g. each month.
     * @type {number}
     * @memberof LeadAllocationQuotaResource
     */
    allocationQuotaPerRefill?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadAllocationQuotaResource
     */
    quotaPrice?: AmountResource;
}

/**
 *
 * @export
 * @interface LeadAllocationQuotaResult
 */
export interface LeadAllocationQuotaResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof LeadAllocationQuotaResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof LeadAllocationQuotaResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof LeadAllocationQuotaResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof LeadAllocationQuotaResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<LeadAllocationQuotaResource>}
     * @memberof LeadAllocationQuotaResult
     */
    data?: Array<LeadAllocationQuotaResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof LeadAllocationQuotaResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface LeadAllocationResource
 */
export interface LeadAllocationResource {
    /**
     * Partner business unit to allocate.
     * @type {number}
     * @memberof LeadAllocationResource
     */
    partnerBusinessUnitId?: number;
    /**
     * The type of allocation that should be used. E.g. 'overdelivery'
     * @type {string}
     * @memberof LeadAllocationResource
     */
    allocationType?: LeadAllocationResource.AllocationTypeEnum;
}

/**
 * @export
 * @namespace LeadAllocationResource
 */
export namespace LeadAllocationResource {
    /**
     * @export
     * @enum {string}
     */
    export enum AllocationTypeEnum {
        Any = <any> 'any',
        Overdelivery = <any> 'overdelivery'
    }
}

/**
 *
 * @export
 * @interface LeadAllocationSuggestionsInfoResource
 */
export interface LeadAllocationSuggestionsInfoResource {
    /**
     *
     * @type {number}
     * @memberof LeadAllocationSuggestionsInfoResource
     */
    accepted?: number;
    /**
     *
     * @type {number}
     * @memberof LeadAllocationSuggestionsInfoResource
     */
    count?: number;
}

/**
 *
 * @export
 * @interface LeadAllocationsResource
 */
export interface LeadAllocationsResource {
    /**
     * Partner business units to allocate.
     * @type {Array<LeadAllocationResource>}
     * @memberof LeadAllocationsResource
     */
    leadAllocations?: Array<LeadAllocationResource>;
}

/**
 *
 * @export
 * @interface LeadAttributeResource
 */
export interface LeadAttributeResource {
    /**
     * Lead Field Id.
     * @type {number}
     * @memberof LeadAttributeResource
     */
    id?: number;
    /**
     * Identifier for the Lead Field
     * @type {string}
     * @memberof LeadAttributeResource
     */
    identifier?: string;
    /**
     * Label for the given Lead Field.
     * @type {string}
     * @memberof LeadAttributeResource
     */
    label?: string;
    /**
     * Flag whether data field can have multiple values
     * @type {boolean}
     * @memberof LeadAttributeResource
     */
    isMultiple?: boolean;
    /**
     * Array of selected values.
     * @type {Array<DataFieldValueResource>}
     * @memberof LeadAttributeResource
     */
    selected?: Array<DataFieldValueResource>;
    /**
     * Type of field
     * @type {string}
     * @memberof LeadAttributeResource
     */
    type?: LeadAttributeResource.TypeEnum;
}

/**
 * @export
 * @namespace LeadAttributeResource
 */
export namespace LeadAttributeResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Unknown = <any> 'unknown',
        Text = <any> 'text',
        Number = <any> 'number',
        Checkbox = <any> 'checkbox',
        Textbox = <any> 'textbox',
        Dropdown = <any> 'dropdown'
    }
}

/**
 *
 * @export
 * @interface LeadBulkActionDiscardResource
 */
export interface LeadBulkActionDiscardResource {
    /**
     *
     * @type {Array<number>}
     * @memberof LeadBulkActionDiscardResource
     */
    leadIds?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof LeadBulkActionDiscardResource
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof LeadBulkActionDiscardResource
     */
    note?: string;
}

/**
 *
 * @export
 * @interface LeadCategoryResource
 */
export interface LeadCategoryResource {
    /**
     * Lead Category id
     * @type {number}
     * @memberof LeadCategoryResource
     */
    id?: number;
    /**
     * Identifier for the Lead Category
     * @type {string}
     * @memberof LeadCategoryResource
     */
    identifier?: string;
    /**
     * Title for the given Lead Category.
     * @type {string}
     * @memberof LeadCategoryResource
     */
    title?: string;
}

/**
 *
 * @export
 * @interface LeadCategoryResult
 */
export interface LeadCategoryResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof LeadCategoryResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof LeadCategoryResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof LeadCategoryResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof LeadCategoryResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<LeadCategoryResource>}
     * @memberof LeadCategoryResult
     */
    data?: Array<LeadCategoryResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof LeadCategoryResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface LeadCertificationsResource
 */
export interface LeadCertificationsResource {
    /**
     * Certification IDs. @var int[]
     * @type {Array<number>}
     * @memberof LeadCertificationsResource
     */
    certificationIds?: Array<number>;
}

/**
 *
 * @export
 * @interface LeadClientExperimentResource
 */
export interface LeadClientExperimentResource {
    /**
     *
     * @type {string}
     * @memberof LeadClientExperimentResource
     */
    identifier?: LeadClientExperimentResource.IdentifierEnum;
}

/**
 * @export
 * @namespace LeadClientExperimentResource
 */
export namespace LeadClientExperimentResource {
    /**
     * @export
     * @enum {string}
     */
    export enum IdentifierEnum {
        CustomerChoice = <any> 'customer_choice'
    }
}

/**
 *
 * @export
 * @interface LeadClientResource
 */
export interface LeadClientResource {
    /**
     * Id of the client
     * @type {number}
     * @memberof LeadClientResource
     */
    id?: number;
    /**
     * The full name for the client.
     * @type {string}
     * @memberof LeadClientResource
     */
    fullName?: string;
    /**
     * The first name for the client.
     * @type {string}
     * @memberof LeadClientResource
     */
    firstName?: string;
    /**
     * The last name for the client.
     * @type {string}
     * @memberof LeadClientResource
     */
    lastName?: string;
    /**
     * The name of the company.
     * @type {string}
     * @memberof LeadClientResource
     */
    companyName?: string;
    /**
     * The email used to contact the client.
     * @type {string}
     * @memberof LeadClientResource
     */
    email?: string;
    /**
     * Phone number.
     * @type {string}
     * @memberof LeadClientResource
     */
    phone?: string;
    /**
     * Mobile number.
     * @type {string}
     * @memberof LeadClientResource
     */
    mobile?: string;
    /**
     * VAT number.
     * @type {string}
     * @memberof LeadClientResource
     */
    vatNumber?: string;
    /**
     *
     * @type {LeadCustomerTypeResource}
     * @memberof LeadClientResource
     */
    customerType?: LeadCustomerTypeResource;
    /**
     * Social security number.
     * @type {string}
     * @memberof LeadClientResource
     */
    socialSecurityNumber?: string;
    /**
     *
     * @type {boolean}
     * @memberof LeadClientResource
     */
    isPhoneVerified?: boolean;
    /**
     *
     * @type {Array<LeadClientExperimentResource>}
     * @memberof LeadClientResource
     */
    experiments?: Array<LeadClientExperimentResource>;
    /**
     *
     * @type {string}
     * @memberof LeadClientResource
     */
    accessToken?: string;
}

/**
 *
 * @export
 * @interface LeadCompanySizesResource
 */
export interface LeadCompanySizesResource {
    /**
     * Company size IDs. @var int[]
     * @type {Array<number>}
     * @memberof LeadCompanySizesResource
     */
    companySizeIds?: Array<number>;
}

/**
 *
 * @export
 * @interface LeadCompanySizesResult
 */
export interface LeadCompanySizesResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof LeadCompanySizesResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof LeadCompanySizesResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof LeadCompanySizesResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof LeadCompanySizesResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<LeadCompanySizesResource>}
     * @memberof LeadCompanySizesResult
     */
    data?: Array<LeadCompanySizesResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof LeadCompanySizesResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface LeadCustomerInfoResource
 */
export interface LeadCustomerInfoResource {
    /**
     * The first name for the customer.
     * @type {string}
     * @memberof LeadCustomerInfoResource
     */
    firstName?: string;
    /**
     * The last name for the customer.
     * @type {string}
     * @memberof LeadCustomerInfoResource
     */
    lastName?: string;
    /**
     * The name of the company.
     * @type {string}
     * @memberof LeadCustomerInfoResource
     */
    companyName?: string;
    /**
     * The email used to contact the customer.
     * @type {string}
     * @memberof LeadCustomerInfoResource
     */
    email?: string;
    /**
     * The Phone Number used to contact the customer.
     * @type {string}
     * @memberof LeadCustomerInfoResource
     */
    phone?: string;
    /**
     * The Mobile Phone Number used to contact the customer.
     * @type {string}
     * @memberof LeadCustomerInfoResource
     */
    mobile?: string;
    /**
     * VAT Number for the customer.
     * @type {string}
     * @memberof LeadCustomerInfoResource
     */
    vatNumber?: string;
    /**
     *
     * @type {LeadCustomerTypeResource}
     * @memberof LeadCustomerInfoResource
     */
    customerType?: LeadCustomerTypeResource;
    /**
     * If true, prices should be displayed to the customer including VAT
     * @type {boolean}
     * @memberof LeadCustomerInfoResource
     */
    showPricesIncludingVat?: boolean;
    /**
     * Social security number.
     * @type {string}
     * @memberof LeadCustomerInfoResource
     */
    socialSecurityNumber?: string;
}

/**
 *
 * @export
 * @interface LeadCustomerTypeResource
 */
export interface LeadCustomerTypeResource {
    /**
     * Lead type id
     * @type {number}
     * @memberof LeadCustomerTypeResource
     */
    id?: number;
    /**
     * Lead type identifier
     * @type {string}
     * @memberof LeadCustomerTypeResource
     */
    identifier?: string;
}

/**
 *
 * @export
 * @interface LeadEmployeeResource
 */
export interface LeadEmployeeResource {
    /**
     * Employee id.
     * @type {number}
     * @memberof LeadEmployeeResource
     */
    id?: number;
    /**
     * Employee First Name
     * @type {string}
     * @memberof LeadEmployeeResource
     */
    firstName?: string;
    /**
     * Employee Last Name
     * @type {string}
     * @memberof LeadEmployeeResource
     */
    lastName?: string;
    /**
     * Employee Nick Name, e.g. 'CADA'
     * @type {string}
     * @memberof LeadEmployeeResource
     */
    nickName?: string;
}

/**
 *
 * @export
 * @interface LeadFeedbackResource
 */
export interface LeadFeedbackResource {
    /**
     * The reason for choosing the partner
     * @type {string}
     * @memberof LeadFeedbackResource
     */
    reason?: string;
    /**
     * Satisfaction
     * @type {number}
     * @memberof LeadFeedbackResource
     */
    satisfaction?: number;
    /**
     * Feedback
     * @type {string}
     * @memberof LeadFeedbackResource
     */
    feedback?: string;
    /**
     *
     * @type {PartnerSuggestResource}
     * @memberof LeadFeedbackResource
     */
    partner?: PartnerSuggestResource;
}

/**
 *
 * @export
 * @interface LeadGeoResource
 */
export interface LeadGeoResource {
    /**
     * Code for the given location.
     * @type {string}
     * @memberof LeadGeoResource
     */
    code?: string;
    /**
     * The address.
     * @type {string}
     * @memberof LeadGeoResource
     */
    address?: string;
    /**
     * Zip Code.
     * @type {string}
     * @memberof LeadGeoResource
     */
    zipCode?: string;
    /**
     * Name of the city.
     * @type {string}
     * @memberof LeadGeoResource
     */
    cityName?: string;
    /**
     *
     * @type {LocationGeoPointResource}
     * @memberof LeadGeoResource
     */
    point?: LocationGeoPointResource;
}

/**
 *
 * @export
 * @interface LeadIndustriesResource
 */
export interface LeadIndustriesResource {
    /**
     *
     * @type {LeadIndustryResource}
     * @memberof LeadIndustriesResource
     */
    primary?: LeadIndustryResource;
    /**
     *
     * @type {LeadIndustryResource}
     * @memberof LeadIndustriesResource
     */
    secondary?: LeadIndustryResource;
    /**
     *
     * @type {Array<LeadIndustryResource>}
     * @memberof LeadIndustriesResource
     */
    subIndustries?: Array<LeadIndustryResource>;
}

/**
 *
 * @export
 * @interface LeadIndustryResource
 */
export interface LeadIndustryResource {
    /**
     * Id for the industry.
     * @type {number}
     * @memberof LeadIndustryResource
     */
    id?: number;
    /**
     * Name of the industry.
     * @type {string}
     * @memberof LeadIndustryResource
     */
    name?: string;
    /**
     * Uri friendly name for the industry.
     * @type {string}
     * @memberof LeadIndustryResource
     */
    slug?: string;
    /**
     *
     * @type {string}
     * @memberof LeadIndustryResource
     */
    identifier?: string;
    /**
     *
     * @type {boolean}
     * @memberof LeadIndustryResource
     */
    isAutoAllocatable?: boolean;
}

/**
 *
 * @export
 * @interface LeadMarketplaceResource
 */
export interface LeadMarketplaceResource {
    /**
     *
     * @type {string}
     * @memberof LeadMarketplaceResource
     */
    premiumAccessDeadline?: string;
    /**
     *
     * @type {string}
     * @memberof LeadMarketplaceResource
     */
    basicAccessDeadline?: string;
    /**
     *
     * @type {boolean}
     * @memberof LeadMarketplaceResource
     */
    matchesMarketPremiumAccessCriteria?: boolean;
}

/**
 *
 * @export
 * @interface LeadMeetingResource
 */
export interface LeadMeetingResource {
    /**
     * Price for the meeting.
     * @type {number}
     * @memberof LeadMeetingResource
     */
    meetingPrice?: number;
    /**
     * Description for the meeting.
     * @type {string}
     * @memberof LeadMeetingResource
     */
    description?: string;
    /**
     * Partner user to make meeting booking for.
     * @type {number}
     * @memberof LeadMeetingResource
     */
    partnerUserId?: number;
}

/**
 *
 * @export
 * @interface LeadNoteResource
 */
export interface LeadNoteResource {
    /**
     * Note
     * @type {string}
     * @memberof LeadNoteResource
     */
    note?: string;
    /**
     * Employee creator id
     * @type {number}
     * @memberof LeadNoteResource
     */
    employeeId?: number;
    /**
     * Date
     * @type {string}
     * @memberof LeadNoteResource
     */
    createdAt?: string;
}

/**
 *
 * @export
 * @interface LeadOffersInfoItemResource
 */
export interface LeadOffersInfoItemResource {
    /**
     * The offers's id.
     * @type {number}
     * @memberof LeadOffersInfoItemResource
     */
    id?: number;
    /**
     * Did this offer win
     * @type {boolean}
     * @memberof LeadOffersInfoItemResource
     */
    won?: boolean;
    /**
     *
     * @type {string}
     * @memberof LeadOffersInfoItemResource
     */
    createdAt?: string;
    /**
     *
     * @type {PartnerSuggestResource}
     * @memberof LeadOffersInfoItemResource
     */
    partner?: PartnerSuggestResource;
}

/**
 *
 * @export
 * @interface LeadOffersInfoResource
 */
export interface LeadOffersInfoResource {
    /**
     * Max Offer count.
     * @type {number}
     * @memberof LeadOffersInfoResource
     */
    max?: number;
    /**
     * Number of offers.
     * @type {number}
     * @memberof LeadOffersInfoResource
     */
    count?: number;
    /**
     * Open for quotes.
     * @type {boolean}
     * @memberof LeadOffersInfoResource
     */
    openForOffers?: boolean;
    /**
     * List of offers.
     * @type {Array<LeadOffersInfoItemResource>}
     * @memberof LeadOffersInfoResource
     */
    offers?: Array<LeadOffersInfoItemResource>;
}

/**
 *
 * @export
 * @interface LeadPartnerActionResource
 */
export interface LeadPartnerActionResource {
    /**
     *
     * @type {number}
     * @memberof LeadPartnerActionResource
     */
    id?: number;
    /**
     *
     * @type {number}
     * @memberof LeadPartnerActionResource
     */
    partnerId?: number;
    /**
     *
     * @type {number}
     * @memberof LeadPartnerActionResource
     */
    leadId?: number;
    /**
     *
     * @type {string}
     * @memberof LeadPartnerActionResource
     */
    actionType?: LeadPartnerActionResource.ActionTypeEnum;
    /**
     *
     * @type {string}
     * @memberof LeadPartnerActionResource
     */
    createdAt?: string;
}

/**
 * @export
 * @namespace LeadPartnerActionResource
 */
export namespace LeadPartnerActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionTypeEnum {
        RevealPhoneNumber = <any> 'reveal_phone_number',
        RequestMeeting = <any> 'request_meeting',
        Ascending = <any> 'ascending',
        Descending = <any> 'descending'
    }
}

/**
 *
 * @export
 * @interface LeadPartnerAllocationLimitResource
 */
export interface LeadPartnerAllocationLimitResource {
    /**
     * ID.
     * @type {number}
     * @memberof LeadPartnerAllocationLimitResource
     */
    id?: number;
    /**
     * Business Unit ID.
     * @type {number}
     * @memberof LeadPartnerAllocationLimitResource
     */
    partnerBusinessUnitId?: number;
    /**
     * Allocation limit.
     * @type {number}
     * @memberof LeadPartnerAllocationLimitResource
     */
    limit?: number;
    /**
     * When this allocation is effective.
     * @type {string}
     * @memberof LeadPartnerAllocationLimitResource
     */
    effectiveAt?: string;
    /**
     * When this allocation limit was created.
     * @type {string}
     * @memberof LeadPartnerAllocationLimitResource
     */
    createdAt?: string;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadPartnerAllocationLimitResource
     */
    price?: AmountResource;
}

/**
 *
 * @export
 * @interface LeadPartnerAllocationLimitResult
 */
export interface LeadPartnerAllocationLimitResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof LeadPartnerAllocationLimitResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof LeadPartnerAllocationLimitResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof LeadPartnerAllocationLimitResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof LeadPartnerAllocationLimitResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<LeadPartnerAllocationLimitResource>}
     * @memberof LeadPartnerAllocationLimitResult
     */
    data?: Array<LeadPartnerAllocationLimitResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof LeadPartnerAllocationLimitResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface LeadPartnerAllocationQuoteResource
 */
export interface LeadPartnerAllocationQuoteResource {
    /**
     * The quote's id.
     * @type {number}
     * @memberof LeadPartnerAllocationQuoteResource
     */
    id?: number;
    /**
     * Created time
     * @type {string}
     * @memberof LeadPartnerAllocationQuoteResource
     */
    createdAt?: string;
    /**
     * Quote accepted at
     * @type {string}
     * @memberof LeadPartnerAllocationQuoteResource
     */
    acceptedAt?: string;
    /**
     * Created time
     * @type {string}
     * @memberof LeadPartnerAllocationQuoteResource
     */
    publishedAt?: string;
}

/**
 *
 * @export
 * @interface LeadPartnerAllocationResource
 */
export interface LeadPartnerAllocationResource {
    /**
     * ID.
     * @type {number}
     * @memberof LeadPartnerAllocationResource
     */
    id?: number;
    /**
     * Lead ID.
     * @type {number}
     * @memberof LeadPartnerAllocationResource
     */
    leadId?: number;
    /**
     * Partner ID.
     * @type {number}
     * @memberof LeadPartnerAllocationResource
     */
    partnerId?: number;
    /**
     * Business Unit ID.
     * @type {number}
     * @memberof LeadPartnerAllocationResource
     */
    partnerBusinessUnitId?: number;
    /**
     * When this allocation was processed.
     * @type {string}
     * @memberof LeadPartnerAllocationResource
     */
    processedAt?: string;
    /**
     * When this allocation was created.
     * @type {string}
     * @memberof LeadPartnerAllocationResource
     */
    createdAt?: string;
    /**
     * Who created this allocation.
     * @type {number}
     * @memberof LeadPartnerAllocationResource
     */
    allocatedByEmployeeId?: number;
    /**
     *
     * @type {LeadPartnerAllocationQuoteResource}
     * @memberof LeadPartnerAllocationResource
     */
    quote?: LeadPartnerAllocationQuoteResource;
    /**
     * When the lead eas deallocated.
     * @type {string}
     * @memberof LeadPartnerAllocationResource
     */
    deallocatedAt?: string;
    /**
     * Who deallocated the allocation.
     * @type {number}
     * @memberof LeadPartnerAllocationResource
     */
    deallocatedByEmployeeId?: number;
}

/**
 *
 * @export
 * @interface LeadPartnerAllocationResult
 */
export interface LeadPartnerAllocationResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof LeadPartnerAllocationResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof LeadPartnerAllocationResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof LeadPartnerAllocationResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof LeadPartnerAllocationResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<LeadPartnerAllocationResource>}
     * @memberof LeadPartnerAllocationResult
     */
    data?: Array<LeadPartnerAllocationResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof LeadPartnerAllocationResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface LeadPartnerMeetingRequestResource
 */
export interface LeadPartnerMeetingRequestResource {
    /**
     *
     * @type {number}
     * @memberof LeadPartnerMeetingRequestResource
     */
    id?: number;
    /**
     *
     * @type {number}
     * @memberof LeadPartnerMeetingRequestResource
     */
    leadId?: number;
    /**
     *
     * @type {number}
     * @memberof LeadPartnerMeetingRequestResource
     */
    partnerId?: number;
    /**
     *
     * @type {Array<LeadPartnerMeetingRequestTimeslotResource>}
     * @memberof LeadPartnerMeetingRequestResource
     */
    leadPartnerMeetingRequestTimeslots?: Array<LeadPartnerMeetingRequestTimeslotResource>;
    /**
     *
     * @type {string}
     * @memberof LeadPartnerMeetingRequestResource
     */
    createdAt?: string;
}

/**
 *
 * @export
 * @interface LeadPartnerMeetingRequestResult
 */
export interface LeadPartnerMeetingRequestResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof LeadPartnerMeetingRequestResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof LeadPartnerMeetingRequestResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof LeadPartnerMeetingRequestResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof LeadPartnerMeetingRequestResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<LeadPartnerMeetingRequestResource>}
     * @memberof LeadPartnerMeetingRequestResult
     */
    data?: Array<LeadPartnerMeetingRequestResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof LeadPartnerMeetingRequestResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface LeadPartnerMeetingRequestTimeslotResource
 */
export interface LeadPartnerMeetingRequestTimeslotResource {
    /**
     *
     * @type {number}
     * @memberof LeadPartnerMeetingRequestTimeslotResource
     */
    id?: number;
    /**
     *
     * @type {number}
     * @memberof LeadPartnerMeetingRequestTimeslotResource
     */
    leadPartnerMeetingRequestId?: number;
    /**
     *
     * @type {string}
     * @memberof LeadPartnerMeetingRequestTimeslotResource
     */
    from?: string;
    /**
     *
     * @type {string}
     * @memberof LeadPartnerMeetingRequestTimeslotResource
     */
    to?: string;
    /**
     *
     * @type {string}
     * @memberof LeadPartnerMeetingRequestTimeslotResource
     */
    createdAt?: string;
}

/**
 *
 * @export
 * @interface LeadPartnerResource
 */
export interface LeadPartnerResource {
    /**
     * Id of the suggested partner.
     * @type {number}
     * @memberof LeadPartnerResource
     */
    id?: number;
    /**
     * Company name of the suggested partner.
     * @type {string}
     * @memberof LeadPartnerResource
     */
    companyName?: string;
    /**
     * Company voucher email.
     * @type {string}
     * @memberof LeadPartnerResource
     */
    email?: string;
    /**
     * Company phone.
     * @type {string}
     * @memberof LeadPartnerResource
     */
    phone?: string;
    /**
     * Slug to use for Uri
     * @type {string}
     * @memberof LeadPartnerResource
     */
    slug?: string;
    /**
     * Partner State
     * @type {string}
     * @memberof LeadPartnerResource
     */
    state?: LeadPartnerResource.StateEnum;
    /**
     * Status of the suggested partner.
     * @type {string}
     * @memberof LeadPartnerResource
     */
    status?: string;
    /**
     * Zip Code of the suggested partner.
     * @type {string}
     * @memberof LeadPartnerResource
     */
    zipCode?: string;
    /**
     * City of the suggested partner.
     * @type {string}
     * @memberof LeadPartnerResource
     */
    city?: string;
    /**
     * Geo Location of the suggested partner.
     * @type {string}
     * @memberof LeadPartnerResource
     */
    geoCode?: string;
    /**
     * Partner State.
     * @type {string}
     * @memberof LeadPartnerResource
     */
    partnerState?: string;
    /**
     *
     * @type {PartnerRatingResource}
     * @memberof LeadPartnerResource
     */
    rating?: PartnerRatingResource;
    /**
     * Partner logo.
     * @type {string}
     * @memberof LeadPartnerResource
     */
    logo?: string;
    /**
     *
     * @type {LeadPartnerUserResource}
     * @memberof LeadPartnerResource
     */
    partnerUser?: LeadPartnerUserResource;
    /**
     * Partner subscription type
     * @type {string}
     * @memberof LeadPartnerResource
     */
    subscriptionType?: string;
}

/**
 * @export
 * @namespace LeadPartnerResource
 */
export namespace LeadPartnerResource {
    /**
     * @export
     * @enum {string}
     */
    export enum StateEnum {
        Unknown = <any> 'unknown',
        Canvas = <any> 'canvas',
        Demo = <any> 'demo',
        Inactive = <any> 'inactive',
        ExPartner = <any> 'ex_partner',
        Active = <any> 'active',
        Closed = <any> 'closed',
        BusinessPartner = <any> 'business_partner'
    }
}

/**
 *
 * @export
 * @interface LeadPartnerResult
 */
export interface LeadPartnerResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof LeadPartnerResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof LeadPartnerResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof LeadPartnerResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof LeadPartnerResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<LeadPartnerResource>}
     * @memberof LeadPartnerResult
     */
    data?: Array<LeadPartnerResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof LeadPartnerResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface LeadPartnerUserResource
 */
export interface LeadPartnerUserResource {
    /**
     * Partner user id @var integer
     * @type {number}
     * @memberof LeadPartnerUserResource
     */
    id?: number;
    /**
     * Partner user full name @var string
     * @type {string}
     * @memberof LeadPartnerUserResource
     */
    name?: string;
    /**
     * Partner user email @var string
     * @type {string}
     * @memberof LeadPartnerUserResource
     */
    email?: string;
    /**
     * Partner user phone @var string
     * @type {string}
     * @memberof LeadPartnerUserResource
     */
    phone?: string;
    /**
     * Partner user mobile @var string
     * @type {string}
     * @memberof LeadPartnerUserResource
     */
    mobile?: string;
    /**
     * Partner User Profile picture @var
     * @type {string}
     * @memberof LeadPartnerUserResource
     */
    profilePicture?: string;
}

/**
 *
 * @export
 * @interface LeadProductResource
 */
export interface LeadProductResource {
    /**
     * LeadProduct id
     * @type {number}
     * @memberof LeadProductResource
     */
    id?: number;
    /**
     * Lead id
     * @type {number}
     * @memberof LeadProductResource
     */
    leadId?: number;
    /**
     *
     * @type {ProductResource}
     * @memberof LeadProductResource
     */
    product?: ProductResource;
    /**
     * Product description specific to this lead
     * @type {string}
     * @memberof LeadProductResource
     */
    description?: string;
    /**
     * Yearly, quarterly, monthly, unit etc
     * @type {string}
     * @memberof LeadProductResource
     */
    type?: LeadProductResource.TypeEnum;
    /**
     * Quantity
     * @type {number}
     * @memberof LeadProductResource
     */
    quantity?: number;
    /**
     * Subsequent years
     * @type {boolean}
     * @memberof LeadProductResource
     */
    subsequentYears?: boolean;
}

/**
 * @export
 * @namespace LeadProductResource
 */
export namespace LeadProductResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Unknown = <any> 'unknown',
        Unit = <any> 'unit',
        Yearly = <any> 'yearly',
        Quarterly = <any> 'quarterly',
        Monthly = <any> 'monthly',
        Biannually = <any> 'biannually',
        Weekly = <any> 'weekly',
        EverySecondMonth = <any> 'every_second_month'
    }
}

/**
 *
 * @export
 * @interface LeadProgressResource
 */
export interface LeadProgressResource {
    /**
     * Progress step
     * @type {string}
     * @memberof LeadProgressResource
     */
    step?: LeadProgressResource.StepEnum;
    /**
     * Has been completed
     * @type {boolean}
     * @memberof LeadProgressResource
     */
    isCompleted?: boolean;
}

/**
 * @export
 * @namespace LeadProgressResource
 */
export namespace LeadProgressResource {
    /**
     * @export
     * @enum {string}
     */
    export enum StepEnum {
        None = <any> 'none',
        Validation = <any> 'validation',
        AwaitingOffers = <any> 'awaiting_offers',
        AwaitingQuotes = <any> 'awaiting_quotes',
        QuoteChosen = <any> 'quote_chosen',
        MatchValidation = <any> 'match_validation',
        FollowUp = <any> 'follow_up',
        Done = <any> 'done'
    }
}

/**
 *
 * @export
 * @interface LeadQuoteActionResource
 */
export interface LeadQuoteActionResource {
    /**
     * Lead action
     * @type {string}
     * @memberof LeadQuoteActionResource
     */
    action?: LeadQuoteActionResource.ActionEnum;
    /**
     * Date
     * @type {string}
     * @memberof LeadQuoteActionResource
     */
    date?: string;
    /**
     * Quote id
     * @type {number}
     * @memberof LeadQuoteActionResource
     */
    quoteId?: number;
    /**
     * Quote refusal reason
     * @type {string}
     * @memberof LeadQuoteActionResource
     */
    refusalReason?: string;
}

/**
 * @export
 * @namespace LeadQuoteActionResource
 */
export namespace LeadQuoteActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        Unknown = <any> 'unknown',
        Accept = <any> 'accept',
        Publish = <any> 'publish',
        Called = <any> 'called',
        CloseReservation = <any> 'close_reservation',
        Refuse = <any> 'refuse'
    }
}

/**
 *
 * @export
 * @interface LeadQuoteFeeResource
 */
export interface LeadQuoteFeeResource {
    /**
     * Lead the fees are for.
     * @type {number}
     * @memberof LeadQuoteFeeResource
     */
    leadId?: number;
    /**
     * Employee who made the fee.
     * @type {number}
     * @memberof LeadQuoteFeeResource
     */
    employeeId?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuoteFeeResource
     */
    quoteFee?: AmountResource;
}

/**
 *
 * @export
 * @interface LeadQuoteInfoEntryResource
 */
export interface LeadQuoteInfoEntryResource {
    /**
     * The quote's id.
     * @type {number}
     * @memberof LeadQuoteInfoEntryResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof LeadQuoteInfoEntryResource
     */
    status?: LeadQuoteInfoEntryResource.StatusEnum;
    /**
     *
     * @type {string}
     * @memberof LeadQuoteInfoEntryResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof LeadQuoteInfoEntryResource
     */
    paidAt?: string;
    /**
     *
     * @type {string}
     * @memberof LeadQuoteInfoEntryResource
     */
    expiresAt?: string;
    /**
     *
     * @type {string}
     * @memberof LeadQuoteInfoEntryResource
     */
    publishedAt?: string;
    /**
     *
     * @type {LeadPartnerResource}
     * @memberof LeadQuoteInfoEntryResource
     */
    partner?: LeadPartnerResource;
    /**
     * For the given partner and lead,
     * @type {number}
     * @memberof LeadQuoteInfoEntryResource
     */
    partnerLeadOfferId?: number;
    /**
     * Title
     * @type {string}
     * @memberof LeadQuoteInfoEntryResource
     */
    title?: string;
    /**
     * Description
     * @type {string}
     * @memberof LeadQuoteInfoEntryResource
     */
    description?: string;
    /**
     * List of quote items.
     * @type {Array<LeadQuoteItemResource>}
     * @memberof LeadQuoteInfoEntryResource
     */
    items?: Array<LeadQuoteItemResource>;
    /**
     *
     * @type {LeadQuotePaymentResource}
     * @memberof LeadQuoteInfoEntryResource
     */
    payment?: LeadQuotePaymentResource;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuoteInfoEntryResource
     */
    totalVatAmount?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuoteInfoEntryResource
     */
    totalAmountExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuoteInfoEntryResource
     */
    totalAmountInclVat?: AmountResource;
    /**
     *
     * @type {string}
     * @memberof LeadQuoteInfoEntryResource
     */
    reservationExpiresAt?: string;
    /**
     *
     * @type {Array<LeadPartnerActionResource>}
     * @memberof LeadQuoteInfoEntryResource
     */
    leadPartnerActions?: Array<LeadPartnerActionResource>;
}

/**
 * @export
 * @namespace LeadQuoteInfoEntryResource
 */
export namespace LeadQuoteInfoEntryResource {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        New = <any> 'new',
        Accepted = <any> 'accepted',
        Rejected = <any> 'rejected',
        Expired = <any> 'expired',
        Closed = <any> 'closed'
    }
}

/**
 *
 * @export
 * @interface LeadQuoteItemResource
 */
export interface LeadQuoteItemResource {
    /**
     * Id for the Quote Item
     * @type {number}
     * @memberof LeadQuoteItemResource
     */
    id?: number;
    /**
     * Title
     * @type {string}
     * @memberof LeadQuoteItemResource
     */
    title?: string;
    /**
     * When the quote item is created
     * @type {string}
     * @memberof LeadQuoteItemResource
     */
    createdAt?: string;
    /**
     * When the quote item was updated
     * @type {string}
     * @memberof LeadQuoteItemResource
     */
    updatedAt?: string;
    /**
     * Type of quote item
     * @type {string}
     * @memberof LeadQuoteItemResource
     */
    type?: LeadQuoteItemResource.TypeEnum;
    /**
     * Lead product id of quote item
     * @type {number}
     * @memberof LeadQuoteItemResource
     */
    leadProductId?: number;
    /**
     *
     * @type {LeadProductResource}
     * @memberof LeadQuoteItemResource
     */
    leadProduct?: LeadProductResource;
    /**
     * Determine if quote is standard product
     * @type {string}
     * @memberof LeadQuoteItemResource
     */
    isProduct?: string;
    /**
     * Quantity of this item
     * @type {number}
     * @memberof LeadQuoteItemResource
     */
    quantity?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuoteItemResource
     */
    amountVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuoteItemResource
     */
    amountExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuoteItemResource
     */
    amountInclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuoteItemResource
     */
    totalAmountVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuoteItemResource
     */
    totalAmountExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuoteItemResource
     */
    totalAmountInclVat?: AmountResource;
    /**
     *
     * @type {PartnerQuoteProductCommentResource}
     * @memberof LeadQuoteItemResource
     */
    partnerProductComment?: PartnerQuoteProductCommentResource;
}

/**
 * @export
 * @namespace LeadQuoteItemResource
 */
export namespace LeadQuoteItemResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Unknown = <any> 'unknown',
        Unit = <any> 'unit',
        Yearly = <any> 'yearly',
        Quarterly = <any> 'quarterly',
        Monthly = <any> 'monthly',
        Biannually = <any> 'biannually',
        Weekly = <any> 'weekly',
        EverySecondMonth = <any> 'every_second_month'
    }
}

/**
 *
 * @export
 * @interface LeadQuoteItemResult
 */
export interface LeadQuoteItemResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof LeadQuoteItemResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof LeadQuoteItemResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof LeadQuoteItemResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof LeadQuoteItemResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<LeadQuoteItemResource>}
     * @memberof LeadQuoteItemResult
     */
    data?: Array<LeadQuoteItemResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof LeadQuoteItemResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface LeadQuotePaymentResource
 */
export interface LeadQuotePaymentResource {
    /**
     *
     * @type {number}
     * @memberof LeadQuotePaymentResource
     */
    prepaymentPercentage?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuotePaymentResource
     */
    prepaymentVatAmount?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuotePaymentResource
     */
    prepaymentAmountExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuotePaymentResource
     */
    prepaymentAmountInclVat?: AmountResource;
    /**
     *
     * @type {number}
     * @memberof LeadQuotePaymentResource
     */
    revenueSharePercentage?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuotePaymentResource
     */
    revenueShareVatAmount?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuotePaymentResource
     */
    revenueShareAmountExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuotePaymentResource
     */
    revenueShareAmountInclVat?: AmountResource;
    /**
     *
     * @type {number}
     * @memberof LeadQuotePaymentResource
     */
    revenueShareSubsequentYearsPercentage?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuotePaymentResource
     */
    revenueShareSubsequentYearsVatAmount?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuotePaymentResource
     */
    revenueShareSubsequentYearsAmountExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuotePaymentResource
     */
    revenueShareSubsequentYearsAmountInclVat?: AmountResource;
}

/**
 *
 * @export
 * @interface LeadQuoteProgressResource
 */
export interface LeadQuoteProgressResource {
    /**
     * Lead quote progress title
     * @type {string}
     * @memberof LeadQuoteProgressResource
     */
    titles?: string;
}

/**
 *
 * @export
 * @interface LeadQuoteProgressResult
 */
export interface LeadQuoteProgressResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof LeadQuoteProgressResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof LeadQuoteProgressResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof LeadQuoteProgressResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof LeadQuoteProgressResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<LeadQuoteProgressResource>}
     * @memberof LeadQuoteProgressResult
     */
    data?: Array<LeadQuoteProgressResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof LeadQuoteProgressResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface LeadQuoteResource
 */
export interface LeadQuoteResource {
    /**
     * The quote's id.
     * @type {number}
     * @memberof LeadQuoteResource
     */
    id?: number;
    /**
     * Quote status
     * @type {string}
     * @memberof LeadQuoteResource
     */
    status?: LeadQuoteResource.StatusEnum;
    /**
     * Description of progress from partner's perspective.
     * @type {string}
     * @memberof LeadQuoteResource
     */
    progress?: LeadQuoteResource.ProgressEnum;
    /**
     * Created time
     * @type {string}
     * @memberof LeadQuoteResource
     */
    createdAt?: string;
    /**
     * When the quote was updated
     * @type {string}
     * @memberof LeadQuoteResource
     */
    updatedAt?: string;
    /**
     * Quote accepted at
     * @type {string}
     * @memberof LeadQuoteResource
     */
    acceptedAt?: string;
    /**
     * Quote rejected at
     * @type {string}
     * @memberof LeadQuoteResource
     */
    rejectedAt?: string;
    /**
     * Created time
     * @type {string}
     * @memberof LeadQuoteResource
     */
    paidAt?: string;
    /**
     * Created time
     * @type {string}
     * @memberof LeadQuoteResource
     */
    expiresAt?: string;
    /**
     * Created time
     * @type {string}
     * @memberof LeadQuoteResource
     */
    publishedAt?: string;
    /**
     * Lead Id
     * @type {number}
     * @memberof LeadQuoteResource
     */
    leadId?: number;
    /**
     *
     * @type {ProjectLeadResource}
     * @memberof LeadQuoteResource
     */
    lead?: ProjectLeadResource;
    /**
     *
     * @type {PartnerSuggestResource}
     * @memberof LeadQuoteResource
     */
    partner?: PartnerSuggestResource;
    /**
     * For the given partner and lead,
     * @type {number}
     * @memberof LeadQuoteResource
     */
    partnerLeadOfferId?: number;
    /**
     * Partners terms for the given quote
     * @type {string}
     * @memberof LeadQuoteResource
     */
    partnerTerms?: string;
    /**
     * Title
     * @type {string}
     * @memberof LeadQuoteResource
     */
    title?: string;
    /**
     * Description
     * @type {string}
     * @memberof LeadQuoteResource
     */
    description?: string;
    /**
     * Quote Items
     * @type {Array<LeadQuoteItemResource>}
     * @memberof LeadQuoteResource
     */
    items?: Array<LeadQuoteItemResource>;
    /**
     *
     * @type {LeadQuotePaymentResource}
     * @memberof LeadQuoteResource
     */
    payment?: LeadQuotePaymentResource;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuoteResource
     */
    totalVatAmount?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuoteResource
     */
    totalAmountExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuoteResource
     */
    totalAmountInclVat?: AmountResource;
    /**
     *
     * @type {LeadClientResource}
     * @memberof LeadQuoteResource
     */
    client?: LeadClientResource;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuoteResource
     */
    serviceFee?: AmountResource;
    /**
     * Reservation time expiry date
     * @type {string}
     * @memberof LeadQuoteResource
     */
    reservationExpiresAt?: string;
    /**
     * Refusal time expiry date
     * @type {string}
     * @memberof LeadQuoteResource
     */
    refusalExpiresAt?: string;
    /**
     * Define if quote can be refused
     * @type {boolean}
     * @memberof LeadQuoteResource
     */
    canBeRefused?: boolean;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuoteResource
     */
    revenueShareAmountExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuoteResource
     */
    revenueShareSubsequentYearsAmountExclVat?: AmountResource;
    /**
     * If the total quote amount is below the minimum quote amount specified on the lead
     * @type {boolean}
     * @memberof LeadQuoteResource
     */
    isBelowMinimumAmount?: boolean;
    /**
     *
     * @type {ActionerResource}
     * @memberof LeadQuoteResource
     */
    acceptedByActioner?: ActionerResource;
    /**
     * List of lead products.
     * @type {Array<LeadProductResource>}
     * @memberof LeadQuoteResource
     */
    leadProducts?: Array<LeadProductResource>;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadQuoteResource
     */
    quoteFeeAmountExclVat?: AmountResource;
}

/**
 * @export
 * @namespace LeadQuoteResource
 */
export namespace LeadQuoteResource {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        New = <any> 'new',
        Accepted = <any> 'accepted',
        Rejected = <any> 'rejected',
        Expired = <any> 'expired',
        Closed = <any> 'closed'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum ProgressEnum {
        Unknown = <any> 'unknown',
        QuoteProvided = <any> 'quote_provided',
        BidRoundClosed = <any> 'bid_round_closed',
        PendingDecision = <any> 'pending_decision',
        Won = <any> 'won',
        Lost = <any> 'lost',
        Draft = <any> 'draft'
    }
}

/**
 *
 * @export
 * @interface LeadQuoteResult
 */
export interface LeadQuoteResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof LeadQuoteResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof LeadQuoteResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof LeadQuoteResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof LeadQuoteResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<LeadQuoteResource>}
     * @memberof LeadQuoteResult
     */
    data?: Array<LeadQuoteResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof LeadQuoteResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface LeadQuotesInfoResource
 */
export interface LeadQuotesInfoResource {
    /**
     * Max Offer count.
     * @type {number}
     * @memberof LeadQuotesInfoResource
     */
    max?: number;
    /**
     * Number of offers.
     * @type {number}
     * @memberof LeadQuotesInfoResource
     */
    count?: number;
    /**
     * Number of reserved spots.
     * @type {number}
     * @memberof LeadQuotesInfoResource
     */
    reserved?: number;
    /**
     * Open for quotes.
     * @type {boolean}
     * @memberof LeadQuotesInfoResource
     */
    openForQuotes?: boolean;
    /**
     * Status of the reservation
     * @type {boolean}
     * @memberof LeadQuotesInfoResource
     */
    openForReservations?: boolean;
    /**
     *
     * @type {LeadQuoteInfoEntryResource}
     * @memberof LeadQuotesInfoResource
     */
    acceptedQuote?: LeadQuoteInfoEntryResource;
    /**
     * List of offers.
     * @type {Array<LeadQuoteInfoEntryResource>}
     * @memberof LeadQuotesInfoResource
     */
    quotes?: Array<LeadQuoteInfoEntryResource>;
    /**
     * Date the client match decision is postponed to.
     * @type {string}
     * @memberof LeadQuotesInfoResource
     */
    clientDecisionPostponedTo?: string;
    /**
     * Reason the client postponed the decision.
     * @type {string}
     * @memberof LeadQuotesInfoResource
     */
    clientDecisionPostponedReason?: string;
    /**
     *
     * @type {EmployeeLeadPredictionResource}
     * @memberof LeadQuotesInfoResource
     */
    employeeLeadPrediction?: EmployeeLeadPredictionResource;
    /**
     *
     * @type {LeadQuoteFeeResource}
     * @memberof LeadQuotesInfoResource
     */
    quoteFee?: LeadQuoteFeeResource;
}

/**
 *
 * @export
 * @interface LeadRequirementsResource
 */
export interface LeadRequirementsResource {
    /**
     * Was it a local partner?
     * @type {string}
     * @memberof LeadRequirementsResource
     */
    localPartner?: string;
    /**
     * When can the given Lead be contacted.
     * @type {string}
     * @memberof LeadRequirementsResource
     */
    contactAvailableAt?: string;
    /**
     * Should the lead be contacted by phone?
     * @type {boolean}
     * @memberof LeadRequirementsResource
     */
    contactByPhone?: boolean;
    /**
     * Should the lead the contacted by email?
     * @type {boolean}
     * @memberof LeadRequirementsResource
     */
    contactByEmail?: boolean;
}

/**
 *
 * @export
 * @interface LeadResource
 */
export interface LeadResource {
    /**
     * Id for the Lead.
     * @type {number}
     * @memberof LeadResource
     */
    id?: number;
    /**
     * Date the Lead was created.
     * @type {string}
     * @memberof LeadResource
     */
    createdAt?: string;
    /**
     * Date the Lead was updated.
     * @type {string}
     * @memberof LeadResource
     */
    updatedAt?: string;
    /**
     * Date the Lead was Updated.
     * @type {string}
     * @memberof LeadResource
     */
    validatedAt?: string;
    /**
     * Date the Lead Expires.
     * @type {string}
     * @memberof LeadResource
     */
    expiresAt?: string;
    /**
     * Date where a lead has been extended/boosted.
     * @type {string}
     * @memberof LeadResource
     */
    expirationBoostedAt?: string;
    /**
     * Date the Lead Expires taking into account only working hours.
     * @type {string}
     * @memberof LeadResource
     */
    workingHoursExpiresAt?: string;
    /**
     * Current Validation Status of the Lead.
     * @type {string}
     * @memberof LeadResource
     */
    status?: LeadResource.StatusEnum;
    /**
     * Current reservation status of the Lead.
     * @type {string}
     * @memberof LeadResource
     */
    reservationStatus?: string;
    /**
     * Progress
     * @type {Array<LeadProgressResource>}
     * @memberof LeadResource
     */
    progress?: Array<LeadProgressResource>;
    /**
     * Title for the Lead.
     * @type {string}
     * @memberof LeadResource
     */
    title?: string;
    /**
     * A description of the given Lead.
     * @type {string}
     * @memberof LeadResource
     */
    description?: string;
    /**
     *
     * @type {string}
     * @memberof LeadResource
     */
    marketplaceDescription?: string;
    /**
     *
     * @type {LeadClientResource}
     * @memberof LeadResource
     */
    client?: LeadClientResource;
    /**
     *
     * @type {LeadCustomerInfoResource}
     * @memberof LeadResource
     */
    customerInfo?: LeadCustomerInfoResource;
    /**
     *
     * @type {LeadRequirementsResource}
     * @memberof LeadResource
     */
    requirements?: LeadRequirementsResource;
    /**
     * The price for the given lead.
     * @type {number}
     * @memberof LeadResource
     */
    punchPrice?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadResource
     */
    serviceFee?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadResource
     */
    serviceFeeSubsequentYearsAmountExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadResource
     */
    minimumQuoteAmountExclVat?: AmountResource;
    /**
     *
     * @type {LeadGeoResource}
     * @memberof LeadResource
     */
    geo?: LeadGeoResource;
    /**
     * Attributes for the lead.
     * @type {Array<LeadAttributeResource>}
     * @memberof LeadResource
     */
    attributes?: Array<LeadAttributeResource>;
    /**
     *
     * @type {LeadIndustriesResource}
     * @memberof LeadResource
     */
    industries?: LeadIndustriesResource;
    /**
     * What products are connected to this Lead.
     * @type {Array<LeadProductResource>}
     * @memberof LeadResource
     */
    products?: Array<LeadProductResource>;
    /**
     *
     * @type {LeadMeetingResource}
     * @memberof LeadResource
     */
    meeting?: LeadMeetingResource;
    /**
     *
     * @type {LeadAcceptedResource}
     * @memberof LeadResource
     */
    accepted?: LeadAcceptedResource;
    /**
     *
     * @type {LeadOffersInfoResource}
     * @memberof LeadResource
     */
    offersInfo?: LeadOffersInfoResource;
    /**
     *
     * @type {LeadQuotesInfoResource}
     * @memberof LeadResource
     */
    quotesInfo?: LeadQuotesInfoResource;
    /**
     *
     * @type {LeadAllocationSuggestionsInfoResource}
     * @memberof LeadResource
     */
    allocationSuggestionsInfo?: LeadAllocationSuggestionsInfoResource;
    /**
     *
     * @type {AffiliateCampaignResource}
     * @memberof LeadResource
     */
    affiliateCampaign?: AffiliateCampaignResource;
    /**
     *
     * @type {LeadFeedbackResource}
     * @memberof LeadResource
     */
    feedback?: LeadFeedbackResource;
    /**
     *
     * @type {LeadEmployeeResource}
     * @memberof LeadResource
     */
    validator?: LeadEmployeeResource;
    /**
     * Colleciton of custom key-value pairs.
     * @type {Array<KeyValueResource>}
     * @memberof LeadResource
     */
    customVariables?: Array<KeyValueResource>;
    /**
     *
     * @type {LeadCategoryResource}
     * @memberof LeadResource
     */
    category?: LeadCategoryResource;
    /**
     *
     * @type {AmountResource}
     * @memberof LeadResource
     */
    revenueAmountExclVat?: AmountResource;
    /**
     * Does this lead have a service fee
     * @type {boolean}
     * @memberof LeadResource
     */
    isServiceFee?: boolean;
    /**
     * Does the lead want a local partner
     * @type {boolean}
     * @memberof LeadResource
     */
    wantsLocalPartner?: boolean;
    /**
     * Digital communication?
     * @type {boolean}
     * @memberof LeadResource
     */
    isDigital?: boolean;
    /**
     * Does the lead optin for email communication with Ageras?
     * @type {boolean}
     * @memberof LeadResource
     */
    emailOptin?: boolean;
    /**
     *
     * @type {LeadActionsInfoResource}
     * @memberof LeadResource
     */
    actionsInfo?: LeadActionsInfoResource;
    /**
     * Range the lead is happy to travel to reach a partner.
     * @type {number}
     * @memberof LeadResource
     */
    geoRange?: number;
    /**
     *
     * @type {SectorResource}
     * @memberof LeadResource
     */
    primarySector?: SectorResource;
    /**
     *
     * @type {RevenueSegmentResource}
     * @memberof LeadResource
     */
    revenueSegment?: RevenueSegmentResource;
    /**
     *
     * @type {CompanySizeResource}
     * @memberof LeadResource
     */
    desiredPartnerCompanySize?: CompanySizeResource;
    /**
     *
     * @type {PersonalTitleResource}
     * @memberof LeadResource
     */
    personalTitle?: PersonalTitleResource;
    /**
     *
     * @type {LeadMarketplaceResource}
     * @memberof LeadResource
     */
    marketplace?: LeadMarketplaceResource;
}

/**
 * @export
 * @namespace LeadResource
 */
export namespace LeadResource {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Unknown = <any> 'unknown',
        Duplicate = <any> 'duplicate',
        Test = <any> 'test',
        NotSerious = <any> 'not_serious',
        SendToAdvisor = <any> 'send_to_advisor',
        Spam = <any> 'spam',
        Validated = <any> 'validated',
        Untreated = <any> 'untreated'
    }
}

/**
 *
 * @export
 * @interface LeadResult
 */
export interface LeadResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof LeadResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof LeadResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof LeadResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof LeadResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<LeadResource>}
     * @memberof LeadResult
     */
    data?: Array<LeadResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof LeadResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface LeadSuggestResource
 */
export interface LeadSuggestResource {
    /**
     * Id for the suggested Lead.
     * @type {number}
     * @memberof LeadSuggestResource
     */
    id?: number;
    /**
     * Title for the suggested Lead.
     * @type {string}
     * @memberof LeadSuggestResource
     */
    title?: string;
    /**
     * Status for the suggested lead.
     * @type {string}
     * @memberof LeadSuggestResource
     */
    status?: LeadSuggestResource.StatusEnum;
    /**
     * Zip Code for the suggested lead.
     * @type {string}
     * @memberof LeadSuggestResource
     */
    zipCode?: string;
    /**
     * City for the suggested lead.
     * @type {string}
     * @memberof LeadSuggestResource
     */
    city?: string;
    /**
     * Location for the suggested Lead.
     * @type {string}
     * @memberof LeadSuggestResource
     */
    geoCode?: string;
    /**
     * Extra text for suggested lead. @var string
     * @type {string}
     * @memberof LeadSuggestResource
     */
    text?: string;
    /**
     * Lead's full name.
     * @type {string}
     * @memberof LeadSuggestResource
     */
    fullName?: string;
}

/**
 * @export
 * @namespace LeadSuggestResource
 */
export namespace LeadSuggestResource {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Unknown = <any> 'unknown',
        Duplicate = <any> 'duplicate',
        Test = <any> 'test',
        NotSerious = <any> 'not_serious',
        SendToAdvisor = <any> 'send_to_advisor',
        Spam = <any> 'spam',
        Validated = <any> 'validated',
        Untreated = <any> 'untreated'
    }
}

/**
 *
 * @export
 * @interface LeadTypeGroupResource
 */
export interface LeadTypeGroupResource {
    /**
     *
     * @type {number}
     * @memberof LeadTypeGroupResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof LeadTypeGroupResource
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof LeadTypeGroupResource
     */
    geoCode?: string;
    /**
     *
     * @type {Array<LeadTypeResource>}
     * @memberof LeadTypeGroupResource
     */
    types?: Array<LeadTypeResource>;
}

/**
 *
 * @export
 * @interface LeadTypeGroupResult
 */
export interface LeadTypeGroupResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof LeadTypeGroupResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof LeadTypeGroupResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof LeadTypeGroupResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof LeadTypeGroupResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<LeadTypeGroupResource>}
     * @memberof LeadTypeGroupResult
     */
    data?: Array<LeadTypeGroupResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof LeadTypeGroupResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface LeadTypeResource
 */
export interface LeadTypeResource {
    /**
     * Type's ID. @var int
     * @type {number}
     * @memberof LeadTypeResource
     */
    id?: number;
    /**
     * Type's name. @var string
     * @type {string}
     * @memberof LeadTypeResource
     */
    title?: string;
    /**
     * Type geo code. @var string
     * @type {string}
     * @memberof LeadTypeResource
     */
    geoCode?: string;
    /**
     * Created at time stamp. @var Carbon
     * @type {string}
     * @memberof LeadTypeResource
     */
    createdAt?: string;
    /**
     * Archived at time stamp. @var Carbon
     * @type {string}
     * @memberof LeadTypeResource
     */
    archivedAt?: string;
    /**
     * Type's grouping ID. @var int
     * @type {number}
     * @memberof LeadTypeResource
     */
    typeGroupId?: number;
}

/**
 *
 * @export
 * @interface LeadTypeResult
 */
export interface LeadTypeResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof LeadTypeResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof LeadTypeResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof LeadTypeResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof LeadTypeResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<LeadTypeResource>}
     * @memberof LeadTypeResult
     */
    data?: Array<LeadTypeResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof LeadTypeResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface LocationGeoPointResource
 */
export interface LocationGeoPointResource {
    /**
     * latitude.
     * @type {number}
     * @memberof LocationGeoPointResource
     */
    lat?: number;
    /**
     * longitude.
     * @type {number}
     * @memberof LocationGeoPointResource
     */
    lon?: number;
}

/**
 *
 * @export
 * @interface LocationResource
 */
export interface LocationResource {
    /**
     * id for resource
     * @type {number}
     * @memberof LocationResource
     */
    id?: number;
    /**
     * Code for the given location.
     * @type {string}
     * @memberof LocationResource
     */
    code?: string;
    /**
     * url slug
     * @type {string}
     * @memberof LocationResource
     */
    slug?: string;
    /**
     * Name of the country
     * @type {string}
     * @memberof LocationResource
     */
    countryName?: string;
    /**
     * Zip Code.
     * @type {string}
     * @memberof LocationResource
     */
    zipCode?: string;
    /**
     * Name of the city.
     * @type {string}
     * @memberof LocationResource
     */
    cityName?: string;
    /**
     * District the location is part of
     * @type {string}
     * @memberof LocationResource
     */
    cityDistrict?: string;
    /**
     * The address.
     * @type {string}
     * @memberof LocationResource
     */
    address?: string;
    /**
     *
     * @type {LocationGeoPointResource}
     * @memberof LocationResource
     */
    point?: LocationGeoPointResource;
    /**
     * Name of the region
     * @type {string}
     * @memberof LocationResource
     */
    regionName?: string;
    /**
     * Region abbreviation
     * @type {string}
     * @memberof LocationResource
     */
    regionAbbreviation?: string;
    /**
     * Name of the sub region
     * @type {string}
     * @memberof LocationResource
     */
    subRegionName?: string;
}

/**
 *
 * @export
 * @interface LocationResult
 */
export interface LocationResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof LocationResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof LocationResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof LocationResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof LocationResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<LocationResource>}
     * @memberof LocationResult
     */
    data?: Array<LocationResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof LocationResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface MatchResource
 */
export interface MatchResource {
    /**
     * Geo Location Geo
     * @type {string}
     * @memberof MatchResource
     */
    geoCode?: string;
    /**
     * Channel
     * @type {string}
     * @memberof MatchResource
     */
    matchChannel?: string;
    /**
     * partner involved in the match
     * @type {number}
     * @memberof MatchResource
     */
    partnerId?: number;
    /**
     * lead involved in the match
     * @type {number}
     * @memberof MatchResource
     */
    leadId?: number;
    /**
     * Validator involved in the match
     * @type {number}
     * @memberof MatchResource
     */
    validatorId?: number;
    /**
     * Date of match
     * @type {string}
     * @memberof MatchResource
     */
    acceptedAt?: string;
    /**
     * Date of unmatch
     * @type {string}
     * @memberof MatchResource
     */
    unacceptedAt?: string;
}

/**
 *
 * @export
 * @interface MatchResult
 */
export interface MatchResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof MatchResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof MatchResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof MatchResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof MatchResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<MatchResource>}
     * @memberof MatchResult
     */
    data?: Array<MatchResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof MatchResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface MeetingActionResource
 */
export interface MeetingActionResource {
    /**
     *
     * @type {string}
     * @memberof MeetingActionResource
     */
    action?: MeetingActionResource.ActionEnum;
    /**
     *
     * @type {Array<MeetingDateResource>}
     * @memberof MeetingActionResource
     */
    dates?: Array<MeetingDateResource>;
    /**
     *
     * @type {Array<MeetingSlotDateResource>}
     * @memberof MeetingActionResource
     */
    slotDates?: Array<MeetingSlotDateResource>;
    /**
     *
     * @type {number}
     * @memberof MeetingActionResource
     */
    partnerId?: number;
    /**
     *
     * @type {Array<number>}
     * @memberof MeetingActionResource
     */
    partnerIds?: Array<number>;
    /**
     *
     * @type {number}
     * @memberof MeetingActionResource
     */
    leadId?: number;
}

/**
 * @export
 * @namespace MeetingActionResource
 */
export namespace MeetingActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        Meeting = <any> 'request_meeting',
        MeetingWithSlots = <any> 'request_meeting_with_slots'
    }
}

/**
 *
 * @export
 * @interface MeetingDateResource
 */
export interface MeetingDateResource {
    /**
     *
     * @type {string}
     * @memberof MeetingDateResource
     */
    date?: string;
}

/**
 *
 * @export
 * @interface MeetingSlotDateResource
 */
export interface MeetingSlotDateResource {
    /**
     *
     * @type {string}
     * @memberof MeetingSlotDateResource
     */
    from?: string;
    /**
     *
     * @type {string}
     * @memberof MeetingSlotDateResource
     */
    to?: string;
}

/**
 *
 * @export
 * @interface MessageResource
 */
export interface MessageResource {
    /**
     *
     * @type {number}
     * @memberof MessageResource
     */
    id?: number;
    /**
     *
     * @type {number}
     * @memberof MessageResource
     */
    conversationId?: number;
    /**
     *
     * @type {number}
     * @memberof MessageResource
     */
    replyToMessageId?: number;
    /**
     *
     * @type {string}
     * @memberof MessageResource
     */
    text?: string;
    /**
     *
     * @type {ParticipantResource}
     * @memberof MessageResource
     */
    participant?: ParticipantResource;
    /**
     *
     * @type {Array<RecipientResource>}
     * @memberof MessageResource
     */
    recipients?: Array<RecipientResource>;
    /**
     *
     * @type {Array<AttachmentResource>}
     * @memberof MessageResource
     */
    attachments?: Array<AttachmentResource>;
    /**
     *
     * @type {number}
     * @memberof MessageResource
     */
    projectId?: number;
    /**
     *
     * @type {string}
     * @memberof MessageResource
     */
    seenAt?: string;
    /**
     *
     * @type {string}
     * @memberof MessageResource
     */
    readAt?: string;
    /**
     *
     * @type {string}
     * @memberof MessageResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MessageResource
     */
    updatedAt?: string;
}

/**
 *
 * @export
 * @interface MessageResult
 */
export interface MessageResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof MessageResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof MessageResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof MessageResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof MessageResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<MessageResource>}
     * @memberof MessageResult
     */
    data?: Array<MessageResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof MessageResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface NestedContractOfferGroupResource
 */
export interface NestedContractOfferGroupResource {
    /**
     * The offer group id.
     * @type {number}
     * @memberof NestedContractOfferGroupResource
     */
    id?: number;
}

/**
 *
 * @export
 * @interface NestedPartnerContractOfferResource
 */
export interface NestedPartnerContractOfferResource {
    /**
     * The partner id.
     * @type {number}
     * @memberof NestedPartnerContractOfferResource
     */
    id?: number;
}

/**
 *
 * @export
 * @interface NestedPaymentMethodResource
 */
export interface NestedPaymentMethodResource {
    /**
     * Id of the payment method.
     * @type {number}
     * @memberof NestedPaymentMethodResource
     */
    id?: number;
    /**
     * Stating if the payment method is the partner's preferred one.
     * @type {boolean}
     * @memberof NestedPaymentMethodResource
     */
    isPreferred?: boolean;
    /**
     * Owning partner id.
     * @type {number}
     * @memberof NestedPaymentMethodResource
     */
    partnerId?: number;
}

/**
 *
 * @export
 * @interface NotificationActionResource
 */
export interface NotificationActionResource {
    /**
     *
     * @type {Array<number>}
     * @memberof NotificationActionResource
     */
    notificationIds?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof NotificationActionResource
     */
    action?: NotificationActionResource.ActionEnum;
}

/**
 * @export
 * @namespace NotificationActionResource
 */
export namespace NotificationActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        Unknown = <any> 'unknown',
        MarkSeen = <any> 'mark_seen',
        MarkRead = <any> 'mark_read'
    }
}

/**
 *
 * @export
 * @interface NotificationRecipientResource
 */
export interface NotificationRecipientResource {
    /**
     * Recipient type
     * @type {string}
     * @memberof NotificationRecipientResource
     */
    type?: NotificationRecipientResource.TypeEnum;
    /**
     *
     * @type {PartnerUserResource}
     * @memberof NotificationRecipientResource
     */
    partnerUser?: PartnerUserResource;
}

/**
 * @export
 * @namespace NotificationRecipientResource
 */
export namespace NotificationRecipientResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Unknown = <any> 'unknown',
        PartnerUser = <any> 'partner_user'
    }
}

/**
 *
 * @export
 * @interface NotificationResource
 */
export interface NotificationResource {
    /**
     * Id
     * @type {number}
     * @memberof NotificationResource
     */
    id?: number;
    /**
     * Notification type
     * @type {string}
     * @memberof NotificationResource
     */
    type?: string;
    /**
     *
     * @type {NotificationTriggerResource}
     * @memberof NotificationResource
     */
    trigger?: NotificationTriggerResource;
    /**
     *
     * @type {NotificationRecipientResource}
     * @memberof NotificationResource
     */
    recipient?: NotificationRecipientResource;
    /**
     *
     * @type {NotificationSubjectResource}
     * @memberof NotificationResource
     */
    subject?: NotificationSubjectResource;
    /**
     * Seen at
     * @type {string}
     * @memberof NotificationResource
     */
    seenAt?: string;
    /**
     * Read at
     * @type {string}
     * @memberof NotificationResource
     */
    readAt?: string;
    /**
     * Created at
     * @type {string}
     * @memberof NotificationResource
     */
    createdAt?: string;
    /**
     * Updated at
     * @type {string}
     * @memberof NotificationResource
     */
    updatedAt?: string;
}

/**
 *
 * @export
 * @interface NotificationResult
 */
export interface NotificationResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof NotificationResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof NotificationResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof NotificationResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof NotificationResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<NotificationResource>}
     * @memberof NotificationResult
     */
    data?: Array<NotificationResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof NotificationResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface NotificationSubjectResource
 */
export interface NotificationSubjectResource {
    /**
     * Subject type
     * @type {string}
     * @memberof NotificationSubjectResource
     */
    type?: NotificationSubjectResource.TypeEnum;
    /**
     *
     * @type {MessageResource}
     * @memberof NotificationSubjectResource
     */
    message?: MessageResource;
}

/**
 * @export
 * @namespace NotificationSubjectResource
 */
export namespace NotificationSubjectResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Unknown = <any> 'unknown',
        Message = <any> 'message'
    }
}

/**
 *
 * @export
 * @interface NotificationTriggerResource
 */
export interface NotificationTriggerResource {
    /**
     * Trigger type
     * @type {string}
     * @memberof NotificationTriggerResource
     */
    type?: NotificationTriggerResource.TypeEnum;
    /**
     *
     * @type {PartnerUserResource}
     * @memberof NotificationTriggerResource
     */
    partnerUser?: PartnerUserResource;
    /**
     *
     * @type {EmployeeResource}
     * @memberof NotificationTriggerResource
     */
    employee?: EmployeeResource;
    /**
     *
     * @type {ProjectCustomerResource}
     * @memberof NotificationTriggerResource
     */
    projectCustomer?: ProjectCustomerResource;
    /**
     *
     * @type {ClientResource}
     * @memberof NotificationTriggerResource
     */
    client?: ClientResource;
}

/**
 * @export
 * @namespace NotificationTriggerResource
 */
export namespace NotificationTriggerResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Unknown = <any> 'unknown',
        Client = <any> 'client',
        Employee = <any> 'employee',
        PartnerUser = <any> 'partner_user',
        ProjectCustomer = <any> 'project_customer'
    }
}

/**
 *
 * @export
 * @interface OfferActionResource
 */
export interface OfferActionResource {
    /**
     *
     * @type {number}
     * @memberof OfferActionResource
     */
    partnerId?: number;
    /**
     *
     * @type {string}
     * @memberof OfferActionResource
     */
    action?: OfferActionResource.ActionEnum;
}

/**
 * @export
 * @namespace OfferActionResource
 */
export namespace OfferActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        AcceptOffer = <any> 'accept-offer',
        Activate = <any> 'activate',
        Deactivate = <any> 'deactivate'
    }
}

/**
 *
 * @export
 * @interface OrderActionResource
 */
export interface OrderActionResource {
    /**
     *
     * @type {number}
     * @memberof OrderActionResource
     */
    quoteId?: number;
    /**
     *
     * @type {string}
     * @memberof OrderActionResource
     */
    action?: OrderActionResource.ActionEnum;
    /**
     *
     * @type {string}
     * @memberof OrderActionResource
     */
    date?: string;
}

/**
 * @export
 * @namespace OrderActionResource
 */
export namespace OrderActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        Generate = <any> 'generate'
    }
}

/**
 *
 * @export
 * @interface OrderClientResource
 */
export interface OrderClientResource {
    /**
     * Client id.
     * @type {number}
     * @memberof OrderClientResource
     */
    id?: number;
    /**
     * Client First Name
     * @type {string}
     * @memberof OrderClientResource
     */
    firstName?: string;
    /**
     * Clients Last Name
     * @type {string}
     * @memberof OrderClientResource
     */
    lastName?: string;
    /**
     * Clients company name
     * @type {string}
     * @memberof OrderClientResource
     */
    companyName?: string;
}

/**
 *
 * @export
 * @interface OrderConsumerResource
 */
export interface OrderConsumerResource {
    /**
     * Type of consumer for the order
     * @type {string}
     * @memberof OrderConsumerResource
     */
    type?: OrderConsumerResource.TypeEnum;
    /**
     *
     * @type {OrderPartnerResource}
     * @memberof OrderConsumerResource
     */
    partner?: OrderPartnerResource;
    /**
     *
     * @type {OrderPartnerUserResource}
     * @memberof OrderConsumerResource
     */
    partnerUser?: OrderPartnerUserResource;
    /**
     *
     * @type {OrderClientResource}
     * @memberof OrderConsumerResource
     */
    client?: OrderClientResource;
    /**
     *
     * @type {OrderEmployeeResource}
     * @memberof OrderConsumerResource
     */
    employee?: OrderEmployeeResource;
}

/**
 * @export
 * @namespace OrderConsumerResource
 */
export namespace OrderConsumerResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Unknown = <any> 'unknown',
        Partner = <any> 'partner',
        PartnerUser = <any> 'partner_user',
        Employee = <any> 'employee',
        Client = <any> 'client'
    }
}

/**
 *
 * @export
 * @interface OrderDiscountResource
 */
export interface OrderDiscountResource {
    /**
     * Discount Id
     * @type {number}
     * @memberof OrderDiscountResource
     */
    id?: number;
    /**
     * Name of the Discount
     * @type {string}
     * @memberof OrderDiscountResource
     */
    name?: string;
}

/**
 *
 * @export
 * @interface OrderEmployeeResource
 */
export interface OrderEmployeeResource {
    /**
     * Employee id.
     * @type {number}
     * @memberof OrderEmployeeResource
     */
    id?: number;
    /**
     * Employee First Name
     * @type {string}
     * @memberof OrderEmployeeResource
     */
    firstName?: string;
    /**
     * Employee Last Name
     * @type {string}
     * @memberof OrderEmployeeResource
     */
    lastName?: string;
}

/**
 *
 * @export
 * @interface OrderInstallmentResource
 */
export interface OrderInstallmentResource {
    /**
     *
     * @type {string}
     * @memberof OrderInstallmentResource
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OrderInstallmentResource
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof OrderInstallmentResource
     */
    name?: string;
    /**
     *
     * @type {number}
     * @memberof OrderInstallmentResource
     */
    vat?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof OrderInstallmentResource
     */
    amountVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof OrderInstallmentResource
     */
    amountExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof OrderInstallmentResource
     */
    amountInclVat?: AmountResource;
    /**
     *
     * @type {PaymentMethodResource}
     * @memberof OrderInstallmentResource
     */
    paymentSolution?: PaymentMethodResource;
    /**
     *
     * @type {PaymentProviderResource}
     * @memberof OrderInstallmentResource
     */
    paymentProvider?: PaymentProviderResource;
    /**
     *
     * @type {PaymentCardResource}
     * @memberof OrderInstallmentResource
     */
    paymentCard?: PaymentCardResource;
    /**
     *
     * @type {boolean}
     * @memberof OrderInstallmentResource
     */
    readyForPayment?: boolean;
    /**
     *
     * @type {string}
     * @memberof OrderInstallmentResource
     */
    paidAt?: string;
    /**
     *
     * @type {string}
     * @memberof OrderInstallmentResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof OrderInstallmentResource
     */
    updatedAt?: string;
}

/**
 *
 * @export
 * @interface OrderItemResource
 */
export interface OrderItemResource {
    /**
     * Type of consumer for the order
     * @type {string}
     * @memberof OrderItemResource
     */
    type?: OrderItemResource.TypeEnum;
    /**
     *
     * @type {OrderPartnerLeadQuoteResource}
     * @memberof OrderItemResource
     */
    quote?: OrderPartnerLeadQuoteResource;
    /**
     *
     * @type {OrderPackageResource}
     * @memberof OrderItemResource
     */
    _package?: OrderPackageResource;
    /**
     *
     * @type {OrderDiscountResource}
     * @memberof OrderItemResource
     */
    discount?: OrderDiscountResource;
}

/**
 * @export
 * @namespace OrderItemResource
 */
export namespace OrderItemResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Unknown = <any> 'unknown',
        Quote = <any> 'quote',
        Package = <any> 'package',
        Discount = <any> 'discount'
    }
}

/**
 *
 * @export
 * @interface OrderLineResource
 */
export interface OrderLineResource {
    /**
     * id for the line
     * @type {number}
     * @memberof OrderLineResource
     */
    id?: number;
    /**
     *
     * @type {OrderItemResource}
     * @memberof OrderLineResource
     */
    item?: OrderItemResource;
    /**
     * Quantity per price.
     * @type {number}
     * @memberof OrderLineResource
     */
    quantity?: number;
    /**
     * Title for the line
     * @type {string}
     * @memberof OrderLineResource
     */
    name?: string;
    /**
     * Vat percent on item
     * @type {number}
     * @memberof OrderLineResource
     */
    itemVatPercent?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof OrderLineResource
     */
    itemVatAmount?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof OrderLineResource
     */
    itemPriceInclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof OrderLineResource
     */
    itemPriceExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof OrderLineResource
     */
    vatAmount?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof OrderLineResource
     */
    priceInclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof OrderLineResource
     */
    priceExclVat?: AmountResource;
    /**
     *
     * @type {OrderLineSellerResource}
     * @memberof OrderLineResource
     */
    seller?: OrderLineSellerResource;
}

/**
 *
 * @export
 * @interface OrderLineSellerResource
 */
export interface OrderLineSellerResource {
    /**
     * Type of seller for the order
     * @type {string}
     * @memberof OrderLineSellerResource
     */
    type?: OrderLineSellerResource.TypeEnum;
    /**
     *
     * @type {OrderPartnerResource}
     * @memberof OrderLineSellerResource
     */
    partner?: OrderPartnerResource;
}

/**
 * @export
 * @namespace OrderLineSellerResource
 */
export namespace OrderLineSellerResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Unknown = <any> 'unknown',
        Partner = <any> 'partner'
    }
}

/**
 *
 * @export
 * @interface OrderPackageResource
 */
export interface OrderPackageResource {
    /**
     * Package Id
     * @type {number}
     * @memberof OrderPackageResource
     */
    id?: number;
    /**
     * Name of the Package
     * @type {string}
     * @memberof OrderPackageResource
     */
    name?: string;
}

/**
 *
 * @export
 * @interface OrderPartnerLeadQuoteResource
 */
export interface OrderPartnerLeadQuoteResource {
    /**
     * Lead quote id id.
     * @type {number}
     * @memberof OrderPartnerLeadQuoteResource
     */
    id?: number;
    /**
     * Name of the quote
     * @type {string}
     * @memberof OrderPartnerLeadQuoteResource
     */
    name?: string;
}

/**
 *
 * @export
 * @interface OrderPartnerResource
 */
export interface OrderPartnerResource {
    /**
     * Partner id.
     * @type {number}
     * @memberof OrderPartnerResource
     */
    id?: number;
    /**
     * Partner company name
     * @type {string}
     * @memberof OrderPartnerResource
     */
    companyName?: string;
}

/**
 *
 * @export
 * @interface OrderPartnerUserResource
 */
export interface OrderPartnerUserResource {
    /**
     * Employee id.
     * @type {number}
     * @memberof OrderPartnerUserResource
     */
    id?: number;
    /**
     * Employee First Name
     * @type {string}
     * @memberof OrderPartnerUserResource
     */
    firstName?: string;
    /**
     * Employee Last Name
     * @type {string}
     * @memberof OrderPartnerUserResource
     */
    lastName?: string;
}

/**
 *
 * @export
 * @interface OrderResource
 */
export interface OrderResource {
    /**
     * Order ID.
     * @type {string}
     * @memberof OrderResource
     */
    id?: string;
    /**
     * order geo code
     * @type {string}
     * @memberof OrderResource
     */
    geoCode?: string;
    /**
     * order status
     * @type {string}
     * @memberof OrderResource
     */
    status?: string;
    /**
     * Order creation date.
     * @type {string}
     * @memberof OrderResource
     */
    createdAt?: string;
    /**
     * Order Last update.
     * @type {string}
     * @memberof OrderResource
     */
    updatedAt?: string;
    /**
     * Order payment date.
     * @type {string}
     * @memberof OrderResource
     */
    paidAt?: string;
    /**
     * Order due date
     * @type {string}
     * @memberof OrderResource
     */
    dueAt?: string;
    /**
     *
     * @type {OrderConsumerResource}
     * @memberof OrderResource
     */
    buyer?: OrderConsumerResource;
    /**
     * order lines
     * @type {Array<OrderLineResource>}
     * @memberof OrderResource
     */
    lines?: Array<OrderLineResource>;
    /**
     * Installments.
     * @type {Array<OrderInstallmentResource>}
     * @memberof OrderResource
     */
    installments?: Array<OrderInstallmentResource>;
    /**
     *
     * @type {AmountResource}
     * @memberof OrderResource
     */
    totalVatAmount?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof OrderResource
     */
    totalAmountExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof OrderResource
     */
    totalAmountInclVat?: AmountResource;
}

/**
 *
 * @export
 * @interface OrderResult
 */
export interface OrderResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof OrderResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof OrderResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof OrderResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof OrderResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<OrderResource>}
     * @memberof OrderResult
     */
    data?: Array<OrderResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof OrderResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PackageFeatureResource
 */
export interface PackageFeatureResource {
    /**
     * ID of the feature.
     * @type {number}
     * @memberof PackageFeatureResource
     */
    id?: number;
    /**
     * String identifier of the feature.
     * @type {string}
     * @memberof PackageFeatureResource
     */
    identifier?: string;
    /**
     * Package name.
     * @type {string}
     * @memberof PackageFeatureResource
     */
    name?: string;
    /**
     * Description.
     * @type {string}
     * @memberof PackageFeatureResource
     */
    description?: string;
    /**
     * Priority or sort order.
     * @type {number}
     * @memberof PackageFeatureResource
     */
    priority?: number;
    /**
     * Whether or not the feature is hidden.
     * @type {string}
     * @memberof PackageFeatureResource
     */
    isHidden?: string;
    /**
     * Feature created at time stamp.
     * @type {string}
     * @memberof PackageFeatureResource
     */
    createdAt?: string;
    /**
     * Feature update at time stamp.
     * @type {string}
     * @memberof PackageFeatureResource
     */
    updatedAt?: string;
}

/**
 *
 * @export
 * @interface PackageFeatureResult
 */
export interface PackageFeatureResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PackageFeatureResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PackageFeatureResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PackageFeatureResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PackageFeatureResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PackageFeatureResource>}
     * @memberof PackageFeatureResult
     */
    data?: Array<PackageFeatureResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PackageFeatureResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PackageResource
 */
export interface PackageResource {
    /**
     * Package ID.
     * @type {number}
     * @memberof PackageResource
     */
    id?: number;
    /**
     * Name of the package.
     * @type {string}
     * @memberof PackageResource
     */
    name?: string;
    /**
     *
     * @type {AmountResource}
     * @memberof PackageResource
     */
    monthlyPrice?: AmountResource;
    /**
     * Fet.
     * @type {Array<PackageFeatureResource>}
     * @memberof PackageResource
     */
    features?: Array<PackageFeatureResource>;
    /**
     * Package identifier.
     * @type {string}
     * @memberof PackageResource
     */
    identifier?: string;
    /**
     * Package description.
     * @type {string}
     * @memberof PackageResource
     */
    description?: string;
    /**
     * Package priority.
     * @type {string}
     * @memberof PackageResource
     */
    priority?: string;
    /**
     * Package created at time stamp.
     * @type {string}
     * @memberof PackageResource
     */
    createdAt?: string;
    /**
     * Package update at time stamp.
     * @type {string}
     * @memberof PackageResource
     */
    updatedAt?: string;
}

/**
 *
 * @export
 * @interface PackageResult
 */
export interface PackageResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PackageResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PackageResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PackageResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PackageResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PackageResource>}
     * @memberof PackageResult
     */
    data?: Array<PackageResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PackageResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface ParticipantResource
 */
export interface ParticipantResource {
    /**
     *
     * @type {number}
     * @memberof ParticipantResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ParticipantResource
     */
    firstName?: string;
    /**
     *
     * @type {string}
     * @memberof ParticipantResource
     */
    lastName?: string;
    /**
     *
     * @type {string}
     * @memberof ParticipantResource
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof ParticipantResource
     */
    picture?: string;
    /**
     *
     * @type {string}
     * @memberof ParticipantResource
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof ParticipantResource
     */
    type?: string;
    /**
     *
     * @type {number}
     * @memberof ParticipantResource
     */
    employeeId?: number;
    /**
     *
     * @type {number}
     * @memberof ParticipantResource
     */
    leadId?: number;
    /**
     *
     * @type {number}
     * @memberof ParticipantResource
     */
    partnerUserId?: number;
    /**
     *
     * @type {number}
     * @memberof ParticipantResource
     */
    clientId?: number;
    /**
     *
     * @type {number}
     * @memberof ParticipantResource
     */
    partnerId?: number;
    /**
     *
     * @type {number}
     * @memberof ParticipantResource
     */
    customerId?: number;
    /**
     *
     * @type {string}
     * @memberof ParticipantResource
     */
    zipCode?: string;
    /**
     *
     * @type {string}
     * @memberof ParticipantResource
     */
    city?: string;
}

/**
 *
 * @export
 * @interface PartnerActionFeesResource
 */
export interface PartnerActionFeesResource {
    /**
     *
     * @type {string}
     * @memberof PartnerActionFeesResource
     */
    action?: string;
    /**
     *
     * @type {number}
     * @memberof PartnerActionFeesResource
     */
    actionSummary?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerActionFeesResource
     */
    leadId?: number;
    /**
     *
     * @type {LeadClientResource}
     * @memberof PartnerActionFeesResource
     */
    client?: LeadClientResource;
    /**
     *
     * @type {string}
     * @memberof PartnerActionFeesResource
     */
    createdAt?: string;
    /**
     *
     * @type {number}
     * @memberof PartnerActionFeesResource
     */
    projectId?: number;
}

/**
 *
 * @export
 * @interface PartnerActionFeesResult
 */
export interface PartnerActionFeesResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerActionFeesResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerActionFeesResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerActionFeesResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerActionFeesResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerActionFeesResource>}
     * @memberof PartnerActionFeesResult
     */
    data?: Array<PartnerActionFeesResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerActionFeesResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerActionResource
 */
export interface PartnerActionResource {
    /**
     * Partner action
     * @type {string}
     * @memberof PartnerActionResource
     */
    action?: PartnerActionResource.ActionEnum;
    /**
     * Action note
     * @type {string}
     * @memberof PartnerActionResource
     */
    note?: string;
    /**
     * Next action at
     * @type {string}
     * @memberof PartnerActionResource
     */
    nextActionAt?: string;
    /**
     * Subscription type
     * @type {number}
     * @memberof PartnerActionResource
     */
    partnerPackageTypeId?: number;
}

/**
 * @export
 * @namespace PartnerActionResource
 */
export namespace PartnerActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        Unknown = <any> 'unknown',
        MakePartner = <any> 'make-partner',
        MakeDemo = <any> 'make-demo',
        MakeCanvas = <any> 'make-canvas',
        ContinueDemo = <any> 'continue-demo',
        NotInterested = <any> 'not-interested',
        StripeSync = <any> 'stripe-sync',
        CanvasPostpone = <any> 'canvas-postpone',
        CanvasNotInIndustry = <any> 'canvas-not-in-industry',
        CanvasOutOfBusiness = <any> 'canvas-out-of-business',
        CanvasAlreadyPartner = <any> 'canvas-already-partner',
        CanvasNotRelevant = <any> 'canvas-not-relevant',
        EmployeeCallsPartner = <any> 'employee-calls-partner',
        InvokeRightToBeForgotten = <any> 'invoke_right_to_be_forgotten',
        TransitionToContract = <any> 'transition_to_contract',
        GrantPortalAccess = <any> 'grant_portal_access',
        RevokePortalAccess = <any> 'revoke_portal_access',
        SubscribeToLeadMarketplace = <any> 'subscribe_to_lead_marketplace',
        SendPurchaseLimitReached = <any> 'send_purchase_limit_reached'
    }
}

/**
 *
 * @export
 * @interface PartnerAlertResource
 */
export interface PartnerAlertResource {
    /**
     *
     * @type {string}
     * @memberof PartnerAlertResource
     */
    type?: PartnerAlertResource.TypeEnum;
    /**
     *
     * @type {string}
     * @memberof PartnerAlertResource
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerAlertResource
     */
    description?: string;
}

/**
 * @export
 * @namespace PartnerAlertResource
 */
export namespace PartnerAlertResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Unknown = <any> 'unknown',
        Danger = <any> 'danger',
        Warning = <any> 'warning'
    }
}

/**
 *
 * @export
 * @interface PartnerAllocationBoostResource
 */
export interface PartnerAllocationBoostResource {
    /**
     * When should the boost start taking effect.
     * @type {string}
     * @memberof PartnerAllocationBoostResource
     */
    startsAt?: string;
    /**
     * When should the boost stop having an effect.
     * @type {string}
     * @memberof PartnerAllocationBoostResource
     */
    endsAt?: string;
    /**
     * Contracts affected by this boost.
     * @type {Array<number>}
     * @memberof PartnerAllocationBoostResource
     */
    partnerContractId?: Array<number>;
}

/**
 *
 * @export
 * @interface PartnerAllocationDeliveryPartnerBusinessUnitResource
 */
export interface PartnerAllocationDeliveryPartnerBusinessUnitResource {
    /**
     * @var int
     * @type {number}
     * @memberof PartnerAllocationDeliveryPartnerBusinessUnitResource
     */
    id?: number;
    /**
     * @var string
     * @type {string}
     * @memberof PartnerAllocationDeliveryPartnerBusinessUnitResource
     */
    displayName?: string;
    /**
     *
     * @type {PartnerAllocationDeliveryRevenueSegmentResource}
     * @memberof PartnerAllocationDeliveryPartnerBusinessUnitResource
     */
    revenueSegment?: PartnerAllocationDeliveryRevenueSegmentResource;
    /**
     * Partner contract of the business unit.
     * @type {number}
     * @memberof PartnerAllocationDeliveryPartnerBusinessUnitResource
     */
    partnerContractId?: number;
}

/**
 *
 * @export
 * @interface PartnerAllocationDeliveryPartnerResource
 */
export interface PartnerAllocationDeliveryPartnerResource {
    /**
     * ID of the partner.
     * @type {number}
     * @memberof PartnerAllocationDeliveryPartnerResource
     */
    id?: number;
    /**
     * Company name of the partner.
     * @type {string}
     * @memberof PartnerAllocationDeliveryPartnerResource
     */
    companyName?: string;
}

/**
 *
 * @export
 * @interface PartnerAllocationDeliveryResource
 */
export interface PartnerAllocationDeliveryResource {
    /**
     * ID of the allocation delivery.
     * @type {number}
     * @memberof PartnerAllocationDeliveryResource
     */
    id?: number;
    /**
     * When the invoicing is processed into an invoice.
     * @type {string}
     * @memberof PartnerAllocationDeliveryResource
     */
    processAt?: string;
    /**
     * When the invoicing was processed into an invoice.
     * @type {string}
     * @memberof PartnerAllocationDeliveryResource
     */
    processedAt?: string;
    /**
     *
     * @type {PartnerAllocationDeliveryPartnerResource}
     * @memberof PartnerAllocationDeliveryResource
     */
    partner?: PartnerAllocationDeliveryPartnerResource;
    /**
     *
     * @type {PartnerAllocationDeliveryPartnerBusinessUnitResource}
     * @memberof PartnerAllocationDeliveryResource
     */
    partnerBusinessUnit?: PartnerAllocationDeliveryPartnerBusinessUnitResource;
    /**
     * Count of allocations that are created when this delivery is processed.
     * @type {number}
     * @memberof PartnerAllocationDeliveryResource
     */
    deliveryAllocationCount?: number;
    /**
     * Count of allocations from this delivery that are allocated.
     * @type {number}
     * @memberof PartnerAllocationDeliveryResource
     */
    allocatedAllocationCount?: number;
}

/**
 *
 * @export
 * @interface PartnerAllocationDeliveryResult
 */
export interface PartnerAllocationDeliveryResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerAllocationDeliveryResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerAllocationDeliveryResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerAllocationDeliveryResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerAllocationDeliveryResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerAllocationDeliveryResource>}
     * @memberof PartnerAllocationDeliveryResult
     */
    data?: Array<PartnerAllocationDeliveryResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerAllocationDeliveryResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerAllocationDeliveryRevenueSegmentResource
 */
export interface PartnerAllocationDeliveryRevenueSegmentResource {
    /**
     * @var int
     * @type {number}
     * @memberof PartnerAllocationDeliveryRevenueSegmentResource
     */
    id?: number;
    /**
     * @var string
     * @type {string}
     * @memberof PartnerAllocationDeliveryRevenueSegmentResource
     */
    displayName?: string;
}

/**
 *
 * @export
 * @interface PartnerAllocationPauseResource
 */
export interface PartnerAllocationPauseResource {
    /**
     * When should the adjustment start taking effect.
     * @type {string}
     * @memberof PartnerAllocationPauseResource
     */
    startsAt?: string;
    /**
     * When should the adjustment stop having an effect.
     * @type {string}
     * @memberof PartnerAllocationPauseResource
     */
    endsAt?: string;
    /**
     * Contracts affected by this adjustment.
     * @type {Array<number>}
     * @memberof PartnerAllocationPauseResource
     */
    partnerContractId?: Array<number>;
}

/**
 *
 * @export
 * @interface PartnerAssigneeInfoResource
 */
export interface PartnerAssigneeInfoResource {
    /**
     *
     * @type {string}
     * @memberof PartnerAssigneeInfoResource
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerAssigneeInfoResource
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerAssigneeInfoResource
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerAssigneeInfoResource
     */
    phone?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerAssigneeInfoResource
     */
    image?: string;
}

/**
 *
 * @export
 * @interface PartnerAssigneeResource
 */
export interface PartnerAssigneeResource {
    /**
     *
     * @type {string}
     * @memberof PartnerAssigneeResource
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerAssigneeResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerAssigneeResource
     */
    updatedAt?: string;
    /**
     *
     * @type {number}
     * @memberof PartnerAssigneeResource
     */
    partnerId?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerAssigneeResource
     */
    assigneeId?: number;
    /**
     *
     * @type {string}
     * @memberof PartnerAssigneeResource
     */
    assigneeType?: PartnerAssigneeResource.AssigneeTypeEnum;
    /**
     *
     * @type {string}
     * @memberof PartnerAssigneeResource
     */
    assigneeRole?: PartnerAssigneeResource.AssigneeRoleEnum;
    /**
     *
     * @type {PartnerAssigneeInfoResource}
     * @memberof PartnerAssigneeResource
     */
    assigneeInfo?: PartnerAssigneeInfoResource;
}

/**
 * @export
 * @namespace PartnerAssigneeResource
 */
export namespace PartnerAssigneeResource {
    /**
     * @export
     * @enum {string}
     */
    export enum AssigneeTypeEnum {
        All = <any> 'all',
        Employee = <any> 'employee'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum AssigneeRoleEnum {
        All = <any> 'all',
        ClientConsultant = <any> 'client_consultant',
        AccountManager = <any> 'account_manager'
    }
}

/**
 *
 * @export
 * @interface PartnerAssigneeResult
 */
export interface PartnerAssigneeResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerAssigneeResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerAssigneeResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerAssigneeResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerAssigneeResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerAssigneeResource>}
     * @memberof PartnerAssigneeResult
     */
    data?: Array<PartnerAssigneeResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerAssigneeResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerAssociationResource
 */
export interface PartnerAssociationResource {
    /**
     *
     * @type {number}
     * @memberof PartnerAssociationResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof PartnerAssociationResource
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerAssociationResource
     */
    imageLogo?: string;
}

/**
 *
 * @export
 * @interface PartnerAttributeResource
 */
export interface PartnerAttributeResource {
    /**
     * Partner Field Id.
     * @type {number}
     * @memberof PartnerAttributeResource
     */
    id?: number;
    /**
     * Identifier for the Partner Field
     * @type {string}
     * @memberof PartnerAttributeResource
     */
    identifier?: string;
    /**
     * Label for the given Partner Field.
     * @type {string}
     * @memberof PartnerAttributeResource
     */
    label?: string;
    /**
     * Flag whether data field can have multiple values
     * @type {boolean}
     * @memberof PartnerAttributeResource
     */
    isMultiple?: boolean;
    /**
     * Array of selected values.
     * @type {Array<DataFieldValueResource>}
     * @memberof PartnerAttributeResource
     */
    selected?: Array<DataFieldValueResource>;
    /**
     * Type of field
     * @type {string}
     * @memberof PartnerAttributeResource
     */
    type?: PartnerAttributeResource.TypeEnum;
}

/**
 * @export
 * @namespace PartnerAttributeResource
 */
export namespace PartnerAttributeResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Unknown = <any> 'unknown',
        Text = <any> 'text',
        Number = <any> 'number',
        Checkbox = <any> 'checkbox',
        Textbox = <any> 'textbox',
        Dropdown = <any> 'dropdown'
    }
}

/**
 *
 * @export
 * @interface PartnerBadgeResource
 */
export interface PartnerBadgeResource {
    /**
     * Id
     * @type {number}
     * @memberof PartnerBadgeResource
     */
    id?: number;
    /**
     * Industry id
     * @type {number}
     * @memberof PartnerBadgeResource
     */
    industryId?: number;
    /**
     * Badge token
     * @type {string}
     * @memberof PartnerBadgeResource
     */
    badgeToken?: string;
    /**
     * Description
     * @type {string}
     * @memberof PartnerBadgeResource
     */
    description?: string;
    /**
     * Geo code
     * @type {string}
     * @memberof PartnerBadgeResource
     */
    geoCode?: string;
}

/**
 *
 * @export
 * @interface PartnerBankaccountResource
 */
export interface PartnerBankaccountResource {
    /**
     *
     * @type {number}
     * @memberof PartnerBankaccountResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof PartnerBankaccountResource
     */
    bankName?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerBankaccountResource
     */
    country?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerBankaccountResource
     */
    routingNumber?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerBankaccountResource
     */
    ibanEncrypted?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerBankaccountResource
     */
    accountServiceKey?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerBankaccountResource
     */
    lastFour?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerBankaccountResource
     */
    currency?: string;
}

/**
 *
 * @export
 * @interface PartnerBankaccountResult
 */
export interface PartnerBankaccountResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerBankaccountResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerBankaccountResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerBankaccountResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerBankaccountResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerBankaccountResource>}
     * @memberof PartnerBankaccountResult
     */
    data?: Array<PartnerBankaccountResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerBankaccountResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerBusinessUnitAccumulatedAllocationResource
 */
export interface PartnerBusinessUnitAccumulatedAllocationResource {
    /**
     * Number of allocations fulfilled by the normal quota of allocations.
     * @type {number}
     * @memberof PartnerBusinessUnitAccumulatedAllocationResource
     */
    normalQuotaAllocationCount?: number;
    /**
     * Count of allocations accumulated from the normal allocation quota.
     * @type {number}
     * @memberof PartnerBusinessUnitAccumulatedAllocationResource
     */
    normalAllocationQuota?: number;
    /**
     * Number of allocations fulfilled by the pay per spot limit.
     * @type {number}
     * @memberof PartnerBusinessUnitAccumulatedAllocationResource
     */
    payPerSpotAllocationCount?: number;
    /**
     * Count of allocations added each refill period from the pay per spot limit.
     * @type {number}
     * @memberof PartnerBusinessUnitAccumulatedAllocationResource
     */
    payPerSpotAllocationLimit?: number;
    /**
     * Number of allocations fulfilled by just in time fulfillment.
     * @type {number}
     * @memberof PartnerBusinessUnitAccumulatedAllocationResource
     */
    justInTimeAllocationCount?: number;
    /**
     * Total including normal quota and just in time allocations.
     * @type {number}
     * @memberof PartnerBusinessUnitAccumulatedAllocationResource
     */
    allocationCount?: number;
    /**
     * Synonym for normal quota.
     * @type {number}
     * @memberof PartnerBusinessUnitAccumulatedAllocationResource
     */
    allocationQuota?: number;
    /**
     * Count of allocations that were overdelivered, i.e. above the applicable quota
     * @type {number}
     * @memberof PartnerBusinessUnitAccumulatedAllocationResource
     */
    overdeliveryAllocationCount?: number;
}

/**
 *
 * @export
 * @interface PartnerBusinessUnitAllocationResource
 */
export interface PartnerBusinessUnitAllocationResource {
    /**
     * Number of allocations fulfilled by the normal quota.
     * @type {number}
     * @memberof PartnerBusinessUnitAllocationResource
     */
    normalQuotaAllocationCount?: number;
    /**
     * Count of allocations added each refill period from the normal allocation quota.
     * @type {number}
     * @memberof PartnerBusinessUnitAllocationResource
     */
    normalAllocationQuota?: number;
    /**
     * Number of allocations fulfilled by the pay per spot limit.
     * @type {number}
     * @memberof PartnerBusinessUnitAllocationResource
     */
    payPerSpotAllocationCount?: number;
    /**
     * Count of allocations added each refill period from the pay per spot limit.
     * @type {number}
     * @memberof PartnerBusinessUnitAllocationResource
     */
    payPerSpotAllocationLimit?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerBusinessUnitAllocationResource
     */
    payPerSpotAllocationPrice?: AmountResource;
    /**
     * Number of allocations fulfilled by just in time fulfillment.
     * @type {number}
     * @memberof PartnerBusinessUnitAllocationResource
     */
    justInTimeAllocationCount?: number;
    /**
     * Sum of norma quota count and just in time count.
     * @type {number}
     * @memberof PartnerBusinessUnitAllocationResource
     */
    allocationCount?: number;
    /**
     * Synonym for normal_allocation_quota.
     * @type {number}
     * @memberof PartnerBusinessUnitAllocationResource
     */
    allocationQuotaPerRefill?: number;
    /**
     * Count of allocations that were overdelivered, i.e. above the applicable quota
     * @type {number}
     * @memberof PartnerBusinessUnitAllocationResource
     */
    overdeliveryAllocationCount?: number;
}

/**
 *
 * @export
 * @interface PartnerBusinessUnitBusinessModelResource
 */
export interface PartnerBusinessUnitBusinessModelResource {
    /**
     *
     * @type {number}
     * @memberof PartnerBusinessUnitBusinessModelResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof PartnerBusinessUnitBusinessModelResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerBusinessUnitBusinessModelResource
     */
    abbreviation?: string;
}

/**
 *
 * @export
 * @interface PartnerBusinessUnitContractResource
 */
export interface PartnerBusinessUnitContractResource {
    /**
     * ID of the contract.
     * @type {number}
     * @memberof PartnerBusinessUnitContractResource
     */
    id?: number;
    /**
     * Partner that owns the contract.
     * @type {number}
     * @memberof PartnerBusinessUnitContractResource
     */
    partnerId?: number;
    /**
     * When was this contract effective to.
     * @type {string}
     * @memberof PartnerBusinessUnitContractResource
     */
    retiredAt?: string;
    /**
     * The linked business units
     * @type {Array<ContractPartnerBusinessUnitResource>}
     * @memberof PartnerBusinessUnitContractResource
     */
    partnerBusinessUnits?: Array<ContractPartnerBusinessUnitResource>;
}

/**
 *
 * @export
 * @interface PartnerBusinessUnitContractResult
 */
export interface PartnerBusinessUnitContractResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerBusinessUnitContractResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerBusinessUnitContractResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerBusinessUnitContractResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerBusinessUnitContractResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerBusinessUnitContractResource>}
     * @memberof PartnerBusinessUnitContractResult
     */
    data?: Array<PartnerBusinessUnitContractResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerBusinessUnitContractResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerBusinessUnitGeoResource
 */
export interface PartnerBusinessUnitGeoResource {
    /**
     * Geo code.
     * @type {string}
     * @memberof PartnerBusinessUnitGeoResource
     */
    code?: string;
    /**
     * Street address.
     * @type {string}
     * @memberof PartnerBusinessUnitGeoResource
     */
    address?: string;
    /**
     * Zip Code.
     * @type {string}
     * @memberof PartnerBusinessUnitGeoResource
     */
    zipCode?: string;
    /**
     * Name of the city.
     * @type {string}
     * @memberof PartnerBusinessUnitGeoResource
     */
    cityName?: string;
    /**
     *
     * @type {LocationGeoPointResource}
     * @memberof PartnerBusinessUnitGeoResource
     */
    point?: LocationGeoPointResource;
    /**
     *
     * @type {SegmentationSubGeoRegionResource}
     * @memberof PartnerBusinessUnitGeoResource
     */
    homeSubGeoRegion?: SegmentationSubGeoRegionResource;
}

/**
 *
 * @export
 * @interface PartnerBusinessUnitIndustriesResource
 */
export interface PartnerBusinessUnitIndustriesResource {
    /**
     * Industry IDs.
     * @type {Array<number>}
     * @memberof PartnerBusinessUnitIndustriesResource
     */
    industryIds?: Array<number>;
}

/**
 *
 * @export
 * @interface PartnerBusinessUnitIndustryResource
 */
export interface PartnerBusinessUnitIndustryResource {
    /**
     * Industry ID.
     * @type {number}
     * @memberof PartnerBusinessUnitIndustryResource
     */
    id?: number;
    /**
     * Name of the industry.
     * @type {string}
     * @memberof PartnerBusinessUnitIndustryResource
     */
    name?: string;
    /**
     * Uri friendly name for the industry.
     * @type {string}
     * @memberof PartnerBusinessUnitIndustryResource
     */
    slug?: string;
    /**
     * industry identifier
     * @type {string}
     * @memberof PartnerBusinessUnitIndustryResource
     */
    identifier?: string;
}

/**
 *
 * @export
 * @interface PartnerBusinessUnitIndustryResult
 */
export interface PartnerBusinessUnitIndustryResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerBusinessUnitIndustryResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerBusinessUnitIndustryResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerBusinessUnitIndustryResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerBusinessUnitIndustryResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerBusinessUnitIndustryResource>}
     * @memberof PartnerBusinessUnitIndustryResult
     */
    data?: Array<PartnerBusinessUnitIndustryResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerBusinessUnitIndustryResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerBusinessUnitKpiResource
 */
export interface PartnerBusinessUnitKpiResource {
    /**
     * Ratio between optimal delivery ratio and actual delivery ratio within current contract period
     * @type {number}
     * @memberof PartnerBusinessUnitKpiResource
     */
    currentPeriodDeliveryTrend?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerBusinessUnitKpiResource
     */
    mrrAmountExclVat?: AmountResource;
    /**
     * Active status
     * @type {boolean}
     * @memberof PartnerBusinessUnitKpiResource
     */
    isActive?: boolean;
    /**
     * Allocated at value of latest allocation
     * @type {string}
     * @memberof PartnerBusinessUnitKpiResource
     */
    latestAllocatedAt?: string;
    /**
     * Publishing ratio per period
     * @type {number}
     * @memberof PartnerBusinessUnitKpiResource
     */
    allTimePublishingRatio?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerBusinessUnitKpiResource
     */
    currentMonthPublishingRatio?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerBusinessUnitKpiResource
     */
    currentPeriodPublishingRatio?: number;
    /**
     * Success ratio per period
     * @type {number}
     * @memberof PartnerBusinessUnitKpiResource
     */
    allTimeSuccessRatio?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerBusinessUnitKpiResource
     */
    currentMonthSuccessRatio?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerBusinessUnitKpiResource
     */
    currentPeriodSuccessRatio?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerBusinessUnitKpiResource
     */
    allTimeRevenueExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerBusinessUnitKpiResource
     */
    currentMonthRevenueExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerBusinessUnitKpiResource
     */
    currentPeriodRevenueExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerBusinessUnitKpiResource
     */
    allTimeValueOfWonCasesExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerBusinessUnitKpiResource
     */
    currentMonthValueOfWonCasesExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerBusinessUnitKpiResource
     */
    currentPeriodValueOfWonCasesExclVat?: AmountResource;
}

/**
 *
 * @export
 * @interface PartnerBusinessUnitMonthAllocationResource
 */
export interface PartnerBusinessUnitMonthAllocationResource {
    /**
     * Number of lead budget allocations allocated in current month.
     * @type {number}
     * @memberof PartnerBusinessUnitMonthAllocationResource
     */
    currentMonthAllocationCount?: number;
    /**
     * Suggested lead budget quota for current month based on number of unused period allocations and number of remaining months.
     * @type {number}
     * @memberof PartnerBusinessUnitMonthAllocationResource
     */
    suggestedAllocationQuota?: number;
    /**
     * Number of allocations fulfilled by the pay per spot limit in current month.
     * @type {number}
     * @memberof PartnerBusinessUnitMonthAllocationResource
     */
    payPerSpotAllocationCount?: number;
    /**
     * Count of remaining pey-per-spot spots in current period allocation limit.
     * @type {number}
     * @memberof PartnerBusinessUnitMonthAllocationResource
     */
    payPerSpotAllocationLimit?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerBusinessUnitMonthAllocationResource
     */
    payPerSpotAllocationPrice?: AmountResource;
    /**
     * Count of allocations that were overdelivered, i.e. above the applicable period quota
     * @type {number}
     * @memberof PartnerBusinessUnitMonthAllocationResource
     */
    overdeliveryAllocationCount?: number;
}

/**
 *
 * @export
 * @interface PartnerBusinessUnitPartnerContractPeriodResource
 */
export interface PartnerBusinessUnitPartnerContractPeriodResource {
    /**
     * Primary ID.
     * @type {number}
     * @memberof PartnerBusinessUnitPartnerContractPeriodResource
     */
    id?: number;
    /**
     * Start at date.
     * @type {string}
     * @memberof PartnerBusinessUnitPartnerContractPeriodResource
     */
    startAt?: string;
    /**
     * Ends at date.
     * @type {string}
     * @memberof PartnerBusinessUnitPartnerContractPeriodResource
     */
    endAt?: string;
    /**
     * KPIs array.
     * @type {Array<KeyValueResource>}
     * @memberof PartnerBusinessUnitPartnerContractPeriodResource
     */
    kpis?: Array<KeyValueResource>;
}

/**
 *
 * @export
 * @interface PartnerBusinessUnitPartnerContractResource
 */
export interface PartnerBusinessUnitPartnerContractResource {
    /**
     * Primary ID.
     * @type {number}
     * @memberof PartnerBusinessUnitPartnerContractResource
     */
    id?: number;
    /**
     * When the contract starts.
     * @type {string}
     * @memberof PartnerBusinessUnitPartnerContractResource
     */
    startAt?: string;
    /**
     * When the contract was accepted.
     * @type {string}
     * @memberof PartnerBusinessUnitPartnerContractResource
     */
    acceptedAt?: string;
    /**
     * When the contract was canceled.
     * @type {string}
     * @memberof PartnerBusinessUnitPartnerContractResource
     */
    canceledAt?: string;
    /**
     * When the contract churns.
     * @type {string}
     * @memberof PartnerBusinessUnitPartnerContractResource
     */
    churnAt?: string;
    /**
     * How long the contract last for each contract period.
     * @type {number}
     * @memberof PartnerBusinessUnitPartnerContractResource
     */
    bindingPeriodMonths?: number;
    /**
     * How many months the partner pays for at a time.
     * @type {number}
     * @memberof PartnerBusinessUnitPartnerContractResource
     */
    billingFrequencyMonths?: number;
    /**
     *
     * @type {PartnerBusinessUnitPartnerContractPeriodResource}
     * @memberof PartnerBusinessUnitPartnerContractResource
     */
    currentPartnerContractPeriod?: PartnerBusinessUnitPartnerContractPeriodResource;
    /**
     * List of allocation pauses.
     * @type {Array<DateSpanResource>}
     * @memberof PartnerBusinessUnitPartnerContractResource
     */
    allocationPauses?: Array<DateSpanResource>;
    /**
     * List of allocation boosts.
     * @type {Array<DateSpanResource>}
     * @memberof PartnerBusinessUnitPartnerContractResource
     */
    allocationBoosts?: Array<DateSpanResource>;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerBusinessUnitPartnerContractResource
     */
    businessUnitMrr?: AmountResource;
}

/**
 *
 * @export
 * @interface PartnerBusinessUnitPartnerResource
 */
export interface PartnerBusinessUnitPartnerResource {
    /**
     * Primary ID.
     * @type {number}
     * @memberof PartnerBusinessUnitPartnerResource
     */
    id?: number;
    /**
     * Attributes for the partner.
     * @type {Array<PartnerAttributeResource>}
     * @memberof PartnerBusinessUnitPartnerResource
     */
    attributes?: Array<PartnerAttributeResource>;
    /**
     * Partner company name.
     * @type {string}
     * @memberof PartnerBusinessUnitPartnerResource
     */
    companyName?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerBusinessUnitPartnerResource
     */
    companyInformation?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerBusinessUnitPartnerResource
     */
    slug?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerBusinessUnitPartnerResource
     */
    logo?: string;
    /**
     * Does the partner have a marketing package?
     * @type {boolean}
     * @memberof PartnerBusinessUnitPartnerResource
     */
    hasMarketingPackage?: boolean;
    /**
     * Is the partner's subscription paused?
     * @type {boolean}
     * @memberof PartnerBusinessUnitPartnerResource
     */
    isSubscriptionPaused?: boolean;
    /**
     * Is partner access active;
     * @type {boolean}
     * @memberof PartnerBusinessUnitPartnerResource
     */
    hasActiveAccess?: boolean;
    /**
     * An array of the partner's features
     * @type {Array<string>}
     * @memberof PartnerBusinessUnitPartnerResource
     */
    features?: Array<string>;
    /**
     *
     * @type {PartnerRatingResource}
     * @memberof PartnerBusinessUnitPartnerResource
     */
    rating?: PartnerRatingResource;
    /**
     *
     * @type {Array<PartnerBadgeResource>}
     * @memberof PartnerBusinessUnitPartnerResource
     */
    badges?: Array<PartnerBadgeResource>;
    /**
     *
     * @type {PartnerGeoResource}
     * @memberof PartnerBusinessUnitPartnerResource
     */
    geo?: PartnerGeoResource;
    /**
     *
     * @type {Array<PartnerBusinessUnitPartnerUserResource>}
     * @memberof PartnerBusinessUnitPartnerResource
     */
    publicEmployees?: Array<PartnerBusinessUnitPartnerUserResource>;
    /**
     *
     * @type {PartnerBusinessUnitPartnerUserResource}
     * @memberof PartnerBusinessUnitPartnerResource
     */
    masterEmployee?: PartnerBusinessUnitPartnerUserResource;
}

/**
 *
 * @export
 * @interface PartnerBusinessUnitPartnerUserResource
 */
export interface PartnerBusinessUnitPartnerUserResource {
    /**
     *
     * @type {number}
     * @memberof PartnerBusinessUnitPartnerUserResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof PartnerBusinessUnitPartnerUserResource
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerBusinessUnitPartnerUserResource
     */
    firstName?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerBusinessUnitPartnerUserResource
     */
    lastName?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerBusinessUnitPartnerUserResource
     */
    title?: string;
    /**
     *
     * @type {Array<PartnerUserCvLanguageResource>}
     * @memberof PartnerBusinessUnitPartnerUserResource
     */
    languages?: Array<PartnerUserCvLanguageResource>;
    /**
     *
     * @type {string}
     * @memberof PartnerBusinessUnitPartnerUserResource
     */
    profilePicture?: string;
}

/**
 *
 * @export
 * @interface PartnerBusinessUnitResource
 */
export interface PartnerBusinessUnitResource {
    /**
     * Primary ID.
     * @type {number}
     * @memberof PartnerBusinessUnitResource
     */
    id?: number;
    /**
     * Partner ID.
     * @type {number}
     * @memberof PartnerBusinessUnitResource
     */
    partnerId?: number;
    /**
     * Partner user ID.
     * @type {number}
     * @memberof PartnerBusinessUnitResource
     */
    partnerUserId?: number;
    /**
     * Grouping ID.
     * @type {number}
     * @memberof PartnerBusinessUnitResource
     */
    partnerBusinessUnitGroupingId?: number;
    /**
     *
     * @type {PartnerBusinessUnitGeoResource}
     * @memberof PartnerBusinessUnitResource
     */
    geo?: PartnerBusinessUnitGeoResource;
    /**
     * Does this business unit disable digital leads?
     * @type {boolean}
     * @memberof PartnerBusinessUnitResource
     */
    disableDigitalLeads?: boolean;
    /**
     * Human-readable name.
     * @type {string}
     * @memberof PartnerBusinessUnitResource
     */
    displayName?: string;
    /**
     * Distance this business unit is willing to look for leads.
     * @type {number}
     * @memberof PartnerBusinessUnitResource
     */
    geoRange?: number;
    /**
     *
     * @type {PartnerBusinessUnitAllocationResource}
     * @memberof PartnerBusinessUnitResource
     */
    currentAllocation?: PartnerBusinessUnitAllocationResource;
    /**
     *
     * @type {PartnerBusinessUnitMonthAllocationResource}
     * @memberof PartnerBusinessUnitResource
     */
    currentMonthAllocation?: PartnerBusinessUnitMonthAllocationResource;
    /**
     *
     * @type {PartnerBusinessUnitAccumulatedAllocationResource}
     * @memberof PartnerBusinessUnitResource
     */
    accumulatedAllocation?: PartnerBusinessUnitAccumulatedAllocationResource;
    /**
     *
     * @type {PartnerBusinessUnitRevenueRangeResource}
     * @memberof PartnerBusinessUnitResource
     */
    desiredRevenueRange?: PartnerBusinessUnitRevenueRangeResource;
    /**
     * Desired sectors.
     * @type {Array<SectorResource>}
     * @memberof PartnerBusinessUnitResource
     */
    desiredSectors?: Array<SectorResource>;
    /**
     *
     * @type {RevenueSegmentResource}
     * @memberof PartnerBusinessUnitResource
     */
    revenueSegment?: RevenueSegmentResource;
    /**
     * Desired lead types.
     * @type {Array<LeadTypeResource>}
     * @memberof PartnerBusinessUnitResource
     */
    desiredTypes?: Array<LeadTypeResource>;
    /**
     * Desired industries.
     * @type {Array<IndustryResource>}
     * @memberof PartnerBusinessUnitResource
     */
    desiredIndustries?: Array<IndustryResource>;
    /**
     *
     * @type {PartnerEmployeeResource}
     * @memberof PartnerBusinessUnitResource
     */
    employee?: PartnerEmployeeResource;
    /**
     *
     * @type {PartnerBusinessUnitPartnerResource}
     * @memberof PartnerBusinessUnitResource
     */
    partner?: PartnerBusinessUnitPartnerResource;
    /**
     * Business models of the business units.
     * @type {Array<PartnerBusinessUnitBusinessModelResource>}
     * @memberof PartnerBusinessUnitResource
     */
    businessModels?: Array<PartnerBusinessUnitBusinessModelResource>;
    /**
     * If the business unit is active
     * @type {boolean}
     * @memberof PartnerBusinessUnitResource
     */
    isActive?: boolean;
    /**
     * When the business unit is considered retired
     * @type {string}
     * @memberof PartnerBusinessUnitResource
     */
    retiredAt?: string;
    /**
     *
     * @type {PartnerBusinessUnitPartnerContractResource}
     * @memberof PartnerBusinessUnitResource
     */
    partnerContract?: PartnerBusinessUnitPartnerContractResource;
    /**
     * Does the business unit want exclusive leads?
     * @type {boolean}
     * @memberof PartnerBusinessUnitResource
     */
    wantsExclusiveLeads?: boolean;
    /**
     * Important notes about this business unit.
     * @type {Array<string>}
     * @memberof PartnerBusinessUnitResource
     */
    highlightedNotes?: Array<string>;
    /**
     *
     * @type {PartnerBusinessUnitKpiResource}
     * @memberof PartnerBusinessUnitResource
     */
    kpi?: PartnerBusinessUnitKpiResource;
}

/**
 *
 * @export
 * @interface PartnerBusinessUnitResult
 */
export interface PartnerBusinessUnitResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerBusinessUnitResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerBusinessUnitResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerBusinessUnitResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerBusinessUnitResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerBusinessUnitResource>}
     * @memberof PartnerBusinessUnitResult
     */
    data?: Array<PartnerBusinessUnitResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerBusinessUnitResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerBusinessUnitRevenueRangeResource
 */
export interface PartnerBusinessUnitRevenueRangeResource {
    /**
     * Minimum revenue.
     * @type {number}
     * @memberof PartnerBusinessUnitRevenueRangeResource
     */
    minimumRevenue?: number;
    /**
     * Maximum revenue.
     * @type {number}
     * @memberof PartnerBusinessUnitRevenueRangeResource
     */
    maximumRevenue?: number;
    /**
     * Currency for the min and max revenues.
     * @type {string}
     * @memberof PartnerBusinessUnitRevenueRangeResource
     */
    revenueCurrency?: string;
}

/**
 *
 * @export
 * @interface PartnerBusinessUnitSegmentationGeoRegionResource
 */
export interface PartnerBusinessUnitSegmentationGeoRegionResource {
    /**
     * Segmentation geo region ID.
     * @type {number}
     * @memberof PartnerBusinessUnitSegmentationGeoRegionResource
     */
    id?: number;
    /**
     * Geo code.
     * @type {string}
     * @memberof PartnerBusinessUnitSegmentationGeoRegionResource
     */
    geoCode?: string;
    /**
     * @var Human readable region name.
     * @type {string}
     * @memberof PartnerBusinessUnitSegmentationGeoRegionResource
     */
    regionName?: string;
}

/**
 *
 * @export
 * @interface PartnerBusinessUnitSegmentationGeoRegionResult
 */
export interface PartnerBusinessUnitSegmentationGeoRegionResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerBusinessUnitSegmentationGeoRegionResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerBusinessUnitSegmentationGeoRegionResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerBusinessUnitSegmentationGeoRegionResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerBusinessUnitSegmentationGeoRegionResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerBusinessUnitSegmentationGeoRegionResource>}
     * @memberof PartnerBusinessUnitSegmentationGeoRegionResult
     */
    data?: Array<PartnerBusinessUnitSegmentationGeoRegionResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerBusinessUnitSegmentationGeoRegionResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerBusinessUnitSegmentationGeoRegionsResource
 */
export interface PartnerBusinessUnitSegmentationGeoRegionsResource {
    /**
     * Segmentation geo region IDs.
     * @type {Array<number>}
     * @memberof PartnerBusinessUnitSegmentationGeoRegionsResource
     */
    segmentationGeoRegionIds?: Array<number>;
}

/**
 *
 * @export
 * @interface PartnerBusinessUnitTypeResource
 */
export interface PartnerBusinessUnitTypeResource {
    /**
     * Type ID.
     * @type {number}
     * @memberof PartnerBusinessUnitTypeResource
     */
    id?: number;
    /**
     * Type title.
     * @type {string}
     * @memberof PartnerBusinessUnitTypeResource
     */
    title?: string;
    /**
     * Type geo_code.
     * @type {string}
     * @memberof PartnerBusinessUnitTypeResource
     */
    geoCode?: string;
}

/**
 *
 * @export
 * @interface PartnerBusinessUnitTypeResult
 */
export interface PartnerBusinessUnitTypeResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerBusinessUnitTypeResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerBusinessUnitTypeResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerBusinessUnitTypeResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerBusinessUnitTypeResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerBusinessUnitTypeResource>}
     * @memberof PartnerBusinessUnitTypeResult
     */
    data?: Array<PartnerBusinessUnitTypeResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerBusinessUnitTypeResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerBusinessUnitTypesResource
 */
export interface PartnerBusinessUnitTypesResource {
    /**
     * Type IDs.
     * @type {Array<number>}
     * @memberof PartnerBusinessUnitTypesResource
     */
    typeIds?: Array<number>;
}

/**
 *
 * @export
 * @interface PartnerCanvasResource
 */
export interface PartnerCanvasResource {
    /**
     * Canvas status
     * @type {string}
     * @memberof PartnerCanvasResource
     */
    status?: PartnerCanvasResource.StatusEnum;
}

/**
 * @export
 * @namespace PartnerCanvasResource
 */
export namespace PartnerCanvasResource {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Unknown = <any> 'unknown',
        Untreated = <any> 'untreated',
        Postponed = <any> 'postponed',
        NotRelevant = <any> 'not_relevant',
        NotInterested = <any> 'not_interested',
        AlreadyPartner = <any> 'already_partner',
        XPartner = <any> 'x_partner'
    }
}

/**
 *
 * @export
 * @interface PartnerCategoryResource
 */
export interface PartnerCategoryResource {
    /**
     * Partner Category id
     * @type {number}
     * @memberof PartnerCategoryResource
     */
    id?: number;
    /**
     * Identifier for the Partner Category
     * @type {string}
     * @memberof PartnerCategoryResource
     */
    identifier?: string;
    /**
     * Title for the given Partner Category.
     * @type {string}
     * @memberof PartnerCategoryResource
     */
    title?: string;
    /**
     * Geo Code the given Partner Category.
     * @type {string}
     * @memberof PartnerCategoryResource
     */
    geoCode?: string;
}

/**
 *
 * @export
 * @interface PartnerCategoryResult
 */
export interface PartnerCategoryResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerCategoryResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerCategoryResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerCategoryResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerCategoryResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerCategoryResource>}
     * @memberof PartnerCategoryResult
     */
    data?: Array<PartnerCategoryResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerCategoryResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerCertificationsResource
 */
export interface PartnerCertificationsResource {
    /**
     * Array with ids. @var array
     * @type {Array<number>}
     * @memberof PartnerCertificationsResource
     */
    certificationIds?: Array<number>;
}

/**
 *
 * @export
 * @interface PartnerContentResource
 */
export interface PartnerContentResource {
    /**
     * Content key identifier.
     * @type {string}
     * @memberof PartnerContentResource
     */
    key?: string;
    /**
     * Base64 value.
     * @type {string}
     * @memberof PartnerContentResource
     */
    value?: string;
}

/**
 *
 * @export
 * @interface PartnerContractActionDataResource
 */
export interface PartnerContractActionDataResource {
    /**
     * Option to delete unused allocation
     * @type {boolean}
     * @memberof PartnerContractActionDataResource
     */
    deleteUnusedAllocations?: boolean;
}

/**
 *
 * @export
 * @interface PartnerContractActionResource
 */
export interface PartnerContractActionResource {
    /**
     * Partner contract action.
     * @type {string}
     * @memberof PartnerContractActionResource
     */
    action?: PartnerContractActionResource.ActionEnum;
    /**
     *
     * @type {PartnerContractActionDataResource}
     * @memberof PartnerContractActionResource
     */
    actionData?: PartnerContractActionDataResource;
}

/**
 * @export
 * @namespace PartnerContractActionResource
 */
export namespace PartnerContractActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        Unknown = <any> 'unknown',
        CancelNormally = <any> 'cancel_normally',
        CancelImmediately = <any> 'cancel_immediately',
        Accept = <any> 'accept',
        AcceptForPartner = <any> 'accept_for_partner',
        UncancelNormalCancellation = <any> 'uncancel_normal_cancellation',
        Archive = <any> 'archive'
    }
}

/**
 *
 * @export
 * @interface PartnerContractAllocationBoostResource
 */
export interface PartnerContractAllocationBoostResource {
    /**
     * The contract ID.
     * @type {number}
     * @memberof PartnerContractAllocationBoostResource
     */
    id?: number;
    /**
     * When the pause starts.
     * @type {string}
     * @memberof PartnerContractAllocationBoostResource
     */
    startsAt?: string;
    /**
     * When the pause ends.
     * @type {string}
     * @memberof PartnerContractAllocationBoostResource
     */
    endsAt?: string;
}

/**
 *
 * @export
 * @interface PartnerContractAllocationPauseResource
 */
export interface PartnerContractAllocationPauseResource {
    /**
     * The contract ID.
     * @type {number}
     * @memberof PartnerContractAllocationPauseResource
     */
    id?: number;
    /**
     * When the pause starts.
     * @type {string}
     * @memberof PartnerContractAllocationPauseResource
     */
    startsAt?: string;
    /**
     * When the pause ends.
     * @type {string}
     * @memberof PartnerContractAllocationPauseResource
     */
    endsAt?: string;
}

/**
 *
 * @export
 * @interface PartnerContractBusinessUnitPartnerUserResource
 */
export interface PartnerContractBusinessUnitPartnerUserResource {
    /**
     * ID of the component.
     * @type {number}
     * @memberof PartnerContractBusinessUnitPartnerUserResource
     */
    id?: number;
    /**
     * Display name of the compnent.
     * @type {string}
     * @memberof PartnerContractBusinessUnitPartnerUserResource
     */
    name?: string;
}

/**
 *
 * @export
 * @interface PartnerContractBusinessUnitQuotaResource
 */
export interface PartnerContractBusinessUnitQuotaResource {
    /**
     * The quota amount.
     * @type {number}
     * @memberof PartnerContractBusinessUnitQuotaResource
     */
    count?: number;
    /**
     * The quota allocated.
     * @type {number}
     * @memberof PartnerContractBusinessUnitQuotaResource
     */
    allocated?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerContractBusinessUnitQuotaResource
     */
    priceExclVat?: AmountResource;
}

/**
 *
 * @export
 * @interface PartnerContractBusinessUnitResource
 */
export interface PartnerContractBusinessUnitResource {
    /**
     * Primary ID of the business unit.
     * @type {number}
     * @memberof PartnerContractBusinessUnitResource
     */
    id?: number;
    /**
     *
     * @type {PartnerContractBusinessUnitPartnerUserResource}
     * @memberof PartnerContractBusinessUnitResource
     */
    partnerUser?: PartnerContractBusinessUnitPartnerUserResource;
    /**
     * Partner business unit grouping ID.
     * @type {number}
     * @memberof PartnerContractBusinessUnitResource
     */
    partnerBusinessUnitGroupingId?: number;
    /**
     * Does the business unit accept digial leads?
     * @type {boolean}
     * @memberof PartnerContractBusinessUnitResource
     */
    wantsDigitalLeads?: boolean;
    /**
     * Display name.
     * @type {string}
     * @memberof PartnerContractBusinessUnitResource
     */
    displayName?: string;
    /**
     *
     * @type {PartnerBusinessUnitGeoResource}
     * @memberof PartnerContractBusinessUnitResource
     */
    geo?: PartnerBusinessUnitGeoResource;
    /**
     *
     * @type {PartnerBusinessUnitRevenueRangeResource}
     * @memberof PartnerContractBusinessUnitResource
     */
    revenues?: PartnerBusinessUnitRevenueRangeResource;
    /**
     * Desired sectors.
     * @type {Array<SectorResource>}
     * @memberof PartnerContractBusinessUnitResource
     */
    sectors?: Array<SectorResource>;
    /**
     * Desired industries.
     * @type {Array<IndustryResource>}
     * @memberof PartnerContractBusinessUnitResource
     */
    industries?: Array<IndustryResource>;
    /**
     * Desired client types.
     * @type {Array<LeadTypeResource>}
     * @memberof PartnerContractBusinessUnitResource
     */
    clientTypes?: Array<LeadTypeResource>;
    /**
     * Desired geo regions.
     * @type {Array<PartnerBusinessUnitSegmentationGeoRegionResource>}
     * @memberof PartnerContractBusinessUnitResource
     */
    geoRegions?: Array<PartnerBusinessUnitSegmentationGeoRegionResource>;
    /**
     *
     * @type {PartnerContractBusinessUnitQuotaResource}
     * @memberof PartnerContractBusinessUnitResource
     */
    allocationQuota?: PartnerContractBusinessUnitQuotaResource;
    /**
     *
     * @type {PartnerContractBusinessUnitQuotaResource}
     * @memberof PartnerContractBusinessUnitResource
     */
    allocationLimit?: PartnerContractBusinessUnitQuotaResource;
    /**
     * Flag whether business unit wants exclusive leads
     * @type {boolean}
     * @memberof PartnerContractBusinessUnitResource
     */
    wantsExclusiveLeads?: boolean;
    /**
     *
     * @type {RevenueSegmentResource}
     * @memberof PartnerContractBusinessUnitResource
     */
    revenueSegment?: RevenueSegmentResource;
}

/**
 *
 * @export
 * @interface PartnerContractKpiResource
 */
export interface PartnerContractKpiResource {
    /**
     * The number of total deallocations.
     * @type {number}
     * @memberof PartnerContractKpiResource
     */
    deallocations?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerContractKpiResource
     */
    bindingPeriodAmountExclVat?: AmountResource;
    /**
     * Contract active status
     * @type {boolean}
     * @memberof PartnerContractKpiResource
     */
    isActive?: boolean;
    /**
     * Next renewal date
     * @type {string}
     * @memberof PartnerContractKpiResource
     */
    nextRenewalAt?: string;
    /**
     * Weighted delivery trend from all business units in current period
     * @type {number}
     * @memberof PartnerContractKpiResource
     */
    weightedDeliveryTrend?: number;
}

/**
 *
 * @export
 * @interface PartnerContractOfferGroupResource
 */
export interface PartnerContractOfferGroupResource {
    /**
     * The offer group id.
     * @type {number}
     * @memberof PartnerContractOfferGroupResource
     */
    id?: number;
    /**
     *
     * @type {NestedContractOfferGroupResource}
     * @memberof PartnerContractOfferGroupResource
     */
    partner?: NestedContractOfferGroupResource;
    /**
     * List of connected offers.
     * @type {Array<PartnerContractOfferResource>}
     * @memberof PartnerContractOfferGroupResource
     */
    offers?: Array<PartnerContractOfferResource>;
    /**
     * List of indicative regions the partner is interested in.
     * @type {Array<SegmentationGeoRegionResource>}
     * @memberof PartnerContractOfferGroupResource
     */
    segmentationGeoRegions?: Array<SegmentationGeoRegionResource>;
}

/**
 *
 * @export
 * @interface PartnerContractOfferGroupResult
 */
export interface PartnerContractOfferGroupResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerContractOfferGroupResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerContractOfferGroupResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerContractOfferGroupResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerContractOfferGroupResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerContractOfferGroupResource>}
     * @memberof PartnerContractOfferGroupResult
     */
    data?: Array<PartnerContractOfferGroupResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerContractOfferGroupResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerContractOfferItemResource
 */
export interface PartnerContractOfferItemResource {
    /**
     * The offer item id.
     * @type {number}
     * @memberof PartnerContractOfferItemResource
     */
    id?: number;
    /**
     *
     * @type {NestedPartnerContractOfferResource}
     * @memberof PartnerContractOfferItemResource
     */
    offer?: NestedPartnerContractOfferResource;
    /**
     *
     * @type {RevenueSegmentResource}
     * @memberof PartnerContractOfferItemResource
     */
    revenueSegment?: RevenueSegmentResource;
    /**
     * The number of leads that the partner will be guaranteed.
     * @type {number}
     * @memberof PartnerContractOfferItemResource
     */
    leadQuota?: number;
}

/**
 *
 * @export
 * @interface PartnerContractOfferResource
 */
export interface PartnerContractOfferResource {
    /**
     * The offer id.
     * @type {number}
     * @memberof PartnerContractOfferResource
     */
    id?: number;
    /**
     *
     * @type {NestedContractOfferGroupResource}
     * @memberof PartnerContractOfferResource
     */
    offerGroup?: NestedContractOfferGroupResource;
    /**
     * List of offer items that are connected to this offer.
     * @type {Array<PartnerContractOfferItemResource>}
     * @memberof PartnerContractOfferResource
     */
    offerItems?: Array<PartnerContractOfferItemResource>;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerContractOfferResource
     */
    priceExclVat?: AmountResource;
    /**
     * List of offer items that are connected to this offer.
     * @type {number}
     * @memberof PartnerContractOfferResource
     */
    sort?: number;
}

/**
 *
 * @export
 * @interface PartnerContractPartnerResource
 */
export interface PartnerContractPartnerResource {
    /**
     * The partner ID.
     * @type {number}
     * @memberof PartnerContractPartnerResource
     */
    id?: number;
}

/**
 *
 * @export
 * @interface PartnerContractPdfResource
 */
export interface PartnerContractPdfResource {
    /**
     * The contract ID.
     * @type {string}
     * @memberof PartnerContractPdfResource
     */
    url?: string;
}

/**
 *
 * @export
 * @interface PartnerContractPeriodResource
 */
export interface PartnerContractPeriodResource {
    /**
     * The contract period ID.
     * @type {number}
     * @memberof PartnerContractPeriodResource
     */
    id?: number;
    /**
     * The contract period start date.
     * @type {string}
     * @memberof PartnerContractPeriodResource
     */
    startsAt?: string;
    /**
     * The contract period end date.
     * @type {string}
     * @memberof PartnerContractPeriodResource
     */
    endsAt?: string;
    /**
     * The contract period immutable date.
     * @type {string}
     * @memberof PartnerContractPeriodResource
     */
    immutableEndsAt?: string;
}

/**
 *
 * @export
 * @interface PartnerContractResource
 */
export interface PartnerContractResource {
    /**
     * The contract ID.
     * @type {number}
     * @memberof PartnerContractResource
     */
    id?: number;
    /**
     *
     * @type {PartnerContractPartnerResource}
     * @memberof PartnerContractResource
     */
    partner?: PartnerContractPartnerResource;
    /**
     * When the contract was created.
     * @type {string}
     * @memberof PartnerContractResource
     */
    createdAt?: string;
    /**
     * Number of months contract is bound for, i.e. how often is the contract renewed.
     * @type {number}
     * @memberof PartnerContractResource
     */
    bindingPeriodMonths?: number;
    /**
     * How often is the contract billed.
     * @type {number}
     * @memberof PartnerContractResource
     */
    billingFrequencyMonths?: number;
    /**
     * How often are the allocations populated.
     * @type {number}
     * @memberof PartnerContractResource
     */
    allocationFrequencyMonths?: number;
    /**
     * How many months before contract end can partner cancel.
     * @type {number}
     * @memberof PartnerContractResource
     */
    cancellationPeriodMonths?: number;
    /**
     * When the contract was accepted start.
     * @type {string}
     * @memberof PartnerContractResource
     */
    acceptedAt?: string;
    /**
     * When the contract was canceled.
     * @type {string}
     * @memberof PartnerContractResource
     */
    canceledAt?: string;
    /**
     * If the contract has been replaced by another contract.
     * @type {boolean}
     * @memberof PartnerContractResource
     */
    hasBeenReplaced?: boolean;
    /**
     * When the contract should start.
     * @type {string}
     * @memberof PartnerContractResource
     */
    startsAt?: string;
    /**
     * When the contract was signed.
     * @type {string}
     * @memberof PartnerContractResource
     */
    signedAt?: string;
    /**
     * Business units on this contract.
     * @type {Array<PartnerContractBusinessUnitResource>}
     * @memberof PartnerContractResource
     */
    businessUnits?: Array<PartnerContractBusinessUnitResource>;
    /**
     *
     * @type {PartnerContractPeriodResource}
     * @memberof PartnerContractResource
     */
    currentPartnerContractPeriod?: PartnerContractPeriodResource;
    /**
     * The end date of the last created period on a canceled contract, marking when the contract will truly end.
     * @type {string}
     * @memberof PartnerContractResource
     */
    churnsAt?: string;
    /**
     * ID of the contract that this contract intends to replace.
     * @type {number}
     * @memberof PartnerContractResource
     */
    intendingToReplacePartnerContractId?: number;
    /**
     * ID of the contract being replaced by this contract.
     * @type {number}
     * @memberof PartnerContractResource
     */
    replacesPartnerContractId?: number;
    /**
     *
     * @type {PartnerCategoryResource}
     * @memberof PartnerContractResource
     */
    category?: PartnerCategoryResource;
    /**
     * List of allocation pauses.
     * @type {Array<PartnerContractAllocationPauseResource>}
     * @memberof PartnerContractResource
     */
    allocationPauses?: Array<PartnerContractAllocationPauseResource>;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerContractResource
     */
    bindingPeriodAmountExclVat?: AmountResource;
    /**
     *
     * @type {PartnerContractKpiResource}
     * @memberof PartnerContractResource
     */
    kpi?: PartnerContractKpiResource;
    /**
     * List of allocation boost periods.
     * @type {Array<PartnerContractAllocationBoostResource>}
     * @memberof PartnerContractResource
     */
    allocationBoosts?: Array<PartnerContractAllocationBoostResource>;
}

/**
 *
 * @export
 * @interface PartnerContractResult
 */
export interface PartnerContractResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerContractResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerContractResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerContractResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerContractResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerContractResource>}
     * @memberof PartnerContractResult
     */
    data?: Array<PartnerContractResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerContractResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerContractSignedPdfFileResource
 */
export interface PartnerContractSignedPdfFileResource {
    /**
     * The contract signed pdf.
     * @type {string}
     * @memberof PartnerContractSignedPdfFileResource
     */
    signedContract?: string;
}

/**
 *
 * @export
 * @interface PartnerCouponActionResource
 */
export interface PartnerCouponActionResource {
    /**
     * Partner action
     * @type {string}
     * @memberof PartnerCouponActionResource
     */
    action?: PartnerCouponActionResource.ActionEnum;
    /**
     * Date
     * @type {string}
     * @memberof PartnerCouponActionResource
     */
    date?: string;
}

/**
 * @export
 * @namespace PartnerCouponActionResource
 */
export namespace PartnerCouponActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        Unknown = <any> 'unknown',
        Deactivate = <any> 'deactivate',
        Extend = <any> 'extend',
        Reactivate = <any> 'reactivate'
    }
}

/**
 *
 * @export
 * @interface PartnerCouponResource
 */
export interface PartnerCouponResource {
    /**
     *
     * @type {string}
     * @memberof PartnerCouponResource
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerCouponResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerCouponResource
     */
    expiresAt?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerCouponResource
     */
    updatedAt?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerCouponResource
     */
    deactivatedAt?: string;
    /**
     *
     * @type {number}
     * @memberof PartnerCouponResource
     */
    partnerId?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerCouponResource
     */
    employeeId?: number;
    /**
     *
     * @type {string}
     * @memberof PartnerCouponResource
     */
    message?: string;
    /**
     *
     * @type {number}
     * @memberof PartnerCouponResource
     */
    punchesTotal?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerCouponResource
     */
    punchesLeft?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerCouponResource
     */
    price?: AmountResource;
    /**
     *
     * @type {string}
     * @memberof PartnerCouponResource
     */
    dueAt?: string;
    /**
     *
     * @type {boolean}
     * @memberof PartnerCouponResource
     */
    isSilent?: boolean;
    /**
     *
     * @type {number}
     * @memberof PartnerCouponResource
     */
    partnerOfferId?: number;
    /**
     *
     * @type {string}
     * @memberof PartnerCouponResource
     */
    type?: PartnerCouponResource.TypeEnum;
}

/**
 * @export
 * @namespace PartnerCouponResource
 */
export namespace PartnerCouponResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Coupon = <any> 'coupon',
        MarketplaceCoupon = <any> 'marketplace_coupon',
        Refund = <any> 'refund',
        Transfer = <any> 'transfer',
        Reactivation = <any> 'reactivation'
    }
}

/**
 *
 * @export
 * @interface PartnerCouponResult
 */
export interface PartnerCouponResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerCouponResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerCouponResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerCouponResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerCouponResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerCouponResource>}
     * @memberof PartnerCouponResult
     */
    data?: Array<PartnerCouponResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerCouponResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerCouponTransactionResource
 */
export interface PartnerCouponTransactionResource {
    /**
     *
     * @type {string}
     * @memberof PartnerCouponTransactionResource
     */
    id?: string;
    /**
     *
     * @type {number}
     * @memberof PartnerCouponTransactionResource
     */
    punches?: number;
    /**
     *
     * @type {string}
     * @memberof PartnerCouponTransactionResource
     */
    createdAt?: string;
    /**
     *
     * @type {number}
     * @memberof PartnerCouponTransactionResource
     */
    couponId?: number;
    /**
     *
     * @type {string}
     * @memberof PartnerCouponTransactionResource
     */
    product?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerCouponTransactionResource
     */
    type?: string;
    /**
     *
     * @type {LeadResource}
     * @memberof PartnerCouponTransactionResource
     */
    lead?: LeadResource;
}

/**
 *
 * @export
 * @interface PartnerCouponTransactionResult
 */
export interface PartnerCouponTransactionResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerCouponTransactionResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerCouponTransactionResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerCouponTransactionResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerCouponTransactionResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerCouponTransactionResource>}
     * @memberof PartnerCouponTransactionResult
     */
    data?: Array<PartnerCouponTransactionResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerCouponTransactionResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerDeliverableLeadResource
 */
export interface PartnerDeliverableLeadResource {
    /**
     * Lead id.
     * @type {number}
     * @memberof PartnerDeliverableLeadResource
     */
    id?: number;
    /**
     * Lead First Name
     * @type {string}
     * @memberof PartnerDeliverableLeadResource
     */
    firstName?: string;
    /**
     * Leads Last Name
     * @type {string}
     * @memberof PartnerDeliverableLeadResource
     */
    lastName?: string;
    /**
     * Leads company name
     * @type {string}
     * @memberof PartnerDeliverableLeadResource
     */
    companyName?: string;
}

/**
 *
 * @export
 * @interface PartnerDeliverableLineItemResource
 */
export interface PartnerDeliverableLineItemResource {
    /**
     * type of the delivered item
     * @type {string}
     * @memberof PartnerDeliverableLineItemResource
     */
    type?: PartnerDeliverableLineItemResource.TypeEnum;
    /**
     *
     * @type {PartnerDeliverableQuoteItemResource}
     * @memberof PartnerDeliverableLineItemResource
     */
    quoteItem?: PartnerDeliverableQuoteItemResource;
}

/**
 * @export
 * @namespace PartnerDeliverableLineItemResource
 */
export namespace PartnerDeliverableLineItemResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Unknown = <any> 'unknown',
        QuoteItem = <any> 'quote_item'
    }
}

/**
 *
 * @export
 * @interface PartnerDeliverableLineResource
 */
export interface PartnerDeliverableLineResource {
    /**
     * deliverable line id
     * @type {number}
     * @memberof PartnerDeliverableLineResource
     */
    id?: number;
    /**
     * when the line was created
     * @type {string}
     * @memberof PartnerDeliverableLineResource
     */
    createdAt?: string;
    /**
     * when the line was update
     * @type {string}
     * @memberof PartnerDeliverableLineResource
     */
    updatedAt?: string;
    /**
     * Name describing the deliverable line
     * @type {string}
     * @memberof PartnerDeliverableLineResource
     */
    name?: string;
    /**
     *
     * @type {PartnerDeliverableLineItemResource}
     * @memberof PartnerDeliverableLineResource
     */
    item?: PartnerDeliverableLineItemResource;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerDeliverableLineResource
     */
    amountVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerDeliverableLineResource
     */
    amountExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerDeliverableLineResource
     */
    amountInclVat?: AmountResource;
}

/**
 *
 * @export
 * @interface PartnerDeliverableLineResult
 */
export interface PartnerDeliverableLineResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerDeliverableLineResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerDeliverableLineResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerDeliverableLineResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerDeliverableLineResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerDeliverableLineResource>}
     * @memberof PartnerDeliverableLineResult
     */
    data?: Array<PartnerDeliverableLineResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerDeliverableLineResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerDeliverableQuoteItemResource
 */
export interface PartnerDeliverableQuoteItemResource {
    /**
     * id of quote
     * @type {number}
     * @memberof PartnerDeliverableQuoteItemResource
     */
    id?: number;
    /**
     * name of quote
     * @type {string}
     * @memberof PartnerDeliverableQuoteItemResource
     */
    name?: string;
}

/**
 *
 * @export
 * @interface PartnerDeliverableResource
 */
export interface PartnerDeliverableResource {
    /**
     * id for partner deliverable
     * @type {number}
     * @memberof PartnerDeliverableResource
     */
    id?: number;
    /**
     * created
     * @type {string}
     * @memberof PartnerDeliverableResource
     */
    createdAt?: string;
    /**
     * updated
     * @type {string}
     * @memberof PartnerDeliverableResource
     */
    updatedAt?: string;
    /**
     * status of the deliverable
     * @type {string}
     * @memberof PartnerDeliverableResource
     */
    status?: PartnerDeliverableResource.StatusEnum;
    /**
     * partner id
     * @type {number}
     * @memberof PartnerDeliverableResource
     */
    partnerId?: number;
    /**
     *
     * @type {PartnerDeliverableLeadResource}
     * @memberof PartnerDeliverableResource
     */
    lead?: PartnerDeliverableLeadResource;
    /**
     * description of the deliverable
     * @type {string}
     * @memberof PartnerDeliverableResource
     */
    description?: string;
    /**
     * lines
     * @type {Array<PartnerDeliverableLineResource>}
     * @memberof PartnerDeliverableResource
     */
    lines?: Array<PartnerDeliverableLineResource>;
    /**
     * Attachment to the deliverable like invoices, etc.
     * @type {Array<AttachmentResource>}
     * @memberof PartnerDeliverableResource
     */
    attachments?: Array<AttachmentResource>;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerDeliverableResource
     */
    totalVatAmount?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerDeliverableResource
     */
    totalAmountExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerDeliverableResource
     */
    totalAmountInclVat?: AmountResource;
}

/**
 * @export
 * @namespace PartnerDeliverableResource
 */
export namespace PartnerDeliverableResource {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Unknown = <any> 'unknown',
        New = <any> 'new',
        Delivered = <any> 'delivered',
        Paid = <any> 'paid',
        Rejected = <any> 'rejected'
    }
}

/**
 *
 * @export
 * @interface PartnerDeliverableResult
 */
export interface PartnerDeliverableResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerDeliverableResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerDeliverableResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerDeliverableResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerDeliverableResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerDeliverableResource>}
     * @memberof PartnerDeliverableResult
     */
    data?: Array<PartnerDeliverableResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerDeliverableResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerDemoResource
 */
export interface PartnerDemoResource {
    /**
     * Days that extend the lead period
     * @type {number}
     * @memberof PartnerDemoResource
     */
    leadExtensionPeriod?: number;
    /**
     * has lead access
     * @type {boolean}
     * @memberof PartnerDemoResource
     */
    hasLeadAccess?: boolean;
}

/**
 *
 * @export
 * @interface PartnerDiscountResource
 */
export interface PartnerDiscountResource {
    /**
     *
     * @type {number}
     * @memberof PartnerDiscountResource
     */
    id?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerDiscountResource
     */
    partnerId?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerDiscountResource
     */
    employeeId?: number;
    /**
     * The subscription type related to.
     * @type {number}
     * @memberof PartnerDiscountResource
     */
    partnerPackageTypeId?: number;
    /**
     * Discount value.
     * @type {string}
     * @memberof PartnerDiscountResource
     */
    discount?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerDiscountResource
     */
    startsAt?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerDiscountResource
     */
    endsAt?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerDiscountResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerDiscountResource
     */
    updatedAt?: string;
}

/**
 *
 * @export
 * @interface PartnerDiscountResult
 */
export interface PartnerDiscountResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerDiscountResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerDiscountResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerDiscountResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerDiscountResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerDiscountResource>}
     * @memberof PartnerDiscountResult
     */
    data?: Array<PartnerDiscountResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerDiscountResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerEmployeeNoteResource
 */
export interface PartnerEmployeeNoteResource {
    /**
     *
     * @type {number}
     * @memberof PartnerEmployeeNoteResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof PartnerEmployeeNoteResource
     */
    content?: string;
    /**
     *
     * @type {PartnerEmployeeResource}
     * @memberof PartnerEmployeeNoteResource
     */
    employee?: PartnerEmployeeResource;
    /**
     *
     * @type {number}
     * @memberof PartnerEmployeeNoteResource
     */
    leadId?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerEmployeeNoteResource
     */
    partnerId?: number;
    /**
     *
     * @type {string}
     * @memberof PartnerEmployeeNoteResource
     */
    createdAt?: string;
}

/**
 *
 * @export
 * @interface PartnerEmployeeNoteResult
 */
export interface PartnerEmployeeNoteResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerEmployeeNoteResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerEmployeeNoteResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerEmployeeNoteResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerEmployeeNoteResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerEmployeeNoteResource>}
     * @memberof PartnerEmployeeNoteResult
     */
    data?: Array<PartnerEmployeeNoteResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerEmployeeNoteResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerEmployeeResource
 */
export interface PartnerEmployeeResource {
    /**
     *
     * @type {number}
     * @memberof PartnerEmployeeResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof PartnerEmployeeResource
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerEmployeeResource
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerEmployeeResource
     */
    profilePicture?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerEmployeeResource
     */
    initials?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerEmployeeResource
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerEmployeeResource
     */
    phoneWork?: string;
}

/**
 *
 * @export
 * @interface PartnerFeatureResource
 */
export interface PartnerFeatureResource {
    /**
     * Id.
     * @type {number}
     * @memberof PartnerFeatureResource
     */
    id?: number;
    /**
     * Identifier
     * @type {string}
     * @memberof PartnerFeatureResource
     */
    identifier?: string;
}

/**
 *
 * @export
 * @interface PartnerFeeResource
 */
export interface PartnerFeeResource {
    /**
     * ID.
     * @type {number}
     * @memberof PartnerFeeResource
     */
    id?: number;
    /**
     * Partner ID.
     * @type {number}
     * @memberof PartnerFeeResource
     */
    partnerId?: number;
    /**
     * created at date.
     * @type {string}
     * @memberof PartnerFeeResource
     */
    createdAt?: string;
    /**
     * updated at date.
     * @type {string}
     * @memberof PartnerFeeResource
     */
    updatedAt?: string;
    /**
     * effective from date.
     * @type {string}
     * @memberof PartnerFeeResource
     */
    effectiveAt?: string;
    /**
     * revenue share percentage
     * @type {number}
     * @memberof PartnerFeeResource
     */
    revenueSharePercentage?: number;
    /**
     * revenue share subsequent years percentage
     * @type {number}
     * @memberof PartnerFeeResource
     */
    revenueShareSubsequentYearsPercentage?: number;
}

/**
 *
 * @export
 * @interface PartnerFeeResult
 */
export interface PartnerFeeResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerFeeResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerFeeResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerFeeResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerFeeResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerFeeResource>}
     * @memberof PartnerFeeResult
     */
    data?: Array<PartnerFeeResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerFeeResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerGeoResource
 */
export interface PartnerGeoResource {
    /**
     * Code for the given location.
     * @type {string}
     * @memberof PartnerGeoResource
     */
    code?: string;
    /**
     * url slug
     * @type {string}
     * @memberof PartnerGeoResource
     */
    slug?: string;
    /**
     * The address.
     * @type {string}
     * @memberof PartnerGeoResource
     */
    address?: string;
    /**
     * Zip Code.
     * @type {string}
     * @memberof PartnerGeoResource
     */
    zipCode?: string;
    /**
     * Name of the city.
     * @type {string}
     * @memberof PartnerGeoResource
     */
    cityName?: string;
    /**
     * Name of the city district.
     * @type {string}
     * @memberof PartnerGeoResource
     */
    cityDistrict?: string;
    /**
     *
     * @type {LocationGeoPointResource}
     * @memberof PartnerGeoResource
     */
    point?: LocationGeoPointResource;
    /**
     *
     * @type {SegmentationSubGeoRegionResource}
     * @memberof PartnerGeoResource
     */
    homeSubGeoRegion?: SegmentationSubGeoRegionResource;
}

/**
 *
 * @export
 * @interface PartnerImpressumResource
 */
export interface PartnerImpressumResource {
    /**
     * Text of the impressum.
     * @type {string}
     * @memberof PartnerImpressumResource
     */
    text?: string;
}

/**
 *
 * @export
 * @interface PartnerIndustriesResource
 */
export interface PartnerIndustriesResource {
    /**
     * Industry IDs.
     * @type {Array<number>}
     * @memberof PartnerIndustriesResource
     */
    industryIds?: Array<number>;
}

/**
 *
 * @export
 * @interface PartnerIndustryResource
 */
export interface PartnerIndustryResource {
    /**
     * Id for the industry.
     * @type {number}
     * @memberof PartnerIndustryResource
     */
    id?: number;
    /**
     * Name of the industry.
     * @type {string}
     * @memberof PartnerIndustryResource
     */
    name?: string;
    /**
     * Uri friendly name for the industry.
     * @type {string}
     * @memberof PartnerIndustryResource
     */
    slug?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerIndustryResource
     */
    identifier?: string;
}

/**
 *
 * @export
 * @interface PartnerIndustryResult
 */
export interface PartnerIndustryResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerIndustryResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerIndustryResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerIndustryResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerIndustryResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerIndustryResource>}
     * @memberof PartnerIndustryResult
     */
    data?: Array<PartnerIndustryResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerIndustryResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerInvoicingResource
 */
export interface PartnerInvoicingResource {
    /**
     * Name of the company to invoice.
     * @type {string}
     * @memberof PartnerInvoicingResource
     */
    companyName?: string;
    /**
     * Vat number for the invoice.
     * @type {string}
     * @memberof PartnerInvoicingResource
     */
    vatNumber?: string;
    /**
     * Adress.
     * @type {string}
     * @memberof PartnerInvoicingResource
     */
    address?: string;
    /**
     * Zip Code.
     * @type {string}
     * @memberof PartnerInvoicingResource
     */
    zipCode?: string;
    /**
     * City Name.
     * @type {string}
     * @memberof PartnerInvoicingResource
     */
    cityName?: string;
    /**
     * Email to the contact.
     * @type {string}
     * @memberof PartnerInvoicingResource
     */
    email?: string;
    /**
     * Attention to a given employee in the partner company.
     * @type {string}
     * @memberof PartnerInvoicingResource
     */
    attention?: string;
    /**
     * Number of days bewteen invoice issue date and due date.
     * @type {number}
     * @memberof PartnerInvoicingResource
     */
    daysGrace?: number;
}

/**
 *
 * @export
 * @interface PartnerKPIResource
 */
export interface PartnerKPIResource {
    /**
     *
     * @type {number}
     * @memberof PartnerKPIResource
     */
    punchesMonthly?: number;
    /**
     *
     * @type {string}
     * @memberof PartnerKPIResource
     */
    lastLogin?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerKPIResource
     */
    lastInvoice?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerKPIResource
     */
    lastPhoneCall?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerKPIResource
     */
    lastPublishedQuote?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerKPIResource
     */
    lastSubscriptionStartDate?: string;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerKPIResource
     */
    revenueMonth?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerKPIResource
     */
    revenue6months?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerKPIResource
     */
    revenueTotal?: AmountResource;
    /**
     *
     * @type {number}
     * @memberof PartnerKPIResource
     */
    acceptedQuotesCount?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerKPIResource
     */
    acceptedQuotesCountMonth?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerKPIResource
     */
    publishedQuotesCount?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerKPIResource
     */
    publishedQuotesCountMonth?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerKPIResource
     */
    unmatchedCount?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerKPIResource
     */
    unmatchedPercent?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerKPIResource
     */
    winRatio?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerKPIResource
     */
    allocatedQuotesCount?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerKPIResource
     */
    allocatedQuotesCountMonth?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerKPIResource
     */
    acceptedQuoteAmountExclVat?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerKPIResource
     */
    acceptedQuoteAmountExclVatMonth?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerKPIResource
     */
    overdueInvoiceAmountExclVat?: AmountResource;
}

/**
 *
 * @export
 * @interface PartnerLeadTypeResource
 */
export interface PartnerLeadTypeResource {
    /**
     *
     * @type {number}
     * @memberof PartnerLeadTypeResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof PartnerLeadTypeResource
     */
    title?: string;
}

/**
 *
 * @export
 * @interface PartnerLeadTypesResource
 */
export interface PartnerLeadTypesResource {
    /**
     * Type IDs.
     * @type {Array<number>}
     * @memberof PartnerLeadTypesResource
     */
    typeIds?: Array<number>;
}

/**
 *
 * @export
 * @interface PartnerMarketingConsentResource
 */
export interface PartnerMarketingConsentResource {
    /**
     *
     * @type {boolean}
     * @memberof PartnerMarketingConsentResource
     */
    isGranted?: boolean;
}

/**
 *
 * @export
 * @interface PartnerMarketplaceResource
 */
export interface PartnerMarketplaceResource {
    /**
     * Current created quotes for partner depends on model
     * @type {number}
     * @memberof PartnerMarketplaceResource
     */
    currentQuotesCount?: number;
    /**
     * Current limit on quotes for partner depends on model
     * @type {number}
     * @memberof PartnerMarketplaceResource
     */
    currentQuotesLimit?: number;
}

/**
 *
 * @export
 * @interface PartnerNewKpiResource
 */
export interface PartnerNewKpiResource {
    /**
     * Success ratio.
     * @type {number}
     * @memberof PartnerNewKpiResource
     */
    successRatio?: number;
    /**
     * Publishing ratio.
     * @type {number}
     * @memberof PartnerNewKpiResource
     */
    publishingRatio?: number;
    /**
     * Count of deallocations.
     * @type {number}
     * @memberof PartnerNewKpiResource
     */
    deallocations?: number;
    /**
     * Count of active contracts.
     * @type {number}
     * @memberof PartnerNewKpiResource
     */
    activeContractsCount?: number;
    /**
     * Count of active allocation pauses.
     * @type {number}
     * @memberof PartnerNewKpiResource
     */
    activeAllocationPausesCount?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerNewKpiResource
     */
    overdueInvoiceAmountInclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerNewKpiResource
     */
    activeContractsAmountExclVat?: AmountResource;
    /**
     * Next contract renewal date
     * @type {string}
     * @memberof PartnerNewKpiResource
     */
    nextContractRenewalAt?: string;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerNewKpiResource
     */
    activeBusinessUnitsAmountExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerNewKpiResource
     */
    activeBusinessUnitsMrrExclVat?: AmountResource;
    /**
     * Ratio between optimal delivery ratio and acutal delivery ratio within current contract period
     * @type {number}
     * @memberof PartnerNewKpiResource
     */
    currentPeriodWeightedDeliveryTrend?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerNewKpiResource
     */
    allTimePurchasedMarketplaceLeadsValueExclVatLocalCurrency?: AmountResource;
    /**
     * @var $all_time_purchased_marketplace_leads_count
     * @type {number}
     * @memberof PartnerNewKpiResource
     */
    allTimePurchasedMarketplaceLeadsCount?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerNewKpiResource
     */
    leadMarketplacePartnerSpendExclVatLocalCurrency?: AmountResource;
}

/**
 *
 * @export
 * @interface PartnerNoteResource
 */
export interface PartnerNoteResource {
    /**
     *
     * @type {number}
     * @memberof PartnerNoteResource
     */
    id?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerNoteResource
     */
    employeeId?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerNoteResource
     */
    partnerUserId?: number;
    /**
     *
     * @type {string}
     * @memberof PartnerNoteResource
     */
    creatorType?: PartnerNoteResource.CreatorTypeEnum;
    /**
     *
     * @type {string}
     * @memberof PartnerNoteResource
     */
    note?: string;
    /**
     *
     * @type {PartnerSatisfactionResource}
     * @memberof PartnerNoteResource
     */
    satisfaction?: PartnerSatisfactionResource;
}

/**
 * @export
 * @namespace PartnerNoteResource
 */
export namespace PartnerNoteResource {
    /**
     * @export
     * @enum {string}
     */
    export enum CreatorTypeEnum {
        Unknown = <any> 'unknown',
        Employee = <any> 'employee',
        PartnerUser = <any> 'partner_user'
    }
}

/**
 *
 * @export
 * @interface PartnerNoteResult
 */
export interface PartnerNoteResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerNoteResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerNoteResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerNoteResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerNoteResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerNoteResource>}
     * @memberof PartnerNoteResult
     */
    data?: Array<PartnerNoteResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerNoteResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerOfferResource
 */
export interface PartnerOfferResource {
    /**
     * Offer ID.
     * @type {string}
     * @memberof PartnerOfferResource
     */
    id?: string;
    /**
     * Offer created at date.
     * @type {string}
     * @memberof PartnerOfferResource
     */
    createdAt?: string;
    /**
     * Offer updated at date.
     * @type {string}
     * @memberof PartnerOfferResource
     */
    updatedAt?: string;
    /**
     * Offer accepted at date.
     * @type {string}
     * @memberof PartnerOfferResource
     */
    acceptedAt?: string;
    /**
     * Offer expired at date.
     * @type {string}
     * @memberof PartnerOfferResource
     */
    expiresAt?: string;
    /**
     * Offer deprecated/grandfathered at.
     * @type {string}
     * @memberof PartnerOfferResource
     */
    deprecatedAt?: string;
    /**
     * Offer type.
     * @type {string}
     * @memberof PartnerOfferResource
     */
    type?: PartnerOfferResource.TypeEnum;
    /**
     * Sale type.
     * @type {string}
     * @memberof PartnerOfferResource
     */
    saleType?: string;
    /**
     * Name.
     * @type {string}
     * @memberof PartnerOfferResource
     */
    name?: string;
    /**
     * Offer number of punches.
     * @type {number}
     * @memberof PartnerOfferResource
     */
    punches?: number;
    /**
     * Number of days from offer accept to payment is due.
     * @type {number}
     * @memberof PartnerOfferResource
     */
    dueDays?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerOfferResource
     */
    price?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerOfferResource
     */
    avgPunchPrice?: AmountResource;
    /**
     * Status.
     * @type {string}
     * @memberof PartnerOfferResource
     */
    status?: PartnerOfferResource.StatusEnum;
    /**
     *
     * @type {EmployeeResource}
     * @memberof PartnerOfferResource
     */
    employee?: EmployeeResource;
    /**
     * Is offer activated.
     * @type {boolean}
     * @memberof PartnerOfferResource
     */
    isActivated?: boolean;
}

/**
 * @export
 * @namespace PartnerOfferResource
 */
export namespace PartnerOfferResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Canvas = <any> 'canvas',
        Demo = <any> 'demo',
        Partner = <any> 'partner',
        Standard = <any> 'standard'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        New = <any> 'new',
        Expired = <any> 'expired',
        Accepted = <any> 'accepted'
    }
}

/**
 *
 * @export
 * @interface PartnerOfferResult
 */
export interface PartnerOfferResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerOfferResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerOfferResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerOfferResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerOfferResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerOfferResource>}
     * @memberof PartnerOfferResult
     */
    data?: Array<PartnerOfferResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerOfferResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerPaymentScheduleResource
 */
export interface PartnerPaymentScheduleResource {
    /**
     * Name of payment schedule type.
     * @type {string}
     * @memberof PartnerPaymentScheduleResource
     */
    name?: string;
    /**
     * Identifier name of payment schedule type.
     * @type {string}
     * @memberof PartnerPaymentScheduleResource
     */
    identifier?: PartnerPaymentScheduleResource.IdentifierEnum;
}

/**
 * @export
 * @namespace PartnerPaymentScheduleResource
 */
export namespace PartnerPaymentScheduleResource {
    /**
     * @export
     * @enum {string}
     */
    export enum IdentifierEnum {
        WithSubscriptionPeriod = <any> 'with_subscription_period',
        Immediate = <any> 'immediate'
    }
}

/**
 *
 * @export
 * @interface PartnerPaymentScheduleResult
 */
export interface PartnerPaymentScheduleResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerPaymentScheduleResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerPaymentScheduleResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerPaymentScheduleResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerPaymentScheduleResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerPaymentScheduleResource>}
     * @memberof PartnerPaymentScheduleResult
     */
    data?: Array<PartnerPaymentScheduleResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerPaymentScheduleResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerPreferencesResource
 */
export interface PartnerPreferencesResource {
    /**
     *
     * @type {CurrencyResource}
     * @memberof PartnerPreferencesResource
     */
    currency?: CurrencyResource;
    /**
     * Maximum distance to leads in kilometres. Used to segment the right leads for the partner.
     * @type {number}
     * @memberof PartnerPreferencesResource
     */
    leadDistance?: number;
    /**
     * If partner wants digital leads
     * @type {boolean}
     * @memberof PartnerPreferencesResource
     */
    digitalLeads?: boolean;
}

/**
 *
 * @export
 * @interface PartnerQuoteProductCommentProductResource
 */
export interface PartnerQuoteProductCommentProductResource {
    /**
     *
     * @type {number}
     * @memberof PartnerQuoteProductCommentProductResource
     */
    id?: number;
}

/**
 *
 * @export
 * @interface PartnerQuoteProductCommentResource
 */
export interface PartnerQuoteProductCommentResource {
    /**
     *
     * @type {number}
     * @memberof PartnerQuoteProductCommentResource
     */
    id?: number;
    /**
     *
     * @type {PartnerQuoteProductCommentProductResource}
     * @memberof PartnerQuoteProductCommentResource
     */
    product?: PartnerQuoteProductCommentProductResource;
    /**
     *
     * @type {string}
     * @memberof PartnerQuoteProductCommentResource
     */
    comment?: string;
}

/**
 *
 * @export
 * @interface PartnerQuoteProductCommentResult
 */
export interface PartnerQuoteProductCommentResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerQuoteProductCommentResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerQuoteProductCommentResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerQuoteProductCommentResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerQuoteProductCommentResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerQuoteProductCommentResource>}
     * @memberof PartnerQuoteProductCommentResult
     */
    data?: Array<PartnerQuoteProductCommentResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerQuoteProductCommentResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerQuoteProductPriceProductResource
 */
export interface PartnerQuoteProductPriceProductResource {
    /**
     *
     * @type {number}
     * @memberof PartnerQuoteProductPriceProductResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof PartnerQuoteProductPriceProductResource
     */
    name?: string;
}

/**
 *
 * @export
 * @interface PartnerQuoteProductPriceResource
 */
export interface PartnerQuoteProductPriceResource {
    /**
     *
     * @type {number}
     * @memberof PartnerQuoteProductPriceResource
     */
    id?: number;
    /**
     *
     * @type {PartnerQuoteProductPriceProductResource}
     * @memberof PartnerQuoteProductPriceResource
     */
    product?: PartnerQuoteProductPriceProductResource;
    /**
     *
     * @type {PartnerRevenueSegmentResource}
     * @memberof PartnerQuoteProductPriceResource
     */
    partnerRevenueSegment?: PartnerRevenueSegmentResource;
    /**
     *
     * @type {number}
     * @memberof PartnerQuoteProductPriceResource
     */
    minimumQuantity?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerQuoteProductPriceResource
     */
    maximumQuantity?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerQuoteProductPriceResource
     */
    pricePerUnitExclVat?: AmountResource;
    /**
     *
     * @type {boolean}
     * @memberof PartnerQuoteProductPriceResource
     */
    isFixedPrice?: boolean;
}

/**
 *
 * @export
 * @interface PartnerQuoteProductPriceResult
 */
export interface PartnerQuoteProductPriceResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerQuoteProductPriceResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerQuoteProductPriceResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerQuoteProductPriceResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerQuoteProductPriceResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerQuoteProductPriceResource>}
     * @memberof PartnerQuoteProductPriceResult
     */
    data?: Array<PartnerQuoteProductPriceResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerQuoteProductPriceResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerQuoteVendorResource
 */
export interface PartnerQuoteVendorResource {
    /**
     *
     * @type {number}
     * @memberof PartnerQuoteVendorResource
     */
    id?: number;
    /**
     *
     * @type {PartnerResource}
     * @memberof PartnerQuoteVendorResource
     */
    partner?: PartnerResource;
    /**
     *
     * @type {string}
     * @memberof PartnerQuoteVendorResource
     */
    integrationIdentifier?: string;
}

/**
 *
 * @export
 * @interface PartnerQuoteVendorResult
 */
export interface PartnerQuoteVendorResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerQuoteVendorResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerQuoteVendorResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerQuoteVendorResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerQuoteVendorResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerQuoteVendorResource>}
     * @memberof PartnerQuoteVendorResult
     */
    data?: Array<PartnerQuoteVendorResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerQuoteVendorResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerRatingResource
 */
export interface PartnerRatingResource {
    /**
     * Average Score for the partner.
     * @type {number}
     * @memberof PartnerRatingResource
     */
    score?: number;
    /**
     * Based on the ratings, the amount of stars it represents
     * @type {number}
     * @memberof PartnerRatingResource
     */
    stars?: number;
    /**
     * Number of ratings given.
     * @type {number}
     * @memberof PartnerRatingResource
     */
    count?: number;
    /**
     *
     * @type {PartnerRatingTypesResource}
     * @memberof PartnerRatingResource
     */
    types?: PartnerRatingTypesResource;
    /**
     * The spread of ratings
     * @type {Array<number>}
     * @memberof PartnerRatingResource
     */
    spread?: Array<number>;
}

/**
 *
 * @export
 * @interface PartnerRatingTypesResource
 */
export interface PartnerRatingTypesResource {
    /**
     * Rating on quality
     * @type {number}
     * @memberof PartnerRatingTypesResource
     */
    ratingQuality?: number;
    /**
     * Rating on contact
     * @type {number}
     * @memberof PartnerRatingTypesResource
     */
    ratingContact?: number;
    /**
     * Rating on expertise
     * @type {number}
     * @memberof PartnerRatingTypesResource
     */
    ratingExpertise?: number;
    /**
     * Rating on schedule
     * @type {number}
     * @memberof PartnerRatingTypesResource
     */
    ratingSchedule?: number;
    /**
     * Rating on budget
     * @type {number}
     * @memberof PartnerRatingTypesResource
     */
    ratingBudget?: number;
}

/**
 *
 * @export
 * @interface PartnerResource
 */
export interface PartnerResource {
    /**
     * The partner's id.
     * @type {number}
     * @memberof PartnerResource
     */
    id?: number;
    /**
     * When was the partner created in the system.
     * @type {string}
     * @memberof PartnerResource
     */
    createdAt?: string;
    /**
     * At what date was the partner last updated.
     * @type {string}
     * @memberof PartnerResource
     */
    updatedAt?: string;
    /**
     * reserved at.
     * @type {string}
     * @memberof PartnerResource
     */
    reservedAt?: string;
    /**
     * The company name of the partner.
     * @type {string}
     * @memberof PartnerResource
     */
    companyName?: string;
    /**
     *
     * @type {PartnerTitleResource}
     * @memberof PartnerResource
     */
    title?: PartnerTitleResource;
    /**
     * The slug for the given partner
     * @type {string}
     * @memberof PartnerResource
     */
    slug?: string;
    /**
     * Has the partner been disabled.
     * @type {boolean}
     * @memberof PartnerResource
     */
    isEnabled?: boolean;
    /**
     * Is it a public partner
     * @type {boolean}
     * @memberof PartnerResource
     */
    isPublic?: boolean;
    /**
     * Has the partner an active subscription.
     * @type {boolean}
     * @memberof PartnerResource
     */
    hasSubscription?: boolean;
    /**
     * Has the partner an active subscription with the advisor product (Customer Choice).
     * @type {boolean}
     * @memberof PartnerResource
     */
    hasAdvisorSubscription?: boolean;
    /**
     * Is the partner connected to Stripe. stripe_id filled in.
     * @type {boolean}
     * @memberof PartnerResource
     */
    isStripeCustomer?: boolean;
    /**
     * Has the partner got an active contract.
     * @type {boolean}
     * @memberof PartnerResource
     */
    hasActiveContract?: boolean;
    /**
     * Has the partner got an active contract.
     * @type {boolean}
     * @memberof PartnerResource
     */
    hasUnusedAllocations?: boolean;
    /**
     * What is the current state of the partner.
     * @type {string}
     * @memberof PartnerResource
     */
    state?: PartnerResource.StateEnum;
    /**
     * Access information regarding the partner.
     * @type {string}
     * @memberof PartnerResource
     */
    access?: string;
    /**
     * Information regarding the partner company.
     * @type {string}
     * @memberof PartnerResource
     */
    companyInformation?: string;
    /**
     * Vat information about he company.
     * @type {string}
     * @memberof PartnerResource
     */
    vatNumber?: string;
    /**
     * Website for the partner.
     * @type {string}
     * @memberof PartnerResource
     */
    website?: string;
    /**
     * Logo for the partner.
     * @type {string}
     * @memberof PartnerResource
     */
    logo?: string;
    /**
     * Phone number to contact the partner.
     * @type {string}
     * @memberof PartnerResource
     */
    phone?: string;
    /**
     *
     * @type {PartnerGeoResource}
     * @memberof PartnerResource
     */
    geo?: PartnerGeoResource;
    /**
     *
     * @type {PartnerRatingResource}
     * @memberof PartnerResource
     */
    rating?: PartnerRatingResource;
    /**
     *
     * @type {PartnerEmployeeResource}
     * @memberof PartnerResource
     */
    employee?: PartnerEmployeeResource;
    /**
     *
     * @type {PartnerEmployeeResource}
     * @memberof PartnerResource
     */
    subscriptionEmployee?: PartnerEmployeeResource;
    /**
     * Lead Types.
     * @type {Array<PartnerLeadTypeResource>}
     * @memberof PartnerResource
     */
    leadTypes?: Array<PartnerLeadTypeResource>;
    /**
     *
     * @type {PartnerIndustryResource}
     * @memberof PartnerResource
     */
    industry?: PartnerIndustryResource;
    /**
     * Partner's segmented industries.
     * @type {Array<PartnerIndustryResource>}
     * @memberof PartnerResource
     */
    industries?: Array<PartnerIndustryResource>;
    /**
     *
     * @type {PartnerSatisfactionResource}
     * @memberof PartnerResource
     */
    satisfaction?: PartnerSatisfactionResource;
    /**
     * Partner Alerts.
     * @type {Array<PartnerAlertResource>}
     * @memberof PartnerResource
     */
    alerts?: Array<PartnerAlertResource>;
    /**
     *
     * @type {PartnerKPIResource}
     * @memberof PartnerResource
     */
    kpi?: PartnerKPIResource;
    /**
     *
     * @type {PartnerNewKpiResource}
     * @memberof PartnerResource
     */
    newKpi?: PartnerNewKpiResource;
    /**
     *
     * @type {PartnerInvoicingResource}
     * @memberof PartnerResource
     */
    invoicing?: PartnerInvoicingResource;
    /**
     *
     * @type {PartnerPreferencesResource}
     * @memberof PartnerResource
     */
    preferences?: PartnerPreferencesResource;
    /**
     *
     * @type {PartnerDemoResource}
     * @memberof PartnerResource
     */
    demo?: PartnerDemoResource;
    /**
     *
     * @type {PartnerCanvasResource}
     * @memberof PartnerResource
     */
    canvas?: PartnerCanvasResource;
    /**
     *
     * @type {PartnerTasksResource}
     * @memberof PartnerResource
     */
    tasks?: PartnerTasksResource;
    /**
     * Partner subscription data
     * @type {Array<PartnerSubscriptionResource>}
     * @memberof PartnerResource
     */
    subscription?: Array<PartnerSubscriptionResource>;
    /**
     *
     * @type {PaymentSolutionResource}
     * @memberof PartnerResource
     */
    preferredPaymentSolution?: PaymentSolutionResource;
    /**
     * Partner payment schedule
     * @type {string}
     * @memberof PartnerResource
     */
    paymentSchedule?: PartnerResource.PaymentScheduleEnum;
    /**
     * Attributes for the partner.
     * @type {Array<PartnerAttributeResource>}
     * @memberof PartnerResource
     */
    attributes?: Array<PartnerAttributeResource>;
    /**
     *
     * @type {PartnerRevenueRangeResource}
     * @memberof PartnerResource
     */
    desiredRevenueRange?: PartnerRevenueRangeResource;
    /**
     *
     * @type {PartnerImpressumResource}
     * @memberof PartnerResource
     */
    impressum?: PartnerImpressumResource;
    /**
     * Flag whether partner pays for matches
     * @type {boolean}
     * @memberof PartnerResource
     */
    isPayToMatch?: boolean;
    /**
     * Flag whether partner pays for quotes
     * @type {boolean}
     * @memberof PartnerResource
     */
    isPayToQuote?: boolean;
    /**
     * True, if this partner has a marketing package.
     * @type {boolean}
     * @memberof PartnerResource
     */
    hasMarketingPackage?: boolean;
    /**
     * Number of kilometres a partner is willing to travel for leads.
     * @type {number}
     * @memberof PartnerResource
     */
    geoRange?: number;
    /**
     * Badges for the partner.
     * @type {Array<PartnerBadgeResource>}
     * @memberof PartnerResource
     */
    badges?: Array<PartnerBadgeResource>;
    /**
     *
     * @type {PartnerCategoryResource}
     * @memberof PartnerResource
     */
    category?: PartnerCategoryResource;
    /**
     * Partner Category Features.
     * @type {Array<string>}
     * @memberof PartnerResource
     */
    features?: Array<string>;
    /**
     *
     * @type {PartnerAssociationResource}
     * @memberof PartnerResource
     */
    association?: PartnerAssociationResource;
    /**
     * True if partner has access to the Partner portal.
     * @type {boolean}
     * @memberof PartnerResource
     */
    hasPortalAccess?: boolean;
    /**
     *
     * @type {CompanySizeResource}
     * @memberof PartnerResource
     */
    companySize?: CompanySizeResource;
    /**
     * Hide from public website
     * @type {boolean}
     * @memberof PartnerResource
     */
    hideFromWebsite?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof PartnerResource
     */
    wantsOverdueInvoiceReminders?: boolean;
    /**
     * enable/disable autoquoting for partner
     * @type {boolean}
     * @memberof PartnerResource
     */
    autoQuotingEnabled?: boolean;
    /**
     * Recent number of purchases on marketplace light
     * @type {number}
     * @memberof PartnerResource
     */
    leadMarketplaceRecentPurchaseCount?: number;
    /**
     * Maximum number of purchases on marketplace light
     * @type {number}
     * @memberof PartnerResource
     */
    leadMarketplaceRecentPurchaseCountMax?: number;
    /**
     *
     * @type {PartnerSignUpResource}
     * @memberof PartnerResource
     */
    partnerSignUp?: PartnerSignUpResource;
    /**
     *
     * @type {PartnerMarketingConsentResource}
     * @memberof PartnerResource
     */
    marketingConsent?: PartnerMarketingConsentResource;
    /**
     *
     * @type {PartnerMarketplaceResource}
     * @memberof PartnerResource
     */
    marketplace?: PartnerMarketplaceResource;
}

/**
 * @export
 * @namespace PartnerResource
 */
export namespace PartnerResource {
    /**
     * @export
     * @enum {string}
     */
    export enum StateEnum {
        Unknown = <any> 'unknown',
        Canvas = <any> 'canvas',
        Demo = <any> 'demo',
        Inactive = <any> 'inactive',
        ExPartner = <any> 'ex_partner',
        Active = <any> 'active',
        Closed = <any> 'closed',
        BusinessPartner = <any> 'business_partner'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum PaymentScheduleEnum {
        WithSubscriptionPeriod = <any> 'with_subscription_period',
        Immediate = <any> 'immediate'
    }
}

/**
 *
 * @export
 * @interface PartnerResult
 */
export interface PartnerResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerResource>}
     * @memberof PartnerResult
     */
    data?: Array<PartnerResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerRevenueRangeResource
 */
export interface PartnerRevenueRangeResource {
    /**
     * ID.
     * @type {number}
     * @memberof PartnerRevenueRangeResource
     */
    id?: number;
    /**
     * Minimum revenue.
     * @type {number}
     * @memberof PartnerRevenueRangeResource
     */
    minimumRevenue?: number;
    /**
     * Maximum revenue.
     * @type {number}
     * @memberof PartnerRevenueRangeResource
     */
    maximumRevenue?: number;
    /**
     * Currency for the min and max revenues.
     * @type {string}
     * @memberof PartnerRevenueRangeResource
     */
    revenueCurrency?: string;
}

/**
 *
 * @export
 * @interface PartnerRevenueSegmentActionResource
 */
export interface PartnerRevenueSegmentActionResource {
    /**
     *
     * @type {string}
     * @memberof PartnerRevenueSegmentActionResource
     */
    action?: PartnerRevenueSegmentActionResource.ActionEnum;
    /**
     *
     * @type {number}
     * @memberof PartnerRevenueSegmentActionResource
     */
    revenueSegmentId?: number;
    /**
     *
     * @type {Array<number>}
     * @memberof PartnerRevenueSegmentActionResource
     */
    leadTypeIds?: Array<number>;
    /**
     *
     * @type {boolean}
     * @memberof PartnerRevenueSegmentActionResource
     */
    forceCopy?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof PartnerRevenueSegmentActionResource
     */
    withPrices?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof PartnerRevenueSegmentActionResource
     */
    copyType?: boolean;
}

/**
 * @export
 * @namespace PartnerRevenueSegmentActionResource
 */
export namespace PartnerRevenueSegmentActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        Copy = <any> 'copy'
    }
}

/**
 *
 * @export
 * @interface PartnerRevenueSegmentResource
 */
export interface PartnerRevenueSegmentResource {
    /**
     * ID of the revenue segment.
     * @type {number}
     * @memberof PartnerRevenueSegmentResource
     */
    id?: number;
    /**
     *
     * @type {LeadTypeResource}
     * @memberof PartnerRevenueSegmentResource
     */
    leadType?: LeadTypeResource;
    /**
     *
     * @type {PartnerResource}
     * @memberof PartnerRevenueSegmentResource
     */
    partner?: PartnerResource;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerRevenueSegmentResource
     */
    maximumRevenue?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerRevenueSegmentResource
     */
    minimumRevenue?: AmountResource;
    /**
     *
     * @type {Array<SectorResource>}
     * @memberof PartnerRevenueSegmentResource
     */
    sectors?: Array<SectorResource>;
}

/**
 *
 * @export
 * @interface PartnerRevenueSegmentResult
 */
export interface PartnerRevenueSegmentResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerRevenueSegmentResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerRevenueSegmentResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerRevenueSegmentResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerRevenueSegmentResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerRevenueSegmentResource>}
     * @memberof PartnerRevenueSegmentResult
     */
    data?: Array<PartnerRevenueSegmentResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerRevenueSegmentResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerSatisfactionResource
 */
export interface PartnerSatisfactionResource {
    /**
     *
     * @type {number}
     * @memberof PartnerSatisfactionResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof PartnerSatisfactionResource
     */
    createdAt?: string;
    /**
     *
     * @type {number}
     * @memberof PartnerSatisfactionResource
     */
    satisfactionLevelId?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerSatisfactionResource
     */
    score?: number;
    /**
     *
     * @type {string}
     * @memberof PartnerSatisfactionResource
     */
    label?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerSatisfactionResource
     */
    identifier?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerSatisfactionResource
     */
    note?: string;
}

/**
 *
 * @export
 * @interface PartnerSectorsResource
 */
export interface PartnerSectorsResource {
    /**
     * Sector IDs.
     * @type {Array<number>}
     * @memberof PartnerSectorsResource
     */
    sectorIds?: Array<number>;
}

/**
 *
 * @export
 * @interface PartnerSignUpActionResource
 */
export interface PartnerSignUpActionResource {
    /**
     *
     * @type {string}
     * @memberof PartnerSignUpActionResource
     */
    action?: PartnerSignUpActionResource.ActionEnum;
    /**
     *
     * @type {string}
     * @memberof PartnerSignUpActionResource
     */
    companyName?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerSignUpActionResource
     */
    password?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerSignUpActionResource
     */
    zipCode?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerSignUpActionResource
     */
    phoneNumber?: string;
}

/**
 * @export
 * @namespace PartnerSignUpActionResource
 */
export namespace PartnerSignUpActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        ConvertToPartner = <any> 'convert_to_partner'
    }
}

/**
 *
 * @export
 * @interface PartnerSignUpResource
 */
export interface PartnerSignUpResource {
    /**
     *
     * @type {string}
     * @memberof PartnerSignUpResource
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerSignUpResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerSignUpResource
     */
    geoCode?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerSignUpResource
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerSignUpResource
     */
    signupToken?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerSignUpResource
     */
    type?: PartnerSignUpResource.TypeEnum;
    /**
     *
     * @type {string}
     * @memberof PartnerSignUpResource
     */
    firstName?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerSignUpResource
     */
    lastName?: string;
    /**
     *
     * @type {number}
     * @memberof PartnerSignUpResource
     */
    industryId?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerSignUpResource
     */
    partnerId?: number;
    /**
     *
     * @type {string}
     * @memberof PartnerSignUpResource
     */
    expiresAt?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerSignUpResource
     */
    processedAt?: string;
}

/**
 * @export
 * @namespace PartnerSignUpResource
 */
export namespace PartnerSignUpResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Basic = <any> 'basic',
        Plus = <any> 'plus'
    }
}

/**
 *
 * @export
 * @interface PartnerSignUpResult
 */
export interface PartnerSignUpResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerSignUpResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerSignUpResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerSignUpResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerSignUpResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerSignUpResource>}
     * @memberof PartnerSignUpResult
     */
    data?: Array<PartnerSignUpResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerSignUpResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerSubscriptionActionResource
 */
export interface PartnerSubscriptionActionResource {
    /**
     *
     * @type {string}
     * @memberof PartnerSubscriptionActionResource
     */
    cancelAt?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerSubscriptionActionResource
     */
    action?: PartnerSubscriptionActionResource.ActionEnum;
}

/**
 * @export
 * @namespace PartnerSubscriptionActionResource
 */
export namespace PartnerSubscriptionActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        Cancel = <any> 'cancel',
        CancelWithCancellationPeriod = <any> 'cancel_with_cancellation_period'
    }
}

/**
 *
 * @export
 * @interface PartnerSubscriptionAgreementActionResource
 */
export interface PartnerSubscriptionAgreementActionResource {
    /**
     * Partner package agreement action.
     * @type {string}
     * @memberof PartnerSubscriptionAgreementActionResource
     */
    action?: PartnerSubscriptionAgreementActionResource.ActionEnum;
    /**
     * Partner user who is accepting the agreement.
     * @type {number}
     * @memberof PartnerSubscriptionAgreementActionResource
     */
    acceptingPartnerUserId?: number;
}

/**
 * @export
 * @namespace PartnerSubscriptionAgreementActionResource
 */
export namespace PartnerSubscriptionAgreementActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        Unknown = <any> 'unknown',
        AcceptAgreement = <any> 'accept_agreement'
    }
}

/**
 *
 * @export
 * @interface PartnerSubscriptionAgreementResource
 */
export interface PartnerSubscriptionAgreementResource {
    /**
     * Primary ID.
     * @type {number}
     * @memberof PartnerSubscriptionAgreementResource
     */
    id?: number;
    /**
     * Employee who created the agreement.
     * @type {number}
     * @memberof PartnerSubscriptionAgreementResource
     */
    createdByEmployeeId?: number;
    /**
     * Subscription type this agreement is for.
     * @type {number}
     * @memberof PartnerSubscriptionAgreementResource
     */
    subscriptionTypeId?: number;
    /**
     * Additional terms this agreement includes.
     * @type {number}
     * @memberof PartnerSubscriptionAgreementResource
     */
    subjectToTermsId?: number;
    /**
     * Is this agreement available for agreeing?
     * @type {boolean}
     * @memberof PartnerSubscriptionAgreementResource
     */
    isAvailableForAgreement?: boolean;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerSubscriptionAgreementResource
     */
    monthlyPrice?: AmountResource;
    /**
     * Free form text describing the agreement.
     * @type {string}
     * @memberof PartnerSubscriptionAgreementResource
     */
    agreementTerms?: string;
    /**
     * When the agreement was accepted.
     * @type {string}
     * @memberof PartnerSubscriptionAgreementResource
     */
    acceptedAt?: string;
    /**
     * Partner user who accepted the agreement.
     * @type {number}
     * @memberof PartnerSubscriptionAgreementResource
     */
    acceptedByPartnerUserId?: number;
    /**
     * Date agreement was created.
     * @type {string}
     * @memberof PartnerSubscriptionAgreementResource
     */
    createdAt?: string;
}

/**
 *
 * @export
 * @interface PartnerSubscriptionAgreementResult
 */
export interface PartnerSubscriptionAgreementResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerSubscriptionAgreementResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerSubscriptionAgreementResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerSubscriptionAgreementResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerSubscriptionAgreementResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerSubscriptionAgreementResource>}
     * @memberof PartnerSubscriptionAgreementResult
     */
    data?: Array<PartnerSubscriptionAgreementResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerSubscriptionAgreementResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerSubscriptionFeatureResource
 */
export interface PartnerSubscriptionFeatureResource {
    /**
     * ID.
     * @type {number}
     * @memberof PartnerSubscriptionFeatureResource
     */
    id?: number;
    /**
     * Identifier.
     * @type {string}
     * @memberof PartnerSubscriptionFeatureResource
     */
    identifier?: string;
    /**
     * Is hidden.
     * @type {boolean}
     * @memberof PartnerSubscriptionFeatureResource
     */
    isHidden?: boolean;
}

/**
 *
 * @export
 * @interface PartnerSubscriptionPauseResource
 */
export interface PartnerSubscriptionPauseResource {
    /**
     * ID of the pause.
     * @type {number}
     * @memberof PartnerSubscriptionPauseResource
     */
    id?: number;
    /**
     * Subscription ID this pause is for.
     * @type {number}
     * @memberof PartnerSubscriptionPauseResource
     */
    subscriptionId?: number;
    /**
     * Start date of the pause.
     * @type {string}
     * @memberof PartnerSubscriptionPauseResource
     */
    startsAt?: string;
    /**
     * End date of the pause.
     * @type {string}
     * @memberof PartnerSubscriptionPauseResource
     */
    endsAt?: string;
}

/**
 *
 * @export
 * @interface PartnerSubscriptionPauseResult
 */
export interface PartnerSubscriptionPauseResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerSubscriptionPauseResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerSubscriptionPauseResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerSubscriptionPauseResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerSubscriptionPauseResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerSubscriptionPauseResource>}
     * @memberof PartnerSubscriptionPauseResult
     */
    data?: Array<PartnerSubscriptionPauseResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerSubscriptionPauseResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerSubscriptionPriceResource
 */
export interface PartnerSubscriptionPriceResource {
    /**
     * Subscription id.
     * @type {number}
     * @memberof PartnerSubscriptionPriceResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof PartnerSubscriptionPriceResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerSubscriptionPriceResource
     */
    updatedAt?: string;
    /**
     * The package subscribed to.
     * @type {number}
     * @memberof PartnerSubscriptionPriceResource
     */
    partnerPackageId?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerSubscriptionPriceResource
     */
    monthlyPrice?: AmountResource;
    /**
     * Date from when this price is in effect
     * @type {string}
     * @memberof PartnerSubscriptionPriceResource
     */
    effectiveAt?: string;
}

/**
 *
 * @export
 * @interface PartnerSubscriptionPriceResult
 */
export interface PartnerSubscriptionPriceResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerSubscriptionPriceResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerSubscriptionPriceResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerSubscriptionPriceResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerSubscriptionPriceResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerSubscriptionPriceResource>}
     * @memberof PartnerSubscriptionPriceResult
     */
    data?: Array<PartnerSubscriptionPriceResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerSubscriptionPriceResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerSubscriptionResource
 */
export interface PartnerSubscriptionResource {
    /**
     * Subscription id.
     * @type {number}
     * @memberof PartnerSubscriptionResource
     */
    id?: number;
    /**
     * The subscribed partner id.
     * @type {number}
     * @memberof PartnerSubscriptionResource
     */
    partnerId?: number;
    /**
     * The package subscribed to.
     * @type {number}
     * @memberof PartnerSubscriptionResource
     */
    packageId?: number;
    /**
     *
     * @type {PaymentSolutionResource}
     * @memberof PartnerSubscriptionResource
     */
    paymentSolution?: PaymentSolutionResource;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerSubscriptionResource
     */
    monthlyPrice?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerSubscriptionResource
     */
    currentMonthlyPrice?: AmountResource;
    /**
     * When the package should start.
     * @type {string}
     * @memberof PartnerSubscriptionResource
     */
    startsAt?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerSubscriptionResource
     */
    expiresAt?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerSubscriptionResource
     */
    canceledAt?: string;
    /**
     * Package created at time stamp.
     * @type {string}
     * @memberof PartnerSubscriptionResource
     */
    createdAt?: string;
    /**
     * Package update at time stamp.
     * @type {string}
     * @memberof PartnerSubscriptionResource
     */
    updatedAt?: string;
    /**
     * Package type
     * @type {string}
     * @memberof PartnerSubscriptionResource
     */
    packageType?: string;
    /**
     * When the package churned.
     * @type {string}
     * @memberof PartnerSubscriptionResource
     */
    churnedAt?: string;
    /**
     * Length of each extension when this package is extended.
     * @type {number}
     * @memberof PartnerSubscriptionResource
     */
    extensionPeriodMonths?: number;
}

/**
 *
 * @export
 * @interface PartnerSubscriptionResult
 */
export interface PartnerSubscriptionResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerSubscriptionResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerSubscriptionResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerSubscriptionResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerSubscriptionResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerSubscriptionResource>}
     * @memberof PartnerSubscriptionResult
     */
    data?: Array<PartnerSubscriptionResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerSubscriptionResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerSubscriptionTypeResource
 */
export interface PartnerSubscriptionTypeResource {
    /**
     * ID.
     * @type {number}
     * @memberof PartnerSubscriptionTypeResource
     */
    id?: number;
    /**
     * Identifier.
     * @type {string}
     * @memberof PartnerSubscriptionTypeResource
     */
    identifier?: string;
    /**
     * Name.
     * @type {string}
     * @memberof PartnerSubscriptionTypeResource
     */
    name?: string;
    /**
     * Geo code.
     * @type {string}
     * @memberof PartnerSubscriptionTypeResource
     */
    geoCode?: string;
    /**
     *
     * @type {AmountResource}
     * @memberof PartnerSubscriptionTypeResource
     */
    monthlyPrice?: AmountResource;
    /**
     * Subscription features to be unlocked.
     * @type {Array<PartnerSubscriptionFeatureResource>}
     * @memberof PartnerSubscriptionTypeResource
     */
    features?: Array<PartnerSubscriptionFeatureResource>;
}

/**
 *
 * @export
 * @interface PartnerSubscriptionTypeResult
 */
export interface PartnerSubscriptionTypeResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerSubscriptionTypeResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerSubscriptionTypeResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerSubscriptionTypeResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerSubscriptionTypeResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerSubscriptionTypeResource>}
     * @memberof PartnerSubscriptionTypeResult
     */
    data?: Array<PartnerSubscriptionTypeResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerSubscriptionTypeResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerSuggestResource
 */
export interface PartnerSuggestResource {
    /**
     * Id of the suggested partner.
     * @type {number}
     * @memberof PartnerSuggestResource
     */
    id?: number;
    /**
     * Company name of the suggested partner.
     * @type {string}
     * @memberof PartnerSuggestResource
     */
    companyName?: string;
    /**
     * Slug to use for Uri
     * @type {string}
     * @memberof PartnerSuggestResource
     */
    slug?: string;
    /**
     * Partner State
     * @type {string}
     * @memberof PartnerSuggestResource
     */
    state?: PartnerSuggestResource.StateEnum;
    /**
     * Status of the suggested partner.
     * @type {string}
     * @memberof PartnerSuggestResource
     */
    status?: string;
    /**
     * Zip Code of the suggested partner.
     * @type {string}
     * @memberof PartnerSuggestResource
     */
    zipCode?: string;
    /**
     * City of the suggested partner.
     * @type {string}
     * @memberof PartnerSuggestResource
     */
    city?: string;
    /**
     * Geo Location of the suggested partner.
     * @type {string}
     * @memberof PartnerSuggestResource
     */
    geoCode?: string;
    /**
     * Partner State.
     * @type {string}
     * @memberof PartnerSuggestResource
     */
    partnerState?: string;
}

/**
 * @export
 * @namespace PartnerSuggestResource
 */
export namespace PartnerSuggestResource {
    /**
     * @export
     * @enum {string}
     */
    export enum StateEnum {
        Unknown = <any> 'unknown',
        Canvas = <any> 'canvas',
        Demo = <any> 'demo',
        Inactive = <any> 'inactive',
        ExPartner = <any> 'ex_partner',
        Active = <any> 'active',
        Closed = <any> 'closed',
        BusinessPartner = <any> 'business_partner'
    }
}

/**
 *
 * @export
 * @interface PartnerSuggestResult
 */
export interface PartnerSuggestResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerSuggestResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerSuggestResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerSuggestResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerSuggestResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerSuggestResource>}
     * @memberof PartnerSuggestResult
     */
    data?: Array<PartnerSuggestResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerSuggestResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerTasksResource
 */
export interface PartnerTasksResource {
    /**
     * count
     * @type {number}
     * @memberof PartnerTasksResource
     */
    count?: number;
    /**
     *
     * @type {TaskResource}
     * @memberof PartnerTasksResource
     */
    next?: TaskResource;
}

/**
 *
 * @export
 * @interface PartnerTitleResource
 */
export interface PartnerTitleResource {
    /**
     * Title ID.
     * @type {number}
     * @memberof PartnerTitleResource
     */
    id?: number;
    /**
     * Title identifier.
     * @type {string}
     * @memberof PartnerTitleResource
     */
    identifier?: string;
    /**
     * Title name.
     * @type {string}
     * @memberof PartnerTitleResource
     */
    name?: string;
    /**
     *
     * @type {PartnerIndustryResource}
     * @memberof PartnerTitleResource
     */
    industry?: PartnerIndustryResource;
    /**
     *
     * @type {string}
     * @memberof PartnerTitleResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerTitleResource
     */
    updatedAt?: string;
}

/**
 *
 * @export
 * @interface PartnerTitleResult
 */
export interface PartnerTitleResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerTitleResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerTitleResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerTitleResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerTitleResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerTitleResource>}
     * @memberof PartnerTitleResult
     */
    data?: Array<PartnerTitleResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerTitleResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerTypesResource
 */
export interface PartnerTypesResource {
    /**
     * Array with <PartnerTypeResource>s. @var array
     * @type {string}
     * @memberof PartnerTypesResource
     */
    types?: string;
}

/**
 *
 * @export
 * @interface PartnerTypesResult
 */
export interface PartnerTypesResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerTypesResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerTypesResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerTypesResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerTypesResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerTypesResource>}
     * @memberof PartnerTypesResult
     */
    data?: Array<PartnerTypesResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerTypesResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerUniqueSellingPointResource
 */
export interface PartnerUniqueSellingPointResource {
    /**
     *
     * @type {number}
     * @memberof PartnerUniqueSellingPointResource
     */
    id?: number;
    /**
     *
     * @type {number}
     * @memberof PartnerUniqueSellingPointResource
     */
    partnerId?: number;
    /**
     *
     * @type {string}
     * @memberof PartnerUniqueSellingPointResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerUniqueSellingPointResource
     */
    text?: string;
}

/**
 *
 * @export
 * @interface PartnerUniqueSellingPointResult
 */
export interface PartnerUniqueSellingPointResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerUniqueSellingPointResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerUniqueSellingPointResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerUniqueSellingPointResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerUniqueSellingPointResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerUniqueSellingPointResource>}
     * @memberof PartnerUniqueSellingPointResult
     */
    data?: Array<PartnerUniqueSellingPointResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerUniqueSellingPointResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerUserActionResource
 */
export interface PartnerUserActionResource {
    /**
     *
     * @type {string}
     * @memberof PartnerUserActionResource
     */
    action?: PartnerUserActionResource.ActionEnum;
}

/**
 * @export
 * @namespace PartnerUserActionResource
 */
export namespace PartnerUserActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        Unknown = <any> 'unknown',
        GrantMarketingConsent = <any> 'grant_marketing_consent'
    }
}

/**
 *
 * @export
 * @interface PartnerUserCvAwardResource
 */
export interface PartnerUserCvAwardResource {
    /**
     * ID.
     * @type {number}
     * @memberof PartnerUserCvAwardResource
     */
    id?: number;
    /**
     * Award name.
     * @type {string}
     * @memberof PartnerUserCvAwardResource
     */
    awardName?: string;
    /**
     * Award issuer.
     * @type {string}
     * @memberof PartnerUserCvAwardResource
     */
    issuer?: string;
    /**
     * Award description.
     * @type {string}
     * @memberof PartnerUserCvAwardResource
     */
    description?: string;
    /**
     * Award type.
     * @type {string}
     * @memberof PartnerUserCvAwardResource
     */
    awardType?: string;
    /**
     * Award date.
     * @type {string}
     * @memberof PartnerUserCvAwardResource
     */
    rewardDate?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerUserCvAwardResource
     */
    validation?: string;
}

/**
 *
 * @export
 * @interface PartnerUserCvBadgeResource
 */
export interface PartnerUserCvBadgeResource {
    /**
     * Certification ID.
     * @type {number}
     * @memberof PartnerUserCvBadgeResource
     */
    id?: number;
    /**
     * Certification name
     * @type {string}
     * @memberof PartnerUserCvBadgeResource
     */
    title?: string;
    /**
     * Certification description.
     * @type {string}
     * @memberof PartnerUserCvBadgeResource
     */
    description?: string;
}

/**
 *
 * @export
 * @interface PartnerUserCvCourseResource
 */
export interface PartnerUserCvCourseResource {
    /**
     * Partner user education ID.
     * @type {number}
     * @memberof PartnerUserCvCourseResource
     */
    id?: number;
    /**
     * Course name.
     * @type {string}
     * @memberof PartnerUserCvCourseResource
     */
    courseName?: string;
    /**
     * Facility name.
     * @type {string}
     * @memberof PartnerUserCvCourseResource
     */
    facilityName?: string;
    /**
     * Course description.
     * @type {string}
     * @memberof PartnerUserCvCourseResource
     */
    content?: string;
    /**
     * Course stared at time stamp.
     * @type {string}
     * @memberof PartnerUserCvCourseResource
     */
    fromDate?: string;
}

/**
 *
 * @export
 * @interface PartnerUserCvDescriptionResource
 */
export interface PartnerUserCvDescriptionResource {
    /**
     * Description ID.
     * @type {number}
     * @memberof PartnerUserCvDescriptionResource
     */
    id?: number;
    /**
     * Partner user description.
     * @type {string}
     * @memberof PartnerUserCvDescriptionResource
     */
    description?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerUserCvDescriptionResource
     */
    validation?: string;
}

/**
 *
 * @export
 * @interface PartnerUserCvEducationResource
 */
export interface PartnerUserCvEducationResource {
    /**
     * Partner user education ID.
     * @type {number}
     * @memberof PartnerUserCvEducationResource
     */
    id?: number;
    /**
     * Education name.
     * @type {string}
     * @memberof PartnerUserCvEducationResource
     */
    educationName?: string;
    /**
     * Facility name.
     * @type {string}
     * @memberof PartnerUserCvEducationResource
     */
    facilityName?: string;
    /**
     * Education description.
     * @type {string}
     * @memberof PartnerUserCvEducationResource
     */
    content?: string;
    /**
     * Education level:.
     * @type {number}
     * @memberof PartnerUserCvEducationResource
     */
    degreeLevel?: number;
    /**
     * Education stared at time stamp.
     * @type {string}
     * @memberof PartnerUserCvEducationResource
     */
    fromDate?: string;
    /**
     * Education end at time stamp.
     * @type {string}
     * @memberof PartnerUserCvEducationResource
     */
    toDate?: string;
}

/**
 *
 * @export
 * @interface PartnerUserCvExperienceResource
 */
export interface PartnerUserCvExperienceResource {
    /**
     * Experience ID.
     * @type {number}
     * @memberof PartnerUserCvExperienceResource
     */
    id?: number;
    /**
     * Job Title.
     * @type {string}
     * @memberof PartnerUserCvExperienceResource
     */
    title?: string;
    /**
     * Company Name.
     * @type {string}
     * @memberof PartnerUserCvExperienceResource
     */
    companyName?: string;
    /**
     * Job description.
     * @type {string}
     * @memberof PartnerUserCvExperienceResource
     */
    content?: string;
    /**
     * Job start at time stamp.
     * @type {string}
     * @memberof PartnerUserCvExperienceResource
     */
    fromDate?: string;
    /**
     * Job ended at time stamp.
     * @type {string}
     * @memberof PartnerUserCvExperienceResource
     */
    toDate?: string;
    /**
     *
     * @type {PartnerUserCvPartnerResource}
     * @memberof PartnerUserCvExperienceResource
     */
    partner?: PartnerUserCvPartnerResource;
    /**
     *
     * @type {string}
     * @memberof PartnerUserCvExperienceResource
     */
    validation?: string;
}

/**
 *
 * @export
 * @interface PartnerUserCvExpertiseResource
 */
export interface PartnerUserCvExpertiseResource {
    /**
     * Partner user experience ID.
     * @type {number}
     * @memberof PartnerUserCvExpertiseResource
     */
    id?: number;
    /**
     * Partner user expertise type.
     * @type {string}
     * @memberof PartnerUserCvExpertiseResource
     */
    expertiseType?: string;
    /**
     * Partner user expertise name.
     * @type {string}
     * @memberof PartnerUserCvExpertiseResource
     */
    expertiseName?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerUserCvExpertiseResource
     */
    validation?: string;
}

/**
 *
 * @export
 * @interface PartnerUserCvLanguageResource
 */
export interface PartnerUserCvLanguageResource {
    /**
     * Partner user language record ID.
     * @type {number}
     * @memberof PartnerUserCvLanguageResource
     */
    id?: number;
    /**
     * Language identifier.
     * @type {string}
     * @memberof PartnerUserCvLanguageResource
     */
    identifier?: string;
    /**
     * Language fluency.
     * @type {string}
     * @memberof PartnerUserCvLanguageResource
     */
    proficiency?: string;
    /**
     * Name of the lagnuage, e.g. 'Australian English'.
     * @type {string}
     * @memberof PartnerUserCvLanguageResource
     */
    name?: string;
    /**
     * Original name of the lagnuage'.
     * @type {string}
     * @memberof PartnerUserCvLanguageResource
     */
    originalName?: string;
}

/**
 *
 * @export
 * @interface PartnerUserCvPartnerResource
 */
export interface PartnerUserCvPartnerResource {
    /**
     * The partner's id.
     * @type {number}
     * @memberof PartnerUserCvPartnerResource
     */
    id?: number;
    /**
     * The company name of the partner.
     * @type {string}
     * @memberof PartnerUserCvPartnerResource
     */
    companyName?: string;
    /**
     * Information regarding the partner company.
     * @type {string}
     * @memberof PartnerUserCvPartnerResource
     */
    companyInformation?: string;
}

/**
 *
 * @export
 * @interface PartnerUserCvResource
 */
export interface PartnerUserCvResource {
    /**
     * Partner use ID.
     * @type {number}
     * @memberof PartnerUserCvResource
     */
    id?: number;
    /**
     * Partner ID.
     * @type {number}
     * @memberof PartnerUserCvResource
     */
    partnerId?: number;
    /**
     *
     * @type {PartnerUserCvDescriptionResource}
     * @memberof PartnerUserCvResource
     */
    description?: PartnerUserCvDescriptionResource;
    /**
     * Partner user experience.
     * @type {Array<PartnerUserCvExperienceResource>}
     * @memberof PartnerUserCvResource
     */
    experiences?: Array<PartnerUserCvExperienceResource>;
    /**
     * Partner user education.
     * @type {Array<PartnerUserCvEducationResource>}
     * @memberof PartnerUserCvResource
     */
    educations?: Array<PartnerUserCvEducationResource>;
    /**
     * Partner user courses.
     * @type {Array<PartnerUserCvCourseResource>}
     * @memberof PartnerUserCvResource
     */
    courses?: Array<PartnerUserCvCourseResource>;
    /**
     * Partner user languages.
     * @type {Array<PartnerUserCvLanguageResource>}
     * @memberof PartnerUserCvResource
     */
    languages?: Array<PartnerUserCvLanguageResource>;
    /**
     * Partner user expertises.
     * @type {Array<PartnerUserCvExpertiseResource>}
     * @memberof PartnerUserCvResource
     */
    expertises?: Array<PartnerUserCvExpertiseResource>;
    /**
     * Partner user expertises.
     * @type {Array<PartnerUserCvAwardResource>}
     * @memberof PartnerUserCvResource
     */
    awards?: Array<PartnerUserCvAwardResource>;
    /**
     * Partner user personal badges.
     * @type {Array<PartnerUserCvBadgeResource>}
     * @memberof PartnerUserCvResource
     */
    badges?: Array<PartnerUserCvBadgeResource>;
    /**
     *
     * @type {PartnerUserCvSocialMediaResource}
     * @memberof PartnerUserCvResource
     */
    socialMedia?: PartnerUserCvSocialMediaResource;
}

/**
 *
 * @export
 * @interface PartnerUserCvResult
 */
export interface PartnerUserCvResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerUserCvResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerUserCvResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerUserCvResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerUserCvResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerUserCvResource>}
     * @memberof PartnerUserCvResult
     */
    data?: Array<PartnerUserCvResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerUserCvResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerUserCvSocialMediaResource
 */
export interface PartnerUserCvSocialMediaResource {
    /**
     * Partner User social media ID.
     * @type {number}
     * @memberof PartnerUserCvSocialMediaResource
     */
    id?: number;
    /**
     * Partner user facebook link.
     * @type {string}
     * @memberof PartnerUserCvSocialMediaResource
     */
    facebook?: string;
    /**
     * Partner user linkedin link.
     * @type {string}
     * @memberof PartnerUserCvSocialMediaResource
     */
    linkedin?: string;
    /**
     * Partner user twitter link.
     * @type {string}
     * @memberof PartnerUserCvSocialMediaResource
     */
    twitter?: string;
    /**
     * Partner user website link.
     * @type {string}
     * @memberof PartnerUserCvSocialMediaResource
     */
    website?: string;
    /**
     *
     * @type {string}
     * @memberof PartnerUserCvSocialMediaResource
     */
    validation?: string;
}

/**
 *
 * @export
 * @interface PartnerUserResource
 */
export interface PartnerUserResource {
    /**
     * User Id.
     * @type {number}
     * @memberof PartnerUserResource
     */
    id?: number;
    /**
     * Id to the given partner.
     * @type {number}
     * @memberof PartnerUserResource
     */
    partnerId?: number;
    /**
     * Email to the given partner user.
     * @type {string}
     * @memberof PartnerUserResource
     */
    email?: string;
    /**
     * Password.
     * @type {string}
     * @memberof PartnerUserResource
     */
    password?: string;
    /**
     * Full name of the partner user.
     * @type {string}
     * @memberof PartnerUserResource
     */
    name?: string;
    /**
     * first name.
     * @type {string}
     * @memberof PartnerUserResource
     */
    firstName?: string;
    /**
     * last name.
     * @type {string}
     * @memberof PartnerUserResource
     */
    lastName?: string;
    /**
     * Phone Number.
     * @type {string}
     * @memberof PartnerUserResource
     */
    phone?: string;
    /**
     * Mobile Number.
     * @type {string}
     * @memberof PartnerUserResource
     */
    mobile?: string;
    /**
     * True / false if the current partner user is finance user.
     * @type {boolean}
     * @memberof PartnerUserResource
     */
    isFinanceUser?: boolean;
    /**
     * Last time user was logged in.
     * @type {string}
     * @memberof PartnerUserResource
     */
    lastLogin?: string;
    /**
     * Portrait of the partner user.
     * @type {string}
     * @memberof PartnerUserResource
     */
    profilePicture?: string;
    /**
     * Partner User is interested in emails.
     * @type {boolean}
     * @memberof PartnerUserResource
     */
    wantsEmails?: boolean;
    /**
     * Partner User is interested in text messsages.
     * @type {boolean}
     * @memberof PartnerUserResource
     */
    wantsTextMessages?: boolean;
    /**
     * Partner User is interested in daily emails.
     * @type {boolean}
     * @memberof PartnerUserResource
     */
    wantsDailyEmail?: boolean;
    /**
     * Partner User is visible.
     * @type {boolean}
     * @memberof PartnerUserResource
     */
    isVisible?: boolean;
    /**
     * Partner User is Active.
     * @type {boolean}
     * @memberof PartnerUserResource
     */
    isActive?: boolean;
    /**
     * The Type of Access the Partner User has.
     * @type {string}
     * @memberof PartnerUserResource
     */
    accessType?: PartnerUserResource.AccessTypeEnum;
    /**
     * partner User has access to refunds.
     * @type {boolean}
     * @memberof PartnerUserResource
     */
    hasRefundPermission?: boolean;
    /**
     * Last time the given partner user was updated.
     * @type {string}
     * @memberof PartnerUserResource
     */
    updatedAt?: string;
    /**
     * The date the partner user was created.
     * @type {string}
     * @memberof PartnerUserResource
     */
    createdAt?: string;
    /**
     * Partner user CV Resoruce.
     * @type {string}
     * @memberof PartnerUserResource
     */
    cv?: string;
}

/**
 * @export
 * @namespace PartnerUserResource
 */
export namespace PartnerUserResource {
    /**
     * @export
     * @enum {string}
     */
    export enum AccessTypeEnum {
        Standard = <any> 'standard',
        Master = <any> 'master'
    }
}

/**
 *
 * @export
 * @interface PartnerUserResult
 */
export interface PartnerUserResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerUserResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerUserResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerUserResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerUserResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerUserResource>}
     * @memberof PartnerUserResult
     */
    data?: Array<PartnerUserResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerUserResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PartnerVerificationEntryResource
 */
export interface PartnerVerificationEntryResource {
    /**
     * Identifier.
     * @type {string}
     * @memberof PartnerVerificationEntryResource
     */
    identifier?: string;
    /**
     * @var
     * @type {string}
     * @memberof PartnerVerificationEntryResource
     */
    type?: string;
    /**
     * @var
     * @type {boolean}
     * @memberof PartnerVerificationEntryResource
     */
    blocking?: boolean;
    /**
     * @var
     * @type {string}
     * @memberof PartnerVerificationEntryResource
     */
    requestedBy?: string;
    /**
     * @var
     * @type {string}
     * @memberof PartnerVerificationEntryResource
     */
    value?: string;
    /**
     * @var
     * @type {string}
     * @memberof PartnerVerificationEntryResource
     */
    metadata?: string;
}

/**
 *
 * @export
 * @interface PartnerVerificationResource
 */
export interface PartnerVerificationResource {
    /**
     * @var
     * @type {string}
     * @memberof PartnerVerificationResource
     */
    id?: string;
    /**
     * @var
     * @type {number}
     * @memberof PartnerVerificationResource
     */
    partnerId?: number;
    /**
     * @var
     * @type {Array<PartnerVerificationEntryResource>}
     * @memberof PartnerVerificationResource
     */
    entries?: Array<PartnerVerificationEntryResource>;
}

/**
 *
 * @export
 * @interface PartnerVerificationResult
 */
export interface PartnerVerificationResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PartnerVerificationResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PartnerVerificationResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PartnerVerificationResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PartnerVerificationResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PartnerVerificationResource>}
     * @memberof PartnerVerificationResult
     */
    data?: Array<PartnerVerificationResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PartnerVerificationResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PasswordReminderResource
 */
export interface PasswordReminderResource {
    /**
     *
     * @type {string}
     * @memberof PasswordReminderResource
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof PasswordReminderResource
     */
    consumerType?: string;
}

/**
 *
 * @export
 * @interface PasswordResetResource
 */
export interface PasswordResetResource {
    /**
     * email for the current consumer.
     * @type {string}
     * @memberof PasswordResetResource
     */
    email?: string;
    /**
     * type of consumer type.
     * @type {string}
     * @memberof PasswordResetResource
     */
    consumerType?: string;
    /**
     * new password.
     * @type {string}
     * @memberof PasswordResetResource
     */
    password?: string;
    /**
     * Current Password.
     * @type {string}
     * @memberof PasswordResetResource
     */
    currentPassword?: string;
    /**
     * Reset Token.
     * @type {string}
     * @memberof PasswordResetResource
     */
    resetToken?: string;
}

/**
 *
 * @export
 * @interface PaymentCardDataResource
 */
export interface PaymentCardDataResource {
    /**
     *
     * @type {number}
     * @memberof PaymentCardDataResource
     */
    txnid?: number;
    /**
     *
     * @type {string}
     * @memberof PaymentCardDataResource
     */
    orderid?: string;
    /**
     *
     * @type {number}
     * @memberof PaymentCardDataResource
     */
    amount?: number;
    /**
     *
     * @type {number}
     * @memberof PaymentCardDataResource
     */
    currency?: number;
    /**
     *
     * @type {number}
     * @memberof PaymentCardDataResource
     */
    date?: number;
    /**
     *
     * @type {string}
     * @memberof PaymentCardDataResource
     */
    time?: string;
    /**
     *
     * @type {number}
     * @memberof PaymentCardDataResource
     */
    txnfee?: number;
    /**
     *
     * @type {number}
     * @memberof PaymentCardDataResource
     */
    subscriptionid?: number;
    /**
     *
     * @type {number}
     * @memberof PaymentCardDataResource
     */
    paymenttype?: number;
    /**
     *
     * @type {string}
     * @memberof PaymentCardDataResource
     */
    cardno?: string;
    /**
     *
     * @type {string}
     * @memberof PaymentCardDataResource
     */
    hash?: string;
    /**
     *
     * @type {string}
     * @memberof PaymentCardDataResource
     */
    token?: string;
}

/**
 *
 * @export
 * @interface PaymentCardExpirationResource
 */
export interface PaymentCardExpirationResource {
    /**
     *
     * @type {number}
     * @memberof PaymentCardExpirationResource
     */
    month?: number;
    /**
     *
     * @type {number}
     * @memberof PaymentCardExpirationResource
     */
    year?: number;
}

/**
 *
 * @export
 * @interface PaymentCardOwnerResource
 */
export interface PaymentCardOwnerResource {
    /**
     *
     * @type {string}
     * @memberof PaymentCardOwnerResource
     */
    type?: PaymentCardOwnerResource.TypeEnum;
    /**
     *
     * @type {CardClientResource}
     * @memberof PaymentCardOwnerResource
     */
    client?: CardClientResource;
    /**
     *
     * @type {string}
     * @memberof PaymentCardOwnerResource
     */
    partnerUser?: string;
}

/**
 * @export
 * @namespace PaymentCardOwnerResource
 */
export namespace PaymentCardOwnerResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Unknown = <any> 'unknown',
        Partner = <any> 'partner',
        Client = <any> 'client'
    }
}

/**
 *
 * @export
 * @interface PaymentCardResource
 */
export interface PaymentCardResource {
    /**
     * User Id.
     * @type {number}
     * @memberof PaymentCardResource
     */
    id?: number;
    /**
     *
     * @type {PaymentCardOwnerResource}
     * @memberof PaymentCardResource
     */
    owner?: PaymentCardOwnerResource;
    /**
     *
     * @type {PaymentProviderResource}
     * @memberof PaymentCardResource
     */
    provider?: PaymentProviderResource;
    /**
     * Card name.
     * @type {string}
     * @memberof PaymentCardResource
     */
    cardName?: string;
    /**
     * Type of card
     * @type {string}
     * @memberof PaymentCardResource
     */
    cardType?: string;
    /**
     * Card mask.
     * @type {string}
     * @memberof PaymentCardResource
     */
    cardMask?: string;
    /**
     *
     * @type {PaymentCardExpirationResource}
     * @memberof PaymentCardResource
     */
    expiration?: PaymentCardExpirationResource;
    /**
     * Is payment card is being used in any package.
     * @type {boolean}
     * @memberof PaymentCardResource
     */
    isBeingUsed?: boolean;
    /**
     * If this payment card is the preferred payment method.
     * @type {boolean}
     * @memberof PaymentCardResource
     */
    isPreferred?: boolean;
    /**
     *
     * @type {NestedPaymentMethodResource}
     * @memberof PaymentCardResource
     */
    paymentMethod?: NestedPaymentMethodResource;
    /**
     *
     * @type {PaymentCardDataResource}
     * @memberof PaymentCardResource
     */
    data?: PaymentCardDataResource;
}

/**
 *
 * @export
 * @interface PaymentCardResult
 */
export interface PaymentCardResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PaymentCardResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PaymentCardResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PaymentCardResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PaymentCardResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PaymentCardResource>}
     * @memberof PaymentCardResult
     */
    data?: Array<PaymentCardResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PaymentCardResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PaymentMethodActionResource
 */
export interface PaymentMethodActionResource {
    /**
     * Payment method action
     * @type {string}
     * @memberof PaymentMethodActionResource
     */
    action?: PaymentMethodActionResource.ActionEnum;
}

/**
 * @export
 * @namespace PaymentMethodActionResource
 */
export namespace PaymentMethodActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        Unknown = <any> 'unknown',
        Prefer = <any> 'prefer',
        Unprefer = <any> 'unprefer'
    }
}

/**
 *
 * @export
 * @interface PaymentMethodResource
 */
export interface PaymentMethodResource {
    /**
     * Identifier of payment method.
     * @type {string}
     * @memberof PaymentMethodResource
     */
    name?: PaymentMethodResource.NameEnum;
    /**
     * Id of payment card.
     * @type {number}
     * @memberof PaymentMethodResource
     */
    paymentCardId?: number;
}

/**
 * @export
 * @namespace PaymentMethodResource
 */
export namespace PaymentMethodResource {
    /**
     * @export
     * @enum {string}
     */
    export enum NameEnum {
        Invoice = <any> 'invoice',
        Card = <any> 'card'
    }
}

/**
 *
 * @export
 * @interface PaymentProviderResource
 */
export interface PaymentProviderResource {
    /**
     *
     * @type {number}
     * @memberof PaymentProviderResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof PaymentProviderResource
     */
    identifier?: string;
    /**
     *
     * @type {string}
     * @memberof PaymentProviderResource
     */
    geoCode?: string;
}

/**
 *
 * @export
 * @interface PaymentSolutionResource
 */
export interface PaymentSolutionResource {
    /**
     * Identifier of payment solution.
     * @type {number}
     * @memberof PaymentSolutionResource
     */
    id?: number;
    /**
     * Name of payment solution.
     * @type {string}
     * @memberof PaymentSolutionResource
     */
    name?: string;
    /**
     * Identifier name of payment solution.
     * @type {string}
     * @memberof PaymentSolutionResource
     */
    identifier?: PaymentSolutionResource.IdentifierEnum;
    /**
     * Identifier name of payment solution.
     * @type {string}
     * @memberof PaymentSolutionResource
     */
    geoCode?: string;
    /**
     * Date of creation of payment solution.
     * @type {string}
     * @memberof PaymentSolutionResource
     */
    createdAt?: string;
}

/**
 * @export
 * @namespace PaymentSolutionResource
 */
export namespace PaymentSolutionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum IdentifierEnum {
        Invoice = <any> 'invoice',
        Card = <any> 'card',
        InvoiceNoFee = <any> 'invoice_no_fee',
        Giropay = <any> 'giropay',
        SepaDirectDebit = <any> 'sepa_direct_debit',
        Free = <any> 'free',
        Ideal = <any> 'ideal',
        Manual = <any> 'manual',
        Punches = <any> 'punches'
    }
}

/**
 *
 * @export
 * @interface PaymentSolutionResult
 */
export interface PaymentSolutionResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PaymentSolutionResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PaymentSolutionResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PaymentSolutionResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PaymentSolutionResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PaymentSolutionResource>}
     * @memberof PaymentSolutionResult
     */
    data?: Array<PaymentSolutionResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PaymentSolutionResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PermissionResource
 */
export interface PermissionResource {
    /**
     * Identifier for the given permission.
     * @type {string}
     * @memberof PermissionResource
     */
    id?: string;
    /**
     * Id for the permission.
     * @type {string}
     * @memberof PermissionResource
     */
    description?: string;
}

/**
 *
 * @export
 * @interface PermissionResult
 */
export interface PermissionResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PermissionResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PermissionResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PermissionResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PermissionResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PermissionResource>}
     * @memberof PermissionResult
     */
    data?: Array<PermissionResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PermissionResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PersonalTitleResource
 */
export interface PersonalTitleResource {
    /**
     *
     * @type {number}
     * @memberof PersonalTitleResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof PersonalTitleResource
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof PersonalTitleResource
     */
    geoCode?: string;
}

/**
 *
 * @export
 * @interface PhoneActionCallResource
 */
export interface PhoneActionCallResource {
    /**
     * The phone number of the receiver of the call
     * @type {string}
     * @memberof PhoneActionCallResource
     */
    destination?: string;
    /**
     * The two letter geo code of the destination phone number
     * @type {string}
     * @memberof PhoneActionCallResource
     */
    geoCode?: string;
}

/**
 *
 * @export
 * @interface PhoneActionResource
 */
export interface PhoneActionResource {
    /**
     * The action type
     * @type {string}
     * @memberof PhoneActionResource
     */
    type?: PhoneActionResource.TypeEnum;
    /**
     *
     * @type {PhoneActionCallResource}
     * @memberof PhoneActionResource
     */
    call?: PhoneActionCallResource;
}

/**
 * @export
 * @namespace PhoneActionResource
 */
export namespace PhoneActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Unknown = <any> 'unknown',
        Call = <any> 'call'
    }
}

/**
 *
 * @export
 * @interface PhoneEmployeeResource
 */
export interface PhoneEmployeeResource {
    /**
     * The id of the attached employee
     * @type {number}
     * @memberof PhoneEmployeeResource
     */
    id?: number;
}

/**
 *
 * @export
 * @interface PhoneGeoResource
 */
export interface PhoneGeoResource {
    /**
     * Code for the given location.
     * @type {string}
     * @memberof PhoneGeoResource
     */
    code?: string;
}

/**
 *
 * @export
 * @interface PhoneResource
 */
export interface PhoneResource {
    /**
     * The phone's id.
     * @type {number}
     * @memberof PhoneResource
     */
    id?: number;
    /**
     * When was the phone created in the system.
     * @type {string}
     * @memberof PhoneResource
     */
    createdAt?: string;
    /**
     * At what date was the phone last updated.
     * @type {string}
     * @memberof PhoneResource
     */
    updatedAt?: string;
    /**
     * The mac address of the phone.
     * @type {string}
     * @memberof PhoneResource
     */
    macAddress?: string;
    /**
     * The internal phone number.
     * @type {string}
     * @memberof PhoneResource
     */
    vpnNumber?: string;
    /**
     * Phone's title
     * @type {string}
     * @memberof PhoneResource
     */
    title?: string;
    /**
     * The external phone number.
     * @type {string}
     * @memberof PhoneResource
     */
    phoneNumber?: string;
    /**
     *
     * @type {PhoneEmployeeResource}
     * @memberof PhoneResource
     */
    employee?: PhoneEmployeeResource;
    /**
     *
     * @type {PhoneGeoResource}
     * @memberof PhoneResource
     */
    geo?: PhoneGeoResource;
    /**
     * The local ip address of the phone.
     * @type {string}
     * @memberof PhoneResource
     */
    localIpAddress?: string;
    /**
     * The model of the hardware itself.
     * @type {string}
     * @memberof PhoneResource
     */
    model?: string;
    /**
     * If the phone is active. This is automatically set to true when some physical action is registered.
     * @type {string}
     * @memberof PhoneResource
     */
    isActive?: string;
}

/**
 *
 * @export
 * @interface PhoneVerificationActionResource
 */
export interface PhoneVerificationActionResource {
    /**
     *
     * @type {number}
     * @memberof PhoneVerificationActionResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof PhoneVerificationActionResource
     */
    phoneNumber?: string;
    /**
     *
     * @type {string}
     * @memberof PhoneVerificationActionResource
     */
    action?: PhoneVerificationActionResource.ActionEnum;
    /**
     *
     * @type {number}
     * @memberof PhoneVerificationActionResource
     */
    clientId?: number;
}

/**
 * @export
 * @namespace PhoneVerificationActionResource
 */
export namespace PhoneVerificationActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        VerifyByEmployee = <any> 'verify_by_employee'
    }
}

/**
 *
 * @export
 * @interface PhoneVerificationClientResource
 */
export interface PhoneVerificationClientResource {
    /**
     *
     * @type {string}
     * @memberof PhoneVerificationClientResource
     */
    token?: string;
    /**
     *
     * @type {string}
     * @memberof PhoneVerificationClientResource
     */
    id?: string;
}

/**
 *
 * @export
 * @interface PhoneVerificationResource
 */
export interface PhoneVerificationResource {
    /**
     *
     * @type {number}
     * @memberof PhoneVerificationResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof PhoneVerificationResource
     */
    phoneNumber?: string;
    /**
     *
     * @type {string}
     * @memberof PhoneVerificationResource
     */
    geoCode?: string;
    /**
     *
     * @type {string}
     * @memberof PhoneVerificationResource
     */
    verificationCode?: string;
    /**
     *
     * @type {string}
     * @memberof PhoneVerificationResource
     */
    verifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof PhoneVerificationResource
     */
    createdAt?: string;
    /**
     *
     * @type {PhoneVerificationClientResource}
     * @memberof PhoneVerificationResource
     */
    client?: PhoneVerificationClientResource;
    /**
     *
     * @type {number}
     * @memberof PhoneVerificationResource
     */
    latestLeadId?: number;
}

/**
 *
 * @export
 * @interface PrepaymentResource
 */
export interface PrepaymentResource {
    /**
     *
     * @type {number}
     * @memberof PrepaymentResource
     */
    id?: number;
    /**
     *
     * @type {number}
     * @memberof PrepaymentResource
     */
    partnerId?: number;
    /**
     *
     * @type {string}
     * @memberof PrepaymentResource
     */
    geoCode?: string;
    /**
     *
     * @type {AmountResource}
     * @memberof PrepaymentResource
     */
    amount?: AmountResource;
    /**
     *
     * @type {string}
     * @memberof PrepaymentResource
     */
    processedAt?: string;
    /**
     *
     * @type {PartnerCouponResource}
     * @memberof PrepaymentResource
     */
    partnerCoupon?: PartnerCouponResource;
}

/**
 *
 * @export
 * @interface ProductResource
 */
export interface ProductResource {
    /**
     * The product's id.
     * @type {number}
     * @memberof ProductResource
     */
    id?: number;
    /**
     * Geo code for product
     * @type {string}
     * @memberof ProductResource
     */
    geoCode?: string;
    /**
     * Product's name
     * @type {string}
     * @memberof ProductResource
     */
    name?: string;
    /**
     *
     * @type {EmployeeResource}
     * @memberof ProductResource
     */
    creatorEmployee?: EmployeeResource;
    /**
     * The partners can set up prices to auto-quote but not all products are auto-quotable
     * @type {boolean}
     * @memberof ProductResource
     */
    isAutoQuotable?: boolean;
}

/**
 *
 * @export
 * @interface ProductResult
 */
export interface ProductResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof ProductResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof ProductResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof ProductResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof ProductResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<ProductResource>}
     * @memberof ProductResult
     */
    data?: Array<ProductResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof ProductResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface ProjectCustomerResource
 */
export interface ProjectCustomerResource {
    /**
     *
     * @type {number}
     * @memberof ProjectCustomerResource
     */
    id?: number;
    /**
     * The first name for the customer.
     * @type {string}
     * @memberof ProjectCustomerResource
     */
    firstName?: string;
    /**
     * The last name for the customer.
     * @type {string}
     * @memberof ProjectCustomerResource
     */
    lastName?: string;
    /**
     * The name of the company.
     * @type {string}
     * @memberof ProjectCustomerResource
     */
    companyName?: string;
    /**
     * The email used to contact the customer.
     * @type {string}
     * @memberof ProjectCustomerResource
     */
    email?: string;
    /**
     * The Phone Number used to contact the customer.
     * @type {string}
     * @memberof ProjectCustomerResource
     */
    phone?: string;
    /**
     * The Mobile Phone Number used to contact the customer.
     * @type {string}
     * @memberof ProjectCustomerResource
     */
    mobile?: string;
    /**
     * Do the customer have a website ?
     * @type {string}
     * @memberof ProjectCustomerResource
     */
    website?: string;
    /**
     * VAT Number for the customer.
     * @type {string}
     * @memberof ProjectCustomerResource
     */
    vatNumber?: string;
    /**
     *
     * @type {ProjectGeoResource}
     * @memberof ProjectCustomerResource
     */
    geo?: ProjectGeoResource;
}

/**
 *
 * @export
 * @interface ProjectEventResource
 */
export interface ProjectEventResource {
    /**
     *
     * @type {number}
     * @memberof ProjectEventResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ProjectEventResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof ProjectEventResource
     */
    updatedAt?: string;
    /**
     *
     * @type {string}
     * @memberof ProjectEventResource
     */
    type?: ProjectEventResource.TypeEnum;
    /**
     *
     * @type {string}
     * @memberof ProjectEventResource
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof ProjectEventResource
     */
    description?: string;
    /**
     *
     * @type {string}
     * @memberof ProjectEventResource
     */
    scheduledStart?: string;
    /**
     *
     * @type {string}
     * @memberof ProjectEventResource
     */
    scheduledStop?: string;
    /**
     *
     * @type {number}
     * @memberof ProjectEventResource
     */
    scheduledLength?: number;
    /**
     *
     * @type {ProjectResource}
     * @memberof ProjectEventResource
     */
    project?: ProjectResource;
    /**
     *
     * @type {ProjectPartnerUserResource}
     * @memberof ProjectEventResource
     */
    partnerUser?: ProjectPartnerUserResource;
}

/**
 * @export
 * @namespace ProjectEventResource
 */
export namespace ProjectEventResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Unknown = <any> 'unknown',
        Meeting = <any> 'meeting'
    }
}

/**
 *
 * @export
 * @interface ProjectGeoResource
 */
export interface ProjectGeoResource {
    /**
     * Code for the given location.
     * @type {string}
     * @memberof ProjectGeoResource
     */
    code?: string;
    /**
     * The address.
     * @type {string}
     * @memberof ProjectGeoResource
     */
    address?: string;
    /**
     * Zip Code.
     * @type {string}
     * @memberof ProjectGeoResource
     */
    zipCode?: string;
    /**
     * Name of the city.
     * @type {string}
     * @memberof ProjectGeoResource
     */
    cityName?: string;
    /**
     *
     * @type {LocationGeoPointResource}
     * @memberof ProjectGeoResource
     */
    point?: LocationGeoPointResource;
}

/**
 *
 * @export
 * @interface ProjectLeadResource
 */
export interface ProjectLeadResource {
    /**
     *
     * @type {number}
     * @memberof ProjectLeadResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ProjectLeadResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof ProjectLeadResource
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof ProjectLeadResource
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof ProjectLeadResource
     */
    geoCode?: string;
    /**
     *
     * @type {string}
     * @memberof ProjectLeadResource
     */
    city?: string;
    /**
     *
     * @type {number}
     * @memberof ProjectLeadResource
     */
    price?: number;
}

/**
 *
 * @export
 * @interface ProjectNoteResource
 */
export interface ProjectNoteResource {
    /**
     *
     * @type {number}
     * @memberof ProjectNoteResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ProjectNoteResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof ProjectNoteResource
     */
    updatedAt?: string;
    /**
     *
     * @type {string}
     * @memberof ProjectNoteResource
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof ProjectNoteResource
     */
    content?: string;
    /**
     *
     * @type {number}
     * @memberof ProjectNoteResource
     */
    projectId?: number;
    /**
     *
     * @type {ProjectPartnerUserResource}
     * @memberof ProjectNoteResource
     */
    partnerUser?: ProjectPartnerUserResource;
}

/**
 *
 * @export
 * @interface ProjectNoteResult
 */
export interface ProjectNoteResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof ProjectNoteResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof ProjectNoteResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof ProjectNoteResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof ProjectNoteResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<ProjectNoteResource>}
     * @memberof ProjectNoteResult
     */
    data?: Array<ProjectNoteResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof ProjectNoteResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface ProjectPartnerUserResource
 */
export interface ProjectPartnerUserResource {
    /**
     *
     * @type {number}
     * @memberof ProjectPartnerUserResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ProjectPartnerUserResource
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof ProjectPartnerUserResource
     */
    profilePicture?: string;
}

/**
 *
 * @export
 * @interface ProjectResource
 */
export interface ProjectResource {
    /**
     * Id for the project.
     * @type {number}
     * @memberof ProjectResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ProjectResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof ProjectResource
     */
    updatedAt?: string;
    /**
     *
     * @type {ProjectStatusResource}
     * @memberof ProjectResource
     */
    status?: ProjectStatusResource;
    /**
     *
     * @type {string}
     * @memberof ProjectResource
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof ProjectResource
     */
    internal?: string;
    /**
     *
     * @type {string}
     * @memberof ProjectResource
     */
    description?: string;
    /**
     *
     * @type {string}
     * @memberof ProjectResource
     */
    geoCode?: string;
    /**
     *
     * @type {LeadResource}
     * @memberof ProjectResource
     */
    lead?: LeadResource;
    /**
     *
     * @type {PartnerResource}
     * @memberof ProjectResource
     */
    partner?: PartnerResource;
    /**
     *
     * @type {PartnerUserResource}
     * @memberof ProjectResource
     */
    partnerUser?: PartnerUserResource;
    /**
     *
     * @type {Array<ProjectCustomerResource>}
     * @memberof ProjectResource
     */
    customers?: Array<ProjectCustomerResource>;
    /**
     *
     * @type {ProjectGeoResource}
     * @memberof ProjectResource
     */
    geo?: ProjectGeoResource;
    /**
     *
     * @type {LeadQuoteResource}
     * @memberof ProjectResource
     */
    quote?: LeadQuoteResource;
    /**
     *
     * @type {Array<ProjectNoteResource>}
     * @memberof ProjectResource
     */
    notes?: Array<ProjectNoteResource>;
}

/**
 *
 * @export
 * @interface ProjectResult
 */
export interface ProjectResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof ProjectResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof ProjectResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof ProjectResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof ProjectResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<ProjectResource>}
     * @memberof ProjectResult
     */
    data?: Array<ProjectResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof ProjectResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface ProjectStatusResource
 */
export interface ProjectStatusResource {
    /**
     *
     * @type {string}
     * @memberof ProjectStatusResource
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof ProjectStatusResource
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof ProjectStatusResource
     */
    color?: string;
}

/**
 *
 * @export
 * @interface ProjectStatusResult
 */
export interface ProjectStatusResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof ProjectStatusResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof ProjectStatusResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof ProjectStatusResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof ProjectStatusResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<ProjectStatusResource>}
     * @memberof ProjectStatusResult
     */
    data?: Array<ProjectStatusResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof ProjectStatusResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface PromptActionResource
 */
export interface PromptActionResource {
    /**
     * Action
     * @type {string}
     * @memberof PromptActionResource
     */
    action?: PromptActionResource.ActionEnum;
}

/**
 * @export
 * @namespace PromptActionResource
 */
export namespace PromptActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        Unknown = <any> 'unknown',
        Dismiss = <any> 'dismiss',
        Impression = <any> 'impression',
        Complete = <any> 'complete'
    }
}

/**
 *
 * @export
 * @interface PromptAttributeResource
 */
export interface PromptAttributeResource {
    /**
     * Attribute key
     * @type {string}
     * @memberof PromptAttributeResource
     */
    key?: string;
    /**
     * Value
     * @type {string}
     * @memberof PromptAttributeResource
     */
    value?: string;
}

/**
 *
 * @export
 * @interface PromptResource
 */
export interface PromptResource {
    /**
     * Client id.
     * @type {number}
     * @memberof PromptResource
     */
    id?: number;
    /**
     * Attributes
     * @type {Array<PromptAttributeResource>}
     * @memberof PromptResource
     */
    attributes?: Array<PromptAttributeResource>;
    /**
     * Type
     * @type {string}
     * @memberof PromptResource
     */
    type?: string;
    /**
     * Trigger
     * @type {string}
     * @memberof PromptResource
     */
    trigger?: string;
    /**
     * Receiver
     * @type {string}
     * @memberof PromptResource
     */
    receiverType?: PromptResource.ReceiverTypeEnum;
    /**
     * Partner id
     * @type {number}
     * @memberof PromptResource
     */
    partnerId?: number;
    /**
     * Partner user id
     * @type {number}
     * @memberof PromptResource
     */
    partnerUserId?: number;
    /**
     * Last status
     * @type {string}
     * @memberof PromptResource
     */
    status?: string;
    /**
     * Last status date
     * @type {string}
     * @memberof PromptResource
     */
    statusAt?: string;
    /**
     * Expires at
     * @type {string}
     * @memberof PromptResource
     */
    expiresAt?: string;
}

/**
 * @export
 * @namespace PromptResource
 */
export namespace PromptResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ReceiverTypeEnum {
        Unknown = <any> 'unknown',
        Partner = <any> 'partner',
        PartnerUser = <any> 'partner_user'
    }
}

/**
 *
 * @export
 * @interface PromptResult
 */
export interface PromptResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof PromptResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof PromptResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof PromptResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof PromptResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<PromptResource>}
     * @memberof PromptResult
     */
    data?: Array<PromptResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof PromptResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface RatingPartnerResource
 */
export interface RatingPartnerResource {
    /**
     * Id of the suggested partner.
     * @type {number}
     * @memberof RatingPartnerResource
     */
    id?: number;
    /**
     * Company name of the suggested partner.
     * @type {string}
     * @memberof RatingPartnerResource
     */
    companyName?: string;
    /**
     * Slug to use for Uri
     * @type {string}
     * @memberof RatingPartnerResource
     */
    slug?: string;
    /**
     * Partner State
     * @type {string}
     * @memberof RatingPartnerResource
     */
    state?: RatingPartnerResource.StateEnum;
    /**
     * Status of the suggested partner.
     * @type {string}
     * @memberof RatingPartnerResource
     */
    status?: string;
    /**
     * Zip Code of the suggested partner.
     * @type {string}
     * @memberof RatingPartnerResource
     */
    zipCode?: string;
    /**
     * City of the suggested partner.
     * @type {string}
     * @memberof RatingPartnerResource
     */
    city?: string;
    /**
     * Geo Location of the suggested partner.
     * @type {string}
     * @memberof RatingPartnerResource
     */
    geoCode?: string;
    /**
     * Partner State.
     * @type {string}
     * @memberof RatingPartnerResource
     */
    partnerState?: string;
}

/**
 * @export
 * @namespace RatingPartnerResource
 */
export namespace RatingPartnerResource {
    /**
     * @export
     * @enum {string}
     */
    export enum StateEnum {
        Unknown = <any> 'unknown',
        Canvas = <any> 'canvas',
        Demo = <any> 'demo',
        Inactive = <any> 'inactive',
        ExPartner = <any> 'ex_partner',
        Active = <any> 'active',
        Closed = <any> 'closed',
        BusinessPartner = <any> 'business_partner'
    }
}

/**
 *
 * @export
 * @interface RatingReplyResource
 */
export interface RatingReplyResource {
    /**
     * Comment for the rating reply.
     * @type {string}
     * @memberof RatingReplyResource
     */
    comment?: string;
    /**
     *
     * @type {PartnerUserResource}
     * @memberof RatingReplyResource
     */
    user?: PartnerUserResource;
    /**
     * Time of reply.
     * @type {string}
     * @memberof RatingReplyResource
     */
    createdAt?: string;
}

/**
 *
 * @export
 * @interface RatingResource
 */
export interface RatingResource {
    /**
     * Review ID.
     * @type {number}
     * @memberof RatingResource
     */
    id?: number;
    /**
     * Time of creation of the rating.
     * @type {string}
     * @memberof RatingResource
     */
    createdAt?: string;
    /**
     * Time of last update.
     * @type {string}
     * @memberof RatingResource
     */
    updatedAt?: string;
    /**
     *
     * @type {RatingPartnerResource}
     * @memberof RatingResource
     */
    partner?: RatingPartnerResource;
    /**
     * Name of the person who wrote the review.
     * @type {string}
     * @memberof RatingResource
     */
    name?: string;
    /**
     * Email of the reviewer.
     * @type {string}
     * @memberof RatingResource
     */
    email?: string;
    /**
     * Phone number of the reviewer.
     * @type {string}
     * @memberof RatingResource
     */
    phone?: string;
    /**
     *
     * @type {RatingScoreResource}
     * @memberof RatingResource
     */
    score?: RatingScoreResource;
    /**
     * Stars for the given rating
     * @type {number}
     * @memberof RatingResource
     */
    stars?: number;
    /**
     *
     * @type {RatingReplyResource}
     * @memberof RatingResource
     */
    reply?: RatingReplyResource;
    /**
     * Title of the review.
     * @type {string}
     * @memberof RatingResource
     */
    title?: string;
    /**
     * The comment left by the reviewer.
     * @type {string}
     * @memberof RatingResource
     */
    comment?: string;
    /**
     * Time of deletion.
     * @type {string}
     * @memberof RatingResource
     */
    deletedAt?: string;
    /**
     * Is the rating approved?
     * @type {boolean}
     * @memberof RatingResource
     */
    isApproved?: boolean;
    /**
     * Is the rating active?
     * @type {boolean}
     * @memberof RatingResource
     */
    isActive?: boolean;
    /**
     * Is the rating validated?
     * @type {boolean}
     * @memberof RatingResource
     */
    isValidated?: boolean;
    /**
     * Is the rating featured?
     * @type {boolean}
     * @memberof RatingResource
     */
    isFeatured?: boolean;
    /**
     * Is the rating public
     * @type {boolean}
     * @memberof RatingResource
     */
    isPublic?: boolean;
    /**
     * If the rating is featured, the time the rating was featured.
     * @type {string}
     * @memberof RatingResource
     */
    featuredAt?: string;
    /**
     * Lead ID this rating relates to.
     * @type {number}
     * @memberof RatingResource
     */
    leadId?: number;
    /**
     * IP address of the reviewer.
     * @type {string}
     * @memberof RatingResource
     */
    ip?: string;
}

/**
 *
 * @export
 * @interface RatingResult
 */
export interface RatingResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof RatingResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof RatingResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof RatingResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof RatingResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<RatingResource>}
     * @memberof RatingResult
     */
    data?: Array<RatingResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof RatingResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface RatingScoreResource
 */
export interface RatingScoreResource {
    /**
     * Overall rating. An aggregation of the other ratings combined.
     * @type {number}
     * @memberof RatingScoreResource
     */
    overall?: number;
    /**
     * Rating from 1 to 5 of the quality of the partner's work.
     * @type {number}
     * @memberof RatingScoreResource
     */
    quality?: number;
    /**
     * Rating from 1 to 5 of the communication with the partner.
     * @type {number}
     * @memberof RatingScoreResource
     */
    contact?: number;
    /**
     * Rating from 1 to 5 of the partner's expertise.
     * @type {number}
     * @memberof RatingScoreResource
     */
    expertise?: number;
    /**
     * Rating from 1 to 5 of the partner's ability to keep the schedule.
     * @type {number}
     * @memberof RatingScoreResource
     */
    schedule?: number;
    /**
     * Rating from 1 to 5 of the partner's ability to keep the budget.
     * @type {number}
     * @memberof RatingScoreResource
     */
    budget?: number;
}

/**
 *
 * @export
 * @interface RecipientResource
 */
export interface RecipientResource {
    /**
     *
     * @type {number}
     * @memberof RecipientResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof RecipientResource
     */
    type?: string;
    /**
     *
     * @type {number}
     * @memberof RecipientResource
     */
    employeeId?: number;
    /**
     *
     * @type {number}
     * @memberof RecipientResource
     */
    leadId?: number;
    /**
     *
     * @type {number}
     * @memberof RecipientResource
     */
    partnerUserId?: number;
    /**
     *
     * @type {string}
     * @memberof RecipientResource
     */
    readAt?: string;
    /**
     *
     * @type {string}
     * @memberof RecipientResource
     */
    hiddenAt?: string;
}

/**
 *
 * @export
 * @interface RefundResource
 */
export interface RefundResource {
    /**
     * Refund request ID.
     * @type {number}
     * @memberof RefundResource
     */
    id?: number;
    /**
     * Partner ID.
     * @type {number}
     * @memberof RefundResource
     */
    partnerId?: number;
    /**
     * Lead ID.
     * @type {number}
     * @memberof RefundResource
     */
    leadId?: number;
    /**
     * Lead contacted by partner.
     * @type {string}
     * @memberof RefundResource
     */
    contactedBy?: string;
    /**
     * Date the refund request was created.
     * @type {string}
     * @memberof RefundResource
     */
    createdAt?: string;
    /**
     * Date the refund request was updated.
     * @type {string}
     * @memberof RefundResource
     */
    updatedAt?: string;
    /**
     * Date the refund request was updated.
     * @type {string}
     * @memberof RefundResource
     */
    deletedAt?: string;
    /**
     * Reason that the lead purchase should be refunded. Given by the partner.
     * @type {string}
     * @memberof RefundResource
     */
    reason?: string;
    /**
     * Argumentation that the lead purchase should be refunded. Given by the partner.
     * @type {string}
     * @memberof RefundResource
     */
    argument?: string;
    /**
     * The partner's description of the contact established between lead and partner. Given by the partner.
     * @type {string}
     * @memberof RefundResource
     */
    contact?: string;
    /**
     * Settlement reason given by Ageras.
     * @type {string}
     * @memberof RefundResource
     */
    settlement?: string;
    /**
     * Refund request status.
     * @type {string}
     * @memberof RefundResource
     */
    status?: RefundResource.StatusEnum;
}

/**
 * @export
 * @namespace RefundResource
 */
export namespace RefundResource {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Rejected = <any> 'rejected',
        Refunded = <any> 'refunded',
        New = <any> 'new'
    }
}

/**
 *
 * @export
 * @interface RefundResult
 */
export interface RefundResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof RefundResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof RefundResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof RefundResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof RefundResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<RefundResource>}
     * @memberof RefundResult
     */
    data?: Array<RefundResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof RefundResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface RevenueSegmentResource
 */
export interface RevenueSegmentResource {
    /**
     * Primary ID.
     * @type {number}
     * @memberof RevenueSegmentResource
     */
    id?: number;
    /**
     * Geo code.
     * @type {string}
     * @memberof RevenueSegmentResource
     */
    geoCode?: string;
    /**
     * Human-readable name.
     * @type {string}
     * @memberof RevenueSegmentResource
     */
    displayName?: string;
    /**
     *
     * @type {AmountResource}
     * @memberof RevenueSegmentResource
     */
    minimumRevenue?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof RevenueSegmentResource
     */
    maximumRevenue?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof RevenueSegmentResource
     */
    lbPriceExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof RevenueSegmentResource
     */
    ppsPriceExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof RevenueSegmentResource
     */
    minimumLbPriceExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof RevenueSegmentResource
     */
    minimumPpsPriceExclVat?: AmountResource;
    /**
     *
     * @type {LeadCategoryResource}
     * @memberof RevenueSegmentResource
     */
    leadCategory?: LeadCategoryResource;
    /**
     * If and when was the segment archived.
     * @type {string}
     * @memberof RevenueSegmentResource
     */
    archivedAt?: string;
}

/**
 *
 * @export
 * @interface RevenueSegmentResult
 */
export interface RevenueSegmentResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof RevenueSegmentResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof RevenueSegmentResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof RevenueSegmentResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof RevenueSegmentResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<RevenueSegmentResource>}
     * @memberof RevenueSegmentResult
     */
    data?: Array<RevenueSegmentResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof RevenueSegmentResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface SearchResource
 */
export interface SearchResource {
    /**
     *
     * @type {string}
     * @memberof SearchResource
     */
    text?: string;
    /**
     *
     * @type {number}
     * @memberof SearchResource
     */
    hits?: number;
    /**
     *
     * @type {string}
     * @memberof SearchResource
     */
    type?: string;
}

/**
 *
 * @export
 * @interface SearchResult
 */
export interface SearchResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof SearchResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof SearchResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof SearchResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof SearchResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<SearchResource>}
     * @memberof SearchResult
     */
    data?: Array<SearchResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof SearchResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface SectorResource
 */
export interface SectorResource {
    /**
     * Sector ID.
     * @type {number}
     * @memberof SectorResource
     */
    id?: number;
    /**
     * Geo code.
     * @type {string}
     * @memberof SectorResource
     */
    geoCode?: string;
    /**
     * Human-readable name.
     * @type {string}
     * @memberof SectorResource
     */
    displayName?: string;
    /**
     *
     * @type {string}
     * @memberof SectorResource
     */
    archivedAt?: string;
}

/**
 *
 * @export
 * @interface SectorResult
 */
export interface SectorResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof SectorResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof SectorResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof SectorResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof SectorResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<SectorResource>}
     * @memberof SectorResult
     */
    data?: Array<SectorResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof SectorResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface SegmentationGeoRegionResource
 */
export interface SegmentationGeoRegionResource {
    /**
     * Sector ID.
     * @type {number}
     * @memberof SegmentationGeoRegionResource
     */
    id?: number;
    /**
     * Geo code.
     * @type {string}
     * @memberof SegmentationGeoRegionResource
     */
    geoCode?: string;
    /**
     * Human-readable name.
     * @type {string}
     * @memberof SegmentationGeoRegionResource
     */
    regionName?: string;
    /**
     * List of points that define this region.
     * @type {Array<LocationGeoPointResource>}
     * @memberof SegmentationGeoRegionResource
     */
    points?: Array<LocationGeoPointResource>;
}

/**
 *
 * @export
 * @interface SegmentationGeoRegionResult
 */
export interface SegmentationGeoRegionResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof SegmentationGeoRegionResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof SegmentationGeoRegionResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof SegmentationGeoRegionResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof SegmentationGeoRegionResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<SegmentationGeoRegionResource>}
     * @memberof SegmentationGeoRegionResult
     */
    data?: Array<SegmentationGeoRegionResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof SegmentationGeoRegionResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface SegmentationSubGeoRegionResource
 */
export interface SegmentationSubGeoRegionResource {
    /**
     * Primary ID.
     * @type {number}
     * @memberof SegmentationSubGeoRegionResource
     */
    id?: number;
    /**
     * Parent region ID.
     * @type {number}
     * @memberof SegmentationSubGeoRegionResource
     */
    segmentationGeoRegionId?: number;
    /**
     * Human-readable name.
     * @type {string}
     * @memberof SegmentationSubGeoRegionResource
     */
    subRegionName?: string;
    /**
     * List of points that define this region.
     * @type {Array<LocationGeoPointResource>}
     * @memberof SegmentationSubGeoRegionResource
     */
    points?: Array<LocationGeoPointResource>;
}

/**
 *
 * @export
 * @interface SegmentationSubGeoRegionResult
 */
export interface SegmentationSubGeoRegionResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof SegmentationSubGeoRegionResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof SegmentationSubGeoRegionResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof SegmentationSubGeoRegionResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof SegmentationSubGeoRegionResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<SegmentationSubGeoRegionResource>}
     * @memberof SegmentationSubGeoRegionResult
     */
    data?: Array<SegmentationSubGeoRegionResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof SegmentationSubGeoRegionResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface SegmentationSubGeoRegionsResource
 */
export interface SegmentationSubGeoRegionsResource {
    /**
     * Segmentation sub geo region IDs.
     * @type {Array<number>}
     * @memberof SegmentationSubGeoRegionsResource
     */
    segmentationSubGeoRegionId?: Array<number>;
}

/**
 *
 * @export
 * @interface SoftConversionResource
 */
export interface SoftConversionResource {
    /**
     * Primary ID.
     * @type {number}
     * @memberof SoftConversionResource
     */
    id?: number;
    /**
     * Email address.
     * @type {string}
     * @memberof SoftConversionResource
     */
    email?: string;
    /**
     * E.g. AU
     * @type {string}
     * @memberof SoftConversionResource
     */
    countryCode?: string;
    /**
     * Saubscribed at.
     * @type {string}
     * @memberof SoftConversionResource
     */
    subscribedAt?: string;
    /**
     * Full name, e.g. John Smith
     * @type {string}
     * @memberof SoftConversionResource
     */
    fullName?: string;
    /**
     * E.g. Ageras
     * @type {string}
     * @memberof SoftConversionResource
     */
    companyName?: string;
    /**
     * Source.
     * @type {string}
     * @memberof SoftConversionResource
     */
    source?: string;
    /**
     * Medium.
     * @type {string}
     * @memberof SoftConversionResource
     */
    medium?: string;
    /**
     * Campaign ID.
     * @type {string}
     * @memberof SoftConversionResource
     */
    campaignId?: string;
    /**
     * Original referrer.
     * @type {string}
     * @memberof SoftConversionResource
     */
    originalReferrer?: string;
    /**
     * Conversion page.
     * @type {string}
     * @memberof SoftConversionResource
     */
    conversionPage?: string;
}

/**
 *
 * @export
 * @interface StripeProductResource
 */
export interface StripeProductResource {
    /**
     *
     * @type {number}
     * @memberof StripeProductResource
     */
    id?: number;
    /**
     *
     * @type {boolean}
     * @memberof StripeProductResource
     */
    isActive?: boolean;
    /**
     *
     * @type {string}
     * @memberof StripeProductResource
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof StripeProductResource
     */
    description?: string;
    /**
     *
     * @type {string}
     * @memberof StripeProductResource
     */
    stripePrice?: string;
    /**
     *
     * @type {number}
     * @memberof StripeProductResource
     */
    priceExclVat?: number;
    /**
     *
     * @type {number}
     * @memberof StripeProductResource
     */
    priceInclVat?: number;
    /**
     *
     * @type {number}
     * @memberof StripeProductResource
     */
    vat?: number;
    /**
     *
     * @type {number}
     * @memberof StripeProductResource
     */
    vatRate?: number;
    /**
     *
     * @type {string}
     * @memberof StripeProductResource
     */
    currency?: string;
    /**
     *
     * @type {string}
     * @memberof StripeProductResource
     */
    paymentFrequency?: string;
}

/**
 *
 * @export
 * @interface StripeProductResult
 */
export interface StripeProductResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof StripeProductResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof StripeProductResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof StripeProductResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof StripeProductResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<StripeProductResource>}
     * @memberof StripeProductResult
     */
    data?: Array<StripeProductResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof StripeProductResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface TaskPriorityResource
 */
export interface TaskPriorityResource {
    /**
     *
     * @type {number}
     * @memberof TaskPriorityResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TaskPriorityResource
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof TaskPriorityResource
     */
    color?: string;
    /**
     *
     * @type {number}
     * @memberof TaskPriorityResource
     */
    rank?: number;
}

/**
 *
 * @export
 * @interface TaskPriorityResult
 */
export interface TaskPriorityResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof TaskPriorityResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof TaskPriorityResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof TaskPriorityResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof TaskPriorityResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<TaskPriorityResource>}
     * @memberof TaskPriorityResult
     */
    data?: Array<TaskPriorityResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof TaskPriorityResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface TaskResource
 */
export interface TaskResource {
    /**
     *
     * @type {number}
     * @memberof TaskResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TaskResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TaskResource
     */
    updatedAt?: string;
    /**
     *
     * @type {string}
     * @memberof TaskResource
     */
    deadlineAt?: string;
    /**
     *
     * @type {string}
     * @memberof TaskResource
     */
    completedAt?: string;
    /**
     *
     * @type {string}
     * @memberof TaskResource
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof TaskResource
     */
    description?: string;
    /**
     *
     * @type {EmployeeResource}
     * @memberof TaskResource
     */
    employee?: EmployeeResource;
    /**
     *
     * @type {EmployeeResource}
     * @memberof TaskResource
     */
    assignedEmployee?: EmployeeResource;
    /**
     *
     * @type {EmployeeResource}
     * @memberof TaskResource
     */
    completedByEmployee?: EmployeeResource;
    /**
     *
     * @type {TaskStatusResource}
     * @memberof TaskResource
     */
    status?: TaskStatusResource;
    /**
     *
     * @type {TaskPriorityResource}
     * @memberof TaskResource
     */
    priority?: TaskPriorityResource;
    /**
     *
     * @type {Array<TaskableResource>}
     * @memberof TaskResource
     */
    taskables?: Array<TaskableResource>;
}

/**
 *
 * @export
 * @interface TaskResult
 */
export interface TaskResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof TaskResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof TaskResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof TaskResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof TaskResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<TaskResource>}
     * @memberof TaskResult
     */
    data?: Array<TaskResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof TaskResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface TaskStatusResource
 */
export interface TaskStatusResource {
    /**
     *
     * @type {number}
     * @memberof TaskStatusResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TaskStatusResource
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof TaskStatusResource
     */
    color?: string;
}

/**
 *
 * @export
 * @interface TaskStatusResult
 */
export interface TaskStatusResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof TaskStatusResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof TaskStatusResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof TaskStatusResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof TaskStatusResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<TaskStatusResource>}
     * @memberof TaskStatusResult
     */
    data?: Array<TaskStatusResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof TaskStatusResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface TaskableEmployeeResource
 */
export interface TaskableEmployeeResource {
    /**
     * Id for the Employee.
     * @type {number}
     * @memberof TaskableEmployeeResource
     */
    id?: number;
    /**
     * Employee Created.
     * @type {string}
     * @memberof TaskableEmployeeResource
     */
    createdAt?: string;
    /**
     * Employees name.
     * @type {string}
     * @memberof TaskableEmployeeResource
     */
    name?: string;
    /**
     * Title for the employee.
     * @type {string}
     * @memberof TaskableEmployeeResource
     */
    title?: string;
    /**
     * Location for the employee.
     * @type {string}
     * @memberof TaskableEmployeeResource
     */
    geoCode?: string;
    /**
     * Photo of the employee.
     * @type {string}
     * @memberof TaskableEmployeeResource
     */
    image?: string;
}

/**
 *
 * @export
 * @interface TaskableLeadResource
 */
export interface TaskableLeadResource {
    /**
     *
     * @type {number}
     * @memberof TaskableLeadResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TaskableLeadResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TaskableLeadResource
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof TaskableLeadResource
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof TaskableLeadResource
     */
    geoCode?: string;
    /**
     *
     * @type {string}
     * @memberof TaskableLeadResource
     */
    city?: string;
    /**
     *
     * @type {number}
     * @memberof TaskableLeadResource
     */
    price?: number;
}

/**
 *
 * @export
 * @interface TaskablePartnerResource
 */
export interface TaskablePartnerResource {
    /**
     *
     * @type {number}
     * @memberof TaskablePartnerResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TaskablePartnerResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TaskablePartnerResource
     */
    companyName?: string;
    /**
     *
     * @type {string}
     * @memberof TaskablePartnerResource
     */
    geoCode?: string;
    /**
     *
     * @type {string}
     * @memberof TaskablePartnerResource
     */
    city?: string;
    /**
     *
     * @type {number}
     * @memberof TaskablePartnerResource
     */
    punchesLeft?: number;
}

/**
 *
 * @export
 * @interface TaskableResource
 */
export interface TaskableResource {
    /**
     *
     * @type {number}
     * @memberof TaskableResource
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TaskableResource
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TaskableResource
     */
    type?: TaskableResource.TypeEnum;
    /**
     *
     * @type {TaskablePartnerResource}
     * @memberof TaskableResource
     */
    partner?: TaskablePartnerResource;
    /**
     *
     * @type {TaskableLeadResource}
     * @memberof TaskableResource
     */
    lead?: TaskableLeadResource;
    /**
     *
     * @type {TaskableEmployeeResource}
     * @memberof TaskableResource
     */
    employee?: TaskableEmployeeResource;
}

/**
 * @export
 * @namespace TaskableResource
 */
export namespace TaskableResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Unknown = <any> 'unknown',
        Employee = <any> 'employee',
        Lead = <any> 'lead',
        Partner = <any> 'partner'
    }
}

/**
 *
 * @export
 * @interface TaskableResult
 */
export interface TaskableResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof TaskableResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof TaskableResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof TaskableResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof TaskableResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<TaskableResource>}
     * @memberof TaskableResult
     */
    data?: Array<TaskableResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof TaskableResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface TermsActionResource
 */
export interface TermsActionResource {
    /**
     *
     * @type {number}
     * @memberof TermsActionResource
     */
    termsId?: number;
    /**
     *
     * @type {string}
     * @memberof TermsActionResource
     */
    action?: TermsActionResource.ActionEnum;
    /**
     *
     * @type {string}
     * @memberof TermsActionResource
     */
    date?: string;
}

/**
 * @export
 * @namespace TermsActionResource
 */
export namespace TermsActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        Accept = <any> 'accept',
        CheckAccepted = <any> 'check_accepted'
    }
}

/**
 *
 * @export
 * @interface TermsResource
 */
export interface TermsResource {
    /**
     * Terms ID.
     * @type {number}
     * @memberof TermsResource
     */
    id?: number;
    /**
     * Geo code.
     * @type {string}
     * @memberof TermsResource
     */
    geoCode?: string;
    /**
     * Terms identifier.
     * @type {string}
     * @memberof TermsResource
     */
    identifier?: string;
    /**
     * Terms localized headline.
     * @type {string}
     * @memberof TermsResource
     */
    headline?: string;
    /**
     * Terms body.
     * @type {string}
     * @memberof TermsResource
     */
    body?: string;
}

/**
 *
 * @export
 * @interface TermsResult
 */
export interface TermsResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof TermsResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof TermsResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof TermsResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof TermsResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<TermsResource>}
     * @memberof TermsResult
     */
    data?: Array<TermsResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof TermsResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface TypeaheadResource
 */
export interface TypeaheadResource {
    /**
     *
     * @type {string}
     * @memberof TypeaheadResource
     */
    type?: TypeaheadResource.TypeEnum;
    /**
     *
     * @type {PartnerSuggestResource}
     * @memberof TypeaheadResource
     */
    partner?: PartnerSuggestResource;
    /**
     *
     * @type {LeadSuggestResource}
     * @memberof TypeaheadResource
     */
    lead?: LeadSuggestResource;
}

/**
 * @export
 * @namespace TypeaheadResource
 */
export namespace TypeaheadResource {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Unknown = <any> 'unknown',
        Employee = <any> 'employee',
        Lead = <any> 'lead',
        Partner = <any> 'partner'
    }
}

/**
 *
 * @export
 * @interface TypeaheadResult
 */
export interface TypeaheadResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof TypeaheadResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof TypeaheadResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof TypeaheadResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof TypeaheadResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<TypeaheadResource>}
     * @memberof TypeaheadResult
     */
    data?: Array<TypeaheadResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof TypeaheadResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface VapidResource
 */
export interface VapidResource {
    /**
     * @var string *_/
     * @type {string}
     * @memberof VapidResource
     */
    publicKey?: string;
}

/**
 *
 * @export
 * @interface VoucherActionResource
 */
export interface VoucherActionResource {
    /**
     *
     * @type {string}
     * @memberof VoucherActionResource
     */
    action?: VoucherActionResource.ActionEnum;
}

/**
 * @export
 * @namespace VoucherActionResource
 */
export namespace VoucherActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        AttemptCharge = <any> 'attempt_charge'
    }
}

/**
 *
 * @export
 * @interface VoucherRefundRequestActionResource
 */
export interface VoucherRefundRequestActionResource {
    /**
     * Lead action
     * @type {string}
     * @memberof VoucherRefundRequestActionResource
     */
    action?: VoucherRefundRequestActionResource.ActionEnum;
    /**
     * Refund request id
     * @type {number}
     * @memberof VoucherRefundRequestActionResource
     */
    voucherRefundRequestId?: number;
    /**
     * Amount of money to refund. @var float
     * @type {number}
     * @memberof VoucherRefundRequestActionResource
     */
    acceptedAmountExclVat?: number;
    /**
     * Reason for the decision. @var string
     * @type {string}
     * @memberof VoucherRefundRequestActionResource
     */
    decisionReason?: string;
}

/**
 * @export
 * @namespace VoucherRefundRequestActionResource
 */
export namespace VoucherRefundRequestActionResource {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        Unknown = <any> 'unknown',
        Accept = <any> 'accept',
        Reject = <any> 'reject'
    }
}

/**
 *
 * @export
 * @interface VoucherRefundRequestResource
 */
export interface VoucherRefundRequestResource {
    /**
     * Voucher refund request ID.
     * @type {number}
     * @memberof VoucherRefundRequestResource
     */
    id?: number;
    /**
     *
     * @type {VoucherRefundRequestVoucherResource}
     * @memberof VoucherRefundRequestResource
     */
    invoice?: VoucherRefundRequestVoucherResource;
    /**
     *
     * @type {InvoicePartnerResource}
     * @memberof VoucherRefundRequestResource
     */
    partner?: InvoicePartnerResource;
    /**
     *
     * @type {InvoicePartnerUserResource}
     * @memberof VoucherRefundRequestResource
     */
    partnerUser?: InvoicePartnerUserResource;
    /**
     * Status.
     * @type {string}
     * @memberof VoucherRefundRequestResource
     */
    status?: VoucherRefundRequestResource.StatusEnum;
    /**
     * Request reason.
     * @type {string}
     * @memberof VoucherRefundRequestResource
     */
    requestReason?: string;
    /**
     * Request details.
     * @type {string}
     * @memberof VoucherRefundRequestResource
     */
    partnerRequestDescription?: string;
    /**
     * Employee's reason for their decision.
     * @type {string}
     * @memberof VoucherRefundRequestResource
     */
    employeeDecisionReason?: string;
    /**
     *
     * @type {AmountResource}
     * @memberof VoucherRefundRequestResource
     */
    requestedAmountExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof VoucherRefundRequestResource
     */
    acceptedAmountExclVat?: AmountResource;
    /**
     * Created at date.
     * @type {string}
     * @memberof VoucherRefundRequestResource
     */
    createdAt?: string;
    /**
     * Last update.
     * @type {string}
     * @memberof VoucherRefundRequestResource
     */
    updatedAt?: string;
    /**
     * Base64-encoded supporting documentation.
     * @type {string}
     * @memberof VoucherRefundRequestResource
     */
    documentation?: string;
    /**
     * URL to stored documentation file.
     * @type {string}
     * @memberof VoucherRefundRequestResource
     */
    documentationUrl?: string;
    /**
     * Employee ID of employee taking care of this refund.
     * @type {number}
     * @memberof VoucherRefundRequestResource
     */
    assignedEmployeeId?: number;
}

/**
 * @export
 * @namespace VoucherRefundRequestResource
 */
export namespace VoucherRefundRequestResource {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Pending = <any> 'pending',
        Accepted = <any> 'accepted',
        Rejected = <any> 'rejected'
    }
}

/**
 *
 * @export
 * @interface VoucherRefundRequestResult
 */
export interface VoucherRefundRequestResult {
    /**
     * Current Page.
     * @type {number}
     * @memberof VoucherRefundRequestResult
     */
    page?: number;
    /**
     * Number of results per page.
     * @type {number}
     * @memberof VoucherRefundRequestResult
     */
    limit?: number;
    /**
     * Number of pages.
     * @type {number}
     * @memberof VoucherRefundRequestResult
     */
    pages?: number;
    /**
     * Total number of results.
     * @type {number}
     * @memberof VoucherRefundRequestResult
     */
    total?: number;
    /**
     * The result.
     * @type {Array<VoucherRefundRequestResource>}
     * @memberof VoucherRefundRequestResult
     */
    data?: Array<VoucherRefundRequestResource>;
    /**
     * Options for related or alternative searches.
     * @type {string}
     * @memberof VoucherRefundRequestResult
     */
    didYouMean?: string;
}

/**
 *
 * @export
 * @interface VoucherRefundRequestVoucherResource
 */
export interface VoucherRefundRequestVoucherResource {
    /**
     * Voucher refund request ID.
     * @type {number}
     * @memberof VoucherRefundRequestVoucherResource
     */
    id?: number;
    /**
     * Created at date.
     * @type {string}
     * @memberof VoucherRefundRequestVoucherResource
     */
    createdAt?: string;
    /**
     * Invoice number.
     * @type {number}
     * @memberof VoucherRefundRequestVoucherResource
     */
    invoiceNumber?: number;
    /**
     *
     * @type {AmountResource}
     * @memberof VoucherRefundRequestVoucherResource
     */
    totalAmountExclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof VoucherRefundRequestVoucherResource
     */
    totalAmountInclVat?: AmountResource;
    /**
     *
     * @type {AmountResource}
     * @memberof VoucherRefundRequestVoucherResource
     */
    totalVatAmount?: AmountResource;
    /**
     * Invoice type.
     * @type {string}
     * @memberof VoucherRefundRequestVoucherResource
     */
    type?: string;
}


/**
 * ActivitiesApi - fetch parameter creator
 * @export
 */
export const ActivitiesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} activityId
         * @param {ActivityActionResource} activityActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activitiesActionsCreate(activityId: string, activityActionResource: ActivityActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'activityId' is not null or undefined
            if (activityId === null || activityId === undefined) {
                throw new RequiredError('activityId','Required parameter activityId was null or undefined when calling activitiesActionsCreate.');
            }
            // verify required parameter 'activityActionResource' is not null or undefined
            if (activityActionResource === null || activityActionResource === undefined) {
                throw new RequiredError('activityActionResource','Required parameter activityActionResource was null or undefined when calling activitiesActionsCreate.');
            }
            const localVarPath = `/activities/{activity_id}/actions`
                .replace(`{${"activity_id"}}`, encodeURIComponent(String(activityId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ActivityActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(activityActionResource || {}) : (activityActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List activities
         * @summary List activities
         * @param {string} [activityId]
         * @param {string} [partnerId]
         * @param {string} [leadId]
         * @param {string} [clientId]
         * @param {string} [geoCode]
         * @param {string} [employeeId]
         * @param {string} [partnerUserId]
         * @param {string} [type]
         * @param {'id' | 'is_pinned' | 'ascending' | 'descending'} [sort] @var string
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activitiesIndex(activityId?: string, partnerId?: string, leadId?: string, clientId?: string, geoCode?: string, employeeId?: string, partnerUserId?: string, type?: string, sort?: 'id' | 'is_pinned' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/activities`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (activityId !== undefined) {
                localVarQueryParameter['activity_id'] = activityId;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (leadId !== undefined) {
                localVarQueryParameter['lead_id'] = leadId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employee_id'] = employeeId;
            }

            if (partnerUserId !== undefined) {
                localVarQueryParameter['partner_user_id'] = partnerUserId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivitiesApi - functional programming interface
 * @export
 */
export const ActivitiesApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} activityId
         * @param {ActivityActionResource} activityActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activitiesActionsCreate(activityId: string, activityActionResource: ActivityActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ActivityResource> {
            const localVarFetchArgs = ActivitiesApiFetchParamCreator(configuration).activitiesActionsCreate(activityId, activityActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * List activities
         * @summary List activities
         * @param {string} [activityId]
         * @param {string} [partnerId]
         * @param {string} [leadId]
         * @param {string} [clientId]
         * @param {string} [geoCode]
         * @param {string} [employeeId]
         * @param {string} [partnerUserId]
         * @param {string} [type]
         * @param {'id' | 'is_pinned' | 'ascending' | 'descending'} [sort] @var string
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activitiesIndex(activityId?: string, partnerId?: string, leadId?: string, clientId?: string, geoCode?: string, employeeId?: string, partnerUserId?: string, type?: string, sort?: 'id' | 'is_pinned' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ActivityResult> {
            const localVarFetchArgs = ActivitiesApiFetchParamCreator(configuration).activitiesIndex(activityId, partnerId, leadId, clientId, geoCode, employeeId, partnerUserId, type, sort, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ActivitiesApi - factory interface
 * @export
 */
export const ActivitiesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} activityId
         * @param {ActivityActionResource} activityActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activitiesActionsCreate(activityId: string, activityActionResource: ActivityActionResource, options?: any) {
            return ActivitiesApiFp(configuration).activitiesActionsCreate(activityId, activityActionResource, options)(fetch, basePath);
        },
        /**
         * List activities
         * @summary List activities
         * @param {string} [activityId]
         * @param {string} [partnerId]
         * @param {string} [leadId]
         * @param {string} [clientId]
         * @param {string} [geoCode]
         * @param {string} [employeeId]
         * @param {string} [partnerUserId]
         * @param {string} [type]
         * @param {'id' | 'is_pinned' | 'ascending' | 'descending'} [sort] @var string
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activitiesIndex(activityId?: string, partnerId?: string, leadId?: string, clientId?: string, geoCode?: string, employeeId?: string, partnerUserId?: string, type?: string, sort?: 'id' | 'is_pinned' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
            return ActivitiesApiFp(configuration).activitiesIndex(activityId, partnerId, leadId, clientId, geoCode, employeeId, partnerUserId, type, sort, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * ActivitiesApi - object-oriented interface
 * @export
 * @class ActivitiesApi
 * @extends {BaseAPI}
 */
export class ActivitiesApi extends BaseAPI {
    /**
     *
     * @param {string} activityId
     * @param {ActivityActionResource} activityActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public activitiesActionsCreate(activityId: string, activityActionResource: ActivityActionResource, options?: any) {
        return ActivitiesApiFp(this.configuration).activitiesActionsCreate(activityId, activityActionResource, options)(this.fetch, this.basePath);
    }

    /**
     * List activities
     * @summary List activities
     * @param {string} [activityId]
     * @param {string} [partnerId]
     * @param {string} [leadId]
     * @param {string} [clientId]
     * @param {string} [geoCode]
     * @param {string} [employeeId]
     * @param {string} [partnerUserId]
     * @param {string} [type]
     * @param {'id' | 'is_pinned' | 'ascending' | 'descending'} [sort] @var string
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public activitiesIndex(activityId?: string, partnerId?: string, leadId?: string, clientId?: string, geoCode?: string, employeeId?: string, partnerUserId?: string, type?: string, sort?: 'id' | 'is_pinned' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
        return ActivitiesApiFp(this.configuration).activitiesIndex(activityId, partnerId, leadId, clientId, geoCode, employeeId, partnerUserId, type, sort, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * AffiliatesApi - fetch parameter creator
 * @export
 */
export const AffiliatesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} affiliateId
         * @param {boolean} [isActive] Determinate if affiliate campaigns is active or not.
         * @param {number} [limit] Limit of campaigns per page. @var int
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliatesCampaignsIndex(affiliateId: string, isActive?: boolean, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'affiliateId' is not null or undefined
            if (affiliateId === null || affiliateId === undefined) {
                throw new RequiredError('affiliateId','Required parameter affiliateId was null or undefined when calling affiliatesCampaignsIndex.');
            }
            const localVarPath = `/affiliates/{affiliate_id}/campaigns`
                .replace(`{${"affiliate_id"}}`, encodeURIComponent(String(affiliateId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [geoCode] Affiliate geo code origin.
         * @param {boolean} [isActive] Determinate if affiliate is active or not.
         * @param {boolean} [hasActiveCampaigns] Determinate if affiliate has active campaigns. @var boolean
         * @param {number} [limit] Limit of affiliates per page. @var int
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliatesIndex(geoCode?: string, isActive?: boolean, hasActiveCampaigns?: boolean, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/affiliates`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }

            if (hasActiveCampaigns !== undefined) {
                localVarQueryParameter['has_active_campaigns'] = hasActiveCampaigns;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AffiliatesApi - functional programming interface
 * @export
 */
export const AffiliatesApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} affiliateId
         * @param {boolean} [isActive] Determinate if affiliate campaigns is active or not.
         * @param {number} [limit] Limit of campaigns per page. @var int
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliatesCampaignsIndex(affiliateId: string, isActive?: boolean, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AffiliateCampaignResult> {
            const localVarFetchArgs = AffiliatesApiFetchParamCreator(configuration).affiliatesCampaignsIndex(affiliateId, isActive, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [geoCode] Affiliate geo code origin.
         * @param {boolean} [isActive] Determinate if affiliate is active or not.
         * @param {boolean} [hasActiveCampaigns] Determinate if affiliate has active campaigns. @var boolean
         * @param {number} [limit] Limit of affiliates per page. @var int
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliatesIndex(geoCode?: string, isActive?: boolean, hasActiveCampaigns?: boolean, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AffiliateResult> {
            const localVarFetchArgs = AffiliatesApiFetchParamCreator(configuration).affiliatesIndex(geoCode, isActive, hasActiveCampaigns, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AffiliatesApi - factory interface
 * @export
 */
export const AffiliatesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} affiliateId
         * @param {boolean} [isActive] Determinate if affiliate campaigns is active or not.
         * @param {number} [limit] Limit of campaigns per page. @var int
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliatesCampaignsIndex(affiliateId: string, isActive?: boolean, limit?: number, page?: number, query?: string, options?: any) {
            return AffiliatesApiFp(configuration).affiliatesCampaignsIndex(affiliateId, isActive, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [geoCode] Affiliate geo code origin.
         * @param {boolean} [isActive] Determinate if affiliate is active or not.
         * @param {boolean} [hasActiveCampaigns] Determinate if affiliate has active campaigns. @var boolean
         * @param {number} [limit] Limit of affiliates per page. @var int
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliatesIndex(geoCode?: string, isActive?: boolean, hasActiveCampaigns?: boolean, limit?: number, page?: number, query?: string, options?: any) {
            return AffiliatesApiFp(configuration).affiliatesIndex(geoCode, isActive, hasActiveCampaigns, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * AffiliatesApi - object-oriented interface
 * @export
 * @class AffiliatesApi
 * @extends {BaseAPI}
 */
export class AffiliatesApi extends BaseAPI {
    /**
     *
     * @param {string} affiliateId
     * @param {boolean} [isActive] Determinate if affiliate campaigns is active or not.
     * @param {number} [limit] Limit of campaigns per page. @var int
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliatesApi
     */
    public affiliatesCampaignsIndex(affiliateId: string, isActive?: boolean, limit?: number, page?: number, query?: string, options?: any) {
        return AffiliatesApiFp(this.configuration).affiliatesCampaignsIndex(affiliateId, isActive, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [geoCode] Affiliate geo code origin.
     * @param {boolean} [isActive] Determinate if affiliate is active or not.
     * @param {boolean} [hasActiveCampaigns] Determinate if affiliate has active campaigns. @var boolean
     * @param {number} [limit] Limit of affiliates per page. @var int
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliatesApi
     */
    public affiliatesIndex(geoCode?: string, isActive?: boolean, hasActiveCampaigns?: boolean, limit?: number, page?: number, query?: string, options?: any) {
        return AffiliatesApiFp(this.configuration).affiliatesIndex(geoCode, isActive, hasActiveCampaigns, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * AuthApi - fetch parameter creator
 * @export
 */
export const AuthApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {AuthResource} authResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authCreate(authResource: AuthResource, options: any = {}): FetchArgs {
            // verify required parameter 'authResource' is not null or undefined
            if (authResource === null || authResource === undefined) {
                throw new RequiredError('authResource','Required parameter authResource was null or undefined when calling authCreate.');
            }
            const localVarPath = `/auth`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AuthResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(authResource || {}) : (authResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} authId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDelete(authId: string, options: any = {}): FetchArgs {
            // verify required parameter 'authId' is not null or undefined
            if (authId === null || authId === undefined) {
                throw new RequiredError('authId','Required parameter authId was null or undefined when calling authDelete.');
            }
            const localVarPath = `/auth/{auth_id}`
                .replace(`{${"auth_id"}}`, encodeURIComponent(String(authId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} authId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authGet(authId: string, options: any = {}): FetchArgs {
            // verify required parameter 'authId' is not null or undefined
            if (authId === null || authId === undefined) {
                throw new RequiredError('authId','Required parameter authId was null or undefined when calling authGet.');
            }
            const localVarPath = `/auth/{auth_id}`
                .replace(`{${"auth_id"}}`, encodeURIComponent(String(authId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {PasswordResetResource} passwordResetResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPasswordCreate(passwordResetResource: PasswordResetResource, options: any = {}): FetchArgs {
            // verify required parameter 'passwordResetResource' is not null or undefined
            if (passwordResetResource === null || passwordResetResource === undefined) {
                throw new RequiredError('passwordResetResource','Required parameter passwordResetResource was null or undefined when calling authPasswordCreate.');
            }
            const localVarPath = `/auth/password`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PasswordResetResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(passwordResetResource || {}) : (passwordResetResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {PasswordResetResource} passwordResetResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPasswordUpdate(passwordResetResource: PasswordResetResource, options: any = {}): FetchArgs {
            // verify required parameter 'passwordResetResource' is not null or undefined
            if (passwordResetResource === null || passwordResetResource === undefined) {
                throw new RequiredError('passwordResetResource','Required parameter passwordResetResource was null or undefined when calling authPasswordUpdate.');
            }
            const localVarPath = `/auth/password`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PasswordResetResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(passwordResetResource || {}) : (passwordResetResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {PasswordReminderResource} passwordReminderResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPasswordreminderCreate(passwordReminderResource: PasswordReminderResource, options: any = {}): FetchArgs {
            // verify required parameter 'passwordReminderResource' is not null or undefined
            if (passwordReminderResource === null || passwordReminderResource === undefined) {
                throw new RequiredError('passwordReminderResource','Required parameter passwordReminderResource was null or undefined when calling authPasswordreminderCreate.');
            }
            const localVarPath = `/auth/passwordreminder`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PasswordReminderResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(passwordReminderResource || {}) : (passwordReminderResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPermissionsIndex(limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/auth/permissions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenDelete(options: any = {}): FetchArgs {
            const localVarPath = `/auth/token`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenGet(options: any = {}): FetchArgs {
            const localVarPath = `/auth/token`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {AuthResource} authResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenUpdate(authResource: AuthResource, options: any = {}): FetchArgs {
            // verify required parameter 'authResource' is not null or undefined
            if (authResource === null || authResource === undefined) {
                throw new RequiredError('authResource','Required parameter authResource was null or undefined when calling authTokenUpdate.');
            }
            const localVarPath = `/auth/token`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AuthResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(authResource || {}) : (authResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} authId
         * @param {AuthResource} authResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUpdate(authId: string, authResource: AuthResource, options: any = {}): FetchArgs {
            // verify required parameter 'authId' is not null or undefined
            if (authId === null || authId === undefined) {
                throw new RequiredError('authId','Required parameter authId was null or undefined when calling authUpdate.');
            }
            // verify required parameter 'authResource' is not null or undefined
            if (authResource === null || authResource === undefined) {
                throw new RequiredError('authResource','Required parameter authResource was null or undefined when calling authUpdate.');
            }
            const localVarPath = `/auth/{auth_id}`
                .replace(`{${"auth_id"}}`, encodeURIComponent(String(authId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AuthResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(authResource || {}) : (authResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {AuthResource} authResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authCreate(authResource: AuthResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthResource> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authCreate(authResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} authId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDelete(authId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authDelete(authId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} authId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authGet(authId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthResource> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authGet(authId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {PasswordResetResource} passwordResetResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPasswordCreate(passwordResetResource: PasswordResetResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PasswordResetResource> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authPasswordCreate(passwordResetResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {PasswordResetResource} passwordResetResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPasswordUpdate(passwordResetResource: PasswordResetResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PasswordResetResource> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authPasswordUpdate(passwordResetResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {PasswordReminderResource} passwordReminderResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPasswordreminderCreate(passwordReminderResource: PasswordReminderResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PasswordReminderResource> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authPasswordreminderCreate(passwordReminderResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPermissionsIndex(limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PermissionResult> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authPermissionsIndex(limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenDelete(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authTokenDelete(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthResource> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authTokenGet(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {AuthResource} authResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenUpdate(authResource: AuthResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthResource> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authTokenUpdate(authResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} authId
         * @param {AuthResource} authResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUpdate(authId: string, authResource: AuthResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthResource> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authUpdate(authId, authResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {AuthResource} authResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authCreate(authResource: AuthResource, options?: any) {
            return AuthApiFp(configuration).authCreate(authResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} authId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDelete(authId: string, options?: any) {
            return AuthApiFp(configuration).authDelete(authId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} authId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authGet(authId: string, options?: any) {
            return AuthApiFp(configuration).authGet(authId, options)(fetch, basePath);
        },
        /**
         *
         * @param {PasswordResetResource} passwordResetResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPasswordCreate(passwordResetResource: PasswordResetResource, options?: any) {
            return AuthApiFp(configuration).authPasswordCreate(passwordResetResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {PasswordResetResource} passwordResetResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPasswordUpdate(passwordResetResource: PasswordResetResource, options?: any) {
            return AuthApiFp(configuration).authPasswordUpdate(passwordResetResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {PasswordReminderResource} passwordReminderResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPasswordreminderCreate(passwordReminderResource: PasswordReminderResource, options?: any) {
            return AuthApiFp(configuration).authPasswordreminderCreate(passwordReminderResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPermissionsIndex(limit?: number, page?: number, query?: string, options?: any) {
            return AuthApiFp(configuration).authPermissionsIndex(limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenDelete(options?: any) {
            return AuthApiFp(configuration).authTokenDelete(options)(fetch, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenGet(options?: any) {
            return AuthApiFp(configuration).authTokenGet(options)(fetch, basePath);
        },
        /**
         *
         * @param {AuthResource} authResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenUpdate(authResource: AuthResource, options?: any) {
            return AuthApiFp(configuration).authTokenUpdate(authResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} authId
         * @param {AuthResource} authResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUpdate(authId: string, authResource: AuthResource, options?: any) {
            return AuthApiFp(configuration).authUpdate(authId, authResource, options)(fetch, basePath);
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     *
     * @param {AuthResource} authResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authCreate(authResource: AuthResource, options?: any) {
        return AuthApiFp(this.configuration).authCreate(authResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} authId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authDelete(authId: string, options?: any) {
        return AuthApiFp(this.configuration).authDelete(authId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} authId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authGet(authId: string, options?: any) {
        return AuthApiFp(this.configuration).authGet(authId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {PasswordResetResource} passwordResetResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authPasswordCreate(passwordResetResource: PasswordResetResource, options?: any) {
        return AuthApiFp(this.configuration).authPasswordCreate(passwordResetResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {PasswordResetResource} passwordResetResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authPasswordUpdate(passwordResetResource: PasswordResetResource, options?: any) {
        return AuthApiFp(this.configuration).authPasswordUpdate(passwordResetResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {PasswordReminderResource} passwordReminderResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authPasswordreminderCreate(passwordReminderResource: PasswordReminderResource, options?: any) {
        return AuthApiFp(this.configuration).authPasswordreminderCreate(passwordReminderResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authPermissionsIndex(limit?: number, page?: number, query?: string, options?: any) {
        return AuthApiFp(this.configuration).authPermissionsIndex(limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authTokenDelete(options?: any) {
        return AuthApiFp(this.configuration).authTokenDelete(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authTokenGet(options?: any) {
        return AuthApiFp(this.configuration).authTokenGet(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {AuthResource} authResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authTokenUpdate(authResource: AuthResource, options?: any) {
        return AuthApiFp(this.configuration).authTokenUpdate(authResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} authId
     * @param {AuthResource} authResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authUpdate(authId: string, authResource: AuthResource, options?: any) {
        return AuthApiFp(this.configuration).authUpdate(authId, authResource, options)(this.fetch, this.basePath);
    }

}

/**
 * BusinessModelsApi - fetch parameter creator
 * @export
 */
export const BusinessModelsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {BusinessModelResource} businessModelResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessModelsCreate(businessModelResource: BusinessModelResource, options: any = {}): FetchArgs {
            // verify required parameter 'businessModelResource' is not null or undefined
            if (businessModelResource === null || businessModelResource === undefined) {
                throw new RequiredError('businessModelResource','Required parameter businessModelResource was null or undefined when calling businessModelsCreate.');
            }
            const localVarPath = `/business_models`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BusinessModelResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(businessModelResource || {}) : (businessModelResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} businessModelId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessModelsDelete(businessModelId: string, options: any = {}): FetchArgs {
            // verify required parameter 'businessModelId' is not null or undefined
            if (businessModelId === null || businessModelId === undefined) {
                throw new RequiredError('businessModelId','Required parameter businessModelId was null or undefined when calling businessModelsDelete.');
            }
            const localVarPath = `/business_models/{business_model_id}`
                .replace(`{${"business_model_id"}}`, encodeURIComponent(String(businessModelId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} businessModelId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessModelsGet(businessModelId: string, options: any = {}): FetchArgs {
            // verify required parameter 'businessModelId' is not null or undefined
            if (businessModelId === null || businessModelId === undefined) {
                throw new RequiredError('businessModelId','Required parameter businessModelId was null or undefined when calling businessModelsGet.');
            }
            const localVarPath = `/business_models/{business_model_id}`
                .replace(`{${"business_model_id"}}`, encodeURIComponent(String(businessModelId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [businessModelId]
         * @param {string} [identifier]
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessModelsIndex(businessModelId?: string, identifier?: string, geoCode?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/business_models`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (businessModelId !== undefined) {
                localVarQueryParameter['business_model_id'] = businessModelId;
            }

            if (identifier !== undefined) {
                localVarQueryParameter['identifier'] = identifier;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} businessModelId
         * @param {BusinessModelResource} businessModelResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessModelsUpdate(businessModelId: string, businessModelResource: BusinessModelResource, options: any = {}): FetchArgs {
            // verify required parameter 'businessModelId' is not null or undefined
            if (businessModelId === null || businessModelId === undefined) {
                throw new RequiredError('businessModelId','Required parameter businessModelId was null or undefined when calling businessModelsUpdate.');
            }
            // verify required parameter 'businessModelResource' is not null or undefined
            if (businessModelResource === null || businessModelResource === undefined) {
                throw new RequiredError('businessModelResource','Required parameter businessModelResource was null or undefined when calling businessModelsUpdate.');
            }
            const localVarPath = `/business_models/{business_model_id}`
                .replace(`{${"business_model_id"}}`, encodeURIComponent(String(businessModelId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BusinessModelResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(businessModelResource || {}) : (businessModelResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BusinessModelsApi - functional programming interface
 * @export
 */
export const BusinessModelsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {BusinessModelResource} businessModelResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessModelsCreate(businessModelResource: BusinessModelResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BusinessModelResource> {
            const localVarFetchArgs = BusinessModelsApiFetchParamCreator(configuration).businessModelsCreate(businessModelResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} businessModelId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessModelsDelete(businessModelId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = BusinessModelsApiFetchParamCreator(configuration).businessModelsDelete(businessModelId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} businessModelId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessModelsGet(businessModelId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BusinessModelResource> {
            const localVarFetchArgs = BusinessModelsApiFetchParamCreator(configuration).businessModelsGet(businessModelId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [businessModelId]
         * @param {string} [identifier]
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessModelsIndex(businessModelId?: string, identifier?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BusinessModelResult> {
            const localVarFetchArgs = BusinessModelsApiFetchParamCreator(configuration).businessModelsIndex(businessModelId, identifier, geoCode, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} businessModelId
         * @param {BusinessModelResource} businessModelResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessModelsUpdate(businessModelId: string, businessModelResource: BusinessModelResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BusinessModelResource> {
            const localVarFetchArgs = BusinessModelsApiFetchParamCreator(configuration).businessModelsUpdate(businessModelId, businessModelResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BusinessModelsApi - factory interface
 * @export
 */
export const BusinessModelsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {BusinessModelResource} businessModelResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessModelsCreate(businessModelResource: BusinessModelResource, options?: any) {
            return BusinessModelsApiFp(configuration).businessModelsCreate(businessModelResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} businessModelId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessModelsDelete(businessModelId: string, options?: any) {
            return BusinessModelsApiFp(configuration).businessModelsDelete(businessModelId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} businessModelId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessModelsGet(businessModelId: string, options?: any) {
            return BusinessModelsApiFp(configuration).businessModelsGet(businessModelId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [businessModelId]
         * @param {string} [identifier]
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessModelsIndex(businessModelId?: string, identifier?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
            return BusinessModelsApiFp(configuration).businessModelsIndex(businessModelId, identifier, geoCode, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} businessModelId
         * @param {BusinessModelResource} businessModelResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessModelsUpdate(businessModelId: string, businessModelResource: BusinessModelResource, options?: any) {
            return BusinessModelsApiFp(configuration).businessModelsUpdate(businessModelId, businessModelResource, options)(fetch, basePath);
        },
    };
};

/**
 * BusinessModelsApi - object-oriented interface
 * @export
 * @class BusinessModelsApi
 * @extends {BaseAPI}
 */
export class BusinessModelsApi extends BaseAPI {
    /**
     *
     * @param {BusinessModelResource} businessModelResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessModelsApi
     */
    public businessModelsCreate(businessModelResource: BusinessModelResource, options?: any) {
        return BusinessModelsApiFp(this.configuration).businessModelsCreate(businessModelResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} businessModelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessModelsApi
     */
    public businessModelsDelete(businessModelId: string, options?: any) {
        return BusinessModelsApiFp(this.configuration).businessModelsDelete(businessModelId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} businessModelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessModelsApi
     */
    public businessModelsGet(businessModelId: string, options?: any) {
        return BusinessModelsApiFp(this.configuration).businessModelsGet(businessModelId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [businessModelId]
     * @param {string} [identifier]
     * @param {string} [geoCode]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessModelsApi
     */
    public businessModelsIndex(businessModelId?: string, identifier?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
        return BusinessModelsApiFp(this.configuration).businessModelsIndex(businessModelId, identifier, geoCode, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} businessModelId
     * @param {BusinessModelResource} businessModelResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessModelsApi
     */
    public businessModelsUpdate(businessModelId: string, businessModelResource: BusinessModelResource, options?: any) {
        return BusinessModelsApiFp(this.configuration).businessModelsUpdate(businessModelId, businessModelResource, options)(this.fetch, this.basePath);
    }

}

/**
 * CashierproductsApi - fetch parameter creator
 * @export
 */
export const CashierproductsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashierproductsIndex(geoCode?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/cashierproducts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CashierproductsApi - functional programming interface
 * @export
 */
export const CashierproductsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashierproductsIndex(geoCode?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StripeProductResult> {
            const localVarFetchArgs = CashierproductsApiFetchParamCreator(configuration).cashierproductsIndex(geoCode, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CashierproductsApi - factory interface
 * @export
 */
export const CashierproductsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashierproductsIndex(geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
            return CashierproductsApiFp(configuration).cashierproductsIndex(geoCode, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * CashierproductsApi - object-oriented interface
 * @export
 * @class CashierproductsApi
 * @extends {BaseAPI}
 */
export class CashierproductsApi extends BaseAPI {
    /**
     *
     * @param {string} [geoCode]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashierproductsApi
     */
    public cashierproductsIndex(geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
        return CashierproductsApiFp(this.configuration).cashierproductsIndex(geoCode, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * CertificationsApi - fetch parameter creator
 * @export
 */
export const CertificationsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [geoCode]
         * @param {number} [industryId]
         * @param {boolean} [isSegmentable] Is the certification used for segmentation?
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        certificationsIndex(geoCode?: string, industryId?: number, isSegmentable?: boolean, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/certifications`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (industryId !== undefined) {
                localVarQueryParameter['industry_id'] = industryId;
            }

            if (isSegmentable !== undefined) {
                localVarQueryParameter['is_segmentable'] = isSegmentable;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CertificationsApi - functional programming interface
 * @export
 */
export const CertificationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [geoCode]
         * @param {number} [industryId]
         * @param {boolean} [isSegmentable] Is the certification used for segmentation?
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        certificationsIndex(geoCode?: string, industryId?: number, isSegmentable?: boolean, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CertificationResult> {
            const localVarFetchArgs = CertificationsApiFetchParamCreator(configuration).certificationsIndex(geoCode, industryId, isSegmentable, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CertificationsApi - factory interface
 * @export
 */
export const CertificationsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} [geoCode]
         * @param {number} [industryId]
         * @param {boolean} [isSegmentable] Is the certification used for segmentation?
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        certificationsIndex(geoCode?: string, industryId?: number, isSegmentable?: boolean, limit?: number, page?: number, query?: string, options?: any) {
            return CertificationsApiFp(configuration).certificationsIndex(geoCode, industryId, isSegmentable, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * CertificationsApi - object-oriented interface
 * @export
 * @class CertificationsApi
 * @extends {BaseAPI}
 */
export class CertificationsApi extends BaseAPI {
    /**
     *
     * @param {string} [geoCode]
     * @param {number} [industryId]
     * @param {boolean} [isSegmentable] Is the certification used for segmentation?
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificationsApi
     */
    public certificationsIndex(geoCode?: string, industryId?: number, isSegmentable?: boolean, limit?: number, page?: number, query?: string, options?: any) {
        return CertificationsApiFp(this.configuration).certificationsIndex(geoCode, industryId, isSegmentable, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * ChargesApi - fetch parameter creator
 * @export
 */
export const ChargesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} [voucherId] Voucher id.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chargesIndex(voucherId?: number, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/charges`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (voucherId !== undefined) {
                localVarQueryParameter['voucher_id'] = voucherId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChargesApi - functional programming interface
 * @export
 */
export const ChargesApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} [voucherId] Voucher id.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chargesIndex(voucherId?: number, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ChargeResult> {
            const localVarFetchArgs = ChargesApiFetchParamCreator(configuration).chargesIndex(voucherId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ChargesApi - factory interface
 * @export
 */
export const ChargesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {number} [voucherId] Voucher id.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chargesIndex(voucherId?: number, limit?: number, page?: number, query?: string, options?: any) {
            return ChargesApiFp(configuration).chargesIndex(voucherId, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * ChargesApi - object-oriented interface
 * @export
 * @class ChargesApi
 * @extends {BaseAPI}
 */
export class ChargesApi extends BaseAPI {
    /**
     *
     * @param {number} [voucherId] Voucher id.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChargesApi
     */
    public chargesIndex(voucherId?: number, limit?: number, page?: number, query?: string, options?: any) {
        return ChargesApiFp(this.configuration).chargesIndex(voucherId, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * CheckoutsApi - fetch parameter creator
 * @export
 */
export const CheckoutsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {CheckoutResource} checkoutResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutsCreate(checkoutResource: CheckoutResource, options: any = {}): FetchArgs {
            // verify required parameter 'checkoutResource' is not null or undefined
            if (checkoutResource === null || checkoutResource === undefined) {
                throw new RequiredError('checkoutResource','Required parameter checkoutResource was null or undefined when calling checkoutsCreate.');
            }
            const localVarPath = `/checkouts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CheckoutResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(checkoutResource || {}) : (checkoutResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} checkoutId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutsGet(checkoutId: string, options: any = {}): FetchArgs {
            // verify required parameter 'checkoutId' is not null or undefined
            if (checkoutId === null || checkoutId === undefined) {
                throw new RequiredError('checkoutId','Required parameter checkoutId was null or undefined when calling checkoutsGet.');
            }
            const localVarPath = `/checkouts/{checkout_id}`
                .replace(`{${"checkout_id"}}`, encodeURIComponent(String(checkoutId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [checkoutId] Id of checkouts to retrieve
         * @param {string} [clientId] Clients
         * @param {string} [partnerId] Partners
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutsIndex(checkoutId?: string, clientId?: string, partnerId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/checkouts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (checkoutId !== undefined) {
                localVarQueryParameter['checkout_id'] = checkoutId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} checkoutId
         * @param {CheckoutResource} checkoutResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutsUpdate(checkoutId: string, checkoutResource: CheckoutResource, options: any = {}): FetchArgs {
            // verify required parameter 'checkoutId' is not null or undefined
            if (checkoutId === null || checkoutId === undefined) {
                throw new RequiredError('checkoutId','Required parameter checkoutId was null or undefined when calling checkoutsUpdate.');
            }
            // verify required parameter 'checkoutResource' is not null or undefined
            if (checkoutResource === null || checkoutResource === undefined) {
                throw new RequiredError('checkoutResource','Required parameter checkoutResource was null or undefined when calling checkoutsUpdate.');
            }
            const localVarPath = `/checkouts/{checkout_id}`
                .replace(`{${"checkout_id"}}`, encodeURIComponent(String(checkoutId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CheckoutResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(checkoutResource || {}) : (checkoutResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CheckoutsApi - functional programming interface
 * @export
 */
export const CheckoutsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {CheckoutResource} checkoutResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutsCreate(checkoutResource: CheckoutResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CheckoutResource> {
            const localVarFetchArgs = CheckoutsApiFetchParamCreator(configuration).checkoutsCreate(checkoutResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} checkoutId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutsGet(checkoutId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CheckoutResource> {
            const localVarFetchArgs = CheckoutsApiFetchParamCreator(configuration).checkoutsGet(checkoutId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [checkoutId] Id of checkouts to retrieve
         * @param {string} [clientId] Clients
         * @param {string} [partnerId] Partners
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutsIndex(checkoutId?: string, clientId?: string, partnerId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CheckoutResult> {
            const localVarFetchArgs = CheckoutsApiFetchParamCreator(configuration).checkoutsIndex(checkoutId, clientId, partnerId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} checkoutId
         * @param {CheckoutResource} checkoutResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutsUpdate(checkoutId: string, checkoutResource: CheckoutResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CheckoutResource> {
            const localVarFetchArgs = CheckoutsApiFetchParamCreator(configuration).checkoutsUpdate(checkoutId, checkoutResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CheckoutsApi - factory interface
 * @export
 */
export const CheckoutsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {CheckoutResource} checkoutResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutsCreate(checkoutResource: CheckoutResource, options?: any) {
            return CheckoutsApiFp(configuration).checkoutsCreate(checkoutResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} checkoutId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutsGet(checkoutId: string, options?: any) {
            return CheckoutsApiFp(configuration).checkoutsGet(checkoutId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [checkoutId] Id of checkouts to retrieve
         * @param {string} [clientId] Clients
         * @param {string} [partnerId] Partners
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutsIndex(checkoutId?: string, clientId?: string, partnerId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return CheckoutsApiFp(configuration).checkoutsIndex(checkoutId, clientId, partnerId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} checkoutId
         * @param {CheckoutResource} checkoutResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutsUpdate(checkoutId: string, checkoutResource: CheckoutResource, options?: any) {
            return CheckoutsApiFp(configuration).checkoutsUpdate(checkoutId, checkoutResource, options)(fetch, basePath);
        },
    };
};

/**
 * CheckoutsApi - object-oriented interface
 * @export
 * @class CheckoutsApi
 * @extends {BaseAPI}
 */
export class CheckoutsApi extends BaseAPI {
    /**
     *
     * @param {CheckoutResource} checkoutResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutsApi
     */
    public checkoutsCreate(checkoutResource: CheckoutResource, options?: any) {
        return CheckoutsApiFp(this.configuration).checkoutsCreate(checkoutResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} checkoutId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutsApi
     */
    public checkoutsGet(checkoutId: string, options?: any) {
        return CheckoutsApiFp(this.configuration).checkoutsGet(checkoutId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [checkoutId] Id of checkouts to retrieve
     * @param {string} [clientId] Clients
     * @param {string} [partnerId] Partners
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutsApi
     */
    public checkoutsIndex(checkoutId?: string, clientId?: string, partnerId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return CheckoutsApiFp(this.configuration).checkoutsIndex(checkoutId, clientId, partnerId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} checkoutId
     * @param {CheckoutResource} checkoutResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutsApi
     */
    public checkoutsUpdate(checkoutId: string, checkoutResource: CheckoutResource, options?: any) {
        return CheckoutsApiFp(this.configuration).checkoutsUpdate(checkoutId, checkoutResource, options)(this.fetch, this.basePath);
    }

}

/**
 * ClientsApi - fetch parameter creator
 * @export
 */
export const ClientsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} clientId
         * @param {ClientAllocationSuggestionActionResource} clientAllocationSuggestionActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsAllocationsuggestionsActionsCreate(clientId: string, clientAllocationSuggestionActionResource: ClientAllocationSuggestionActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling clientsAllocationsuggestionsActionsCreate.');
            }
            // verify required parameter 'clientAllocationSuggestionActionResource' is not null or undefined
            if (clientAllocationSuggestionActionResource === null || clientAllocationSuggestionActionResource === undefined) {
                throw new RequiredError('clientAllocationSuggestionActionResource','Required parameter clientAllocationSuggestionActionResource was null or undefined when calling clientsAllocationsuggestionsActionsCreate.');
            }
            const localVarPath = `/clients/{client_id}/allocationsuggestions/actions`
                .replace(`{${"client_id"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ClientAllocationSuggestionActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(clientAllocationSuggestionActionResource || {}) : (clientAllocationSuggestionActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} clientId
         * @param {number} [leadId]
         * @param {boolean} [isAccepted]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsAllocationsuggestionsIndex(clientId: string, leadId?: number, isAccepted?: boolean, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling clientsAllocationsuggestionsIndex.');
            }
            const localVarPath = `/clients/{client_id}/allocationsuggestions`
                .replace(`{${"client_id"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (leadId !== undefined) {
                localVarQueryParameter['lead_id'] = leadId;
            }

            if (isAccepted !== undefined) {
                localVarQueryParameter['is_accepted'] = isAccepted;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} clientId
         * @param {BrowserPushSubscriptionResource} browserPushSubscriptionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsBrowserpushsubscriptionsCreate(clientId: string, browserPushSubscriptionResource: BrowserPushSubscriptionResource, options: any = {}): FetchArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling clientsBrowserpushsubscriptionsCreate.');
            }
            // verify required parameter 'browserPushSubscriptionResource' is not null or undefined
            if (browserPushSubscriptionResource === null || browserPushSubscriptionResource === undefined) {
                throw new RequiredError('browserPushSubscriptionResource','Required parameter browserPushSubscriptionResource was null or undefined when calling clientsBrowserpushsubscriptionsCreate.');
            }
            const localVarPath = `/clients/{client_id}/browserpushsubscriptions`
                .replace(`{${"client_id"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BrowserPushSubscriptionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(browserPushSubscriptionResource || {}) : (browserPushSubscriptionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} clientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsGet(clientId: string, options: any = {}): FetchArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling clientsGet.');
            }
            const localVarPath = `/clients/{client_id}`
                .replace(`{${"client_id"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} clientId
         * @param {ClientResource} clientResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsUpdate(clientId: string, clientResource: ClientResource, options: any = {}): FetchArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling clientsUpdate.');
            }
            // verify required parameter 'clientResource' is not null or undefined
            if (clientResource === null || clientResource === undefined) {
                throw new RequiredError('clientResource','Required parameter clientResource was null or undefined when calling clientsUpdate.');
            }
            const localVarPath = `/clients/{client_id}`
                .replace(`{${"client_id"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ClientResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(clientResource || {}) : (clientResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientsApi - functional programming interface
 * @export
 */
export const ClientsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} clientId
         * @param {ClientAllocationSuggestionActionResource} clientAllocationSuggestionActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsAllocationsuggestionsActionsCreate(clientId: string, clientAllocationSuggestionActionResource: ClientAllocationSuggestionActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClientAllocationSuggestionActionResource> {
            const localVarFetchArgs = ClientsApiFetchParamCreator(configuration).clientsAllocationsuggestionsActionsCreate(clientId, clientAllocationSuggestionActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} clientId
         * @param {number} [leadId]
         * @param {boolean} [isAccepted]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsAllocationsuggestionsIndex(clientId: string, leadId?: number, isAccepted?: boolean, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClientAllocationSuggestionResult> {
            const localVarFetchArgs = ClientsApiFetchParamCreator(configuration).clientsAllocationsuggestionsIndex(clientId, leadId, isAccepted, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} clientId
         * @param {BrowserPushSubscriptionResource} browserPushSubscriptionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsBrowserpushsubscriptionsCreate(clientId: string, browserPushSubscriptionResource: BrowserPushSubscriptionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BrowserPushSubscriptionResource> {
            const localVarFetchArgs = ClientsApiFetchParamCreator(configuration).clientsBrowserpushsubscriptionsCreate(clientId, browserPushSubscriptionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} clientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsGet(clientId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClientResource> {
            const localVarFetchArgs = ClientsApiFetchParamCreator(configuration).clientsGet(clientId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} clientId
         * @param {ClientResource} clientResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsUpdate(clientId: string, clientResource: ClientResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClientResource> {
            const localVarFetchArgs = ClientsApiFetchParamCreator(configuration).clientsUpdate(clientId, clientResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ClientsApi - factory interface
 * @export
 */
export const ClientsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} clientId
         * @param {ClientAllocationSuggestionActionResource} clientAllocationSuggestionActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsAllocationsuggestionsActionsCreate(clientId: string, clientAllocationSuggestionActionResource: ClientAllocationSuggestionActionResource, options?: any) {
            return ClientsApiFp(configuration).clientsAllocationsuggestionsActionsCreate(clientId, clientAllocationSuggestionActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} clientId
         * @param {number} [leadId]
         * @param {boolean} [isAccepted]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsAllocationsuggestionsIndex(clientId: string, leadId?: number, isAccepted?: boolean, limit?: number, page?: number, query?: string, options?: any) {
            return ClientsApiFp(configuration).clientsAllocationsuggestionsIndex(clientId, leadId, isAccepted, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} clientId
         * @param {BrowserPushSubscriptionResource} browserPushSubscriptionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsBrowserpushsubscriptionsCreate(clientId: string, browserPushSubscriptionResource: BrowserPushSubscriptionResource, options?: any) {
            return ClientsApiFp(configuration).clientsBrowserpushsubscriptionsCreate(clientId, browserPushSubscriptionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} clientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsGet(clientId: string, options?: any) {
            return ClientsApiFp(configuration).clientsGet(clientId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} clientId
         * @param {ClientResource} clientResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsUpdate(clientId: string, clientResource: ClientResource, options?: any) {
            return ClientsApiFp(configuration).clientsUpdate(clientId, clientResource, options)(fetch, basePath);
        },
    };
};

/**
 * ClientsApi - object-oriented interface
 * @export
 * @class ClientsApi
 * @extends {BaseAPI}
 */
export class ClientsApi extends BaseAPI {
    /**
     *
     * @param {string} clientId
     * @param {ClientAllocationSuggestionActionResource} clientAllocationSuggestionActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsAllocationsuggestionsActionsCreate(clientId: string, clientAllocationSuggestionActionResource: ClientAllocationSuggestionActionResource, options?: any) {
        return ClientsApiFp(this.configuration).clientsAllocationsuggestionsActionsCreate(clientId, clientAllocationSuggestionActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} clientId
     * @param {number} [leadId]
     * @param {boolean} [isAccepted]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsAllocationsuggestionsIndex(clientId: string, leadId?: number, isAccepted?: boolean, limit?: number, page?: number, query?: string, options?: any) {
        return ClientsApiFp(this.configuration).clientsAllocationsuggestionsIndex(clientId, leadId, isAccepted, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} clientId
     * @param {BrowserPushSubscriptionResource} browserPushSubscriptionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsBrowserpushsubscriptionsCreate(clientId: string, browserPushSubscriptionResource: BrowserPushSubscriptionResource, options?: any) {
        return ClientsApiFp(this.configuration).clientsBrowserpushsubscriptionsCreate(clientId, browserPushSubscriptionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} clientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsGet(clientId: string, options?: any) {
        return ClientsApiFp(this.configuration).clientsGet(clientId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} clientId
     * @param {ClientResource} clientResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsUpdate(clientId: string, clientResource: ClientResource, options?: any) {
        return ClientsApiFp(this.configuration).clientsUpdate(clientId, clientResource, options)(this.fetch, this.basePath);
    }

}

/**
 * ClientsignupsApi - fetch parameter creator
 * @export
 */
export const ClientsignupsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} clientSignUpId
         * @param {ClientSignUpActionResource} clientSignUpActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsignupsActionsCreate(clientSignUpId: string, clientSignUpActionResource: ClientSignUpActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'clientSignUpId' is not null or undefined
            if (clientSignUpId === null || clientSignUpId === undefined) {
                throw new RequiredError('clientSignUpId','Required parameter clientSignUpId was null or undefined when calling clientsignupsActionsCreate.');
            }
            // verify required parameter 'clientSignUpActionResource' is not null or undefined
            if (clientSignUpActionResource === null || clientSignUpActionResource === undefined) {
                throw new RequiredError('clientSignUpActionResource','Required parameter clientSignUpActionResource was null or undefined when calling clientsignupsActionsCreate.');
            }
            const localVarPath = `/clientsignups/{client_sign_up_id}/actions`
                .replace(`{${"client_sign_up_id"}}`, encodeURIComponent(String(clientSignUpId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ClientSignUpActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(clientSignUpActionResource || {}) : (clientSignUpActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {ClientSignUpResource} clientSignUpResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsignupsCreate(clientSignUpResource: ClientSignUpResource, options: any = {}): FetchArgs {
            // verify required parameter 'clientSignUpResource' is not null or undefined
            if (clientSignUpResource === null || clientSignUpResource === undefined) {
                throw new RequiredError('clientSignUpResource','Required parameter clientSignUpResource was null or undefined when calling clientsignupsCreate.');
            }
            const localVarPath = `/clientsignups`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ClientSignUpResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(clientSignUpResource || {}) : (clientSignUpResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientsignupsApi - functional programming interface
 * @export
 */
export const ClientsignupsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} clientSignUpId
         * @param {ClientSignUpActionResource} clientSignUpActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsignupsActionsCreate(clientSignUpId: string, clientSignUpActionResource: ClientSignUpActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClientSignUpResource> {
            const localVarFetchArgs = ClientsignupsApiFetchParamCreator(configuration).clientsignupsActionsCreate(clientSignUpId, clientSignUpActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {ClientSignUpResource} clientSignUpResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsignupsCreate(clientSignUpResource: ClientSignUpResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClientSignUpResource> {
            const localVarFetchArgs = ClientsignupsApiFetchParamCreator(configuration).clientsignupsCreate(clientSignUpResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ClientsignupsApi - factory interface
 * @export
 */
export const ClientsignupsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} clientSignUpId
         * @param {ClientSignUpActionResource} clientSignUpActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsignupsActionsCreate(clientSignUpId: string, clientSignUpActionResource: ClientSignUpActionResource, options?: any) {
            return ClientsignupsApiFp(configuration).clientsignupsActionsCreate(clientSignUpId, clientSignUpActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {ClientSignUpResource} clientSignUpResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsignupsCreate(clientSignUpResource: ClientSignUpResource, options?: any) {
            return ClientsignupsApiFp(configuration).clientsignupsCreate(clientSignUpResource, options)(fetch, basePath);
        },
    };
};

/**
 * ClientsignupsApi - object-oriented interface
 * @export
 * @class ClientsignupsApi
 * @extends {BaseAPI}
 */
export class ClientsignupsApi extends BaseAPI {
    /**
     *
     * @param {string} clientSignUpId
     * @param {ClientSignUpActionResource} clientSignUpActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsignupsApi
     */
    public clientsignupsActionsCreate(clientSignUpId: string, clientSignUpActionResource: ClientSignUpActionResource, options?: any) {
        return ClientsignupsApiFp(this.configuration).clientsignupsActionsCreate(clientSignUpId, clientSignUpActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {ClientSignUpResource} clientSignUpResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsignupsApi
     */
    public clientsignupsCreate(clientSignUpResource: ClientSignUpResource, options?: any) {
        return ClientsignupsApiFp(this.configuration).clientsignupsCreate(clientSignUpResource, options)(this.fetch, this.basePath);
    }

}

/**
 * CompanylookupsApi - fetch parameter creator
 * @export
 */
export const CompanylookupsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {CompanyLookUpActionResource} companyLookUpActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companylookupsActionsCreate(companyLookUpActionResource: CompanyLookUpActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'companyLookUpActionResource' is not null or undefined
            if (companyLookUpActionResource === null || companyLookUpActionResource === undefined) {
                throw new RequiredError('companyLookUpActionResource','Required parameter companyLookUpActionResource was null or undefined when calling companylookupsActionsCreate.');
            }
            const localVarPath = `/companylookups/actions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CompanyLookUpActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(companyLookUpActionResource || {}) : (companyLookUpActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanylookupsApi - functional programming interface
 * @export
 */
export const CompanylookupsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {CompanyLookUpActionResource} companyLookUpActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companylookupsActionsCreate(companyLookUpActionResource: CompanyLookUpActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompanyResource> {
            const localVarFetchArgs = CompanylookupsApiFetchParamCreator(configuration).companylookupsActionsCreate(companyLookUpActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CompanylookupsApi - factory interface
 * @export
 */
export const CompanylookupsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {CompanyLookUpActionResource} companyLookUpActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companylookupsActionsCreate(companyLookUpActionResource: CompanyLookUpActionResource, options?: any) {
            return CompanylookupsApiFp(configuration).companylookupsActionsCreate(companyLookUpActionResource, options)(fetch, basePath);
        },
    };
};

/**
 * CompanylookupsApi - object-oriented interface
 * @export
 * @class CompanylookupsApi
 * @extends {BaseAPI}
 */
export class CompanylookupsApi extends BaseAPI {
    /**
     *
     * @param {CompanyLookUpActionResource} companyLookUpActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanylookupsApi
     */
    public companylookupsActionsCreate(companyLookUpActionResource: CompanyLookUpActionResource, options?: any) {
        return CompanylookupsApiFp(this.configuration).companylookupsActionsCreate(companyLookUpActionResource, options)(this.fetch, this.basePath);
    }

}

/**
 * CompanysizesApi - fetch parameter creator
 * @export
 */
export const CompanysizesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [geoCode] Filter by geo code.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companysizesIndex(geoCode?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/companysizes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanysizesApi - functional programming interface
 * @export
 */
export const CompanysizesApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [geoCode] Filter by geo code.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companysizesIndex(geoCode?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompanySizeResult> {
            const localVarFetchArgs = CompanysizesApiFetchParamCreator(configuration).companysizesIndex(geoCode, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CompanysizesApi - factory interface
 * @export
 */
export const CompanysizesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} [geoCode] Filter by geo code.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companysizesIndex(geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
            return CompanysizesApiFp(configuration).companysizesIndex(geoCode, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * CompanysizesApi - object-oriented interface
 * @export
 * @class CompanysizesApi
 * @extends {BaseAPI}
 */
export class CompanysizesApi extends BaseAPI {
    /**
     *
     * @param {string} [geoCode] Filter by geo code.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanysizesApi
     */
    public companysizesIndex(geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
        return CompanysizesApiFp(this.configuration).companysizesIndex(geoCode, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * CompanytypesApi - fetch parameter creator
 * @export
 */
export const CompanytypesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [geoCode] Filter by geo code.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companytypesIndex(geoCode?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/companytypes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanytypesApi - functional programming interface
 * @export
 */
export const CompanytypesApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [geoCode] Filter by geo code.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companytypesIndex(geoCode?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompanyTypeResult> {
            const localVarFetchArgs = CompanytypesApiFetchParamCreator(configuration).companytypesIndex(geoCode, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CompanytypesApi - factory interface
 * @export
 */
export const CompanytypesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} [geoCode] Filter by geo code.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companytypesIndex(geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
            return CompanytypesApiFp(configuration).companytypesIndex(geoCode, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * CompanytypesApi - object-oriented interface
 * @export
 * @class CompanytypesApi
 * @extends {BaseAPI}
 */
export class CompanytypesApi extends BaseAPI {
    /**
     *
     * @param {string} [geoCode] Filter by geo code.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanytypesApi
     */
    public companytypesIndex(geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
        return CompanytypesApiFp(this.configuration).companytypesIndex(geoCode, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * ContactarchivesApi - fetch parameter creator
 * @export
 */
export const ContactarchivesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} contactArchiveId
         * @param {ContactArchiveActionResource} contactArchiveActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactarchivesActionsCreate(contactArchiveId: string, contactArchiveActionResource: ContactArchiveActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'contactArchiveId' is not null or undefined
            if (contactArchiveId === null || contactArchiveId === undefined) {
                throw new RequiredError('contactArchiveId','Required parameter contactArchiveId was null or undefined when calling contactarchivesActionsCreate.');
            }
            // verify required parameter 'contactArchiveActionResource' is not null or undefined
            if (contactArchiveActionResource === null || contactArchiveActionResource === undefined) {
                throw new RequiredError('contactArchiveActionResource','Required parameter contactArchiveActionResource was null or undefined when calling contactarchivesActionsCreate.');
            }
            const localVarPath = `/contactarchives/{contact_archive_id}/actions`
                .replace(`{${"contact_archive_id"}}`, encodeURIComponent(String(contactArchiveId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ContactArchiveActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(contactArchiveActionResource || {}) : (contactArchiveActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [receiverPartnerId] Id of partner in correspondence.
         * @param {number} [receiverLeadId] Id of lead in correspondence
         * @param {number} [contactArchiveId] Id of contact archive item
         * @param {'sent_at' | 'ascending' | 'descending'} [sort] Sort by.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactarchivesIndex(receiverPartnerId?: number, receiverLeadId?: number, contactArchiveId?: number, sort?: 'sent_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/contactarchives`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (receiverPartnerId !== undefined) {
                localVarQueryParameter['receiver_partner_id'] = receiverPartnerId;
            }

            if (receiverLeadId !== undefined) {
                localVarQueryParameter['receiver_lead_id'] = receiverLeadId;
            }

            if (contactArchiveId !== undefined) {
                localVarQueryParameter['contact_archive_id'] = contactArchiveId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactarchivesApi - functional programming interface
 * @export
 */
export const ContactarchivesApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} contactArchiveId
         * @param {ContactArchiveActionResource} contactArchiveActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactarchivesActionsCreate(contactArchiveId: string, contactArchiveActionResource: ContactArchiveActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ContactArchiveResource> {
            const localVarFetchArgs = ContactarchivesApiFetchParamCreator(configuration).contactarchivesActionsCreate(contactArchiveId, contactArchiveActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} [receiverPartnerId] Id of partner in correspondence.
         * @param {number} [receiverLeadId] Id of lead in correspondence
         * @param {number} [contactArchiveId] Id of contact archive item
         * @param {'sent_at' | 'ascending' | 'descending'} [sort] Sort by.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactarchivesIndex(receiverPartnerId?: number, receiverLeadId?: number, contactArchiveId?: number, sort?: 'sent_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ContactArchiveResult> {
            const localVarFetchArgs = ContactarchivesApiFetchParamCreator(configuration).contactarchivesIndex(receiverPartnerId, receiverLeadId, contactArchiveId, sort, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ContactarchivesApi - factory interface
 * @export
 */
export const ContactarchivesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} contactArchiveId
         * @param {ContactArchiveActionResource} contactArchiveActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactarchivesActionsCreate(contactArchiveId: string, contactArchiveActionResource: ContactArchiveActionResource, options?: any) {
            return ContactarchivesApiFp(configuration).contactarchivesActionsCreate(contactArchiveId, contactArchiveActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} [receiverPartnerId] Id of partner in correspondence.
         * @param {number} [receiverLeadId] Id of lead in correspondence
         * @param {number} [contactArchiveId] Id of contact archive item
         * @param {'sent_at' | 'ascending' | 'descending'} [sort] Sort by.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactarchivesIndex(receiverPartnerId?: number, receiverLeadId?: number, contactArchiveId?: number, sort?: 'sent_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
            return ContactarchivesApiFp(configuration).contactarchivesIndex(receiverPartnerId, receiverLeadId, contactArchiveId, sort, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * ContactarchivesApi - object-oriented interface
 * @export
 * @class ContactarchivesApi
 * @extends {BaseAPI}
 */
export class ContactarchivesApi extends BaseAPI {
    /**
     *
     * @param {string} contactArchiveId
     * @param {ContactArchiveActionResource} contactArchiveActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactarchivesApi
     */
    public contactarchivesActionsCreate(contactArchiveId: string, contactArchiveActionResource: ContactArchiveActionResource, options?: any) {
        return ContactarchivesApiFp(this.configuration).contactarchivesActionsCreate(contactArchiveId, contactArchiveActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} [receiverPartnerId] Id of partner in correspondence.
     * @param {number} [receiverLeadId] Id of lead in correspondence
     * @param {number} [contactArchiveId] Id of contact archive item
     * @param {'sent_at' | 'ascending' | 'descending'} [sort] Sort by.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactarchivesApi
     */
    public contactarchivesIndex(receiverPartnerId?: number, receiverLeadId?: number, contactArchiveId?: number, sort?: 'sent_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
        return ContactarchivesApiFp(this.configuration).contactarchivesIndex(receiverPartnerId, receiverLeadId, contactArchiveId, sort, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * ContentsApi - fetch parameter creator
 * @export
 */
export const ContentsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [pageId] List of page identification(ID).
         * @param {string} [pageType] Filter pages by their type
         * @param {string} [pageTypeId] Filter pages by their type id
         * @param {string} [letter] Filter pages by first letter
         * @param {string} [geoCode] Geo Code for the Content Page
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentsPagesAggregationsIndex(pageId?: string, pageType?: string, pageTypeId?: string, letter?: string, geoCode?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/contents/pages/aggregations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (pageId !== undefined) {
                localVarQueryParameter['page_id'] = pageId;
            }

            if (pageType !== undefined) {
                localVarQueryParameter['page_type'] = pageType;
            }

            if (pageTypeId !== undefined) {
                localVarQueryParameter['page_type_id'] = pageTypeId;
            }

            if (letter !== undefined) {
                localVarQueryParameter['letter'] = letter;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} contentId
         * @param {string} [pageId] List of page identification(ID).
         * @param {string} [pageType] Filter pages by their type
         * @param {string} [pageTypeId] Filter pages by their type id
         * @param {string} [letter] Filter pages by first letter
         * @param {string} [geoCode] Geo Code for the Content Page
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentsPagesAggregationsIndex_1(contentId: string, pageId?: string, pageType?: string, pageTypeId?: string, letter?: string, geoCode?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'contentId' is not null or undefined
            if (contentId === null || contentId === undefined) {
                throw new RequiredError('contentId','Required parameter contentId was null or undefined when calling contentsPagesAggregationsIndex_1.');
            }
            const localVarPath = `/contents/{content_id}/pages/aggregations`
                .replace(`{${"content_id"}}`, encodeURIComponent(String(contentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (pageId !== undefined) {
                localVarQueryParameter['page_id'] = pageId;
            }

            if (pageType !== undefined) {
                localVarQueryParameter['page_type'] = pageType;
            }

            if (pageTypeId !== undefined) {
                localVarQueryParameter['page_type_id'] = pageTypeId;
            }

            if (letter !== undefined) {
                localVarQueryParameter['letter'] = letter;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} contentPageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentsPagesGet(contentPageId: string, options: any = {}): FetchArgs {
            // verify required parameter 'contentPageId' is not null or undefined
            if (contentPageId === null || contentPageId === undefined) {
                throw new RequiredError('contentPageId','Required parameter contentPageId was null or undefined when calling contentsPagesGet.');
            }
            const localVarPath = `/contents/pages/{content_page_id}`
                .replace(`{${"content_page_id"}}`, encodeURIComponent(String(contentPageId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} contentId
         * @param {string} contentPageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentsPagesGet_2(contentId: string, contentPageId: string, options: any = {}): FetchArgs {
            // verify required parameter 'contentId' is not null or undefined
            if (contentId === null || contentId === undefined) {
                throw new RequiredError('contentId','Required parameter contentId was null or undefined when calling contentsPagesGet_2.');
            }
            // verify required parameter 'contentPageId' is not null or undefined
            if (contentPageId === null || contentPageId === undefined) {
                throw new RequiredError('contentPageId','Required parameter contentPageId was null or undefined when calling contentsPagesGet_2.');
            }
            const localVarPath = `/contents/{content_id}/pages/{content_page_id}`
                .replace(`{${"content_id"}}`, encodeURIComponent(String(contentId)))
                .replace(`{${"content_page_id"}}`, encodeURIComponent(String(contentPageId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [pageId] List of page identification(ID).
         * @param {string} [pageType] Filter pages by their type
         * @param {string} [pageTypeId] Filter pages by their type id
         * @param {string} [letter] Filter pages by first letter
         * @param {string} [geoCode] Geo Code for the Content Page
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentsPagesIndex(pageId?: string, pageType?: string, pageTypeId?: string, letter?: string, geoCode?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/contents/pages`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (pageId !== undefined) {
                localVarQueryParameter['page_id'] = pageId;
            }

            if (pageType !== undefined) {
                localVarQueryParameter['page_type'] = pageType;
            }

            if (pageTypeId !== undefined) {
                localVarQueryParameter['page_type_id'] = pageTypeId;
            }

            if (letter !== undefined) {
                localVarQueryParameter['letter'] = letter;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [pageTypeId] List of page type identification(ID).
         * @param {string} [geoCode] geo
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentsPagesTypesIndex(pageTypeId?: string, geoCode?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/contents/pages/types`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (pageTypeId !== undefined) {
                localVarQueryParameter['page_type_id'] = pageTypeId;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentsApi - functional programming interface
 * @export
 */
export const ContentsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [pageId] List of page identification(ID).
         * @param {string} [pageType] Filter pages by their type
         * @param {string} [pageTypeId] Filter pages by their type id
         * @param {string} [letter] Filter pages by first letter
         * @param {string} [geoCode] Geo Code for the Content Page
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentsPagesAggregationsIndex(pageId?: string, pageType?: string, pageTypeId?: string, letter?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AggregationResult> {
            const localVarFetchArgs = ContentsApiFetchParamCreator(configuration).contentsPagesAggregationsIndex(pageId, pageType, pageTypeId, letter, geoCode, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} contentId
         * @param {string} [pageId] List of page identification(ID).
         * @param {string} [pageType] Filter pages by their type
         * @param {string} [pageTypeId] Filter pages by their type id
         * @param {string} [letter] Filter pages by first letter
         * @param {string} [geoCode] Geo Code for the Content Page
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentsPagesAggregationsIndex_1(contentId: string, pageId?: string, pageType?: string, pageTypeId?: string, letter?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AggregationResult> {
            const localVarFetchArgs = ContentsApiFetchParamCreator(configuration).contentsPagesAggregationsIndex_1(contentId, pageId, pageType, pageTypeId, letter, geoCode, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} contentPageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentsPagesGet(contentPageId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ContentPageResource> {
            const localVarFetchArgs = ContentsApiFetchParamCreator(configuration).contentsPagesGet(contentPageId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} contentId
         * @param {string} contentPageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentsPagesGet_2(contentId: string, contentPageId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ContentPageResource> {
            const localVarFetchArgs = ContentsApiFetchParamCreator(configuration).contentsPagesGet_2(contentId, contentPageId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [pageId] List of page identification(ID).
         * @param {string} [pageType] Filter pages by their type
         * @param {string} [pageTypeId] Filter pages by their type id
         * @param {string} [letter] Filter pages by first letter
         * @param {string} [geoCode] Geo Code for the Content Page
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentsPagesIndex(pageId?: string, pageType?: string, pageTypeId?: string, letter?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ContentPageResult> {
            const localVarFetchArgs = ContentsApiFetchParamCreator(configuration).contentsPagesIndex(pageId, pageType, pageTypeId, letter, geoCode, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [pageTypeId] List of page type identification(ID).
         * @param {string} [geoCode] geo
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentsPagesTypesIndex(pageTypeId?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ContentPageTypeResult> {
            const localVarFetchArgs = ContentsApiFetchParamCreator(configuration).contentsPagesTypesIndex(pageTypeId, geoCode, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ContentsApi - factory interface
 * @export
 */
export const ContentsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} [pageId] List of page identification(ID).
         * @param {string} [pageType] Filter pages by their type
         * @param {string} [pageTypeId] Filter pages by their type id
         * @param {string} [letter] Filter pages by first letter
         * @param {string} [geoCode] Geo Code for the Content Page
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentsPagesAggregationsIndex(pageId?: string, pageType?: string, pageTypeId?: string, letter?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
            return ContentsApiFp(configuration).contentsPagesAggregationsIndex(pageId, pageType, pageTypeId, letter, geoCode, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} contentId
         * @param {string} [pageId] List of page identification(ID).
         * @param {string} [pageType] Filter pages by their type
         * @param {string} [pageTypeId] Filter pages by their type id
         * @param {string} [letter] Filter pages by first letter
         * @param {string} [geoCode] Geo Code for the Content Page
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentsPagesAggregationsIndex_1(contentId: string, pageId?: string, pageType?: string, pageTypeId?: string, letter?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
            return ContentsApiFp(configuration).contentsPagesAggregationsIndex_1(contentId, pageId, pageType, pageTypeId, letter, geoCode, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} contentPageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentsPagesGet(contentPageId: string, options?: any) {
            return ContentsApiFp(configuration).contentsPagesGet(contentPageId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} contentId
         * @param {string} contentPageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentsPagesGet_2(contentId: string, contentPageId: string, options?: any) {
            return ContentsApiFp(configuration).contentsPagesGet_2(contentId, contentPageId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [pageId] List of page identification(ID).
         * @param {string} [pageType] Filter pages by their type
         * @param {string} [pageTypeId] Filter pages by their type id
         * @param {string} [letter] Filter pages by first letter
         * @param {string} [geoCode] Geo Code for the Content Page
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentsPagesIndex(pageId?: string, pageType?: string, pageTypeId?: string, letter?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
            return ContentsApiFp(configuration).contentsPagesIndex(pageId, pageType, pageTypeId, letter, geoCode, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [pageTypeId] List of page type identification(ID).
         * @param {string} [geoCode] geo
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentsPagesTypesIndex(pageTypeId?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
            return ContentsApiFp(configuration).contentsPagesTypesIndex(pageTypeId, geoCode, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * ContentsApi - object-oriented interface
 * @export
 * @class ContentsApi
 * @extends {BaseAPI}
 */
export class ContentsApi extends BaseAPI {
    /**
     *
     * @param {string} [pageId] List of page identification(ID).
     * @param {string} [pageType] Filter pages by their type
     * @param {string} [pageTypeId] Filter pages by their type id
     * @param {string} [letter] Filter pages by first letter
     * @param {string} [geoCode] Geo Code for the Content Page
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentsApi
     */
    public contentsPagesAggregationsIndex(pageId?: string, pageType?: string, pageTypeId?: string, letter?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
        return ContentsApiFp(this.configuration).contentsPagesAggregationsIndex(pageId, pageType, pageTypeId, letter, geoCode, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} contentId
     * @param {string} [pageId] List of page identification(ID).
     * @param {string} [pageType] Filter pages by their type
     * @param {string} [pageTypeId] Filter pages by their type id
     * @param {string} [letter] Filter pages by first letter
     * @param {string} [geoCode] Geo Code for the Content Page
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentsApi
     */
    public contentsPagesAggregationsIndex_1(contentId: string, pageId?: string, pageType?: string, pageTypeId?: string, letter?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
        return ContentsApiFp(this.configuration).contentsPagesAggregationsIndex_1(contentId, pageId, pageType, pageTypeId, letter, geoCode, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} contentPageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentsApi
     */
    public contentsPagesGet(contentPageId: string, options?: any) {
        return ContentsApiFp(this.configuration).contentsPagesGet(contentPageId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} contentId
     * @param {string} contentPageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentsApi
     */
    public contentsPagesGet_2(contentId: string, contentPageId: string, options?: any) {
        return ContentsApiFp(this.configuration).contentsPagesGet_2(contentId, contentPageId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [pageId] List of page identification(ID).
     * @param {string} [pageType] Filter pages by their type
     * @param {string} [pageTypeId] Filter pages by their type id
     * @param {string} [letter] Filter pages by first letter
     * @param {string} [geoCode] Geo Code for the Content Page
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentsApi
     */
    public contentsPagesIndex(pageId?: string, pageType?: string, pageTypeId?: string, letter?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
        return ContentsApiFp(this.configuration).contentsPagesIndex(pageId, pageType, pageTypeId, letter, geoCode, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [pageTypeId] List of page type identification(ID).
     * @param {string} [geoCode] geo
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentsApi
     */
    public contentsPagesTypesIndex(pageTypeId?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
        return ContentsApiFp(this.configuration).contentsPagesTypesIndex(pageTypeId, geoCode, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * ConversationsApi - fetch parameter creator
 * @export
 */
export const ConversationsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} conversationId
         * @param {ConversationActionResource} conversationActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsActionsCreate(conversationId: string, conversationActionResource: ConversationActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError('conversationId','Required parameter conversationId was null or undefined when calling conversationsActionsCreate.');
            }
            // verify required parameter 'conversationActionResource' is not null or undefined
            if (conversationActionResource === null || conversationActionResource === undefined) {
                throw new RequiredError('conversationActionResource','Required parameter conversationActionResource was null or undefined when calling conversationsActionsCreate.');
            }
            const localVarPath = `/conversations/{conversation_id}/actions`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ConversationActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(conversationActionResource || {}) : (conversationActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {AttachmentResource} attachmentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsAttachmentsCreate(attachmentResource: AttachmentResource, options: any = {}): FetchArgs {
            // verify required parameter 'attachmentResource' is not null or undefined
            if (attachmentResource === null || attachmentResource === undefined) {
                throw new RequiredError('attachmentResource','Required parameter attachmentResource was null or undefined when calling conversationsAttachmentsCreate.');
            }
            const localVarPath = `/conversations/attachments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AttachmentResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(attachmentResource || {}) : (attachmentResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {AttachmentResource} attachmentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsAttachmentsCreate_1(conversationId: string, attachmentResource: AttachmentResource, options: any = {}): FetchArgs {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError('conversationId','Required parameter conversationId was null or undefined when calling conversationsAttachmentsCreate_1.');
            }
            // verify required parameter 'attachmentResource' is not null or undefined
            if (attachmentResource === null || attachmentResource === undefined) {
                throw new RequiredError('attachmentResource','Required parameter attachmentResource was null or undefined when calling conversationsAttachmentsCreate_1.');
            }
            const localVarPath = `/conversations/{conversation_id}/attachments`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AttachmentResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(attachmentResource || {}) : (attachmentResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} attachmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsAttachmentsGet(attachmentId: string, options: any = {}): FetchArgs {
            // verify required parameter 'attachmentId' is not null or undefined
            if (attachmentId === null || attachmentId === undefined) {
                throw new RequiredError('attachmentId','Required parameter attachmentId was null or undefined when calling conversationsAttachmentsGet.');
            }
            const localVarPath = `/conversations/attachments/{attachment_id}`
                .replace(`{${"attachment_id"}}`, encodeURIComponent(String(attachmentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {string} attachmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsAttachmentsGet_2(conversationId: string, attachmentId: string, options: any = {}): FetchArgs {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError('conversationId','Required parameter conversationId was null or undefined when calling conversationsAttachmentsGet_2.');
            }
            // verify required parameter 'attachmentId' is not null or undefined
            if (attachmentId === null || attachmentId === undefined) {
                throw new RequiredError('attachmentId','Required parameter attachmentId was null or undefined when calling conversationsAttachmentsGet_2.');
            }
            const localVarPath = `/conversations/{conversation_id}/attachments/{attachment_id}`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"attachment_id"}}`, encodeURIComponent(String(attachmentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [conversationId] Conversation id @var int
         * @param {string} [messageId] Message ID. @var int
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsAttachmentsIndex(conversationId?: string, messageId?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/conversations/attachments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (conversationId !== undefined) {
                localVarQueryParameter['conversation_id'] = conversationId;
            }

            if (messageId !== undefined) {
                localVarQueryParameter['message_id'] = messageId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {string} [messageId] Message ID. @var int
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsAttachmentsIndex_3(conversationId: string, messageId?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError('conversationId','Required parameter conversationId was null or undefined when calling conversationsAttachmentsIndex_3.');
            }
            const localVarPath = `/conversations/{conversation_id}/attachments`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (messageId !== undefined) {
                localVarQueryParameter['message_id'] = messageId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {ConversationResource} conversationResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsCreate(conversationResource: ConversationResource, options: any = {}): FetchArgs {
            // verify required parameter 'conversationResource' is not null or undefined
            if (conversationResource === null || conversationResource === undefined) {
                throw new RequiredError('conversationResource','Required parameter conversationResource was null or undefined when calling conversationsCreate.');
            }
            const localVarPath = `/conversations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ConversationResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(conversationResource || {}) : (conversationResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsGet(conversationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError('conversationId','Required parameter conversationId was null or undefined when calling conversationsGet.');
            }
            const localVarPath = `/conversations/{conversation_id}`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [conversationId] Conversation id.
         * @param {string} [projectId] Project id
         * @param {string} [partnerId] Partner id
         * @param {string} [partnerUserId] Partner user id
         * @param {string} [clientId] Client id
         * @param {string} [employeeId] Employee id
         * @param {string} [leadId] Lead id
         * @param {'latest_activity' | 'ascending' | 'descending'} [sort]
         * @param {boolean} [isLeadValidated]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsIndex(conversationId?: string, projectId?: string, partnerId?: string, partnerUserId?: string, clientId?: string, employeeId?: string, leadId?: string, sort?: 'latest_activity' | 'ascending' | 'descending', isLeadValidated?: boolean, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/conversations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (conversationId !== undefined) {
                localVarQueryParameter['conversation_id'] = conversationId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (partnerUserId !== undefined) {
                localVarQueryParameter['partner_user_id'] = partnerUserId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employee_id'] = employeeId;
            }

            if (leadId !== undefined) {
                localVarQueryParameter['lead_id'] = leadId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (isLeadValidated !== undefined) {
                localVarQueryParameter['is_lead_validated'] = isLeadValidated;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {AttachmentResource} attachmentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsCreate(attachmentResource: AttachmentResource, options: any = {}): FetchArgs {
            // verify required parameter 'attachmentResource' is not null or undefined
            if (attachmentResource === null || attachmentResource === undefined) {
                throw new RequiredError('attachmentResource','Required parameter attachmentResource was null or undefined when calling conversationsMessagesAttachmentsCreate.');
            }
            const localVarPath = `/conversations/conversations/messages/attachments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AttachmentResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(attachmentResource || {}) : (attachmentResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {AttachmentResource} attachmentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsCreate_4(attachmentResource: AttachmentResource, options: any = {}): FetchArgs {
            // verify required parameter 'attachmentResource' is not null or undefined
            if (attachmentResource === null || attachmentResource === undefined) {
                throw new RequiredError('attachmentResource','Required parameter attachmentResource was null or undefined when calling conversationsMessagesAttachmentsCreate_4.');
            }
            const localVarPath = `/conversations/messages/attachments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AttachmentResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(attachmentResource || {}) : (attachmentResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {AttachmentResource} attachmentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsCreate_5(conversationId: string, attachmentResource: AttachmentResource, options: any = {}): FetchArgs {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError('conversationId','Required parameter conversationId was null or undefined when calling conversationsMessagesAttachmentsCreate_5.');
            }
            // verify required parameter 'attachmentResource' is not null or undefined
            if (attachmentResource === null || attachmentResource === undefined) {
                throw new RequiredError('attachmentResource','Required parameter attachmentResource was null or undefined when calling conversationsMessagesAttachmentsCreate_5.');
            }
            const localVarPath = `/conversations/{conversation_id}/messages/attachments`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AttachmentResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(attachmentResource || {}) : (attachmentResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} attachmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsGet(attachmentId: string, options: any = {}): FetchArgs {
            // verify required parameter 'attachmentId' is not null or undefined
            if (attachmentId === null || attachmentId === undefined) {
                throw new RequiredError('attachmentId','Required parameter attachmentId was null or undefined when calling conversationsMessagesAttachmentsGet.');
            }
            const localVarPath = `/conversations/conversations/messages/attachments/{attachment_id}`
                .replace(`{${"attachment_id"}}`, encodeURIComponent(String(attachmentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} attachmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsGet_6(attachmentId: string, options: any = {}): FetchArgs {
            // verify required parameter 'attachmentId' is not null or undefined
            if (attachmentId === null || attachmentId === undefined) {
                throw new RequiredError('attachmentId','Required parameter attachmentId was null or undefined when calling conversationsMessagesAttachmentsGet_6.');
            }
            const localVarPath = `/conversations/messages/attachments/{attachment_id}`
                .replace(`{${"attachment_id"}}`, encodeURIComponent(String(attachmentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {string} attachmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsGet_7(conversationId: string, attachmentId: string, options: any = {}): FetchArgs {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError('conversationId','Required parameter conversationId was null or undefined when calling conversationsMessagesAttachmentsGet_7.');
            }
            // verify required parameter 'attachmentId' is not null or undefined
            if (attachmentId === null || attachmentId === undefined) {
                throw new RequiredError('attachmentId','Required parameter attachmentId was null or undefined when calling conversationsMessagesAttachmentsGet_7.');
            }
            const localVarPath = `/conversations/{conversation_id}/messages/attachments/{attachment_id}`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"attachment_id"}}`, encodeURIComponent(String(attachmentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [conversationId] Conversation id @var int
         * @param {string} [messageId] Message ID. @var int
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsIndex(conversationId?: string, messageId?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/conversations/conversations/messages/attachments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (conversationId !== undefined) {
                localVarQueryParameter['conversation_id'] = conversationId;
            }

            if (messageId !== undefined) {
                localVarQueryParameter['message_id'] = messageId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [conversationId] Conversation id @var int
         * @param {string} [messageId] Message ID. @var int
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsIndex_8(conversationId?: string, messageId?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/conversations/messages/attachments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (conversationId !== undefined) {
                localVarQueryParameter['conversation_id'] = conversationId;
            }

            if (messageId !== undefined) {
                localVarQueryParameter['message_id'] = messageId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {string} [messageId] Message ID. @var int
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsIndex_9(conversationId: string, messageId?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError('conversationId','Required parameter conversationId was null or undefined when calling conversationsMessagesAttachmentsIndex_9.');
            }
            const localVarPath = `/conversations/{conversation_id}/messages/attachments`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (messageId !== undefined) {
                localVarQueryParameter['message_id'] = messageId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {MessageResource} messageResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesCreate(messageResource: MessageResource, options: any = {}): FetchArgs {
            // verify required parameter 'messageResource' is not null or undefined
            if (messageResource === null || messageResource === undefined) {
                throw new RequiredError('messageResource','Required parameter messageResource was null or undefined when calling conversationsMessagesCreate.');
            }
            const localVarPath = `/conversations/messages`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MessageResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(messageResource || {}) : (messageResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {MessageResource} messageResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesCreate_10(conversationId: string, messageResource: MessageResource, options: any = {}): FetchArgs {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError('conversationId','Required parameter conversationId was null or undefined when calling conversationsMessagesCreate_10.');
            }
            // verify required parameter 'messageResource' is not null or undefined
            if (messageResource === null || messageResource === undefined) {
                throw new RequiredError('messageResource','Required parameter messageResource was null or undefined when calling conversationsMessagesCreate_10.');
            }
            const localVarPath = `/conversations/{conversation_id}/messages`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MessageResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(messageResource || {}) : (messageResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} messageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesGet(messageId: string, options: any = {}): FetchArgs {
            // verify required parameter 'messageId' is not null or undefined
            if (messageId === null || messageId === undefined) {
                throw new RequiredError('messageId','Required parameter messageId was null or undefined when calling conversationsMessagesGet.');
            }
            const localVarPath = `/conversations/messages/{message_id}`
                .replace(`{${"message_id"}}`, encodeURIComponent(String(messageId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {string} messageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesGet_11(conversationId: string, messageId: string, options: any = {}): FetchArgs {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError('conversationId','Required parameter conversationId was null or undefined when calling conversationsMessagesGet_11.');
            }
            // verify required parameter 'messageId' is not null or undefined
            if (messageId === null || messageId === undefined) {
                throw new RequiredError('messageId','Required parameter messageId was null or undefined when calling conversationsMessagesGet_11.');
            }
            const localVarPath = `/conversations/{conversation_id}/messages/{message_id}`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"message_id"}}`, encodeURIComponent(String(messageId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [conversationId] Conversation id @var int
         * @param {string} [messageId] Message ID. @var int
         * @param {number} [participantId] Message Participant ID. @var int
         * @param {number} [unreadByParticipantId] Parameter for getting unread messages by participant_id @var int
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesIndex(conversationId?: string, messageId?: string, participantId?: number, unreadByParticipantId?: number, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/conversations/messages`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (conversationId !== undefined) {
                localVarQueryParameter['conversation_id'] = conversationId;
            }

            if (messageId !== undefined) {
                localVarQueryParameter['message_id'] = messageId;
            }

            if (participantId !== undefined) {
                localVarQueryParameter['participant_id'] = participantId;
            }

            if (unreadByParticipantId !== undefined) {
                localVarQueryParameter['unread_by_participant_id'] = unreadByParticipantId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {string} [messageId] Message ID. @var int
         * @param {number} [participantId] Message Participant ID. @var int
         * @param {number} [unreadByParticipantId] Parameter for getting unread messages by participant_id @var int
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesIndex_12(conversationId: string, messageId?: string, participantId?: number, unreadByParticipantId?: number, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'conversationId' is not null or undefined
            if (conversationId === null || conversationId === undefined) {
                throw new RequiredError('conversationId','Required parameter conversationId was null or undefined when calling conversationsMessagesIndex_12.');
            }
            const localVarPath = `/conversations/{conversation_id}/messages`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (messageId !== undefined) {
                localVarQueryParameter['message_id'] = messageId;
            }

            if (participantId !== undefined) {
                localVarQueryParameter['participant_id'] = participantId;
            }

            if (unreadByParticipantId !== undefined) {
                localVarQueryParameter['unread_by_participant_id'] = unreadByParticipantId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConversationsApi - functional programming interface
 * @export
 */
export const ConversationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} conversationId
         * @param {ConversationActionResource} conversationActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsActionsCreate(conversationId: string, conversationActionResource: ConversationActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ConversationResource> {
            const localVarFetchArgs = ConversationsApiFetchParamCreator(configuration).conversationsActionsCreate(conversationId, conversationActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {AttachmentResource} attachmentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsAttachmentsCreate(attachmentResource: AttachmentResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AttachmentResource> {
            const localVarFetchArgs = ConversationsApiFetchParamCreator(configuration).conversationsAttachmentsCreate(attachmentResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {AttachmentResource} attachmentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsAttachmentsCreate_1(conversationId: string, attachmentResource: AttachmentResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AttachmentResource> {
            const localVarFetchArgs = ConversationsApiFetchParamCreator(configuration).conversationsAttachmentsCreate_1(conversationId, attachmentResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} attachmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsAttachmentsGet(attachmentId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AttachmentResource> {
            const localVarFetchArgs = ConversationsApiFetchParamCreator(configuration).conversationsAttachmentsGet(attachmentId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {string} attachmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsAttachmentsGet_2(conversationId: string, attachmentId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AttachmentResource> {
            const localVarFetchArgs = ConversationsApiFetchParamCreator(configuration).conversationsAttachmentsGet_2(conversationId, attachmentId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [conversationId] Conversation id @var int
         * @param {string} [messageId] Message ID. @var int
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsAttachmentsIndex(conversationId?: string, messageId?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AttachmentResult> {
            const localVarFetchArgs = ConversationsApiFetchParamCreator(configuration).conversationsAttachmentsIndex(conversationId, messageId, sort, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {string} [messageId] Message ID. @var int
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsAttachmentsIndex_3(conversationId: string, messageId?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AttachmentResult> {
            const localVarFetchArgs = ConversationsApiFetchParamCreator(configuration).conversationsAttachmentsIndex_3(conversationId, messageId, sort, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {ConversationResource} conversationResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsCreate(conversationResource: ConversationResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ConversationResource> {
            const localVarFetchArgs = ConversationsApiFetchParamCreator(configuration).conversationsCreate(conversationResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsGet(conversationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ConversationResource> {
            const localVarFetchArgs = ConversationsApiFetchParamCreator(configuration).conversationsGet(conversationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [conversationId] Conversation id.
         * @param {string} [projectId] Project id
         * @param {string} [partnerId] Partner id
         * @param {string} [partnerUserId] Partner user id
         * @param {string} [clientId] Client id
         * @param {string} [employeeId] Employee id
         * @param {string} [leadId] Lead id
         * @param {'latest_activity' | 'ascending' | 'descending'} [sort]
         * @param {boolean} [isLeadValidated]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsIndex(conversationId?: string, projectId?: string, partnerId?: string, partnerUserId?: string, clientId?: string, employeeId?: string, leadId?: string, sort?: 'latest_activity' | 'ascending' | 'descending', isLeadValidated?: boolean, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ConversationResult> {
            const localVarFetchArgs = ConversationsApiFetchParamCreator(configuration).conversationsIndex(conversationId, projectId, partnerId, partnerUserId, clientId, employeeId, leadId, sort, isLeadValidated, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {AttachmentResource} attachmentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsCreate(attachmentResource: AttachmentResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AttachmentResource> {
            const localVarFetchArgs = ConversationsApiFetchParamCreator(configuration).conversationsMessagesAttachmentsCreate(attachmentResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {AttachmentResource} attachmentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsCreate_4(attachmentResource: AttachmentResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AttachmentResource> {
            const localVarFetchArgs = ConversationsApiFetchParamCreator(configuration).conversationsMessagesAttachmentsCreate_4(attachmentResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {AttachmentResource} attachmentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsCreate_5(conversationId: string, attachmentResource: AttachmentResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AttachmentResource> {
            const localVarFetchArgs = ConversationsApiFetchParamCreator(configuration).conversationsMessagesAttachmentsCreate_5(conversationId, attachmentResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} attachmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsGet(attachmentId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AttachmentResource> {
            const localVarFetchArgs = ConversationsApiFetchParamCreator(configuration).conversationsMessagesAttachmentsGet(attachmentId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} attachmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsGet_6(attachmentId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AttachmentResource> {
            const localVarFetchArgs = ConversationsApiFetchParamCreator(configuration).conversationsMessagesAttachmentsGet_6(attachmentId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {string} attachmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsGet_7(conversationId: string, attachmentId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AttachmentResource> {
            const localVarFetchArgs = ConversationsApiFetchParamCreator(configuration).conversationsMessagesAttachmentsGet_7(conversationId, attachmentId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [conversationId] Conversation id @var int
         * @param {string} [messageId] Message ID. @var int
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsIndex(conversationId?: string, messageId?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AttachmentResult> {
            const localVarFetchArgs = ConversationsApiFetchParamCreator(configuration).conversationsMessagesAttachmentsIndex(conversationId, messageId, sort, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [conversationId] Conversation id @var int
         * @param {string} [messageId] Message ID. @var int
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsIndex_8(conversationId?: string, messageId?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AttachmentResult> {
            const localVarFetchArgs = ConversationsApiFetchParamCreator(configuration).conversationsMessagesAttachmentsIndex_8(conversationId, messageId, sort, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {string} [messageId] Message ID. @var int
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsIndex_9(conversationId: string, messageId?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AttachmentResult> {
            const localVarFetchArgs = ConversationsApiFetchParamCreator(configuration).conversationsMessagesAttachmentsIndex_9(conversationId, messageId, sort, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {MessageResource} messageResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesCreate(messageResource: MessageResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MessageResource> {
            const localVarFetchArgs = ConversationsApiFetchParamCreator(configuration).conversationsMessagesCreate(messageResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {MessageResource} messageResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesCreate_10(conversationId: string, messageResource: MessageResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MessageResource> {
            const localVarFetchArgs = ConversationsApiFetchParamCreator(configuration).conversationsMessagesCreate_10(conversationId, messageResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} messageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesGet(messageId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MessageResource> {
            const localVarFetchArgs = ConversationsApiFetchParamCreator(configuration).conversationsMessagesGet(messageId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {string} messageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesGet_11(conversationId: string, messageId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MessageResource> {
            const localVarFetchArgs = ConversationsApiFetchParamCreator(configuration).conversationsMessagesGet_11(conversationId, messageId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [conversationId] Conversation id @var int
         * @param {string} [messageId] Message ID. @var int
         * @param {number} [participantId] Message Participant ID. @var int
         * @param {number} [unreadByParticipantId] Parameter for getting unread messages by participant_id @var int
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesIndex(conversationId?: string, messageId?: string, participantId?: number, unreadByParticipantId?: number, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MessageResult> {
            const localVarFetchArgs = ConversationsApiFetchParamCreator(configuration).conversationsMessagesIndex(conversationId, messageId, participantId, unreadByParticipantId, sort, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} conversationId
         * @param {string} [messageId] Message ID. @var int
         * @param {number} [participantId] Message Participant ID. @var int
         * @param {number} [unreadByParticipantId] Parameter for getting unread messages by participant_id @var int
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesIndex_12(conversationId: string, messageId?: string, participantId?: number, unreadByParticipantId?: number, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MessageResult> {
            const localVarFetchArgs = ConversationsApiFetchParamCreator(configuration).conversationsMessagesIndex_12(conversationId, messageId, participantId, unreadByParticipantId, sort, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ConversationsApi - factory interface
 * @export
 */
export const ConversationsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} conversationId
         * @param {ConversationActionResource} conversationActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsActionsCreate(conversationId: string, conversationActionResource: ConversationActionResource, options?: any) {
            return ConversationsApiFp(configuration).conversationsActionsCreate(conversationId, conversationActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {AttachmentResource} attachmentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsAttachmentsCreate(attachmentResource: AttachmentResource, options?: any) {
            return ConversationsApiFp(configuration).conversationsAttachmentsCreate(attachmentResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} conversationId
         * @param {AttachmentResource} attachmentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsAttachmentsCreate_1(conversationId: string, attachmentResource: AttachmentResource, options?: any) {
            return ConversationsApiFp(configuration).conversationsAttachmentsCreate_1(conversationId, attachmentResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} attachmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsAttachmentsGet(attachmentId: string, options?: any) {
            return ConversationsApiFp(configuration).conversationsAttachmentsGet(attachmentId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} conversationId
         * @param {string} attachmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsAttachmentsGet_2(conversationId: string, attachmentId: string, options?: any) {
            return ConversationsApiFp(configuration).conversationsAttachmentsGet_2(conversationId, attachmentId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [conversationId] Conversation id @var int
         * @param {string} [messageId] Message ID. @var int
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsAttachmentsIndex(conversationId?: string, messageId?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
            return ConversationsApiFp(configuration).conversationsAttachmentsIndex(conversationId, messageId, sort, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} conversationId
         * @param {string} [messageId] Message ID. @var int
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsAttachmentsIndex_3(conversationId: string, messageId?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
            return ConversationsApiFp(configuration).conversationsAttachmentsIndex_3(conversationId, messageId, sort, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {ConversationResource} conversationResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsCreate(conversationResource: ConversationResource, options?: any) {
            return ConversationsApiFp(configuration).conversationsCreate(conversationResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsGet(conversationId: string, options?: any) {
            return ConversationsApiFp(configuration).conversationsGet(conversationId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [conversationId] Conversation id.
         * @param {string} [projectId] Project id
         * @param {string} [partnerId] Partner id
         * @param {string} [partnerUserId] Partner user id
         * @param {string} [clientId] Client id
         * @param {string} [employeeId] Employee id
         * @param {string} [leadId] Lead id
         * @param {'latest_activity' | 'ascending' | 'descending'} [sort]
         * @param {boolean} [isLeadValidated]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsIndex(conversationId?: string, projectId?: string, partnerId?: string, partnerUserId?: string, clientId?: string, employeeId?: string, leadId?: string, sort?: 'latest_activity' | 'ascending' | 'descending', isLeadValidated?: boolean, limit?: number, page?: number, query?: string, options?: any) {
            return ConversationsApiFp(configuration).conversationsIndex(conversationId, projectId, partnerId, partnerUserId, clientId, employeeId, leadId, sort, isLeadValidated, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {AttachmentResource} attachmentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsCreate(attachmentResource: AttachmentResource, options?: any) {
            return ConversationsApiFp(configuration).conversationsMessagesAttachmentsCreate(attachmentResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {AttachmentResource} attachmentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsCreate_4(attachmentResource: AttachmentResource, options?: any) {
            return ConversationsApiFp(configuration).conversationsMessagesAttachmentsCreate_4(attachmentResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} conversationId
         * @param {AttachmentResource} attachmentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsCreate_5(conversationId: string, attachmentResource: AttachmentResource, options?: any) {
            return ConversationsApiFp(configuration).conversationsMessagesAttachmentsCreate_5(conversationId, attachmentResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} attachmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsGet(attachmentId: string, options?: any) {
            return ConversationsApiFp(configuration).conversationsMessagesAttachmentsGet(attachmentId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} attachmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsGet_6(attachmentId: string, options?: any) {
            return ConversationsApiFp(configuration).conversationsMessagesAttachmentsGet_6(attachmentId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} conversationId
         * @param {string} attachmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsGet_7(conversationId: string, attachmentId: string, options?: any) {
            return ConversationsApiFp(configuration).conversationsMessagesAttachmentsGet_7(conversationId, attachmentId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [conversationId] Conversation id @var int
         * @param {string} [messageId] Message ID. @var int
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsIndex(conversationId?: string, messageId?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
            return ConversationsApiFp(configuration).conversationsMessagesAttachmentsIndex(conversationId, messageId, sort, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [conversationId] Conversation id @var int
         * @param {string} [messageId] Message ID. @var int
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsIndex_8(conversationId?: string, messageId?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
            return ConversationsApiFp(configuration).conversationsMessagesAttachmentsIndex_8(conversationId, messageId, sort, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} conversationId
         * @param {string} [messageId] Message ID. @var int
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesAttachmentsIndex_9(conversationId: string, messageId?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
            return ConversationsApiFp(configuration).conversationsMessagesAttachmentsIndex_9(conversationId, messageId, sort, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {MessageResource} messageResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesCreate(messageResource: MessageResource, options?: any) {
            return ConversationsApiFp(configuration).conversationsMessagesCreate(messageResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} conversationId
         * @param {MessageResource} messageResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesCreate_10(conversationId: string, messageResource: MessageResource, options?: any) {
            return ConversationsApiFp(configuration).conversationsMessagesCreate_10(conversationId, messageResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} messageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesGet(messageId: string, options?: any) {
            return ConversationsApiFp(configuration).conversationsMessagesGet(messageId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} conversationId
         * @param {string} messageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesGet_11(conversationId: string, messageId: string, options?: any) {
            return ConversationsApiFp(configuration).conversationsMessagesGet_11(conversationId, messageId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [conversationId] Conversation id @var int
         * @param {string} [messageId] Message ID. @var int
         * @param {number} [participantId] Message Participant ID. @var int
         * @param {number} [unreadByParticipantId] Parameter for getting unread messages by participant_id @var int
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesIndex(conversationId?: string, messageId?: string, participantId?: number, unreadByParticipantId?: number, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
            return ConversationsApiFp(configuration).conversationsMessagesIndex(conversationId, messageId, participantId, unreadByParticipantId, sort, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} conversationId
         * @param {string} [messageId] Message ID. @var int
         * @param {number} [participantId] Message Participant ID. @var int
         * @param {number} [unreadByParticipantId] Parameter for getting unread messages by participant_id @var int
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsMessagesIndex_12(conversationId: string, messageId?: string, participantId?: number, unreadByParticipantId?: number, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
            return ConversationsApiFp(configuration).conversationsMessagesIndex_12(conversationId, messageId, participantId, unreadByParticipantId, sort, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * ConversationsApi - object-oriented interface
 * @export
 * @class ConversationsApi
 * @extends {BaseAPI}
 */
export class ConversationsApi extends BaseAPI {
    /**
     *
     * @param {string} conversationId
     * @param {ConversationActionResource} conversationActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsActionsCreate(conversationId: string, conversationActionResource: ConversationActionResource, options?: any) {
        return ConversationsApiFp(this.configuration).conversationsActionsCreate(conversationId, conversationActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {AttachmentResource} attachmentResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsAttachmentsCreate(attachmentResource: AttachmentResource, options?: any) {
        return ConversationsApiFp(this.configuration).conversationsAttachmentsCreate(attachmentResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} conversationId
     * @param {AttachmentResource} attachmentResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsAttachmentsCreate_1(conversationId: string, attachmentResource: AttachmentResource, options?: any) {
        return ConversationsApiFp(this.configuration).conversationsAttachmentsCreate_1(conversationId, attachmentResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} attachmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsAttachmentsGet(attachmentId: string, options?: any) {
        return ConversationsApiFp(this.configuration).conversationsAttachmentsGet(attachmentId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} conversationId
     * @param {string} attachmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsAttachmentsGet_2(conversationId: string, attachmentId: string, options?: any) {
        return ConversationsApiFp(this.configuration).conversationsAttachmentsGet_2(conversationId, attachmentId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [conversationId] Conversation id @var int
     * @param {string} [messageId] Message ID. @var int
     * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsAttachmentsIndex(conversationId?: string, messageId?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
        return ConversationsApiFp(this.configuration).conversationsAttachmentsIndex(conversationId, messageId, sort, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} conversationId
     * @param {string} [messageId] Message ID. @var int
     * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsAttachmentsIndex_3(conversationId: string, messageId?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
        return ConversationsApiFp(this.configuration).conversationsAttachmentsIndex_3(conversationId, messageId, sort, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {ConversationResource} conversationResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsCreate(conversationResource: ConversationResource, options?: any) {
        return ConversationsApiFp(this.configuration).conversationsCreate(conversationResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} conversationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsGet(conversationId: string, options?: any) {
        return ConversationsApiFp(this.configuration).conversationsGet(conversationId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [conversationId] Conversation id.
     * @param {string} [projectId] Project id
     * @param {string} [partnerId] Partner id
     * @param {string} [partnerUserId] Partner user id
     * @param {string} [clientId] Client id
     * @param {string} [employeeId] Employee id
     * @param {string} [leadId] Lead id
     * @param {'latest_activity' | 'ascending' | 'descending'} [sort]
     * @param {boolean} [isLeadValidated]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsIndex(conversationId?: string, projectId?: string, partnerId?: string, partnerUserId?: string, clientId?: string, employeeId?: string, leadId?: string, sort?: 'latest_activity' | 'ascending' | 'descending', isLeadValidated?: boolean, limit?: number, page?: number, query?: string, options?: any) {
        return ConversationsApiFp(this.configuration).conversationsIndex(conversationId, projectId, partnerId, partnerUserId, clientId, employeeId, leadId, sort, isLeadValidated, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {AttachmentResource} attachmentResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsMessagesAttachmentsCreate(attachmentResource: AttachmentResource, options?: any) {
        return ConversationsApiFp(this.configuration).conversationsMessagesAttachmentsCreate(attachmentResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {AttachmentResource} attachmentResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsMessagesAttachmentsCreate_4(attachmentResource: AttachmentResource, options?: any) {
        return ConversationsApiFp(this.configuration).conversationsMessagesAttachmentsCreate_4(attachmentResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} conversationId
     * @param {AttachmentResource} attachmentResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsMessagesAttachmentsCreate_5(conversationId: string, attachmentResource: AttachmentResource, options?: any) {
        return ConversationsApiFp(this.configuration).conversationsMessagesAttachmentsCreate_5(conversationId, attachmentResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} attachmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsMessagesAttachmentsGet(attachmentId: string, options?: any) {
        return ConversationsApiFp(this.configuration).conversationsMessagesAttachmentsGet(attachmentId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} attachmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsMessagesAttachmentsGet_6(attachmentId: string, options?: any) {
        return ConversationsApiFp(this.configuration).conversationsMessagesAttachmentsGet_6(attachmentId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} conversationId
     * @param {string} attachmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsMessagesAttachmentsGet_7(conversationId: string, attachmentId: string, options?: any) {
        return ConversationsApiFp(this.configuration).conversationsMessagesAttachmentsGet_7(conversationId, attachmentId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [conversationId] Conversation id @var int
     * @param {string} [messageId] Message ID. @var int
     * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsMessagesAttachmentsIndex(conversationId?: string, messageId?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
        return ConversationsApiFp(this.configuration).conversationsMessagesAttachmentsIndex(conversationId, messageId, sort, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [conversationId] Conversation id @var int
     * @param {string} [messageId] Message ID. @var int
     * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsMessagesAttachmentsIndex_8(conversationId?: string, messageId?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
        return ConversationsApiFp(this.configuration).conversationsMessagesAttachmentsIndex_8(conversationId, messageId, sort, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} conversationId
     * @param {string} [messageId] Message ID. @var int
     * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsMessagesAttachmentsIndex_9(conversationId: string, messageId?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
        return ConversationsApiFp(this.configuration).conversationsMessagesAttachmentsIndex_9(conversationId, messageId, sort, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {MessageResource} messageResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsMessagesCreate(messageResource: MessageResource, options?: any) {
        return ConversationsApiFp(this.configuration).conversationsMessagesCreate(messageResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} conversationId
     * @param {MessageResource} messageResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsMessagesCreate_10(conversationId: string, messageResource: MessageResource, options?: any) {
        return ConversationsApiFp(this.configuration).conversationsMessagesCreate_10(conversationId, messageResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} messageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsMessagesGet(messageId: string, options?: any) {
        return ConversationsApiFp(this.configuration).conversationsMessagesGet(messageId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} conversationId
     * @param {string} messageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsMessagesGet_11(conversationId: string, messageId: string, options?: any) {
        return ConversationsApiFp(this.configuration).conversationsMessagesGet_11(conversationId, messageId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [conversationId] Conversation id @var int
     * @param {string} [messageId] Message ID. @var int
     * @param {number} [participantId] Message Participant ID. @var int
     * @param {number} [unreadByParticipantId] Parameter for getting unread messages by participant_id @var int
     * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsMessagesIndex(conversationId?: string, messageId?: string, participantId?: number, unreadByParticipantId?: number, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
        return ConversationsApiFp(this.configuration).conversationsMessagesIndex(conversationId, messageId, participantId, unreadByParticipantId, sort, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} conversationId
     * @param {string} [messageId] Message ID. @var int
     * @param {number} [participantId] Message Participant ID. @var int
     * @param {number} [unreadByParticipantId] Parameter for getting unread messages by participant_id @var int
     * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort messages
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public conversationsMessagesIndex_12(conversationId: string, messageId?: string, participantId?: number, unreadByParticipantId?: number, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
        return ConversationsApiFp(this.configuration).conversationsMessagesIndex_12(conversationId, messageId, participantId, unreadByParticipantId, sort, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * CreditsystemApi - fetch parameter creator
 * @export
 */
export const CreditsystemApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {CreditSystemActionResource} creditSystemActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditsystemActionsCreate(creditSystemActionResource: CreditSystemActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'creditSystemActionResource' is not null or undefined
            if (creditSystemActionResource === null || creditSystemActionResource === undefined) {
                throw new RequiredError('creditSystemActionResource','Required parameter creditSystemActionResource was null or undefined when calling creditsystemActionsCreate.');
            }
            const localVarPath = `/creditsystem/actions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreditSystemActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(creditSystemActionResource || {}) : (creditSystemActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CreditsystemApi - functional programming interface
 * @export
 */
export const CreditsystemApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {CreditSystemActionResource} creditSystemActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditsystemActionsCreate(creditSystemActionResource: CreditSystemActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreditSystemActionResource> {
            const localVarFetchArgs = CreditsystemApiFetchParamCreator(configuration).creditsystemActionsCreate(creditSystemActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CreditsystemApi - factory interface
 * @export
 */
export const CreditsystemApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {CreditSystemActionResource} creditSystemActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditsystemActionsCreate(creditSystemActionResource: CreditSystemActionResource, options?: any) {
            return CreditsystemApiFp(configuration).creditsystemActionsCreate(creditSystemActionResource, options)(fetch, basePath);
        },
    };
};

/**
 * CreditsystemApi - object-oriented interface
 * @export
 * @class CreditsystemApi
 * @extends {BaseAPI}
 */
export class CreditsystemApi extends BaseAPI {
    /**
     *
     * @param {CreditSystemActionResource} creditSystemActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditsystemApi
     */
    public creditsystemActionsCreate(creditSystemActionResource: CreditSystemActionResource, options?: any) {
        return CreditsystemApiFp(this.configuration).creditsystemActionsCreate(creditSystemActionResource, options)(this.fetch, this.basePath);
    }

}

/**
 * CustomersApi - fetch parameter creator
 * @export
 */
export const CustomersApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} clientId
         * @param {PhoneVerificationActionResource} phoneVerificationActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersActionsCreate(clientId: string, phoneVerificationActionResource: PhoneVerificationActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling customersActionsCreate.');
            }
            // verify required parameter 'phoneVerificationActionResource' is not null or undefined
            if (phoneVerificationActionResource === null || phoneVerificationActionResource === undefined) {
                throw new RequiredError('phoneVerificationActionResource','Required parameter phoneVerificationActionResource was null or undefined when calling customersActionsCreate.');
            }
            const localVarPath = `/customers/{client_id}/actions`
                .replace(`{${"client_id"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PhoneVerificationActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(phoneVerificationActionResource || {}) : (phoneVerificationActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {PhoneVerificationResource} phoneVerificationResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersPhoneverificationsCreate(phoneVerificationResource: PhoneVerificationResource, options: any = {}): FetchArgs {
            // verify required parameter 'phoneVerificationResource' is not null or undefined
            if (phoneVerificationResource === null || phoneVerificationResource === undefined) {
                throw new RequiredError('phoneVerificationResource','Required parameter phoneVerificationResource was null or undefined when calling customersPhoneverificationsCreate.');
            }
            const localVarPath = `/customers/phoneverifications`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PhoneVerificationResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(phoneVerificationResource || {}) : (phoneVerificationResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersApi - functional programming interface
 * @export
 */
export const CustomersApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} clientId
         * @param {PhoneVerificationActionResource} phoneVerificationActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersActionsCreate(clientId: string, phoneVerificationActionResource: PhoneVerificationActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PhoneVerificationResource> {
            const localVarFetchArgs = CustomersApiFetchParamCreator(configuration).customersActionsCreate(clientId, phoneVerificationActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {PhoneVerificationResource} phoneVerificationResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersPhoneverificationsCreate(phoneVerificationResource: PhoneVerificationResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PhoneVerificationResource> {
            const localVarFetchArgs = CustomersApiFetchParamCreator(configuration).customersPhoneverificationsCreate(phoneVerificationResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CustomersApi - factory interface
 * @export
 */
export const CustomersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} clientId
         * @param {PhoneVerificationActionResource} phoneVerificationActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersActionsCreate(clientId: string, phoneVerificationActionResource: PhoneVerificationActionResource, options?: any) {
            return CustomersApiFp(configuration).customersActionsCreate(clientId, phoneVerificationActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {PhoneVerificationResource} phoneVerificationResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersPhoneverificationsCreate(phoneVerificationResource: PhoneVerificationResource, options?: any) {
            return CustomersApiFp(configuration).customersPhoneverificationsCreate(phoneVerificationResource, options)(fetch, basePath);
        },
    };
};

/**
 * CustomersApi - object-oriented interface
 * @export
 * @class CustomersApi
 * @extends {BaseAPI}
 */
export class CustomersApi extends BaseAPI {
    /**
     *
     * @param {string} clientId
     * @param {PhoneVerificationActionResource} phoneVerificationActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customersActionsCreate(clientId: string, phoneVerificationActionResource: PhoneVerificationActionResource, options?: any) {
        return CustomersApiFp(this.configuration).customersActionsCreate(clientId, phoneVerificationActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {PhoneVerificationResource} phoneVerificationResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customersPhoneverificationsCreate(phoneVerificationResource: PhoneVerificationResource, options?: any) {
        return CustomersApiFp(this.configuration).customersPhoneverificationsCreate(phoneVerificationResource, options)(this.fetch, this.basePath);
    }

}

/**
 * CvApi - fetch parameter creator
 * @export
 */
export const CvApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvAwardResource} partnerUserCvAwardResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvAwardCreate(partnerUserId: string, partnerUserCvAwardResource: PartnerUserCvAwardResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvAwardCreate.');
            }
            // verify required parameter 'partnerUserCvAwardResource' is not null or undefined
            if (partnerUserCvAwardResource === null || partnerUserCvAwardResource === undefined) {
                throw new RequiredError('partnerUserCvAwardResource','Required parameter partnerUserCvAwardResource was null or undefined when calling cvAwardCreate.');
            }
            const localVarPath = `/cv/{partner_user_id}/award`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerUserCvAwardResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerUserCvAwardResource || {}) : (partnerUserCvAwardResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} awardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvAwardDelete(partnerUserId: string, awardId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvAwardDelete.');
            }
            // verify required parameter 'awardId' is not null or undefined
            if (awardId === null || awardId === undefined) {
                throw new RequiredError('awardId','Required parameter awardId was null or undefined when calling cvAwardDelete.');
            }
            const localVarPath = `/cv/{partner_user_id}/award/{award_id}`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)))
                .replace(`{${"award_id"}}`, encodeURIComponent(String(awardId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvAwardResource} partnerUserCvAwardResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvAwardUpdate(partnerUserId: string, partnerUserCvAwardResource: PartnerUserCvAwardResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvAwardUpdate.');
            }
            // verify required parameter 'partnerUserCvAwardResource' is not null or undefined
            if (partnerUserCvAwardResource === null || partnerUserCvAwardResource === undefined) {
                throw new RequiredError('partnerUserCvAwardResource','Required parameter partnerUserCvAwardResource was null or undefined when calling cvAwardUpdate.');
            }
            const localVarPath = `/cv/{partner_user_id}/award`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerUserCvAwardResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerUserCvAwardResource || {}) : (partnerUserCvAwardResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvBadgeResource} partnerUserCvBadgeResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvBadgeCreate(partnerUserId: string, partnerUserCvBadgeResource: PartnerUserCvBadgeResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvBadgeCreate.');
            }
            // verify required parameter 'partnerUserCvBadgeResource' is not null or undefined
            if (partnerUserCvBadgeResource === null || partnerUserCvBadgeResource === undefined) {
                throw new RequiredError('partnerUserCvBadgeResource','Required parameter partnerUserCvBadgeResource was null or undefined when calling cvBadgeCreate.');
            }
            const localVarPath = `/cv/{partner_user_id}/badge`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerUserCvBadgeResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerUserCvBadgeResource || {}) : (partnerUserCvBadgeResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} badgeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvBadgeDelete(partnerUserId: string, badgeId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvBadgeDelete.');
            }
            // verify required parameter 'badgeId' is not null or undefined
            if (badgeId === null || badgeId === undefined) {
                throw new RequiredError('badgeId','Required parameter badgeId was null or undefined when calling cvBadgeDelete.');
            }
            const localVarPath = `/cv/{partner_user_id}/badge/{badge_id}`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)))
                .replace(`{${"badge_id"}}`, encodeURIComponent(String(badgeId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvCourseResource} partnerUserCvCourseResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvCourseCreate(partnerUserId: string, partnerUserCvCourseResource: PartnerUserCvCourseResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvCourseCreate.');
            }
            // verify required parameter 'partnerUserCvCourseResource' is not null or undefined
            if (partnerUserCvCourseResource === null || partnerUserCvCourseResource === undefined) {
                throw new RequiredError('partnerUserCvCourseResource','Required parameter partnerUserCvCourseResource was null or undefined when calling cvCourseCreate.');
            }
            const localVarPath = `/cv/{partner_user_id}/course`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerUserCvCourseResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerUserCvCourseResource || {}) : (partnerUserCvCourseResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvCourseDelete(partnerUserId: string, courseId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvCourseDelete.');
            }
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling cvCourseDelete.');
            }
            const localVarPath = `/cv/{partner_user_id}/course/{course_id}`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)))
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvCourseResource} partnerUserCvCourseResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvCourseUpdate(partnerUserId: string, partnerUserCvCourseResource: PartnerUserCvCourseResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvCourseUpdate.');
            }
            // verify required parameter 'partnerUserCvCourseResource' is not null or undefined
            if (partnerUserCvCourseResource === null || partnerUserCvCourseResource === undefined) {
                throw new RequiredError('partnerUserCvCourseResource','Required parameter partnerUserCvCourseResource was null or undefined when calling cvCourseUpdate.');
            }
            const localVarPath = `/cv/{partner_user_id}/course`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerUserCvCourseResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerUserCvCourseResource || {}) : (partnerUserCvCourseResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvDescriptionResource} partnerUserCvDescriptionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvDescriptionCreate(partnerUserId: string, partnerUserCvDescriptionResource: PartnerUserCvDescriptionResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvDescriptionCreate.');
            }
            // verify required parameter 'partnerUserCvDescriptionResource' is not null or undefined
            if (partnerUserCvDescriptionResource === null || partnerUserCvDescriptionResource === undefined) {
                throw new RequiredError('partnerUserCvDescriptionResource','Required parameter partnerUserCvDescriptionResource was null or undefined when calling cvDescriptionCreate.');
            }
            const localVarPath = `/cv/{partner_user_id}/description`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerUserCvDescriptionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerUserCvDescriptionResource || {}) : (partnerUserCvDescriptionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} descriptionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvDescriptionDelete(partnerUserId: string, descriptionId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvDescriptionDelete.');
            }
            // verify required parameter 'descriptionId' is not null or undefined
            if (descriptionId === null || descriptionId === undefined) {
                throw new RequiredError('descriptionId','Required parameter descriptionId was null or undefined when calling cvDescriptionDelete.');
            }
            const localVarPath = `/cv/{partner_user_id}/description/{description_id}`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)))
                .replace(`{${"description_id"}}`, encodeURIComponent(String(descriptionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvDescriptionResource} partnerUserCvDescriptionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvDescriptionUpdate(partnerUserId: string, partnerUserCvDescriptionResource: PartnerUserCvDescriptionResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvDescriptionUpdate.');
            }
            // verify required parameter 'partnerUserCvDescriptionResource' is not null or undefined
            if (partnerUserCvDescriptionResource === null || partnerUserCvDescriptionResource === undefined) {
                throw new RequiredError('partnerUserCvDescriptionResource','Required parameter partnerUserCvDescriptionResource was null or undefined when calling cvDescriptionUpdate.');
            }
            const localVarPath = `/cv/{partner_user_id}/description`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerUserCvDescriptionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerUserCvDescriptionResource || {}) : (partnerUserCvDescriptionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvEducationResource} partnerUserCvEducationResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvEducationCreate(partnerUserId: string, partnerUserCvEducationResource: PartnerUserCvEducationResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvEducationCreate.');
            }
            // verify required parameter 'partnerUserCvEducationResource' is not null or undefined
            if (partnerUserCvEducationResource === null || partnerUserCvEducationResource === undefined) {
                throw new RequiredError('partnerUserCvEducationResource','Required parameter partnerUserCvEducationResource was null or undefined when calling cvEducationCreate.');
            }
            const localVarPath = `/cv/{partner_user_id}/education`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerUserCvEducationResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerUserCvEducationResource || {}) : (partnerUserCvEducationResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} educationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvEducationDelete(partnerUserId: string, educationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvEducationDelete.');
            }
            // verify required parameter 'educationId' is not null or undefined
            if (educationId === null || educationId === undefined) {
                throw new RequiredError('educationId','Required parameter educationId was null or undefined when calling cvEducationDelete.');
            }
            const localVarPath = `/cv/{partner_user_id}/education/{education_id}`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)))
                .replace(`{${"education_id"}}`, encodeURIComponent(String(educationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvEducationResource} partnerUserCvEducationResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvEducationUpdate(partnerUserId: string, partnerUserCvEducationResource: PartnerUserCvEducationResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvEducationUpdate.');
            }
            // verify required parameter 'partnerUserCvEducationResource' is not null or undefined
            if (partnerUserCvEducationResource === null || partnerUserCvEducationResource === undefined) {
                throw new RequiredError('partnerUserCvEducationResource','Required parameter partnerUserCvEducationResource was null or undefined when calling cvEducationUpdate.');
            }
            const localVarPath = `/cv/{partner_user_id}/education`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerUserCvEducationResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerUserCvEducationResource || {}) : (partnerUserCvEducationResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvExperienceResource} partnerUserCvExperienceResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvExperienceCreate(partnerUserId: string, partnerUserCvExperienceResource: PartnerUserCvExperienceResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvExperienceCreate.');
            }
            // verify required parameter 'partnerUserCvExperienceResource' is not null or undefined
            if (partnerUserCvExperienceResource === null || partnerUserCvExperienceResource === undefined) {
                throw new RequiredError('partnerUserCvExperienceResource','Required parameter partnerUserCvExperienceResource was null or undefined when calling cvExperienceCreate.');
            }
            const localVarPath = `/cv/{partner_user_id}/experience`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerUserCvExperienceResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerUserCvExperienceResource || {}) : (partnerUserCvExperienceResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} experienceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvExperienceDelete(partnerUserId: string, experienceId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvExperienceDelete.');
            }
            // verify required parameter 'experienceId' is not null or undefined
            if (experienceId === null || experienceId === undefined) {
                throw new RequiredError('experienceId','Required parameter experienceId was null or undefined when calling cvExperienceDelete.');
            }
            const localVarPath = `/cv/{partner_user_id}/experience/{experience_id}`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)))
                .replace(`{${"experience_id"}}`, encodeURIComponent(String(experienceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvExperienceResource} partnerUserCvExperienceResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvExperienceUpdate(partnerUserId: string, partnerUserCvExperienceResource: PartnerUserCvExperienceResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvExperienceUpdate.');
            }
            // verify required parameter 'partnerUserCvExperienceResource' is not null or undefined
            if (partnerUserCvExperienceResource === null || partnerUserCvExperienceResource === undefined) {
                throw new RequiredError('partnerUserCvExperienceResource','Required parameter partnerUserCvExperienceResource was null or undefined when calling cvExperienceUpdate.');
            }
            const localVarPath = `/cv/{partner_user_id}/experience`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerUserCvExperienceResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerUserCvExperienceResource || {}) : (partnerUserCvExperienceResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvExpertiseResource} partnerUserCvExpertiseResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvExpertiseCreate(partnerUserId: string, partnerUserCvExpertiseResource: PartnerUserCvExpertiseResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvExpertiseCreate.');
            }
            // verify required parameter 'partnerUserCvExpertiseResource' is not null or undefined
            if (partnerUserCvExpertiseResource === null || partnerUserCvExpertiseResource === undefined) {
                throw new RequiredError('partnerUserCvExpertiseResource','Required parameter partnerUserCvExpertiseResource was null or undefined when calling cvExpertiseCreate.');
            }
            const localVarPath = `/cv/{partner_user_id}/expertise`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerUserCvExpertiseResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerUserCvExpertiseResource || {}) : (partnerUserCvExpertiseResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} expertiseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvExpertiseDelete(partnerUserId: string, expertiseId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvExpertiseDelete.');
            }
            // verify required parameter 'expertiseId' is not null or undefined
            if (expertiseId === null || expertiseId === undefined) {
                throw new RequiredError('expertiseId','Required parameter expertiseId was null or undefined when calling cvExpertiseDelete.');
            }
            const localVarPath = `/cv/{partner_user_id}/expertise/{expertise_id}`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)))
                .replace(`{${"expertise_id"}}`, encodeURIComponent(String(expertiseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvExpertiseResource} partnerUserCvExpertiseResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvExpertiseUpdate(partnerUserId: string, partnerUserCvExpertiseResource: PartnerUserCvExpertiseResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvExpertiseUpdate.');
            }
            // verify required parameter 'partnerUserCvExpertiseResource' is not null or undefined
            if (partnerUserCvExpertiseResource === null || partnerUserCvExpertiseResource === undefined) {
                throw new RequiredError('partnerUserCvExpertiseResource','Required parameter partnerUserCvExpertiseResource was null or undefined when calling cvExpertiseUpdate.');
            }
            const localVarPath = `/cv/{partner_user_id}/expertise`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerUserCvExpertiseResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerUserCvExpertiseResource || {}) : (partnerUserCvExpertiseResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvGet(partnerUserId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvGet.');
            }
            const localVarPath = `/cv/{partner_user_id}`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [partnerId]
         * @param {number} [partnerUserId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvIndex(partnerId?: number, partnerUserId?: number, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/cv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (partnerUserId !== undefined) {
                localVarQueryParameter['partner_user_id'] = partnerUserId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvLanguageResource} partnerUserCvLanguageResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvLanguageCreate(partnerUserId: string, partnerUserCvLanguageResource: PartnerUserCvLanguageResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvLanguageCreate.');
            }
            // verify required parameter 'partnerUserCvLanguageResource' is not null or undefined
            if (partnerUserCvLanguageResource === null || partnerUserCvLanguageResource === undefined) {
                throw new RequiredError('partnerUserCvLanguageResource','Required parameter partnerUserCvLanguageResource was null or undefined when calling cvLanguageCreate.');
            }
            const localVarPath = `/cv/{partner_user_id}/language`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerUserCvLanguageResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerUserCvLanguageResource || {}) : (partnerUserCvLanguageResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} languageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvLanguageDelete(partnerUserId: string, languageId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvLanguageDelete.');
            }
            // verify required parameter 'languageId' is not null or undefined
            if (languageId === null || languageId === undefined) {
                throw new RequiredError('languageId','Required parameter languageId was null or undefined when calling cvLanguageDelete.');
            }
            const localVarPath = `/cv/{partner_user_id}/language/{language_id}`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)))
                .replace(`{${"language_id"}}`, encodeURIComponent(String(languageId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvLanguageResource} partnerUserCvLanguageResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvLanguageUpdate(partnerUserId: string, partnerUserCvLanguageResource: PartnerUserCvLanguageResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvLanguageUpdate.');
            }
            // verify required parameter 'partnerUserCvLanguageResource' is not null or undefined
            if (partnerUserCvLanguageResource === null || partnerUserCvLanguageResource === undefined) {
                throw new RequiredError('partnerUserCvLanguageResource','Required parameter partnerUserCvLanguageResource was null or undefined when calling cvLanguageUpdate.');
            }
            const localVarPath = `/cv/{partner_user_id}/language`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerUserCvLanguageResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerUserCvLanguageResource || {}) : (partnerUserCvLanguageResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvSocialDelete(partnerUserId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvSocialDelete.');
            }
            const localVarPath = `/cv/cv/{partner_user_id}/social`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvSocialDelete_1(partnerUserId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvSocialDelete_1.');
            }
            const localVarPath = `/cv/{partner_user_id}/social`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvSocialMediaResource} partnerUserCvSocialMediaResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvSocialUpdate(partnerUserId: string, partnerUserCvSocialMediaResource: PartnerUserCvSocialMediaResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling cvSocialUpdate.');
            }
            // verify required parameter 'partnerUserCvSocialMediaResource' is not null or undefined
            if (partnerUserCvSocialMediaResource === null || partnerUserCvSocialMediaResource === undefined) {
                throw new RequiredError('partnerUserCvSocialMediaResource','Required parameter partnerUserCvSocialMediaResource was null or undefined when calling cvSocialUpdate.');
            }
            const localVarPath = `/cv/{partner_user_id}/social`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerUserCvSocialMediaResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerUserCvSocialMediaResource || {}) : (partnerUserCvSocialMediaResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CvApi - functional programming interface
 * @export
 */
export const CvApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvAwardResource} partnerUserCvAwardResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvAwardCreate(partnerUserId: string, partnerUserCvAwardResource: PartnerUserCvAwardResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserCvAwardResource> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvAwardCreate(partnerUserId, partnerUserCvAwardResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} awardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvAwardDelete(partnerUserId: string, awardId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvAwardDelete(partnerUserId, awardId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvAwardResource} partnerUserCvAwardResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvAwardUpdate(partnerUserId: string, partnerUserCvAwardResource: PartnerUserCvAwardResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserCvAwardResource> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvAwardUpdate(partnerUserId, partnerUserCvAwardResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvBadgeResource} partnerUserCvBadgeResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvBadgeCreate(partnerUserId: string, partnerUserCvBadgeResource: PartnerUserCvBadgeResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CertificationResource> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvBadgeCreate(partnerUserId, partnerUserCvBadgeResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} badgeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvBadgeDelete(partnerUserId: string, badgeId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvBadgeDelete(partnerUserId, badgeId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvCourseResource} partnerUserCvCourseResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvCourseCreate(partnerUserId: string, partnerUserCvCourseResource: PartnerUserCvCourseResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserCvCourseResource> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvCourseCreate(partnerUserId, partnerUserCvCourseResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvCourseDelete(partnerUserId: string, courseId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvCourseDelete(partnerUserId, courseId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvCourseResource} partnerUserCvCourseResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvCourseUpdate(partnerUserId: string, partnerUserCvCourseResource: PartnerUserCvCourseResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserCvCourseResource> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvCourseUpdate(partnerUserId, partnerUserCvCourseResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvDescriptionResource} partnerUserCvDescriptionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvDescriptionCreate(partnerUserId: string, partnerUserCvDescriptionResource: PartnerUserCvDescriptionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserCvDescriptionResource> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvDescriptionCreate(partnerUserId, partnerUserCvDescriptionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} descriptionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvDescriptionDelete(partnerUserId: string, descriptionId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvDescriptionDelete(partnerUserId, descriptionId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvDescriptionResource} partnerUserCvDescriptionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvDescriptionUpdate(partnerUserId: string, partnerUserCvDescriptionResource: PartnerUserCvDescriptionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserCvDescriptionResource> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvDescriptionUpdate(partnerUserId, partnerUserCvDescriptionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvEducationResource} partnerUserCvEducationResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvEducationCreate(partnerUserId: string, partnerUserCvEducationResource: PartnerUserCvEducationResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserCvEducationResource> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvEducationCreate(partnerUserId, partnerUserCvEducationResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} educationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvEducationDelete(partnerUserId: string, educationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvEducationDelete(partnerUserId, educationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvEducationResource} partnerUserCvEducationResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvEducationUpdate(partnerUserId: string, partnerUserCvEducationResource: PartnerUserCvEducationResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserCvEducationResource> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvEducationUpdate(partnerUserId, partnerUserCvEducationResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvExperienceResource} partnerUserCvExperienceResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvExperienceCreate(partnerUserId: string, partnerUserCvExperienceResource: PartnerUserCvExperienceResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserCvExperienceResource> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvExperienceCreate(partnerUserId, partnerUserCvExperienceResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} experienceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvExperienceDelete(partnerUserId: string, experienceId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvExperienceDelete(partnerUserId, experienceId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvExperienceResource} partnerUserCvExperienceResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvExperienceUpdate(partnerUserId: string, partnerUserCvExperienceResource: PartnerUserCvExperienceResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserCvExperienceResource> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvExperienceUpdate(partnerUserId, partnerUserCvExperienceResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvExpertiseResource} partnerUserCvExpertiseResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvExpertiseCreate(partnerUserId: string, partnerUserCvExpertiseResource: PartnerUserCvExpertiseResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserCvExpertiseResource> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvExpertiseCreate(partnerUserId, partnerUserCvExpertiseResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} expertiseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvExpertiseDelete(partnerUserId: string, expertiseId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvExpertiseDelete(partnerUserId, expertiseId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvExpertiseResource} partnerUserCvExpertiseResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvExpertiseUpdate(partnerUserId: string, partnerUserCvExpertiseResource: PartnerUserCvExpertiseResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserCvExpertiseResource> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvExpertiseUpdate(partnerUserId, partnerUserCvExpertiseResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvGet(partnerUserId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserCvResource> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvGet(partnerUserId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} [partnerId]
         * @param {number} [partnerUserId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvIndex(partnerId?: number, partnerUserId?: number, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserCvResult> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvIndex(partnerId, partnerUserId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvLanguageResource} partnerUserCvLanguageResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvLanguageCreate(partnerUserId: string, partnerUserCvLanguageResource: PartnerUserCvLanguageResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserCvLanguageResource> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvLanguageCreate(partnerUserId, partnerUserCvLanguageResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} languageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvLanguageDelete(partnerUserId: string, languageId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvLanguageDelete(partnerUserId, languageId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvLanguageResource} partnerUserCvLanguageResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvLanguageUpdate(partnerUserId: string, partnerUserCvLanguageResource: PartnerUserCvLanguageResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserCvLanguageResource> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvLanguageUpdate(partnerUserId, partnerUserCvLanguageResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvSocialDelete(partnerUserId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvSocialDelete(partnerUserId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvSocialDelete_1(partnerUserId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvSocialDelete_1(partnerUserId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvSocialMediaResource} partnerUserCvSocialMediaResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvSocialUpdate(partnerUserId: string, partnerUserCvSocialMediaResource: PartnerUserCvSocialMediaResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserCvSocialMediaResource> {
            const localVarFetchArgs = CvApiFetchParamCreator(configuration).cvSocialUpdate(partnerUserId, partnerUserCvSocialMediaResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CvApi - factory interface
 * @export
 */
export const CvApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvAwardResource} partnerUserCvAwardResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvAwardCreate(partnerUserId: string, partnerUserCvAwardResource: PartnerUserCvAwardResource, options?: any) {
            return CvApiFp(configuration).cvAwardCreate(partnerUserId, partnerUserCvAwardResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} awardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvAwardDelete(partnerUserId: string, awardId: string, options?: any) {
            return CvApiFp(configuration).cvAwardDelete(partnerUserId, awardId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvAwardResource} partnerUserCvAwardResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvAwardUpdate(partnerUserId: string, partnerUserCvAwardResource: PartnerUserCvAwardResource, options?: any) {
            return CvApiFp(configuration).cvAwardUpdate(partnerUserId, partnerUserCvAwardResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvBadgeResource} partnerUserCvBadgeResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvBadgeCreate(partnerUserId: string, partnerUserCvBadgeResource: PartnerUserCvBadgeResource, options?: any) {
            return CvApiFp(configuration).cvBadgeCreate(partnerUserId, partnerUserCvBadgeResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} badgeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvBadgeDelete(partnerUserId: string, badgeId: string, options?: any) {
            return CvApiFp(configuration).cvBadgeDelete(partnerUserId, badgeId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvCourseResource} partnerUserCvCourseResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvCourseCreate(partnerUserId: string, partnerUserCvCourseResource: PartnerUserCvCourseResource, options?: any) {
            return CvApiFp(configuration).cvCourseCreate(partnerUserId, partnerUserCvCourseResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvCourseDelete(partnerUserId: string, courseId: string, options?: any) {
            return CvApiFp(configuration).cvCourseDelete(partnerUserId, courseId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvCourseResource} partnerUserCvCourseResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvCourseUpdate(partnerUserId: string, partnerUserCvCourseResource: PartnerUserCvCourseResource, options?: any) {
            return CvApiFp(configuration).cvCourseUpdate(partnerUserId, partnerUserCvCourseResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvDescriptionResource} partnerUserCvDescriptionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvDescriptionCreate(partnerUserId: string, partnerUserCvDescriptionResource: PartnerUserCvDescriptionResource, options?: any) {
            return CvApiFp(configuration).cvDescriptionCreate(partnerUserId, partnerUserCvDescriptionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} descriptionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvDescriptionDelete(partnerUserId: string, descriptionId: string, options?: any) {
            return CvApiFp(configuration).cvDescriptionDelete(partnerUserId, descriptionId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvDescriptionResource} partnerUserCvDescriptionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvDescriptionUpdate(partnerUserId: string, partnerUserCvDescriptionResource: PartnerUserCvDescriptionResource, options?: any) {
            return CvApiFp(configuration).cvDescriptionUpdate(partnerUserId, partnerUserCvDescriptionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvEducationResource} partnerUserCvEducationResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvEducationCreate(partnerUserId: string, partnerUserCvEducationResource: PartnerUserCvEducationResource, options?: any) {
            return CvApiFp(configuration).cvEducationCreate(partnerUserId, partnerUserCvEducationResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} educationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvEducationDelete(partnerUserId: string, educationId: string, options?: any) {
            return CvApiFp(configuration).cvEducationDelete(partnerUserId, educationId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvEducationResource} partnerUserCvEducationResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvEducationUpdate(partnerUserId: string, partnerUserCvEducationResource: PartnerUserCvEducationResource, options?: any) {
            return CvApiFp(configuration).cvEducationUpdate(partnerUserId, partnerUserCvEducationResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvExperienceResource} partnerUserCvExperienceResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvExperienceCreate(partnerUserId: string, partnerUserCvExperienceResource: PartnerUserCvExperienceResource, options?: any) {
            return CvApiFp(configuration).cvExperienceCreate(partnerUserId, partnerUserCvExperienceResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} experienceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvExperienceDelete(partnerUserId: string, experienceId: string, options?: any) {
            return CvApiFp(configuration).cvExperienceDelete(partnerUserId, experienceId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvExperienceResource} partnerUserCvExperienceResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvExperienceUpdate(partnerUserId: string, partnerUserCvExperienceResource: PartnerUserCvExperienceResource, options?: any) {
            return CvApiFp(configuration).cvExperienceUpdate(partnerUserId, partnerUserCvExperienceResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvExpertiseResource} partnerUserCvExpertiseResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvExpertiseCreate(partnerUserId: string, partnerUserCvExpertiseResource: PartnerUserCvExpertiseResource, options?: any) {
            return CvApiFp(configuration).cvExpertiseCreate(partnerUserId, partnerUserCvExpertiseResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} expertiseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvExpertiseDelete(partnerUserId: string, expertiseId: string, options?: any) {
            return CvApiFp(configuration).cvExpertiseDelete(partnerUserId, expertiseId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvExpertiseResource} partnerUserCvExpertiseResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvExpertiseUpdate(partnerUserId: string, partnerUserCvExpertiseResource: PartnerUserCvExpertiseResource, options?: any) {
            return CvApiFp(configuration).cvExpertiseUpdate(partnerUserId, partnerUserCvExpertiseResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvGet(partnerUserId: string, options?: any) {
            return CvApiFp(configuration).cvGet(partnerUserId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} [partnerId]
         * @param {number} [partnerUserId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvIndex(partnerId?: number, partnerUserId?: number, limit?: number, page?: number, query?: string, options?: any) {
            return CvApiFp(configuration).cvIndex(partnerId, partnerUserId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvLanguageResource} partnerUserCvLanguageResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvLanguageCreate(partnerUserId: string, partnerUserCvLanguageResource: PartnerUserCvLanguageResource, options?: any) {
            return CvApiFp(configuration).cvLanguageCreate(partnerUserId, partnerUserCvLanguageResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} languageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvLanguageDelete(partnerUserId: string, languageId: string, options?: any) {
            return CvApiFp(configuration).cvLanguageDelete(partnerUserId, languageId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvLanguageResource} partnerUserCvLanguageResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvLanguageUpdate(partnerUserId: string, partnerUserCvLanguageResource: PartnerUserCvLanguageResource, options?: any) {
            return CvApiFp(configuration).cvLanguageUpdate(partnerUserId, partnerUserCvLanguageResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvSocialDelete(partnerUserId: string, options?: any) {
            return CvApiFp(configuration).cvSocialDelete(partnerUserId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvSocialDelete_1(partnerUserId: string, options?: any) {
            return CvApiFp(configuration).cvSocialDelete_1(partnerUserId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserCvSocialMediaResource} partnerUserCvSocialMediaResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvSocialUpdate(partnerUserId: string, partnerUserCvSocialMediaResource: PartnerUserCvSocialMediaResource, options?: any) {
            return CvApiFp(configuration).cvSocialUpdate(partnerUserId, partnerUserCvSocialMediaResource, options)(fetch, basePath);
        },
    };
};

/**
 * CvApi - object-oriented interface
 * @export
 * @class CvApi
 * @extends {BaseAPI}
 */
export class CvApi extends BaseAPI {
    /**
     *
     * @param {string} partnerUserId
     * @param {PartnerUserCvAwardResource} partnerUserCvAwardResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvAwardCreate(partnerUserId: string, partnerUserCvAwardResource: PartnerUserCvAwardResource, options?: any) {
        return CvApiFp(this.configuration).cvAwardCreate(partnerUserId, partnerUserCvAwardResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {string} awardId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvAwardDelete(partnerUserId: string, awardId: string, options?: any) {
        return CvApiFp(this.configuration).cvAwardDelete(partnerUserId, awardId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {PartnerUserCvAwardResource} partnerUserCvAwardResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvAwardUpdate(partnerUserId: string, partnerUserCvAwardResource: PartnerUserCvAwardResource, options?: any) {
        return CvApiFp(this.configuration).cvAwardUpdate(partnerUserId, partnerUserCvAwardResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {PartnerUserCvBadgeResource} partnerUserCvBadgeResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvBadgeCreate(partnerUserId: string, partnerUserCvBadgeResource: PartnerUserCvBadgeResource, options?: any) {
        return CvApiFp(this.configuration).cvBadgeCreate(partnerUserId, partnerUserCvBadgeResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {string} badgeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvBadgeDelete(partnerUserId: string, badgeId: string, options?: any) {
        return CvApiFp(this.configuration).cvBadgeDelete(partnerUserId, badgeId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {PartnerUserCvCourseResource} partnerUserCvCourseResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvCourseCreate(partnerUserId: string, partnerUserCvCourseResource: PartnerUserCvCourseResource, options?: any) {
        return CvApiFp(this.configuration).cvCourseCreate(partnerUserId, partnerUserCvCourseResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {string} courseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvCourseDelete(partnerUserId: string, courseId: string, options?: any) {
        return CvApiFp(this.configuration).cvCourseDelete(partnerUserId, courseId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {PartnerUserCvCourseResource} partnerUserCvCourseResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvCourseUpdate(partnerUserId: string, partnerUserCvCourseResource: PartnerUserCvCourseResource, options?: any) {
        return CvApiFp(this.configuration).cvCourseUpdate(partnerUserId, partnerUserCvCourseResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {PartnerUserCvDescriptionResource} partnerUserCvDescriptionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvDescriptionCreate(partnerUserId: string, partnerUserCvDescriptionResource: PartnerUserCvDescriptionResource, options?: any) {
        return CvApiFp(this.configuration).cvDescriptionCreate(partnerUserId, partnerUserCvDescriptionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {string} descriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvDescriptionDelete(partnerUserId: string, descriptionId: string, options?: any) {
        return CvApiFp(this.configuration).cvDescriptionDelete(partnerUserId, descriptionId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {PartnerUserCvDescriptionResource} partnerUserCvDescriptionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvDescriptionUpdate(partnerUserId: string, partnerUserCvDescriptionResource: PartnerUserCvDescriptionResource, options?: any) {
        return CvApiFp(this.configuration).cvDescriptionUpdate(partnerUserId, partnerUserCvDescriptionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {PartnerUserCvEducationResource} partnerUserCvEducationResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvEducationCreate(partnerUserId: string, partnerUserCvEducationResource: PartnerUserCvEducationResource, options?: any) {
        return CvApiFp(this.configuration).cvEducationCreate(partnerUserId, partnerUserCvEducationResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {string} educationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvEducationDelete(partnerUserId: string, educationId: string, options?: any) {
        return CvApiFp(this.configuration).cvEducationDelete(partnerUserId, educationId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {PartnerUserCvEducationResource} partnerUserCvEducationResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvEducationUpdate(partnerUserId: string, partnerUserCvEducationResource: PartnerUserCvEducationResource, options?: any) {
        return CvApiFp(this.configuration).cvEducationUpdate(partnerUserId, partnerUserCvEducationResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {PartnerUserCvExperienceResource} partnerUserCvExperienceResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvExperienceCreate(partnerUserId: string, partnerUserCvExperienceResource: PartnerUserCvExperienceResource, options?: any) {
        return CvApiFp(this.configuration).cvExperienceCreate(partnerUserId, partnerUserCvExperienceResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {string} experienceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvExperienceDelete(partnerUserId: string, experienceId: string, options?: any) {
        return CvApiFp(this.configuration).cvExperienceDelete(partnerUserId, experienceId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {PartnerUserCvExperienceResource} partnerUserCvExperienceResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvExperienceUpdate(partnerUserId: string, partnerUserCvExperienceResource: PartnerUserCvExperienceResource, options?: any) {
        return CvApiFp(this.configuration).cvExperienceUpdate(partnerUserId, partnerUserCvExperienceResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {PartnerUserCvExpertiseResource} partnerUserCvExpertiseResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvExpertiseCreate(partnerUserId: string, partnerUserCvExpertiseResource: PartnerUserCvExpertiseResource, options?: any) {
        return CvApiFp(this.configuration).cvExpertiseCreate(partnerUserId, partnerUserCvExpertiseResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {string} expertiseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvExpertiseDelete(partnerUserId: string, expertiseId: string, options?: any) {
        return CvApiFp(this.configuration).cvExpertiseDelete(partnerUserId, expertiseId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {PartnerUserCvExpertiseResource} partnerUserCvExpertiseResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvExpertiseUpdate(partnerUserId: string, partnerUserCvExpertiseResource: PartnerUserCvExpertiseResource, options?: any) {
        return CvApiFp(this.configuration).cvExpertiseUpdate(partnerUserId, partnerUserCvExpertiseResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvGet(partnerUserId: string, options?: any) {
        return CvApiFp(this.configuration).cvGet(partnerUserId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} [partnerId]
     * @param {number} [partnerUserId]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvIndex(partnerId?: number, partnerUserId?: number, limit?: number, page?: number, query?: string, options?: any) {
        return CvApiFp(this.configuration).cvIndex(partnerId, partnerUserId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {PartnerUserCvLanguageResource} partnerUserCvLanguageResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvLanguageCreate(partnerUserId: string, partnerUserCvLanguageResource: PartnerUserCvLanguageResource, options?: any) {
        return CvApiFp(this.configuration).cvLanguageCreate(partnerUserId, partnerUserCvLanguageResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {string} languageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvLanguageDelete(partnerUserId: string, languageId: string, options?: any) {
        return CvApiFp(this.configuration).cvLanguageDelete(partnerUserId, languageId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {PartnerUserCvLanguageResource} partnerUserCvLanguageResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvLanguageUpdate(partnerUserId: string, partnerUserCvLanguageResource: PartnerUserCvLanguageResource, options?: any) {
        return CvApiFp(this.configuration).cvLanguageUpdate(partnerUserId, partnerUserCvLanguageResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvSocialDelete(partnerUserId: string, options?: any) {
        return CvApiFp(this.configuration).cvSocialDelete(partnerUserId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvSocialDelete_1(partnerUserId: string, options?: any) {
        return CvApiFp(this.configuration).cvSocialDelete_1(partnerUserId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {PartnerUserCvSocialMediaResource} partnerUserCvSocialMediaResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvApi
     */
    public cvSocialUpdate(partnerUserId: string, partnerUserCvSocialMediaResource: PartnerUserCvSocialMediaResource, options?: any) {
        return CvApiFp(this.configuration).cvSocialUpdate(partnerUserId, partnerUserCvSocialMediaResource, options)(this.fetch, this.basePath);
    }

}

/**
 * DatafieldsApi - fetch parameter creator
 * @export
 */
export const DatafieldsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [industryId] List data fields by industry_id
         * @param {string} [tag] List data fields by tag
         * @param {string} [type] List data fields by type
         * @param {boolean} [isActive] Filter by whether a data field is active
         * @param {string} [identifier] Filter data fields by identifier
         * @param {string} [geoCode] Filter data fields by geo_code
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datafieldsIndex(industryId?: string, tag?: string, type?: string, isActive?: boolean, identifier?: string, geoCode?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/datafields`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (industryId !== undefined) {
                localVarQueryParameter['industry_id'] = industryId;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }

            if (identifier !== undefined) {
                localVarQueryParameter['identifier'] = identifier;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DatafieldsApi - functional programming interface
 * @export
 */
export const DatafieldsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [industryId] List data fields by industry_id
         * @param {string} [tag] List data fields by tag
         * @param {string} [type] List data fields by type
         * @param {boolean} [isActive] Filter by whether a data field is active
         * @param {string} [identifier] Filter data fields by identifier
         * @param {string} [geoCode] Filter data fields by geo_code
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datafieldsIndex(industryId?: string, tag?: string, type?: string, isActive?: boolean, identifier?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DataFieldResult> {
            const localVarFetchArgs = DatafieldsApiFetchParamCreator(configuration).datafieldsIndex(industryId, tag, type, isActive, identifier, geoCode, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DatafieldsApi - factory interface
 * @export
 */
export const DatafieldsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} [industryId] List data fields by industry_id
         * @param {string} [tag] List data fields by tag
         * @param {string} [type] List data fields by type
         * @param {boolean} [isActive] Filter by whether a data field is active
         * @param {string} [identifier] Filter data fields by identifier
         * @param {string} [geoCode] Filter data fields by geo_code
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datafieldsIndex(industryId?: string, tag?: string, type?: string, isActive?: boolean, identifier?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
            return DatafieldsApiFp(configuration).datafieldsIndex(industryId, tag, type, isActive, identifier, geoCode, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * DatafieldsApi - object-oriented interface
 * @export
 * @class DatafieldsApi
 * @extends {BaseAPI}
 */
export class DatafieldsApi extends BaseAPI {
    /**
     *
     * @param {string} [industryId] List data fields by industry_id
     * @param {string} [tag] List data fields by tag
     * @param {string} [type] List data fields by type
     * @param {boolean} [isActive] Filter by whether a data field is active
     * @param {string} [identifier] Filter data fields by identifier
     * @param {string} [geoCode] Filter data fields by geo_code
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatafieldsApi
     */
    public datafieldsIndex(industryId?: string, tag?: string, type?: string, isActive?: boolean, identifier?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
        return DatafieldsApiFp(this.configuration).datafieldsIndex(industryId, tag, type, isActive, identifier, geoCode, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * FeedbacksApi - fetch parameter creator
 * @export
 */
export const FeedbacksApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {FeedbackResource} feedbackResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbacksCreate(feedbackResource: FeedbackResource, options: any = {}): FetchArgs {
            // verify required parameter 'feedbackResource' is not null or undefined
            if (feedbackResource === null || feedbackResource === undefined) {
                throw new RequiredError('feedbackResource','Required parameter feedbackResource was null or undefined when calling feedbacksCreate.');
            }
            const localVarPath = `/feedbacks`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FeedbackResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(feedbackResource || {}) : (feedbackResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedbacksApi - functional programming interface
 * @export
 */
export const FeedbacksApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {FeedbackResource} feedbackResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbacksCreate(feedbackResource: FeedbackResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FeedbackResource> {
            const localVarFetchArgs = FeedbacksApiFetchParamCreator(configuration).feedbacksCreate(feedbackResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * FeedbacksApi - factory interface
 * @export
 */
export const FeedbacksApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {FeedbackResource} feedbackResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbacksCreate(feedbackResource: FeedbackResource, options?: any) {
            return FeedbacksApiFp(configuration).feedbacksCreate(feedbackResource, options)(fetch, basePath);
        },
    };
};

/**
 * FeedbacksApi - object-oriented interface
 * @export
 * @class FeedbacksApi
 * @extends {BaseAPI}
 */
export class FeedbacksApi extends BaseAPI {
    /**
     *
     * @param {FeedbackResource} feedbackResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbacksApi
     */
    public feedbacksCreate(feedbackResource: FeedbackResource, options?: any) {
        return FeedbacksApiFp(this.configuration).feedbacksCreate(feedbackResource, options)(this.fetch, this.basePath);
    }

}

/**
 * FormsApi - fetch parameter creator
 * @export
 */
export const FormsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} [industryId]
         * @param {number} [typeId]
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsIndex(industryId?: number, typeId?: number, geoCode?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/forms`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (industryId !== undefined) {
                localVarQueryParameter['industry_id'] = industryId;
            }

            if (typeId !== undefined) {
                localVarQueryParameter['type_id'] = typeId;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} formId
         * @param {FormResponseResource} formResponseResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsResponsesCreate(formId: string, formResponseResource: FormResponseResource, options: any = {}): FetchArgs {
            // verify required parameter 'formId' is not null or undefined
            if (formId === null || formId === undefined) {
                throw new RequiredError('formId','Required parameter formId was null or undefined when calling formsResponsesCreate.');
            }
            // verify required parameter 'formResponseResource' is not null or undefined
            if (formResponseResource === null || formResponseResource === undefined) {
                throw new RequiredError('formResponseResource','Required parameter formResponseResource was null or undefined when calling formsResponsesCreate.');
            }
            const localVarPath = `/forms/{form_id}/responses`
                .replace(`{${"form_id"}}`, encodeURIComponent(String(formId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FormResponseResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(formResponseResource || {}) : (formResponseResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FormsApi - functional programming interface
 * @export
 */
export const FormsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} [industryId]
         * @param {number} [typeId]
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsIndex(industryId?: number, typeId?: number, geoCode?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FormResult> {
            const localVarFetchArgs = FormsApiFetchParamCreator(configuration).formsIndex(industryId, typeId, geoCode, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} formId
         * @param {FormResponseResource} formResponseResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsResponsesCreate(formId: string, formResponseResource: FormResponseResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FormResponseResource> {
            const localVarFetchArgs = FormsApiFetchParamCreator(configuration).formsResponsesCreate(formId, formResponseResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * FormsApi - factory interface
 * @export
 */
export const FormsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {number} [industryId]
         * @param {number} [typeId]
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsIndex(industryId?: number, typeId?: number, geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
            return FormsApiFp(configuration).formsIndex(industryId, typeId, geoCode, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} formId
         * @param {FormResponseResource} formResponseResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsResponsesCreate(formId: string, formResponseResource: FormResponseResource, options?: any) {
            return FormsApiFp(configuration).formsResponsesCreate(formId, formResponseResource, options)(fetch, basePath);
        },
    };
};

/**
 * FormsApi - object-oriented interface
 * @export
 * @class FormsApi
 * @extends {BaseAPI}
 */
export class FormsApi extends BaseAPI {
    /**
     *
     * @param {number} [industryId]
     * @param {number} [typeId]
     * @param {string} [geoCode]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsApi
     */
    public formsIndex(industryId?: number, typeId?: number, geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
        return FormsApiFp(this.configuration).formsIndex(industryId, typeId, geoCode, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} formId
     * @param {FormResponseResource} formResponseResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsApi
     */
    public formsResponsesCreate(formId: string, formResponseResource: FormResponseResource, options?: any) {
        return FormsApiFp(this.configuration).formsResponsesCreate(formId, formResponseResource, options)(this.fetch, this.basePath);
    }

}

/**
 * IndustriesApi - fetch parameter creator
 * @export
 */
export const IndustriesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        industriesGroupIndex(geoCode?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/industries/group`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all industries.
         * @summary Get all industries.
         * @param {string} [industryId] Industry ID.
         * @param {string} [name] Industry name in its native language.
         * @param {string} [geoCode] Industry geo code origin.
         * @param {boolean} [isActive] Determinate if industry is active or not.
         * @param {number} [limit] Limit of industries per page.
         * @param {string} [parentIndustryId] Parent industry to filter by.
         * @param {string} [identifier] identifier for the industry
         * @param {string} [companyTypeId]
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        industriesIndex(industryId?: string, name?: string, geoCode?: string, isActive?: boolean, limit?: number, parentIndustryId?: string, identifier?: string, companyTypeId?: string, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/industries`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (industryId !== undefined) {
                localVarQueryParameter['industry_id'] = industryId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (parentIndustryId !== undefined) {
                localVarQueryParameter['parent_industry_id'] = parentIndustryId;
            }

            if (identifier !== undefined) {
                localVarQueryParameter['identifier'] = identifier;
            }

            if (companyTypeId !== undefined) {
                localVarQueryParameter['company_type_id'] = companyTypeId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IndustriesApi - functional programming interface
 * @export
 */
export const IndustriesApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        industriesGroupIndex(geoCode?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IndustryGroupResult> {
            const localVarFetchArgs = IndustriesApiFetchParamCreator(configuration).industriesGroupIndex(geoCode, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get all industries.
         * @summary Get all industries.
         * @param {string} [industryId] Industry ID.
         * @param {string} [name] Industry name in its native language.
         * @param {string} [geoCode] Industry geo code origin.
         * @param {boolean} [isActive] Determinate if industry is active or not.
         * @param {number} [limit] Limit of industries per page.
         * @param {string} [parentIndustryId] Parent industry to filter by.
         * @param {string} [identifier] identifier for the industry
         * @param {string} [companyTypeId]
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        industriesIndex(industryId?: string, name?: string, geoCode?: string, isActive?: boolean, limit?: number, parentIndustryId?: string, identifier?: string, companyTypeId?: string, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IndustryResult> {
            const localVarFetchArgs = IndustriesApiFetchParamCreator(configuration).industriesIndex(industryId, name, geoCode, isActive, limit, parentIndustryId, identifier, companyTypeId, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * IndustriesApi - factory interface
 * @export
 */
export const IndustriesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        industriesGroupIndex(geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
            return IndustriesApiFp(configuration).industriesGroupIndex(geoCode, limit, page, query, options)(fetch, basePath);
        },
        /**
         * Get all industries.
         * @summary Get all industries.
         * @param {string} [industryId] Industry ID.
         * @param {string} [name] Industry name in its native language.
         * @param {string} [geoCode] Industry geo code origin.
         * @param {boolean} [isActive] Determinate if industry is active or not.
         * @param {number} [limit] Limit of industries per page.
         * @param {string} [parentIndustryId] Parent industry to filter by.
         * @param {string} [identifier] identifier for the industry
         * @param {string} [companyTypeId]
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        industriesIndex(industryId?: string, name?: string, geoCode?: string, isActive?: boolean, limit?: number, parentIndustryId?: string, identifier?: string, companyTypeId?: string, page?: number, query?: string, options?: any) {
            return IndustriesApiFp(configuration).industriesIndex(industryId, name, geoCode, isActive, limit, parentIndustryId, identifier, companyTypeId, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * IndustriesApi - object-oriented interface
 * @export
 * @class IndustriesApi
 * @extends {BaseAPI}
 */
export class IndustriesApi extends BaseAPI {
    /**
     *
     * @param {string} [geoCode]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndustriesApi
     */
    public industriesGroupIndex(geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
        return IndustriesApiFp(this.configuration).industriesGroupIndex(geoCode, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     * Get all industries.
     * @summary Get all industries.
     * @param {string} [industryId] Industry ID.
     * @param {string} [name] Industry name in its native language.
     * @param {string} [geoCode] Industry geo code origin.
     * @param {boolean} [isActive] Determinate if industry is active or not.
     * @param {number} [limit] Limit of industries per page.
     * @param {string} [parentIndustryId] Parent industry to filter by.
     * @param {string} [identifier] identifier for the industry
     * @param {string} [companyTypeId]
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndustriesApi
     */
    public industriesIndex(industryId?: string, name?: string, geoCode?: string, isActive?: boolean, limit?: number, parentIndustryId?: string, identifier?: string, companyTypeId?: string, page?: number, query?: string, options?: any) {
        return IndustriesApiFp(this.configuration).industriesIndex(industryId, name, geoCode, isActive, limit, parentIndustryId, identifier, companyTypeId, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * InvoicesApi - fetch parameter creator
 * @export
 */
export const InvoicesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesGet(invoiceId: string, options: any = {}): FetchArgs {
            // verify required parameter 'invoiceId' is not null or undefined
            if (invoiceId === null || invoiceId === undefined) {
                throw new RequiredError('invoiceId','Required parameter invoiceId was null or undefined when calling invoicesGet.');
            }
            const localVarPath = `/invoices/{invoice_id}`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [invoiceId] Invoice Id.
         * @param {string} [partnerId] Filter Invoices by a given Partner.
         * @param {string} [clientId] Filter Invoices by a given Client.
         * @param {boolean} [isPaid] Filter invoices by whether or not they are paid.
         * @param {boolean} [isOverdue] Filter invoices by whether they are overdue or not. An invoice is overdue when the payment deadline has passed but the invoice hasn&#39;t been paid.
         * @param {'created_at' | 'paid_at' | 'ascending' | 'descending'} [sort] Desired sorting.
         * @param {string} [geoCode] Geographic Location Code.
         * @param {string} [invoiceNumber] Invoice Number.
         * @param {boolean} [hasSubscriptionLineItem] Filter invoices by whether they are related to subscription or not.
         * @param {string} [query] General query string for searching vouchers.
         * @param {number} [dunningStage] Filter invoices by whether or not they have reached the given dunning stage(s).
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesIndex(invoiceId?: string, partnerId?: string, clientId?: string, isPaid?: boolean, isOverdue?: boolean, sort?: 'created_at' | 'paid_at' | 'ascending' | 'descending', geoCode?: string, invoiceNumber?: string, hasSubscriptionLineItem?: boolean, query?: string, dunningStage?: number, limit?: number, page?: number, options: any = {}): FetchArgs {
            const localVarPath = `/invoices`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoice_id'] = invoiceId;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (isPaid !== undefined) {
                localVarQueryParameter['is_paid'] = isPaid;
            }

            if (isOverdue !== undefined) {
                localVarQueryParameter['is_overdue'] = isOverdue;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (invoiceNumber !== undefined) {
                localVarQueryParameter['invoice_number'] = invoiceNumber;
            }

            if (hasSubscriptionLineItem !== undefined) {
                localVarQueryParameter['has_subscription_line_item'] = hasSubscriptionLineItem;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (dunningStage !== undefined) {
                localVarQueryParameter['dunning_stage'] = dunningStage;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} invoiceId
         * @param {string} voucherRefundRequestId
         * @param {VoucherRefundRequestActionResource} voucherRefundRequestActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesRefundrequestsActionsCreate(invoiceId: string, voucherRefundRequestId: string, voucherRefundRequestActionResource: VoucherRefundRequestActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'invoiceId' is not null or undefined
            if (invoiceId === null || invoiceId === undefined) {
                throw new RequiredError('invoiceId','Required parameter invoiceId was null or undefined when calling invoicesRefundrequestsActionsCreate.');
            }
            // verify required parameter 'voucherRefundRequestId' is not null or undefined
            if (voucherRefundRequestId === null || voucherRefundRequestId === undefined) {
                throw new RequiredError('voucherRefundRequestId','Required parameter voucherRefundRequestId was null or undefined when calling invoicesRefundrequestsActionsCreate.');
            }
            // verify required parameter 'voucherRefundRequestActionResource' is not null or undefined
            if (voucherRefundRequestActionResource === null || voucherRefundRequestActionResource === undefined) {
                throw new RequiredError('voucherRefundRequestActionResource','Required parameter voucherRefundRequestActionResource was null or undefined when calling invoicesRefundrequestsActionsCreate.');
            }
            const localVarPath = `/invoices/{invoice_id}/refundrequests/{voucher_refund_request_id}/actions`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"voucher_refund_request_id"}}`, encodeURIComponent(String(voucherRefundRequestId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"VoucherRefundRequestActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(voucherRefundRequestActionResource || {}) : (voucherRefundRequestActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} invoiceId
         * @param {VoucherRefundRequestResource} voucherRefundRequestResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesRefundrequestsCreate(invoiceId: string, voucherRefundRequestResource: VoucherRefundRequestResource, options: any = {}): FetchArgs {
            // verify required parameter 'invoiceId' is not null or undefined
            if (invoiceId === null || invoiceId === undefined) {
                throw new RequiredError('invoiceId','Required parameter invoiceId was null or undefined when calling invoicesRefundrequestsCreate.');
            }
            // verify required parameter 'voucherRefundRequestResource' is not null or undefined
            if (voucherRefundRequestResource === null || voucherRefundRequestResource === undefined) {
                throw new RequiredError('voucherRefundRequestResource','Required parameter voucherRefundRequestResource was null or undefined when calling invoicesRefundrequestsCreate.');
            }
            const localVarPath = `/invoices/{invoice_id}/refundrequests`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"VoucherRefundRequestResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(voucherRefundRequestResource || {}) : (voucherRefundRequestResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} voucherRefundRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesRefundrequestsGet(voucherRefundRequestId: string, options: any = {}): FetchArgs {
            // verify required parameter 'voucherRefundRequestId' is not null or undefined
            if (voucherRefundRequestId === null || voucherRefundRequestId === undefined) {
                throw new RequiredError('voucherRefundRequestId','Required parameter voucherRefundRequestId was null or undefined when calling invoicesRefundrequestsGet.');
            }
            const localVarPath = `/invoices/refundrequests/{voucher_refund_request_id}`
                .replace(`{${"voucher_refund_request_id"}}`, encodeURIComponent(String(voucherRefundRequestId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} invoiceId
         * @param {string} voucherRefundRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesRefundrequestsGet_1(invoiceId: string, voucherRefundRequestId: string, options: any = {}): FetchArgs {
            // verify required parameter 'invoiceId' is not null or undefined
            if (invoiceId === null || invoiceId === undefined) {
                throw new RequiredError('invoiceId','Required parameter invoiceId was null or undefined when calling invoicesRefundrequestsGet_1.');
            }
            // verify required parameter 'voucherRefundRequestId' is not null or undefined
            if (voucherRefundRequestId === null || voucherRefundRequestId === undefined) {
                throw new RequiredError('voucherRefundRequestId','Required parameter voucherRefundRequestId was null or undefined when calling invoicesRefundrequestsGet_1.');
            }
            const localVarPath = `/invoices/{invoice_id}/refundrequests/{voucher_refund_request_id}`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"voucher_refund_request_id"}}`, encodeURIComponent(String(voucherRefundRequestId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [id] Voucher refund request id.
         * @param {string} [voucherId] Invoice Id.
         * @param {string} [partnerId] Filter Invoices by a given Partner.
         * @param {'created_at' | 'requested_amount_excl_vat' | 'status' | 'ascending' | 'descending'} [sort] Desired sorting.
         * @param {string} [status] Voucher refund request status.
         * @param {string} [geoCode] Partner geo code.
         * @param {string} [assignedEmployeeId] Filter by assigned employee ID.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesRefundrequestsIndex(id?: string, voucherId?: string, partnerId?: string, sort?: 'created_at' | 'requested_amount_excl_vat' | 'status' | 'ascending' | 'descending', status?: string, geoCode?: string, assignedEmployeeId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/invoices/refundrequests`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (voucherId !== undefined) {
                localVarQueryParameter['voucher_id'] = voucherId;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (assignedEmployeeId !== undefined) {
                localVarQueryParameter['assigned_employee_id'] = assignedEmployeeId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} voucherId
         * @param {string} [id] Voucher refund request id.
         * @param {string} [partnerId] Filter Invoices by a given Partner.
         * @param {'created_at' | 'requested_amount_excl_vat' | 'status' | 'ascending' | 'descending'} [sort] Desired sorting.
         * @param {string} [status] Voucher refund request status.
         * @param {string} [geoCode] Partner geo code.
         * @param {string} [assignedEmployeeId] Filter by assigned employee ID.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesRefundrequestsIndex_2(voucherId: string, id?: string, partnerId?: string, sort?: 'created_at' | 'requested_amount_excl_vat' | 'status' | 'ascending' | 'descending', status?: string, geoCode?: string, assignedEmployeeId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'voucherId' is not null or undefined
            if (voucherId === null || voucherId === undefined) {
                throw new RequiredError('voucherId','Required parameter voucherId was null or undefined when calling invoicesRefundrequestsIndex_2.');
            }
            const localVarPath = `/invoices/{voucher_id}/refundrequests`
                .replace(`{${"voucher_id"}}`, encodeURIComponent(String(voucherId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (assignedEmployeeId !== undefined) {
                localVarQueryParameter['assigned_employee_id'] = assignedEmployeeId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoicesApi - functional programming interface
 * @export
 */
export const InvoicesApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesGet(invoiceId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InvoiceResource> {
            const localVarFetchArgs = InvoicesApiFetchParamCreator(configuration).invoicesGet(invoiceId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [invoiceId] Invoice Id.
         * @param {string} [partnerId] Filter Invoices by a given Partner.
         * @param {string} [clientId] Filter Invoices by a given Client.
         * @param {boolean} [isPaid] Filter invoices by whether or not they are paid.
         * @param {boolean} [isOverdue] Filter invoices by whether they are overdue or not. An invoice is overdue when the payment deadline has passed but the invoice hasn&#39;t been paid.
         * @param {'created_at' | 'paid_at' | 'ascending' | 'descending'} [sort] Desired sorting.
         * @param {string} [geoCode] Geographic Location Code.
         * @param {string} [invoiceNumber] Invoice Number.
         * @param {boolean} [hasSubscriptionLineItem] Filter invoices by whether they are related to subscription or not.
         * @param {string} [query] General query string for searching vouchers.
         * @param {number} [dunningStage] Filter invoices by whether or not they have reached the given dunning stage(s).
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesIndex(invoiceId?: string, partnerId?: string, clientId?: string, isPaid?: boolean, isOverdue?: boolean, sort?: 'created_at' | 'paid_at' | 'ascending' | 'descending', geoCode?: string, invoiceNumber?: string, hasSubscriptionLineItem?: boolean, query?: string, dunningStage?: number, limit?: number, page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InvoiceResult> {
            const localVarFetchArgs = InvoicesApiFetchParamCreator(configuration).invoicesIndex(invoiceId, partnerId, clientId, isPaid, isOverdue, sort, geoCode, invoiceNumber, hasSubscriptionLineItem, query, dunningStage, limit, page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} invoiceId
         * @param {string} voucherRefundRequestId
         * @param {VoucherRefundRequestActionResource} voucherRefundRequestActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesRefundrequestsActionsCreate(invoiceId: string, voucherRefundRequestId: string, voucherRefundRequestActionResource: VoucherRefundRequestActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VoucherRefundRequestResource> {
            const localVarFetchArgs = InvoicesApiFetchParamCreator(configuration).invoicesRefundrequestsActionsCreate(invoiceId, voucherRefundRequestId, voucherRefundRequestActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} invoiceId
         * @param {VoucherRefundRequestResource} voucherRefundRequestResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesRefundrequestsCreate(invoiceId: string, voucherRefundRequestResource: VoucherRefundRequestResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VoucherRefundRequestResource> {
            const localVarFetchArgs = InvoicesApiFetchParamCreator(configuration).invoicesRefundrequestsCreate(invoiceId, voucherRefundRequestResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} voucherRefundRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesRefundrequestsGet(voucherRefundRequestId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VoucherRefundRequestResource> {
            const localVarFetchArgs = InvoicesApiFetchParamCreator(configuration).invoicesRefundrequestsGet(voucherRefundRequestId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} invoiceId
         * @param {string} voucherRefundRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesRefundrequestsGet_1(invoiceId: string, voucherRefundRequestId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VoucherRefundRequestResource> {
            const localVarFetchArgs = InvoicesApiFetchParamCreator(configuration).invoicesRefundrequestsGet_1(invoiceId, voucherRefundRequestId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [id] Voucher refund request id.
         * @param {string} [voucherId] Invoice Id.
         * @param {string} [partnerId] Filter Invoices by a given Partner.
         * @param {'created_at' | 'requested_amount_excl_vat' | 'status' | 'ascending' | 'descending'} [sort] Desired sorting.
         * @param {string} [status] Voucher refund request status.
         * @param {string} [geoCode] Partner geo code.
         * @param {string} [assignedEmployeeId] Filter by assigned employee ID.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesRefundrequestsIndex(id?: string, voucherId?: string, partnerId?: string, sort?: 'created_at' | 'requested_amount_excl_vat' | 'status' | 'ascending' | 'descending', status?: string, geoCode?: string, assignedEmployeeId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VoucherRefundRequestResult> {
            const localVarFetchArgs = InvoicesApiFetchParamCreator(configuration).invoicesRefundrequestsIndex(id, voucherId, partnerId, sort, status, geoCode, assignedEmployeeId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} voucherId
         * @param {string} [id] Voucher refund request id.
         * @param {string} [partnerId] Filter Invoices by a given Partner.
         * @param {'created_at' | 'requested_amount_excl_vat' | 'status' | 'ascending' | 'descending'} [sort] Desired sorting.
         * @param {string} [status] Voucher refund request status.
         * @param {string} [geoCode] Partner geo code.
         * @param {string} [assignedEmployeeId] Filter by assigned employee ID.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesRefundrequestsIndex_2(voucherId: string, id?: string, partnerId?: string, sort?: 'created_at' | 'requested_amount_excl_vat' | 'status' | 'ascending' | 'descending', status?: string, geoCode?: string, assignedEmployeeId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VoucherRefundRequestResult> {
            const localVarFetchArgs = InvoicesApiFetchParamCreator(configuration).invoicesRefundrequestsIndex_2(voucherId, id, partnerId, sort, status, geoCode, assignedEmployeeId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * InvoicesApi - factory interface
 * @export
 */
export const InvoicesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesGet(invoiceId: string, options?: any) {
            return InvoicesApiFp(configuration).invoicesGet(invoiceId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [invoiceId] Invoice Id.
         * @param {string} [partnerId] Filter Invoices by a given Partner.
         * @param {string} [clientId] Filter Invoices by a given Client.
         * @param {boolean} [isPaid] Filter invoices by whether or not they are paid.
         * @param {boolean} [isOverdue] Filter invoices by whether they are overdue or not. An invoice is overdue when the payment deadline has passed but the invoice hasn&#39;t been paid.
         * @param {'created_at' | 'paid_at' | 'ascending' | 'descending'} [sort] Desired sorting.
         * @param {string} [geoCode] Geographic Location Code.
         * @param {string} [invoiceNumber] Invoice Number.
         * @param {boolean} [hasSubscriptionLineItem] Filter invoices by whether they are related to subscription or not.
         * @param {string} [query] General query string for searching vouchers.
         * @param {number} [dunningStage] Filter invoices by whether or not they have reached the given dunning stage(s).
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesIndex(invoiceId?: string, partnerId?: string, clientId?: string, isPaid?: boolean, isOverdue?: boolean, sort?: 'created_at' | 'paid_at' | 'ascending' | 'descending', geoCode?: string, invoiceNumber?: string, hasSubscriptionLineItem?: boolean, query?: string, dunningStage?: number, limit?: number, page?: number, options?: any) {
            return InvoicesApiFp(configuration).invoicesIndex(invoiceId, partnerId, clientId, isPaid, isOverdue, sort, geoCode, invoiceNumber, hasSubscriptionLineItem, query, dunningStage, limit, page, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} invoiceId
         * @param {string} voucherRefundRequestId
         * @param {VoucherRefundRequestActionResource} voucherRefundRequestActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesRefundrequestsActionsCreate(invoiceId: string, voucherRefundRequestId: string, voucherRefundRequestActionResource: VoucherRefundRequestActionResource, options?: any) {
            return InvoicesApiFp(configuration).invoicesRefundrequestsActionsCreate(invoiceId, voucherRefundRequestId, voucherRefundRequestActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} invoiceId
         * @param {VoucherRefundRequestResource} voucherRefundRequestResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesRefundrequestsCreate(invoiceId: string, voucherRefundRequestResource: VoucherRefundRequestResource, options?: any) {
            return InvoicesApiFp(configuration).invoicesRefundrequestsCreate(invoiceId, voucherRefundRequestResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} voucherRefundRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesRefundrequestsGet(voucherRefundRequestId: string, options?: any) {
            return InvoicesApiFp(configuration).invoicesRefundrequestsGet(voucherRefundRequestId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} invoiceId
         * @param {string} voucherRefundRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesRefundrequestsGet_1(invoiceId: string, voucherRefundRequestId: string, options?: any) {
            return InvoicesApiFp(configuration).invoicesRefundrequestsGet_1(invoiceId, voucherRefundRequestId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [id] Voucher refund request id.
         * @param {string} [voucherId] Invoice Id.
         * @param {string} [partnerId] Filter Invoices by a given Partner.
         * @param {'created_at' | 'requested_amount_excl_vat' | 'status' | 'ascending' | 'descending'} [sort] Desired sorting.
         * @param {string} [status] Voucher refund request status.
         * @param {string} [geoCode] Partner geo code.
         * @param {string} [assignedEmployeeId] Filter by assigned employee ID.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesRefundrequestsIndex(id?: string, voucherId?: string, partnerId?: string, sort?: 'created_at' | 'requested_amount_excl_vat' | 'status' | 'ascending' | 'descending', status?: string, geoCode?: string, assignedEmployeeId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return InvoicesApiFp(configuration).invoicesRefundrequestsIndex(id, voucherId, partnerId, sort, status, geoCode, assignedEmployeeId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} voucherId
         * @param {string} [id] Voucher refund request id.
         * @param {string} [partnerId] Filter Invoices by a given Partner.
         * @param {'created_at' | 'requested_amount_excl_vat' | 'status' | 'ascending' | 'descending'} [sort] Desired sorting.
         * @param {string} [status] Voucher refund request status.
         * @param {string} [geoCode] Partner geo code.
         * @param {string} [assignedEmployeeId] Filter by assigned employee ID.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesRefundrequestsIndex_2(voucherId: string, id?: string, partnerId?: string, sort?: 'created_at' | 'requested_amount_excl_vat' | 'status' | 'ascending' | 'descending', status?: string, geoCode?: string, assignedEmployeeId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return InvoicesApiFp(configuration).invoicesRefundrequestsIndex_2(voucherId, id, partnerId, sort, status, geoCode, assignedEmployeeId, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * InvoicesApi - object-oriented interface
 * @export
 * @class InvoicesApi
 * @extends {BaseAPI}
 */
export class InvoicesApi extends BaseAPI {
    /**
     *
     * @param {string} invoiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoicesGet(invoiceId: string, options?: any) {
        return InvoicesApiFp(this.configuration).invoicesGet(invoiceId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [invoiceId] Invoice Id.
     * @param {string} [partnerId] Filter Invoices by a given Partner.
     * @param {string} [clientId] Filter Invoices by a given Client.
     * @param {boolean} [isPaid] Filter invoices by whether or not they are paid.
     * @param {boolean} [isOverdue] Filter invoices by whether they are overdue or not. An invoice is overdue when the payment deadline has passed but the invoice hasn&#39;t been paid.
     * @param {'created_at' | 'paid_at' | 'ascending' | 'descending'} [sort] Desired sorting.
     * @param {string} [geoCode] Geographic Location Code.
     * @param {string} [invoiceNumber] Invoice Number.
     * @param {boolean} [hasSubscriptionLineItem] Filter invoices by whether they are related to subscription or not.
     * @param {string} [query] General query string for searching vouchers.
     * @param {number} [dunningStage] Filter invoices by whether or not they have reached the given dunning stage(s).
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoicesIndex(invoiceId?: string, partnerId?: string, clientId?: string, isPaid?: boolean, isOverdue?: boolean, sort?: 'created_at' | 'paid_at' | 'ascending' | 'descending', geoCode?: string, invoiceNumber?: string, hasSubscriptionLineItem?: boolean, query?: string, dunningStage?: number, limit?: number, page?: number, options?: any) {
        return InvoicesApiFp(this.configuration).invoicesIndex(invoiceId, partnerId, clientId, isPaid, isOverdue, sort, geoCode, invoiceNumber, hasSubscriptionLineItem, query, dunningStage, limit, page, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} invoiceId
     * @param {string} voucherRefundRequestId
     * @param {VoucherRefundRequestActionResource} voucherRefundRequestActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoicesRefundrequestsActionsCreate(invoiceId: string, voucherRefundRequestId: string, voucherRefundRequestActionResource: VoucherRefundRequestActionResource, options?: any) {
        return InvoicesApiFp(this.configuration).invoicesRefundrequestsActionsCreate(invoiceId, voucherRefundRequestId, voucherRefundRequestActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} invoiceId
     * @param {VoucherRefundRequestResource} voucherRefundRequestResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoicesRefundrequestsCreate(invoiceId: string, voucherRefundRequestResource: VoucherRefundRequestResource, options?: any) {
        return InvoicesApiFp(this.configuration).invoicesRefundrequestsCreate(invoiceId, voucherRefundRequestResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} voucherRefundRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoicesRefundrequestsGet(voucherRefundRequestId: string, options?: any) {
        return InvoicesApiFp(this.configuration).invoicesRefundrequestsGet(voucherRefundRequestId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} invoiceId
     * @param {string} voucherRefundRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoicesRefundrequestsGet_1(invoiceId: string, voucherRefundRequestId: string, options?: any) {
        return InvoicesApiFp(this.configuration).invoicesRefundrequestsGet_1(invoiceId, voucherRefundRequestId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [id] Voucher refund request id.
     * @param {string} [voucherId] Invoice Id.
     * @param {string} [partnerId] Filter Invoices by a given Partner.
     * @param {'created_at' | 'requested_amount_excl_vat' | 'status' | 'ascending' | 'descending'} [sort] Desired sorting.
     * @param {string} [status] Voucher refund request status.
     * @param {string} [geoCode] Partner geo code.
     * @param {string} [assignedEmployeeId] Filter by assigned employee ID.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoicesRefundrequestsIndex(id?: string, voucherId?: string, partnerId?: string, sort?: 'created_at' | 'requested_amount_excl_vat' | 'status' | 'ascending' | 'descending', status?: string, geoCode?: string, assignedEmployeeId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return InvoicesApiFp(this.configuration).invoicesRefundrequestsIndex(id, voucherId, partnerId, sort, status, geoCode, assignedEmployeeId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} voucherId
     * @param {string} [id] Voucher refund request id.
     * @param {string} [partnerId] Filter Invoices by a given Partner.
     * @param {'created_at' | 'requested_amount_excl_vat' | 'status' | 'ascending' | 'descending'} [sort] Desired sorting.
     * @param {string} [status] Voucher refund request status.
     * @param {string} [geoCode] Partner geo code.
     * @param {string} [assignedEmployeeId] Filter by assigned employee ID.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoicesRefundrequestsIndex_2(voucherId: string, id?: string, partnerId?: string, sort?: 'created_at' | 'requested_amount_excl_vat' | 'status' | 'ascending' | 'descending', status?: string, geoCode?: string, assignedEmployeeId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return InvoicesApiFp(this.configuration).invoicesRefundrequestsIndex_2(voucherId, id, partnerId, sort, status, geoCode, assignedEmployeeId, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * KpiApi - fetch parameter creator
 * @export
 */
export const KpiApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [kpiId] Identifiers for the kpi&#39;s
         * @param {string} [geoCode] Geographic Location Code.
         * @param {string} [industry] Industry code for the kpi
         * @param {string} [industryId] Industry id for the kpi
         * @param {string} [employeeId] Employee Id
         * @param {string} [partnerId] Partner Id
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiIndex(kpiId?: string, geoCode?: string, industry?: string, industryId?: string, employeeId?: string, partnerId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/kpi`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (kpiId !== undefined) {
                localVarQueryParameter['kpi_id'] = kpiId;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (industry !== undefined) {
                localVarQueryParameter['industry'] = industry;
            }

            if (industryId !== undefined) {
                localVarQueryParameter['industry_id'] = industryId;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employee_id'] = employeeId;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {KpiTargetResource} kpiTargetResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiTargetsCreate(kpiTargetResource: KpiTargetResource, options: any = {}): FetchArgs {
            // verify required parameter 'kpiTargetResource' is not null or undefined
            if (kpiTargetResource === null || kpiTargetResource === undefined) {
                throw new RequiredError('kpiTargetResource','Required parameter kpiTargetResource was null or undefined when calling kpiTargetsCreate.');
            }
            const localVarPath = `/kpi/targets`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KpiTargetResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(kpiTargetResource || {}) : (kpiTargetResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [kpiIdentifier] Identifier for KPI.
         * @param {string} [employeeId] Employee ID to get KPI values for.
         * @param {string} [temporalScope] Which scopes of time to retrieve KPI values for.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiTargetsIndex(kpiIdentifier?: string, employeeId?: string, temporalScope?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/kpi/targets`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (kpiIdentifier !== undefined) {
                localVarQueryParameter['kpi_identifier'] = kpiIdentifier;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employee_id'] = employeeId;
            }

            if (temporalScope !== undefined) {
                localVarQueryParameter['temporal_scope'] = temporalScope;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [kpiIdentifier] Identifier for KPI.
         * @param {string} [employeeId] Employee ID to get KPI values for.
         * @param {string} [temporalScope] Which scopes of time to retrieve KPI values for.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiValuesIndex(kpiIdentifier?: string, employeeId?: string, temporalScope?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/kpi/values`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (kpiIdentifier !== undefined) {
                localVarQueryParameter['kpi_identifier'] = kpiIdentifier;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employee_id'] = employeeId;
            }

            if (temporalScope !== undefined) {
                localVarQueryParameter['temporal_scope'] = temporalScope;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KpiApi - functional programming interface
 * @export
 */
export const KpiApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [kpiId] Identifiers for the kpi&#39;s
         * @param {string} [geoCode] Geographic Location Code.
         * @param {string} [industry] Industry code for the kpi
         * @param {string} [industryId] Industry id for the kpi
         * @param {string} [employeeId] Employee Id
         * @param {string} [partnerId] Partner Id
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiIndex(kpiId?: string, geoCode?: string, industry?: string, industryId?: string, employeeId?: string, partnerId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KPIResult> {
            const localVarFetchArgs = KpiApiFetchParamCreator(configuration).kpiIndex(kpiId, geoCode, industry, industryId, employeeId, partnerId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {KpiTargetResource} kpiTargetResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiTargetsCreate(kpiTargetResource: KpiTargetResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KpiTargetResource> {
            const localVarFetchArgs = KpiApiFetchParamCreator(configuration).kpiTargetsCreate(kpiTargetResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [kpiIdentifier] Identifier for KPI.
         * @param {string} [employeeId] Employee ID to get KPI values for.
         * @param {string} [temporalScope] Which scopes of time to retrieve KPI values for.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiTargetsIndex(kpiIdentifier?: string, employeeId?: string, temporalScope?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KpiTargetResult> {
            const localVarFetchArgs = KpiApiFetchParamCreator(configuration).kpiTargetsIndex(kpiIdentifier, employeeId, temporalScope, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [kpiIdentifier] Identifier for KPI.
         * @param {string} [employeeId] Employee ID to get KPI values for.
         * @param {string} [temporalScope] Which scopes of time to retrieve KPI values for.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiValuesIndex(kpiIdentifier?: string, employeeId?: string, temporalScope?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KpiValueResult> {
            const localVarFetchArgs = KpiApiFetchParamCreator(configuration).kpiValuesIndex(kpiIdentifier, employeeId, temporalScope, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * KpiApi - factory interface
 * @export
 */
export const KpiApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} [kpiId] Identifiers for the kpi&#39;s
         * @param {string} [geoCode] Geographic Location Code.
         * @param {string} [industry] Industry code for the kpi
         * @param {string} [industryId] Industry id for the kpi
         * @param {string} [employeeId] Employee Id
         * @param {string} [partnerId] Partner Id
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiIndex(kpiId?: string, geoCode?: string, industry?: string, industryId?: string, employeeId?: string, partnerId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return KpiApiFp(configuration).kpiIndex(kpiId, geoCode, industry, industryId, employeeId, partnerId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {KpiTargetResource} kpiTargetResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiTargetsCreate(kpiTargetResource: KpiTargetResource, options?: any) {
            return KpiApiFp(configuration).kpiTargetsCreate(kpiTargetResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [kpiIdentifier] Identifier for KPI.
         * @param {string} [employeeId] Employee ID to get KPI values for.
         * @param {string} [temporalScope] Which scopes of time to retrieve KPI values for.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiTargetsIndex(kpiIdentifier?: string, employeeId?: string, temporalScope?: string, limit?: number, page?: number, query?: string, options?: any) {
            return KpiApiFp(configuration).kpiTargetsIndex(kpiIdentifier, employeeId, temporalScope, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [kpiIdentifier] Identifier for KPI.
         * @param {string} [employeeId] Employee ID to get KPI values for.
         * @param {string} [temporalScope] Which scopes of time to retrieve KPI values for.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kpiValuesIndex(kpiIdentifier?: string, employeeId?: string, temporalScope?: string, limit?: number, page?: number, query?: string, options?: any) {
            return KpiApiFp(configuration).kpiValuesIndex(kpiIdentifier, employeeId, temporalScope, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * KpiApi - object-oriented interface
 * @export
 * @class KpiApi
 * @extends {BaseAPI}
 */
export class KpiApi extends BaseAPI {
    /**
     *
     * @param {string} [kpiId] Identifiers for the kpi&#39;s
     * @param {string} [geoCode] Geographic Location Code.
     * @param {string} [industry] Industry code for the kpi
     * @param {string} [industryId] Industry id for the kpi
     * @param {string} [employeeId] Employee Id
     * @param {string} [partnerId] Partner Id
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public kpiIndex(kpiId?: string, geoCode?: string, industry?: string, industryId?: string, employeeId?: string, partnerId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return KpiApiFp(this.configuration).kpiIndex(kpiId, geoCode, industry, industryId, employeeId, partnerId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {KpiTargetResource} kpiTargetResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public kpiTargetsCreate(kpiTargetResource: KpiTargetResource, options?: any) {
        return KpiApiFp(this.configuration).kpiTargetsCreate(kpiTargetResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [kpiIdentifier] Identifier for KPI.
     * @param {string} [employeeId] Employee ID to get KPI values for.
     * @param {string} [temporalScope] Which scopes of time to retrieve KPI values for.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public kpiTargetsIndex(kpiIdentifier?: string, employeeId?: string, temporalScope?: string, limit?: number, page?: number, query?: string, options?: any) {
        return KpiApiFp(this.configuration).kpiTargetsIndex(kpiIdentifier, employeeId, temporalScope, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [kpiIdentifier] Identifier for KPI.
     * @param {string} [employeeId] Employee ID to get KPI values for.
     * @param {string} [temporalScope] Which scopes of time to retrieve KPI values for.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public kpiValuesIndex(kpiIdentifier?: string, employeeId?: string, temporalScope?: string, limit?: number, page?: number, query?: string, options?: any) {
        return KpiApiFp(this.configuration).kpiValuesIndex(kpiIdentifier, employeeId, temporalScope, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * LanguagesApi - fetch parameter creator
 * @export
 */
export const LanguagesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [geoCode] Geo code to display language - default en.
         * @param {string} [isoCode] Language iso code.
         * @param {string} [language] Language translation in geo code.
         * @param {string} [_native] Language in native language.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languagesIndex(geoCode?: string, isoCode?: string, language?: string, _native?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/languages`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (isoCode !== undefined) {
                localVarQueryParameter['iso_code'] = isoCode;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (_native !== undefined) {
                localVarQueryParameter['native'] = _native;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LanguagesApi - functional programming interface
 * @export
 */
export const LanguagesApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [geoCode] Geo code to display language - default en.
         * @param {string} [isoCode] Language iso code.
         * @param {string} [language] Language translation in geo code.
         * @param {string} [_native] Language in native language.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languagesIndex(geoCode?: string, isoCode?: string, language?: string, _native?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LanguageResult> {
            const localVarFetchArgs = LanguagesApiFetchParamCreator(configuration).languagesIndex(geoCode, isoCode, language, _native, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * LanguagesApi - factory interface
 * @export
 */
export const LanguagesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} [geoCode] Geo code to display language - default en.
         * @param {string} [isoCode] Language iso code.
         * @param {string} [language] Language translation in geo code.
         * @param {string} [_native] Language in native language.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languagesIndex(geoCode?: string, isoCode?: string, language?: string, _native?: string, limit?: number, page?: number, query?: string, options?: any) {
            return LanguagesApiFp(configuration).languagesIndex(geoCode, isoCode, language, _native, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * LanguagesApi - object-oriented interface
 * @export
 * @class LanguagesApi
 * @extends {BaseAPI}
 */
export class LanguagesApi extends BaseAPI {
    /**
     *
     * @param {string} [geoCode] Geo code to display language - default en.
     * @param {string} [isoCode] Language iso code.
     * @param {string} [language] Language translation in geo code.
     * @param {string} [_native] Language in native language.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguagesApi
     */
    public languagesIndex(geoCode?: string, isoCode?: string, language?: string, _native?: string, limit?: number, page?: number, query?: string, options?: any) {
        return LanguagesApiFp(this.configuration).languagesIndex(geoCode, isoCode, language, _native, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * LeadpartneractionsApi - fetch parameter creator
 * @export
 */
export const LeadpartneractionsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {LeadPartnerActionResource} leadPartnerActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadpartneractionsCreate(leadPartnerActionResource: LeadPartnerActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'leadPartnerActionResource' is not null or undefined
            if (leadPartnerActionResource === null || leadPartnerActionResource === undefined) {
                throw new RequiredError('leadPartnerActionResource','Required parameter leadPartnerActionResource was null or undefined when calling leadpartneractionsCreate.');
            }
            const localVarPath = `/leadpartneractions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LeadPartnerActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(leadPartnerActionResource || {}) : (leadPartnerActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeadpartneractionsApi - functional programming interface
 * @export
 */
export const LeadpartneractionsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {LeadPartnerActionResource} leadPartnerActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadpartneractionsCreate(leadPartnerActionResource: LeadPartnerActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadPartnerActionResource> {
            const localVarFetchArgs = LeadpartneractionsApiFetchParamCreator(configuration).leadpartneractionsCreate(leadPartnerActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * LeadpartneractionsApi - factory interface
 * @export
 */
export const LeadpartneractionsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {LeadPartnerActionResource} leadPartnerActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadpartneractionsCreate(leadPartnerActionResource: LeadPartnerActionResource, options?: any) {
            return LeadpartneractionsApiFp(configuration).leadpartneractionsCreate(leadPartnerActionResource, options)(fetch, basePath);
        },
    };
};

/**
 * LeadpartneractionsApi - object-oriented interface
 * @export
 * @class LeadpartneractionsApi
 * @extends {BaseAPI}
 */
export class LeadpartneractionsApi extends BaseAPI {
    /**
     *
     * @param {LeadPartnerActionResource} leadPartnerActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadpartneractionsApi
     */
    public leadpartneractionsCreate(leadPartnerActionResource: LeadPartnerActionResource, options?: any) {
        return LeadpartneractionsApiFp(this.configuration).leadpartneractionsCreate(leadPartnerActionResource, options)(this.fetch, this.basePath);
    }

}

/**
 * LeadsApi - fetch parameter creator
 * @export
 */
export const LeadsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This function serves up one class with a lot of functionality
         * @summary This function serves up one class with a lot of functionality
         * @param {string} leadId
         * @param {LeadActionResource} leadActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsActionsCreate(leadId: string, leadActionResource: LeadActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsActionsCreate.');
            }
            // verify required parameter 'leadActionResource' is not null or undefined
            if (leadActionResource === null || leadActionResource === undefined) {
                throw new RequiredError('leadActionResource','Required parameter leadActionResource was null or undefined when calling leadsActionsCreate.');
            }
            const localVarPath = `/leads/{lead_id}/actions`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LeadActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(leadActionResource || {}) : (leadActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [leadId] List of leads identification(ID).
         * @param {string} [phone] Filter by a given phone number.
         * @param {number} [partnerId] Provide a given partner id to filter by. Requires \&quot;filter\&quot; to be \&quot;bought_by_partner\&quot; or \&quot;segmented_for_partner\&quot;.
         * @param {'' | 'dk' | 'no' | 'de' | 'se' | 'uk' | 'nl' | 'us' | 'com' | 'ascending' | 'descending'} [geoCode] Geographic Location Code.
         * @param {'created_at' | 'validated_at' | 'expires_at' | 'status' | 'quote_published_at' | 'distance' | 'completed_call_priority_date' | 'client_decision_postponed_to' | 'service_fee' | 'minimum_quote_amount_excl_vat' | 'last_action_at' | 'last_contact_at' | 'call_count_before_validation' | 'lead_quote_fee' | 'ascending' | 'descending'} [sort] Sort Leads by a given attribute.
         * @param {string} [status] Status for the given lead.
         * @param {number} [clientId] Lead&#39;s client
         * @param {string} [employeeId] Lead&#39;s client&#39;s assigned employee ID
         * @param {boolean} [clientDecisionPostponed] Client decision to choose a quote match is postponed or not.
         * @param {string} [allQuotesPublishedBefore] Lead received its latest quote match before this date.
         * @param {number} [expiresWithin] Get leads expiring within this many minutes.
         * @param {string} [industry] Industry code for the given lead.
         * @param {boolean} [isQuotesLead] Get leads that are for quotes. @var bool
         * @param {boolean} [hasMaxQuotes] Has maximum number of quotes. @var bool
         * @param {boolean} [hasAcceptedQuote] Has an accepted quote. @var bool
         * @param {boolean} [hasRejectedQuote] Has a rejected quote. @var bool
         * @param {number} [noQuoteForPartnerId] Get leads that do not have a quote for this partner ID. @var int
         * @param {boolean} [isMatchPriority] Is the lead prioritised for being matched? @var bool
         * @param {boolean} [isCompletedCallPriority] Is the lead prioritised for a completion call? @var bool
         * @param {string} [geoPoint] Location Point to sort leads by
         * @param {string} [geoRect] Location Points to define an area to filter leads by. Provide the bottom left (southwest) and top right (northeast) corners of the reactangle separated by commas, e.g. \&quot;52.19,12.42,52.85,14.22\&quot;.
         * @param {number} [geoDistance] Location filter by distance
         * @param {string} [minimumRevenueAmountExclVat] Get leads that will create at least this amount (in euros) of revenue if matched.
         * @param {string} [maximumRevenueAmountExclVat] Get leads that will create no more than this amount (in euros) of revenue if matched.
         * @param {'basic' | 'exclusive'} [leadCategory] Lead&#39;s category
         * @param {string} [validatorId] Lead&#39;s validator
         * @param {boolean} [isGivenUp] Is the lead given up on?
         * @param {string} [industryId] Filter the leads by their industry ids
         * @param {number} [callCountBeforeValidationLt] Lead must have less than this many calls before validation occurred.
         * @param {boolean} [isValidationPostponed] Is the lead&#39;s validation postponed?
         * @param {string} [createdAtLte] Get leads created before this date (inclusive).
         * @param {string} [createdAtGte] Get leads created after this date (inclusive).
         * @param {string} [validatedAtGte] Get leads validated after this date (inclusive).
         * @param {string} [latestCalledAtBefore] Find leads with a latest call before this date.
         * @param {string} [segmentationCriteria] Which criteria to segment on, e.g. language.
         * @param {number} [segmentedForPartnerBusinessUnitId] List leads that are segmented for a business unit.
         * @param {string} [subGeoRegionName] List leads in any of these sub geo regions. E.g. &#39;Aarhus&#39;, &#39;Copenhagen&#39;.
         * @param {boolean} [isAnonymized] Have the leads been anonymized.
         * @param {boolean} [hasLeadQuoteFee] Filter by has lead quote fee.
         * @param {string} [desiredPartnerCompanySizeId] Filter by desired partner company size.
         * @param {number} [segmentedForLeadMarketplacePartnerId] Find leads for the partner&#39;s lead marketplace.
         * @param {string} [personalTitleId] Filter by lead&#39;s personal title
         * @param {boolean} [isLimitedAccessMarketplaceLead] List marketplace leads that have been validated within limited access time window
         * @param {boolean} [isBasicAccessMarketplaceLead] List marketplace leads that have been created within basic access time window
         * @param {string} [typeId] List leads by the client type id
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsAggregationsIndex(leadId?: string, phone?: string, partnerId?: number, geoCode?: '' | 'dk' | 'no' | 'de' | 'se' | 'uk' | 'nl' | 'us' | 'com' | 'ascending' | 'descending', sort?: 'created_at' | 'validated_at' | 'expires_at' | 'status' | 'quote_published_at' | 'distance' | 'completed_call_priority_date' | 'client_decision_postponed_to' | 'service_fee' | 'minimum_quote_amount_excl_vat' | 'last_action_at' | 'last_contact_at' | 'call_count_before_validation' | 'lead_quote_fee' | 'ascending' | 'descending', status?: string, clientId?: number, employeeId?: string, clientDecisionPostponed?: boolean, allQuotesPublishedBefore?: string, expiresWithin?: number, industry?: string, isQuotesLead?: boolean, hasMaxQuotes?: boolean, hasAcceptedQuote?: boolean, hasRejectedQuote?: boolean, noQuoteForPartnerId?: number, isMatchPriority?: boolean, isCompletedCallPriority?: boolean, geoPoint?: string, geoRect?: string, geoDistance?: number, minimumRevenueAmountExclVat?: string, maximumRevenueAmountExclVat?: string, leadCategory?: 'basic' | 'exclusive', validatorId?: string, isGivenUp?: boolean, industryId?: string, callCountBeforeValidationLt?: number, isValidationPostponed?: boolean, createdAtLte?: string, createdAtGte?: string, validatedAtGte?: string, latestCalledAtBefore?: string, segmentationCriteria?: string, segmentedForPartnerBusinessUnitId?: number, subGeoRegionName?: string, isAnonymized?: boolean, hasLeadQuoteFee?: boolean, desiredPartnerCompanySizeId?: string, segmentedForLeadMarketplacePartnerId?: number, personalTitleId?: string, isLimitedAccessMarketplaceLead?: boolean, isBasicAccessMarketplaceLead?: boolean, typeId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/leads/aggregations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (leadId !== undefined) {
                localVarQueryParameter['lead_id'] = leadId;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employee_id'] = employeeId;
            }

            if (clientDecisionPostponed !== undefined) {
                localVarQueryParameter['client_decision_postponed'] = clientDecisionPostponed;
            }

            if (allQuotesPublishedBefore !== undefined) {
                localVarQueryParameter['all_quotes_published_before'] = allQuotesPublishedBefore;
            }

            if (expiresWithin !== undefined) {
                localVarQueryParameter['expires_within'] = expiresWithin;
            }

            if (industry !== undefined) {
                localVarQueryParameter['industry'] = industry;
            }

            if (isQuotesLead !== undefined) {
                localVarQueryParameter['is_quotes_lead'] = isQuotesLead;
            }

            if (hasMaxQuotes !== undefined) {
                localVarQueryParameter['has_max_quotes'] = hasMaxQuotes;
            }

            if (hasAcceptedQuote !== undefined) {
                localVarQueryParameter['has_accepted_quote'] = hasAcceptedQuote;
            }

            if (hasRejectedQuote !== undefined) {
                localVarQueryParameter['has_rejected_quote'] = hasRejectedQuote;
            }

            if (noQuoteForPartnerId !== undefined) {
                localVarQueryParameter['no_quote_for_partner_id'] = noQuoteForPartnerId;
            }

            if (isMatchPriority !== undefined) {
                localVarQueryParameter['is_match_priority'] = isMatchPriority;
            }

            if (isCompletedCallPriority !== undefined) {
                localVarQueryParameter['is_completed_call_priority'] = isCompletedCallPriority;
            }

            if (geoPoint !== undefined) {
                localVarQueryParameter['geo_point'] = geoPoint;
            }

            if (geoRect !== undefined) {
                localVarQueryParameter['geo_rect'] = geoRect;
            }

            if (geoDistance !== undefined) {
                localVarQueryParameter['geo_distance'] = geoDistance;
            }

            if (minimumRevenueAmountExclVat !== undefined) {
                localVarQueryParameter['minimum_revenue_amount_excl_vat'] = minimumRevenueAmountExclVat;
            }

            if (maximumRevenueAmountExclVat !== undefined) {
                localVarQueryParameter['maximum_revenue_amount_excl_vat'] = maximumRevenueAmountExclVat;
            }

            if (leadCategory !== undefined) {
                localVarQueryParameter['lead_category'] = leadCategory;
            }

            if (validatorId !== undefined) {
                localVarQueryParameter['validator_id'] = validatorId;
            }

            if (isGivenUp !== undefined) {
                localVarQueryParameter['is_given_up'] = isGivenUp;
            }

            if (industryId !== undefined) {
                localVarQueryParameter['industry_id'] = industryId;
            }

            if (callCountBeforeValidationLt !== undefined) {
                localVarQueryParameter['call_count_before_validation_lt'] = callCountBeforeValidationLt;
            }

            if (isValidationPostponed !== undefined) {
                localVarQueryParameter['is_validation_postponed'] = isValidationPostponed;
            }

            if (createdAtLte !== undefined) {
                localVarQueryParameter['created_at_lte'] = createdAtLte;
            }

            if (createdAtGte !== undefined) {
                localVarQueryParameter['created_at_gte'] = createdAtGte;
            }

            if (validatedAtGte !== undefined) {
                localVarQueryParameter['validated_at_gte'] = validatedAtGte;
            }

            if (latestCalledAtBefore !== undefined) {
                localVarQueryParameter['latest_called_at_before'] = latestCalledAtBefore;
            }

            if (segmentationCriteria !== undefined) {
                localVarQueryParameter['segmentation_criteria'] = segmentationCriteria;
            }

            if (segmentedForPartnerBusinessUnitId !== undefined) {
                localVarQueryParameter['segmented_for_partner_business_unit_id'] = segmentedForPartnerBusinessUnitId;
            }

            if (subGeoRegionName !== undefined) {
                localVarQueryParameter['sub_geo_region_name'] = subGeoRegionName;
            }

            if (isAnonymized !== undefined) {
                localVarQueryParameter['is_anonymized'] = isAnonymized;
            }

            if (hasLeadQuoteFee !== undefined) {
                localVarQueryParameter['has_lead_quote_fee'] = hasLeadQuoteFee;
            }

            if (desiredPartnerCompanySizeId !== undefined) {
                localVarQueryParameter['desired_partner_company_size_id'] = desiredPartnerCompanySizeId;
            }

            if (segmentedForLeadMarketplacePartnerId !== undefined) {
                localVarQueryParameter['segmented_for_lead_marketplace_partner_id'] = segmentedForLeadMarketplacePartnerId;
            }

            if (personalTitleId !== undefined) {
                localVarQueryParameter['personal_title_id'] = personalTitleId;
            }

            if (isLimitedAccessMarketplaceLead !== undefined) {
                localVarQueryParameter['is_limited_access_marketplace_lead'] = isLimitedAccessMarketplaceLead;
            }

            if (isBasicAccessMarketplaceLead !== undefined) {
                localVarQueryParameter['is_basic_access_marketplace_lead'] = isBasicAccessMarketplaceLead;
            }

            if (typeId !== undefined) {
                localVarQueryParameter['type_id'] = typeId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {LeadAllocationsResource} leadAllocationsResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsAllocationsCreate(leadId: string, leadAllocationsResource: LeadAllocationsResource, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsAllocationsCreate.');
            }
            // verify required parameter 'leadAllocationsResource' is not null or undefined
            if (leadAllocationsResource === null || leadAllocationsResource === undefined) {
                throw new RequiredError('leadAllocationsResource','Required parameter leadAllocationsResource was null or undefined when calling leadsAllocationsCreate.');
            }
            const localVarPath = `/leads/{lead_id}/allocations`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LeadAllocationsResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(leadAllocationsResource || {}) : (leadAllocationsResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [leadId] Lead ID.
         * @param {number} [partnerId] Partner ID.
         * @param {string} [partnerBusinessUnitId] Business Unit ID.
         * @param {string} [startsAt]
         * @param {string} [endsAt]
         * @param {boolean} [hasBusinessUnit]
         * @param {'created_at' | 'quote_accepted_at' | 'quote_published_at' | 'partner_id' | 'lead_id' | 'partner_business_unit_id' | 'ascending' | 'descending'} [sort]
         * @param {boolean} [isUsed] Filter by the allocation being used or unused.
         * @param {boolean} [isProcessed] Filter by the allocation being used or unused.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsAllocationsIndex(leadId?: number, partnerId?: number, partnerBusinessUnitId?: string, startsAt?: string, endsAt?: string, hasBusinessUnit?: boolean, sort?: 'created_at' | 'quote_accepted_at' | 'quote_published_at' | 'partner_id' | 'lead_id' | 'partner_business_unit_id' | 'ascending' | 'descending', isUsed?: boolean, isProcessed?: boolean, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/leads/allocations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (leadId !== undefined) {
                localVarQueryParameter['lead_id'] = leadId;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (partnerBusinessUnitId !== undefined) {
                localVarQueryParameter['partner_business_unit_id'] = partnerBusinessUnitId;
            }

            if (startsAt !== undefined) {
                localVarQueryParameter['starts_at'] = startsAt;
            }

            if (endsAt !== undefined) {
                localVarQueryParameter['ends_at'] = endsAt;
            }

            if (hasBusinessUnit !== undefined) {
                localVarQueryParameter['has_business_unit'] = hasBusinessUnit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (isUsed !== undefined) {
                localVarQueryParameter['is_used'] = isUsed;
            }

            if (isProcessed !== undefined) {
                localVarQueryParameter['is_processed'] = isProcessed;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} allocationSuggestionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsAllocationsuggestionsDelete(leadId: string, allocationSuggestionId: string, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsAllocationsuggestionsDelete.');
            }
            // verify required parameter 'allocationSuggestionId' is not null or undefined
            if (allocationSuggestionId === null || allocationSuggestionId === undefined) {
                throw new RequiredError('allocationSuggestionId','Required parameter allocationSuggestionId was null or undefined when calling leadsAllocationsuggestionsDelete.');
            }
            const localVarPath = `/leads/{lead_id}/allocationsuggestions/{allocation_suggestion_id}`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)))
                .replace(`{${"allocation_suggestion_id"}}`, encodeURIComponent(String(allocationSuggestionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [id] Category id @var int
         * @param {string} [identifier] Category identifier @var string
         * @param {string} [title] Category title. @var string
         * @param {number} [limit] Limit of industries per page. @var int
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsCategoriesIndex(id?: string, identifier?: string, title?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/leads/categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (identifier !== undefined) {
                localVarQueryParameter['identifier'] = identifier;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {CertificationResource} certificationResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsCertificationsCreate(leadId: string, certificationResource: CertificationResource, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsCertificationsCreate.');
            }
            // verify required parameter 'certificationResource' is not null or undefined
            if (certificationResource === null || certificationResource === undefined) {
                throw new RequiredError('certificationResource','Required parameter certificationResource was null or undefined when calling leadsCertificationsCreate.');
            }
            const localVarPath = `/leads/{lead_id}/certifications`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CertificationResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(certificationResource || {}) : (certificationResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} certificationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsCertificationsDelete(leadId: string, certificationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsCertificationsDelete.');
            }
            // verify required parameter 'certificationId' is not null or undefined
            if (certificationId === null || certificationId === undefined) {
                throw new RequiredError('certificationId','Required parameter certificationId was null or undefined when calling leadsCertificationsDelete.');
            }
            const localVarPath = `/leads/{lead_id}/certifications/{certification_id}`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)))
                .replace(`{${"certification_id"}}`, encodeURIComponent(String(certificationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {boolean} [isSegmentable] Is the certification used for segmentation?
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsCertificationsIndex(leadId: string, isSegmentable?: boolean, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsCertificationsIndex.');
            }
            const localVarPath = `/leads/{lead_id}/certifications`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (isSegmentable !== undefined) {
                localVarQueryParameter['is_segmentable'] = isSegmentable;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {LeadCertificationsResource} leadCertificationsResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsCertificationsUpdate(leadId: string, leadCertificationsResource: LeadCertificationsResource, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsCertificationsUpdate.');
            }
            // verify required parameter 'leadCertificationsResource' is not null or undefined
            if (leadCertificationsResource === null || leadCertificationsResource === undefined) {
                throw new RequiredError('leadCertificationsResource','Required parameter leadCertificationsResource was null or undefined when calling leadsCertificationsUpdate.');
            }
            const localVarPath = `/leads/{lead_id}/certifications`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LeadCertificationsResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(leadCertificationsResource || {}) : (leadCertificationsResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsCompanysizesIndex(leadId: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsCompanysizesIndex.');
            }
            const localVarPath = `/leads/{lead_id}/companysizes`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {LeadCompanySizesResource} leadCompanySizesResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsCompanysizesUpdate(leadId: string, leadCompanySizesResource: LeadCompanySizesResource, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsCompanysizesUpdate.');
            }
            // verify required parameter 'leadCompanySizesResource' is not null or undefined
            if (leadCompanySizesResource === null || leadCompanySizesResource === undefined) {
                throw new RequiredError('leadCompanySizesResource','Required parameter leadCompanySizesResource was null or undefined when calling leadsCompanysizesUpdate.');
            }
            const localVarPath = `/leads/{lead_id}/companysizes`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LeadCompanySizesResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(leadCompanySizesResource || {}) : (leadCompanySizesResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {LeadResource} leadResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsCreate(leadResource: LeadResource, options: any = {}): FetchArgs {
            // verify required parameter 'leadResource' is not null or undefined
            if (leadResource === null || leadResource === undefined) {
                throw new RequiredError('leadResource','Required parameter leadResource was null or undefined when calling leadsCreate.');
            }
            const localVarPath = `/leads`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LeadResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(leadResource || {}) : (leadResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsDelete(leadId: string, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsDelete.');
            }
            const localVarPath = `/leads/{lead_id}`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsDemopartnersIndex(partnerId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling leadsDemopartnersIndex.');
            }
            const localVarPath = `/leads/demopartners/{partner_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsDemopartnersIndex_1(leadId: string, partnerId: string, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsDemopartnersIndex_1.');
            }
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling leadsDemopartnersIndex_1.');
            }
            const localVarPath = `/leads/{lead_id}/demopartners/{partner_id}`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)))
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {LeadBulkActionDiscardResource} leadBulkActionDiscardResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsDiscardsCreate(leadBulkActionDiscardResource: LeadBulkActionDiscardResource, options: any = {}): FetchArgs {
            // verify required parameter 'leadBulkActionDiscardResource' is not null or undefined
            if (leadBulkActionDiscardResource === null || leadBulkActionDiscardResource === undefined) {
                throw new RequiredError('leadBulkActionDiscardResource','Required parameter leadBulkActionDiscardResource was null or undefined when calling leadsDiscardsCreate.');
            }
            const localVarPath = `/leads/discards`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LeadBulkActionDiscardResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(leadBulkActionDiscardResource || {}) : (leadBulkActionDiscardResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {PartnerSuggestResource} partnerSuggestResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsExcludedpartnersCreate(leadId: string, partnerSuggestResource: PartnerSuggestResource, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsExcludedpartnersCreate.');
            }
            // verify required parameter 'partnerSuggestResource' is not null or undefined
            if (partnerSuggestResource === null || partnerSuggestResource === undefined) {
                throw new RequiredError('partnerSuggestResource','Required parameter partnerSuggestResource was null or undefined when calling leadsExcludedpartnersCreate.');
            }
            const localVarPath = `/leads/{lead_id}/excludedpartners`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerSuggestResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerSuggestResource || {}) : (partnerSuggestResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsExcludedpartnersDelete(leadId: string, partnerId: string, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsExcludedpartnersDelete.');
            }
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling leadsExcludedpartnersDelete.');
            }
            const localVarPath = `/leads/{lead_id}/excludedpartners/{partner_id}`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)))
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsExcludedpartnersIndex(leadId: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsExcludedpartnersIndex.');
            }
            const localVarPath = `/leads/{lead_id}/excludedpartners`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsGet(leadId: string, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsGet.');
            }
            const localVarPath = `/leads/{lead_id}`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [leadId] List of leads identification(ID).
         * @param {string} [phone] Filter by a given phone number.
         * @param {number} [partnerId] Provide a given partner id to filter by. Requires \&quot;filter\&quot; to be \&quot;bought_by_partner\&quot; or \&quot;segmented_for_partner\&quot;.
         * @param {'' | 'dk' | 'no' | 'de' | 'se' | 'uk' | 'nl' | 'us' | 'com' | 'ascending' | 'descending'} [geoCode] Geographic Location Code.
         * @param {'created_at' | 'validated_at' | 'expires_at' | 'status' | 'quote_published_at' | 'distance' | 'completed_call_priority_date' | 'client_decision_postponed_to' | 'service_fee' | 'minimum_quote_amount_excl_vat' | 'last_action_at' | 'last_contact_at' | 'call_count_before_validation' | 'lead_quote_fee' | 'ascending' | 'descending'} [sort] Sort Leads by a given attribute.
         * @param {string} [status] Status for the given lead.
         * @param {number} [clientId] Lead&#39;s client
         * @param {string} [employeeId] Lead&#39;s client&#39;s assigned employee ID
         * @param {boolean} [clientDecisionPostponed] Client decision to choose a quote match is postponed or not.
         * @param {string} [allQuotesPublishedBefore] Lead received its latest quote match before this date.
         * @param {number} [expiresWithin] Get leads expiring within this many minutes.
         * @param {string} [industry] Industry code for the given lead.
         * @param {boolean} [isQuotesLead] Get leads that are for quotes. @var bool
         * @param {boolean} [hasMaxQuotes] Has maximum number of quotes. @var bool
         * @param {boolean} [hasAcceptedQuote] Has an accepted quote. @var bool
         * @param {boolean} [hasRejectedQuote] Has a rejected quote. @var bool
         * @param {number} [noQuoteForPartnerId] Get leads that do not have a quote for this partner ID. @var int
         * @param {boolean} [isMatchPriority] Is the lead prioritised for being matched? @var bool
         * @param {boolean} [isCompletedCallPriority] Is the lead prioritised for a completion call? @var bool
         * @param {string} [geoPoint] Location Point to sort leads by
         * @param {string} [geoRect] Location Points to define an area to filter leads by. Provide the bottom left (southwest) and top right (northeast) corners of the reactangle separated by commas, e.g. \&quot;52.19,12.42,52.85,14.22\&quot;.
         * @param {number} [geoDistance] Location filter by distance
         * @param {string} [minimumRevenueAmountExclVat] Get leads that will create at least this amount (in euros) of revenue if matched.
         * @param {string} [maximumRevenueAmountExclVat] Get leads that will create no more than this amount (in euros) of revenue if matched.
         * @param {'basic' | 'exclusive'} [leadCategory] Lead&#39;s category
         * @param {string} [validatorId] Lead&#39;s validator
         * @param {boolean} [isGivenUp] Is the lead given up on?
         * @param {string} [industryId] Filter the leads by their industry ids
         * @param {number} [callCountBeforeValidationLt] Lead must have less than this many calls before validation occurred.
         * @param {boolean} [isValidationPostponed] Is the lead&#39;s validation postponed?
         * @param {string} [createdAtLte] Get leads created before this date (inclusive).
         * @param {string} [createdAtGte] Get leads created after this date (inclusive).
         * @param {string} [validatedAtGte] Get leads validated after this date (inclusive).
         * @param {string} [latestCalledAtBefore] Find leads with a latest call before this date.
         * @param {string} [segmentationCriteria] Which criteria to segment on, e.g. language.
         * @param {number} [segmentedForPartnerBusinessUnitId] List leads that are segmented for a business unit.
         * @param {string} [subGeoRegionName] List leads in any of these sub geo regions. E.g. &#39;Aarhus&#39;, &#39;Copenhagen&#39;.
         * @param {boolean} [isAnonymized] Have the leads been anonymized.
         * @param {boolean} [hasLeadQuoteFee] Filter by has lead quote fee.
         * @param {string} [desiredPartnerCompanySizeId] Filter by desired partner company size.
         * @param {number} [segmentedForLeadMarketplacePartnerId] Find leads for the partner&#39;s lead marketplace.
         * @param {string} [personalTitleId] Filter by lead&#39;s personal title
         * @param {boolean} [isLimitedAccessMarketplaceLead] List marketplace leads that have been validated within limited access time window
         * @param {boolean} [isBasicAccessMarketplaceLead] List marketplace leads that have been created within basic access time window
         * @param {string} [typeId] List leads by the client type id
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsIndex(leadId?: string, phone?: string, partnerId?: number, geoCode?: '' | 'dk' | 'no' | 'de' | 'se' | 'uk' | 'nl' | 'us' | 'com' | 'ascending' | 'descending', sort?: 'created_at' | 'validated_at' | 'expires_at' | 'status' | 'quote_published_at' | 'distance' | 'completed_call_priority_date' | 'client_decision_postponed_to' | 'service_fee' | 'minimum_quote_amount_excl_vat' | 'last_action_at' | 'last_contact_at' | 'call_count_before_validation' | 'lead_quote_fee' | 'ascending' | 'descending', status?: string, clientId?: number, employeeId?: string, clientDecisionPostponed?: boolean, allQuotesPublishedBefore?: string, expiresWithin?: number, industry?: string, isQuotesLead?: boolean, hasMaxQuotes?: boolean, hasAcceptedQuote?: boolean, hasRejectedQuote?: boolean, noQuoteForPartnerId?: number, isMatchPriority?: boolean, isCompletedCallPriority?: boolean, geoPoint?: string, geoRect?: string, geoDistance?: number, minimumRevenueAmountExclVat?: string, maximumRevenueAmountExclVat?: string, leadCategory?: 'basic' | 'exclusive', validatorId?: string, isGivenUp?: boolean, industryId?: string, callCountBeforeValidationLt?: number, isValidationPostponed?: boolean, createdAtLte?: string, createdAtGte?: string, validatedAtGte?: string, latestCalledAtBefore?: string, segmentationCriteria?: string, segmentedForPartnerBusinessUnitId?: number, subGeoRegionName?: string, isAnonymized?: boolean, hasLeadQuoteFee?: boolean, desiredPartnerCompanySizeId?: string, segmentedForLeadMarketplacePartnerId?: number, personalTitleId?: string, isLimitedAccessMarketplaceLead?: boolean, isBasicAccessMarketplaceLead?: boolean, typeId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/leads`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (leadId !== undefined) {
                localVarQueryParameter['lead_id'] = leadId;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employee_id'] = employeeId;
            }

            if (clientDecisionPostponed !== undefined) {
                localVarQueryParameter['client_decision_postponed'] = clientDecisionPostponed;
            }

            if (allQuotesPublishedBefore !== undefined) {
                localVarQueryParameter['all_quotes_published_before'] = allQuotesPublishedBefore;
            }

            if (expiresWithin !== undefined) {
                localVarQueryParameter['expires_within'] = expiresWithin;
            }

            if (industry !== undefined) {
                localVarQueryParameter['industry'] = industry;
            }

            if (isQuotesLead !== undefined) {
                localVarQueryParameter['is_quotes_lead'] = isQuotesLead;
            }

            if (hasMaxQuotes !== undefined) {
                localVarQueryParameter['has_max_quotes'] = hasMaxQuotes;
            }

            if (hasAcceptedQuote !== undefined) {
                localVarQueryParameter['has_accepted_quote'] = hasAcceptedQuote;
            }

            if (hasRejectedQuote !== undefined) {
                localVarQueryParameter['has_rejected_quote'] = hasRejectedQuote;
            }

            if (noQuoteForPartnerId !== undefined) {
                localVarQueryParameter['no_quote_for_partner_id'] = noQuoteForPartnerId;
            }

            if (isMatchPriority !== undefined) {
                localVarQueryParameter['is_match_priority'] = isMatchPriority;
            }

            if (isCompletedCallPriority !== undefined) {
                localVarQueryParameter['is_completed_call_priority'] = isCompletedCallPriority;
            }

            if (geoPoint !== undefined) {
                localVarQueryParameter['geo_point'] = geoPoint;
            }

            if (geoRect !== undefined) {
                localVarQueryParameter['geo_rect'] = geoRect;
            }

            if (geoDistance !== undefined) {
                localVarQueryParameter['geo_distance'] = geoDistance;
            }

            if (minimumRevenueAmountExclVat !== undefined) {
                localVarQueryParameter['minimum_revenue_amount_excl_vat'] = minimumRevenueAmountExclVat;
            }

            if (maximumRevenueAmountExclVat !== undefined) {
                localVarQueryParameter['maximum_revenue_amount_excl_vat'] = maximumRevenueAmountExclVat;
            }

            if (leadCategory !== undefined) {
                localVarQueryParameter['lead_category'] = leadCategory;
            }

            if (validatorId !== undefined) {
                localVarQueryParameter['validator_id'] = validatorId;
            }

            if (isGivenUp !== undefined) {
                localVarQueryParameter['is_given_up'] = isGivenUp;
            }

            if (industryId !== undefined) {
                localVarQueryParameter['industry_id'] = industryId;
            }

            if (callCountBeforeValidationLt !== undefined) {
                localVarQueryParameter['call_count_before_validation_lt'] = callCountBeforeValidationLt;
            }

            if (isValidationPostponed !== undefined) {
                localVarQueryParameter['is_validation_postponed'] = isValidationPostponed;
            }

            if (createdAtLte !== undefined) {
                localVarQueryParameter['created_at_lte'] = createdAtLte;
            }

            if (createdAtGte !== undefined) {
                localVarQueryParameter['created_at_gte'] = createdAtGte;
            }

            if (validatedAtGte !== undefined) {
                localVarQueryParameter['validated_at_gte'] = validatedAtGte;
            }

            if (latestCalledAtBefore !== undefined) {
                localVarQueryParameter['latest_called_at_before'] = latestCalledAtBefore;
            }

            if (segmentationCriteria !== undefined) {
                localVarQueryParameter['segmentation_criteria'] = segmentationCriteria;
            }

            if (segmentedForPartnerBusinessUnitId !== undefined) {
                localVarQueryParameter['segmented_for_partner_business_unit_id'] = segmentedForPartnerBusinessUnitId;
            }

            if (subGeoRegionName !== undefined) {
                localVarQueryParameter['sub_geo_region_name'] = subGeoRegionName;
            }

            if (isAnonymized !== undefined) {
                localVarQueryParameter['is_anonymized'] = isAnonymized;
            }

            if (hasLeadQuoteFee !== undefined) {
                localVarQueryParameter['has_lead_quote_fee'] = hasLeadQuoteFee;
            }

            if (desiredPartnerCompanySizeId !== undefined) {
                localVarQueryParameter['desired_partner_company_size_id'] = desiredPartnerCompanySizeId;
            }

            if (segmentedForLeadMarketplacePartnerId !== undefined) {
                localVarQueryParameter['segmented_for_lead_marketplace_partner_id'] = segmentedForLeadMarketplacePartnerId;
            }

            if (personalTitleId !== undefined) {
                localVarQueryParameter['personal_title_id'] = personalTitleId;
            }

            if (isLimitedAccessMarketplaceLead !== undefined) {
                localVarQueryParameter['is_limited_access_marketplace_lead'] = isLimitedAccessMarketplaceLead;
            }

            if (isBasicAccessMarketplaceLead !== undefined) {
                localVarQueryParameter['is_basic_access_marketplace_lead'] = isBasicAccessMarketplaceLead;
            }

            if (typeId !== undefined) {
                localVarQueryParameter['type_id'] = typeId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {LeadNoteResource} leadNoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsNotesCreate(leadId: string, leadNoteResource: LeadNoteResource, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsNotesCreate.');
            }
            // verify required parameter 'leadNoteResource' is not null or undefined
            if (leadNoteResource === null || leadNoteResource === undefined) {
                throw new RequiredError('leadNoteResource','Required parameter leadNoteResource was null or undefined when calling leadsNotesCreate.');
            }
            const localVarPath = `/leads/{lead_id}/notes`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LeadNoteResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(leadNoteResource || {}) : (leadNoteResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {LeadQuoteFeeResource} leadQuoteFeeResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotefeesCreate(leadId: string, leadQuoteFeeResource: LeadQuoteFeeResource, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsQuotefeesCreate.');
            }
            // verify required parameter 'leadQuoteFeeResource' is not null or undefined
            if (leadQuoteFeeResource === null || leadQuoteFeeResource === undefined) {
                throw new RequiredError('leadQuoteFeeResource','Required parameter leadQuoteFeeResource was null or undefined when calling leadsQuotefeesCreate.');
            }
            const localVarPath = `/leads/{lead_id}/quotefees`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LeadQuoteFeeResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(leadQuoteFeeResource || {}) : (leadQuoteFeeResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotefeesDelete(leadId: string, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsQuotefeesDelete.');
            }
            const localVarPath = `/leads/{lead_id}/quotefees`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {LeadQuoteActionResource} leadQuoteActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesActionsCreate(leadId: string, leadQuoteId: string, leadQuoteActionResource: LeadQuoteActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsQuotesActionsCreate.');
            }
            // verify required parameter 'leadQuoteId' is not null or undefined
            if (leadQuoteId === null || leadQuoteId === undefined) {
                throw new RequiredError('leadQuoteId','Required parameter leadQuoteId was null or undefined when calling leadsQuotesActionsCreate.');
            }
            // verify required parameter 'leadQuoteActionResource' is not null or undefined
            if (leadQuoteActionResource === null || leadQuoteActionResource === undefined) {
                throw new RequiredError('leadQuoteActionResource','Required parameter leadQuoteActionResource was null or undefined when calling leadsQuotesActionsCreate.');
            }
            const localVarPath = `/leads/{lead_id}/quotes/{lead_quote_id}/actions`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)))
                .replace(`{${"lead_quote_id"}}`, encodeURIComponent(String(leadQuoteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LeadQuoteActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(leadQuoteActionResource || {}) : (leadQuoteActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {LeadQuoteResource} leadQuoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesCreate(leadId: string, leadQuoteResource: LeadQuoteResource, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsQuotesCreate.');
            }
            // verify required parameter 'leadQuoteResource' is not null or undefined
            if (leadQuoteResource === null || leadQuoteResource === undefined) {
                throw new RequiredError('leadQuoteResource','Required parameter leadQuoteResource was null or undefined when calling leadsQuotesCreate.');
            }
            const localVarPath = `/leads/{lead_id}/quotes`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LeadQuoteResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(leadQuoteResource || {}) : (leadQuoteResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesDelete(leadId: string, leadQuoteId: string, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsQuotesDelete.');
            }
            // verify required parameter 'leadQuoteId' is not null or undefined
            if (leadQuoteId === null || leadQuoteId === undefined) {
                throw new RequiredError('leadQuoteId','Required parameter leadQuoteId was null or undefined when calling leadsQuotesDelete.');
            }
            const localVarPath = `/leads/{lead_id}/quotes/{lead_quote_id}`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)))
                .replace(`{${"lead_quote_id"}}`, encodeURIComponent(String(leadQuoteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadQuoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesGet(leadQuoteId: string, options: any = {}): FetchArgs {
            // verify required parameter 'leadQuoteId' is not null or undefined
            if (leadQuoteId === null || leadQuoteId === undefined) {
                throw new RequiredError('leadQuoteId','Required parameter leadQuoteId was null or undefined when calling leadsQuotesGet.');
            }
            const localVarPath = `/leads/quotes/{lead_quote_id}`
                .replace(`{${"lead_quote_id"}}`, encodeURIComponent(String(leadQuoteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesGet_2(leadId: string, leadQuoteId: string, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsQuotesGet_2.');
            }
            // verify required parameter 'leadQuoteId' is not null or undefined
            if (leadQuoteId === null || leadQuoteId === undefined) {
                throw new RequiredError('leadQuoteId','Required parameter leadQuoteId was null or undefined when calling leadsQuotesGet_2.');
            }
            const localVarPath = `/leads/{lead_id}/quotes/{lead_quote_id}`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)))
                .replace(`{${"lead_quote_id"}}`, encodeURIComponent(String(leadQuoteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [clientId] List of quotes by client_id
         * @param {string} [leadId] List of leads identification(ID).
         * @param {string} [leadQuoteId] List of lead quotes identification(ID).
         * @param {string} [partnerId] Offers belonging to a given partner.
         * @param {string} [geoCode] Geographic Location Code.
         * @param {boolean} [isAccepted] Quote is accepted.
         * @param {boolean} [isPublished] Quote is published.
         * @param {boolean} [isRejected] Quote is rejected.
         * @param {'created_at' | 'accepted_at' | 'published_at' | 'rejected_at' | 'total_price_excl_vat' | 'ascending' | 'descending'} [sort] Sort options.
         * @param {string} [leadCategoryType] Filter by lead category type.
         * @param {boolean} [reservationIsExpired] Quote&#39;s reservation is expired.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesIndex(clientId?: string, leadId?: string, leadQuoteId?: string, partnerId?: string, geoCode?: string, isAccepted?: boolean, isPublished?: boolean, isRejected?: boolean, sort?: 'created_at' | 'accepted_at' | 'published_at' | 'rejected_at' | 'total_price_excl_vat' | 'ascending' | 'descending', leadCategoryType?: string, reservationIsExpired?: boolean, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/leads/quotes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (leadId !== undefined) {
                localVarQueryParameter['lead_id'] = leadId;
            }

            if (leadQuoteId !== undefined) {
                localVarQueryParameter['lead_quote_id'] = leadQuoteId;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (isAccepted !== undefined) {
                localVarQueryParameter['is_accepted'] = isAccepted;
            }

            if (isPublished !== undefined) {
                localVarQueryParameter['is_published'] = isPublished;
            }

            if (isRejected !== undefined) {
                localVarQueryParameter['is_rejected'] = isRejected;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (leadCategoryType !== undefined) {
                localVarQueryParameter['lead_category_type'] = leadCategoryType;
            }

            if (reservationIsExpired !== undefined) {
                localVarQueryParameter['reservation_is_expired'] = reservationIsExpired;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} [clientId] List of quotes by client_id
         * @param {string} [leadQuoteId] List of lead quotes identification(ID).
         * @param {string} [partnerId] Offers belonging to a given partner.
         * @param {string} [geoCode] Geographic Location Code.
         * @param {boolean} [isAccepted] Quote is accepted.
         * @param {boolean} [isPublished] Quote is published.
         * @param {boolean} [isRejected] Quote is rejected.
         * @param {'created_at' | 'accepted_at' | 'published_at' | 'rejected_at' | 'total_price_excl_vat' | 'ascending' | 'descending'} [sort] Sort options.
         * @param {string} [leadCategoryType] Filter by lead category type.
         * @param {boolean} [reservationIsExpired] Quote&#39;s reservation is expired.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesIndex_3(leadId: string, clientId?: string, leadQuoteId?: string, partnerId?: string, geoCode?: string, isAccepted?: boolean, isPublished?: boolean, isRejected?: boolean, sort?: 'created_at' | 'accepted_at' | 'published_at' | 'rejected_at' | 'total_price_excl_vat' | 'ascending' | 'descending', leadCategoryType?: string, reservationIsExpired?: boolean, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsQuotesIndex_3.');
            }
            const localVarPath = `/leads/{lead_id}/quotes`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (leadQuoteId !== undefined) {
                localVarQueryParameter['lead_quote_id'] = leadQuoteId;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (isAccepted !== undefined) {
                localVarQueryParameter['is_accepted'] = isAccepted;
            }

            if (isPublished !== undefined) {
                localVarQueryParameter['is_published'] = isPublished;
            }

            if (isRejected !== undefined) {
                localVarQueryParameter['is_rejected'] = isRejected;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (leadCategoryType !== undefined) {
                localVarQueryParameter['lead_category_type'] = leadCategoryType;
            }

            if (reservationIsExpired !== undefined) {
                localVarQueryParameter['reservation_is_expired'] = reservationIsExpired;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {LeadQuoteItemResource} leadQuoteItemResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesItemsCreate(leadId: string, leadQuoteId: string, leadQuoteItemResource: LeadQuoteItemResource, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsQuotesItemsCreate.');
            }
            // verify required parameter 'leadQuoteId' is not null or undefined
            if (leadQuoteId === null || leadQuoteId === undefined) {
                throw new RequiredError('leadQuoteId','Required parameter leadQuoteId was null or undefined when calling leadsQuotesItemsCreate.');
            }
            // verify required parameter 'leadQuoteItemResource' is not null or undefined
            if (leadQuoteItemResource === null || leadQuoteItemResource === undefined) {
                throw new RequiredError('leadQuoteItemResource','Required parameter leadQuoteItemResource was null or undefined when calling leadsQuotesItemsCreate.');
            }
            const localVarPath = `/leads/{lead_id}/quotes/{lead_quote_id}/items`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)))
                .replace(`{${"lead_quote_id"}}`, encodeURIComponent(String(leadQuoteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LeadQuoteItemResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(leadQuoteItemResource || {}) : (leadQuoteItemResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {string} leadQuoteItemId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesItemsDelete(leadId: string, leadQuoteId: string, leadQuoteItemId: string, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsQuotesItemsDelete.');
            }
            // verify required parameter 'leadQuoteId' is not null or undefined
            if (leadQuoteId === null || leadQuoteId === undefined) {
                throw new RequiredError('leadQuoteId','Required parameter leadQuoteId was null or undefined when calling leadsQuotesItemsDelete.');
            }
            // verify required parameter 'leadQuoteItemId' is not null or undefined
            if (leadQuoteItemId === null || leadQuoteItemId === undefined) {
                throw new RequiredError('leadQuoteItemId','Required parameter leadQuoteItemId was null or undefined when calling leadsQuotesItemsDelete.');
            }
            const localVarPath = `/leads/{lead_id}/quotes/{lead_quote_id}/items/{lead_quote_item_id}`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)))
                .replace(`{${"lead_quote_id"}}`, encodeURIComponent(String(leadQuoteId)))
                .replace(`{${"lead_quote_item_id"}}`, encodeURIComponent(String(leadQuoteItemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {string} leadQuoteItemId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesItemsGet(leadId: string, leadQuoteId: string, leadQuoteItemId: string, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsQuotesItemsGet.');
            }
            // verify required parameter 'leadQuoteId' is not null or undefined
            if (leadQuoteId === null || leadQuoteId === undefined) {
                throw new RequiredError('leadQuoteId','Required parameter leadQuoteId was null or undefined when calling leadsQuotesItemsGet.');
            }
            // verify required parameter 'leadQuoteItemId' is not null or undefined
            if (leadQuoteItemId === null || leadQuoteItemId === undefined) {
                throw new RequiredError('leadQuoteItemId','Required parameter leadQuoteItemId was null or undefined when calling leadsQuotesItemsGet.');
            }
            const localVarPath = `/leads/{lead_id}/quotes/{lead_quote_id}/items/{lead_quote_item_id}`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)))
                .replace(`{${"lead_quote_id"}}`, encodeURIComponent(String(leadQuoteId)))
                .replace(`{${"lead_quote_item_id"}}`, encodeURIComponent(String(leadQuoteItemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesItemsIndex(leadId: string, leadQuoteId: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsQuotesItemsIndex.');
            }
            // verify required parameter 'leadQuoteId' is not null or undefined
            if (leadQuoteId === null || leadQuoteId === undefined) {
                throw new RequiredError('leadQuoteId','Required parameter leadQuoteId was null or undefined when calling leadsQuotesItemsIndex.');
            }
            const localVarPath = `/leads/{lead_id}/quotes/{lead_quote_id}/items`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)))
                .replace(`{${"lead_quote_id"}}`, encodeURIComponent(String(leadQuoteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {string} leadQuoteItemId
         * @param {LeadQuoteItemResource} leadQuoteItemResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesItemsUpdate(leadId: string, leadQuoteId: string, leadQuoteItemId: string, leadQuoteItemResource: LeadQuoteItemResource, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsQuotesItemsUpdate.');
            }
            // verify required parameter 'leadQuoteId' is not null or undefined
            if (leadQuoteId === null || leadQuoteId === undefined) {
                throw new RequiredError('leadQuoteId','Required parameter leadQuoteId was null or undefined when calling leadsQuotesItemsUpdate.');
            }
            // verify required parameter 'leadQuoteItemId' is not null or undefined
            if (leadQuoteItemId === null || leadQuoteItemId === undefined) {
                throw new RequiredError('leadQuoteItemId','Required parameter leadQuoteItemId was null or undefined when calling leadsQuotesItemsUpdate.');
            }
            // verify required parameter 'leadQuoteItemResource' is not null or undefined
            if (leadQuoteItemResource === null || leadQuoteItemResource === undefined) {
                throw new RequiredError('leadQuoteItemResource','Required parameter leadQuoteItemResource was null or undefined when calling leadsQuotesItemsUpdate.');
            }
            const localVarPath = `/leads/{lead_id}/quotes/{lead_quote_id}/items/{lead_quote_item_id}`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)))
                .replace(`{${"lead_quote_id"}}`, encodeURIComponent(String(leadQuoteId)))
                .replace(`{${"lead_quote_item_id"}}`, encodeURIComponent(String(leadQuoteItemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LeadQuoteItemResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(leadQuoteItemResource || {}) : (leadQuoteItemResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [title]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesProgressstepsIndex(title?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/leads/quotes/progresssteps`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadQuoteId
         * @param {LeadQuoteResource} leadQuoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesUpdate(leadQuoteId: string, leadQuoteResource: LeadQuoteResource, options: any = {}): FetchArgs {
            // verify required parameter 'leadQuoteId' is not null or undefined
            if (leadQuoteId === null || leadQuoteId === undefined) {
                throw new RequiredError('leadQuoteId','Required parameter leadQuoteId was null or undefined when calling leadsQuotesUpdate.');
            }
            // verify required parameter 'leadQuoteResource' is not null or undefined
            if (leadQuoteResource === null || leadQuoteResource === undefined) {
                throw new RequiredError('leadQuoteResource','Required parameter leadQuoteResource was null or undefined when calling leadsQuotesUpdate.');
            }
            const localVarPath = `/leads/quotes/{lead_quote_id}`
                .replace(`{${"lead_quote_id"}}`, encodeURIComponent(String(leadQuoteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LeadQuoteResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(leadQuoteResource || {}) : (leadQuoteResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {LeadQuoteResource} leadQuoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesUpdate_4(leadId: string, leadQuoteId: string, leadQuoteResource: LeadQuoteResource, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsQuotesUpdate_4.');
            }
            // verify required parameter 'leadQuoteId' is not null or undefined
            if (leadQuoteId === null || leadQuoteId === undefined) {
                throw new RequiredError('leadQuoteId','Required parameter leadQuoteId was null or undefined when calling leadsQuotesUpdate_4.');
            }
            // verify required parameter 'leadQuoteResource' is not null or undefined
            if (leadQuoteResource === null || leadQuoteResource === undefined) {
                throw new RequiredError('leadQuoteResource','Required parameter leadQuoteResource was null or undefined when calling leadsQuotesUpdate_4.');
            }
            const localVarPath = `/leads/{lead_id}/quotes/{lead_quote_id}`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)))
                .replace(`{${"lead_quote_id"}}`, encodeURIComponent(String(leadQuoteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LeadQuoteResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(leadQuoteResource || {}) : (leadQuoteResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsSegmentationsubgeoregionsIndex(leadId: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsSegmentationsubgeoregionsIndex.');
            }
            const localVarPath = `/leads/{lead_id}/segmentationsubgeoregions`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {SegmentationSubGeoRegionsResource} segmentationSubGeoRegionsResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsSegmentationsubgeoregionsUpdate(leadId: string, segmentationSubGeoRegionsResource: SegmentationSubGeoRegionsResource, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsSegmentationsubgeoregionsUpdate.');
            }
            // verify required parameter 'segmentationSubGeoRegionsResource' is not null or undefined
            if (segmentationSubGeoRegionsResource === null || segmentationSubGeoRegionsResource === undefined) {
                throw new RequiredError('segmentationSubGeoRegionsResource','Required parameter segmentationSubGeoRegionsResource was null or undefined when calling leadsSegmentationsubgeoregionsUpdate.');
            }
            const localVarPath = `/leads/{lead_id}/segmentationsubgeoregions`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SegmentationSubGeoRegionsResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(segmentationSubGeoRegionsResource || {}) : (segmentationSubGeoRegionsResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsTypegroupsIndex(geoCode?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/leads/typegroups`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [typeId] Type ID. @var int
         * @param {string} [title] Type title in its native language. @var string
         * @param {string} [geoCode] Industry geo code origin. @var string
         * @param {number} [limit] Limit of industries per page. @var int
         * @param {boolean} [isArchived]
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsTypesIndex(typeId?: string, title?: string, geoCode?: string, limit?: number, isArchived?: boolean, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/leads/types`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (typeId !== undefined) {
                localVarQueryParameter['type_id'] = typeId;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (isArchived !== undefined) {
                localVarQueryParameter['is_archived'] = isArchived;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {LeadResource} leadResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsUpdate(leadId: string, leadResource: LeadResource, options: any = {}): FetchArgs {
            // verify required parameter 'leadId' is not null or undefined
            if (leadId === null || leadId === undefined) {
                throw new RequiredError('leadId','Required parameter leadId was null or undefined when calling leadsUpdate.');
            }
            // verify required parameter 'leadResource' is not null or undefined
            if (leadResource === null || leadResource === undefined) {
                throw new RequiredError('leadResource','Required parameter leadResource was null or undefined when calling leadsUpdate.');
            }
            const localVarPath = `/leads/{lead_id}`
                .replace(`{${"lead_id"}}`, encodeURIComponent(String(leadId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LeadResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(leadResource || {}) : (leadResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeadsApi - functional programming interface
 * @export
 */
export const LeadsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * This function serves up one class with a lot of functionality
         * @summary This function serves up one class with a lot of functionality
         * @param {string} leadId
         * @param {LeadActionResource} leadActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsActionsCreate(leadId: string, leadActionResource: LeadActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadResource> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsActionsCreate(leadId, leadActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [leadId] List of leads identification(ID).
         * @param {string} [phone] Filter by a given phone number.
         * @param {number} [partnerId] Provide a given partner id to filter by. Requires \&quot;filter\&quot; to be \&quot;bought_by_partner\&quot; or \&quot;segmented_for_partner\&quot;.
         * @param {'' | 'dk' | 'no' | 'de' | 'se' | 'uk' | 'nl' | 'us' | 'com' | 'ascending' | 'descending'} [geoCode] Geographic Location Code.
         * @param {'created_at' | 'validated_at' | 'expires_at' | 'status' | 'quote_published_at' | 'distance' | 'completed_call_priority_date' | 'client_decision_postponed_to' | 'service_fee' | 'minimum_quote_amount_excl_vat' | 'last_action_at' | 'last_contact_at' | 'call_count_before_validation' | 'lead_quote_fee' | 'ascending' | 'descending'} [sort] Sort Leads by a given attribute.
         * @param {string} [status] Status for the given lead.
         * @param {number} [clientId] Lead&#39;s client
         * @param {string} [employeeId] Lead&#39;s client&#39;s assigned employee ID
         * @param {boolean} [clientDecisionPostponed] Client decision to choose a quote match is postponed or not.
         * @param {string} [allQuotesPublishedBefore] Lead received its latest quote match before this date.
         * @param {number} [expiresWithin] Get leads expiring within this many minutes.
         * @param {string} [industry] Industry code for the given lead.
         * @param {boolean} [isQuotesLead] Get leads that are for quotes. @var bool
         * @param {boolean} [hasMaxQuotes] Has maximum number of quotes. @var bool
         * @param {boolean} [hasAcceptedQuote] Has an accepted quote. @var bool
         * @param {boolean} [hasRejectedQuote] Has a rejected quote. @var bool
         * @param {number} [noQuoteForPartnerId] Get leads that do not have a quote for this partner ID. @var int
         * @param {boolean} [isMatchPriority] Is the lead prioritised for being matched? @var bool
         * @param {boolean} [isCompletedCallPriority] Is the lead prioritised for a completion call? @var bool
         * @param {string} [geoPoint] Location Point to sort leads by
         * @param {string} [geoRect] Location Points to define an area to filter leads by. Provide the bottom left (southwest) and top right (northeast) corners of the reactangle separated by commas, e.g. \&quot;52.19,12.42,52.85,14.22\&quot;.
         * @param {number} [geoDistance] Location filter by distance
         * @param {string} [minimumRevenueAmountExclVat] Get leads that will create at least this amount (in euros) of revenue if matched.
         * @param {string} [maximumRevenueAmountExclVat] Get leads that will create no more than this amount (in euros) of revenue if matched.
         * @param {'basic' | 'exclusive'} [leadCategory] Lead&#39;s category
         * @param {string} [validatorId] Lead&#39;s validator
         * @param {boolean} [isGivenUp] Is the lead given up on?
         * @param {string} [industryId] Filter the leads by their industry ids
         * @param {number} [callCountBeforeValidationLt] Lead must have less than this many calls before validation occurred.
         * @param {boolean} [isValidationPostponed] Is the lead&#39;s validation postponed?
         * @param {string} [createdAtLte] Get leads created before this date (inclusive).
         * @param {string} [createdAtGte] Get leads created after this date (inclusive).
         * @param {string} [validatedAtGte] Get leads validated after this date (inclusive).
         * @param {string} [latestCalledAtBefore] Find leads with a latest call before this date.
         * @param {string} [segmentationCriteria] Which criteria to segment on, e.g. language.
         * @param {number} [segmentedForPartnerBusinessUnitId] List leads that are segmented for a business unit.
         * @param {string} [subGeoRegionName] List leads in any of these sub geo regions. E.g. &#39;Aarhus&#39;, &#39;Copenhagen&#39;.
         * @param {boolean} [isAnonymized] Have the leads been anonymized.
         * @param {boolean} [hasLeadQuoteFee] Filter by has lead quote fee.
         * @param {string} [desiredPartnerCompanySizeId] Filter by desired partner company size.
         * @param {number} [segmentedForLeadMarketplacePartnerId] Find leads for the partner&#39;s lead marketplace.
         * @param {string} [personalTitleId] Filter by lead&#39;s personal title
         * @param {boolean} [isLimitedAccessMarketplaceLead] List marketplace leads that have been validated within limited access time window
         * @param {boolean} [isBasicAccessMarketplaceLead] List marketplace leads that have been created within basic access time window
         * @param {string} [typeId] List leads by the client type id
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsAggregationsIndex(leadId?: string, phone?: string, partnerId?: number, geoCode?: '' | 'dk' | 'no' | 'de' | 'se' | 'uk' | 'nl' | 'us' | 'com' | 'ascending' | 'descending', sort?: 'created_at' | 'validated_at' | 'expires_at' | 'status' | 'quote_published_at' | 'distance' | 'completed_call_priority_date' | 'client_decision_postponed_to' | 'service_fee' | 'minimum_quote_amount_excl_vat' | 'last_action_at' | 'last_contact_at' | 'call_count_before_validation' | 'lead_quote_fee' | 'ascending' | 'descending', status?: string, clientId?: number, employeeId?: string, clientDecisionPostponed?: boolean, allQuotesPublishedBefore?: string, expiresWithin?: number, industry?: string, isQuotesLead?: boolean, hasMaxQuotes?: boolean, hasAcceptedQuote?: boolean, hasRejectedQuote?: boolean, noQuoteForPartnerId?: number, isMatchPriority?: boolean, isCompletedCallPriority?: boolean, geoPoint?: string, geoRect?: string, geoDistance?: number, minimumRevenueAmountExclVat?: string, maximumRevenueAmountExclVat?: string, leadCategory?: 'basic' | 'exclusive', validatorId?: string, isGivenUp?: boolean, industryId?: string, callCountBeforeValidationLt?: number, isValidationPostponed?: boolean, createdAtLte?: string, createdAtGte?: string, validatedAtGte?: string, latestCalledAtBefore?: string, segmentationCriteria?: string, segmentedForPartnerBusinessUnitId?: number, subGeoRegionName?: string, isAnonymized?: boolean, hasLeadQuoteFee?: boolean, desiredPartnerCompanySizeId?: string, segmentedForLeadMarketplacePartnerId?: number, personalTitleId?: string, isLimitedAccessMarketplaceLead?: boolean, isBasicAccessMarketplaceLead?: boolean, typeId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AggregationResult> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsAggregationsIndex(leadId, phone, partnerId, geoCode, sort, status, clientId, employeeId, clientDecisionPostponed, allQuotesPublishedBefore, expiresWithin, industry, isQuotesLead, hasMaxQuotes, hasAcceptedQuote, hasRejectedQuote, noQuoteForPartnerId, isMatchPriority, isCompletedCallPriority, geoPoint, geoRect, geoDistance, minimumRevenueAmountExclVat, maximumRevenueAmountExclVat, leadCategory, validatorId, isGivenUp, industryId, callCountBeforeValidationLt, isValidationPostponed, createdAtLte, createdAtGte, validatedAtGte, latestCalledAtBefore, segmentationCriteria, segmentedForPartnerBusinessUnitId, subGeoRegionName, isAnonymized, hasLeadQuoteFee, desiredPartnerCompanySizeId, segmentedForLeadMarketplacePartnerId, personalTitleId, isLimitedAccessMarketplaceLead, isBasicAccessMarketplaceLead, typeId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {LeadAllocationsResource} leadAllocationsResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsAllocationsCreate(leadId: string, leadAllocationsResource: LeadAllocationsResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadAllocationsResource> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsAllocationsCreate(leadId, leadAllocationsResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} [leadId] Lead ID.
         * @param {number} [partnerId] Partner ID.
         * @param {string} [partnerBusinessUnitId] Business Unit ID.
         * @param {string} [startsAt]
         * @param {string} [endsAt]
         * @param {boolean} [hasBusinessUnit]
         * @param {'created_at' | 'quote_accepted_at' | 'quote_published_at' | 'partner_id' | 'lead_id' | 'partner_business_unit_id' | 'ascending' | 'descending'} [sort]
         * @param {boolean} [isUsed] Filter by the allocation being used or unused.
         * @param {boolean} [isProcessed] Filter by the allocation being used or unused.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsAllocationsIndex(leadId?: number, partnerId?: number, partnerBusinessUnitId?: string, startsAt?: string, endsAt?: string, hasBusinessUnit?: boolean, sort?: 'created_at' | 'quote_accepted_at' | 'quote_published_at' | 'partner_id' | 'lead_id' | 'partner_business_unit_id' | 'ascending' | 'descending', isUsed?: boolean, isProcessed?: boolean, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadPartnerAllocationResult> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsAllocationsIndex(leadId, partnerId, partnerBusinessUnitId, startsAt, endsAt, hasBusinessUnit, sort, isUsed, isProcessed, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} allocationSuggestionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsAllocationsuggestionsDelete(leadId: string, allocationSuggestionId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsAllocationsuggestionsDelete(leadId, allocationSuggestionId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [id] Category id @var int
         * @param {string} [identifier] Category identifier @var string
         * @param {string} [title] Category title. @var string
         * @param {number} [limit] Limit of industries per page. @var int
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsCategoriesIndex(id?: string, identifier?: string, title?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadCategoryResult> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsCategoriesIndex(id, identifier, title, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {CertificationResource} certificationResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsCertificationsCreate(leadId: string, certificationResource: CertificationResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CertificationResource> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsCertificationsCreate(leadId, certificationResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} certificationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsCertificationsDelete(leadId: string, certificationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsCertificationsDelete(leadId, certificationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {boolean} [isSegmentable] Is the certification used for segmentation?
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsCertificationsIndex(leadId: string, isSegmentable?: boolean, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CertificationResult> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsCertificationsIndex(leadId, isSegmentable, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {LeadCertificationsResource} leadCertificationsResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsCertificationsUpdate(leadId: string, leadCertificationsResource: LeadCertificationsResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CertificationResource> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsCertificationsUpdate(leadId, leadCertificationsResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsCompanysizesIndex(leadId: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadCompanySizesResult> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsCompanysizesIndex(leadId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {LeadCompanySizesResource} leadCompanySizesResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsCompanysizesUpdate(leadId: string, leadCompanySizesResource: LeadCompanySizesResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadCompanySizesResource> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsCompanysizesUpdate(leadId, leadCompanySizesResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {LeadResource} leadResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsCreate(leadResource: LeadResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadResource> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsCreate(leadResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsDelete(leadId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsDelete(leadId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsDemopartnersIndex(partnerId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadResult> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsDemopartnersIndex(partnerId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsDemopartnersIndex_1(leadId: string, partnerId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadResult> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsDemopartnersIndex_1(leadId, partnerId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {LeadBulkActionDiscardResource} leadBulkActionDiscardResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsDiscardsCreate(leadBulkActionDiscardResource: LeadBulkActionDiscardResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadBulkActionDiscardResource> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsDiscardsCreate(leadBulkActionDiscardResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {PartnerSuggestResource} partnerSuggestResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsExcludedpartnersCreate(leadId: string, partnerSuggestResource: PartnerSuggestResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadResource> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsExcludedpartnersCreate(leadId, partnerSuggestResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsExcludedpartnersDelete(leadId: string, partnerId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsExcludedpartnersDelete(leadId, partnerId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsExcludedpartnersIndex(leadId: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadPartnerResult> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsExcludedpartnersIndex(leadId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsGet(leadId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadResource> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsGet(leadId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [leadId] List of leads identification(ID).
         * @param {string} [phone] Filter by a given phone number.
         * @param {number} [partnerId] Provide a given partner id to filter by. Requires \&quot;filter\&quot; to be \&quot;bought_by_partner\&quot; or \&quot;segmented_for_partner\&quot;.
         * @param {'' | 'dk' | 'no' | 'de' | 'se' | 'uk' | 'nl' | 'us' | 'com' | 'ascending' | 'descending'} [geoCode] Geographic Location Code.
         * @param {'created_at' | 'validated_at' | 'expires_at' | 'status' | 'quote_published_at' | 'distance' | 'completed_call_priority_date' | 'client_decision_postponed_to' | 'service_fee' | 'minimum_quote_amount_excl_vat' | 'last_action_at' | 'last_contact_at' | 'call_count_before_validation' | 'lead_quote_fee' | 'ascending' | 'descending'} [sort] Sort Leads by a given attribute.
         * @param {string} [status] Status for the given lead.
         * @param {number} [clientId] Lead&#39;s client
         * @param {string} [employeeId] Lead&#39;s client&#39;s assigned employee ID
         * @param {boolean} [clientDecisionPostponed] Client decision to choose a quote match is postponed or not.
         * @param {string} [allQuotesPublishedBefore] Lead received its latest quote match before this date.
         * @param {number} [expiresWithin] Get leads expiring within this many minutes.
         * @param {string} [industry] Industry code for the given lead.
         * @param {boolean} [isQuotesLead] Get leads that are for quotes. @var bool
         * @param {boolean} [hasMaxQuotes] Has maximum number of quotes. @var bool
         * @param {boolean} [hasAcceptedQuote] Has an accepted quote. @var bool
         * @param {boolean} [hasRejectedQuote] Has a rejected quote. @var bool
         * @param {number} [noQuoteForPartnerId] Get leads that do not have a quote for this partner ID. @var int
         * @param {boolean} [isMatchPriority] Is the lead prioritised for being matched? @var bool
         * @param {boolean} [isCompletedCallPriority] Is the lead prioritised for a completion call? @var bool
         * @param {string} [geoPoint] Location Point to sort leads by
         * @param {string} [geoRect] Location Points to define an area to filter leads by. Provide the bottom left (southwest) and top right (northeast) corners of the reactangle separated by commas, e.g. \&quot;52.19,12.42,52.85,14.22\&quot;.
         * @param {number} [geoDistance] Location filter by distance
         * @param {string} [minimumRevenueAmountExclVat] Get leads that will create at least this amount (in euros) of revenue if matched.
         * @param {string} [maximumRevenueAmountExclVat] Get leads that will create no more than this amount (in euros) of revenue if matched.
         * @param {'basic' | 'exclusive'} [leadCategory] Lead&#39;s category
         * @param {string} [validatorId] Lead&#39;s validator
         * @param {boolean} [isGivenUp] Is the lead given up on?
         * @param {string} [industryId] Filter the leads by their industry ids
         * @param {number} [callCountBeforeValidationLt] Lead must have less than this many calls before validation occurred.
         * @param {boolean} [isValidationPostponed] Is the lead&#39;s validation postponed?
         * @param {string} [createdAtLte] Get leads created before this date (inclusive).
         * @param {string} [createdAtGte] Get leads created after this date (inclusive).
         * @param {string} [validatedAtGte] Get leads validated after this date (inclusive).
         * @param {string} [latestCalledAtBefore] Find leads with a latest call before this date.
         * @param {string} [segmentationCriteria] Which criteria to segment on, e.g. language.
         * @param {number} [segmentedForPartnerBusinessUnitId] List leads that are segmented for a business unit.
         * @param {string} [subGeoRegionName] List leads in any of these sub geo regions. E.g. &#39;Aarhus&#39;, &#39;Copenhagen&#39;.
         * @param {boolean} [isAnonymized] Have the leads been anonymized.
         * @param {boolean} [hasLeadQuoteFee] Filter by has lead quote fee.
         * @param {string} [desiredPartnerCompanySizeId] Filter by desired partner company size.
         * @param {number} [segmentedForLeadMarketplacePartnerId] Find leads for the partner&#39;s lead marketplace.
         * @param {string} [personalTitleId] Filter by lead&#39;s personal title
         * @param {boolean} [isLimitedAccessMarketplaceLead] List marketplace leads that have been validated within limited access time window
         * @param {boolean} [isBasicAccessMarketplaceLead] List marketplace leads that have been created within basic access time window
         * @param {string} [typeId] List leads by the client type id
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsIndex(leadId?: string, phone?: string, partnerId?: number, geoCode?: '' | 'dk' | 'no' | 'de' | 'se' | 'uk' | 'nl' | 'us' | 'com' | 'ascending' | 'descending', sort?: 'created_at' | 'validated_at' | 'expires_at' | 'status' | 'quote_published_at' | 'distance' | 'completed_call_priority_date' | 'client_decision_postponed_to' | 'service_fee' | 'minimum_quote_amount_excl_vat' | 'last_action_at' | 'last_contact_at' | 'call_count_before_validation' | 'lead_quote_fee' | 'ascending' | 'descending', status?: string, clientId?: number, employeeId?: string, clientDecisionPostponed?: boolean, allQuotesPublishedBefore?: string, expiresWithin?: number, industry?: string, isQuotesLead?: boolean, hasMaxQuotes?: boolean, hasAcceptedQuote?: boolean, hasRejectedQuote?: boolean, noQuoteForPartnerId?: number, isMatchPriority?: boolean, isCompletedCallPriority?: boolean, geoPoint?: string, geoRect?: string, geoDistance?: number, minimumRevenueAmountExclVat?: string, maximumRevenueAmountExclVat?: string, leadCategory?: 'basic' | 'exclusive', validatorId?: string, isGivenUp?: boolean, industryId?: string, callCountBeforeValidationLt?: number, isValidationPostponed?: boolean, createdAtLte?: string, createdAtGte?: string, validatedAtGte?: string, latestCalledAtBefore?: string, segmentationCriteria?: string, segmentedForPartnerBusinessUnitId?: number, subGeoRegionName?: string, isAnonymized?: boolean, hasLeadQuoteFee?: boolean, desiredPartnerCompanySizeId?: string, segmentedForLeadMarketplacePartnerId?: number, personalTitleId?: string, isLimitedAccessMarketplaceLead?: boolean, isBasicAccessMarketplaceLead?: boolean, typeId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadResult> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsIndex(leadId, phone, partnerId, geoCode, sort, status, clientId, employeeId, clientDecisionPostponed, allQuotesPublishedBefore, expiresWithin, industry, isQuotesLead, hasMaxQuotes, hasAcceptedQuote, hasRejectedQuote, noQuoteForPartnerId, isMatchPriority, isCompletedCallPriority, geoPoint, geoRect, geoDistance, minimumRevenueAmountExclVat, maximumRevenueAmountExclVat, leadCategory, validatorId, isGivenUp, industryId, callCountBeforeValidationLt, isValidationPostponed, createdAtLte, createdAtGte, validatedAtGte, latestCalledAtBefore, segmentationCriteria, segmentedForPartnerBusinessUnitId, subGeoRegionName, isAnonymized, hasLeadQuoteFee, desiredPartnerCompanySizeId, segmentedForLeadMarketplacePartnerId, personalTitleId, isLimitedAccessMarketplaceLead, isBasicAccessMarketplaceLead, typeId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {LeadNoteResource} leadNoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsNotesCreate(leadId: string, leadNoteResource: LeadNoteResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadResource> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsNotesCreate(leadId, leadNoteResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {LeadQuoteFeeResource} leadQuoteFeeResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotefeesCreate(leadId: string, leadQuoteFeeResource: LeadQuoteFeeResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadQuoteFeeResource> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsQuotefeesCreate(leadId, leadQuoteFeeResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotefeesDelete(leadId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsQuotefeesDelete(leadId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {LeadQuoteActionResource} leadQuoteActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesActionsCreate(leadId: string, leadQuoteId: string, leadQuoteActionResource: LeadQuoteActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadQuoteResource> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsQuotesActionsCreate(leadId, leadQuoteId, leadQuoteActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {LeadQuoteResource} leadQuoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesCreate(leadId: string, leadQuoteResource: LeadQuoteResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadQuoteResource> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsQuotesCreate(leadId, leadQuoteResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesDelete(leadId: string, leadQuoteId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsQuotesDelete(leadId, leadQuoteId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadQuoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesGet(leadQuoteId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadQuoteResource> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsQuotesGet(leadQuoteId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesGet_2(leadId: string, leadQuoteId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadQuoteResource> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsQuotesGet_2(leadId, leadQuoteId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [clientId] List of quotes by client_id
         * @param {string} [leadId] List of leads identification(ID).
         * @param {string} [leadQuoteId] List of lead quotes identification(ID).
         * @param {string} [partnerId] Offers belonging to a given partner.
         * @param {string} [geoCode] Geographic Location Code.
         * @param {boolean} [isAccepted] Quote is accepted.
         * @param {boolean} [isPublished] Quote is published.
         * @param {boolean} [isRejected] Quote is rejected.
         * @param {'created_at' | 'accepted_at' | 'published_at' | 'rejected_at' | 'total_price_excl_vat' | 'ascending' | 'descending'} [sort] Sort options.
         * @param {string} [leadCategoryType] Filter by lead category type.
         * @param {boolean} [reservationIsExpired] Quote&#39;s reservation is expired.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesIndex(clientId?: string, leadId?: string, leadQuoteId?: string, partnerId?: string, geoCode?: string, isAccepted?: boolean, isPublished?: boolean, isRejected?: boolean, sort?: 'created_at' | 'accepted_at' | 'published_at' | 'rejected_at' | 'total_price_excl_vat' | 'ascending' | 'descending', leadCategoryType?: string, reservationIsExpired?: boolean, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadQuoteResult> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsQuotesIndex(clientId, leadId, leadQuoteId, partnerId, geoCode, isAccepted, isPublished, isRejected, sort, leadCategoryType, reservationIsExpired, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} [clientId] List of quotes by client_id
         * @param {string} [leadQuoteId] List of lead quotes identification(ID).
         * @param {string} [partnerId] Offers belonging to a given partner.
         * @param {string} [geoCode] Geographic Location Code.
         * @param {boolean} [isAccepted] Quote is accepted.
         * @param {boolean} [isPublished] Quote is published.
         * @param {boolean} [isRejected] Quote is rejected.
         * @param {'created_at' | 'accepted_at' | 'published_at' | 'rejected_at' | 'total_price_excl_vat' | 'ascending' | 'descending'} [sort] Sort options.
         * @param {string} [leadCategoryType] Filter by lead category type.
         * @param {boolean} [reservationIsExpired] Quote&#39;s reservation is expired.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesIndex_3(leadId: string, clientId?: string, leadQuoteId?: string, partnerId?: string, geoCode?: string, isAccepted?: boolean, isPublished?: boolean, isRejected?: boolean, sort?: 'created_at' | 'accepted_at' | 'published_at' | 'rejected_at' | 'total_price_excl_vat' | 'ascending' | 'descending', leadCategoryType?: string, reservationIsExpired?: boolean, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadQuoteResult> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsQuotesIndex_3(leadId, clientId, leadQuoteId, partnerId, geoCode, isAccepted, isPublished, isRejected, sort, leadCategoryType, reservationIsExpired, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {LeadQuoteItemResource} leadQuoteItemResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesItemsCreate(leadId: string, leadQuoteId: string, leadQuoteItemResource: LeadQuoteItemResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadQuoteItemResource> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsQuotesItemsCreate(leadId, leadQuoteId, leadQuoteItemResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {string} leadQuoteItemId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesItemsDelete(leadId: string, leadQuoteId: string, leadQuoteItemId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsQuotesItemsDelete(leadId, leadQuoteId, leadQuoteItemId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {string} leadQuoteItemId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesItemsGet(leadId: string, leadQuoteId: string, leadQuoteItemId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadQuoteItemResource> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsQuotesItemsGet(leadId, leadQuoteId, leadQuoteItemId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesItemsIndex(leadId: string, leadQuoteId: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadQuoteItemResult> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsQuotesItemsIndex(leadId, leadQuoteId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {string} leadQuoteItemId
         * @param {LeadQuoteItemResource} leadQuoteItemResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesItemsUpdate(leadId: string, leadQuoteId: string, leadQuoteItemId: string, leadQuoteItemResource: LeadQuoteItemResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadQuoteItemResource> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsQuotesItemsUpdate(leadId, leadQuoteId, leadQuoteItemId, leadQuoteItemResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [title]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesProgressstepsIndex(title?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadQuoteProgressResult> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsQuotesProgressstepsIndex(title, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadQuoteId
         * @param {LeadQuoteResource} leadQuoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesUpdate(leadQuoteId: string, leadQuoteResource: LeadQuoteResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadQuoteResource> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsQuotesUpdate(leadQuoteId, leadQuoteResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {LeadQuoteResource} leadQuoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesUpdate_4(leadId: string, leadQuoteId: string, leadQuoteResource: LeadQuoteResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadQuoteResource> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsQuotesUpdate_4(leadId, leadQuoteId, leadQuoteResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsSegmentationsubgeoregionsIndex(leadId: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SegmentationSubGeoRegionResult> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsSegmentationsubgeoregionsIndex(leadId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {SegmentationSubGeoRegionsResource} segmentationSubGeoRegionsResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsSegmentationsubgeoregionsUpdate(leadId: string, segmentationSubGeoRegionsResource: SegmentationSubGeoRegionsResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SegmentationSubGeoRegionsResource> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsSegmentationsubgeoregionsUpdate(leadId, segmentationSubGeoRegionsResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsTypegroupsIndex(geoCode?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadTypeGroupResult> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsTypegroupsIndex(geoCode, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [typeId] Type ID. @var int
         * @param {string} [title] Type title in its native language. @var string
         * @param {string} [geoCode] Industry geo code origin. @var string
         * @param {number} [limit] Limit of industries per page. @var int
         * @param {boolean} [isArchived]
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsTypesIndex(typeId?: string, title?: string, geoCode?: string, limit?: number, isArchived?: boolean, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadTypeResult> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsTypesIndex(typeId, title, geoCode, limit, isArchived, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadId
         * @param {LeadResource} leadResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsUpdate(leadId: string, leadResource: LeadResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadResource> {
            const localVarFetchArgs = LeadsApiFetchParamCreator(configuration).leadsUpdate(leadId, leadResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * LeadsApi - factory interface
 * @export
 */
export const LeadsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * This function serves up one class with a lot of functionality
         * @summary This function serves up one class with a lot of functionality
         * @param {string} leadId
         * @param {LeadActionResource} leadActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsActionsCreate(leadId: string, leadActionResource: LeadActionResource, options?: any) {
            return LeadsApiFp(configuration).leadsActionsCreate(leadId, leadActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [leadId] List of leads identification(ID).
         * @param {string} [phone] Filter by a given phone number.
         * @param {number} [partnerId] Provide a given partner id to filter by. Requires \&quot;filter\&quot; to be \&quot;bought_by_partner\&quot; or \&quot;segmented_for_partner\&quot;.
         * @param {'' | 'dk' | 'no' | 'de' | 'se' | 'uk' | 'nl' | 'us' | 'com' | 'ascending' | 'descending'} [geoCode] Geographic Location Code.
         * @param {'created_at' | 'validated_at' | 'expires_at' | 'status' | 'quote_published_at' | 'distance' | 'completed_call_priority_date' | 'client_decision_postponed_to' | 'service_fee' | 'minimum_quote_amount_excl_vat' | 'last_action_at' | 'last_contact_at' | 'call_count_before_validation' | 'lead_quote_fee' | 'ascending' | 'descending'} [sort] Sort Leads by a given attribute.
         * @param {string} [status] Status for the given lead.
         * @param {number} [clientId] Lead&#39;s client
         * @param {string} [employeeId] Lead&#39;s client&#39;s assigned employee ID
         * @param {boolean} [clientDecisionPostponed] Client decision to choose a quote match is postponed or not.
         * @param {string} [allQuotesPublishedBefore] Lead received its latest quote match before this date.
         * @param {number} [expiresWithin] Get leads expiring within this many minutes.
         * @param {string} [industry] Industry code for the given lead.
         * @param {boolean} [isQuotesLead] Get leads that are for quotes. @var bool
         * @param {boolean} [hasMaxQuotes] Has maximum number of quotes. @var bool
         * @param {boolean} [hasAcceptedQuote] Has an accepted quote. @var bool
         * @param {boolean} [hasRejectedQuote] Has a rejected quote. @var bool
         * @param {number} [noQuoteForPartnerId] Get leads that do not have a quote for this partner ID. @var int
         * @param {boolean} [isMatchPriority] Is the lead prioritised for being matched? @var bool
         * @param {boolean} [isCompletedCallPriority] Is the lead prioritised for a completion call? @var bool
         * @param {string} [geoPoint] Location Point to sort leads by
         * @param {string} [geoRect] Location Points to define an area to filter leads by. Provide the bottom left (southwest) and top right (northeast) corners of the reactangle separated by commas, e.g. \&quot;52.19,12.42,52.85,14.22\&quot;.
         * @param {number} [geoDistance] Location filter by distance
         * @param {string} [minimumRevenueAmountExclVat] Get leads that will create at least this amount (in euros) of revenue if matched.
         * @param {string} [maximumRevenueAmountExclVat] Get leads that will create no more than this amount (in euros) of revenue if matched.
         * @param {'basic' | 'exclusive'} [leadCategory] Lead&#39;s category
         * @param {string} [validatorId] Lead&#39;s validator
         * @param {boolean} [isGivenUp] Is the lead given up on?
         * @param {string} [industryId] Filter the leads by their industry ids
         * @param {number} [callCountBeforeValidationLt] Lead must have less than this many calls before validation occurred.
         * @param {boolean} [isValidationPostponed] Is the lead&#39;s validation postponed?
         * @param {string} [createdAtLte] Get leads created before this date (inclusive).
         * @param {string} [createdAtGte] Get leads created after this date (inclusive).
         * @param {string} [validatedAtGte] Get leads validated after this date (inclusive).
         * @param {string} [latestCalledAtBefore] Find leads with a latest call before this date.
         * @param {string} [segmentationCriteria] Which criteria to segment on, e.g. language.
         * @param {number} [segmentedForPartnerBusinessUnitId] List leads that are segmented for a business unit.
         * @param {string} [subGeoRegionName] List leads in any of these sub geo regions. E.g. &#39;Aarhus&#39;, &#39;Copenhagen&#39;.
         * @param {boolean} [isAnonymized] Have the leads been anonymized.
         * @param {boolean} [hasLeadQuoteFee] Filter by has lead quote fee.
         * @param {string} [desiredPartnerCompanySizeId] Filter by desired partner company size.
         * @param {number} [segmentedForLeadMarketplacePartnerId] Find leads for the partner&#39;s lead marketplace.
         * @param {string} [personalTitleId] Filter by lead&#39;s personal title
         * @param {boolean} [isLimitedAccessMarketplaceLead] List marketplace leads that have been validated within limited access time window
         * @param {boolean} [isBasicAccessMarketplaceLead] List marketplace leads that have been created within basic access time window
         * @param {string} [typeId] List leads by the client type id
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsAggregationsIndex(leadId?: string, phone?: string, partnerId?: number, geoCode?: '' | 'dk' | 'no' | 'de' | 'se' | 'uk' | 'nl' | 'us' | 'com' | 'ascending' | 'descending', sort?: 'created_at' | 'validated_at' | 'expires_at' | 'status' | 'quote_published_at' | 'distance' | 'completed_call_priority_date' | 'client_decision_postponed_to' | 'service_fee' | 'minimum_quote_amount_excl_vat' | 'last_action_at' | 'last_contact_at' | 'call_count_before_validation' | 'lead_quote_fee' | 'ascending' | 'descending', status?: string, clientId?: number, employeeId?: string, clientDecisionPostponed?: boolean, allQuotesPublishedBefore?: string, expiresWithin?: number, industry?: string, isQuotesLead?: boolean, hasMaxQuotes?: boolean, hasAcceptedQuote?: boolean, hasRejectedQuote?: boolean, noQuoteForPartnerId?: number, isMatchPriority?: boolean, isCompletedCallPriority?: boolean, geoPoint?: string, geoRect?: string, geoDistance?: number, minimumRevenueAmountExclVat?: string, maximumRevenueAmountExclVat?: string, leadCategory?: 'basic' | 'exclusive', validatorId?: string, isGivenUp?: boolean, industryId?: string, callCountBeforeValidationLt?: number, isValidationPostponed?: boolean, createdAtLte?: string, createdAtGte?: string, validatedAtGte?: string, latestCalledAtBefore?: string, segmentationCriteria?: string, segmentedForPartnerBusinessUnitId?: number, subGeoRegionName?: string, isAnonymized?: boolean, hasLeadQuoteFee?: boolean, desiredPartnerCompanySizeId?: string, segmentedForLeadMarketplacePartnerId?: number, personalTitleId?: string, isLimitedAccessMarketplaceLead?: boolean, isBasicAccessMarketplaceLead?: boolean, typeId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return LeadsApiFp(configuration).leadsAggregationsIndex(leadId, phone, partnerId, geoCode, sort, status, clientId, employeeId, clientDecisionPostponed, allQuotesPublishedBefore, expiresWithin, industry, isQuotesLead, hasMaxQuotes, hasAcceptedQuote, hasRejectedQuote, noQuoteForPartnerId, isMatchPriority, isCompletedCallPriority, geoPoint, geoRect, geoDistance, minimumRevenueAmountExclVat, maximumRevenueAmountExclVat, leadCategory, validatorId, isGivenUp, industryId, callCountBeforeValidationLt, isValidationPostponed, createdAtLte, createdAtGte, validatedAtGte, latestCalledAtBefore, segmentationCriteria, segmentedForPartnerBusinessUnitId, subGeoRegionName, isAnonymized, hasLeadQuoteFee, desiredPartnerCompanySizeId, segmentedForLeadMarketplacePartnerId, personalTitleId, isLimitedAccessMarketplaceLead, isBasicAccessMarketplaceLead, typeId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {LeadAllocationsResource} leadAllocationsResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsAllocationsCreate(leadId: string, leadAllocationsResource: LeadAllocationsResource, options?: any) {
            return LeadsApiFp(configuration).leadsAllocationsCreate(leadId, leadAllocationsResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} [leadId] Lead ID.
         * @param {number} [partnerId] Partner ID.
         * @param {string} [partnerBusinessUnitId] Business Unit ID.
         * @param {string} [startsAt]
         * @param {string} [endsAt]
         * @param {boolean} [hasBusinessUnit]
         * @param {'created_at' | 'quote_accepted_at' | 'quote_published_at' | 'partner_id' | 'lead_id' | 'partner_business_unit_id' | 'ascending' | 'descending'} [sort]
         * @param {boolean} [isUsed] Filter by the allocation being used or unused.
         * @param {boolean} [isProcessed] Filter by the allocation being used or unused.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsAllocationsIndex(leadId?: number, partnerId?: number, partnerBusinessUnitId?: string, startsAt?: string, endsAt?: string, hasBusinessUnit?: boolean, sort?: 'created_at' | 'quote_accepted_at' | 'quote_published_at' | 'partner_id' | 'lead_id' | 'partner_business_unit_id' | 'ascending' | 'descending', isUsed?: boolean, isProcessed?: boolean, limit?: number, page?: number, query?: string, options?: any) {
            return LeadsApiFp(configuration).leadsAllocationsIndex(leadId, partnerId, partnerBusinessUnitId, startsAt, endsAt, hasBusinessUnit, sort, isUsed, isProcessed, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {string} allocationSuggestionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsAllocationsuggestionsDelete(leadId: string, allocationSuggestionId: string, options?: any) {
            return LeadsApiFp(configuration).leadsAllocationsuggestionsDelete(leadId, allocationSuggestionId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [id] Category id @var int
         * @param {string} [identifier] Category identifier @var string
         * @param {string} [title] Category title. @var string
         * @param {number} [limit] Limit of industries per page. @var int
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsCategoriesIndex(id?: string, identifier?: string, title?: string, limit?: number, page?: number, query?: string, options?: any) {
            return LeadsApiFp(configuration).leadsCategoriesIndex(id, identifier, title, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {CertificationResource} certificationResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsCertificationsCreate(leadId: string, certificationResource: CertificationResource, options?: any) {
            return LeadsApiFp(configuration).leadsCertificationsCreate(leadId, certificationResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {string} certificationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsCertificationsDelete(leadId: string, certificationId: string, options?: any) {
            return LeadsApiFp(configuration).leadsCertificationsDelete(leadId, certificationId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {boolean} [isSegmentable] Is the certification used for segmentation?
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsCertificationsIndex(leadId: string, isSegmentable?: boolean, limit?: number, page?: number, query?: string, options?: any) {
            return LeadsApiFp(configuration).leadsCertificationsIndex(leadId, isSegmentable, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {LeadCertificationsResource} leadCertificationsResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsCertificationsUpdate(leadId: string, leadCertificationsResource: LeadCertificationsResource, options?: any) {
            return LeadsApiFp(configuration).leadsCertificationsUpdate(leadId, leadCertificationsResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsCompanysizesIndex(leadId: string, limit?: number, page?: number, query?: string, options?: any) {
            return LeadsApiFp(configuration).leadsCompanysizesIndex(leadId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {LeadCompanySizesResource} leadCompanySizesResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsCompanysizesUpdate(leadId: string, leadCompanySizesResource: LeadCompanySizesResource, options?: any) {
            return LeadsApiFp(configuration).leadsCompanysizesUpdate(leadId, leadCompanySizesResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {LeadResource} leadResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsCreate(leadResource: LeadResource, options?: any) {
            return LeadsApiFp(configuration).leadsCreate(leadResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsDelete(leadId: string, options?: any) {
            return LeadsApiFp(configuration).leadsDelete(leadId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsDemopartnersIndex(partnerId: string, options?: any) {
            return LeadsApiFp(configuration).leadsDemopartnersIndex(partnerId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsDemopartnersIndex_1(leadId: string, partnerId: string, options?: any) {
            return LeadsApiFp(configuration).leadsDemopartnersIndex_1(leadId, partnerId, options)(fetch, basePath);
        },
        /**
         *
         * @param {LeadBulkActionDiscardResource} leadBulkActionDiscardResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsDiscardsCreate(leadBulkActionDiscardResource: LeadBulkActionDiscardResource, options?: any) {
            return LeadsApiFp(configuration).leadsDiscardsCreate(leadBulkActionDiscardResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {PartnerSuggestResource} partnerSuggestResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsExcludedpartnersCreate(leadId: string, partnerSuggestResource: PartnerSuggestResource, options?: any) {
            return LeadsApiFp(configuration).leadsExcludedpartnersCreate(leadId, partnerSuggestResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsExcludedpartnersDelete(leadId: string, partnerId: string, options?: any) {
            return LeadsApiFp(configuration).leadsExcludedpartnersDelete(leadId, partnerId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsExcludedpartnersIndex(leadId: string, limit?: number, page?: number, query?: string, options?: any) {
            return LeadsApiFp(configuration).leadsExcludedpartnersIndex(leadId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsGet(leadId: string, options?: any) {
            return LeadsApiFp(configuration).leadsGet(leadId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [leadId] List of leads identification(ID).
         * @param {string} [phone] Filter by a given phone number.
         * @param {number} [partnerId] Provide a given partner id to filter by. Requires \&quot;filter\&quot; to be \&quot;bought_by_partner\&quot; or \&quot;segmented_for_partner\&quot;.
         * @param {'' | 'dk' | 'no' | 'de' | 'se' | 'uk' | 'nl' | 'us' | 'com' | 'ascending' | 'descending'} [geoCode] Geographic Location Code.
         * @param {'created_at' | 'validated_at' | 'expires_at' | 'status' | 'quote_published_at' | 'distance' | 'completed_call_priority_date' | 'client_decision_postponed_to' | 'service_fee' | 'minimum_quote_amount_excl_vat' | 'last_action_at' | 'last_contact_at' | 'call_count_before_validation' | 'lead_quote_fee' | 'ascending' | 'descending'} [sort] Sort Leads by a given attribute.
         * @param {string} [status] Status for the given lead.
         * @param {number} [clientId] Lead&#39;s client
         * @param {string} [employeeId] Lead&#39;s client&#39;s assigned employee ID
         * @param {boolean} [clientDecisionPostponed] Client decision to choose a quote match is postponed or not.
         * @param {string} [allQuotesPublishedBefore] Lead received its latest quote match before this date.
         * @param {number} [expiresWithin] Get leads expiring within this many minutes.
         * @param {string} [industry] Industry code for the given lead.
         * @param {boolean} [isQuotesLead] Get leads that are for quotes. @var bool
         * @param {boolean} [hasMaxQuotes] Has maximum number of quotes. @var bool
         * @param {boolean} [hasAcceptedQuote] Has an accepted quote. @var bool
         * @param {boolean} [hasRejectedQuote] Has a rejected quote. @var bool
         * @param {number} [noQuoteForPartnerId] Get leads that do not have a quote for this partner ID. @var int
         * @param {boolean} [isMatchPriority] Is the lead prioritised for being matched? @var bool
         * @param {boolean} [isCompletedCallPriority] Is the lead prioritised for a completion call? @var bool
         * @param {string} [geoPoint] Location Point to sort leads by
         * @param {string} [geoRect] Location Points to define an area to filter leads by. Provide the bottom left (southwest) and top right (northeast) corners of the reactangle separated by commas, e.g. \&quot;52.19,12.42,52.85,14.22\&quot;.
         * @param {number} [geoDistance] Location filter by distance
         * @param {string} [minimumRevenueAmountExclVat] Get leads that will create at least this amount (in euros) of revenue if matched.
         * @param {string} [maximumRevenueAmountExclVat] Get leads that will create no more than this amount (in euros) of revenue if matched.
         * @param {'basic' | 'exclusive'} [leadCategory] Lead&#39;s category
         * @param {string} [validatorId] Lead&#39;s validator
         * @param {boolean} [isGivenUp] Is the lead given up on?
         * @param {string} [industryId] Filter the leads by their industry ids
         * @param {number} [callCountBeforeValidationLt] Lead must have less than this many calls before validation occurred.
         * @param {boolean} [isValidationPostponed] Is the lead&#39;s validation postponed?
         * @param {string} [createdAtLte] Get leads created before this date (inclusive).
         * @param {string} [createdAtGte] Get leads created after this date (inclusive).
         * @param {string} [validatedAtGte] Get leads validated after this date (inclusive).
         * @param {string} [latestCalledAtBefore] Find leads with a latest call before this date.
         * @param {string} [segmentationCriteria] Which criteria to segment on, e.g. language.
         * @param {number} [segmentedForPartnerBusinessUnitId] List leads that are segmented for a business unit.
         * @param {string} [subGeoRegionName] List leads in any of these sub geo regions. E.g. &#39;Aarhus&#39;, &#39;Copenhagen&#39;.
         * @param {boolean} [isAnonymized] Have the leads been anonymized.
         * @param {boolean} [hasLeadQuoteFee] Filter by has lead quote fee.
         * @param {string} [desiredPartnerCompanySizeId] Filter by desired partner company size.
         * @param {number} [segmentedForLeadMarketplacePartnerId] Find leads for the partner&#39;s lead marketplace.
         * @param {string} [personalTitleId] Filter by lead&#39;s personal title
         * @param {boolean} [isLimitedAccessMarketplaceLead] List marketplace leads that have been validated within limited access time window
         * @param {boolean} [isBasicAccessMarketplaceLead] List marketplace leads that have been created within basic access time window
         * @param {string} [typeId] List leads by the client type id
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsIndex(leadId?: string, phone?: string, partnerId?: number, geoCode?: '' | 'dk' | 'no' | 'de' | 'se' | 'uk' | 'nl' | 'us' | 'com' | 'ascending' | 'descending', sort?: 'created_at' | 'validated_at' | 'expires_at' | 'status' | 'quote_published_at' | 'distance' | 'completed_call_priority_date' | 'client_decision_postponed_to' | 'service_fee' | 'minimum_quote_amount_excl_vat' | 'last_action_at' | 'last_contact_at' | 'call_count_before_validation' | 'lead_quote_fee' | 'ascending' | 'descending', status?: string, clientId?: number, employeeId?: string, clientDecisionPostponed?: boolean, allQuotesPublishedBefore?: string, expiresWithin?: number, industry?: string, isQuotesLead?: boolean, hasMaxQuotes?: boolean, hasAcceptedQuote?: boolean, hasRejectedQuote?: boolean, noQuoteForPartnerId?: number, isMatchPriority?: boolean, isCompletedCallPriority?: boolean, geoPoint?: string, geoRect?: string, geoDistance?: number, minimumRevenueAmountExclVat?: string, maximumRevenueAmountExclVat?: string, leadCategory?: 'basic' | 'exclusive', validatorId?: string, isGivenUp?: boolean, industryId?: string, callCountBeforeValidationLt?: number, isValidationPostponed?: boolean, createdAtLte?: string, createdAtGte?: string, validatedAtGte?: string, latestCalledAtBefore?: string, segmentationCriteria?: string, segmentedForPartnerBusinessUnitId?: number, subGeoRegionName?: string, isAnonymized?: boolean, hasLeadQuoteFee?: boolean, desiredPartnerCompanySizeId?: string, segmentedForLeadMarketplacePartnerId?: number, personalTitleId?: string, isLimitedAccessMarketplaceLead?: boolean, isBasicAccessMarketplaceLead?: boolean, typeId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return LeadsApiFp(configuration).leadsIndex(leadId, phone, partnerId, geoCode, sort, status, clientId, employeeId, clientDecisionPostponed, allQuotesPublishedBefore, expiresWithin, industry, isQuotesLead, hasMaxQuotes, hasAcceptedQuote, hasRejectedQuote, noQuoteForPartnerId, isMatchPriority, isCompletedCallPriority, geoPoint, geoRect, geoDistance, minimumRevenueAmountExclVat, maximumRevenueAmountExclVat, leadCategory, validatorId, isGivenUp, industryId, callCountBeforeValidationLt, isValidationPostponed, createdAtLte, createdAtGte, validatedAtGte, latestCalledAtBefore, segmentationCriteria, segmentedForPartnerBusinessUnitId, subGeoRegionName, isAnonymized, hasLeadQuoteFee, desiredPartnerCompanySizeId, segmentedForLeadMarketplacePartnerId, personalTitleId, isLimitedAccessMarketplaceLead, isBasicAccessMarketplaceLead, typeId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {LeadNoteResource} leadNoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsNotesCreate(leadId: string, leadNoteResource: LeadNoteResource, options?: any) {
            return LeadsApiFp(configuration).leadsNotesCreate(leadId, leadNoteResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {LeadQuoteFeeResource} leadQuoteFeeResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotefeesCreate(leadId: string, leadQuoteFeeResource: LeadQuoteFeeResource, options?: any) {
            return LeadsApiFp(configuration).leadsQuotefeesCreate(leadId, leadQuoteFeeResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotefeesDelete(leadId: string, options?: any) {
            return LeadsApiFp(configuration).leadsQuotefeesDelete(leadId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {LeadQuoteActionResource} leadQuoteActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesActionsCreate(leadId: string, leadQuoteId: string, leadQuoteActionResource: LeadQuoteActionResource, options?: any) {
            return LeadsApiFp(configuration).leadsQuotesActionsCreate(leadId, leadQuoteId, leadQuoteActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {LeadQuoteResource} leadQuoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesCreate(leadId: string, leadQuoteResource: LeadQuoteResource, options?: any) {
            return LeadsApiFp(configuration).leadsQuotesCreate(leadId, leadQuoteResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesDelete(leadId: string, leadQuoteId: string, options?: any) {
            return LeadsApiFp(configuration).leadsQuotesDelete(leadId, leadQuoteId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadQuoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesGet(leadQuoteId: string, options?: any) {
            return LeadsApiFp(configuration).leadsQuotesGet(leadQuoteId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesGet_2(leadId: string, leadQuoteId: string, options?: any) {
            return LeadsApiFp(configuration).leadsQuotesGet_2(leadId, leadQuoteId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [clientId] List of quotes by client_id
         * @param {string} [leadId] List of leads identification(ID).
         * @param {string} [leadQuoteId] List of lead quotes identification(ID).
         * @param {string} [partnerId] Offers belonging to a given partner.
         * @param {string} [geoCode] Geographic Location Code.
         * @param {boolean} [isAccepted] Quote is accepted.
         * @param {boolean} [isPublished] Quote is published.
         * @param {boolean} [isRejected] Quote is rejected.
         * @param {'created_at' | 'accepted_at' | 'published_at' | 'rejected_at' | 'total_price_excl_vat' | 'ascending' | 'descending'} [sort] Sort options.
         * @param {string} [leadCategoryType] Filter by lead category type.
         * @param {boolean} [reservationIsExpired] Quote&#39;s reservation is expired.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesIndex(clientId?: string, leadId?: string, leadQuoteId?: string, partnerId?: string, geoCode?: string, isAccepted?: boolean, isPublished?: boolean, isRejected?: boolean, sort?: 'created_at' | 'accepted_at' | 'published_at' | 'rejected_at' | 'total_price_excl_vat' | 'ascending' | 'descending', leadCategoryType?: string, reservationIsExpired?: boolean, limit?: number, page?: number, query?: string, options?: any) {
            return LeadsApiFp(configuration).leadsQuotesIndex(clientId, leadId, leadQuoteId, partnerId, geoCode, isAccepted, isPublished, isRejected, sort, leadCategoryType, reservationIsExpired, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {string} [clientId] List of quotes by client_id
         * @param {string} [leadQuoteId] List of lead quotes identification(ID).
         * @param {string} [partnerId] Offers belonging to a given partner.
         * @param {string} [geoCode] Geographic Location Code.
         * @param {boolean} [isAccepted] Quote is accepted.
         * @param {boolean} [isPublished] Quote is published.
         * @param {boolean} [isRejected] Quote is rejected.
         * @param {'created_at' | 'accepted_at' | 'published_at' | 'rejected_at' | 'total_price_excl_vat' | 'ascending' | 'descending'} [sort] Sort options.
         * @param {string} [leadCategoryType] Filter by lead category type.
         * @param {boolean} [reservationIsExpired] Quote&#39;s reservation is expired.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesIndex_3(leadId: string, clientId?: string, leadQuoteId?: string, partnerId?: string, geoCode?: string, isAccepted?: boolean, isPublished?: boolean, isRejected?: boolean, sort?: 'created_at' | 'accepted_at' | 'published_at' | 'rejected_at' | 'total_price_excl_vat' | 'ascending' | 'descending', leadCategoryType?: string, reservationIsExpired?: boolean, limit?: number, page?: number, query?: string, options?: any) {
            return LeadsApiFp(configuration).leadsQuotesIndex_3(leadId, clientId, leadQuoteId, partnerId, geoCode, isAccepted, isPublished, isRejected, sort, leadCategoryType, reservationIsExpired, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {LeadQuoteItemResource} leadQuoteItemResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesItemsCreate(leadId: string, leadQuoteId: string, leadQuoteItemResource: LeadQuoteItemResource, options?: any) {
            return LeadsApiFp(configuration).leadsQuotesItemsCreate(leadId, leadQuoteId, leadQuoteItemResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {string} leadQuoteItemId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesItemsDelete(leadId: string, leadQuoteId: string, leadQuoteItemId: string, options?: any) {
            return LeadsApiFp(configuration).leadsQuotesItemsDelete(leadId, leadQuoteId, leadQuoteItemId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {string} leadQuoteItemId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesItemsGet(leadId: string, leadQuoteId: string, leadQuoteItemId: string, options?: any) {
            return LeadsApiFp(configuration).leadsQuotesItemsGet(leadId, leadQuoteId, leadQuoteItemId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesItemsIndex(leadId: string, leadQuoteId: string, limit?: number, page?: number, query?: string, options?: any) {
            return LeadsApiFp(configuration).leadsQuotesItemsIndex(leadId, leadQuoteId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {string} leadQuoteItemId
         * @param {LeadQuoteItemResource} leadQuoteItemResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesItemsUpdate(leadId: string, leadQuoteId: string, leadQuoteItemId: string, leadQuoteItemResource: LeadQuoteItemResource, options?: any) {
            return LeadsApiFp(configuration).leadsQuotesItemsUpdate(leadId, leadQuoteId, leadQuoteItemId, leadQuoteItemResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [title]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesProgressstepsIndex(title?: string, limit?: number, page?: number, query?: string, options?: any) {
            return LeadsApiFp(configuration).leadsQuotesProgressstepsIndex(title, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadQuoteId
         * @param {LeadQuoteResource} leadQuoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesUpdate(leadQuoteId: string, leadQuoteResource: LeadQuoteResource, options?: any) {
            return LeadsApiFp(configuration).leadsQuotesUpdate(leadQuoteId, leadQuoteResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {string} leadQuoteId
         * @param {LeadQuoteResource} leadQuoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsQuotesUpdate_4(leadId: string, leadQuoteId: string, leadQuoteResource: LeadQuoteResource, options?: any) {
            return LeadsApiFp(configuration).leadsQuotesUpdate_4(leadId, leadQuoteId, leadQuoteResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsSegmentationsubgeoregionsIndex(leadId: string, limit?: number, page?: number, query?: string, options?: any) {
            return LeadsApiFp(configuration).leadsSegmentationsubgeoregionsIndex(leadId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {SegmentationSubGeoRegionsResource} segmentationSubGeoRegionsResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsSegmentationsubgeoregionsUpdate(leadId: string, segmentationSubGeoRegionsResource: SegmentationSubGeoRegionsResource, options?: any) {
            return LeadsApiFp(configuration).leadsSegmentationsubgeoregionsUpdate(leadId, segmentationSubGeoRegionsResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsTypegroupsIndex(geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
            return LeadsApiFp(configuration).leadsTypegroupsIndex(geoCode, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [typeId] Type ID. @var int
         * @param {string} [title] Type title in its native language. @var string
         * @param {string} [geoCode] Industry geo code origin. @var string
         * @param {number} [limit] Limit of industries per page. @var int
         * @param {boolean} [isArchived]
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsTypesIndex(typeId?: string, title?: string, geoCode?: string, limit?: number, isArchived?: boolean, page?: number, query?: string, options?: any) {
            return LeadsApiFp(configuration).leadsTypesIndex(typeId, title, geoCode, limit, isArchived, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadId
         * @param {LeadResource} leadResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsUpdate(leadId: string, leadResource: LeadResource, options?: any) {
            return LeadsApiFp(configuration).leadsUpdate(leadId, leadResource, options)(fetch, basePath);
        },
    };
};

/**
 * LeadsApi - object-oriented interface
 * @export
 * @class LeadsApi
 * @extends {BaseAPI}
 */
export class LeadsApi extends BaseAPI {
    /**
     * This function serves up one class with a lot of functionality
     * @summary This function serves up one class with a lot of functionality
     * @param {string} leadId
     * @param {LeadActionResource} leadActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsActionsCreate(leadId: string, leadActionResource: LeadActionResource, options?: any) {
        return LeadsApiFp(this.configuration).leadsActionsCreate(leadId, leadActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [leadId] List of leads identification(ID).
     * @param {string} [phone] Filter by a given phone number.
     * @param {number} [partnerId] Provide a given partner id to filter by. Requires \&quot;filter\&quot; to be \&quot;bought_by_partner\&quot; or \&quot;segmented_for_partner\&quot;.
     * @param {'' | 'dk' | 'no' | 'de' | 'se' | 'uk' | 'nl' | 'us' | 'com' | 'ascending' | 'descending'} [geoCode] Geographic Location Code.
     * @param {'created_at' | 'validated_at' | 'expires_at' | 'status' | 'quote_published_at' | 'distance' | 'completed_call_priority_date' | 'client_decision_postponed_to' | 'service_fee' | 'minimum_quote_amount_excl_vat' | 'last_action_at' | 'last_contact_at' | 'call_count_before_validation' | 'lead_quote_fee' | 'ascending' | 'descending'} [sort] Sort Leads by a given attribute.
     * @param {string} [status] Status for the given lead.
     * @param {number} [clientId] Lead&#39;s client
     * @param {string} [employeeId] Lead&#39;s client&#39;s assigned employee ID
     * @param {boolean} [clientDecisionPostponed] Client decision to choose a quote match is postponed or not.
     * @param {string} [allQuotesPublishedBefore] Lead received its latest quote match before this date.
     * @param {number} [expiresWithin] Get leads expiring within this many minutes.
     * @param {string} [industry] Industry code for the given lead.
     * @param {boolean} [isQuotesLead] Get leads that are for quotes. @var bool
     * @param {boolean} [hasMaxQuotes] Has maximum number of quotes. @var bool
     * @param {boolean} [hasAcceptedQuote] Has an accepted quote. @var bool
     * @param {boolean} [hasRejectedQuote] Has a rejected quote. @var bool
     * @param {number} [noQuoteForPartnerId] Get leads that do not have a quote for this partner ID. @var int
     * @param {boolean} [isMatchPriority] Is the lead prioritised for being matched? @var bool
     * @param {boolean} [isCompletedCallPriority] Is the lead prioritised for a completion call? @var bool
     * @param {string} [geoPoint] Location Point to sort leads by
     * @param {string} [geoRect] Location Points to define an area to filter leads by. Provide the bottom left (southwest) and top right (northeast) corners of the reactangle separated by commas, e.g. \&quot;52.19,12.42,52.85,14.22\&quot;.
     * @param {number} [geoDistance] Location filter by distance
     * @param {string} [minimumRevenueAmountExclVat] Get leads that will create at least this amount (in euros) of revenue if matched.
     * @param {string} [maximumRevenueAmountExclVat] Get leads that will create no more than this amount (in euros) of revenue if matched.
     * @param {'basic' | 'exclusive'} [leadCategory] Lead&#39;s category
     * @param {string} [validatorId] Lead&#39;s validator
     * @param {boolean} [isGivenUp] Is the lead given up on?
     * @param {string} [industryId] Filter the leads by their industry ids
     * @param {number} [callCountBeforeValidationLt] Lead must have less than this many calls before validation occurred.
     * @param {boolean} [isValidationPostponed] Is the lead&#39;s validation postponed?
     * @param {string} [createdAtLte] Get leads created before this date (inclusive).
     * @param {string} [createdAtGte] Get leads created after this date (inclusive).
     * @param {string} [validatedAtGte] Get leads validated after this date (inclusive).
     * @param {string} [latestCalledAtBefore] Find leads with a latest call before this date.
     * @param {string} [segmentationCriteria] Which criteria to segment on, e.g. language.
     * @param {number} [segmentedForPartnerBusinessUnitId] List leads that are segmented for a business unit.
     * @param {string} [subGeoRegionName] List leads in any of these sub geo regions. E.g. &#39;Aarhus&#39;, &#39;Copenhagen&#39;.
     * @param {boolean} [isAnonymized] Have the leads been anonymized.
     * @param {boolean} [hasLeadQuoteFee] Filter by has lead quote fee.
     * @param {string} [desiredPartnerCompanySizeId] Filter by desired partner company size.
     * @param {number} [segmentedForLeadMarketplacePartnerId] Find leads for the partner&#39;s lead marketplace.
     * @param {string} [personalTitleId] Filter by lead&#39;s personal title
     * @param {boolean} [isLimitedAccessMarketplaceLead] List marketplace leads that have been validated within limited access time window
     * @param {boolean} [isBasicAccessMarketplaceLead] List marketplace leads that have been created within basic access time window
     * @param {string} [typeId] List leads by the client type id
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsAggregationsIndex(leadId?: string, phone?: string, partnerId?: number, geoCode?: '' | 'dk' | 'no' | 'de' | 'se' | 'uk' | 'nl' | 'us' | 'com' | 'ascending' | 'descending', sort?: 'created_at' | 'validated_at' | 'expires_at' | 'status' | 'quote_published_at' | 'distance' | 'completed_call_priority_date' | 'client_decision_postponed_to' | 'service_fee' | 'minimum_quote_amount_excl_vat' | 'last_action_at' | 'last_contact_at' | 'call_count_before_validation' | 'lead_quote_fee' | 'ascending' | 'descending', status?: string, clientId?: number, employeeId?: string, clientDecisionPostponed?: boolean, allQuotesPublishedBefore?: string, expiresWithin?: number, industry?: string, isQuotesLead?: boolean, hasMaxQuotes?: boolean, hasAcceptedQuote?: boolean, hasRejectedQuote?: boolean, noQuoteForPartnerId?: number, isMatchPriority?: boolean, isCompletedCallPriority?: boolean, geoPoint?: string, geoRect?: string, geoDistance?: number, minimumRevenueAmountExclVat?: string, maximumRevenueAmountExclVat?: string, leadCategory?: 'basic' | 'exclusive', validatorId?: string, isGivenUp?: boolean, industryId?: string, callCountBeforeValidationLt?: number, isValidationPostponed?: boolean, createdAtLte?: string, createdAtGte?: string, validatedAtGte?: string, latestCalledAtBefore?: string, segmentationCriteria?: string, segmentedForPartnerBusinessUnitId?: number, subGeoRegionName?: string, isAnonymized?: boolean, hasLeadQuoteFee?: boolean, desiredPartnerCompanySizeId?: string, segmentedForLeadMarketplacePartnerId?: number, personalTitleId?: string, isLimitedAccessMarketplaceLead?: boolean, isBasicAccessMarketplaceLead?: boolean, typeId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsAggregationsIndex(leadId, phone, partnerId, geoCode, sort, status, clientId, employeeId, clientDecisionPostponed, allQuotesPublishedBefore, expiresWithin, industry, isQuotesLead, hasMaxQuotes, hasAcceptedQuote, hasRejectedQuote, noQuoteForPartnerId, isMatchPriority, isCompletedCallPriority, geoPoint, geoRect, geoDistance, minimumRevenueAmountExclVat, maximumRevenueAmountExclVat, leadCategory, validatorId, isGivenUp, industryId, callCountBeforeValidationLt, isValidationPostponed, createdAtLte, createdAtGte, validatedAtGte, latestCalledAtBefore, segmentationCriteria, segmentedForPartnerBusinessUnitId, subGeoRegionName, isAnonymized, hasLeadQuoteFee, desiredPartnerCompanySizeId, segmentedForLeadMarketplacePartnerId, personalTitleId, isLimitedAccessMarketplaceLead, isBasicAccessMarketplaceLead, typeId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {LeadAllocationsResource} leadAllocationsResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsAllocationsCreate(leadId: string, leadAllocationsResource: LeadAllocationsResource, options?: any) {
        return LeadsApiFp(this.configuration).leadsAllocationsCreate(leadId, leadAllocationsResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} [leadId] Lead ID.
     * @param {number} [partnerId] Partner ID.
     * @param {string} [partnerBusinessUnitId] Business Unit ID.
     * @param {string} [startsAt]
     * @param {string} [endsAt]
     * @param {boolean} [hasBusinessUnit]
     * @param {'created_at' | 'quote_accepted_at' | 'quote_published_at' | 'partner_id' | 'lead_id' | 'partner_business_unit_id' | 'ascending' | 'descending'} [sort]
     * @param {boolean} [isUsed] Filter by the allocation being used or unused.
     * @param {boolean} [isProcessed] Filter by the allocation being used or unused.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsAllocationsIndex(leadId?: number, partnerId?: number, partnerBusinessUnitId?: string, startsAt?: string, endsAt?: string, hasBusinessUnit?: boolean, sort?: 'created_at' | 'quote_accepted_at' | 'quote_published_at' | 'partner_id' | 'lead_id' | 'partner_business_unit_id' | 'ascending' | 'descending', isUsed?: boolean, isProcessed?: boolean, limit?: number, page?: number, query?: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsAllocationsIndex(leadId, partnerId, partnerBusinessUnitId, startsAt, endsAt, hasBusinessUnit, sort, isUsed, isProcessed, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {string} allocationSuggestionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsAllocationsuggestionsDelete(leadId: string, allocationSuggestionId: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsAllocationsuggestionsDelete(leadId, allocationSuggestionId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [id] Category id @var int
     * @param {string} [identifier] Category identifier @var string
     * @param {string} [title] Category title. @var string
     * @param {number} [limit] Limit of industries per page. @var int
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsCategoriesIndex(id?: string, identifier?: string, title?: string, limit?: number, page?: number, query?: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsCategoriesIndex(id, identifier, title, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {CertificationResource} certificationResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsCertificationsCreate(leadId: string, certificationResource: CertificationResource, options?: any) {
        return LeadsApiFp(this.configuration).leadsCertificationsCreate(leadId, certificationResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {string} certificationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsCertificationsDelete(leadId: string, certificationId: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsCertificationsDelete(leadId, certificationId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {boolean} [isSegmentable] Is the certification used for segmentation?
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsCertificationsIndex(leadId: string, isSegmentable?: boolean, limit?: number, page?: number, query?: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsCertificationsIndex(leadId, isSegmentable, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {LeadCertificationsResource} leadCertificationsResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsCertificationsUpdate(leadId: string, leadCertificationsResource: LeadCertificationsResource, options?: any) {
        return LeadsApiFp(this.configuration).leadsCertificationsUpdate(leadId, leadCertificationsResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsCompanysizesIndex(leadId: string, limit?: number, page?: number, query?: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsCompanysizesIndex(leadId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {LeadCompanySizesResource} leadCompanySizesResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsCompanysizesUpdate(leadId: string, leadCompanySizesResource: LeadCompanySizesResource, options?: any) {
        return LeadsApiFp(this.configuration).leadsCompanysizesUpdate(leadId, leadCompanySizesResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {LeadResource} leadResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsCreate(leadResource: LeadResource, options?: any) {
        return LeadsApiFp(this.configuration).leadsCreate(leadResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsDelete(leadId: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsDelete(leadId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsDemopartnersIndex(partnerId: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsDemopartnersIndex(partnerId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {string} partnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsDemopartnersIndex_1(leadId: string, partnerId: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsDemopartnersIndex_1(leadId, partnerId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {LeadBulkActionDiscardResource} leadBulkActionDiscardResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsDiscardsCreate(leadBulkActionDiscardResource: LeadBulkActionDiscardResource, options?: any) {
        return LeadsApiFp(this.configuration).leadsDiscardsCreate(leadBulkActionDiscardResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {PartnerSuggestResource} partnerSuggestResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsExcludedpartnersCreate(leadId: string, partnerSuggestResource: PartnerSuggestResource, options?: any) {
        return LeadsApiFp(this.configuration).leadsExcludedpartnersCreate(leadId, partnerSuggestResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {string} partnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsExcludedpartnersDelete(leadId: string, partnerId: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsExcludedpartnersDelete(leadId, partnerId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsExcludedpartnersIndex(leadId: string, limit?: number, page?: number, query?: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsExcludedpartnersIndex(leadId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsGet(leadId: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsGet(leadId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [leadId] List of leads identification(ID).
     * @param {string} [phone] Filter by a given phone number.
     * @param {number} [partnerId] Provide a given partner id to filter by. Requires \&quot;filter\&quot; to be \&quot;bought_by_partner\&quot; or \&quot;segmented_for_partner\&quot;.
     * @param {'' | 'dk' | 'no' | 'de' | 'se' | 'uk' | 'nl' | 'us' | 'com' | 'ascending' | 'descending'} [geoCode] Geographic Location Code.
     * @param {'created_at' | 'validated_at' | 'expires_at' | 'status' | 'quote_published_at' | 'distance' | 'completed_call_priority_date' | 'client_decision_postponed_to' | 'service_fee' | 'minimum_quote_amount_excl_vat' | 'last_action_at' | 'last_contact_at' | 'call_count_before_validation' | 'lead_quote_fee' | 'ascending' | 'descending'} [sort] Sort Leads by a given attribute.
     * @param {string} [status] Status for the given lead.
     * @param {number} [clientId] Lead&#39;s client
     * @param {string} [employeeId] Lead&#39;s client&#39;s assigned employee ID
     * @param {boolean} [clientDecisionPostponed] Client decision to choose a quote match is postponed or not.
     * @param {string} [allQuotesPublishedBefore] Lead received its latest quote match before this date.
     * @param {number} [expiresWithin] Get leads expiring within this many minutes.
     * @param {string} [industry] Industry code for the given lead.
     * @param {boolean} [isQuotesLead] Get leads that are for quotes. @var bool
     * @param {boolean} [hasMaxQuotes] Has maximum number of quotes. @var bool
     * @param {boolean} [hasAcceptedQuote] Has an accepted quote. @var bool
     * @param {boolean} [hasRejectedQuote] Has a rejected quote. @var bool
     * @param {number} [noQuoteForPartnerId] Get leads that do not have a quote for this partner ID. @var int
     * @param {boolean} [isMatchPriority] Is the lead prioritised for being matched? @var bool
     * @param {boolean} [isCompletedCallPriority] Is the lead prioritised for a completion call? @var bool
     * @param {string} [geoPoint] Location Point to sort leads by
     * @param {string} [geoRect] Location Points to define an area to filter leads by. Provide the bottom left (southwest) and top right (northeast) corners of the reactangle separated by commas, e.g. \&quot;52.19,12.42,52.85,14.22\&quot;.
     * @param {number} [geoDistance] Location filter by distance
     * @param {string} [minimumRevenueAmountExclVat] Get leads that will create at least this amount (in euros) of revenue if matched.
     * @param {string} [maximumRevenueAmountExclVat] Get leads that will create no more than this amount (in euros) of revenue if matched.
     * @param {'basic' | 'exclusive'} [leadCategory] Lead&#39;s category
     * @param {string} [validatorId] Lead&#39;s validator
     * @param {boolean} [isGivenUp] Is the lead given up on?
     * @param {string} [industryId] Filter the leads by their industry ids
     * @param {number} [callCountBeforeValidationLt] Lead must have less than this many calls before validation occurred.
     * @param {boolean} [isValidationPostponed] Is the lead&#39;s validation postponed?
     * @param {string} [createdAtLte] Get leads created before this date (inclusive).
     * @param {string} [createdAtGte] Get leads created after this date (inclusive).
     * @param {string} [validatedAtGte] Get leads validated after this date (inclusive).
     * @param {string} [latestCalledAtBefore] Find leads with a latest call before this date.
     * @param {string} [segmentationCriteria] Which criteria to segment on, e.g. language.
     * @param {number} [segmentedForPartnerBusinessUnitId] List leads that are segmented for a business unit.
     * @param {string} [subGeoRegionName] List leads in any of these sub geo regions. E.g. &#39;Aarhus&#39;, &#39;Copenhagen&#39;.
     * @param {boolean} [isAnonymized] Have the leads been anonymized.
     * @param {boolean} [hasLeadQuoteFee] Filter by has lead quote fee.
     * @param {string} [desiredPartnerCompanySizeId] Filter by desired partner company size.
     * @param {number} [segmentedForLeadMarketplacePartnerId] Find leads for the partner&#39;s lead marketplace.
     * @param {string} [personalTitleId] Filter by lead&#39;s personal title
     * @param {boolean} [isLimitedAccessMarketplaceLead] List marketplace leads that have been validated within limited access time window
     * @param {boolean} [isBasicAccessMarketplaceLead] List marketplace leads that have been created within basic access time window
     * @param {string} [typeId] List leads by the client type id
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsIndex(leadId?: string, phone?: string, partnerId?: number, geoCode?: '' | 'dk' | 'no' | 'de' | 'se' | 'uk' | 'nl' | 'us' | 'com' | 'ascending' | 'descending', sort?: 'created_at' | 'validated_at' | 'expires_at' | 'status' | 'quote_published_at' | 'distance' | 'completed_call_priority_date' | 'client_decision_postponed_to' | 'service_fee' | 'minimum_quote_amount_excl_vat' | 'last_action_at' | 'last_contact_at' | 'call_count_before_validation' | 'lead_quote_fee' | 'ascending' | 'descending', status?: string, clientId?: number, employeeId?: string, clientDecisionPostponed?: boolean, allQuotesPublishedBefore?: string, expiresWithin?: number, industry?: string, isQuotesLead?: boolean, hasMaxQuotes?: boolean, hasAcceptedQuote?: boolean, hasRejectedQuote?: boolean, noQuoteForPartnerId?: number, isMatchPriority?: boolean, isCompletedCallPriority?: boolean, geoPoint?: string, geoRect?: string, geoDistance?: number, minimumRevenueAmountExclVat?: string, maximumRevenueAmountExclVat?: string, leadCategory?: 'basic' | 'exclusive', validatorId?: string, isGivenUp?: boolean, industryId?: string, callCountBeforeValidationLt?: number, isValidationPostponed?: boolean, createdAtLte?: string, createdAtGte?: string, validatedAtGte?: string, latestCalledAtBefore?: string, segmentationCriteria?: string, segmentedForPartnerBusinessUnitId?: number, subGeoRegionName?: string, isAnonymized?: boolean, hasLeadQuoteFee?: boolean, desiredPartnerCompanySizeId?: string, segmentedForLeadMarketplacePartnerId?: number, personalTitleId?: string, isLimitedAccessMarketplaceLead?: boolean, isBasicAccessMarketplaceLead?: boolean, typeId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsIndex(leadId, phone, partnerId, geoCode, sort, status, clientId, employeeId, clientDecisionPostponed, allQuotesPublishedBefore, expiresWithin, industry, isQuotesLead, hasMaxQuotes, hasAcceptedQuote, hasRejectedQuote, noQuoteForPartnerId, isMatchPriority, isCompletedCallPriority, geoPoint, geoRect, geoDistance, minimumRevenueAmountExclVat, maximumRevenueAmountExclVat, leadCategory, validatorId, isGivenUp, industryId, callCountBeforeValidationLt, isValidationPostponed, createdAtLte, createdAtGte, validatedAtGte, latestCalledAtBefore, segmentationCriteria, segmentedForPartnerBusinessUnitId, subGeoRegionName, isAnonymized, hasLeadQuoteFee, desiredPartnerCompanySizeId, segmentedForLeadMarketplacePartnerId, personalTitleId, isLimitedAccessMarketplaceLead, isBasicAccessMarketplaceLead, typeId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {LeadNoteResource} leadNoteResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsNotesCreate(leadId: string, leadNoteResource: LeadNoteResource, options?: any) {
        return LeadsApiFp(this.configuration).leadsNotesCreate(leadId, leadNoteResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {LeadQuoteFeeResource} leadQuoteFeeResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsQuotefeesCreate(leadId: string, leadQuoteFeeResource: LeadQuoteFeeResource, options?: any) {
        return LeadsApiFp(this.configuration).leadsQuotefeesCreate(leadId, leadQuoteFeeResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsQuotefeesDelete(leadId: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsQuotefeesDelete(leadId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {string} leadQuoteId
     * @param {LeadQuoteActionResource} leadQuoteActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsQuotesActionsCreate(leadId: string, leadQuoteId: string, leadQuoteActionResource: LeadQuoteActionResource, options?: any) {
        return LeadsApiFp(this.configuration).leadsQuotesActionsCreate(leadId, leadQuoteId, leadQuoteActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {LeadQuoteResource} leadQuoteResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsQuotesCreate(leadId: string, leadQuoteResource: LeadQuoteResource, options?: any) {
        return LeadsApiFp(this.configuration).leadsQuotesCreate(leadId, leadQuoteResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {string} leadQuoteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsQuotesDelete(leadId: string, leadQuoteId: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsQuotesDelete(leadId, leadQuoteId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadQuoteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsQuotesGet(leadQuoteId: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsQuotesGet(leadQuoteId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {string} leadQuoteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsQuotesGet_2(leadId: string, leadQuoteId: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsQuotesGet_2(leadId, leadQuoteId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [clientId] List of quotes by client_id
     * @param {string} [leadId] List of leads identification(ID).
     * @param {string} [leadQuoteId] List of lead quotes identification(ID).
     * @param {string} [partnerId] Offers belonging to a given partner.
     * @param {string} [geoCode] Geographic Location Code.
     * @param {boolean} [isAccepted] Quote is accepted.
     * @param {boolean} [isPublished] Quote is published.
     * @param {boolean} [isRejected] Quote is rejected.
     * @param {'created_at' | 'accepted_at' | 'published_at' | 'rejected_at' | 'total_price_excl_vat' | 'ascending' | 'descending'} [sort] Sort options.
     * @param {string} [leadCategoryType] Filter by lead category type.
     * @param {boolean} [reservationIsExpired] Quote&#39;s reservation is expired.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsQuotesIndex(clientId?: string, leadId?: string, leadQuoteId?: string, partnerId?: string, geoCode?: string, isAccepted?: boolean, isPublished?: boolean, isRejected?: boolean, sort?: 'created_at' | 'accepted_at' | 'published_at' | 'rejected_at' | 'total_price_excl_vat' | 'ascending' | 'descending', leadCategoryType?: string, reservationIsExpired?: boolean, limit?: number, page?: number, query?: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsQuotesIndex(clientId, leadId, leadQuoteId, partnerId, geoCode, isAccepted, isPublished, isRejected, sort, leadCategoryType, reservationIsExpired, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {string} [clientId] List of quotes by client_id
     * @param {string} [leadQuoteId] List of lead quotes identification(ID).
     * @param {string} [partnerId] Offers belonging to a given partner.
     * @param {string} [geoCode] Geographic Location Code.
     * @param {boolean} [isAccepted] Quote is accepted.
     * @param {boolean} [isPublished] Quote is published.
     * @param {boolean} [isRejected] Quote is rejected.
     * @param {'created_at' | 'accepted_at' | 'published_at' | 'rejected_at' | 'total_price_excl_vat' | 'ascending' | 'descending'} [sort] Sort options.
     * @param {string} [leadCategoryType] Filter by lead category type.
     * @param {boolean} [reservationIsExpired] Quote&#39;s reservation is expired.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsQuotesIndex_3(leadId: string, clientId?: string, leadQuoteId?: string, partnerId?: string, geoCode?: string, isAccepted?: boolean, isPublished?: boolean, isRejected?: boolean, sort?: 'created_at' | 'accepted_at' | 'published_at' | 'rejected_at' | 'total_price_excl_vat' | 'ascending' | 'descending', leadCategoryType?: string, reservationIsExpired?: boolean, limit?: number, page?: number, query?: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsQuotesIndex_3(leadId, clientId, leadQuoteId, partnerId, geoCode, isAccepted, isPublished, isRejected, sort, leadCategoryType, reservationIsExpired, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {string} leadQuoteId
     * @param {LeadQuoteItemResource} leadQuoteItemResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsQuotesItemsCreate(leadId: string, leadQuoteId: string, leadQuoteItemResource: LeadQuoteItemResource, options?: any) {
        return LeadsApiFp(this.configuration).leadsQuotesItemsCreate(leadId, leadQuoteId, leadQuoteItemResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {string} leadQuoteId
     * @param {string} leadQuoteItemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsQuotesItemsDelete(leadId: string, leadQuoteId: string, leadQuoteItemId: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsQuotesItemsDelete(leadId, leadQuoteId, leadQuoteItemId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {string} leadQuoteId
     * @param {string} leadQuoteItemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsQuotesItemsGet(leadId: string, leadQuoteId: string, leadQuoteItemId: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsQuotesItemsGet(leadId, leadQuoteId, leadQuoteItemId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {string} leadQuoteId
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsQuotesItemsIndex(leadId: string, leadQuoteId: string, limit?: number, page?: number, query?: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsQuotesItemsIndex(leadId, leadQuoteId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {string} leadQuoteId
     * @param {string} leadQuoteItemId
     * @param {LeadQuoteItemResource} leadQuoteItemResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsQuotesItemsUpdate(leadId: string, leadQuoteId: string, leadQuoteItemId: string, leadQuoteItemResource: LeadQuoteItemResource, options?: any) {
        return LeadsApiFp(this.configuration).leadsQuotesItemsUpdate(leadId, leadQuoteId, leadQuoteItemId, leadQuoteItemResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [title]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsQuotesProgressstepsIndex(title?: string, limit?: number, page?: number, query?: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsQuotesProgressstepsIndex(title, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadQuoteId
     * @param {LeadQuoteResource} leadQuoteResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsQuotesUpdate(leadQuoteId: string, leadQuoteResource: LeadQuoteResource, options?: any) {
        return LeadsApiFp(this.configuration).leadsQuotesUpdate(leadQuoteId, leadQuoteResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {string} leadQuoteId
     * @param {LeadQuoteResource} leadQuoteResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsQuotesUpdate_4(leadId: string, leadQuoteId: string, leadQuoteResource: LeadQuoteResource, options?: any) {
        return LeadsApiFp(this.configuration).leadsQuotesUpdate_4(leadId, leadQuoteId, leadQuoteResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsSegmentationsubgeoregionsIndex(leadId: string, limit?: number, page?: number, query?: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsSegmentationsubgeoregionsIndex(leadId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {SegmentationSubGeoRegionsResource} segmentationSubGeoRegionsResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsSegmentationsubgeoregionsUpdate(leadId: string, segmentationSubGeoRegionsResource: SegmentationSubGeoRegionsResource, options?: any) {
        return LeadsApiFp(this.configuration).leadsSegmentationsubgeoregionsUpdate(leadId, segmentationSubGeoRegionsResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [geoCode]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsTypegroupsIndex(geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsTypegroupsIndex(geoCode, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [typeId] Type ID. @var int
     * @param {string} [title] Type title in its native language. @var string
     * @param {string} [geoCode] Industry geo code origin. @var string
     * @param {number} [limit] Limit of industries per page. @var int
     * @param {boolean} [isArchived]
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsTypesIndex(typeId?: string, title?: string, geoCode?: string, limit?: number, isArchived?: boolean, page?: number, query?: string, options?: any) {
        return LeadsApiFp(this.configuration).leadsTypesIndex(typeId, title, geoCode, limit, isArchived, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadId
     * @param {LeadResource} leadResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public leadsUpdate(leadId: string, leadResource: LeadResource, options?: any) {
        return LeadsApiFp(this.configuration).leadsUpdate(leadId, leadResource, options)(this.fetch, this.basePath);
    }

}

/**
 * LocationsApi - fetch parameter creator
 * @export
 */
export const LocationsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsGet(locationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'locationId' is not null or undefined
            if (locationId === null || locationId === undefined) {
                throw new RequiredError('locationId','Required parameter locationId was null or undefined when calling locationsGet.');
            }
            const localVarPath = `/locations/{location_id}`
                .replace(`{${"location_id"}}`, encodeURIComponent(String(locationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [locationId]
         * @param {string} [geoCode] Geographic Location Code.
         * @param {string} [geoPoint] Geo Location Point
         * @param {string} [slug] Slug for url friendly strings.
         * @param {'distance' | 'name' | 'partners' | 'ascending' | 'descending'} [sort] Sort types for Locations
         * @param {boolean} [isPrimary] Only Primary Locations
         * @param {number} [minPartnerCount] Filtering based on minimum partner count
         * @param {string} [regionName] Filtering based on region name
         * @param {string} [regionAbbreviation] Filtering based on region abbreviation
         * @param {string} [zipCode] Filtering based on zip code
         * @param {string} [query] Filtering based several parameters, like region, zip code
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsIndex(locationId?: string, geoCode?: string, geoPoint?: string, slug?: string, sort?: 'distance' | 'name' | 'partners' | 'ascending' | 'descending', isPrimary?: boolean, minPartnerCount?: number, regionName?: string, regionAbbreviation?: string, zipCode?: string, query?: string, limit?: number, page?: number, options: any = {}): FetchArgs {
            const localVarPath = `/locations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (locationId !== undefined) {
                localVarQueryParameter['location_id'] = locationId;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (geoPoint !== undefined) {
                localVarQueryParameter['geo_point'] = geoPoint;
            }

            if (slug !== undefined) {
                localVarQueryParameter['slug'] = slug;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (isPrimary !== undefined) {
                localVarQueryParameter['is_primary'] = isPrimary;
            }

            if (minPartnerCount !== undefined) {
                localVarQueryParameter['min_partner_count'] = minPartnerCount;
            }

            if (regionName !== undefined) {
                localVarQueryParameter['region_name'] = regionName;
            }

            if (regionAbbreviation !== undefined) {
                localVarQueryParameter['region_abbreviation'] = regionAbbreviation;
            }

            if (zipCode !== undefined) {
                localVarQueryParameter['zip_code'] = zipCode;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsSegmentationgeoregionsIndex(geoCode?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/locations/segmentationgeoregions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [segmentationGeoRegionId] Filter by segmentation geo region ID.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsSegmentationgeoregionsSegmentationsubgeoregionsIndex(segmentationGeoRegionId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/locations/segmentationgeoregions/segmentationsubgeoregions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (segmentationGeoRegionId !== undefined) {
                localVarQueryParameter['segmentation_geo_region_id'] = segmentationGeoRegionId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationsApi - functional programming interface
 * @export
 */
export const LocationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsGet(locationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LocationResource> {
            const localVarFetchArgs = LocationsApiFetchParamCreator(configuration).locationsGet(locationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [locationId]
         * @param {string} [geoCode] Geographic Location Code.
         * @param {string} [geoPoint] Geo Location Point
         * @param {string} [slug] Slug for url friendly strings.
         * @param {'distance' | 'name' | 'partners' | 'ascending' | 'descending'} [sort] Sort types for Locations
         * @param {boolean} [isPrimary] Only Primary Locations
         * @param {number} [minPartnerCount] Filtering based on minimum partner count
         * @param {string} [regionName] Filtering based on region name
         * @param {string} [regionAbbreviation] Filtering based on region abbreviation
         * @param {string} [zipCode] Filtering based on zip code
         * @param {string} [query] Filtering based several parameters, like region, zip code
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsIndex(locationId?: string, geoCode?: string, geoPoint?: string, slug?: string, sort?: 'distance' | 'name' | 'partners' | 'ascending' | 'descending', isPrimary?: boolean, minPartnerCount?: number, regionName?: string, regionAbbreviation?: string, zipCode?: string, query?: string, limit?: number, page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LocationResult> {
            const localVarFetchArgs = LocationsApiFetchParamCreator(configuration).locationsIndex(locationId, geoCode, geoPoint, slug, sort, isPrimary, minPartnerCount, regionName, regionAbbreviation, zipCode, query, limit, page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsSegmentationgeoregionsIndex(geoCode?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SegmentationGeoRegionResult> {
            const localVarFetchArgs = LocationsApiFetchParamCreator(configuration).locationsSegmentationgeoregionsIndex(geoCode, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [segmentationGeoRegionId] Filter by segmentation geo region ID.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsSegmentationgeoregionsSegmentationsubgeoregionsIndex(segmentationGeoRegionId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SegmentationSubGeoRegionResult> {
            const localVarFetchArgs = LocationsApiFetchParamCreator(configuration).locationsSegmentationgeoregionsSegmentationsubgeoregionsIndex(segmentationGeoRegionId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * LocationsApi - factory interface
 * @export
 */
export const LocationsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsGet(locationId: string, options?: any) {
            return LocationsApiFp(configuration).locationsGet(locationId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [locationId]
         * @param {string} [geoCode] Geographic Location Code.
         * @param {string} [geoPoint] Geo Location Point
         * @param {string} [slug] Slug for url friendly strings.
         * @param {'distance' | 'name' | 'partners' | 'ascending' | 'descending'} [sort] Sort types for Locations
         * @param {boolean} [isPrimary] Only Primary Locations
         * @param {number} [minPartnerCount] Filtering based on minimum partner count
         * @param {string} [regionName] Filtering based on region name
         * @param {string} [regionAbbreviation] Filtering based on region abbreviation
         * @param {string} [zipCode] Filtering based on zip code
         * @param {string} [query] Filtering based several parameters, like region, zip code
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsIndex(locationId?: string, geoCode?: string, geoPoint?: string, slug?: string, sort?: 'distance' | 'name' | 'partners' | 'ascending' | 'descending', isPrimary?: boolean, minPartnerCount?: number, regionName?: string, regionAbbreviation?: string, zipCode?: string, query?: string, limit?: number, page?: number, options?: any) {
            return LocationsApiFp(configuration).locationsIndex(locationId, geoCode, geoPoint, slug, sort, isPrimary, minPartnerCount, regionName, regionAbbreviation, zipCode, query, limit, page, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsSegmentationgeoregionsIndex(geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
            return LocationsApiFp(configuration).locationsSegmentationgeoregionsIndex(geoCode, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [segmentationGeoRegionId] Filter by segmentation geo region ID.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsSegmentationgeoregionsSegmentationsubgeoregionsIndex(segmentationGeoRegionId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return LocationsApiFp(configuration).locationsSegmentationgeoregionsSegmentationsubgeoregionsIndex(segmentationGeoRegionId, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * LocationsApi - object-oriented interface
 * @export
 * @class LocationsApi
 * @extends {BaseAPI}
 */
export class LocationsApi extends BaseAPI {
    /**
     *
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public locationsGet(locationId: string, options?: any) {
        return LocationsApiFp(this.configuration).locationsGet(locationId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [locationId]
     * @param {string} [geoCode] Geographic Location Code.
     * @param {string} [geoPoint] Geo Location Point
     * @param {string} [slug] Slug for url friendly strings.
     * @param {'distance' | 'name' | 'partners' | 'ascending' | 'descending'} [sort] Sort types for Locations
     * @param {boolean} [isPrimary] Only Primary Locations
     * @param {number} [minPartnerCount] Filtering based on minimum partner count
     * @param {string} [regionName] Filtering based on region name
     * @param {string} [regionAbbreviation] Filtering based on region abbreviation
     * @param {string} [zipCode] Filtering based on zip code
     * @param {string} [query] Filtering based several parameters, like region, zip code
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public locationsIndex(locationId?: string, geoCode?: string, geoPoint?: string, slug?: string, sort?: 'distance' | 'name' | 'partners' | 'ascending' | 'descending', isPrimary?: boolean, minPartnerCount?: number, regionName?: string, regionAbbreviation?: string, zipCode?: string, query?: string, limit?: number, page?: number, options?: any) {
        return LocationsApiFp(this.configuration).locationsIndex(locationId, geoCode, geoPoint, slug, sort, isPrimary, minPartnerCount, regionName, regionAbbreviation, zipCode, query, limit, page, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [geoCode]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public locationsSegmentationgeoregionsIndex(geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
        return LocationsApiFp(this.configuration).locationsSegmentationgeoregionsIndex(geoCode, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [segmentationGeoRegionId] Filter by segmentation geo region ID.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public locationsSegmentationgeoregionsSegmentationsubgeoregionsIndex(segmentationGeoRegionId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return LocationsApiFp(this.configuration).locationsSegmentationgeoregionsSegmentationsubgeoregionsIndex(segmentationGeoRegionId, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * MatchesApi - fetch parameter creator
 * @export
 */
export const MatchesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [leadId] Identifiers for the lead&#39;s
         * @param {string} [geoCode] Geographic Location Code.
         * @param {string} [partnerId] Partner Id
         * @param {string} [validatorId] Validator Id
         * @param {boolean} [isAccepted] Accepted
         * @param {boolean} [isUnaccepted] Unaccepted
         * @param {'accepted_at' | 'unaccepted_at' | 'ascending' | 'descending'} [sort] Sort resource by.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matchesIndex(leadId?: string, geoCode?: string, partnerId?: string, validatorId?: string, isAccepted?: boolean, isUnaccepted?: boolean, sort?: 'accepted_at' | 'unaccepted_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/matches`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (leadId !== undefined) {
                localVarQueryParameter['lead_id'] = leadId;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (validatorId !== undefined) {
                localVarQueryParameter['validator_id'] = validatorId;
            }

            if (isAccepted !== undefined) {
                localVarQueryParameter['is_accepted'] = isAccepted;
            }

            if (isUnaccepted !== undefined) {
                localVarQueryParameter['is_unaccepted'] = isUnaccepted;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MatchesApi - functional programming interface
 * @export
 */
export const MatchesApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [leadId] Identifiers for the lead&#39;s
         * @param {string} [geoCode] Geographic Location Code.
         * @param {string} [partnerId] Partner Id
         * @param {string} [validatorId] Validator Id
         * @param {boolean} [isAccepted] Accepted
         * @param {boolean} [isUnaccepted] Unaccepted
         * @param {'accepted_at' | 'unaccepted_at' | 'ascending' | 'descending'} [sort] Sort resource by.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matchesIndex(leadId?: string, geoCode?: string, partnerId?: string, validatorId?: string, isAccepted?: boolean, isUnaccepted?: boolean, sort?: 'accepted_at' | 'unaccepted_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MatchResult> {
            const localVarFetchArgs = MatchesApiFetchParamCreator(configuration).matchesIndex(leadId, geoCode, partnerId, validatorId, isAccepted, isUnaccepted, sort, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * MatchesApi - factory interface
 * @export
 */
export const MatchesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} [leadId] Identifiers for the lead&#39;s
         * @param {string} [geoCode] Geographic Location Code.
         * @param {string} [partnerId] Partner Id
         * @param {string} [validatorId] Validator Id
         * @param {boolean} [isAccepted] Accepted
         * @param {boolean} [isUnaccepted] Unaccepted
         * @param {'accepted_at' | 'unaccepted_at' | 'ascending' | 'descending'} [sort] Sort resource by.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matchesIndex(leadId?: string, geoCode?: string, partnerId?: string, validatorId?: string, isAccepted?: boolean, isUnaccepted?: boolean, sort?: 'accepted_at' | 'unaccepted_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
            return MatchesApiFp(configuration).matchesIndex(leadId, geoCode, partnerId, validatorId, isAccepted, isUnaccepted, sort, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * MatchesApi - object-oriented interface
 * @export
 * @class MatchesApi
 * @extends {BaseAPI}
 */
export class MatchesApi extends BaseAPI {
    /**
     *
     * @param {string} [leadId] Identifiers for the lead&#39;s
     * @param {string} [geoCode] Geographic Location Code.
     * @param {string} [partnerId] Partner Id
     * @param {string} [validatorId] Validator Id
     * @param {boolean} [isAccepted] Accepted
     * @param {boolean} [isUnaccepted] Unaccepted
     * @param {'accepted_at' | 'unaccepted_at' | 'ascending' | 'descending'} [sort] Sort resource by.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchesApi
     */
    public matchesIndex(leadId?: string, geoCode?: string, partnerId?: string, validatorId?: string, isAccepted?: boolean, isUnaccepted?: boolean, sort?: 'accepted_at' | 'unaccepted_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
        return MatchesApiFp(this.configuration).matchesIndex(leadId, geoCode, partnerId, validatorId, isAccepted, isUnaccepted, sort, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * MeetingsApi - fetch parameter creator
 * @export
 */
export const MeetingsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {MeetingActionResource} meetingActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meetingsActionsCreate(meetingActionResource: MeetingActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'meetingActionResource' is not null or undefined
            if (meetingActionResource === null || meetingActionResource === undefined) {
                throw new RequiredError('meetingActionResource','Required parameter meetingActionResource was null or undefined when calling meetingsActionsCreate.');
            }
            const localVarPath = `/meetings/actions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MeetingActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(meetingActionResource || {}) : (meetingActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [leadId]
         * @param {number} [partnerId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meetingsIndex(leadId?: number, partnerId?: number, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/meetings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (leadId !== undefined) {
                localVarQueryParameter['lead_id'] = leadId;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeetingsApi - functional programming interface
 * @export
 */
export const MeetingsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {MeetingActionResource} meetingActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meetingsActionsCreate(meetingActionResource: MeetingActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadPartnerMeetingRequestResource> {
            const localVarFetchArgs = MeetingsApiFetchParamCreator(configuration).meetingsActionsCreate(meetingActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} [leadId]
         * @param {number} [partnerId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meetingsIndex(leadId?: number, partnerId?: number, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadPartnerMeetingRequestResult> {
            const localVarFetchArgs = MeetingsApiFetchParamCreator(configuration).meetingsIndex(leadId, partnerId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * MeetingsApi - factory interface
 * @export
 */
export const MeetingsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {MeetingActionResource} meetingActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meetingsActionsCreate(meetingActionResource: MeetingActionResource, options?: any) {
            return MeetingsApiFp(configuration).meetingsActionsCreate(meetingActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} [leadId]
         * @param {number} [partnerId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meetingsIndex(leadId?: number, partnerId?: number, limit?: number, page?: number, query?: string, options?: any) {
            return MeetingsApiFp(configuration).meetingsIndex(leadId, partnerId, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * MeetingsApi - object-oriented interface
 * @export
 * @class MeetingsApi
 * @extends {BaseAPI}
 */
export class MeetingsApi extends BaseAPI {
    /**
     *
     * @param {MeetingActionResource} meetingActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingsApi
     */
    public meetingsActionsCreate(meetingActionResource: MeetingActionResource, options?: any) {
        return MeetingsApiFp(this.configuration).meetingsActionsCreate(meetingActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} [leadId]
     * @param {number} [partnerId]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingsApi
     */
    public meetingsIndex(leadId?: number, partnerId?: number, limit?: number, page?: number, query?: string, options?: any) {
        return MeetingsApiFp(this.configuration).meetingsIndex(leadId, partnerId, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * NotificationsApi - fetch parameter creator
 * @export
 */
export const NotificationsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {NotificationActionResource} notificationActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsActionsCreate(notificationActionResource: NotificationActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'notificationActionResource' is not null or undefined
            if (notificationActionResource === null || notificationActionResource === undefined) {
                throw new RequiredError('notificationActionResource','Required parameter notificationActionResource was null or undefined when calling notificationsActionsCreate.');
            }
            const localVarPath = `/notifications/actions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"NotificationActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(notificationActionResource || {}) : (notificationActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} notificationId
         * @param {NotificationActionResource} notificationActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsActionsCreate_1(notificationId: string, notificationActionResource: NotificationActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'notificationId' is not null or undefined
            if (notificationId === null || notificationId === undefined) {
                throw new RequiredError('notificationId','Required parameter notificationId was null or undefined when calling notificationsActionsCreate_1.');
            }
            // verify required parameter 'notificationActionResource' is not null or undefined
            if (notificationActionResource === null || notificationActionResource === undefined) {
                throw new RequiredError('notificationActionResource','Required parameter notificationActionResource was null or undefined when calling notificationsActionsCreate_1.');
            }
            const localVarPath = `/notifications/{notification_id}/actions`
                .replace(`{${"notification_id"}}`, encodeURIComponent(String(notificationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"NotificationActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(notificationActionResource || {}) : (notificationActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} notificationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsGet(notificationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'notificationId' is not null or undefined
            if (notificationId === null || notificationId === undefined) {
                throw new RequiredError('notificationId','Required parameter notificationId was null or undefined when calling notificationsGet.');
            }
            const localVarPath = `/notifications/{notification_id}`
                .replace(`{${"notification_id"}}`, encodeURIComponent(String(notificationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [geoCode] Geo Code
         * @param {'relevance' | 'ascending' | 'descending'} [sort] Sort notifications
         * @param {string} [createdAfter] Only show notifications created after a given date
         * @param {string} [partnerUserId] Id&#39;s of the partner users to filter
         * @param {string} [clientId] Id&#39;s of the clients to filter
         * @param {string} [employeeId] Id&#39;s of the employees to filter
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsIndex(geoCode?: string, sort?: 'relevance' | 'ascending' | 'descending', createdAfter?: string, partnerUserId?: string, clientId?: string, employeeId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/notifications`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (createdAfter !== undefined) {
                localVarQueryParameter['created_after'] = createdAfter;
            }

            if (partnerUserId !== undefined) {
                localVarQueryParameter['partner_user_id'] = partnerUserId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employee_id'] = employeeId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {NotificationActionResource} notificationActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsActionsCreate(notificationActionResource: NotificationActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<NotificationResource> {
            const localVarFetchArgs = NotificationsApiFetchParamCreator(configuration).notificationsActionsCreate(notificationActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} notificationId
         * @param {NotificationActionResource} notificationActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsActionsCreate_1(notificationId: string, notificationActionResource: NotificationActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<NotificationResource> {
            const localVarFetchArgs = NotificationsApiFetchParamCreator(configuration).notificationsActionsCreate_1(notificationId, notificationActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} notificationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsGet(notificationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<NotificationResource> {
            const localVarFetchArgs = NotificationsApiFetchParamCreator(configuration).notificationsGet(notificationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [geoCode] Geo Code
         * @param {'relevance' | 'ascending' | 'descending'} [sort] Sort notifications
         * @param {string} [createdAfter] Only show notifications created after a given date
         * @param {string} [partnerUserId] Id&#39;s of the partner users to filter
         * @param {string} [clientId] Id&#39;s of the clients to filter
         * @param {string} [employeeId] Id&#39;s of the employees to filter
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsIndex(geoCode?: string, sort?: 'relevance' | 'ascending' | 'descending', createdAfter?: string, partnerUserId?: string, clientId?: string, employeeId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<NotificationResult> {
            const localVarFetchArgs = NotificationsApiFetchParamCreator(configuration).notificationsIndex(geoCode, sort, createdAfter, partnerUserId, clientId, employeeId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {NotificationActionResource} notificationActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsActionsCreate(notificationActionResource: NotificationActionResource, options?: any) {
            return NotificationsApiFp(configuration).notificationsActionsCreate(notificationActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} notificationId
         * @param {NotificationActionResource} notificationActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsActionsCreate_1(notificationId: string, notificationActionResource: NotificationActionResource, options?: any) {
            return NotificationsApiFp(configuration).notificationsActionsCreate_1(notificationId, notificationActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} notificationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsGet(notificationId: string, options?: any) {
            return NotificationsApiFp(configuration).notificationsGet(notificationId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [geoCode] Geo Code
         * @param {'relevance' | 'ascending' | 'descending'} [sort] Sort notifications
         * @param {string} [createdAfter] Only show notifications created after a given date
         * @param {string} [partnerUserId] Id&#39;s of the partner users to filter
         * @param {string} [clientId] Id&#39;s of the clients to filter
         * @param {string} [employeeId] Id&#39;s of the employees to filter
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsIndex(geoCode?: string, sort?: 'relevance' | 'ascending' | 'descending', createdAfter?: string, partnerUserId?: string, clientId?: string, employeeId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return NotificationsApiFp(configuration).notificationsIndex(geoCode, sort, createdAfter, partnerUserId, clientId, employeeId, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     *
     * @param {NotificationActionResource} notificationActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsActionsCreate(notificationActionResource: NotificationActionResource, options?: any) {
        return NotificationsApiFp(this.configuration).notificationsActionsCreate(notificationActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} notificationId
     * @param {NotificationActionResource} notificationActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsActionsCreate_1(notificationId: string, notificationActionResource: NotificationActionResource, options?: any) {
        return NotificationsApiFp(this.configuration).notificationsActionsCreate_1(notificationId, notificationActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} notificationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsGet(notificationId: string, options?: any) {
        return NotificationsApiFp(this.configuration).notificationsGet(notificationId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [geoCode] Geo Code
     * @param {'relevance' | 'ascending' | 'descending'} [sort] Sort notifications
     * @param {string} [createdAfter] Only show notifications created after a given date
     * @param {string} [partnerUserId] Id&#39;s of the partner users to filter
     * @param {string} [clientId] Id&#39;s of the clients to filter
     * @param {string} [employeeId] Id&#39;s of the employees to filter
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsIndex(geoCode?: string, sort?: 'relevance' | 'ascending' | 'descending', createdAfter?: string, partnerUserId?: string, clientId?: string, employeeId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return NotificationsApiFp(this.configuration).notificationsIndex(geoCode, sort, createdAfter, partnerUserId, clientId, employeeId, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * OffersApi - fetch parameter creator
 * @export
 */
export const OffersApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} offerId
         * @param {OfferActionResource} offerActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersActionsCreate(offerId: string, offerActionResource: OfferActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'offerId' is not null or undefined
            if (offerId === null || offerId === undefined) {
                throw new RequiredError('offerId','Required parameter offerId was null or undefined when calling offersActionsCreate.');
            }
            // verify required parameter 'offerActionResource' is not null or undefined
            if (offerActionResource === null || offerActionResource === undefined) {
                throw new RequiredError('offerActionResource','Required parameter offerActionResource was null or undefined when calling offersActionsCreate.');
            }
            const localVarPath = `/offers/{offer_id}/actions`
                .replace(`{${"offer_id"}}`, encodeURIComponent(String(offerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OfferActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(offerActionResource || {}) : (offerActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} offerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersGet(offerId: string, options: any = {}): FetchArgs {
            // verify required parameter 'offerId' is not null or undefined
            if (offerId === null || offerId === undefined) {
                throw new RequiredError('offerId','Required parameter offerId was null or undefined when calling offersGet.');
            }
            const localVarPath = `/offers/{offer_id}`
                .replace(`{${"offer_id"}}`, encodeURIComponent(String(offerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {boolean} [showExpired] Include expired partners in the result.
         * @param {boolean} [showAccepted] Show accepted partner.
         * @param {number} [partnerId] Partner id of Offers to filter by.
         * @param {'created_at' | 'accepted_at' | 'expires_at' | 'partner_id' | 'price' | 'ascending' | 'descending'} [sort] Order by price asc or desc.
         * @param {string} [action] Action to take.
         * @param {string} [geoCode] Offer geo code.
         * @param {'canvas' | 'demo' | 'partner' | 'standard'} [type] Type of Offer
         * @param {'unknown' | 'churn' | 'resale' | 'new_sale'} [saleType] Sale type
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersIndex(showExpired?: boolean, showAccepted?: boolean, partnerId?: number, sort?: 'created_at' | 'accepted_at' | 'expires_at' | 'partner_id' | 'price' | 'ascending' | 'descending', action?: string, geoCode?: string, type?: 'canvas' | 'demo' | 'partner' | 'standard', saleType?: 'unknown' | 'churn' | 'resale' | 'new_sale', limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/offers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (showExpired !== undefined) {
                localVarQueryParameter['show_expired'] = showExpired;
            }

            if (showAccepted !== undefined) {
                localVarQueryParameter['show_accepted'] = showAccepted;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (saleType !== undefined) {
                localVarQueryParameter['sale_type'] = saleType;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OffersApi - functional programming interface
 * @export
 */
export const OffersApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} offerId
         * @param {OfferActionResource} offerActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersActionsCreate(offerId: string, offerActionResource: OfferActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerOfferResource> {
            const localVarFetchArgs = OffersApiFetchParamCreator(configuration).offersActionsCreate(offerId, offerActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} offerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersGet(offerId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerOfferResource> {
            const localVarFetchArgs = OffersApiFetchParamCreator(configuration).offersGet(offerId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {boolean} [showExpired] Include expired partners in the result.
         * @param {boolean} [showAccepted] Show accepted partner.
         * @param {number} [partnerId] Partner id of Offers to filter by.
         * @param {'created_at' | 'accepted_at' | 'expires_at' | 'partner_id' | 'price' | 'ascending' | 'descending'} [sort] Order by price asc or desc.
         * @param {string} [action] Action to take.
         * @param {string} [geoCode] Offer geo code.
         * @param {'canvas' | 'demo' | 'partner' | 'standard'} [type] Type of Offer
         * @param {'unknown' | 'churn' | 'resale' | 'new_sale'} [saleType] Sale type
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersIndex(showExpired?: boolean, showAccepted?: boolean, partnerId?: number, sort?: 'created_at' | 'accepted_at' | 'expires_at' | 'partner_id' | 'price' | 'ascending' | 'descending', action?: string, geoCode?: string, type?: 'canvas' | 'demo' | 'partner' | 'standard', saleType?: 'unknown' | 'churn' | 'resale' | 'new_sale', limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerOfferResult> {
            const localVarFetchArgs = OffersApiFetchParamCreator(configuration).offersIndex(showExpired, showAccepted, partnerId, sort, action, geoCode, type, saleType, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OffersApi - factory interface
 * @export
 */
export const OffersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} offerId
         * @param {OfferActionResource} offerActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersActionsCreate(offerId: string, offerActionResource: OfferActionResource, options?: any) {
            return OffersApiFp(configuration).offersActionsCreate(offerId, offerActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} offerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersGet(offerId: string, options?: any) {
            return OffersApiFp(configuration).offersGet(offerId, options)(fetch, basePath);
        },
        /**
         *
         * @param {boolean} [showExpired] Include expired partners in the result.
         * @param {boolean} [showAccepted] Show accepted partner.
         * @param {number} [partnerId] Partner id of Offers to filter by.
         * @param {'created_at' | 'accepted_at' | 'expires_at' | 'partner_id' | 'price' | 'ascending' | 'descending'} [sort] Order by price asc or desc.
         * @param {string} [action] Action to take.
         * @param {string} [geoCode] Offer geo code.
         * @param {'canvas' | 'demo' | 'partner' | 'standard'} [type] Type of Offer
         * @param {'unknown' | 'churn' | 'resale' | 'new_sale'} [saleType] Sale type
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersIndex(showExpired?: boolean, showAccepted?: boolean, partnerId?: number, sort?: 'created_at' | 'accepted_at' | 'expires_at' | 'partner_id' | 'price' | 'ascending' | 'descending', action?: string, geoCode?: string, type?: 'canvas' | 'demo' | 'partner' | 'standard', saleType?: 'unknown' | 'churn' | 'resale' | 'new_sale', limit?: number, page?: number, query?: string, options?: any) {
            return OffersApiFp(configuration).offersIndex(showExpired, showAccepted, partnerId, sort, action, geoCode, type, saleType, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * OffersApi - object-oriented interface
 * @export
 * @class OffersApi
 * @extends {BaseAPI}
 */
export class OffersApi extends BaseAPI {
    /**
     *
     * @param {string} offerId
     * @param {OfferActionResource} offerActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public offersActionsCreate(offerId: string, offerActionResource: OfferActionResource, options?: any) {
        return OffersApiFp(this.configuration).offersActionsCreate(offerId, offerActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} offerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public offersGet(offerId: string, options?: any) {
        return OffersApiFp(this.configuration).offersGet(offerId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {boolean} [showExpired] Include expired partners in the result.
     * @param {boolean} [showAccepted] Show accepted partner.
     * @param {number} [partnerId] Partner id of Offers to filter by.
     * @param {'created_at' | 'accepted_at' | 'expires_at' | 'partner_id' | 'price' | 'ascending' | 'descending'} [sort] Order by price asc or desc.
     * @param {string} [action] Action to take.
     * @param {string} [geoCode] Offer geo code.
     * @param {'canvas' | 'demo' | 'partner' | 'standard'} [type] Type of Offer
     * @param {'unknown' | 'churn' | 'resale' | 'new_sale'} [saleType] Sale type
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public offersIndex(showExpired?: boolean, showAccepted?: boolean, partnerId?: number, sort?: 'created_at' | 'accepted_at' | 'expires_at' | 'partner_id' | 'price' | 'ascending' | 'descending', action?: string, geoCode?: string, type?: 'canvas' | 'demo' | 'partner' | 'standard', saleType?: 'unknown' | 'churn' | 'resale' | 'new_sale', limit?: number, page?: number, query?: string, options?: any) {
        return OffersApiFp(this.configuration).offersIndex(showExpired, showAccepted, partnerId, sort, action, geoCode, type, saleType, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * OrdersApi - fetch parameter creator
 * @export
 */
export const OrdersApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} orderId
         * @param {OrderActionResource} orderActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersActionsCreate(orderId: string, orderActionResource: OrderActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling ordersActionsCreate.');
            }
            // verify required parameter 'orderActionResource' is not null or undefined
            if (orderActionResource === null || orderActionResource === undefined) {
                throw new RequiredError('orderActionResource','Required parameter orderActionResource was null or undefined when calling ordersActionsCreate.');
            }
            const localVarPath = `/orders/{order_id}/actions`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(orderActionResource || {}) : (orderActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [orderId] Id of orders to retrieve
         * @param {string} [buyerPartnerId] Partner Buyer id to filter by.
         * @param {string} [buyerClientId] Client id to filter by.
         * @param {string} [geoCode] Geographic Location Code.
         * @param {string} [status] Order status.
         * @param {string} [sort]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersIndex(orderId?: string, buyerPartnerId?: string, buyerClientId?: string, geoCode?: string, status?: string, sort?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/orders`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (orderId !== undefined) {
                localVarQueryParameter['order_id'] = orderId;
            }

            if (buyerPartnerId !== undefined) {
                localVarQueryParameter['buyer_partner_id'] = buyerPartnerId;
            }

            if (buyerClientId !== undefined) {
                localVarQueryParameter['buyer_client_id'] = buyerClientId;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} orderId
         * @param {OrderActionResource} orderActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersActionsCreate(orderId: string, orderActionResource: OrderActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrderResource> {
            const localVarFetchArgs = OrdersApiFetchParamCreator(configuration).ordersActionsCreate(orderId, orderActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [orderId] Id of orders to retrieve
         * @param {string} [buyerPartnerId] Partner Buyer id to filter by.
         * @param {string} [buyerClientId] Client id to filter by.
         * @param {string} [geoCode] Geographic Location Code.
         * @param {string} [status] Order status.
         * @param {string} [sort]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersIndex(orderId?: string, buyerPartnerId?: string, buyerClientId?: string, geoCode?: string, status?: string, sort?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrderResult> {
            const localVarFetchArgs = OrdersApiFetchParamCreator(configuration).ordersIndex(orderId, buyerPartnerId, buyerClientId, geoCode, status, sort, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} orderId
         * @param {OrderActionResource} orderActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersActionsCreate(orderId: string, orderActionResource: OrderActionResource, options?: any) {
            return OrdersApiFp(configuration).ordersActionsCreate(orderId, orderActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [orderId] Id of orders to retrieve
         * @param {string} [buyerPartnerId] Partner Buyer id to filter by.
         * @param {string} [buyerClientId] Client id to filter by.
         * @param {string} [geoCode] Geographic Location Code.
         * @param {string} [status] Order status.
         * @param {string} [sort]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersIndex(orderId?: string, buyerPartnerId?: string, buyerClientId?: string, geoCode?: string, status?: string, sort?: string, limit?: number, page?: number, query?: string, options?: any) {
            return OrdersApiFp(configuration).ordersIndex(orderId, buyerPartnerId, buyerClientId, geoCode, status, sort, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI {
    /**
     *
     * @param {string} orderId
     * @param {OrderActionResource} orderActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersActionsCreate(orderId: string, orderActionResource: OrderActionResource, options?: any) {
        return OrdersApiFp(this.configuration).ordersActionsCreate(orderId, orderActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [orderId] Id of orders to retrieve
     * @param {string} [buyerPartnerId] Partner Buyer id to filter by.
     * @param {string} [buyerClientId] Client id to filter by.
     * @param {string} [geoCode] Geographic Location Code.
     * @param {string} [status] Order status.
     * @param {string} [sort]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersIndex(orderId?: string, buyerPartnerId?: string, buyerClientId?: string, geoCode?: string, status?: string, sort?: string, limit?: number, page?: number, query?: string, options?: any) {
        return OrdersApiFp(this.configuration).ordersIndex(orderId, buyerPartnerId, buyerClientId, geoCode, status, sort, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * OrganisationApi - fetch parameter creator
 * @export
 */
export const OrganisationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} employeeId
         * @param {EmployeeActionResource} employeeActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesActionsCreate(employeeId: string, employeeActionResource: EmployeeActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'employeeId' is not null or undefined
            if (employeeId === null || employeeId === undefined) {
                throw new RequiredError('employeeId','Required parameter employeeId was null or undefined when calling organisationEmployeesActionsCreate.');
            }
            // verify required parameter 'employeeActionResource' is not null or undefined
            if (employeeActionResource === null || employeeActionResource === undefined) {
                throw new RequiredError('employeeActionResource','Required parameter employeeActionResource was null or undefined when calling organisationEmployeesActionsCreate.');
            }
            const localVarPath = `/organisation/employees/{employee_id}/actions`
                .replace(`{${"employee_id"}}`, encodeURIComponent(String(employeeId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EmployeeActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(employeeActionResource || {}) : (employeeActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} organisationId
         * @param {string} employeeId
         * @param {EmployeeActionResource} employeeActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesActionsCreate_1(organisationId: string, employeeId: string, employeeActionResource: EmployeeActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'organisationId' is not null or undefined
            if (organisationId === null || organisationId === undefined) {
                throw new RequiredError('organisationId','Required parameter organisationId was null or undefined when calling organisationEmployeesActionsCreate_1.');
            }
            // verify required parameter 'employeeId' is not null or undefined
            if (employeeId === null || employeeId === undefined) {
                throw new RequiredError('employeeId','Required parameter employeeId was null or undefined when calling organisationEmployeesActionsCreate_1.');
            }
            // verify required parameter 'employeeActionResource' is not null or undefined
            if (employeeActionResource === null || employeeActionResource === undefined) {
                throw new RequiredError('employeeActionResource','Required parameter employeeActionResource was null or undefined when calling organisationEmployeesActionsCreate_1.');
            }
            const localVarPath = `/organisation/{organisation_id}/employees/{employee_id}/actions`
                .replace(`{${"organisation_id"}}`, encodeURIComponent(String(organisationId)))
                .replace(`{${"employee_id"}}`, encodeURIComponent(String(employeeId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EmployeeActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(employeeActionResource || {}) : (employeeActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} employeeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesGet(employeeId: string, options: any = {}): FetchArgs {
            // verify required parameter 'employeeId' is not null or undefined
            if (employeeId === null || employeeId === undefined) {
                throw new RequiredError('employeeId','Required parameter employeeId was null or undefined when calling organisationEmployeesGet.');
            }
            const localVarPath = `/organisation/employees/{employee_id}`
                .replace(`{${"employee_id"}}`, encodeURIComponent(String(employeeId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} organisationId
         * @param {string} employeeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesGet_2(organisationId: string, employeeId: string, options: any = {}): FetchArgs {
            // verify required parameter 'organisationId' is not null or undefined
            if (organisationId === null || organisationId === undefined) {
                throw new RequiredError('organisationId','Required parameter organisationId was null or undefined when calling organisationEmployeesGet_2.');
            }
            // verify required parameter 'employeeId' is not null or undefined
            if (employeeId === null || employeeId === undefined) {
                throw new RequiredError('employeeId','Required parameter employeeId was null or undefined when calling organisationEmployeesGet_2.');
            }
            const localVarPath = `/organisation/{organisation_id}/employees/{employee_id}`
                .replace(`{${"organisation_id"}}`, encodeURIComponent(String(organisationId)))
                .replace(`{${"employee_id"}}`, encodeURIComponent(String(employeeId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [employeeId] Id for the employees.
         * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
         * @param {number} [limit] Number of employees to return pr. page.
         * @param {string} [roleId] The Role that the employee is member of.
         * @param {'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending'} [sort] Attribute to sort by.
         * @param {boolean} [isActive] Active Employee Status ( true / false )
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesIndex(employeeId?: string, geoCode?: string, limit?: number, roleId?: string, sort?: 'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending', isActive?: boolean, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/organisation/employees`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employee_id'] = employeeId;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (roleId !== undefined) {
                localVarQueryParameter['role_id'] = roleId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [employeeId] Id for the employees.
         * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
         * @param {number} [limit] Number of employees to return pr. page.
         * @param {string} [roleId] The Role that the employee is member of.
         * @param {'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending'} [sort] Attribute to sort by.
         * @param {boolean} [isActive] Active Employee Status ( true / false )
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesKpiIndex(employeeId?: string, geoCode?: string, limit?: number, roleId?: string, sort?: 'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending', isActive?: boolean, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/organisation/employees/kpi`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employee_id'] = employeeId;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (roleId !== undefined) {
                localVarQueryParameter['role_id'] = roleId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [employeeId] Id for the employees.
         * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
         * @param {number} [limit] Number of employees to return pr. page.
         * @param {string} [roleId] The Role that the employee is member of.
         * @param {'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending'} [sort] Attribute to sort by.
         * @param {boolean} [isActive] Active Employee Status ( true / false )
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesKpiIndex_3(employeeId?: string, geoCode?: string, limit?: number, roleId?: string, sort?: 'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending', isActive?: boolean, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/organisation/organisation/employees/kpi`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employee_id'] = employeeId;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (roleId !== undefined) {
                localVarQueryParameter['role_id'] = roleId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} organisationId
         * @param {string} [employeeId] Id for the employees.
         * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
         * @param {number} [limit] Number of employees to return pr. page.
         * @param {string} [roleId] The Role that the employee is member of.
         * @param {'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending'} [sort] Attribute to sort by.
         * @param {boolean} [isActive] Active Employee Status ( true / false )
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesKpiIndex_4(organisationId: string, employeeId?: string, geoCode?: string, limit?: number, roleId?: string, sort?: 'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending', isActive?: boolean, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'organisationId' is not null or undefined
            if (organisationId === null || organisationId === undefined) {
                throw new RequiredError('organisationId','Required parameter organisationId was null or undefined when calling organisationEmployeesKpiIndex_4.');
            }
            const localVarPath = `/organisation/{organisation_id}/employees/kpi`
                .replace(`{${"organisation_id"}}`, encodeURIComponent(String(organisationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employee_id'] = employeeId;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (roleId !== undefined) {
                localVarQueryParameter['role_id'] = roleId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} employeeId
         * @param {EmployeeLeadPredictionResource} employeeLeadPredictionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesLeadpredictionsCreate(employeeId: string, employeeLeadPredictionResource: EmployeeLeadPredictionResource, options: any = {}): FetchArgs {
            // verify required parameter 'employeeId' is not null or undefined
            if (employeeId === null || employeeId === undefined) {
                throw new RequiredError('employeeId','Required parameter employeeId was null or undefined when calling organisationEmployeesLeadpredictionsCreate.');
            }
            // verify required parameter 'employeeLeadPredictionResource' is not null or undefined
            if (employeeLeadPredictionResource === null || employeeLeadPredictionResource === undefined) {
                throw new RequiredError('employeeLeadPredictionResource','Required parameter employeeLeadPredictionResource was null or undefined when calling organisationEmployeesLeadpredictionsCreate.');
            }
            const localVarPath = `/organisation/employees/{employee_id}/leadpredictions`
                .replace(`{${"employee_id"}}`, encodeURIComponent(String(employeeId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EmployeeLeadPredictionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(employeeLeadPredictionResource || {}) : (employeeLeadPredictionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} organisationId
         * @param {string} employeeId
         * @param {EmployeeLeadPredictionResource} employeeLeadPredictionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesLeadpredictionsCreate_5(organisationId: string, employeeId: string, employeeLeadPredictionResource: EmployeeLeadPredictionResource, options: any = {}): FetchArgs {
            // verify required parameter 'organisationId' is not null or undefined
            if (organisationId === null || organisationId === undefined) {
                throw new RequiredError('organisationId','Required parameter organisationId was null or undefined when calling organisationEmployeesLeadpredictionsCreate_5.');
            }
            // verify required parameter 'employeeId' is not null or undefined
            if (employeeId === null || employeeId === undefined) {
                throw new RequiredError('employeeId','Required parameter employeeId was null or undefined when calling organisationEmployeesLeadpredictionsCreate_5.');
            }
            // verify required parameter 'employeeLeadPredictionResource' is not null or undefined
            if (employeeLeadPredictionResource === null || employeeLeadPredictionResource === undefined) {
                throw new RequiredError('employeeLeadPredictionResource','Required parameter employeeLeadPredictionResource was null or undefined when calling organisationEmployeesLeadpredictionsCreate_5.');
            }
            const localVarPath = `/organisation/{organisation_id}/employees/{employee_id}/leadpredictions`
                .replace(`{${"organisation_id"}}`, encodeURIComponent(String(organisationId)))
                .replace(`{${"employee_id"}}`, encodeURIComponent(String(employeeId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EmployeeLeadPredictionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(employeeLeadPredictionResource || {}) : (employeeLeadPredictionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} employeeId
         * @param {boolean} [isSeen] Filter by whether or not a notification has been seen.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesNotificationsIndex(employeeId: string, isSeen?: boolean, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'employeeId' is not null or undefined
            if (employeeId === null || employeeId === undefined) {
                throw new RequiredError('employeeId','Required parameter employeeId was null or undefined when calling organisationEmployeesNotificationsIndex.');
            }
            const localVarPath = `/organisation/employees/{employee_id}/notifications`
                .replace(`{${"employee_id"}}`, encodeURIComponent(String(employeeId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (isSeen !== undefined) {
                localVarQueryParameter['is_seen'] = isSeen;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} organisationId
         * @param {string} employeeId
         * @param {boolean} [isSeen] Filter by whether or not a notification has been seen.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesNotificationsIndex_6(organisationId: string, employeeId: string, isSeen?: boolean, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'organisationId' is not null or undefined
            if (organisationId === null || organisationId === undefined) {
                throw new RequiredError('organisationId','Required parameter organisationId was null or undefined when calling organisationEmployeesNotificationsIndex_6.');
            }
            // verify required parameter 'employeeId' is not null or undefined
            if (employeeId === null || employeeId === undefined) {
                throw new RequiredError('employeeId','Required parameter employeeId was null or undefined when calling organisationEmployeesNotificationsIndex_6.');
            }
            const localVarPath = `/organisation/{organisation_id}/employees/{employee_id}/notifications`
                .replace(`{${"organisation_id"}}`, encodeURIComponent(String(organisationId)))
                .replace(`{${"employee_id"}}`, encodeURIComponent(String(employeeId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (isSeen !== undefined) {
                localVarQueryParameter['is_seen'] = isSeen;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [frontendEmployeeTeamId] IDs of frontend employee teams
         * @param {string} [employeeId] IDs of employees this frontend employee is associated with
         * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
         * @param {boolean} [isActive] Return only frontend employees that have an active employee assigned
         * @param {boolean} [hasTeam] Return only frontend employees that are assigned to a team
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationFrontendemployeesIndex(frontendEmployeeTeamId?: string, employeeId?: string, geoCode?: string, isActive?: boolean, hasTeam?: boolean, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/organisation/frontendemployees`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (frontendEmployeeTeamId !== undefined) {
                localVarQueryParameter['frontend_employee_team_id'] = frontendEmployeeTeamId;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employee_id'] = employeeId;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }

            if (hasTeam !== undefined) {
                localVarQueryParameter['has_team'] = hasTeam;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {boolean} [hasFrontendEmployees] Return only frontend teams that have at least one frontend employee
         * @param {string} [frontendEmployeeId] Return only frontend employee teams that have frontend employees of given IDs in employee list
         * @param {string} [employeeId] Return only frontend employee teams that have employees of given IDs associated with frontend employees in them
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationFrontendemployeeteamsIndex(hasFrontendEmployees?: boolean, frontendEmployeeId?: string, employeeId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/organisation/frontendemployeeteams`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (hasFrontendEmployees !== undefined) {
                localVarQueryParameter['has_frontend_employees'] = hasFrontendEmployees;
            }

            if (frontendEmployeeId !== undefined) {
                localVarQueryParameter['frontend_employee_id'] = frontendEmployeeId;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employee_id'] = employeeId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationApi - functional programming interface
 * @export
 */
export const OrganisationApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} employeeId
         * @param {EmployeeActionResource} employeeActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesActionsCreate(employeeId: string, employeeActionResource: EmployeeActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EmployeeResource> {
            const localVarFetchArgs = OrganisationApiFetchParamCreator(configuration).organisationEmployeesActionsCreate(employeeId, employeeActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} organisationId
         * @param {string} employeeId
         * @param {EmployeeActionResource} employeeActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesActionsCreate_1(organisationId: string, employeeId: string, employeeActionResource: EmployeeActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EmployeeResource> {
            const localVarFetchArgs = OrganisationApiFetchParamCreator(configuration).organisationEmployeesActionsCreate_1(organisationId, employeeId, employeeActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} employeeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesGet(employeeId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EmployeeResource> {
            const localVarFetchArgs = OrganisationApiFetchParamCreator(configuration).organisationEmployeesGet(employeeId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} organisationId
         * @param {string} employeeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesGet_2(organisationId: string, employeeId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EmployeeResource> {
            const localVarFetchArgs = OrganisationApiFetchParamCreator(configuration).organisationEmployeesGet_2(organisationId, employeeId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [employeeId] Id for the employees.
         * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
         * @param {number} [limit] Number of employees to return pr. page.
         * @param {string} [roleId] The Role that the employee is member of.
         * @param {'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending'} [sort] Attribute to sort by.
         * @param {boolean} [isActive] Active Employee Status ( true / false )
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesIndex(employeeId?: string, geoCode?: string, limit?: number, roleId?: string, sort?: 'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending', isActive?: boolean, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EmployeeResult> {
            const localVarFetchArgs = OrganisationApiFetchParamCreator(configuration).organisationEmployeesIndex(employeeId, geoCode, limit, roleId, sort, isActive, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [employeeId] Id for the employees.
         * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
         * @param {number} [limit] Number of employees to return pr. page.
         * @param {string} [roleId] The Role that the employee is member of.
         * @param {'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending'} [sort] Attribute to sort by.
         * @param {boolean} [isActive] Active Employee Status ( true / false )
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesKpiIndex(employeeId?: string, geoCode?: string, limit?: number, roleId?: string, sort?: 'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending', isActive?: boolean, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EmployeeKpiResult> {
            const localVarFetchArgs = OrganisationApiFetchParamCreator(configuration).organisationEmployeesKpiIndex(employeeId, geoCode, limit, roleId, sort, isActive, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [employeeId] Id for the employees.
         * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
         * @param {number} [limit] Number of employees to return pr. page.
         * @param {string} [roleId] The Role that the employee is member of.
         * @param {'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending'} [sort] Attribute to sort by.
         * @param {boolean} [isActive] Active Employee Status ( true / false )
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesKpiIndex_3(employeeId?: string, geoCode?: string, limit?: number, roleId?: string, sort?: 'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending', isActive?: boolean, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EmployeeKpiResult> {
            const localVarFetchArgs = OrganisationApiFetchParamCreator(configuration).organisationEmployeesKpiIndex_3(employeeId, geoCode, limit, roleId, sort, isActive, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} organisationId
         * @param {string} [employeeId] Id for the employees.
         * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
         * @param {number} [limit] Number of employees to return pr. page.
         * @param {string} [roleId] The Role that the employee is member of.
         * @param {'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending'} [sort] Attribute to sort by.
         * @param {boolean} [isActive] Active Employee Status ( true / false )
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesKpiIndex_4(organisationId: string, employeeId?: string, geoCode?: string, limit?: number, roleId?: string, sort?: 'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending', isActive?: boolean, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EmployeeKpiResult> {
            const localVarFetchArgs = OrganisationApiFetchParamCreator(configuration).organisationEmployeesKpiIndex_4(organisationId, employeeId, geoCode, limit, roleId, sort, isActive, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} employeeId
         * @param {EmployeeLeadPredictionResource} employeeLeadPredictionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesLeadpredictionsCreate(employeeId: string, employeeLeadPredictionResource: EmployeeLeadPredictionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EmployeeLeadPredictionResource> {
            const localVarFetchArgs = OrganisationApiFetchParamCreator(configuration).organisationEmployeesLeadpredictionsCreate(employeeId, employeeLeadPredictionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} organisationId
         * @param {string} employeeId
         * @param {EmployeeLeadPredictionResource} employeeLeadPredictionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesLeadpredictionsCreate_5(organisationId: string, employeeId: string, employeeLeadPredictionResource: EmployeeLeadPredictionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EmployeeLeadPredictionResource> {
            const localVarFetchArgs = OrganisationApiFetchParamCreator(configuration).organisationEmployeesLeadpredictionsCreate_5(organisationId, employeeId, employeeLeadPredictionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} employeeId
         * @param {boolean} [isSeen] Filter by whether or not a notification has been seen.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesNotificationsIndex(employeeId: string, isSeen?: boolean, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EmployeeNotificationResult> {
            const localVarFetchArgs = OrganisationApiFetchParamCreator(configuration).organisationEmployeesNotificationsIndex(employeeId, isSeen, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} organisationId
         * @param {string} employeeId
         * @param {boolean} [isSeen] Filter by whether or not a notification has been seen.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesNotificationsIndex_6(organisationId: string, employeeId: string, isSeen?: boolean, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EmployeeNotificationResult> {
            const localVarFetchArgs = OrganisationApiFetchParamCreator(configuration).organisationEmployeesNotificationsIndex_6(organisationId, employeeId, isSeen, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [frontendEmployeeTeamId] IDs of frontend employee teams
         * @param {string} [employeeId] IDs of employees this frontend employee is associated with
         * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
         * @param {boolean} [isActive] Return only frontend employees that have an active employee assigned
         * @param {boolean} [hasTeam] Return only frontend employees that are assigned to a team
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationFrontendemployeesIndex(frontendEmployeeTeamId?: string, employeeId?: string, geoCode?: string, isActive?: boolean, hasTeam?: boolean, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FrontendEmployeeResult> {
            const localVarFetchArgs = OrganisationApiFetchParamCreator(configuration).organisationFrontendemployeesIndex(frontendEmployeeTeamId, employeeId, geoCode, isActive, hasTeam, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {boolean} [hasFrontendEmployees] Return only frontend teams that have at least one frontend employee
         * @param {string} [frontendEmployeeId] Return only frontend employee teams that have frontend employees of given IDs in employee list
         * @param {string} [employeeId] Return only frontend employee teams that have employees of given IDs associated with frontend employees in them
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationFrontendemployeeteamsIndex(hasFrontendEmployees?: boolean, frontendEmployeeId?: string, employeeId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FrontendEmployeeTeamResult> {
            const localVarFetchArgs = OrganisationApiFetchParamCreator(configuration).organisationFrontendemployeeteamsIndex(hasFrontendEmployees, frontendEmployeeId, employeeId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OrganisationApi - factory interface
 * @export
 */
export const OrganisationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} employeeId
         * @param {EmployeeActionResource} employeeActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesActionsCreate(employeeId: string, employeeActionResource: EmployeeActionResource, options?: any) {
            return OrganisationApiFp(configuration).organisationEmployeesActionsCreate(employeeId, employeeActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} organisationId
         * @param {string} employeeId
         * @param {EmployeeActionResource} employeeActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesActionsCreate_1(organisationId: string, employeeId: string, employeeActionResource: EmployeeActionResource, options?: any) {
            return OrganisationApiFp(configuration).organisationEmployeesActionsCreate_1(organisationId, employeeId, employeeActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} employeeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesGet(employeeId: string, options?: any) {
            return OrganisationApiFp(configuration).organisationEmployeesGet(employeeId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} organisationId
         * @param {string} employeeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesGet_2(organisationId: string, employeeId: string, options?: any) {
            return OrganisationApiFp(configuration).organisationEmployeesGet_2(organisationId, employeeId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [employeeId] Id for the employees.
         * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
         * @param {number} [limit] Number of employees to return pr. page.
         * @param {string} [roleId] The Role that the employee is member of.
         * @param {'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending'} [sort] Attribute to sort by.
         * @param {boolean} [isActive] Active Employee Status ( true / false )
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesIndex(employeeId?: string, geoCode?: string, limit?: number, roleId?: string, sort?: 'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending', isActive?: boolean, page?: number, query?: string, options?: any) {
            return OrganisationApiFp(configuration).organisationEmployeesIndex(employeeId, geoCode, limit, roleId, sort, isActive, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [employeeId] Id for the employees.
         * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
         * @param {number} [limit] Number of employees to return pr. page.
         * @param {string} [roleId] The Role that the employee is member of.
         * @param {'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending'} [sort] Attribute to sort by.
         * @param {boolean} [isActive] Active Employee Status ( true / false )
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesKpiIndex(employeeId?: string, geoCode?: string, limit?: number, roleId?: string, sort?: 'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending', isActive?: boolean, page?: number, query?: string, options?: any) {
            return OrganisationApiFp(configuration).organisationEmployeesKpiIndex(employeeId, geoCode, limit, roleId, sort, isActive, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [employeeId] Id for the employees.
         * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
         * @param {number} [limit] Number of employees to return pr. page.
         * @param {string} [roleId] The Role that the employee is member of.
         * @param {'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending'} [sort] Attribute to sort by.
         * @param {boolean} [isActive] Active Employee Status ( true / false )
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesKpiIndex_3(employeeId?: string, geoCode?: string, limit?: number, roleId?: string, sort?: 'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending', isActive?: boolean, page?: number, query?: string, options?: any) {
            return OrganisationApiFp(configuration).organisationEmployeesKpiIndex_3(employeeId, geoCode, limit, roleId, sort, isActive, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} organisationId
         * @param {string} [employeeId] Id for the employees.
         * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
         * @param {number} [limit] Number of employees to return pr. page.
         * @param {string} [roleId] The Role that the employee is member of.
         * @param {'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending'} [sort] Attribute to sort by.
         * @param {boolean} [isActive] Active Employee Status ( true / false )
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesKpiIndex_4(organisationId: string, employeeId?: string, geoCode?: string, limit?: number, roleId?: string, sort?: 'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending', isActive?: boolean, page?: number, query?: string, options?: any) {
            return OrganisationApiFp(configuration).organisationEmployeesKpiIndex_4(organisationId, employeeId, geoCode, limit, roleId, sort, isActive, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} employeeId
         * @param {EmployeeLeadPredictionResource} employeeLeadPredictionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesLeadpredictionsCreate(employeeId: string, employeeLeadPredictionResource: EmployeeLeadPredictionResource, options?: any) {
            return OrganisationApiFp(configuration).organisationEmployeesLeadpredictionsCreate(employeeId, employeeLeadPredictionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} organisationId
         * @param {string} employeeId
         * @param {EmployeeLeadPredictionResource} employeeLeadPredictionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesLeadpredictionsCreate_5(organisationId: string, employeeId: string, employeeLeadPredictionResource: EmployeeLeadPredictionResource, options?: any) {
            return OrganisationApiFp(configuration).organisationEmployeesLeadpredictionsCreate_5(organisationId, employeeId, employeeLeadPredictionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} employeeId
         * @param {boolean} [isSeen] Filter by whether or not a notification has been seen.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesNotificationsIndex(employeeId: string, isSeen?: boolean, limit?: number, page?: number, query?: string, options?: any) {
            return OrganisationApiFp(configuration).organisationEmployeesNotificationsIndex(employeeId, isSeen, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} organisationId
         * @param {string} employeeId
         * @param {boolean} [isSeen] Filter by whether or not a notification has been seen.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationEmployeesNotificationsIndex_6(organisationId: string, employeeId: string, isSeen?: boolean, limit?: number, page?: number, query?: string, options?: any) {
            return OrganisationApiFp(configuration).organisationEmployeesNotificationsIndex_6(organisationId, employeeId, isSeen, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [frontendEmployeeTeamId] IDs of frontend employee teams
         * @param {string} [employeeId] IDs of employees this frontend employee is associated with
         * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
         * @param {boolean} [isActive] Return only frontend employees that have an active employee assigned
         * @param {boolean} [hasTeam] Return only frontend employees that are assigned to a team
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationFrontendemployeesIndex(frontendEmployeeTeamId?: string, employeeId?: string, geoCode?: string, isActive?: boolean, hasTeam?: boolean, limit?: number, page?: number, query?: string, options?: any) {
            return OrganisationApiFp(configuration).organisationFrontendemployeesIndex(frontendEmployeeTeamId, employeeId, geoCode, isActive, hasTeam, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {boolean} [hasFrontendEmployees] Return only frontend teams that have at least one frontend employee
         * @param {string} [frontendEmployeeId] Return only frontend employee teams that have frontend employees of given IDs in employee list
         * @param {string} [employeeId] Return only frontend employee teams that have employees of given IDs associated with frontend employees in them
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organisationFrontendemployeeteamsIndex(hasFrontendEmployees?: boolean, frontendEmployeeId?: string, employeeId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return OrganisationApiFp(configuration).organisationFrontendemployeeteamsIndex(hasFrontendEmployees, frontendEmployeeId, employeeId, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * OrganisationApi - object-oriented interface
 * @export
 * @class OrganisationApi
 * @extends {BaseAPI}
 */
export class OrganisationApi extends BaseAPI {
    /**
     *
     * @param {string} employeeId
     * @param {EmployeeActionResource} employeeActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public organisationEmployeesActionsCreate(employeeId: string, employeeActionResource: EmployeeActionResource, options?: any) {
        return OrganisationApiFp(this.configuration).organisationEmployeesActionsCreate(employeeId, employeeActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} organisationId
     * @param {string} employeeId
     * @param {EmployeeActionResource} employeeActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public organisationEmployeesActionsCreate_1(organisationId: string, employeeId: string, employeeActionResource: EmployeeActionResource, options?: any) {
        return OrganisationApiFp(this.configuration).organisationEmployeesActionsCreate_1(organisationId, employeeId, employeeActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} employeeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public organisationEmployeesGet(employeeId: string, options?: any) {
        return OrganisationApiFp(this.configuration).organisationEmployeesGet(employeeId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} organisationId
     * @param {string} employeeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public organisationEmployeesGet_2(organisationId: string, employeeId: string, options?: any) {
        return OrganisationApiFp(this.configuration).organisationEmployeesGet_2(organisationId, employeeId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [employeeId] Id for the employees.
     * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
     * @param {number} [limit] Number of employees to return pr. page.
     * @param {string} [roleId] The Role that the employee is member of.
     * @param {'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending'} [sort] Attribute to sort by.
     * @param {boolean} [isActive] Active Employee Status ( true / false )
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public organisationEmployeesIndex(employeeId?: string, geoCode?: string, limit?: number, roleId?: string, sort?: 'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending', isActive?: boolean, page?: number, query?: string, options?: any) {
        return OrganisationApiFp(this.configuration).organisationEmployeesIndex(employeeId, geoCode, limit, roleId, sort, isActive, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [employeeId] Id for the employees.
     * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
     * @param {number} [limit] Number of employees to return pr. page.
     * @param {string} [roleId] The Role that the employee is member of.
     * @param {'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending'} [sort] Attribute to sort by.
     * @param {boolean} [isActive] Active Employee Status ( true / false )
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public organisationEmployeesKpiIndex(employeeId?: string, geoCode?: string, limit?: number, roleId?: string, sort?: 'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending', isActive?: boolean, page?: number, query?: string, options?: any) {
        return OrganisationApiFp(this.configuration).organisationEmployeesKpiIndex(employeeId, geoCode, limit, roleId, sort, isActive, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [employeeId] Id for the employees.
     * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
     * @param {number} [limit] Number of employees to return pr. page.
     * @param {string} [roleId] The Role that the employee is member of.
     * @param {'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending'} [sort] Attribute to sort by.
     * @param {boolean} [isActive] Active Employee Status ( true / false )
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public organisationEmployeesKpiIndex_3(employeeId?: string, geoCode?: string, limit?: number, roleId?: string, sort?: 'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending', isActive?: boolean, page?: number, query?: string, options?: any) {
        return OrganisationApiFp(this.configuration).organisationEmployeesKpiIndex_3(employeeId, geoCode, limit, roleId, sort, isActive, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} organisationId
     * @param {string} [employeeId] Id for the employees.
     * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
     * @param {number} [limit] Number of employees to return pr. page.
     * @param {string} [roleId] The Role that the employee is member of.
     * @param {'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending'} [sort] Attribute to sort by.
     * @param {boolean} [isActive] Active Employee Status ( true / false )
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public organisationEmployeesKpiIndex_4(organisationId: string, employeeId?: string, geoCode?: string, limit?: number, roleId?: string, sort?: 'created_at' | 'first_name' | 'last_name' | 'email' | 'ascending' | 'descending', isActive?: boolean, page?: number, query?: string, options?: any) {
        return OrganisationApiFp(this.configuration).organisationEmployeesKpiIndex_4(organisationId, employeeId, geoCode, limit, roleId, sort, isActive, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} employeeId
     * @param {EmployeeLeadPredictionResource} employeeLeadPredictionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public organisationEmployeesLeadpredictionsCreate(employeeId: string, employeeLeadPredictionResource: EmployeeLeadPredictionResource, options?: any) {
        return OrganisationApiFp(this.configuration).organisationEmployeesLeadpredictionsCreate(employeeId, employeeLeadPredictionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} organisationId
     * @param {string} employeeId
     * @param {EmployeeLeadPredictionResource} employeeLeadPredictionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public organisationEmployeesLeadpredictionsCreate_5(organisationId: string, employeeId: string, employeeLeadPredictionResource: EmployeeLeadPredictionResource, options?: any) {
        return OrganisationApiFp(this.configuration).organisationEmployeesLeadpredictionsCreate_5(organisationId, employeeId, employeeLeadPredictionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} employeeId
     * @param {boolean} [isSeen] Filter by whether or not a notification has been seen.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public organisationEmployeesNotificationsIndex(employeeId: string, isSeen?: boolean, limit?: number, page?: number, query?: string, options?: any) {
        return OrganisationApiFp(this.configuration).organisationEmployeesNotificationsIndex(employeeId, isSeen, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} organisationId
     * @param {string} employeeId
     * @param {boolean} [isSeen] Filter by whether or not a notification has been seen.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public organisationEmployeesNotificationsIndex_6(organisationId: string, employeeId: string, isSeen?: boolean, limit?: number, page?: number, query?: string, options?: any) {
        return OrganisationApiFp(this.configuration).organisationEmployeesNotificationsIndex_6(organisationId, employeeId, isSeen, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [frontendEmployeeTeamId] IDs of frontend employee teams
     * @param {string} [employeeId] IDs of employees this frontend employee is associated with
     * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
     * @param {boolean} [isActive] Return only frontend employees that have an active employee assigned
     * @param {boolean} [hasTeam] Return only frontend employees that are assigned to a team
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public organisationFrontendemployeesIndex(frontendEmployeeTeamId?: string, employeeId?: string, geoCode?: string, isActive?: boolean, hasTeam?: boolean, limit?: number, page?: number, query?: string, options?: any) {
        return OrganisationApiFp(this.configuration).organisationFrontendemployeesIndex(frontendEmployeeTeamId, employeeId, geoCode, isActive, hasTeam, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {boolean} [hasFrontendEmployees] Return only frontend teams that have at least one frontend employee
     * @param {string} [frontendEmployeeId] Return only frontend employee teams that have frontend employees of given IDs in employee list
     * @param {string} [employeeId] Return only frontend employee teams that have employees of given IDs associated with frontend employees in them
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public organisationFrontendemployeeteamsIndex(hasFrontendEmployees?: boolean, frontendEmployeeId?: string, employeeId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return OrganisationApiFp(this.configuration).organisationFrontendemployeeteamsIndex(hasFrontendEmployees, frontendEmployeeId, employeeId, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * PackagesApi - fetch parameter creator
 * @export
 */
export const PackagesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        packagesFeaturesIndex(limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/packages/features`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        packagesGet(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling packagesGet.');
            }
            const localVarPath = `/packages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [geoCode] Employee id of the partners to filter by.
         * @param {string} [partnerId] Partner ID.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        packagesIndex(geoCode?: string, partnerId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/packages`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PackagesApi - functional programming interface
 * @export
 */
export const PackagesApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        packagesFeaturesIndex(limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PackageFeatureResult> {
            const localVarFetchArgs = PackagesApiFetchParamCreator(configuration).packagesFeaturesIndex(limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        packagesGet(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PackageResource> {
            const localVarFetchArgs = PackagesApiFetchParamCreator(configuration).packagesGet(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [geoCode] Employee id of the partners to filter by.
         * @param {string} [partnerId] Partner ID.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        packagesIndex(geoCode?: string, partnerId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PackageResult> {
            const localVarFetchArgs = PackagesApiFetchParamCreator(configuration).packagesIndex(geoCode, partnerId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PackagesApi - factory interface
 * @export
 */
export const PackagesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        packagesFeaturesIndex(limit?: number, page?: number, query?: string, options?: any) {
            return PackagesApiFp(configuration).packagesFeaturesIndex(limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        packagesGet(id: string, options?: any) {
            return PackagesApiFp(configuration).packagesGet(id, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [geoCode] Employee id of the partners to filter by.
         * @param {string} [partnerId] Partner ID.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        packagesIndex(geoCode?: string, partnerId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PackagesApiFp(configuration).packagesIndex(geoCode, partnerId, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * PackagesApi - object-oriented interface
 * @export
 * @class PackagesApi
 * @extends {BaseAPI}
 */
export class PackagesApi extends BaseAPI {
    /**
     *
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagesApi
     */
    public packagesFeaturesIndex(limit?: number, page?: number, query?: string, options?: any) {
        return PackagesApiFp(this.configuration).packagesFeaturesIndex(limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagesApi
     */
    public packagesGet(id: string, options?: any) {
        return PackagesApiFp(this.configuration).packagesGet(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [geoCode] Employee id of the partners to filter by.
     * @param {string} [partnerId] Partner ID.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackagesApi
     */
    public packagesIndex(geoCode?: string, partnerId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PackagesApiFp(this.configuration).packagesIndex(geoCode, partnerId, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * PartnersApi - fetch parameter creator
 * @export
 */
export const PartnersApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersActionfeesIndex(partnerId: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersActionfeesIndex.');
            }
            const localVarPath = `/partners/{partner_id}/actionfees`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerActionResource} partnerActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersActionsCreate(partnerId: string, partnerActionResource: PartnerActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersActionsCreate.');
            }
            // verify required parameter 'partnerActionResource' is not null or undefined
            if (partnerActionResource === null || partnerActionResource === undefined) {
                throw new RequiredError('partnerActionResource','Required parameter partnerActionResource was null or undefined when calling partnersActionsCreate.');
            }
            const localVarPath = `/partners/{partner_id}/actions`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerActionResource || {}) : (partnerActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [partnerId] Filter partners by partner ID
         * @param {string} [state] States for the partners.
         * @param {boolean} [isEnabled]
         * @param {string} [employeeId] Employee id of the partners to filter by.
         * @param {string} [geoCode] Geographic Location Code.
         * @param {string} [satisfaction] Partner Satisfaction Ratio.
         * @param {string} [punchesUseSpeed] Punches use speed.
         * @param {string} [refillOfferId] Filter the partners by their refill offers
         * @param {string} [industryId] Filter the partners by their industry ids
         * @param {string} [industry] Filter the partners by their industry identifiers
         * @param {string} [geoPoint] Location Point to sort partners by
         * @param {string} [geoRect] Location Points to define an area to filter partners by. Provide the bottom left (southwest) and top right (northeast) corners of the reactangle separated by commas, e.g. \&quot;52.19,12.42,52.85,14.22\&quot;.
         * @param {number} [geoDistance] Location filter by distance
         * @param {'id' | 'relevance' | 'created_at' | 'company_name' | 'state' | 'satisfaction' | 'employee_name' | 'punches_monthly' | 'punches_use_speed' | 'last_login' | 'last_lead_bought' | 'revenue_6months' | 'last_invoice' | 'avg_punch_price_6months' | 'avg_due_days_6months' | 'last_coupon_size' | 'punches_left' | 'punches_spent_monthly' | 'punches_spent_6months' | 'punches_bought_6months' | 'golden_ratio_6months' | 'refill' | 'rating' | 'distance' | 'last_phone_call' | 'canvas_status' | 'address' | 'tasks' | 'last_subscription_start_date' | 'last_published_quote' | 'published_quotes' | 'win_ratio' | 'unmatched' | 'unmatched_percent' | 'digital_leads' | 'has_active_contract' | 'deallocations' | 'overdue_amount' | 'active_contracts_count' | 'active_allocation_pauses_count' | 'active_contracts_amount' | 'next_contract_renewal_at' | 'active_business_units_amount' | 'active_business_units_mrr' | 'current_period_weighted_delivery_trend' | 'success_ratio' | 'publishing_ratio' | 'all_time_purchased_marketplace_leads_value_excl_vat_local_currency' | 'all_time_purchased_marketplace_leads_count' | 'lead_marketplace_partner_spend_excl_vat_local_currency' | 'ascending' | 'descending'} [sort] Sort Partner&#39;s by a given property.
         * @param {string} [canvasStatus] Canvas Status
         * @param {boolean} [isPublic] Only show partners that are visible to the public
         * @param {string} [packageType] Partner subscription package type
         * @param {string} [badgeId]
         * @param {boolean} [digitalLeads]
         * @param {boolean} [hasMarketingPackage]
         * @param {boolean} [hasActiveContract]
         * @param {boolean} [hasUnusedAllocations]
         * @param {boolean} [wantsExclusiveLeads]
         * @param {string} [partnerAssigneeClientConsultantEmployeeId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersAggregationsIndex(partnerId?: string, state?: string, isEnabled?: boolean, employeeId?: string, geoCode?: string, satisfaction?: string, punchesUseSpeed?: string, refillOfferId?: string, industryId?: string, industry?: string, geoPoint?: string, geoRect?: string, geoDistance?: number, sort?: 'id' | 'relevance' | 'created_at' | 'company_name' | 'state' | 'satisfaction' | 'employee_name' | 'punches_monthly' | 'punches_use_speed' | 'last_login' | 'last_lead_bought' | 'revenue_6months' | 'last_invoice' | 'avg_punch_price_6months' | 'avg_due_days_6months' | 'last_coupon_size' | 'punches_left' | 'punches_spent_monthly' | 'punches_spent_6months' | 'punches_bought_6months' | 'golden_ratio_6months' | 'refill' | 'rating' | 'distance' | 'last_phone_call' | 'canvas_status' | 'address' | 'tasks' | 'last_subscription_start_date' | 'last_published_quote' | 'published_quotes' | 'win_ratio' | 'unmatched' | 'unmatched_percent' | 'digital_leads' | 'has_active_contract' | 'deallocations' | 'overdue_amount' | 'active_contracts_count' | 'active_allocation_pauses_count' | 'active_contracts_amount' | 'next_contract_renewal_at' | 'active_business_units_amount' | 'active_business_units_mrr' | 'current_period_weighted_delivery_trend' | 'success_ratio' | 'publishing_ratio' | 'all_time_purchased_marketplace_leads_value_excl_vat_local_currency' | 'all_time_purchased_marketplace_leads_count' | 'lead_marketplace_partner_spend_excl_vat_local_currency' | 'ascending' | 'descending', canvasStatus?: string, isPublic?: boolean, packageType?: string, badgeId?: string, digitalLeads?: boolean, hasMarketingPackage?: boolean, hasActiveContract?: boolean, hasUnusedAllocations?: boolean, wantsExclusiveLeads?: boolean, partnerAssigneeClientConsultantEmployeeId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/partners/aggregations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (isEnabled !== undefined) {
                localVarQueryParameter['is_enabled'] = isEnabled;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employee_id'] = employeeId;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (satisfaction !== undefined) {
                localVarQueryParameter['satisfaction'] = satisfaction;
            }

            if (punchesUseSpeed !== undefined) {
                localVarQueryParameter['punches_use_speed'] = punchesUseSpeed;
            }

            if (refillOfferId !== undefined) {
                localVarQueryParameter['refill_offer_id'] = refillOfferId;
            }

            if (industryId !== undefined) {
                localVarQueryParameter['industry_id'] = industryId;
            }

            if (industry !== undefined) {
                localVarQueryParameter['industry'] = industry;
            }

            if (geoPoint !== undefined) {
                localVarQueryParameter['geo_point'] = geoPoint;
            }

            if (geoRect !== undefined) {
                localVarQueryParameter['geo_rect'] = geoRect;
            }

            if (geoDistance !== undefined) {
                localVarQueryParameter['geo_distance'] = geoDistance;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (canvasStatus !== undefined) {
                localVarQueryParameter['canvas_status'] = canvasStatus;
            }

            if (isPublic !== undefined) {
                localVarQueryParameter['is_public'] = isPublic;
            }

            if (packageType !== undefined) {
                localVarQueryParameter['package_type'] = packageType;
            }

            if (badgeId !== undefined) {
                localVarQueryParameter['badge_id'] = badgeId;
            }

            if (digitalLeads !== undefined) {
                localVarQueryParameter['digital_leads'] = digitalLeads;
            }

            if (hasMarketingPackage !== undefined) {
                localVarQueryParameter['has_marketing_package'] = hasMarketingPackage;
            }

            if (hasActiveContract !== undefined) {
                localVarQueryParameter['has_active_contract'] = hasActiveContract;
            }

            if (hasUnusedAllocations !== undefined) {
                localVarQueryParameter['has_unused_allocations'] = hasUnusedAllocations;
            }

            if (wantsExclusiveLeads !== undefined) {
                localVarQueryParameter['wants_exclusive_leads'] = wantsExclusiveLeads;
            }

            if (partnerAssigneeClientConsultantEmployeeId !== undefined) {
                localVarQueryParameter['partner_assignee_client_consultant_employee_id'] = partnerAssigneeClientConsultantEmployeeId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerAllocationBoostResource} partnerAllocationBoostResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersAllocationboostsCreate(partnerId: string, partnerAllocationBoostResource: PartnerAllocationBoostResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersAllocationboostsCreate.');
            }
            // verify required parameter 'partnerAllocationBoostResource' is not null or undefined
            if (partnerAllocationBoostResource === null || partnerAllocationBoostResource === undefined) {
                throw new RequiredError('partnerAllocationBoostResource','Required parameter partnerAllocationBoostResource was null or undefined when calling partnersAllocationboostsCreate.');
            }
            const localVarPath = `/partners/{partner_id}/allocationboosts`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerAllocationBoostResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerAllocationBoostResource || {}) : (partnerAllocationBoostResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [processAtGte] Get invoicings processed at after a date.
         * @param {string} [processAtLte] Get invoicings processed at after a date.
         * @param {string} [partnerId] Filter by zero or more partner IDs.
         * @param {'process_at' | 'ascending' | 'descending'} [sort] Sort the invoicings.
         * @param {string} [geoCode] Filter by geo codes.
         * @param {string} [employeeId] Filter by partner&#39;s employee.
         * @param {string} [revenueSegmentId] Filter by revenue segment ID.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersAllocationdeliveriesIndex(processAtGte?: string, processAtLte?: string, partnerId?: string, sort?: 'process_at' | 'ascending' | 'descending', geoCode?: string, employeeId?: string, revenueSegmentId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/partners/allocationdeliveries`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (processAtGte !== undefined) {
                localVarQueryParameter['process_at_gte'] = processAtGte;
            }

            if (processAtLte !== undefined) {
                localVarQueryParameter['process_at_lte'] = processAtLte;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employee_id'] = employeeId;
            }

            if (revenueSegmentId !== undefined) {
                localVarQueryParameter['revenue_segment_id'] = revenueSegmentId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [processAtGte] Get invoicings processed at after a date.
         * @param {string} [processAtLte] Get invoicings processed at after a date.
         * @param {'process_at' | 'ascending' | 'descending'} [sort] Sort the invoicings.
         * @param {string} [geoCode] Filter by geo codes.
         * @param {string} [employeeId] Filter by partner&#39;s employee.
         * @param {string} [revenueSegmentId] Filter by revenue segment ID.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersAllocationdeliveriesIndex_1(partnerId: string, processAtGte?: string, processAtLte?: string, sort?: 'process_at' | 'ascending' | 'descending', geoCode?: string, employeeId?: string, revenueSegmentId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersAllocationdeliveriesIndex_1.');
            }
            const localVarPath = `/partners/{partner_id}/allocationdeliveries`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (processAtGte !== undefined) {
                localVarQueryParameter['process_at_gte'] = processAtGte;
            }

            if (processAtLte !== undefined) {
                localVarQueryParameter['process_at_lte'] = processAtLte;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employee_id'] = employeeId;
            }

            if (revenueSegmentId !== undefined) {
                localVarQueryParameter['revenue_segment_id'] = revenueSegmentId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerAllocationPauseResource} partnerAllocationPauseResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersAllocationpausesCreate(partnerId: string, partnerAllocationPauseResource: PartnerAllocationPauseResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersAllocationpausesCreate.');
            }
            // verify required parameter 'partnerAllocationPauseResource' is not null or undefined
            if (partnerAllocationPauseResource === null || partnerAllocationPauseResource === undefined) {
                throw new RequiredError('partnerAllocationPauseResource','Required parameter partnerAllocationPauseResource was null or undefined when calling partnersAllocationpausesCreate.');
            }
            const localVarPath = `/partners/{partner_id}/allocationpauses`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerAllocationPauseResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerAllocationPauseResource || {}) : (partnerAllocationPauseResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerAssigneeResource} partnerAssigneeResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersAssigneesCreate(partnerId: string, partnerAssigneeResource: PartnerAssigneeResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersAssigneesCreate.');
            }
            // verify required parameter 'partnerAssigneeResource' is not null or undefined
            if (partnerAssigneeResource === null || partnerAssigneeResource === undefined) {
                throw new RequiredError('partnerAssigneeResource','Required parameter partnerAssigneeResource was null or undefined when calling partnersAssigneesCreate.');
            }
            const localVarPath = `/partners/{partner_id}/assignees`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerAssigneeResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerAssigneeResource || {}) : (partnerAssigneeResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerAssigneeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersAssigneesDelete(partnerId: string, partnerAssigneeId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersAssigneesDelete.');
            }
            // verify required parameter 'partnerAssigneeId' is not null or undefined
            if (partnerAssigneeId === null || partnerAssigneeId === undefined) {
                throw new RequiredError('partnerAssigneeId','Required parameter partnerAssigneeId was null or undefined when calling partnersAssigneesDelete.');
            }
            const localVarPath = `/partners/{partner_id}/assignees/{partner_assignee_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_assignee_id"}}`, encodeURIComponent(String(partnerAssigneeId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {'all' | 'client_consultant' | 'account_manager'} [roles]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersAssigneesIndex(partnerId: string, roles?: 'all' | 'client_consultant' | 'account_manager', limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersAssigneesIndex.');
            }
            const localVarPath = `/partners/{partner_id}/assignees`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (roles !== undefined) {
                localVarQueryParameter['roles'] = roles;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerBankaccountResource} partnerBankaccountResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBankaccountsCreate(partnerId: string, partnerBankaccountResource: PartnerBankaccountResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBankaccountsCreate.');
            }
            // verify required parameter 'partnerBankaccountResource' is not null or undefined
            if (partnerBankaccountResource === null || partnerBankaccountResource === undefined) {
                throw new RequiredError('partnerBankaccountResource','Required parameter partnerBankaccountResource was null or undefined when calling partnersBankaccountsCreate.');
            }
            const localVarPath = `/partners/{partner_id}/bankaccounts`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerBankaccountResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerBankaccountResource || {}) : (partnerBankaccountResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBankaccountsIndex(partnerId: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBankaccountsIndex.');
            }
            const localVarPath = `/partners/{partner_id}/bankaccounts`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {BrowserPushSubscriptionResource} browserPushSubscriptionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBrowserpushsubscriptionsCreate(partnerId: string, browserPushSubscriptionResource: BrowserPushSubscriptionResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBrowserpushsubscriptionsCreate.');
            }
            // verify required parameter 'browserPushSubscriptionResource' is not null or undefined
            if (browserPushSubscriptionResource === null || browserPushSubscriptionResource === undefined) {
                throw new RequiredError('browserPushSubscriptionResource','Required parameter browserPushSubscriptionResource was null or undefined when calling partnersBrowserpushsubscriptionsCreate.');
            }
            const localVarPath = `/partners/{partner_id}/browserpushsubscriptions`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BrowserPushSubscriptionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(browserPushSubscriptionResource || {}) : (browserPushSubscriptionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerBusinessUnitContractResource} partnerBusinessUnitContractResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitcontractsCreate(partnerId: string, partnerBusinessUnitContractResource: PartnerBusinessUnitContractResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBusinessunitcontractsCreate.');
            }
            // verify required parameter 'partnerBusinessUnitContractResource' is not null or undefined
            if (partnerBusinessUnitContractResource === null || partnerBusinessUnitContractResource === undefined) {
                throw new RequiredError('partnerBusinessUnitContractResource','Required parameter partnerBusinessUnitContractResource was null or undefined when calling partnersBusinessunitcontractsCreate.');
            }
            const localVarPath = `/partners/{partner_id}/businessunitcontracts`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerBusinessUnitContractResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerBusinessUnitContractResource || {}) : (partnerBusinessUnitContractResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitcontractsGet(partnerId: string, contractId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBusinessunitcontractsGet.');
            }
            // verify required parameter 'contractId' is not null or undefined
            if (contractId === null || contractId === undefined) {
                throw new RequiredError('contractId','Required parameter contractId was null or undefined when calling partnersBusinessunitcontractsGet.');
            }
            const localVarPath = `/partners/{partner_id}/businessunitcontracts/{contract_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"contract_id"}}`, encodeURIComponent(String(contractId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitcontractsIndex(partnerId: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBusinessunitcontractsIndex.');
            }
            const localVarPath = `/partners/{partner_id}/businessunitcontracts`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {LeadPartnerAllocationLimitResource} leadPartnerAllocationLimitResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsAllocationlimitsCreate(partnerId: string, partnerBusinessUnitId: string, leadPartnerAllocationLimitResource: LeadPartnerAllocationLimitResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBusinessunitsAllocationlimitsCreate.');
            }
            // verify required parameter 'partnerBusinessUnitId' is not null or undefined
            if (partnerBusinessUnitId === null || partnerBusinessUnitId === undefined) {
                throw new RequiredError('partnerBusinessUnitId','Required parameter partnerBusinessUnitId was null or undefined when calling partnersBusinessunitsAllocationlimitsCreate.');
            }
            // verify required parameter 'leadPartnerAllocationLimitResource' is not null or undefined
            if (leadPartnerAllocationLimitResource === null || leadPartnerAllocationLimitResource === undefined) {
                throw new RequiredError('leadPartnerAllocationLimitResource','Required parameter leadPartnerAllocationLimitResource was null or undefined when calling partnersBusinessunitsAllocationlimitsCreate.');
            }
            const localVarPath = `/partners/{partner_id}/businessunits/{partner_business_unit_id}/allocationlimits`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_business_unit_id"}}`, encodeURIComponent(String(partnerBusinessUnitId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LeadPartnerAllocationLimitResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(leadPartnerAllocationLimitResource || {}) : (leadPartnerAllocationLimitResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {string} limitId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsAllocationlimitsDelete(partnerId: string, partnerBusinessUnitId: string, limitId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBusinessunitsAllocationlimitsDelete.');
            }
            // verify required parameter 'partnerBusinessUnitId' is not null or undefined
            if (partnerBusinessUnitId === null || partnerBusinessUnitId === undefined) {
                throw new RequiredError('partnerBusinessUnitId','Required parameter partnerBusinessUnitId was null or undefined when calling partnersBusinessunitsAllocationlimitsDelete.');
            }
            // verify required parameter 'limitId' is not null or undefined
            if (limitId === null || limitId === undefined) {
                throw new RequiredError('limitId','Required parameter limitId was null or undefined when calling partnersBusinessunitsAllocationlimitsDelete.');
            }
            const localVarPath = `/partners/{partner_id}/businessunits/{partner_business_unit_id}/allocationlimits/{limit_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_business_unit_id"}}`, encodeURIComponent(String(partnerBusinessUnitId)))
                .replace(`{${"limit_id"}}`, encodeURIComponent(String(limitId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [effectiveAtGte]
         * @param {string} [effectiveAtLte]
         * @param {string} [partnerBusinessUnitId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsAllocationlimitsIndex(partnerId: string, effectiveAtGte?: string, effectiveAtLte?: string, partnerBusinessUnitId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBusinessunitsAllocationlimitsIndex.');
            }
            const localVarPath = `/partners/{partner_id}/businessunits/allocationlimits`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (effectiveAtGte !== undefined) {
                localVarQueryParameter['effective_at_gte'] = effectiveAtGte;
            }

            if (effectiveAtLte !== undefined) {
                localVarQueryParameter['effective_at_lte'] = effectiveAtLte;
            }

            if (partnerBusinessUnitId !== undefined) {
                localVarQueryParameter['partner_business_unit_id'] = partnerBusinessUnitId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {string} limitId
         * @param {LeadPartnerAllocationLimitResource} leadPartnerAllocationLimitResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsAllocationlimitsUpdate(partnerId: string, partnerBusinessUnitId: string, limitId: string, leadPartnerAllocationLimitResource: LeadPartnerAllocationLimitResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBusinessunitsAllocationlimitsUpdate.');
            }
            // verify required parameter 'partnerBusinessUnitId' is not null or undefined
            if (partnerBusinessUnitId === null || partnerBusinessUnitId === undefined) {
                throw new RequiredError('partnerBusinessUnitId','Required parameter partnerBusinessUnitId was null or undefined when calling partnersBusinessunitsAllocationlimitsUpdate.');
            }
            // verify required parameter 'limitId' is not null or undefined
            if (limitId === null || limitId === undefined) {
                throw new RequiredError('limitId','Required parameter limitId was null or undefined when calling partnersBusinessunitsAllocationlimitsUpdate.');
            }
            // verify required parameter 'leadPartnerAllocationLimitResource' is not null or undefined
            if (leadPartnerAllocationLimitResource === null || leadPartnerAllocationLimitResource === undefined) {
                throw new RequiredError('leadPartnerAllocationLimitResource','Required parameter leadPartnerAllocationLimitResource was null or undefined when calling partnersBusinessunitsAllocationlimitsUpdate.');
            }
            const localVarPath = `/partners/{partner_id}/businessunits/{partner_business_unit_id}/allocationlimits/{limit_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_business_unit_id"}}`, encodeURIComponent(String(partnerBusinessUnitId)))
                .replace(`{${"limit_id"}}`, encodeURIComponent(String(limitId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LeadPartnerAllocationLimitResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(leadPartnerAllocationLimitResource || {}) : (leadPartnerAllocationLimitResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {LeadAllocationQuotaResource} leadAllocationQuotaResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsAllocationquotasCreate(partnerId: string, partnerBusinessUnitId: string, leadAllocationQuotaResource: LeadAllocationQuotaResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBusinessunitsAllocationquotasCreate.');
            }
            // verify required parameter 'partnerBusinessUnitId' is not null or undefined
            if (partnerBusinessUnitId === null || partnerBusinessUnitId === undefined) {
                throw new RequiredError('partnerBusinessUnitId','Required parameter partnerBusinessUnitId was null or undefined when calling partnersBusinessunitsAllocationquotasCreate.');
            }
            // verify required parameter 'leadAllocationQuotaResource' is not null or undefined
            if (leadAllocationQuotaResource === null || leadAllocationQuotaResource === undefined) {
                throw new RequiredError('leadAllocationQuotaResource','Required parameter leadAllocationQuotaResource was null or undefined when calling partnersBusinessunitsAllocationquotasCreate.');
            }
            const localVarPath = `/partners/{partner_id}/businessunits/{partner_business_unit_id}/allocationquotas`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_business_unit_id"}}`, encodeURIComponent(String(partnerBusinessUnitId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LeadAllocationQuotaResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(leadAllocationQuotaResource || {}) : (leadAllocationQuotaResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {string} quotaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsAllocationquotasDelete(partnerId: string, partnerBusinessUnitId: string, quotaId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBusinessunitsAllocationquotasDelete.');
            }
            // verify required parameter 'partnerBusinessUnitId' is not null or undefined
            if (partnerBusinessUnitId === null || partnerBusinessUnitId === undefined) {
                throw new RequiredError('partnerBusinessUnitId','Required parameter partnerBusinessUnitId was null or undefined when calling partnersBusinessunitsAllocationquotasDelete.');
            }
            // verify required parameter 'quotaId' is not null or undefined
            if (quotaId === null || quotaId === undefined) {
                throw new RequiredError('quotaId','Required parameter quotaId was null or undefined when calling partnersBusinessunitsAllocationquotasDelete.');
            }
            const localVarPath = `/partners/{partner_id}/businessunits/{partner_business_unit_id}/allocationquotas/{quota_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_business_unit_id"}}`, encodeURIComponent(String(partnerBusinessUnitId)))
                .replace(`{${"quota_id"}}`, encodeURIComponent(String(quotaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [effectiveAtGte]
         * @param {string} [effectiveAtLte]
         * @param {string} [partnerBusinessUnitId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsAllocationquotasIndex(partnerId: string, effectiveAtGte?: string, effectiveAtLte?: string, partnerBusinessUnitId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBusinessunitsAllocationquotasIndex.');
            }
            const localVarPath = `/partners/{partner_id}/businessunits/allocationquotas`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (effectiveAtGte !== undefined) {
                localVarQueryParameter['effective_at_gte'] = effectiveAtGte;
            }

            if (effectiveAtLte !== undefined) {
                localVarQueryParameter['effective_at_lte'] = effectiveAtLte;
            }

            if (partnerBusinessUnitId !== undefined) {
                localVarQueryParameter['partner_business_unit_id'] = partnerBusinessUnitId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerBusinessUnitResource} partnerBusinessUnitResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsCreate(partnerId: string, partnerBusinessUnitResource: PartnerBusinessUnitResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBusinessunitsCreate.');
            }
            // verify required parameter 'partnerBusinessUnitResource' is not null or undefined
            if (partnerBusinessUnitResource === null || partnerBusinessUnitResource === undefined) {
                throw new RequiredError('partnerBusinessUnitResource','Required parameter partnerBusinessUnitResource was null or undefined when calling partnersBusinessunitsCreate.');
            }
            const localVarPath = `/partners/{partner_id}/businessunits`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerBusinessUnitResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerBusinessUnitResource || {}) : (partnerBusinessUnitResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessunitId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsDelete(partnerId: string, partnerBusinessunitId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBusinessunitsDelete.');
            }
            // verify required parameter 'partnerBusinessunitId' is not null or undefined
            if (partnerBusinessunitId === null || partnerBusinessunitId === undefined) {
                throw new RequiredError('partnerBusinessunitId','Required parameter partnerBusinessunitId was null or undefined when calling partnersBusinessunitsDelete.');
            }
            const localVarPath = `/partners/{partner_id}/businessunits/{partner_businessunit_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_businessunit_id"}}`, encodeURIComponent(String(partnerBusinessunitId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsGet(partnerId: string, partnerBusinessUnitId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBusinessunitsGet.');
            }
            // verify required parameter 'partnerBusinessUnitId' is not null or undefined
            if (partnerBusinessUnitId === null || partnerBusinessUnitId === undefined) {
                throw new RequiredError('partnerBusinessUnitId','Required parameter partnerBusinessUnitId was null or undefined when calling partnersBusinessunitsGet.');
            }
            const localVarPath = `/partners/{partner_id}/businessunits/{partner_business_unit_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_business_unit_id"}}`, encodeURIComponent(String(partnerBusinessUnitId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [id] Primary ID.
         * @param {string} [partnerId] Partner ID.
         * @param {string} [geoCode] E.g. &#39;dk&#39;.
         * @param {string} [segmentationCriteria] Which criteria to segment on, e.g. language.
         * @param {number} [segmentedForLeadId] List business units that are segmented for this lead.
         * @param {boolean} [isActive] List business units that are active or inactive.
         * @param {'id' | 'allocation_fulfillment_percentage' | 'allocation_fulfillment_since_start_percentage' | 'allocation_fulfillment_forecast_percentage' | 'pay_per_spot_allocation_fulfillment_percentage' | 'pay_per_spot_allocation_fulfillment_forecast_percentage' | 'normal_quota_spot_allocation_fulfillment_percentage' | 'normal_quota_allocation_fulfillment_forecast_percentage' | 'auto_allocation' | 'partner_rating' | 'partner_rating_count' | 'ascending' | 'descending'} [sort] Sort criterion
         * @param {string} [geoPoint] Central point to search from.
         * @param {number} [geoDistance] Range in KM to search from geo_point.
         * @param {string} [assignedEmployeeId] Filter by assigned employee ID.
         * @param {string} [sectorId] Filter by sector IDs.
         * @param {string} [industryId] Filter by industry IDs.
         * @param {string} [clientTypeId] Filter by client type IDs.
         * @param {string} [revenueAmount] Find only business units with a revenue range that includes this amount.
         * @param {boolean} [hasMarketingPackage] Filter business units based on whether or not they have a marketing package.
         * @param {boolean} [hasBusinessModel] Filter business units based on whether or not they have any business model.
         * @param {string} [subGeoRegionId] Filter business units by sub geo region IDs.
         * @param {boolean} [isEligibleForAllocation] Filter business units based on whether they are eligible for allocation.
         * @param {string} [primaryIndustryId] The business unit&#39;s primary industry, e.g. law or accounting.
         * @param {number} [minimumPartnerRating] Minimum request partner rating.
         * @param {'none' | 'partner_association_id' | 'partner_id'} [collapse] Collapse business units based on identical values in specified field.
         * @param {string} [segmentedForCertificationId]
         * @param {string} [segmentedForIndustryId]
         * @param {string} [segmentedForClientTypeId]
         * @param {boolean} [isLeadBudget]
         * @param {boolean} [hasUnusedAllocations]
         * @param {boolean} [hasPausedContract]
         * @param {boolean} [hasActiveAccess]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsIndex(id?: string, partnerId?: string, geoCode?: string, segmentationCriteria?: string, segmentedForLeadId?: number, isActive?: boolean, sort?: 'id' | 'allocation_fulfillment_percentage' | 'allocation_fulfillment_since_start_percentage' | 'allocation_fulfillment_forecast_percentage' | 'pay_per_spot_allocation_fulfillment_percentage' | 'pay_per_spot_allocation_fulfillment_forecast_percentage' | 'normal_quota_spot_allocation_fulfillment_percentage' | 'normal_quota_allocation_fulfillment_forecast_percentage' | 'auto_allocation' | 'partner_rating' | 'partner_rating_count' | 'ascending' | 'descending', geoPoint?: string, geoDistance?: number, assignedEmployeeId?: string, sectorId?: string, industryId?: string, clientTypeId?: string, revenueAmount?: string, hasMarketingPackage?: boolean, hasBusinessModel?: boolean, subGeoRegionId?: string, isEligibleForAllocation?: boolean, primaryIndustryId?: string, minimumPartnerRating?: number, collapse?: 'none' | 'partner_association_id' | 'partner_id', segmentedForCertificationId?: string, segmentedForIndustryId?: string, segmentedForClientTypeId?: string, isLeadBudget?: boolean, hasUnusedAllocations?: boolean, hasPausedContract?: boolean, hasActiveAccess?: boolean, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/partners/businessunits`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (segmentationCriteria !== undefined) {
                localVarQueryParameter['segmentation_criteria'] = segmentationCriteria;
            }

            if (segmentedForLeadId !== undefined) {
                localVarQueryParameter['segmented_for_lead_id'] = segmentedForLeadId;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (geoPoint !== undefined) {
                localVarQueryParameter['geo_point'] = geoPoint;
            }

            if (geoDistance !== undefined) {
                localVarQueryParameter['geo_distance'] = geoDistance;
            }

            if (assignedEmployeeId !== undefined) {
                localVarQueryParameter['assigned_employee_id'] = assignedEmployeeId;
            }

            if (sectorId !== undefined) {
                localVarQueryParameter['sector_id'] = sectorId;
            }

            if (industryId !== undefined) {
                localVarQueryParameter['industry_id'] = industryId;
            }

            if (clientTypeId !== undefined) {
                localVarQueryParameter['client_type_id'] = clientTypeId;
            }

            if (revenueAmount !== undefined) {
                localVarQueryParameter['revenue_amount'] = revenueAmount;
            }

            if (hasMarketingPackage !== undefined) {
                localVarQueryParameter['has_marketing_package'] = hasMarketingPackage;
            }

            if (hasBusinessModel !== undefined) {
                localVarQueryParameter['has_business_model'] = hasBusinessModel;
            }

            if (subGeoRegionId !== undefined) {
                localVarQueryParameter['sub_geo_region_id'] = subGeoRegionId;
            }

            if (isEligibleForAllocation !== undefined) {
                localVarQueryParameter['is_eligible_for_allocation'] = isEligibleForAllocation;
            }

            if (primaryIndustryId !== undefined) {
                localVarQueryParameter['primary_industry_id'] = primaryIndustryId;
            }

            if (minimumPartnerRating !== undefined) {
                localVarQueryParameter['minimum_partner_rating'] = minimumPartnerRating;
            }

            if (collapse !== undefined) {
                localVarQueryParameter['collapse'] = collapse;
            }

            if (segmentedForCertificationId !== undefined) {
                localVarQueryParameter['segmented_for_certification_id'] = segmentedForCertificationId;
            }

            if (segmentedForIndustryId !== undefined) {
                localVarQueryParameter['segmented_for_industry_id'] = segmentedForIndustryId;
            }

            if (segmentedForClientTypeId !== undefined) {
                localVarQueryParameter['segmented_for_client_type_id'] = segmentedForClientTypeId;
            }

            if (isLeadBudget !== undefined) {
                localVarQueryParameter['is_lead_budget'] = isLeadBudget;
            }

            if (hasUnusedAllocations !== undefined) {
                localVarQueryParameter['has_unused_allocations'] = hasUnusedAllocations;
            }

            if (hasPausedContract !== undefined) {
                localVarQueryParameter['has_paused_contract'] = hasPausedContract;
            }

            if (hasActiveAccess !== undefined) {
                localVarQueryParameter['has_active_access'] = hasActiveAccess;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {boolean} [isActive] Filter active industries.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsIndustriesIndex(partnerId: string, partnerBusinessUnitId: string, isActive?: boolean, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBusinessunitsIndustriesIndex.');
            }
            // verify required parameter 'partnerBusinessUnitId' is not null or undefined
            if (partnerBusinessUnitId === null || partnerBusinessUnitId === undefined) {
                throw new RequiredError('partnerBusinessUnitId','Required parameter partnerBusinessUnitId was null or undefined when calling partnersBusinessunitsIndustriesIndex.');
            }
            const localVarPath = `/partners/{partner_id}/businessunits/{partner_business_unit_id}/industries`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_business_unit_id"}}`, encodeURIComponent(String(partnerBusinessUnitId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {PartnerBusinessUnitIndustriesResource} partnerBusinessUnitIndustriesResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsIndustriesUpdate(partnerId: string, partnerBusinessUnitId: string, partnerBusinessUnitIndustriesResource: PartnerBusinessUnitIndustriesResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBusinessunitsIndustriesUpdate.');
            }
            // verify required parameter 'partnerBusinessUnitId' is not null or undefined
            if (partnerBusinessUnitId === null || partnerBusinessUnitId === undefined) {
                throw new RequiredError('partnerBusinessUnitId','Required parameter partnerBusinessUnitId was null or undefined when calling partnersBusinessunitsIndustriesUpdate.');
            }
            // verify required parameter 'partnerBusinessUnitIndustriesResource' is not null or undefined
            if (partnerBusinessUnitIndustriesResource === null || partnerBusinessUnitIndustriesResource === undefined) {
                throw new RequiredError('partnerBusinessUnitIndustriesResource','Required parameter partnerBusinessUnitIndustriesResource was null or undefined when calling partnersBusinessunitsIndustriesUpdate.');
            }
            const localVarPath = `/partners/{partner_id}/businessunits/{partner_business_unit_id}/industries`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_business_unit_id"}}`, encodeURIComponent(String(partnerBusinessUnitId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerBusinessUnitIndustriesResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerBusinessUnitIndustriesResource || {}) : (partnerBusinessUnitIndustriesResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {PartnerBusinessUnitRevenueRangeResource} partnerBusinessUnitRevenueRangeResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsRevenuerangesCreate(partnerId: string, partnerBusinessUnitId: string, partnerBusinessUnitRevenueRangeResource: PartnerBusinessUnitRevenueRangeResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBusinessunitsRevenuerangesCreate.');
            }
            // verify required parameter 'partnerBusinessUnitId' is not null or undefined
            if (partnerBusinessUnitId === null || partnerBusinessUnitId === undefined) {
                throw new RequiredError('partnerBusinessUnitId','Required parameter partnerBusinessUnitId was null or undefined when calling partnersBusinessunitsRevenuerangesCreate.');
            }
            // verify required parameter 'partnerBusinessUnitRevenueRangeResource' is not null or undefined
            if (partnerBusinessUnitRevenueRangeResource === null || partnerBusinessUnitRevenueRangeResource === undefined) {
                throw new RequiredError('partnerBusinessUnitRevenueRangeResource','Required parameter partnerBusinessUnitRevenueRangeResource was null or undefined when calling partnersBusinessunitsRevenuerangesCreate.');
            }
            const localVarPath = `/partners/{partner_id}/businessunits/{partner_business_unit_id}/revenueranges`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_business_unit_id"}}`, encodeURIComponent(String(partnerBusinessUnitId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerBusinessUnitRevenueRangeResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerBusinessUnitRevenueRangeResource || {}) : (partnerBusinessUnitRevenueRangeResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsSectorsIndex(partnerId: string, partnerBusinessUnitId: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBusinessunitsSectorsIndex.');
            }
            // verify required parameter 'partnerBusinessUnitId' is not null or undefined
            if (partnerBusinessUnitId === null || partnerBusinessUnitId === undefined) {
                throw new RequiredError('partnerBusinessUnitId','Required parameter partnerBusinessUnitId was null or undefined when calling partnersBusinessunitsSectorsIndex.');
            }
            const localVarPath = `/partners/{partner_id}/businessunits/{partner_business_unit_id}/sectors`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_business_unit_id"}}`, encodeURIComponent(String(partnerBusinessUnitId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {PartnerSectorsResource} partnerSectorsResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsSectorsUpdate(partnerId: string, partnerBusinessUnitId: string, partnerSectorsResource: PartnerSectorsResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBusinessunitsSectorsUpdate.');
            }
            // verify required parameter 'partnerBusinessUnitId' is not null or undefined
            if (partnerBusinessUnitId === null || partnerBusinessUnitId === undefined) {
                throw new RequiredError('partnerBusinessUnitId','Required parameter partnerBusinessUnitId was null or undefined when calling partnersBusinessunitsSectorsUpdate.');
            }
            // verify required parameter 'partnerSectorsResource' is not null or undefined
            if (partnerSectorsResource === null || partnerSectorsResource === undefined) {
                throw new RequiredError('partnerSectorsResource','Required parameter partnerSectorsResource was null or undefined when calling partnersBusinessunitsSectorsUpdate.');
            }
            const localVarPath = `/partners/{partner_id}/businessunits/{partner_business_unit_id}/sectors`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_business_unit_id"}}`, encodeURIComponent(String(partnerBusinessUnitId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerSectorsResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerSectorsResource || {}) : (partnerSectorsResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsSegmentationgeoregionsIndex(partnerId: string, partnerBusinessUnitId: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBusinessunitsSegmentationgeoregionsIndex.');
            }
            // verify required parameter 'partnerBusinessUnitId' is not null or undefined
            if (partnerBusinessUnitId === null || partnerBusinessUnitId === undefined) {
                throw new RequiredError('partnerBusinessUnitId','Required parameter partnerBusinessUnitId was null or undefined when calling partnersBusinessunitsSegmentationgeoregionsIndex.');
            }
            const localVarPath = `/partners/{partner_id}/businessunits/{partner_business_unit_id}/segmentationgeoregions`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_business_unit_id"}}`, encodeURIComponent(String(partnerBusinessUnitId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {PartnerBusinessUnitSegmentationGeoRegionsResource} partnerBusinessUnitSegmentationGeoRegionsResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsSegmentationgeoregionsUpdate(partnerId: string, partnerBusinessUnitId: string, partnerBusinessUnitSegmentationGeoRegionsResource: PartnerBusinessUnitSegmentationGeoRegionsResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBusinessunitsSegmentationgeoregionsUpdate.');
            }
            // verify required parameter 'partnerBusinessUnitId' is not null or undefined
            if (partnerBusinessUnitId === null || partnerBusinessUnitId === undefined) {
                throw new RequiredError('partnerBusinessUnitId','Required parameter partnerBusinessUnitId was null or undefined when calling partnersBusinessunitsSegmentationgeoregionsUpdate.');
            }
            // verify required parameter 'partnerBusinessUnitSegmentationGeoRegionsResource' is not null or undefined
            if (partnerBusinessUnitSegmentationGeoRegionsResource === null || partnerBusinessUnitSegmentationGeoRegionsResource === undefined) {
                throw new RequiredError('partnerBusinessUnitSegmentationGeoRegionsResource','Required parameter partnerBusinessUnitSegmentationGeoRegionsResource was null or undefined when calling partnersBusinessunitsSegmentationgeoregionsUpdate.');
            }
            const localVarPath = `/partners/{partner_id}/businessunits/{partner_business_unit_id}/segmentationgeoregions`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_business_unit_id"}}`, encodeURIComponent(String(partnerBusinessUnitId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerBusinessUnitSegmentationGeoRegionsResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerBusinessUnitSegmentationGeoRegionsResource || {}) : (partnerBusinessUnitSegmentationGeoRegionsResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsTypesIndex(partnerId: string, partnerBusinessUnitId: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBusinessunitsTypesIndex.');
            }
            // verify required parameter 'partnerBusinessUnitId' is not null or undefined
            if (partnerBusinessUnitId === null || partnerBusinessUnitId === undefined) {
                throw new RequiredError('partnerBusinessUnitId','Required parameter partnerBusinessUnitId was null or undefined when calling partnersBusinessunitsTypesIndex.');
            }
            const localVarPath = `/partners/{partner_id}/businessunits/{partner_business_unit_id}/types`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_business_unit_id"}}`, encodeURIComponent(String(partnerBusinessUnitId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {PartnerBusinessUnitTypesResource} partnerBusinessUnitTypesResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsTypesUpdate(partnerId: string, partnerBusinessUnitId: string, partnerBusinessUnitTypesResource: PartnerBusinessUnitTypesResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBusinessunitsTypesUpdate.');
            }
            // verify required parameter 'partnerBusinessUnitId' is not null or undefined
            if (partnerBusinessUnitId === null || partnerBusinessUnitId === undefined) {
                throw new RequiredError('partnerBusinessUnitId','Required parameter partnerBusinessUnitId was null or undefined when calling partnersBusinessunitsTypesUpdate.');
            }
            // verify required parameter 'partnerBusinessUnitTypesResource' is not null or undefined
            if (partnerBusinessUnitTypesResource === null || partnerBusinessUnitTypesResource === undefined) {
                throw new RequiredError('partnerBusinessUnitTypesResource','Required parameter partnerBusinessUnitTypesResource was null or undefined when calling partnersBusinessunitsTypesUpdate.');
            }
            const localVarPath = `/partners/{partner_id}/businessunits/{partner_business_unit_id}/types`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_business_unit_id"}}`, encodeURIComponent(String(partnerBusinessUnitId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerBusinessUnitTypesResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerBusinessUnitTypesResource || {}) : (partnerBusinessUnitTypesResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {PartnerBusinessUnitResource} partnerBusinessUnitResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsUpdate(partnerId: string, partnerBusinessUnitId: string, partnerBusinessUnitResource: PartnerBusinessUnitResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersBusinessunitsUpdate.');
            }
            // verify required parameter 'partnerBusinessUnitId' is not null or undefined
            if (partnerBusinessUnitId === null || partnerBusinessUnitId === undefined) {
                throw new RequiredError('partnerBusinessUnitId','Required parameter partnerBusinessUnitId was null or undefined when calling partnersBusinessunitsUpdate.');
            }
            // verify required parameter 'partnerBusinessUnitResource' is not null or undefined
            if (partnerBusinessUnitResource === null || partnerBusinessUnitResource === undefined) {
                throw new RequiredError('partnerBusinessUnitResource','Required parameter partnerBusinessUnitResource was null or undefined when calling partnersBusinessunitsUpdate.');
            }
            const localVarPath = `/partners/{partner_id}/businessunits/{partner_business_unit_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_business_unit_id"}}`, encodeURIComponent(String(partnerBusinessUnitId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerBusinessUnitResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerBusinessUnitResource || {}) : (partnerBusinessUnitResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCashierpaymentmethodsGet(partnerId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersCashierpaymentmethodsGet.');
            }
            const localVarPath = `/partners/{partner_id}/cashierpaymentmethods`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {boolean} [isActive] Only include active subscriptions.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCashiersubscriptionsIndex(partnerId: string, isActive?: boolean, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersCashiersubscriptionsIndex.');
            }
            const localVarPath = `/partners/{partner_id}/cashiersubscriptions`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [id] Category id @var int
         * @param {string} [identifier] Category identifier @var string
         * @param {string} [title] Category title. @var string
         * @param {string} [geoCode] Category geo_code. @var string
         * @param {number} [limit] Limit of categories per page. @var int
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCategoriesIndex(id?: string, identifier?: string, title?: string, geoCode?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/partners/categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (identifier !== undefined) {
                localVarQueryParameter['identifier'] = identifier;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {CertificationResource} certificationResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCertificationsCreate(partnerId: string, certificationResource: CertificationResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersCertificationsCreate.');
            }
            // verify required parameter 'certificationResource' is not null or undefined
            if (certificationResource === null || certificationResource === undefined) {
                throw new RequiredError('certificationResource','Required parameter certificationResource was null or undefined when calling partnersCertificationsCreate.');
            }
            const localVarPath = `/partners/{partner_id}/certifications`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CertificationResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(certificationResource || {}) : (certificationResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} certificationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCertificationsDelete(partnerId: string, certificationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersCertificationsDelete.');
            }
            // verify required parameter 'certificationId' is not null or undefined
            if (certificationId === null || certificationId === undefined) {
                throw new RequiredError('certificationId','Required parameter certificationId was null or undefined when calling partnersCertificationsDelete.');
            }
            const localVarPath = `/partners/{partner_id}/certifications/{certification_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"certification_id"}}`, encodeURIComponent(String(certificationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} certificationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCertificationsGet(partnerId: string, certificationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersCertificationsGet.');
            }
            // verify required parameter 'certificationId' is not null or undefined
            if (certificationId === null || certificationId === undefined) {
                throw new RequiredError('certificationId','Required parameter certificationId was null or undefined when calling partnersCertificationsGet.');
            }
            const localVarPath = `/partners/{partner_id}/certifications/{certification_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"certification_id"}}`, encodeURIComponent(String(certificationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [types] Partner type enum. @var array
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCertificationsIndex(partnerId: string, types?: string, geoCode?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersCertificationsIndex.');
            }
            const localVarPath = `/partners/{partner_id}/certifications`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (types !== undefined) {
                localVarQueryParameter['types'] = types;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerCertificationsResource} partnerCertificationsResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCertificationsUpdate(partnerId: string, partnerCertificationsResource: PartnerCertificationsResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersCertificationsUpdate.');
            }
            // verify required parameter 'partnerCertificationsResource' is not null or undefined
            if (partnerCertificationsResource === null || partnerCertificationsResource === undefined) {
                throw new RequiredError('partnerCertificationsResource','Required parameter partnerCertificationsResource was null or undefined when calling partnersCertificationsUpdate.');
            }
            const localVarPath = `/partners/{partner_id}/certifications`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerCertificationsResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerCertificationsResource || {}) : (partnerCertificationsResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contentKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContentsDelete(partnerId: string, contentKey: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersContentsDelete.');
            }
            // verify required parameter 'contentKey' is not null or undefined
            if (contentKey === null || contentKey === undefined) {
                throw new RequiredError('contentKey','Required parameter contentKey was null or undefined when calling partnersContentsDelete.');
            }
            const localVarPath = `/partners/{partner_id}/contents/{content_key}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"content_key"}}`, encodeURIComponent(String(contentKey)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contentKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContentsGet(partnerId: string, contentKey: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersContentsGet.');
            }
            // verify required parameter 'contentKey' is not null or undefined
            if (contentKey === null || contentKey === undefined) {
                throw new RequiredError('contentKey','Required parameter contentKey was null or undefined when calling partnersContentsGet.');
            }
            const localVarPath = `/partners/{partner_id}/contents/{content_key}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"content_key"}}`, encodeURIComponent(String(contentKey)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contentKey
         * @param {PartnerContentResource} partnerContentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContentsUpdate(partnerId: string, contentKey: string, partnerContentResource: PartnerContentResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersContentsUpdate.');
            }
            // verify required parameter 'contentKey' is not null or undefined
            if (contentKey === null || contentKey === undefined) {
                throw new RequiredError('contentKey','Required parameter contentKey was null or undefined when calling partnersContentsUpdate.');
            }
            // verify required parameter 'partnerContentResource' is not null or undefined
            if (partnerContentResource === null || partnerContentResource === undefined) {
                throw new RequiredError('partnerContentResource','Required parameter partnerContentResource was null or undefined when calling partnersContentsUpdate.');
            }
            const localVarPath = `/partners/{partner_id}/contents/{content_key}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"content_key"}}`, encodeURIComponent(String(contentKey)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerContentResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerContentResource || {}) : (partnerContentResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerContractOfferGroupResource} partnerContractOfferGroupResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractoffergroupsCreate(partnerId: string, partnerContractOfferGroupResource: PartnerContractOfferGroupResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersContractoffergroupsCreate.');
            }
            // verify required parameter 'partnerContractOfferGroupResource' is not null or undefined
            if (partnerContractOfferGroupResource === null || partnerContractOfferGroupResource === undefined) {
                throw new RequiredError('partnerContractOfferGroupResource','Required parameter partnerContractOfferGroupResource was null or undefined when calling partnersContractoffergroupsCreate.');
            }
            const localVarPath = `/partners/{partner_id}/contractoffergroups`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerContractOfferGroupResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerContractOfferGroupResource || {}) : (partnerContractOfferGroupResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {boolean} [isArchived]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractoffergroupsIndex(partnerId: string, isArchived?: boolean, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersContractoffergroupsIndex.');
            }
            const localVarPath = `/partners/{partner_id}/contractoffergroups`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (isArchived !== undefined) {
                localVarQueryParameter['is_archived'] = isArchived;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contractId
         * @param {PartnerContractActionResource} partnerContractActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsActionsCreate(partnerId: string, contractId: string, partnerContractActionResource: PartnerContractActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersContractsActionsCreate.');
            }
            // verify required parameter 'contractId' is not null or undefined
            if (contractId === null || contractId === undefined) {
                throw new RequiredError('contractId','Required parameter contractId was null or undefined when calling partnersContractsActionsCreate.');
            }
            // verify required parameter 'partnerContractActionResource' is not null or undefined
            if (partnerContractActionResource === null || partnerContractActionResource === undefined) {
                throw new RequiredError('partnerContractActionResource','Required parameter partnerContractActionResource was null or undefined when calling partnersContractsActionsCreate.');
            }
            const localVarPath = `/partners/{partner_id}/contracts/{contract_id}/actions`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"contract_id"}}`, encodeURIComponent(String(contractId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerContractActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerContractActionResource || {}) : (partnerContractActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerContractId
         * @param {string} boostId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsAllocationboostsDelete(partnerId: string, partnerContractId: string, boostId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersContractsAllocationboostsDelete.');
            }
            // verify required parameter 'partnerContractId' is not null or undefined
            if (partnerContractId === null || partnerContractId === undefined) {
                throw new RequiredError('partnerContractId','Required parameter partnerContractId was null or undefined when calling partnersContractsAllocationboostsDelete.');
            }
            // verify required parameter 'boostId' is not null or undefined
            if (boostId === null || boostId === undefined) {
                throw new RequiredError('boostId','Required parameter boostId was null or undefined when calling partnersContractsAllocationboostsDelete.');
            }
            const localVarPath = `/partners/{partner_id}/contracts/{partner_contract_id}/allocationboosts/{boost_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_contract_id"}}`, encodeURIComponent(String(partnerContractId)))
                .replace(`{${"boost_id"}}`, encodeURIComponent(String(boostId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerContractId
         * @param {string} boostId
         * @param {DateSpanResource} dateSpanResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsAllocationboostsUpdate(partnerId: string, partnerContractId: string, boostId: string, dateSpanResource: DateSpanResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersContractsAllocationboostsUpdate.');
            }
            // verify required parameter 'partnerContractId' is not null or undefined
            if (partnerContractId === null || partnerContractId === undefined) {
                throw new RequiredError('partnerContractId','Required parameter partnerContractId was null or undefined when calling partnersContractsAllocationboostsUpdate.');
            }
            // verify required parameter 'boostId' is not null or undefined
            if (boostId === null || boostId === undefined) {
                throw new RequiredError('boostId','Required parameter boostId was null or undefined when calling partnersContractsAllocationboostsUpdate.');
            }
            // verify required parameter 'dateSpanResource' is not null or undefined
            if (dateSpanResource === null || dateSpanResource === undefined) {
                throw new RequiredError('dateSpanResource','Required parameter dateSpanResource was null or undefined when calling partnersContractsAllocationboostsUpdate.');
            }
            const localVarPath = `/partners/{partner_id}/contracts/{partner_contract_id}/allocationboosts/{boost_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_contract_id"}}`, encodeURIComponent(String(partnerContractId)))
                .replace(`{${"boost_id"}}`, encodeURIComponent(String(boostId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DateSpanResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(dateSpanResource || {}) : (dateSpanResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerContractId
         * @param {string} pauseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsAllocationpausesDelete(partnerId: string, partnerContractId: string, pauseId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersContractsAllocationpausesDelete.');
            }
            // verify required parameter 'partnerContractId' is not null or undefined
            if (partnerContractId === null || partnerContractId === undefined) {
                throw new RequiredError('partnerContractId','Required parameter partnerContractId was null or undefined when calling partnersContractsAllocationpausesDelete.');
            }
            // verify required parameter 'pauseId' is not null or undefined
            if (pauseId === null || pauseId === undefined) {
                throw new RequiredError('pauseId','Required parameter pauseId was null or undefined when calling partnersContractsAllocationpausesDelete.');
            }
            const localVarPath = `/partners/{partner_id}/contracts/{partner_contract_id}/allocationpauses/{pause_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_contract_id"}}`, encodeURIComponent(String(partnerContractId)))
                .replace(`{${"pause_id"}}`, encodeURIComponent(String(pauseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerContractId
         * @param {string} pauseId
         * @param {DateSpanResource} dateSpanResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsAllocationpausesUpdate(partnerId: string, partnerContractId: string, pauseId: string, dateSpanResource: DateSpanResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersContractsAllocationpausesUpdate.');
            }
            // verify required parameter 'partnerContractId' is not null or undefined
            if (partnerContractId === null || partnerContractId === undefined) {
                throw new RequiredError('partnerContractId','Required parameter partnerContractId was null or undefined when calling partnersContractsAllocationpausesUpdate.');
            }
            // verify required parameter 'pauseId' is not null or undefined
            if (pauseId === null || pauseId === undefined) {
                throw new RequiredError('pauseId','Required parameter pauseId was null or undefined when calling partnersContractsAllocationpausesUpdate.');
            }
            // verify required parameter 'dateSpanResource' is not null or undefined
            if (dateSpanResource === null || dateSpanResource === undefined) {
                throw new RequiredError('dateSpanResource','Required parameter dateSpanResource was null or undefined when calling partnersContractsAllocationpausesUpdate.');
            }
            const localVarPath = `/partners/{partner_id}/contracts/{partner_contract_id}/allocationpauses/{pause_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_contract_id"}}`, encodeURIComponent(String(partnerContractId)))
                .replace(`{${"pause_id"}}`, encodeURIComponent(String(pauseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DateSpanResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(dateSpanResource || {}) : (dateSpanResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerContractResource} partnerContractResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsCreate(partnerId: string, partnerContractResource: PartnerContractResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersContractsCreate.');
            }
            // verify required parameter 'partnerContractResource' is not null or undefined
            if (partnerContractResource === null || partnerContractResource === undefined) {
                throw new RequiredError('partnerContractResource','Required parameter partnerContractResource was null or undefined when calling partnersContractsCreate.');
            }
            const localVarPath = `/partners/{partner_id}/contracts`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerContractResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerContractResource || {}) : (partnerContractResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerContractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsDelete(partnerId: string, partnerContractId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersContractsDelete.');
            }
            // verify required parameter 'partnerContractId' is not null or undefined
            if (partnerContractId === null || partnerContractId === undefined) {
                throw new RequiredError('partnerContractId','Required parameter partnerContractId was null or undefined when calling partnersContractsDelete.');
            }
            const localVarPath = `/partners/{partner_id}/contracts/{partner_contract_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_contract_id"}}`, encodeURIComponent(String(partnerContractId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsGet(partnerId: string, contractId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersContractsGet.');
            }
            // verify required parameter 'contractId' is not null or undefined
            if (contractId === null || contractId === undefined) {
                throw new RequiredError('contractId','Required parameter contractId was null or undefined when calling partnersContractsGet.');
            }
            const localVarPath = `/partners/{partner_id}/contracts/{contract_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"contract_id"}}`, encodeURIComponent(String(contractId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {boolean} [isAccepted]
         * @param {boolean} [isArchived]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsIndex(partnerId: string, isAccepted?: boolean, isArchived?: boolean, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersContractsIndex.');
            }
            const localVarPath = `/partners/{partner_id}/contracts`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (isAccepted !== undefined) {
                localVarQueryParameter['is_accepted'] = isAccepted;
            }

            if (isArchived !== undefined) {
                localVarQueryParameter['is_archived'] = isArchived;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsPdfGet(partnerId: string, contractId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersContractsPdfGet.');
            }
            // verify required parameter 'contractId' is not null or undefined
            if (contractId === null || contractId === undefined) {
                throw new RequiredError('contractId','Required parameter contractId was null or undefined when calling partnersContractsPdfGet.');
            }
            const localVarPath = `/partners/{partner_id}/contracts/{contract_id}/pdf`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"contract_id"}}`, encodeURIComponent(String(contractId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contractId
         * @param {PartnerContractSignedPdfFileResource} partnerContractSignedPdfFileResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsSignedpdfCreate(partnerId: string, contractId: string, partnerContractSignedPdfFileResource: PartnerContractSignedPdfFileResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersContractsSignedpdfCreate.');
            }
            // verify required parameter 'contractId' is not null or undefined
            if (contractId === null || contractId === undefined) {
                throw new RequiredError('contractId','Required parameter contractId was null or undefined when calling partnersContractsSignedpdfCreate.');
            }
            // verify required parameter 'partnerContractSignedPdfFileResource' is not null or undefined
            if (partnerContractSignedPdfFileResource === null || partnerContractSignedPdfFileResource === undefined) {
                throw new RequiredError('partnerContractSignedPdfFileResource','Required parameter partnerContractSignedPdfFileResource was null or undefined when calling partnersContractsSignedpdfCreate.');
            }
            const localVarPath = `/partners/{partner_id}/contracts/{contract_id}/signedpdf`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"contract_id"}}`, encodeURIComponent(String(contractId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerContractSignedPdfFileResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerContractSignedPdfFileResource || {}) : (partnerContractSignedPdfFileResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsSignedpdfGet(partnerId: string, contractId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersContractsSignedpdfGet.');
            }
            // verify required parameter 'contractId' is not null or undefined
            if (contractId === null || contractId === undefined) {
                throw new RequiredError('contractId','Required parameter contractId was null or undefined when calling partnersContractsSignedpdfGet.');
            }
            const localVarPath = `/partners/{partner_id}/contracts/{contract_id}/signedpdf`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"contract_id"}}`, encodeURIComponent(String(contractId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsSigningurlGet(partnerId: string, contractId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersContractsSigningurlGet.');
            }
            // verify required parameter 'contractId' is not null or undefined
            if (contractId === null || contractId === undefined) {
                throw new RequiredError('contractId','Required parameter contractId was null or undefined when calling partnersContractsSigningurlGet.');
            }
            const localVarPath = `/partners/{partner_id}/contracts/{contract_id}/signingurl`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"contract_id"}}`, encodeURIComponent(String(contractId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerContractId
         * @param {PartnerContractResource} partnerContractResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsUpdate(partnerId: string, partnerContractId: string, partnerContractResource: PartnerContractResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersContractsUpdate.');
            }
            // verify required parameter 'partnerContractId' is not null or undefined
            if (partnerContractId === null || partnerContractId === undefined) {
                throw new RequiredError('partnerContractId','Required parameter partnerContractId was null or undefined when calling partnersContractsUpdate.');
            }
            // verify required parameter 'partnerContractResource' is not null or undefined
            if (partnerContractResource === null || partnerContractResource === undefined) {
                throw new RequiredError('partnerContractResource','Required parameter partnerContractResource was null or undefined when calling partnersContractsUpdate.');
            }
            const localVarPath = `/partners/{partner_id}/contracts/{partner_contract_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_contract_id"}}`, encodeURIComponent(String(partnerContractId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerContractResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerContractResource || {}) : (partnerContractResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} couponId
         * @param {PartnerCouponActionResource} partnerCouponActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCouponsActionsCreate(partnerId: string, couponId: string, partnerCouponActionResource: PartnerCouponActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersCouponsActionsCreate.');
            }
            // verify required parameter 'couponId' is not null or undefined
            if (couponId === null || couponId === undefined) {
                throw new RequiredError('couponId','Required parameter couponId was null or undefined when calling partnersCouponsActionsCreate.');
            }
            // verify required parameter 'partnerCouponActionResource' is not null or undefined
            if (partnerCouponActionResource === null || partnerCouponActionResource === undefined) {
                throw new RequiredError('partnerCouponActionResource','Required parameter partnerCouponActionResource was null or undefined when calling partnersCouponsActionsCreate.');
            }
            const localVarPath = `/partners/{partner_id}/coupons/{coupon_id}/actions`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"coupon_id"}}`, encodeURIComponent(String(couponId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerCouponActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerCouponActionResource || {}) : (partnerCouponActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerCouponResource} partnerCouponResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCouponsCreate(partnerId: string, partnerCouponResource: PartnerCouponResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersCouponsCreate.');
            }
            // verify required parameter 'partnerCouponResource' is not null or undefined
            if (partnerCouponResource === null || partnerCouponResource === undefined) {
                throw new RequiredError('partnerCouponResource','Required parameter partnerCouponResource was null or undefined when calling partnersCouponsCreate.');
            }
            const localVarPath = `/partners/{partner_id}/coupons`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerCouponResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerCouponResource || {}) : (partnerCouponResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {boolean} [showExpired] Only show expired partner coupons.
         * @param {boolean} [showDeactivated] Only show deactivated partner coupons.
         * @param {boolean} [showEmpty] Should empty partner coupons be included in the result.
         * @param {'created_at' | 'expires_at' | 'deactivated_at' | 'punches_left' | 'ascending' | 'descending'} [sort] Sort the coupons by different values
         * @param {string} [type] Filter the coupons type
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCouponsIndex(partnerId: string, showExpired?: boolean, showDeactivated?: boolean, showEmpty?: boolean, sort?: 'created_at' | 'expires_at' | 'deactivated_at' | 'punches_left' | 'ascending' | 'descending', type?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersCouponsIndex.');
            }
            const localVarPath = `/partners/{partner_id}/coupons`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (showExpired !== undefined) {
                localVarQueryParameter['show_expired'] = showExpired;
            }

            if (showDeactivated !== undefined) {
                localVarQueryParameter['show_deactivated'] = showDeactivated;
            }

            if (showEmpty !== undefined) {
                localVarQueryParameter['show_empty'] = showEmpty;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a Partner
         * @summary Create a Partner
         * @param {PartnerResource} partnerResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCreate(partnerResource: PartnerResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerResource' is not null or undefined
            if (partnerResource === null || partnerResource === undefined) {
                throw new RequiredError('partnerResource','Required parameter partnerResource was null or undefined when calling partnersCreate.');
            }
            const localVarPath = `/partners`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerResource || {}) : (partnerResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDelete(partnerId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersDelete.');
            }
            const localVarPath = `/partners/{partner_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {PartnerDeliverableResource} partnerDeliverableResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesCreate(partnerDeliverableResource: PartnerDeliverableResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerDeliverableResource' is not null or undefined
            if (partnerDeliverableResource === null || partnerDeliverableResource === undefined) {
                throw new RequiredError('partnerDeliverableResource','Required parameter partnerDeliverableResource was null or undefined when calling partnersDeliverablesCreate.');
            }
            const localVarPath = `/partners/deliverables`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerDeliverableResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerDeliverableResource || {}) : (partnerDeliverableResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerDeliverableResource} partnerDeliverableResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesCreate_2(partnerId: string, partnerDeliverableResource: PartnerDeliverableResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersDeliverablesCreate_2.');
            }
            // verify required parameter 'partnerDeliverableResource' is not null or undefined
            if (partnerDeliverableResource === null || partnerDeliverableResource === undefined) {
                throw new RequiredError('partnerDeliverableResource','Required parameter partnerDeliverableResource was null or undefined when calling partnersDeliverablesCreate_2.');
            }
            const localVarPath = `/partners/{partner_id}/deliverables`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerDeliverableResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerDeliverableResource || {}) : (partnerDeliverableResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerDeliverableId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesGet(partnerDeliverableId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerDeliverableId' is not null or undefined
            if (partnerDeliverableId === null || partnerDeliverableId === undefined) {
                throw new RequiredError('partnerDeliverableId','Required parameter partnerDeliverableId was null or undefined when calling partnersDeliverablesGet.');
            }
            const localVarPath = `/partners/deliverables/{partnerDeliverable_id}`
                .replace(`{${"partnerDeliverable_id"}}`, encodeURIComponent(String(partnerDeliverableId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerDeliverableId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesGet_3(partnerId: string, partnerDeliverableId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersDeliverablesGet_3.');
            }
            // verify required parameter 'partnerDeliverableId' is not null or undefined
            if (partnerDeliverableId === null || partnerDeliverableId === undefined) {
                throw new RequiredError('partnerDeliverableId','Required parameter partnerDeliverableId was null or undefined when calling partnersDeliverablesGet_3.');
            }
            const localVarPath = `/partners/{partner_id}/deliverables/{partnerDeliverable_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partnerDeliverable_id"}}`, encodeURIComponent(String(partnerDeliverableId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [clientId] Client id for the clients to filter by
         * @param {string} [leadId] Lead id for the lead to filter by
         * @param {string} [partnerId] Partner id of the partners to filter by.
         * @param {string} [partnerDeliverableId] Partner deliverable id of the partners to filter by.
         * @param {string} [status] States for the partners.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesIndex(clientId?: string, leadId?: string, partnerId?: string, partnerDeliverableId?: string, status?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/partners/deliverables`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (leadId !== undefined) {
                localVarQueryParameter['lead_id'] = leadId;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (partnerDeliverableId !== undefined) {
                localVarQueryParameter['partner_deliverable_id'] = partnerDeliverableId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [clientId] Client id for the clients to filter by
         * @param {string} [leadId] Lead id for the lead to filter by
         * @param {string} [partnerDeliverableId] Partner deliverable id of the partners to filter by.
         * @param {string} [status] States for the partners.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesIndex_4(partnerId: string, clientId?: string, leadId?: string, partnerDeliverableId?: string, status?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersDeliverablesIndex_4.');
            }
            const localVarPath = `/partners/{partner_id}/deliverables`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (leadId !== undefined) {
                localVarQueryParameter['lead_id'] = leadId;
            }

            if (partnerDeliverableId !== undefined) {
                localVarQueryParameter['partner_deliverable_id'] = partnerDeliverableId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerDeliverableId
         * @param {PartnerDeliverableLineResource} partnerDeliverableLineResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesCreate(partnerDeliverableId: string, partnerDeliverableLineResource: PartnerDeliverableLineResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerDeliverableId' is not null or undefined
            if (partnerDeliverableId === null || partnerDeliverableId === undefined) {
                throw new RequiredError('partnerDeliverableId','Required parameter partnerDeliverableId was null or undefined when calling partnersDeliverablesLinesCreate.');
            }
            // verify required parameter 'partnerDeliverableLineResource' is not null or undefined
            if (partnerDeliverableLineResource === null || partnerDeliverableLineResource === undefined) {
                throw new RequiredError('partnerDeliverableLineResource','Required parameter partnerDeliverableLineResource was null or undefined when calling partnersDeliverablesLinesCreate.');
            }
            const localVarPath = `/partners/deliverables/{partnerDeliverable_id}/lines`
                .replace(`{${"partnerDeliverable_id"}}`, encodeURIComponent(String(partnerDeliverableId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerDeliverableLineResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerDeliverableLineResource || {}) : (partnerDeliverableLineResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerDeliverableId
         * @param {PartnerDeliverableLineResource} partnerDeliverableLineResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesCreate_5(partnerId: string, partnerDeliverableId: string, partnerDeliverableLineResource: PartnerDeliverableLineResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersDeliverablesLinesCreate_5.');
            }
            // verify required parameter 'partnerDeliverableId' is not null or undefined
            if (partnerDeliverableId === null || partnerDeliverableId === undefined) {
                throw new RequiredError('partnerDeliverableId','Required parameter partnerDeliverableId was null or undefined when calling partnersDeliverablesLinesCreate_5.');
            }
            // verify required parameter 'partnerDeliverableLineResource' is not null or undefined
            if (partnerDeliverableLineResource === null || partnerDeliverableLineResource === undefined) {
                throw new RequiredError('partnerDeliverableLineResource','Required parameter partnerDeliverableLineResource was null or undefined when calling partnersDeliverablesLinesCreate_5.');
            }
            const localVarPath = `/partners/{partner_id}/deliverables/{partnerDeliverable_id}/lines`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partnerDeliverable_id"}}`, encodeURIComponent(String(partnerDeliverableId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerDeliverableLineResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerDeliverableLineResource || {}) : (partnerDeliverableLineResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerDeliverableId
         * @param {string} partnerDeliverableLineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesDelete(partnerDeliverableId: string, partnerDeliverableLineId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerDeliverableId' is not null or undefined
            if (partnerDeliverableId === null || partnerDeliverableId === undefined) {
                throw new RequiredError('partnerDeliverableId','Required parameter partnerDeliverableId was null or undefined when calling partnersDeliverablesLinesDelete.');
            }
            // verify required parameter 'partnerDeliverableLineId' is not null or undefined
            if (partnerDeliverableLineId === null || partnerDeliverableLineId === undefined) {
                throw new RequiredError('partnerDeliverableLineId','Required parameter partnerDeliverableLineId was null or undefined when calling partnersDeliverablesLinesDelete.');
            }
            const localVarPath = `/partners/deliverables/{partnerDeliverable_id}/lines/{partnerDeliverableLine_id}`
                .replace(`{${"partnerDeliverable_id"}}`, encodeURIComponent(String(partnerDeliverableId)))
                .replace(`{${"partnerDeliverableLine_id"}}`, encodeURIComponent(String(partnerDeliverableLineId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerDeliverableId
         * @param {string} partnerDeliverableLineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesDelete_6(partnerId: string, partnerDeliverableId: string, partnerDeliverableLineId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersDeliverablesLinesDelete_6.');
            }
            // verify required parameter 'partnerDeliverableId' is not null or undefined
            if (partnerDeliverableId === null || partnerDeliverableId === undefined) {
                throw new RequiredError('partnerDeliverableId','Required parameter partnerDeliverableId was null or undefined when calling partnersDeliverablesLinesDelete_6.');
            }
            // verify required parameter 'partnerDeliverableLineId' is not null or undefined
            if (partnerDeliverableLineId === null || partnerDeliverableLineId === undefined) {
                throw new RequiredError('partnerDeliverableLineId','Required parameter partnerDeliverableLineId was null or undefined when calling partnersDeliverablesLinesDelete_6.');
            }
            const localVarPath = `/partners/{partner_id}/deliverables/{partnerDeliverable_id}/lines/{partnerDeliverableLine_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partnerDeliverable_id"}}`, encodeURIComponent(String(partnerDeliverableId)))
                .replace(`{${"partnerDeliverableLine_id"}}`, encodeURIComponent(String(partnerDeliverableLineId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerDeliverableId
         * @param {string} partnerDeliverableLineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesGet(partnerDeliverableId: string, partnerDeliverableLineId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerDeliverableId' is not null or undefined
            if (partnerDeliverableId === null || partnerDeliverableId === undefined) {
                throw new RequiredError('partnerDeliverableId','Required parameter partnerDeliverableId was null or undefined when calling partnersDeliverablesLinesGet.');
            }
            // verify required parameter 'partnerDeliverableLineId' is not null or undefined
            if (partnerDeliverableLineId === null || partnerDeliverableLineId === undefined) {
                throw new RequiredError('partnerDeliverableLineId','Required parameter partnerDeliverableLineId was null or undefined when calling partnersDeliverablesLinesGet.');
            }
            const localVarPath = `/partners/deliverables/{partnerDeliverable_id}/lines/{partnerDeliverableLine_id}`
                .replace(`{${"partnerDeliverable_id"}}`, encodeURIComponent(String(partnerDeliverableId)))
                .replace(`{${"partnerDeliverableLine_id"}}`, encodeURIComponent(String(partnerDeliverableLineId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerDeliverableId
         * @param {string} partnerDeliverableLineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesGet_7(partnerId: string, partnerDeliverableId: string, partnerDeliverableLineId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersDeliverablesLinesGet_7.');
            }
            // verify required parameter 'partnerDeliverableId' is not null or undefined
            if (partnerDeliverableId === null || partnerDeliverableId === undefined) {
                throw new RequiredError('partnerDeliverableId','Required parameter partnerDeliverableId was null or undefined when calling partnersDeliverablesLinesGet_7.');
            }
            // verify required parameter 'partnerDeliverableLineId' is not null or undefined
            if (partnerDeliverableLineId === null || partnerDeliverableLineId === undefined) {
                throw new RequiredError('partnerDeliverableLineId','Required parameter partnerDeliverableLineId was null or undefined when calling partnersDeliverablesLinesGet_7.');
            }
            const localVarPath = `/partners/{partner_id}/deliverables/{partnerDeliverable_id}/lines/{partnerDeliverableLine_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partnerDeliverable_id"}}`, encodeURIComponent(String(partnerDeliverableId)))
                .replace(`{${"partnerDeliverableLine_id"}}`, encodeURIComponent(String(partnerDeliverableLineId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerDeliverableId
         * @param {string} [clientId] Client id for the clients to filter by
         * @param {string} [leadId] Lead id for the lead to filter by
         * @param {string} [partnerId] Partner id of the partners to filter by.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesIndex(partnerDeliverableId: string, clientId?: string, leadId?: string, partnerId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerDeliverableId' is not null or undefined
            if (partnerDeliverableId === null || partnerDeliverableId === undefined) {
                throw new RequiredError('partnerDeliverableId','Required parameter partnerDeliverableId was null or undefined when calling partnersDeliverablesLinesIndex.');
            }
            const localVarPath = `/partners/deliverables/{partnerDeliverable_id}/lines`
                .replace(`{${"partnerDeliverable_id"}}`, encodeURIComponent(String(partnerDeliverableId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (leadId !== undefined) {
                localVarQueryParameter['lead_id'] = leadId;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerDeliverableId
         * @param {string} [clientId] Client id for the clients to filter by
         * @param {string} [leadId] Lead id for the lead to filter by
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesIndex_8(partnerId: string, partnerDeliverableId: string, clientId?: string, leadId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersDeliverablesLinesIndex_8.');
            }
            // verify required parameter 'partnerDeliverableId' is not null or undefined
            if (partnerDeliverableId === null || partnerDeliverableId === undefined) {
                throw new RequiredError('partnerDeliverableId','Required parameter partnerDeliverableId was null or undefined when calling partnersDeliverablesLinesIndex_8.');
            }
            const localVarPath = `/partners/{partner_id}/deliverables/{partnerDeliverable_id}/lines`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partnerDeliverable_id"}}`, encodeURIComponent(String(partnerDeliverableId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (leadId !== undefined) {
                localVarQueryParameter['lead_id'] = leadId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerDeliverableId
         * @param {string} partnerDeliverableLineId
         * @param {PartnerDeliverableLineResource} partnerDeliverableLineResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesUpdate(partnerDeliverableId: string, partnerDeliverableLineId: string, partnerDeliverableLineResource: PartnerDeliverableLineResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerDeliverableId' is not null or undefined
            if (partnerDeliverableId === null || partnerDeliverableId === undefined) {
                throw new RequiredError('partnerDeliverableId','Required parameter partnerDeliverableId was null or undefined when calling partnersDeliverablesLinesUpdate.');
            }
            // verify required parameter 'partnerDeliverableLineId' is not null or undefined
            if (partnerDeliverableLineId === null || partnerDeliverableLineId === undefined) {
                throw new RequiredError('partnerDeliverableLineId','Required parameter partnerDeliverableLineId was null or undefined when calling partnersDeliverablesLinesUpdate.');
            }
            // verify required parameter 'partnerDeliverableLineResource' is not null or undefined
            if (partnerDeliverableLineResource === null || partnerDeliverableLineResource === undefined) {
                throw new RequiredError('partnerDeliverableLineResource','Required parameter partnerDeliverableLineResource was null or undefined when calling partnersDeliverablesLinesUpdate.');
            }
            const localVarPath = `/partners/deliverables/{partnerDeliverable_id}/lines/{partnerDeliverableLine_id}`
                .replace(`{${"partnerDeliverable_id"}}`, encodeURIComponent(String(partnerDeliverableId)))
                .replace(`{${"partnerDeliverableLine_id"}}`, encodeURIComponent(String(partnerDeliverableLineId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerDeliverableLineResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerDeliverableLineResource || {}) : (partnerDeliverableLineResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerDeliverableId
         * @param {string} partnerDeliverableLineId
         * @param {PartnerDeliverableLineResource} partnerDeliverableLineResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesUpdate_9(partnerId: string, partnerDeliverableId: string, partnerDeliverableLineId: string, partnerDeliverableLineResource: PartnerDeliverableLineResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersDeliverablesLinesUpdate_9.');
            }
            // verify required parameter 'partnerDeliverableId' is not null or undefined
            if (partnerDeliverableId === null || partnerDeliverableId === undefined) {
                throw new RequiredError('partnerDeliverableId','Required parameter partnerDeliverableId was null or undefined when calling partnersDeliverablesLinesUpdate_9.');
            }
            // verify required parameter 'partnerDeliverableLineId' is not null or undefined
            if (partnerDeliverableLineId === null || partnerDeliverableLineId === undefined) {
                throw new RequiredError('partnerDeliverableLineId','Required parameter partnerDeliverableLineId was null or undefined when calling partnersDeliverablesLinesUpdate_9.');
            }
            // verify required parameter 'partnerDeliverableLineResource' is not null or undefined
            if (partnerDeliverableLineResource === null || partnerDeliverableLineResource === undefined) {
                throw new RequiredError('partnerDeliverableLineResource','Required parameter partnerDeliverableLineResource was null or undefined when calling partnersDeliverablesLinesUpdate_9.');
            }
            const localVarPath = `/partners/{partner_id}/deliverables/{partnerDeliverable_id}/lines/{partnerDeliverableLine_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partnerDeliverable_id"}}`, encodeURIComponent(String(partnerDeliverableId)))
                .replace(`{${"partnerDeliverableLine_id"}}`, encodeURIComponent(String(partnerDeliverableLineId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerDeliverableLineResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerDeliverableLineResource || {}) : (partnerDeliverableLineResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerDeliverableId
         * @param {PartnerDeliverableResource} partnerDeliverableResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesUpdate(partnerDeliverableId: string, partnerDeliverableResource: PartnerDeliverableResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerDeliverableId' is not null or undefined
            if (partnerDeliverableId === null || partnerDeliverableId === undefined) {
                throw new RequiredError('partnerDeliverableId','Required parameter partnerDeliverableId was null or undefined when calling partnersDeliverablesUpdate.');
            }
            // verify required parameter 'partnerDeliverableResource' is not null or undefined
            if (partnerDeliverableResource === null || partnerDeliverableResource === undefined) {
                throw new RequiredError('partnerDeliverableResource','Required parameter partnerDeliverableResource was null or undefined when calling partnersDeliverablesUpdate.');
            }
            const localVarPath = `/partners/deliverables/{partnerDeliverable_id}`
                .replace(`{${"partnerDeliverable_id"}}`, encodeURIComponent(String(partnerDeliverableId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerDeliverableResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerDeliverableResource || {}) : (partnerDeliverableResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerDeliverableId
         * @param {PartnerDeliverableResource} partnerDeliverableResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesUpdate_10(partnerId: string, partnerDeliverableId: string, partnerDeliverableResource: PartnerDeliverableResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersDeliverablesUpdate_10.');
            }
            // verify required parameter 'partnerDeliverableId' is not null or undefined
            if (partnerDeliverableId === null || partnerDeliverableId === undefined) {
                throw new RequiredError('partnerDeliverableId','Required parameter partnerDeliverableId was null or undefined when calling partnersDeliverablesUpdate_10.');
            }
            // verify required parameter 'partnerDeliverableResource' is not null or undefined
            if (partnerDeliverableResource === null || partnerDeliverableResource === undefined) {
                throw new RequiredError('partnerDeliverableResource','Required parameter partnerDeliverableResource was null or undefined when calling partnersDeliverablesUpdate_10.');
            }
            const localVarPath = `/partners/{partner_id}/deliverables/{partnerDeliverable_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partnerDeliverable_id"}}`, encodeURIComponent(String(partnerDeliverableId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerDeliverableResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerDeliverableResource || {}) : (partnerDeliverableResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerDiscountResource} partnerDiscountResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDiscountsCreate(partnerId: string, partnerDiscountResource: PartnerDiscountResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersDiscountsCreate.');
            }
            // verify required parameter 'partnerDiscountResource' is not null or undefined
            if (partnerDiscountResource === null || partnerDiscountResource === undefined) {
                throw new RequiredError('partnerDiscountResource','Required parameter partnerDiscountResource was null or undefined when calling partnersDiscountsCreate.');
            }
            const localVarPath = `/partners/{partner_id}/discounts`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerDiscountResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerDiscountResource || {}) : (partnerDiscountResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerPackageTypeDiscountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDiscountsDelete(partnerId: string, partnerPackageTypeDiscountId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersDiscountsDelete.');
            }
            // verify required parameter 'partnerPackageTypeDiscountId' is not null or undefined
            if (partnerPackageTypeDiscountId === null || partnerPackageTypeDiscountId === undefined) {
                throw new RequiredError('partnerPackageTypeDiscountId','Required parameter partnerPackageTypeDiscountId was null or undefined when calling partnersDiscountsDelete.');
            }
            const localVarPath = `/partners/{partner_id}/discounts/{partner_package_type_discount_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_package_type_discount_id"}}`, encodeURIComponent(String(partnerPackageTypeDiscountId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [isActive] Only include active discounts.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDiscountsIndex(partnerId: string, isActive?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersDiscountsIndex.');
            }
            const localVarPath = `/partners/{partner_id}/discounts`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerEmployeeNoteResource} partnerEmployeeNoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersEmployeenotesCreate(partnerId: string, partnerEmployeeNoteResource: PartnerEmployeeNoteResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersEmployeenotesCreate.');
            }
            // verify required parameter 'partnerEmployeeNoteResource' is not null or undefined
            if (partnerEmployeeNoteResource === null || partnerEmployeeNoteResource === undefined) {
                throw new RequiredError('partnerEmployeeNoteResource','Required parameter partnerEmployeeNoteResource was null or undefined when calling partnersEmployeenotesCreate.');
            }
            const localVarPath = `/partners/{partner_id}/employeenotes`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerEmployeeNoteResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerEmployeeNoteResource || {}) : (partnerEmployeeNoteResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} employeeNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersEmployeenotesDelete(partnerId: string, employeeNoteId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersEmployeenotesDelete.');
            }
            // verify required parameter 'employeeNoteId' is not null or undefined
            if (employeeNoteId === null || employeeNoteId === undefined) {
                throw new RequiredError('employeeNoteId','Required parameter employeeNoteId was null or undefined when calling partnersEmployeenotesDelete.');
            }
            const localVarPath = `/partners/{partner_id}/employeenotes/{employee_note_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"employee_note_id"}}`, encodeURIComponent(String(employeeNoteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [leadId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersEmployeenotesIndex(partnerId: string, leadId?: number, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersEmployeenotesIndex.');
            }
            const localVarPath = `/partners/{partner_id}/employeenotes`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (leadId !== undefined) {
                localVarQueryParameter['lead_id'] = leadId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerFeatureResource} partnerFeatureResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersFeaturesCreate(partnerId: string, partnerFeatureResource: PartnerFeatureResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersFeaturesCreate.');
            }
            // verify required parameter 'partnerFeatureResource' is not null or undefined
            if (partnerFeatureResource === null || partnerFeatureResource === undefined) {
                throw new RequiredError('partnerFeatureResource','Required parameter partnerFeatureResource was null or undefined when calling partnersFeaturesCreate.');
            }
            const localVarPath = `/partners/{partner_id}/features`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerFeatureResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerFeatureResource || {}) : (partnerFeatureResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} featureIdentifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersFeaturesDelete(partnerId: string, featureIdentifier: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersFeaturesDelete.');
            }
            // verify required parameter 'featureIdentifier' is not null or undefined
            if (featureIdentifier === null || featureIdentifier === undefined) {
                throw new RequiredError('featureIdentifier','Required parameter featureIdentifier was null or undefined when calling partnersFeaturesDelete.');
            }
            const localVarPath = `/partners/{partner_id}/features/{feature_identifier}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"feature_identifier"}}`, encodeURIComponent(String(featureIdentifier)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerFeeResource} partnerFeeResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersFeesCreate(partnerId: string, partnerFeeResource: PartnerFeeResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersFeesCreate.');
            }
            // verify required parameter 'partnerFeeResource' is not null or undefined
            if (partnerFeeResource === null || partnerFeeResource === undefined) {
                throw new RequiredError('partnerFeeResource','Required parameter partnerFeeResource was null or undefined when calling partnersFeesCreate.');
            }
            const localVarPath = `/partners/{partner_id}/fees`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerFeeResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerFeeResource || {}) : (partnerFeeResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersFeesDelete(partnerId: string, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersFeesDelete.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling partnersFeesDelete.');
            }
            const localVarPath = `/partners/{partner_id}/fees/{id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [id]
         * @param {number} [partnerId] Partner ID. @var int
         * @param {string} [effectiveAt] effective from date.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersFeesIndex(id?: number, partnerId?: number, effectiveAt?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/partners/fees`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (effectiveAt !== undefined) {
                localVarQueryParameter['effective_at'] = effectiveAt;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single partner by ID
         * @summary Get a single partner by ID
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersGet(partnerId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersGet.');
            }
            const localVarPath = `/partners/{partner_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerImpressumResource} partnerImpressumResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersImpressumsCreate(partnerId: string, partnerImpressumResource: PartnerImpressumResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersImpressumsCreate.');
            }
            // verify required parameter 'partnerImpressumResource' is not null or undefined
            if (partnerImpressumResource === null || partnerImpressumResource === undefined) {
                throw new RequiredError('partnerImpressumResource','Required parameter partnerImpressumResource was null or undefined when calling partnersImpressumsCreate.');
            }
            const localVarPath = `/partners/{partner_id}/impressums`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerImpressumResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerImpressumResource || {}) : (partnerImpressumResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List partners
         * @summary List partners
         * @param {string} [partnerId] Filter partners by partner ID
         * @param {string} [state] States for the partners.
         * @param {boolean} [isEnabled]
         * @param {string} [employeeId] Employee id of the partners to filter by.
         * @param {string} [geoCode] Geographic Location Code.
         * @param {string} [satisfaction] Partner Satisfaction Ratio.
         * @param {string} [punchesUseSpeed] Punches use speed.
         * @param {string} [refillOfferId] Filter the partners by their refill offers
         * @param {string} [industryId] Filter the partners by their industry ids
         * @param {string} [industry] Filter the partners by their industry identifiers
         * @param {string} [geoPoint] Location Point to sort partners by
         * @param {string} [geoRect] Location Points to define an area to filter partners by. Provide the bottom left (southwest) and top right (northeast) corners of the reactangle separated by commas, e.g. \&quot;52.19,12.42,52.85,14.22\&quot;.
         * @param {number} [geoDistance] Location filter by distance
         * @param {'id' | 'relevance' | 'created_at' | 'company_name' | 'state' | 'satisfaction' | 'employee_name' | 'punches_monthly' | 'punches_use_speed' | 'last_login' | 'last_lead_bought' | 'revenue_6months' | 'last_invoice' | 'avg_punch_price_6months' | 'avg_due_days_6months' | 'last_coupon_size' | 'punches_left' | 'punches_spent_monthly' | 'punches_spent_6months' | 'punches_bought_6months' | 'golden_ratio_6months' | 'refill' | 'rating' | 'distance' | 'last_phone_call' | 'canvas_status' | 'address' | 'tasks' | 'last_subscription_start_date' | 'last_published_quote' | 'published_quotes' | 'win_ratio' | 'unmatched' | 'unmatched_percent' | 'digital_leads' | 'has_active_contract' | 'deallocations' | 'overdue_amount' | 'active_contracts_count' | 'active_allocation_pauses_count' | 'active_contracts_amount' | 'next_contract_renewal_at' | 'active_business_units_amount' | 'active_business_units_mrr' | 'current_period_weighted_delivery_trend' | 'success_ratio' | 'publishing_ratio' | 'all_time_purchased_marketplace_leads_value_excl_vat_local_currency' | 'all_time_purchased_marketplace_leads_count' | 'lead_marketplace_partner_spend_excl_vat_local_currency' | 'ascending' | 'descending'} [sort] Sort Partner&#39;s by a given property.
         * @param {string} [canvasStatus] Canvas Status
         * @param {boolean} [isPublic] Only show partners that are visible to the public
         * @param {string} [packageType] Partner subscription package type
         * @param {string} [badgeId]
         * @param {boolean} [digitalLeads]
         * @param {boolean} [hasMarketingPackage]
         * @param {boolean} [hasActiveContract]
         * @param {boolean} [hasUnusedAllocations]
         * @param {boolean} [wantsExclusiveLeads]
         * @param {string} [partnerAssigneeClientConsultantEmployeeId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersIndex(partnerId?: string, state?: string, isEnabled?: boolean, employeeId?: string, geoCode?: string, satisfaction?: string, punchesUseSpeed?: string, refillOfferId?: string, industryId?: string, industry?: string, geoPoint?: string, geoRect?: string, geoDistance?: number, sort?: 'id' | 'relevance' | 'created_at' | 'company_name' | 'state' | 'satisfaction' | 'employee_name' | 'punches_monthly' | 'punches_use_speed' | 'last_login' | 'last_lead_bought' | 'revenue_6months' | 'last_invoice' | 'avg_punch_price_6months' | 'avg_due_days_6months' | 'last_coupon_size' | 'punches_left' | 'punches_spent_monthly' | 'punches_spent_6months' | 'punches_bought_6months' | 'golden_ratio_6months' | 'refill' | 'rating' | 'distance' | 'last_phone_call' | 'canvas_status' | 'address' | 'tasks' | 'last_subscription_start_date' | 'last_published_quote' | 'published_quotes' | 'win_ratio' | 'unmatched' | 'unmatched_percent' | 'digital_leads' | 'has_active_contract' | 'deallocations' | 'overdue_amount' | 'active_contracts_count' | 'active_allocation_pauses_count' | 'active_contracts_amount' | 'next_contract_renewal_at' | 'active_business_units_amount' | 'active_business_units_mrr' | 'current_period_weighted_delivery_trend' | 'success_ratio' | 'publishing_ratio' | 'all_time_purchased_marketplace_leads_value_excl_vat_local_currency' | 'all_time_purchased_marketplace_leads_count' | 'lead_marketplace_partner_spend_excl_vat_local_currency' | 'ascending' | 'descending', canvasStatus?: string, isPublic?: boolean, packageType?: string, badgeId?: string, digitalLeads?: boolean, hasMarketingPackage?: boolean, hasActiveContract?: boolean, hasUnusedAllocations?: boolean, wantsExclusiveLeads?: boolean, partnerAssigneeClientConsultantEmployeeId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/partners`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (isEnabled !== undefined) {
                localVarQueryParameter['is_enabled'] = isEnabled;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employee_id'] = employeeId;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (satisfaction !== undefined) {
                localVarQueryParameter['satisfaction'] = satisfaction;
            }

            if (punchesUseSpeed !== undefined) {
                localVarQueryParameter['punches_use_speed'] = punchesUseSpeed;
            }

            if (refillOfferId !== undefined) {
                localVarQueryParameter['refill_offer_id'] = refillOfferId;
            }

            if (industryId !== undefined) {
                localVarQueryParameter['industry_id'] = industryId;
            }

            if (industry !== undefined) {
                localVarQueryParameter['industry'] = industry;
            }

            if (geoPoint !== undefined) {
                localVarQueryParameter['geo_point'] = geoPoint;
            }

            if (geoRect !== undefined) {
                localVarQueryParameter['geo_rect'] = geoRect;
            }

            if (geoDistance !== undefined) {
                localVarQueryParameter['geo_distance'] = geoDistance;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (canvasStatus !== undefined) {
                localVarQueryParameter['canvas_status'] = canvasStatus;
            }

            if (isPublic !== undefined) {
                localVarQueryParameter['is_public'] = isPublic;
            }

            if (packageType !== undefined) {
                localVarQueryParameter['package_type'] = packageType;
            }

            if (badgeId !== undefined) {
                localVarQueryParameter['badge_id'] = badgeId;
            }

            if (digitalLeads !== undefined) {
                localVarQueryParameter['digital_leads'] = digitalLeads;
            }

            if (hasMarketingPackage !== undefined) {
                localVarQueryParameter['has_marketing_package'] = hasMarketingPackage;
            }

            if (hasActiveContract !== undefined) {
                localVarQueryParameter['has_active_contract'] = hasActiveContract;
            }

            if (hasUnusedAllocations !== undefined) {
                localVarQueryParameter['has_unused_allocations'] = hasUnusedAllocations;
            }

            if (wantsExclusiveLeads !== undefined) {
                localVarQueryParameter['wants_exclusive_leads'] = wantsExclusiveLeads;
            }

            if (partnerAssigneeClientConsultantEmployeeId !== undefined) {
                localVarQueryParameter['partner_assignee_client_consultant_employee_id'] = partnerAssigneeClientConsultantEmployeeId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerIndustryResource} partnerIndustryResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersIndustriesCreate(partnerId: string, partnerIndustryResource: PartnerIndustryResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersIndustriesCreate.');
            }
            // verify required parameter 'partnerIndustryResource' is not null or undefined
            if (partnerIndustryResource === null || partnerIndustryResource === undefined) {
                throw new RequiredError('partnerIndustryResource','Required parameter partnerIndustryResource was null or undefined when calling partnersIndustriesCreate.');
            }
            const localVarPath = `/partners/{partner_id}/industries`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerIndustryResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerIndustryResource || {}) : (partnerIndustryResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} industryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersIndustriesDelete(partnerId: string, industryId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersIndustriesDelete.');
            }
            // verify required parameter 'industryId' is not null or undefined
            if (industryId === null || industryId === undefined) {
                throw new RequiredError('industryId','Required parameter industryId was null or undefined when calling partnersIndustriesDelete.');
            }
            const localVarPath = `/partners/{partner_id}/industries/{industry_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"industry_id"}}`, encodeURIComponent(String(industryId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} industryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersIndustriesGet(partnerId: string, industryId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersIndustriesGet.');
            }
            // verify required parameter 'industryId' is not null or undefined
            if (industryId === null || industryId === undefined) {
                throw new RequiredError('industryId','Required parameter industryId was null or undefined when calling partnersIndustriesGet.');
            }
            const localVarPath = `/partners/{partner_id}/industries/{industry_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"industry_id"}}`, encodeURIComponent(String(industryId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [geoCode]
         * @param {boolean} [isActive]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersIndustriesIndex(partnerId: string, geoCode?: string, isActive?: boolean, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersIndustriesIndex.');
            }
            const localVarPath = `/partners/{partner_id}/industries`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerIndustriesResource} partnerIndustriesResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersIndustriesUpdate(partnerId: string, partnerIndustriesResource: PartnerIndustriesResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersIndustriesUpdate.');
            }
            // verify required parameter 'partnerIndustriesResource' is not null or undefined
            if (partnerIndustriesResource === null || partnerIndustriesResource === undefined) {
                throw new RequiredError('partnerIndustriesResource','Required parameter partnerIndustriesResource was null or undefined when calling partnersIndustriesUpdate.');
            }
            const localVarPath = `/partners/{partner_id}/industries`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerIndustriesResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerIndustriesResource || {}) : (partnerIndustriesResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [processAtGte] Get invoicings processed at after a date.
         * @param {string} [processAtLte] Get invoicings processed at after a date.
         * @param {string} [partnerId] Filter by zero or more partner IDs.
         * @param {'lead_budget' | 'pay_per_spot'} [businessModel] Lead budget or pay-per-spot.
         * @param {'process_at' | 'ascending' | 'descending'} [sort] Sort the invoicings.
         * @param {string} [geoCode] Filter by geo codes.
         * @param {string} [employeeId] Filter by partner&#39;s employee.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersInvoicingsIndex(processAtGte?: string, processAtLte?: string, partnerId?: string, businessModel?: 'lead_budget' | 'pay_per_spot', sort?: 'process_at' | 'ascending' | 'descending', geoCode?: string, employeeId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/partners/invoicings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (processAtGte !== undefined) {
                localVarQueryParameter['process_at_gte'] = processAtGte;
            }

            if (processAtLte !== undefined) {
                localVarQueryParameter['process_at_lte'] = processAtLte;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (businessModel !== undefined) {
                localVarQueryParameter['business_model'] = businessModel;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employee_id'] = employeeId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [processAtGte] Get invoicings processed at after a date.
         * @param {string} [processAtLte] Get invoicings processed at after a date.
         * @param {'lead_budget' | 'pay_per_spot'} [businessModel] Lead budget or pay-per-spot.
         * @param {'process_at' | 'ascending' | 'descending'} [sort] Sort the invoicings.
         * @param {string} [geoCode] Filter by geo codes.
         * @param {string} [employeeId] Filter by partner&#39;s employee.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersInvoicingsIndex_11(partnerId: string, processAtGte?: string, processAtLte?: string, businessModel?: 'lead_budget' | 'pay_per_spot', sort?: 'process_at' | 'ascending' | 'descending', geoCode?: string, employeeId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersInvoicingsIndex_11.');
            }
            const localVarPath = `/partners/{partner_id}/invoicings`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (processAtGte !== undefined) {
                localVarQueryParameter['process_at_gte'] = processAtGte;
            }

            if (processAtLte !== undefined) {
                localVarQueryParameter['process_at_lte'] = processAtLte;
            }

            if (businessModel !== undefined) {
                localVarQueryParameter['business_model'] = businessModel;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employee_id'] = employeeId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {LeadTypeResource} leadTypeResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersLeadtypesCreate(partnerId: string, leadTypeResource: LeadTypeResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersLeadtypesCreate.');
            }
            // verify required parameter 'leadTypeResource' is not null or undefined
            if (leadTypeResource === null || leadTypeResource === undefined) {
                throw new RequiredError('leadTypeResource','Required parameter leadTypeResource was null or undefined when calling partnersLeadtypesCreate.');
            }
            const localVarPath = `/partners/{partner_id}/lead_types`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LeadTypeResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(leadTypeResource || {}) : (leadTypeResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} leadTypeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersLeadtypesDelete(partnerId: string, leadTypeId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersLeadtypesDelete.');
            }
            // verify required parameter 'leadTypeId' is not null or undefined
            if (leadTypeId === null || leadTypeId === undefined) {
                throw new RequiredError('leadTypeId','Required parameter leadTypeId was null or undefined when calling partnersLeadtypesDelete.');
            }
            const localVarPath = `/partners/{partner_id}/lead_types/{lead_type_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"lead_type_id"}}`, encodeURIComponent(String(leadTypeId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} leadTypeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersLeadtypesGet(leadTypeId: string, options: any = {}): FetchArgs {
            // verify required parameter 'leadTypeId' is not null or undefined
            if (leadTypeId === null || leadTypeId === undefined) {
                throw new RequiredError('leadTypeId','Required parameter leadTypeId was null or undefined when calling partnersLeadtypesGet.');
            }
            const localVarPath = `/partners/lead_types/{lead_type_id}`
                .replace(`{${"lead_type_id"}}`, encodeURIComponent(String(leadTypeId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} leadTypeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersLeadtypesGet_12(partnerId: string, leadTypeId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersLeadtypesGet_12.');
            }
            // verify required parameter 'leadTypeId' is not null or undefined
            if (leadTypeId === null || leadTypeId === undefined) {
                throw new RequiredError('leadTypeId','Required parameter leadTypeId was null or undefined when calling partnersLeadtypesGet_12.');
            }
            const localVarPath = `/partners/{partner_id}/lead_types/{lead_type_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"lead_type_id"}}`, encodeURIComponent(String(leadTypeId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [partnerId] Partner ID. @var int
         * @param {string} [types] Partner type enum. @var array
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersLeadtypesIndex(partnerId?: number, types?: string, geoCode?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/partners/lead_types`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (types !== undefined) {
                localVarQueryParameter['types'] = types;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [types] Partner type enum. @var array
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersLeadtypesIndex_13(partnerId: string, types?: string, geoCode?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersLeadtypesIndex_13.');
            }
            const localVarPath = `/partners/{partner_id}/lead_types`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (types !== undefined) {
                localVarQueryParameter['types'] = types;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerLeadTypesResource} partnerLeadTypesResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersLeadtypesUpdate(partnerId: string, partnerLeadTypesResource: PartnerLeadTypesResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersLeadtypesUpdate.');
            }
            // verify required parameter 'partnerLeadTypesResource' is not null or undefined
            if (partnerLeadTypesResource === null || partnerLeadTypesResource === undefined) {
                throw new RequiredError('partnerLeadTypesResource','Required parameter partnerLeadTypesResource was null or undefined when calling partnersLeadtypesUpdate.');
            }
            const localVarPath = `/partners/{partner_id}/lead_types`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerLeadTypesResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerLeadTypesResource || {}) : (partnerLeadTypesResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {PartnerNoteResource} partnerNoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersNotesCreate(partnerNoteResource: PartnerNoteResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerNoteResource' is not null or undefined
            if (partnerNoteResource === null || partnerNoteResource === undefined) {
                throw new RequiredError('partnerNoteResource','Required parameter partnerNoteResource was null or undefined when calling partnersNotesCreate.');
            }
            const localVarPath = `/partners/notes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerNoteResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerNoteResource || {}) : (partnerNoteResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerNoteResource} partnerNoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersNotesCreate_14(partnerId: string, partnerNoteResource: PartnerNoteResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersNotesCreate_14.');
            }
            // verify required parameter 'partnerNoteResource' is not null or undefined
            if (partnerNoteResource === null || partnerNoteResource === undefined) {
                throw new RequiredError('partnerNoteResource','Required parameter partnerNoteResource was null or undefined when calling partnersNotesCreate_14.');
            }
            const localVarPath = `/partners/{partner_id}/notes`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerNoteResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerNoteResource || {}) : (partnerNoteResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersNotesGet(partnerId: string, partnerNoteId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersNotesGet.');
            }
            // verify required parameter 'partnerNoteId' is not null or undefined
            if (partnerNoteId === null || partnerNoteId === undefined) {
                throw new RequiredError('partnerNoteId','Required parameter partnerNoteId was null or undefined when calling partnersNotesGet.');
            }
            const localVarPath = `/partners/{partner_id}/notes/{partner_note_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_note_id"}}`, encodeURIComponent(String(partnerNoteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [partnerId]
         * @param {'all' | 'satisfactions' | 'notes'} [types]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersNotesIndex(partnerId?: number, types?: 'all' | 'satisfactions' | 'notes', limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/partners/notes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (types !== undefined) {
                localVarQueryParameter['types'] = types;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {'all' | 'satisfactions' | 'notes'} [types]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersNotesIndex_15(partnerId: string, types?: 'all' | 'satisfactions' | 'notes', limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersNotesIndex_15.');
            }
            const localVarPath = `/partners/{partner_id}/notes`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (types !== undefined) {
                localVarQueryParameter['types'] = types;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerQuoteProductCommentResource} partnerQuoteProductCommentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersQuoteproductcommentsCreate(partnerId: string, partnerQuoteProductCommentResource: PartnerQuoteProductCommentResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersQuoteproductcommentsCreate.');
            }
            // verify required parameter 'partnerQuoteProductCommentResource' is not null or undefined
            if (partnerQuoteProductCommentResource === null || partnerQuoteProductCommentResource === undefined) {
                throw new RequiredError('partnerQuoteProductCommentResource','Required parameter partnerQuoteProductCommentResource was null or undefined when calling partnersQuoteproductcommentsCreate.');
            }
            const localVarPath = `/partners/{partner_id}/quoteproductcomments`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerQuoteProductCommentResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerQuoteProductCommentResource || {}) : (partnerQuoteProductCommentResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} quoteProductCommentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersQuoteproductcommentsDelete(partnerId: string, quoteProductCommentId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersQuoteproductcommentsDelete.');
            }
            // verify required parameter 'quoteProductCommentId' is not null or undefined
            if (quoteProductCommentId === null || quoteProductCommentId === undefined) {
                throw new RequiredError('quoteProductCommentId','Required parameter quoteProductCommentId was null or undefined when calling partnersQuoteproductcommentsDelete.');
            }
            const localVarPath = `/partners/{partner_id}/quoteproductcomments/{quote_product_comment_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"quote_product_comment_id"}}`, encodeURIComponent(String(quoteProductCommentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersQuoteproductcommentsIndex(partnerId: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersQuoteproductcommentsIndex.');
            }
            const localVarPath = `/partners/{partner_id}/quoteproductcomments`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerQuoteProductPriceResource} partnerQuoteProductPriceResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersQuoteproductpricesCreate(partnerId: string, partnerQuoteProductPriceResource: PartnerQuoteProductPriceResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersQuoteproductpricesCreate.');
            }
            // verify required parameter 'partnerQuoteProductPriceResource' is not null or undefined
            if (partnerQuoteProductPriceResource === null || partnerQuoteProductPriceResource === undefined) {
                throw new RequiredError('partnerQuoteProductPriceResource','Required parameter partnerQuoteProductPriceResource was null or undefined when calling partnersQuoteproductpricesCreate.');
            }
            const localVarPath = `/partners/{partner_id}/quoteproductprices`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerQuoteProductPriceResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerQuoteProductPriceResource || {}) : (partnerQuoteProductPriceResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} quoteProductPriceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersQuoteproductpricesDelete(partnerId: string, quoteProductPriceId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersQuoteproductpricesDelete.');
            }
            // verify required parameter 'quoteProductPriceId' is not null or undefined
            if (quoteProductPriceId === null || quoteProductPriceId === undefined) {
                throw new RequiredError('quoteProductPriceId','Required parameter quoteProductPriceId was null or undefined when calling partnersQuoteproductpricesDelete.');
            }
            const localVarPath = `/partners/{partner_id}/quoteproductprices/{quote_product_price_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"quote_product_price_id"}}`, encodeURIComponent(String(quoteProductPriceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersQuoteproductpricesIndex(partnerId: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersQuoteproductpricesIndex.');
            }
            const localVarPath = `/partners/{partner_id}/quoteproductprices`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [partnerId] IDs of partners
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersQuotevendorsIndex(partnerId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/partners/quotevendors`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerRevenueRangeResource} partnerRevenueRangeResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersRevenuerangesCreate(partnerId: string, partnerRevenueRangeResource: PartnerRevenueRangeResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersRevenuerangesCreate.');
            }
            // verify required parameter 'partnerRevenueRangeResource' is not null or undefined
            if (partnerRevenueRangeResource === null || partnerRevenueRangeResource === undefined) {
                throw new RequiredError('partnerRevenueRangeResource','Required parameter partnerRevenueRangeResource was null or undefined when calling partnersRevenuerangesCreate.');
            }
            const localVarPath = `/partners/{partner_id}/revenueranges`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerRevenueRangeResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerRevenueRangeResource || {}) : (partnerRevenueRangeResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerRevenueSegmentActionResource} partnerRevenueSegmentActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersRevenuesegmentsActionsCreate(partnerId: string, partnerRevenueSegmentActionResource: PartnerRevenueSegmentActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersRevenuesegmentsActionsCreate.');
            }
            // verify required parameter 'partnerRevenueSegmentActionResource' is not null or undefined
            if (partnerRevenueSegmentActionResource === null || partnerRevenueSegmentActionResource === undefined) {
                throw new RequiredError('partnerRevenueSegmentActionResource','Required parameter partnerRevenueSegmentActionResource was null or undefined when calling partnersRevenuesegmentsActionsCreate.');
            }
            const localVarPath = `/partners/{partner_id}/revenuesegments/actions`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerRevenueSegmentActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerRevenueSegmentActionResource || {}) : (partnerRevenueSegmentActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {PartnerRevenueSegmentResource} partnerRevenueSegmentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersRevenuesegmentsCreate(partnerRevenueSegmentResource: PartnerRevenueSegmentResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerRevenueSegmentResource' is not null or undefined
            if (partnerRevenueSegmentResource === null || partnerRevenueSegmentResource === undefined) {
                throw new RequiredError('partnerRevenueSegmentResource','Required parameter partnerRevenueSegmentResource was null or undefined when calling partnersRevenuesegmentsCreate.');
            }
            const localVarPath = `/partners/revenuesegments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerRevenueSegmentResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerRevenueSegmentResource || {}) : (partnerRevenueSegmentResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerRevenueSegmentResource} partnerRevenueSegmentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersRevenuesegmentsCreate_16(partnerId: string, partnerRevenueSegmentResource: PartnerRevenueSegmentResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersRevenuesegmentsCreate_16.');
            }
            // verify required parameter 'partnerRevenueSegmentResource' is not null or undefined
            if (partnerRevenueSegmentResource === null || partnerRevenueSegmentResource === undefined) {
                throw new RequiredError('partnerRevenueSegmentResource','Required parameter partnerRevenueSegmentResource was null or undefined when calling partnersRevenuesegmentsCreate_16.');
            }
            const localVarPath = `/partners/{partner_id}/revenuesegments`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerRevenueSegmentResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerRevenueSegmentResource || {}) : (partnerRevenueSegmentResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerRevenueSegmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersRevenuesegmentsDelete(partnerId: string, partnerRevenueSegmentId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersRevenuesegmentsDelete.');
            }
            // verify required parameter 'partnerRevenueSegmentId' is not null or undefined
            if (partnerRevenueSegmentId === null || partnerRevenueSegmentId === undefined) {
                throw new RequiredError('partnerRevenueSegmentId','Required parameter partnerRevenueSegmentId was null or undefined when calling partnersRevenuesegmentsDelete.');
            }
            const localVarPath = `/partners/{partner_id}/revenuesegments/{partner_revenue_segment_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_revenue_segment_id"}}`, encodeURIComponent(String(partnerRevenueSegmentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersRevenuesegmentsIndex(partnerId: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersRevenuesegmentsIndex.');
            }
            const localVarPath = `/partners/{partner_id}/revenuesegments`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSectorsIndex(partnerId: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersSectorsIndex.');
            }
            const localVarPath = `/partners/{partner_id}/sectors`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerSectorsResource} partnerSectorsResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSectorsUpdate(partnerId: string, partnerSectorsResource: PartnerSectorsResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersSectorsUpdate.');
            }
            // verify required parameter 'partnerSectorsResource' is not null or undefined
            if (partnerSectorsResource === null || partnerSectorsResource === undefined) {
                throw new RequiredError('partnerSectorsResource','Required parameter partnerSectorsResource was null or undefined when calling partnersSectorsUpdate.');
            }
            const localVarPath = `/partners/{partner_id}/sectors`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerSectorsResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerSectorsResource || {}) : (partnerSectorsResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerUniqueSellingPointResource} partnerUniqueSellingPointResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSellingpointsCreate(partnerId: string, partnerUniqueSellingPointResource: PartnerUniqueSellingPointResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersSellingpointsCreate.');
            }
            // verify required parameter 'partnerUniqueSellingPointResource' is not null or undefined
            if (partnerUniqueSellingPointResource === null || partnerUniqueSellingPointResource === undefined) {
                throw new RequiredError('partnerUniqueSellingPointResource','Required parameter partnerUniqueSellingPointResource was null or undefined when calling partnersSellingpointsCreate.');
            }
            const localVarPath = `/partners/{partner_id}/sellingpoints`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerUniqueSellingPointResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerUniqueSellingPointResource || {}) : (partnerUniqueSellingPointResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} sellingPointId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSellingpointsDelete(partnerId: string, sellingPointId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersSellingpointsDelete.');
            }
            // verify required parameter 'sellingPointId' is not null or undefined
            if (sellingPointId === null || sellingPointId === undefined) {
                throw new RequiredError('sellingPointId','Required parameter sellingPointId was null or undefined when calling partnersSellingpointsDelete.');
            }
            const localVarPath = `/partners/{partner_id}/sellingpoints/{selling_point_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"selling_point_id"}}`, encodeURIComponent(String(sellingPointId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [id]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSellingpointsIndex(partnerId: string, id?: number, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersSellingpointsIndex.');
            }
            const localVarPath = `/partners/{partner_id}/sellingpoints`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} sellingPointId
         * @param {PartnerUniqueSellingPointResource} partnerUniqueSellingPointResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSellingpointsUpdate(partnerId: string, sellingPointId: string, partnerUniqueSellingPointResource: PartnerUniqueSellingPointResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersSellingpointsUpdate.');
            }
            // verify required parameter 'sellingPointId' is not null or undefined
            if (sellingPointId === null || sellingPointId === undefined) {
                throw new RequiredError('sellingPointId','Required parameter sellingPointId was null or undefined when calling partnersSellingpointsUpdate.');
            }
            // verify required parameter 'partnerUniqueSellingPointResource' is not null or undefined
            if (partnerUniqueSellingPointResource === null || partnerUniqueSellingPointResource === undefined) {
                throw new RequiredError('partnerUniqueSellingPointResource','Required parameter partnerUniqueSellingPointResource was null or undefined when calling partnersSellingpointsUpdate.');
            }
            const localVarPath = `/partners/{partner_id}/sellingpoints/{selling_point_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"selling_point_id"}}`, encodeURIComponent(String(sellingPointId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerUniqueSellingPointResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerUniqueSellingPointResource || {}) : (partnerUniqueSellingPointResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} agreementId
         * @param {PartnerSubscriptionAgreementActionResource} partnerSubscriptionAgreementActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionagreementsActionsCreate(partnerId: string, agreementId: string, partnerSubscriptionAgreementActionResource: PartnerSubscriptionAgreementActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersSubscriptionagreementsActionsCreate.');
            }
            // verify required parameter 'agreementId' is not null or undefined
            if (agreementId === null || agreementId === undefined) {
                throw new RequiredError('agreementId','Required parameter agreementId was null or undefined when calling partnersSubscriptionagreementsActionsCreate.');
            }
            // verify required parameter 'partnerSubscriptionAgreementActionResource' is not null or undefined
            if (partnerSubscriptionAgreementActionResource === null || partnerSubscriptionAgreementActionResource === undefined) {
                throw new RequiredError('partnerSubscriptionAgreementActionResource','Required parameter partnerSubscriptionAgreementActionResource was null or undefined when calling partnersSubscriptionagreementsActionsCreate.');
            }
            const localVarPath = `/partners/{partner_id}/subscriptionagreements/{agreement_id}/actions`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"agreement_id"}}`, encodeURIComponent(String(agreementId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerSubscriptionAgreementActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerSubscriptionAgreementActionResource || {}) : (partnerSubscriptionAgreementActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerSubscriptionAgreementResource} partnerSubscriptionAgreementResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionagreementsCreate(partnerId: string, partnerSubscriptionAgreementResource: PartnerSubscriptionAgreementResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersSubscriptionagreementsCreate.');
            }
            // verify required parameter 'partnerSubscriptionAgreementResource' is not null or undefined
            if (partnerSubscriptionAgreementResource === null || partnerSubscriptionAgreementResource === undefined) {
                throw new RequiredError('partnerSubscriptionAgreementResource','Required parameter partnerSubscriptionAgreementResource was null or undefined when calling partnersSubscriptionagreementsCreate.');
            }
            const localVarPath = `/partners/{partner_id}/subscriptionagreements`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerSubscriptionAgreementResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerSubscriptionAgreementResource || {}) : (partnerSubscriptionAgreementResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {boolean} [isAvailableForAgreement] List agreemenets that are available.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionagreementsIndex(partnerId: string, isAvailableForAgreement?: boolean, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersSubscriptionagreementsIndex.');
            }
            const localVarPath = `/partners/{partner_id}/subscriptionagreements`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (isAvailableForAgreement !== undefined) {
                localVarQueryParameter['is_available_for_agreement'] = isAvailableForAgreement;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} agreementId
         * @param {PartnerSubscriptionAgreementResource} partnerSubscriptionAgreementResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionagreementsUpdate(partnerId: string, agreementId: string, partnerSubscriptionAgreementResource: PartnerSubscriptionAgreementResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersSubscriptionagreementsUpdate.');
            }
            // verify required parameter 'agreementId' is not null or undefined
            if (agreementId === null || agreementId === undefined) {
                throw new RequiredError('agreementId','Required parameter agreementId was null or undefined when calling partnersSubscriptionagreementsUpdate.');
            }
            // verify required parameter 'partnerSubscriptionAgreementResource' is not null or undefined
            if (partnerSubscriptionAgreementResource === null || partnerSubscriptionAgreementResource === undefined) {
                throw new RequiredError('partnerSubscriptionAgreementResource','Required parameter partnerSubscriptionAgreementResource was null or undefined when calling partnersSubscriptionagreementsUpdate.');
            }
            const localVarPath = `/partners/{partner_id}/subscriptionagreements/{agreement_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"agreement_id"}}`, encodeURIComponent(String(agreementId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerSubscriptionAgreementResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerSubscriptionAgreementResource || {}) : (partnerSubscriptionAgreementResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} subscriptionId
         * @param {PartnerSubscriptionActionResource} partnerSubscriptionActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsActionsCreate(partnerId: string, subscriptionId: string, partnerSubscriptionActionResource: PartnerSubscriptionActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersSubscriptionsActionsCreate.');
            }
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling partnersSubscriptionsActionsCreate.');
            }
            // verify required parameter 'partnerSubscriptionActionResource' is not null or undefined
            if (partnerSubscriptionActionResource === null || partnerSubscriptionActionResource === undefined) {
                throw new RequiredError('partnerSubscriptionActionResource','Required parameter partnerSubscriptionActionResource was null or undefined when calling partnersSubscriptionsActionsCreate.');
            }
            const localVarPath = `/partners/{partner_id}/subscriptions/{subscription_id}/actions`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"subscription_id"}}`, encodeURIComponent(String(subscriptionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerSubscriptionActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerSubscriptionActionResource || {}) : (partnerSubscriptionActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerSubscriptionResource} partnerSubscriptionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsCreate(partnerId: string, partnerSubscriptionResource: PartnerSubscriptionResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersSubscriptionsCreate.');
            }
            // verify required parameter 'partnerSubscriptionResource' is not null or undefined
            if (partnerSubscriptionResource === null || partnerSubscriptionResource === undefined) {
                throw new RequiredError('partnerSubscriptionResource','Required parameter partnerSubscriptionResource was null or undefined when calling partnersSubscriptionsCreate.');
            }
            const localVarPath = `/partners/{partner_id}/subscriptions`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerSubscriptionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerSubscriptionResource || {}) : (partnerSubscriptionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [isActive] Only include active subscriptions.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsIndex(partnerId: string, isActive?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersSubscriptionsIndex.');
            }
            const localVarPath = `/partners/{partner_id}/subscriptions`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} subscriptionId
         * @param {PartnerSubscriptionPauseResource} partnerSubscriptionPauseResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsPausesCreate(partnerId: string, subscriptionId: string, partnerSubscriptionPauseResource: PartnerSubscriptionPauseResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersSubscriptionsPausesCreate.');
            }
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling partnersSubscriptionsPausesCreate.');
            }
            // verify required parameter 'partnerSubscriptionPauseResource' is not null or undefined
            if (partnerSubscriptionPauseResource === null || partnerSubscriptionPauseResource === undefined) {
                throw new RequiredError('partnerSubscriptionPauseResource','Required parameter partnerSubscriptionPauseResource was null or undefined when calling partnersSubscriptionsPausesCreate.');
            }
            const localVarPath = `/partners/{partner_id}/subscriptions/{subscription_id}/pauses`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"subscription_id"}}`, encodeURIComponent(String(subscriptionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerSubscriptionPauseResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerSubscriptionPauseResource || {}) : (partnerSubscriptionPauseResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} subscriptionId
         * @param {string} pauseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsPausesDelete(partnerId: string, subscriptionId: string, pauseId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersSubscriptionsPausesDelete.');
            }
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling partnersSubscriptionsPausesDelete.');
            }
            // verify required parameter 'pauseId' is not null or undefined
            if (pauseId === null || pauseId === undefined) {
                throw new RequiredError('pauseId','Required parameter pauseId was null or undefined when calling partnersSubscriptionsPausesDelete.');
            }
            const localVarPath = `/partners/{partner_id}/subscriptions/{subscription_id}/pauses/{pause_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"subscription_id"}}`, encodeURIComponent(String(subscriptionId)))
                .replace(`{${"pause_id"}}`, encodeURIComponent(String(pauseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} subscriptionId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsPausesIndex(partnerId: string, subscriptionId: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersSubscriptionsPausesIndex.');
            }
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling partnersSubscriptionsPausesIndex.');
            }
            const localVarPath = `/partners/{partner_id}/subscriptions/{subscription_id}/pauses`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"subscription_id"}}`, encodeURIComponent(String(subscriptionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} subscriptionId
         * @param {PartnerSubscriptionPriceResource} partnerSubscriptionPriceResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsPricesCreate(partnerId: string, subscriptionId: string, partnerSubscriptionPriceResource: PartnerSubscriptionPriceResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersSubscriptionsPricesCreate.');
            }
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling partnersSubscriptionsPricesCreate.');
            }
            // verify required parameter 'partnerSubscriptionPriceResource' is not null or undefined
            if (partnerSubscriptionPriceResource === null || partnerSubscriptionPriceResource === undefined) {
                throw new RequiredError('partnerSubscriptionPriceResource','Required parameter partnerSubscriptionPriceResource was null or undefined when calling partnersSubscriptionsPricesCreate.');
            }
            const localVarPath = `/partners/{partner_id}/subscriptions/{subscription_id}/prices`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"subscription_id"}}`, encodeURIComponent(String(subscriptionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerSubscriptionPriceResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerSubscriptionPriceResource || {}) : (partnerSubscriptionPriceResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} subscriptionId
         * @param {string} subscriptionPriceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsPricesDelete(partnerId: string, subscriptionId: string, subscriptionPriceId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersSubscriptionsPricesDelete.');
            }
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling partnersSubscriptionsPricesDelete.');
            }
            // verify required parameter 'subscriptionPriceId' is not null or undefined
            if (subscriptionPriceId === null || subscriptionPriceId === undefined) {
                throw new RequiredError('subscriptionPriceId','Required parameter subscriptionPriceId was null or undefined when calling partnersSubscriptionsPricesDelete.');
            }
            const localVarPath = `/partners/{partner_id}/subscriptions/{subscription_id}/prices/{subscription_price_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"subscription_id"}}`, encodeURIComponent(String(subscriptionId)))
                .replace(`{${"subscription_price_id"}}`, encodeURIComponent(String(subscriptionPriceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} subscriptionId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsPricesIndex(partnerId: string, subscriptionId: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersSubscriptionsPricesIndex.');
            }
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling partnersSubscriptionsPricesIndex.');
            }
            const localVarPath = `/partners/{partner_id}/subscriptions/{subscription_id}/prices`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"subscription_id"}}`, encodeURIComponent(String(subscriptionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} subscriptionId
         * @param {PartnerSubscriptionResource} partnerSubscriptionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsUpdate(partnerId: string, subscriptionId: string, partnerSubscriptionResource: PartnerSubscriptionResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersSubscriptionsUpdate.');
            }
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling partnersSubscriptionsUpdate.');
            }
            // verify required parameter 'partnerSubscriptionResource' is not null or undefined
            if (partnerSubscriptionResource === null || partnerSubscriptionResource === undefined) {
                throw new RequiredError('partnerSubscriptionResource','Required parameter partnerSubscriptionResource was null or undefined when calling partnersSubscriptionsUpdate.');
            }
            const localVarPath = `/partners/{partner_id}/subscriptions/{subscription_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"subscription_id"}}`, encodeURIComponent(String(subscriptionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerSubscriptionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerSubscriptionResource || {}) : (partnerSubscriptionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [geoCode] Filter by geo code.
         * @param {boolean} [isHidden] Filter hidden types.
         * @param {'' | 'lead_marketplace' | 'ascending' | 'descending'} [identifier] Filter by identifier.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptiontypesIndex(geoCode?: string, isHidden?: boolean, identifier?: '' | 'lead_marketplace' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/partners/subscriptiontypes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (isHidden !== undefined) {
                localVarQueryParameter['is_hidden'] = isHidden;
            }

            if (identifier !== undefined) {
                localVarQueryParameter['identifier'] = identifier;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [geoCode] Filter by geo code.
         * @param {boolean} [isHidden] Filter hidden types.
         * @param {'' | 'lead_marketplace' | 'ascending' | 'descending'} [identifier] Filter by identifier.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptiontypesIndex_17(partnerId: string, geoCode?: string, isHidden?: boolean, identifier?: '' | 'lead_marketplace' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersSubscriptiontypesIndex_17.');
            }
            const localVarPath = `/partners/{partner_id}/subscriptiontypes`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (isHidden !== undefined) {
                localVarQueryParameter['is_hidden'] = isHidden;
            }

            if (identifier !== undefined) {
                localVarQueryParameter['identifier'] = identifier;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [limit] Limit the number of suggest resources pr. page.
         * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
         * @param {string} [query] The search query to search by.
         * @param {string} [type] Type to search for.
         * @param {string} [partnerState] Partner state.
         * @param {number} [page] The page position in the result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSuggestIndex(limit?: number, geoCode?: string, query?: string, type?: string, partnerState?: string, page?: number, options: any = {}): FetchArgs {
            const localVarPath = `/partners/suggest`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (partnerState !== undefined) {
                localVarQueryParameter['partner_state'] = partnerState;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [id]
         * @param {string} [identifier]
         * @param {number} [industryId]
         * @param {string} [industryIdentifier]
         * @param {string} [geoCode]
         * @param {string} [sort]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersTitlesIndex(id?: number, identifier?: string, industryId?: number, industryIdentifier?: string, geoCode?: string, sort?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/partners/titles`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (identifier !== undefined) {
                localVarQueryParameter['identifier'] = identifier;
            }

            if (industryId !== undefined) {
                localVarQueryParameter['industry_id'] = industryId;
            }

            if (industryIdentifier !== undefined) {
                localVarQueryParameter['industry_identifier'] = industryIdentifier;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersTransactionsIndex(partnerId: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersTransactionsIndex.');
            }
            const localVarPath = `/partners/{partner_id}/transactions`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerResource} partnerResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUpdate(partnerId: string, partnerResource: PartnerResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersUpdate.');
            }
            // verify required parameter 'partnerResource' is not null or undefined
            if (partnerResource === null || partnerResource === undefined) {
                throw new RequiredError('partnerResource','Required parameter partnerResource was null or undefined when calling partnersUpdate.');
            }
            const localVarPath = `/partners/{partner_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerResource || {}) : (partnerResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerUserId
         * @param {PartnerUserActionResource} partnerUserActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersActionsCreate(partnerId: string, partnerUserId: string, partnerUserActionResource: PartnerUserActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersUsersActionsCreate.');
            }
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling partnersUsersActionsCreate.');
            }
            // verify required parameter 'partnerUserActionResource' is not null or undefined
            if (partnerUserActionResource === null || partnerUserActionResource === undefined) {
                throw new RequiredError('partnerUserActionResource','Required parameter partnerUserActionResource was null or undefined when calling partnersUsersActionsCreate.');
            }
            const localVarPath = `/partners/{partner_id}/users/{partner_user_id}/actions`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerUserActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerUserActionResource || {}) : (partnerUserActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} contentKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersContentsDelete(partnerUserId: string, contentKey: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling partnersUsersContentsDelete.');
            }
            // verify required parameter 'contentKey' is not null or undefined
            if (contentKey === null || contentKey === undefined) {
                throw new RequiredError('contentKey','Required parameter contentKey was null or undefined when calling partnersUsersContentsDelete.');
            }
            const localVarPath = `/partners/users/{partner_user_id}/contents/{content_key}`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)))
                .replace(`{${"content_key"}}`, encodeURIComponent(String(contentKey)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerUserId
         * @param {string} contentKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersContentsDelete_18(partnerId: string, partnerUserId: string, contentKey: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersUsersContentsDelete_18.');
            }
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling partnersUsersContentsDelete_18.');
            }
            // verify required parameter 'contentKey' is not null or undefined
            if (contentKey === null || contentKey === undefined) {
                throw new RequiredError('contentKey','Required parameter contentKey was null or undefined when calling partnersUsersContentsDelete_18.');
            }
            const localVarPath = `/partners/{partner_id}/users/{partner_user_id}/contents/{content_key}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)))
                .replace(`{${"content_key"}}`, encodeURIComponent(String(contentKey)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} contentKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersContentsGet(partnerUserId: string, contentKey: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling partnersUsersContentsGet.');
            }
            // verify required parameter 'contentKey' is not null or undefined
            if (contentKey === null || contentKey === undefined) {
                throw new RequiredError('contentKey','Required parameter contentKey was null or undefined when calling partnersUsersContentsGet.');
            }
            const localVarPath = `/partners/users/{partner_user_id}/contents/{content_key}`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)))
                .replace(`{${"content_key"}}`, encodeURIComponent(String(contentKey)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerUserId
         * @param {string} contentKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersContentsGet_19(partnerId: string, partnerUserId: string, contentKey: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersUsersContentsGet_19.');
            }
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling partnersUsersContentsGet_19.');
            }
            // verify required parameter 'contentKey' is not null or undefined
            if (contentKey === null || contentKey === undefined) {
                throw new RequiredError('contentKey','Required parameter contentKey was null or undefined when calling partnersUsersContentsGet_19.');
            }
            const localVarPath = `/partners/{partner_id}/users/{partner_user_id}/contents/{content_key}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)))
                .replace(`{${"content_key"}}`, encodeURIComponent(String(contentKey)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} contentKey
         * @param {PartnerContentResource} partnerContentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersContentsUpdate(partnerUserId: string, contentKey: string, partnerContentResource: PartnerContentResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling partnersUsersContentsUpdate.');
            }
            // verify required parameter 'contentKey' is not null or undefined
            if (contentKey === null || contentKey === undefined) {
                throw new RequiredError('contentKey','Required parameter contentKey was null or undefined when calling partnersUsersContentsUpdate.');
            }
            // verify required parameter 'partnerContentResource' is not null or undefined
            if (partnerContentResource === null || partnerContentResource === undefined) {
                throw new RequiredError('partnerContentResource','Required parameter partnerContentResource was null or undefined when calling partnersUsersContentsUpdate.');
            }
            const localVarPath = `/partners/users/{partner_user_id}/contents/{content_key}`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)))
                .replace(`{${"content_key"}}`, encodeURIComponent(String(contentKey)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerContentResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerContentResource || {}) : (partnerContentResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerUserId
         * @param {string} contentKey
         * @param {PartnerContentResource} partnerContentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersContentsUpdate_20(partnerId: string, partnerUserId: string, contentKey: string, partnerContentResource: PartnerContentResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersUsersContentsUpdate_20.');
            }
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling partnersUsersContentsUpdate_20.');
            }
            // verify required parameter 'contentKey' is not null or undefined
            if (contentKey === null || contentKey === undefined) {
                throw new RequiredError('contentKey','Required parameter contentKey was null or undefined when calling partnersUsersContentsUpdate_20.');
            }
            // verify required parameter 'partnerContentResource' is not null or undefined
            if (partnerContentResource === null || partnerContentResource === undefined) {
                throw new RequiredError('partnerContentResource','Required parameter partnerContentResource was null or undefined when calling partnersUsersContentsUpdate_20.');
            }
            const localVarPath = `/partners/{partner_id}/users/{partner_user_id}/contents/{content_key}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)))
                .replace(`{${"content_key"}}`, encodeURIComponent(String(contentKey)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerContentResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerContentResource || {}) : (partnerContentResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {PartnerUserResource} partnerUserResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersCreate(partnerUserResource: PartnerUserResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserResource' is not null or undefined
            if (partnerUserResource === null || partnerUserResource === undefined) {
                throw new RequiredError('partnerUserResource','Required parameter partnerUserResource was null or undefined when calling partnersUsersCreate.');
            }
            const localVarPath = `/partners/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerUserResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerUserResource || {}) : (partnerUserResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerUserResource} partnerUserResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersCreate_21(partnerId: string, partnerUserResource: PartnerUserResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersUsersCreate_21.');
            }
            // verify required parameter 'partnerUserResource' is not null or undefined
            if (partnerUserResource === null || partnerUserResource === undefined) {
                throw new RequiredError('partnerUserResource','Required parameter partnerUserResource was null or undefined when calling partnersUsersCreate_21.');
            }
            const localVarPath = `/partners/{partner_id}/users`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerUserResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerUserResource || {}) : (partnerUserResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerUserId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersDelete(partnerId: string, partnerUserId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersUsersDelete.');
            }
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling partnersUsersDelete.');
            }
            const localVarPath = `/partners/{partner_id}/users/{partner_user_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersGet(partnerUserId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling partnersUsersGet.');
            }
            const localVarPath = `/partners/users/{partner_user_id}`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerUserId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersGet_22(partnerId: string, partnerUserId: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersUsersGet_22.');
            }
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling partnersUsersGet_22.');
            }
            const localVarPath = `/partners/{partner_id}/users/{partner_user_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [partnerUserId] Partner User Id.
         * @param {string} [partnerId] Partner id for the partner users to filter by.
         * @param {string} [sort]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersIndex(partnerUserId?: string, partnerId?: string, sort?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/partners/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (partnerUserId !== undefined) {
                localVarQueryParameter['partner_user_id'] = partnerUserId;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [partnerUserId] Partner User Id.
         * @param {string} [sort]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersIndex_23(partnerId: string, partnerUserId?: string, sort?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersUsersIndex_23.');
            }
            const localVarPath = `/partners/{partner_id}/users`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (partnerUserId !== undefined) {
                localVarQueryParameter['partner_user_id'] = partnerUserId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserResource} partnerUserResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersUpdate(partnerUserId: string, partnerUserResource: PartnerUserResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling partnersUsersUpdate.');
            }
            // verify required parameter 'partnerUserResource' is not null or undefined
            if (partnerUserResource === null || partnerUserResource === undefined) {
                throw new RequiredError('partnerUserResource','Required parameter partnerUserResource was null or undefined when calling partnersUsersUpdate.');
            }
            const localVarPath = `/partners/users/{partner_user_id}`
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerUserResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerUserResource || {}) : (partnerUserResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerUserId
         * @param {PartnerUserResource} partnerUserResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersUpdate_24(partnerId: string, partnerUserId: string, partnerUserResource: PartnerUserResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersUsersUpdate_24.');
            }
            // verify required parameter 'partnerUserId' is not null or undefined
            if (partnerUserId === null || partnerUserId === undefined) {
                throw new RequiredError('partnerUserId','Required parameter partnerUserId was null or undefined when calling partnersUsersUpdate_24.');
            }
            // verify required parameter 'partnerUserResource' is not null or undefined
            if (partnerUserResource === null || partnerUserResource === undefined) {
                throw new RequiredError('partnerUserResource','Required parameter partnerUserResource was null or undefined when calling partnersUsersUpdate_24.');
            }
            const localVarPath = `/partners/{partner_id}/users/{partner_user_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partner_user_id"}}`, encodeURIComponent(String(partnerUserId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerUserResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerUserResource || {}) : (partnerUserResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [partnerId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersVerificationsIndex(partnerId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/partners/verifications`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersVerificationsIndex_25(partnerId: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersVerificationsIndex_25.');
            }
            const localVarPath = `/partners/{partner_id}/verifications`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerVerificationResource} partnerVerificationResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersVerificationsUpdate(partnerId: string, partnerVerificationResource: PartnerVerificationResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling partnersVerificationsUpdate.');
            }
            // verify required parameter 'partnerVerificationResource' is not null or undefined
            if (partnerVerificationResource === null || partnerVerificationResource === undefined) {
                throw new RequiredError('partnerVerificationResource','Required parameter partnerVerificationResource was null or undefined when calling partnersVerificationsUpdate.');
            }
            const localVarPath = `/partners/{partner_id}/verifications`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerVerificationResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerVerificationResource || {}) : (partnerVerificationResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnersApi - functional programming interface
 * @export
 */
export const PartnersApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersActionfeesIndex(partnerId: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerActionFeesResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersActionfeesIndex(partnerId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerActionResource} partnerActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersActionsCreate(partnerId: string, partnerActionResource: PartnerActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersActionsCreate(partnerId, partnerActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [partnerId] Filter partners by partner ID
         * @param {string} [state] States for the partners.
         * @param {boolean} [isEnabled]
         * @param {string} [employeeId] Employee id of the partners to filter by.
         * @param {string} [geoCode] Geographic Location Code.
         * @param {string} [satisfaction] Partner Satisfaction Ratio.
         * @param {string} [punchesUseSpeed] Punches use speed.
         * @param {string} [refillOfferId] Filter the partners by their refill offers
         * @param {string} [industryId] Filter the partners by their industry ids
         * @param {string} [industry] Filter the partners by their industry identifiers
         * @param {string} [geoPoint] Location Point to sort partners by
         * @param {string} [geoRect] Location Points to define an area to filter partners by. Provide the bottom left (southwest) and top right (northeast) corners of the reactangle separated by commas, e.g. \&quot;52.19,12.42,52.85,14.22\&quot;.
         * @param {number} [geoDistance] Location filter by distance
         * @param {'id' | 'relevance' | 'created_at' | 'company_name' | 'state' | 'satisfaction' | 'employee_name' | 'punches_monthly' | 'punches_use_speed' | 'last_login' | 'last_lead_bought' | 'revenue_6months' | 'last_invoice' | 'avg_punch_price_6months' | 'avg_due_days_6months' | 'last_coupon_size' | 'punches_left' | 'punches_spent_monthly' | 'punches_spent_6months' | 'punches_bought_6months' | 'golden_ratio_6months' | 'refill' | 'rating' | 'distance' | 'last_phone_call' | 'canvas_status' | 'address' | 'tasks' | 'last_subscription_start_date' | 'last_published_quote' | 'published_quotes' | 'win_ratio' | 'unmatched' | 'unmatched_percent' | 'digital_leads' | 'has_active_contract' | 'deallocations' | 'overdue_amount' | 'active_contracts_count' | 'active_allocation_pauses_count' | 'active_contracts_amount' | 'next_contract_renewal_at' | 'active_business_units_amount' | 'active_business_units_mrr' | 'current_period_weighted_delivery_trend' | 'success_ratio' | 'publishing_ratio' | 'all_time_purchased_marketplace_leads_value_excl_vat_local_currency' | 'all_time_purchased_marketplace_leads_count' | 'lead_marketplace_partner_spend_excl_vat_local_currency' | 'ascending' | 'descending'} [sort] Sort Partner&#39;s by a given property.
         * @param {string} [canvasStatus] Canvas Status
         * @param {boolean} [isPublic] Only show partners that are visible to the public
         * @param {string} [packageType] Partner subscription package type
         * @param {string} [badgeId]
         * @param {boolean} [digitalLeads]
         * @param {boolean} [hasMarketingPackage]
         * @param {boolean} [hasActiveContract]
         * @param {boolean} [hasUnusedAllocations]
         * @param {boolean} [wantsExclusiveLeads]
         * @param {string} [partnerAssigneeClientConsultantEmployeeId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersAggregationsIndex(partnerId?: string, state?: string, isEnabled?: boolean, employeeId?: string, geoCode?: string, satisfaction?: string, punchesUseSpeed?: string, refillOfferId?: string, industryId?: string, industry?: string, geoPoint?: string, geoRect?: string, geoDistance?: number, sort?: 'id' | 'relevance' | 'created_at' | 'company_name' | 'state' | 'satisfaction' | 'employee_name' | 'punches_monthly' | 'punches_use_speed' | 'last_login' | 'last_lead_bought' | 'revenue_6months' | 'last_invoice' | 'avg_punch_price_6months' | 'avg_due_days_6months' | 'last_coupon_size' | 'punches_left' | 'punches_spent_monthly' | 'punches_spent_6months' | 'punches_bought_6months' | 'golden_ratio_6months' | 'refill' | 'rating' | 'distance' | 'last_phone_call' | 'canvas_status' | 'address' | 'tasks' | 'last_subscription_start_date' | 'last_published_quote' | 'published_quotes' | 'win_ratio' | 'unmatched' | 'unmatched_percent' | 'digital_leads' | 'has_active_contract' | 'deallocations' | 'overdue_amount' | 'active_contracts_count' | 'active_allocation_pauses_count' | 'active_contracts_amount' | 'next_contract_renewal_at' | 'active_business_units_amount' | 'active_business_units_mrr' | 'current_period_weighted_delivery_trend' | 'success_ratio' | 'publishing_ratio' | 'all_time_purchased_marketplace_leads_value_excl_vat_local_currency' | 'all_time_purchased_marketplace_leads_count' | 'lead_marketplace_partner_spend_excl_vat_local_currency' | 'ascending' | 'descending', canvasStatus?: string, isPublic?: boolean, packageType?: string, badgeId?: string, digitalLeads?: boolean, hasMarketingPackage?: boolean, hasActiveContract?: boolean, hasUnusedAllocations?: boolean, wantsExclusiveLeads?: boolean, partnerAssigneeClientConsultantEmployeeId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AggregationResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersAggregationsIndex(partnerId, state, isEnabled, employeeId, geoCode, satisfaction, punchesUseSpeed, refillOfferId, industryId, industry, geoPoint, geoRect, geoDistance, sort, canvasStatus, isPublic, packageType, badgeId, digitalLeads, hasMarketingPackage, hasActiveContract, hasUnusedAllocations, wantsExclusiveLeads, partnerAssigneeClientConsultantEmployeeId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerAllocationBoostResource} partnerAllocationBoostResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersAllocationboostsCreate(partnerId: string, partnerAllocationBoostResource: PartnerAllocationBoostResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerAllocationBoostResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersAllocationboostsCreate(partnerId, partnerAllocationBoostResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [processAtGte] Get invoicings processed at after a date.
         * @param {string} [processAtLte] Get invoicings processed at after a date.
         * @param {string} [partnerId] Filter by zero or more partner IDs.
         * @param {'process_at' | 'ascending' | 'descending'} [sort] Sort the invoicings.
         * @param {string} [geoCode] Filter by geo codes.
         * @param {string} [employeeId] Filter by partner&#39;s employee.
         * @param {string} [revenueSegmentId] Filter by revenue segment ID.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersAllocationdeliveriesIndex(processAtGte?: string, processAtLte?: string, partnerId?: string, sort?: 'process_at' | 'ascending' | 'descending', geoCode?: string, employeeId?: string, revenueSegmentId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerAllocationDeliveryResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersAllocationdeliveriesIndex(processAtGte, processAtLte, partnerId, sort, geoCode, employeeId, revenueSegmentId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [processAtGte] Get invoicings processed at after a date.
         * @param {string} [processAtLte] Get invoicings processed at after a date.
         * @param {'process_at' | 'ascending' | 'descending'} [sort] Sort the invoicings.
         * @param {string} [geoCode] Filter by geo codes.
         * @param {string} [employeeId] Filter by partner&#39;s employee.
         * @param {string} [revenueSegmentId] Filter by revenue segment ID.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersAllocationdeliveriesIndex_1(partnerId: string, processAtGte?: string, processAtLte?: string, sort?: 'process_at' | 'ascending' | 'descending', geoCode?: string, employeeId?: string, revenueSegmentId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerAllocationDeliveryResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersAllocationdeliveriesIndex_1(partnerId, processAtGte, processAtLte, sort, geoCode, employeeId, revenueSegmentId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerAllocationPauseResource} partnerAllocationPauseResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersAllocationpausesCreate(partnerId: string, partnerAllocationPauseResource: PartnerAllocationPauseResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerAllocationPauseResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersAllocationpausesCreate(partnerId, partnerAllocationPauseResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerAssigneeResource} partnerAssigneeResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersAssigneesCreate(partnerId: string, partnerAssigneeResource: PartnerAssigneeResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerAssigneeResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersAssigneesCreate(partnerId, partnerAssigneeResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerAssigneeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersAssigneesDelete(partnerId: string, partnerAssigneeId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersAssigneesDelete(partnerId, partnerAssigneeId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {'all' | 'client_consultant' | 'account_manager'} [roles]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersAssigneesIndex(partnerId: string, roles?: 'all' | 'client_consultant' | 'account_manager', limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerAssigneeResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersAssigneesIndex(partnerId, roles, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerBankaccountResource} partnerBankaccountResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBankaccountsCreate(partnerId: string, partnerBankaccountResource: PartnerBankaccountResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerBankaccountResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBankaccountsCreate(partnerId, partnerBankaccountResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBankaccountsIndex(partnerId: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerBankaccountResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBankaccountsIndex(partnerId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {BrowserPushSubscriptionResource} browserPushSubscriptionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBrowserpushsubscriptionsCreate(partnerId: string, browserPushSubscriptionResource: BrowserPushSubscriptionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BrowserPushSubscriptionResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBrowserpushsubscriptionsCreate(partnerId, browserPushSubscriptionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerBusinessUnitContractResource} partnerBusinessUnitContractResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitcontractsCreate(partnerId: string, partnerBusinessUnitContractResource: PartnerBusinessUnitContractResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerBusinessUnitContractResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBusinessunitcontractsCreate(partnerId, partnerBusinessUnitContractResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitcontractsGet(partnerId: string, contractId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerBusinessUnitContractResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBusinessunitcontractsGet(partnerId, contractId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitcontractsIndex(partnerId: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerBusinessUnitContractResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBusinessunitcontractsIndex(partnerId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {LeadPartnerAllocationLimitResource} leadPartnerAllocationLimitResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsAllocationlimitsCreate(partnerId: string, partnerBusinessUnitId: string, leadPartnerAllocationLimitResource: LeadPartnerAllocationLimitResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadPartnerAllocationLimitResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBusinessunitsAllocationlimitsCreate(partnerId, partnerBusinessUnitId, leadPartnerAllocationLimitResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {string} limitId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsAllocationlimitsDelete(partnerId: string, partnerBusinessUnitId: string, limitId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBusinessunitsAllocationlimitsDelete(partnerId, partnerBusinessUnitId, limitId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [effectiveAtGte]
         * @param {string} [effectiveAtLte]
         * @param {string} [partnerBusinessUnitId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsAllocationlimitsIndex(partnerId: string, effectiveAtGte?: string, effectiveAtLte?: string, partnerBusinessUnitId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadPartnerAllocationLimitResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBusinessunitsAllocationlimitsIndex(partnerId, effectiveAtGte, effectiveAtLte, partnerBusinessUnitId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {string} limitId
         * @param {LeadPartnerAllocationLimitResource} leadPartnerAllocationLimitResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsAllocationlimitsUpdate(partnerId: string, partnerBusinessUnitId: string, limitId: string, leadPartnerAllocationLimitResource: LeadPartnerAllocationLimitResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadPartnerAllocationLimitResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBusinessunitsAllocationlimitsUpdate(partnerId, partnerBusinessUnitId, limitId, leadPartnerAllocationLimitResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {LeadAllocationQuotaResource} leadAllocationQuotaResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsAllocationquotasCreate(partnerId: string, partnerBusinessUnitId: string, leadAllocationQuotaResource: LeadAllocationQuotaResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadAllocationQuotaResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBusinessunitsAllocationquotasCreate(partnerId, partnerBusinessUnitId, leadAllocationQuotaResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {string} quotaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsAllocationquotasDelete(partnerId: string, partnerBusinessUnitId: string, quotaId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBusinessunitsAllocationquotasDelete(partnerId, partnerBusinessUnitId, quotaId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [effectiveAtGte]
         * @param {string} [effectiveAtLte]
         * @param {string} [partnerBusinessUnitId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsAllocationquotasIndex(partnerId: string, effectiveAtGte?: string, effectiveAtLte?: string, partnerBusinessUnitId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LeadAllocationQuotaResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBusinessunitsAllocationquotasIndex(partnerId, effectiveAtGte, effectiveAtLte, partnerBusinessUnitId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerBusinessUnitResource} partnerBusinessUnitResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsCreate(partnerId: string, partnerBusinessUnitResource: PartnerBusinessUnitResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerBusinessUnitResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBusinessunitsCreate(partnerId, partnerBusinessUnitResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessunitId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsDelete(partnerId: string, partnerBusinessunitId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBusinessunitsDelete(partnerId, partnerBusinessunitId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsGet(partnerId: string, partnerBusinessUnitId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerBusinessUnitResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBusinessunitsGet(partnerId, partnerBusinessUnitId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [id] Primary ID.
         * @param {string} [partnerId] Partner ID.
         * @param {string} [geoCode] E.g. &#39;dk&#39;.
         * @param {string} [segmentationCriteria] Which criteria to segment on, e.g. language.
         * @param {number} [segmentedForLeadId] List business units that are segmented for this lead.
         * @param {boolean} [isActive] List business units that are active or inactive.
         * @param {'id' | 'allocation_fulfillment_percentage' | 'allocation_fulfillment_since_start_percentage' | 'allocation_fulfillment_forecast_percentage' | 'pay_per_spot_allocation_fulfillment_percentage' | 'pay_per_spot_allocation_fulfillment_forecast_percentage' | 'normal_quota_spot_allocation_fulfillment_percentage' | 'normal_quota_allocation_fulfillment_forecast_percentage' | 'auto_allocation' | 'partner_rating' | 'partner_rating_count' | 'ascending' | 'descending'} [sort] Sort criterion
         * @param {string} [geoPoint] Central point to search from.
         * @param {number} [geoDistance] Range in KM to search from geo_point.
         * @param {string} [assignedEmployeeId] Filter by assigned employee ID.
         * @param {string} [sectorId] Filter by sector IDs.
         * @param {string} [industryId] Filter by industry IDs.
         * @param {string} [clientTypeId] Filter by client type IDs.
         * @param {string} [revenueAmount] Find only business units with a revenue range that includes this amount.
         * @param {boolean} [hasMarketingPackage] Filter business units based on whether or not they have a marketing package.
         * @param {boolean} [hasBusinessModel] Filter business units based on whether or not they have any business model.
         * @param {string} [subGeoRegionId] Filter business units by sub geo region IDs.
         * @param {boolean} [isEligibleForAllocation] Filter business units based on whether they are eligible for allocation.
         * @param {string} [primaryIndustryId] The business unit&#39;s primary industry, e.g. law or accounting.
         * @param {number} [minimumPartnerRating] Minimum request partner rating.
         * @param {'none' | 'partner_association_id' | 'partner_id'} [collapse] Collapse business units based on identical values in specified field.
         * @param {string} [segmentedForCertificationId]
         * @param {string} [segmentedForIndustryId]
         * @param {string} [segmentedForClientTypeId]
         * @param {boolean} [isLeadBudget]
         * @param {boolean} [hasUnusedAllocations]
         * @param {boolean} [hasPausedContract]
         * @param {boolean} [hasActiveAccess]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsIndex(id?: string, partnerId?: string, geoCode?: string, segmentationCriteria?: string, segmentedForLeadId?: number, isActive?: boolean, sort?: 'id' | 'allocation_fulfillment_percentage' | 'allocation_fulfillment_since_start_percentage' | 'allocation_fulfillment_forecast_percentage' | 'pay_per_spot_allocation_fulfillment_percentage' | 'pay_per_spot_allocation_fulfillment_forecast_percentage' | 'normal_quota_spot_allocation_fulfillment_percentage' | 'normal_quota_allocation_fulfillment_forecast_percentage' | 'auto_allocation' | 'partner_rating' | 'partner_rating_count' | 'ascending' | 'descending', geoPoint?: string, geoDistance?: number, assignedEmployeeId?: string, sectorId?: string, industryId?: string, clientTypeId?: string, revenueAmount?: string, hasMarketingPackage?: boolean, hasBusinessModel?: boolean, subGeoRegionId?: string, isEligibleForAllocation?: boolean, primaryIndustryId?: string, minimumPartnerRating?: number, collapse?: 'none' | 'partner_association_id' | 'partner_id', segmentedForCertificationId?: string, segmentedForIndustryId?: string, segmentedForClientTypeId?: string, isLeadBudget?: boolean, hasUnusedAllocations?: boolean, hasPausedContract?: boolean, hasActiveAccess?: boolean, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerBusinessUnitResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBusinessunitsIndex(id, partnerId, geoCode, segmentationCriteria, segmentedForLeadId, isActive, sort, geoPoint, geoDistance, assignedEmployeeId, sectorId, industryId, clientTypeId, revenueAmount, hasMarketingPackage, hasBusinessModel, subGeoRegionId, isEligibleForAllocation, primaryIndustryId, minimumPartnerRating, collapse, segmentedForCertificationId, segmentedForIndustryId, segmentedForClientTypeId, isLeadBudget, hasUnusedAllocations, hasPausedContract, hasActiveAccess, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {boolean} [isActive] Filter active industries.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsIndustriesIndex(partnerId: string, partnerBusinessUnitId: string, isActive?: boolean, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerBusinessUnitIndustryResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBusinessunitsIndustriesIndex(partnerId, partnerBusinessUnitId, isActive, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {PartnerBusinessUnitIndustriesResource} partnerBusinessUnitIndustriesResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsIndustriesUpdate(partnerId: string, partnerBusinessUnitId: string, partnerBusinessUnitIndustriesResource: PartnerBusinessUnitIndustriesResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerBusinessUnitIndustriesResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBusinessunitsIndustriesUpdate(partnerId, partnerBusinessUnitId, partnerBusinessUnitIndustriesResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {PartnerBusinessUnitRevenueRangeResource} partnerBusinessUnitRevenueRangeResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsRevenuerangesCreate(partnerId: string, partnerBusinessUnitId: string, partnerBusinessUnitRevenueRangeResource: PartnerBusinessUnitRevenueRangeResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerBusinessUnitRevenueRangeResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBusinessunitsRevenuerangesCreate(partnerId, partnerBusinessUnitId, partnerBusinessUnitRevenueRangeResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsSectorsIndex(partnerId: string, partnerBusinessUnitId: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SectorResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBusinessunitsSectorsIndex(partnerId, partnerBusinessUnitId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {PartnerSectorsResource} partnerSectorsResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsSectorsUpdate(partnerId: string, partnerBusinessUnitId: string, partnerSectorsResource: PartnerSectorsResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerSectorsResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBusinessunitsSectorsUpdate(partnerId, partnerBusinessUnitId, partnerSectorsResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsSegmentationgeoregionsIndex(partnerId: string, partnerBusinessUnitId: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerBusinessUnitSegmentationGeoRegionResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBusinessunitsSegmentationgeoregionsIndex(partnerId, partnerBusinessUnitId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {PartnerBusinessUnitSegmentationGeoRegionsResource} partnerBusinessUnitSegmentationGeoRegionsResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsSegmentationgeoregionsUpdate(partnerId: string, partnerBusinessUnitId: string, partnerBusinessUnitSegmentationGeoRegionsResource: PartnerBusinessUnitSegmentationGeoRegionsResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerBusinessUnitSegmentationGeoRegionsResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBusinessunitsSegmentationgeoregionsUpdate(partnerId, partnerBusinessUnitId, partnerBusinessUnitSegmentationGeoRegionsResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsTypesIndex(partnerId: string, partnerBusinessUnitId: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerBusinessUnitTypeResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBusinessunitsTypesIndex(partnerId, partnerBusinessUnitId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {PartnerBusinessUnitTypesResource} partnerBusinessUnitTypesResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsTypesUpdate(partnerId: string, partnerBusinessUnitId: string, partnerBusinessUnitTypesResource: PartnerBusinessUnitTypesResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerBusinessUnitTypesResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBusinessunitsTypesUpdate(partnerId, partnerBusinessUnitId, partnerBusinessUnitTypesResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {PartnerBusinessUnitResource} partnerBusinessUnitResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsUpdate(partnerId: string, partnerBusinessUnitId: string, partnerBusinessUnitResource: PartnerBusinessUnitResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerBusinessUnitResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersBusinessunitsUpdate(partnerId, partnerBusinessUnitId, partnerBusinessUnitResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCashierpaymentmethodsGet(partnerId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CashierPaymentMethodResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersCashierpaymentmethodsGet(partnerId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {boolean} [isActive] Only include active subscriptions.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCashiersubscriptionsIndex(partnerId: string, isActive?: boolean, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CashierSubscriptionResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersCashiersubscriptionsIndex(partnerId, isActive, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [id] Category id @var int
         * @param {string} [identifier] Category identifier @var string
         * @param {string} [title] Category title. @var string
         * @param {string} [geoCode] Category geo_code. @var string
         * @param {number} [limit] Limit of categories per page. @var int
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCategoriesIndex(id?: string, identifier?: string, title?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerCategoryResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersCategoriesIndex(id, identifier, title, geoCode, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {CertificationResource} certificationResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCertificationsCreate(partnerId: string, certificationResource: CertificationResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CertificationResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersCertificationsCreate(partnerId, certificationResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} certificationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCertificationsDelete(partnerId: string, certificationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersCertificationsDelete(partnerId, certificationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} certificationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCertificationsGet(partnerId: string, certificationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CertificationResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersCertificationsGet(partnerId, certificationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [types] Partner type enum. @var array
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCertificationsIndex(partnerId: string, types?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CertificationResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersCertificationsIndex(partnerId, types, geoCode, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerCertificationsResource} partnerCertificationsResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCertificationsUpdate(partnerId: string, partnerCertificationsResource: PartnerCertificationsResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerCertificationsResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersCertificationsUpdate(partnerId, partnerCertificationsResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contentKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContentsDelete(partnerId: string, contentKey: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersContentsDelete(partnerId, contentKey, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contentKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContentsGet(partnerId: string, contentKey: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerContentResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersContentsGet(partnerId, contentKey, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contentKey
         * @param {PartnerContentResource} partnerContentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContentsUpdate(partnerId: string, contentKey: string, partnerContentResource: PartnerContentResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerContentResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersContentsUpdate(partnerId, contentKey, partnerContentResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerContractOfferGroupResource} partnerContractOfferGroupResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractoffergroupsCreate(partnerId: string, partnerContractOfferGroupResource: PartnerContractOfferGroupResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerContractOfferGroupResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersContractoffergroupsCreate(partnerId, partnerContractOfferGroupResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {boolean} [isArchived]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractoffergroupsIndex(partnerId: string, isArchived?: boolean, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerContractOfferGroupResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersContractoffergroupsIndex(partnerId, isArchived, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contractId
         * @param {PartnerContractActionResource} partnerContractActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsActionsCreate(partnerId: string, contractId: string, partnerContractActionResource: PartnerContractActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerContractResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersContractsActionsCreate(partnerId, contractId, partnerContractActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerContractId
         * @param {string} boostId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsAllocationboostsDelete(partnerId: string, partnerContractId: string, boostId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersContractsAllocationboostsDelete(partnerId, partnerContractId, boostId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerContractId
         * @param {string} boostId
         * @param {DateSpanResource} dateSpanResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsAllocationboostsUpdate(partnerId: string, partnerContractId: string, boostId: string, dateSpanResource: DateSpanResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DateSpanResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersContractsAllocationboostsUpdate(partnerId, partnerContractId, boostId, dateSpanResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerContractId
         * @param {string} pauseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsAllocationpausesDelete(partnerId: string, partnerContractId: string, pauseId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersContractsAllocationpausesDelete(partnerId, partnerContractId, pauseId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerContractId
         * @param {string} pauseId
         * @param {DateSpanResource} dateSpanResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsAllocationpausesUpdate(partnerId: string, partnerContractId: string, pauseId: string, dateSpanResource: DateSpanResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DateSpanResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersContractsAllocationpausesUpdate(partnerId, partnerContractId, pauseId, dateSpanResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerContractResource} partnerContractResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsCreate(partnerId: string, partnerContractResource: PartnerContractResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerContractResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersContractsCreate(partnerId, partnerContractResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerContractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsDelete(partnerId: string, partnerContractId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersContractsDelete(partnerId, partnerContractId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsGet(partnerId: string, contractId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerContractResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersContractsGet(partnerId, contractId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {boolean} [isAccepted]
         * @param {boolean} [isArchived]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsIndex(partnerId: string, isAccepted?: boolean, isArchived?: boolean, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerContractResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersContractsIndex(partnerId, isAccepted, isArchived, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsPdfGet(partnerId: string, contractId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerContractPdfResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersContractsPdfGet(partnerId, contractId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contractId
         * @param {PartnerContractSignedPdfFileResource} partnerContractSignedPdfFileResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsSignedpdfCreate(partnerId: string, contractId: string, partnerContractSignedPdfFileResource: PartnerContractSignedPdfFileResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerContractSignedPdfFileResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersContractsSignedpdfCreate(partnerId, contractId, partnerContractSignedPdfFileResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsSignedpdfGet(partnerId: string, contractId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerContractPdfResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersContractsSignedpdfGet(partnerId, contractId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsSigningurlGet(partnerId: string, contractId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerContractResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersContractsSigningurlGet(partnerId, contractId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerContractId
         * @param {PartnerContractResource} partnerContractResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsUpdate(partnerId: string, partnerContractId: string, partnerContractResource: PartnerContractResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerContractResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersContractsUpdate(partnerId, partnerContractId, partnerContractResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} couponId
         * @param {PartnerCouponActionResource} partnerCouponActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCouponsActionsCreate(partnerId: string, couponId: string, partnerCouponActionResource: PartnerCouponActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerCouponResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersCouponsActionsCreate(partnerId, couponId, partnerCouponActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerCouponResource} partnerCouponResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCouponsCreate(partnerId: string, partnerCouponResource: PartnerCouponResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerCouponResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersCouponsCreate(partnerId, partnerCouponResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {boolean} [showExpired] Only show expired partner coupons.
         * @param {boolean} [showDeactivated] Only show deactivated partner coupons.
         * @param {boolean} [showEmpty] Should empty partner coupons be included in the result.
         * @param {'created_at' | 'expires_at' | 'deactivated_at' | 'punches_left' | 'ascending' | 'descending'} [sort] Sort the coupons by different values
         * @param {string} [type] Filter the coupons type
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCouponsIndex(partnerId: string, showExpired?: boolean, showDeactivated?: boolean, showEmpty?: boolean, sort?: 'created_at' | 'expires_at' | 'deactivated_at' | 'punches_left' | 'ascending' | 'descending', type?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerCouponResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersCouponsIndex(partnerId, showExpired, showDeactivated, showEmpty, sort, type, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Create a Partner
         * @summary Create a Partner
         * @param {PartnerResource} partnerResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCreate(partnerResource: PartnerResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersCreate(partnerResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDelete(partnerId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersDelete(partnerId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {PartnerDeliverableResource} partnerDeliverableResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesCreate(partnerDeliverableResource: PartnerDeliverableResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerDeliverableResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersDeliverablesCreate(partnerDeliverableResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerDeliverableResource} partnerDeliverableResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesCreate_2(partnerId: string, partnerDeliverableResource: PartnerDeliverableResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerDeliverableResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersDeliverablesCreate_2(partnerId, partnerDeliverableResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerDeliverableId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesGet(partnerDeliverableId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerDeliverableResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersDeliverablesGet(partnerDeliverableId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerDeliverableId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesGet_3(partnerId: string, partnerDeliverableId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerDeliverableResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersDeliverablesGet_3(partnerId, partnerDeliverableId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [clientId] Client id for the clients to filter by
         * @param {string} [leadId] Lead id for the lead to filter by
         * @param {string} [partnerId] Partner id of the partners to filter by.
         * @param {string} [partnerDeliverableId] Partner deliverable id of the partners to filter by.
         * @param {string} [status] States for the partners.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesIndex(clientId?: string, leadId?: string, partnerId?: string, partnerDeliverableId?: string, status?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerDeliverableResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersDeliverablesIndex(clientId, leadId, partnerId, partnerDeliverableId, status, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [clientId] Client id for the clients to filter by
         * @param {string} [leadId] Lead id for the lead to filter by
         * @param {string} [partnerDeliverableId] Partner deliverable id of the partners to filter by.
         * @param {string} [status] States for the partners.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesIndex_4(partnerId: string, clientId?: string, leadId?: string, partnerDeliverableId?: string, status?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerDeliverableResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersDeliverablesIndex_4(partnerId, clientId, leadId, partnerDeliverableId, status, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerDeliverableId
         * @param {PartnerDeliverableLineResource} partnerDeliverableLineResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesCreate(partnerDeliverableId: string, partnerDeliverableLineResource: PartnerDeliverableLineResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerDeliverableLineResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersDeliverablesLinesCreate(partnerDeliverableId, partnerDeliverableLineResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerDeliverableId
         * @param {PartnerDeliverableLineResource} partnerDeliverableLineResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesCreate_5(partnerId: string, partnerDeliverableId: string, partnerDeliverableLineResource: PartnerDeliverableLineResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerDeliverableLineResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersDeliverablesLinesCreate_5(partnerId, partnerDeliverableId, partnerDeliverableLineResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerDeliverableId
         * @param {string} partnerDeliverableLineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesDelete(partnerDeliverableId: string, partnerDeliverableLineId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersDeliverablesLinesDelete(partnerDeliverableId, partnerDeliverableLineId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerDeliverableId
         * @param {string} partnerDeliverableLineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesDelete_6(partnerId: string, partnerDeliverableId: string, partnerDeliverableLineId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersDeliverablesLinesDelete_6(partnerId, partnerDeliverableId, partnerDeliverableLineId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerDeliverableId
         * @param {string} partnerDeliverableLineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesGet(partnerDeliverableId: string, partnerDeliverableLineId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerDeliverableLineResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersDeliverablesLinesGet(partnerDeliverableId, partnerDeliverableLineId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerDeliverableId
         * @param {string} partnerDeliverableLineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesGet_7(partnerId: string, partnerDeliverableId: string, partnerDeliverableLineId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerDeliverableLineResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersDeliverablesLinesGet_7(partnerId, partnerDeliverableId, partnerDeliverableLineId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerDeliverableId
         * @param {string} [clientId] Client id for the clients to filter by
         * @param {string} [leadId] Lead id for the lead to filter by
         * @param {string} [partnerId] Partner id of the partners to filter by.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesIndex(partnerDeliverableId: string, clientId?: string, leadId?: string, partnerId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerDeliverableLineResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersDeliverablesLinesIndex(partnerDeliverableId, clientId, leadId, partnerId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerDeliverableId
         * @param {string} [clientId] Client id for the clients to filter by
         * @param {string} [leadId] Lead id for the lead to filter by
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesIndex_8(partnerId: string, partnerDeliverableId: string, clientId?: string, leadId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerDeliverableLineResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersDeliverablesLinesIndex_8(partnerId, partnerDeliverableId, clientId, leadId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerDeliverableId
         * @param {string} partnerDeliverableLineId
         * @param {PartnerDeliverableLineResource} partnerDeliverableLineResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesUpdate(partnerDeliverableId: string, partnerDeliverableLineId: string, partnerDeliverableLineResource: PartnerDeliverableLineResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerDeliverableLineResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersDeliverablesLinesUpdate(partnerDeliverableId, partnerDeliverableLineId, partnerDeliverableLineResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerDeliverableId
         * @param {string} partnerDeliverableLineId
         * @param {PartnerDeliverableLineResource} partnerDeliverableLineResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesUpdate_9(partnerId: string, partnerDeliverableId: string, partnerDeliverableLineId: string, partnerDeliverableLineResource: PartnerDeliverableLineResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerDeliverableLineResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersDeliverablesLinesUpdate_9(partnerId, partnerDeliverableId, partnerDeliverableLineId, partnerDeliverableLineResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerDeliverableId
         * @param {PartnerDeliverableResource} partnerDeliverableResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesUpdate(partnerDeliverableId: string, partnerDeliverableResource: PartnerDeliverableResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerDeliverableResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersDeliverablesUpdate(partnerDeliverableId, partnerDeliverableResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerDeliverableId
         * @param {PartnerDeliverableResource} partnerDeliverableResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesUpdate_10(partnerId: string, partnerDeliverableId: string, partnerDeliverableResource: PartnerDeliverableResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerDeliverableResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersDeliverablesUpdate_10(partnerId, partnerDeliverableId, partnerDeliverableResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerDiscountResource} partnerDiscountResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDiscountsCreate(partnerId: string, partnerDiscountResource: PartnerDiscountResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerDiscountResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersDiscountsCreate(partnerId, partnerDiscountResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerPackageTypeDiscountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDiscountsDelete(partnerId: string, partnerPackageTypeDiscountId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersDiscountsDelete(partnerId, partnerPackageTypeDiscountId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [isActive] Only include active discounts.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDiscountsIndex(partnerId: string, isActive?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerDiscountResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersDiscountsIndex(partnerId, isActive, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerEmployeeNoteResource} partnerEmployeeNoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersEmployeenotesCreate(partnerId: string, partnerEmployeeNoteResource: PartnerEmployeeNoteResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerEmployeeNoteResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersEmployeenotesCreate(partnerId, partnerEmployeeNoteResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} employeeNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersEmployeenotesDelete(partnerId: string, employeeNoteId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersEmployeenotesDelete(partnerId, employeeNoteId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [leadId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersEmployeenotesIndex(partnerId: string, leadId?: number, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerEmployeeNoteResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersEmployeenotesIndex(partnerId, leadId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerFeatureResource} partnerFeatureResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersFeaturesCreate(partnerId: string, partnerFeatureResource: PartnerFeatureResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerFeatureResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersFeaturesCreate(partnerId, partnerFeatureResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} featureIdentifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersFeaturesDelete(partnerId: string, featureIdentifier: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersFeaturesDelete(partnerId, featureIdentifier, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerFeeResource} partnerFeeResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersFeesCreate(partnerId: string, partnerFeeResource: PartnerFeeResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerFeeResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersFeesCreate(partnerId, partnerFeeResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersFeesDelete(partnerId: string, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersFeesDelete(partnerId, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} [id]
         * @param {number} [partnerId] Partner ID. @var int
         * @param {string} [effectiveAt] effective from date.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersFeesIndex(id?: number, partnerId?: number, effectiveAt?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerFeeResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersFeesIndex(id, partnerId, effectiveAt, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get a single partner by ID
         * @summary Get a single partner by ID
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersGet(partnerId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersGet(partnerId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerImpressumResource} partnerImpressumResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersImpressumsCreate(partnerId: string, partnerImpressumResource: PartnerImpressumResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerImpressumResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersImpressumsCreate(partnerId, partnerImpressumResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * List partners
         * @summary List partners
         * @param {string} [partnerId] Filter partners by partner ID
         * @param {string} [state] States for the partners.
         * @param {boolean} [isEnabled]
         * @param {string} [employeeId] Employee id of the partners to filter by.
         * @param {string} [geoCode] Geographic Location Code.
         * @param {string} [satisfaction] Partner Satisfaction Ratio.
         * @param {string} [punchesUseSpeed] Punches use speed.
         * @param {string} [refillOfferId] Filter the partners by their refill offers
         * @param {string} [industryId] Filter the partners by their industry ids
         * @param {string} [industry] Filter the partners by their industry identifiers
         * @param {string} [geoPoint] Location Point to sort partners by
         * @param {string} [geoRect] Location Points to define an area to filter partners by. Provide the bottom left (southwest) and top right (northeast) corners of the reactangle separated by commas, e.g. \&quot;52.19,12.42,52.85,14.22\&quot;.
         * @param {number} [geoDistance] Location filter by distance
         * @param {'id' | 'relevance' | 'created_at' | 'company_name' | 'state' | 'satisfaction' | 'employee_name' | 'punches_monthly' | 'punches_use_speed' | 'last_login' | 'last_lead_bought' | 'revenue_6months' | 'last_invoice' | 'avg_punch_price_6months' | 'avg_due_days_6months' | 'last_coupon_size' | 'punches_left' | 'punches_spent_monthly' | 'punches_spent_6months' | 'punches_bought_6months' | 'golden_ratio_6months' | 'refill' | 'rating' | 'distance' | 'last_phone_call' | 'canvas_status' | 'address' | 'tasks' | 'last_subscription_start_date' | 'last_published_quote' | 'published_quotes' | 'win_ratio' | 'unmatched' | 'unmatched_percent' | 'digital_leads' | 'has_active_contract' | 'deallocations' | 'overdue_amount' | 'active_contracts_count' | 'active_allocation_pauses_count' | 'active_contracts_amount' | 'next_contract_renewal_at' | 'active_business_units_amount' | 'active_business_units_mrr' | 'current_period_weighted_delivery_trend' | 'success_ratio' | 'publishing_ratio' | 'all_time_purchased_marketplace_leads_value_excl_vat_local_currency' | 'all_time_purchased_marketplace_leads_count' | 'lead_marketplace_partner_spend_excl_vat_local_currency' | 'ascending' | 'descending'} [sort] Sort Partner&#39;s by a given property.
         * @param {string} [canvasStatus] Canvas Status
         * @param {boolean} [isPublic] Only show partners that are visible to the public
         * @param {string} [packageType] Partner subscription package type
         * @param {string} [badgeId]
         * @param {boolean} [digitalLeads]
         * @param {boolean} [hasMarketingPackage]
         * @param {boolean} [hasActiveContract]
         * @param {boolean} [hasUnusedAllocations]
         * @param {boolean} [wantsExclusiveLeads]
         * @param {string} [partnerAssigneeClientConsultantEmployeeId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersIndex(partnerId?: string, state?: string, isEnabled?: boolean, employeeId?: string, geoCode?: string, satisfaction?: string, punchesUseSpeed?: string, refillOfferId?: string, industryId?: string, industry?: string, geoPoint?: string, geoRect?: string, geoDistance?: number, sort?: 'id' | 'relevance' | 'created_at' | 'company_name' | 'state' | 'satisfaction' | 'employee_name' | 'punches_monthly' | 'punches_use_speed' | 'last_login' | 'last_lead_bought' | 'revenue_6months' | 'last_invoice' | 'avg_punch_price_6months' | 'avg_due_days_6months' | 'last_coupon_size' | 'punches_left' | 'punches_spent_monthly' | 'punches_spent_6months' | 'punches_bought_6months' | 'golden_ratio_6months' | 'refill' | 'rating' | 'distance' | 'last_phone_call' | 'canvas_status' | 'address' | 'tasks' | 'last_subscription_start_date' | 'last_published_quote' | 'published_quotes' | 'win_ratio' | 'unmatched' | 'unmatched_percent' | 'digital_leads' | 'has_active_contract' | 'deallocations' | 'overdue_amount' | 'active_contracts_count' | 'active_allocation_pauses_count' | 'active_contracts_amount' | 'next_contract_renewal_at' | 'active_business_units_amount' | 'active_business_units_mrr' | 'current_period_weighted_delivery_trend' | 'success_ratio' | 'publishing_ratio' | 'all_time_purchased_marketplace_leads_value_excl_vat_local_currency' | 'all_time_purchased_marketplace_leads_count' | 'lead_marketplace_partner_spend_excl_vat_local_currency' | 'ascending' | 'descending', canvasStatus?: string, isPublic?: boolean, packageType?: string, badgeId?: string, digitalLeads?: boolean, hasMarketingPackage?: boolean, hasActiveContract?: boolean, hasUnusedAllocations?: boolean, wantsExclusiveLeads?: boolean, partnerAssigneeClientConsultantEmployeeId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersIndex(partnerId, state, isEnabled, employeeId, geoCode, satisfaction, punchesUseSpeed, refillOfferId, industryId, industry, geoPoint, geoRect, geoDistance, sort, canvasStatus, isPublic, packageType, badgeId, digitalLeads, hasMarketingPackage, hasActiveContract, hasUnusedAllocations, wantsExclusiveLeads, partnerAssigneeClientConsultantEmployeeId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerIndustryResource} partnerIndustryResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersIndustriesCreate(partnerId: string, partnerIndustryResource: PartnerIndustryResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerIndustryResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersIndustriesCreate(partnerId, partnerIndustryResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} industryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersIndustriesDelete(partnerId: string, industryId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersIndustriesDelete(partnerId, industryId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} industryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersIndustriesGet(partnerId: string, industryId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerIndustryResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersIndustriesGet(partnerId, industryId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [geoCode]
         * @param {boolean} [isActive]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersIndustriesIndex(partnerId: string, geoCode?: string, isActive?: boolean, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerIndustryResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersIndustriesIndex(partnerId, geoCode, isActive, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerIndustriesResource} partnerIndustriesResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersIndustriesUpdate(partnerId: string, partnerIndustriesResource: PartnerIndustriesResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerIndustriesResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersIndustriesUpdate(partnerId, partnerIndustriesResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [processAtGte] Get invoicings processed at after a date.
         * @param {string} [processAtLte] Get invoicings processed at after a date.
         * @param {string} [partnerId] Filter by zero or more partner IDs.
         * @param {'lead_budget' | 'pay_per_spot'} [businessModel] Lead budget or pay-per-spot.
         * @param {'process_at' | 'ascending' | 'descending'} [sort] Sort the invoicings.
         * @param {string} [geoCode] Filter by geo codes.
         * @param {string} [employeeId] Filter by partner&#39;s employee.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersInvoicingsIndex(processAtGte?: string, processAtLte?: string, partnerId?: string, businessModel?: 'lead_budget' | 'pay_per_spot', sort?: 'process_at' | 'ascending' | 'descending', geoCode?: string, employeeId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InvoicingResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersInvoicingsIndex(processAtGte, processAtLte, partnerId, businessModel, sort, geoCode, employeeId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [processAtGte] Get invoicings processed at after a date.
         * @param {string} [processAtLte] Get invoicings processed at after a date.
         * @param {'lead_budget' | 'pay_per_spot'} [businessModel] Lead budget or pay-per-spot.
         * @param {'process_at' | 'ascending' | 'descending'} [sort] Sort the invoicings.
         * @param {string} [geoCode] Filter by geo codes.
         * @param {string} [employeeId] Filter by partner&#39;s employee.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersInvoicingsIndex_11(partnerId: string, processAtGte?: string, processAtLte?: string, businessModel?: 'lead_budget' | 'pay_per_spot', sort?: 'process_at' | 'ascending' | 'descending', geoCode?: string, employeeId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InvoicingResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersInvoicingsIndex_11(partnerId, processAtGte, processAtLte, businessModel, sort, geoCode, employeeId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {LeadTypeResource} leadTypeResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersLeadtypesCreate(partnerId: string, leadTypeResource: LeadTypeResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerTypesResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersLeadtypesCreate(partnerId, leadTypeResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} leadTypeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersLeadtypesDelete(partnerId: string, leadTypeId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersLeadtypesDelete(partnerId, leadTypeId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} leadTypeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersLeadtypesGet(leadTypeId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerTypesResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersLeadtypesGet(leadTypeId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} leadTypeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersLeadtypesGet_12(partnerId: string, leadTypeId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerTypesResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersLeadtypesGet_12(partnerId, leadTypeId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} [partnerId] Partner ID. @var int
         * @param {string} [types] Partner type enum. @var array
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersLeadtypesIndex(partnerId?: number, types?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerTypesResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersLeadtypesIndex(partnerId, types, geoCode, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [types] Partner type enum. @var array
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersLeadtypesIndex_13(partnerId: string, types?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerTypesResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersLeadtypesIndex_13(partnerId, types, geoCode, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerLeadTypesResource} partnerLeadTypesResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersLeadtypesUpdate(partnerId: string, partnerLeadTypesResource: PartnerLeadTypesResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerLeadTypesResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersLeadtypesUpdate(partnerId, partnerLeadTypesResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {PartnerNoteResource} partnerNoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersNotesCreate(partnerNoteResource: PartnerNoteResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerNoteResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersNotesCreate(partnerNoteResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerNoteResource} partnerNoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersNotesCreate_14(partnerId: string, partnerNoteResource: PartnerNoteResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerNoteResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersNotesCreate_14(partnerId, partnerNoteResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersNotesGet(partnerId: string, partnerNoteId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerNoteResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersNotesGet(partnerId, partnerNoteId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} [partnerId]
         * @param {'all' | 'satisfactions' | 'notes'} [types]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersNotesIndex(partnerId?: number, types?: 'all' | 'satisfactions' | 'notes', limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerNoteResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersNotesIndex(partnerId, types, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {'all' | 'satisfactions' | 'notes'} [types]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersNotesIndex_15(partnerId: string, types?: 'all' | 'satisfactions' | 'notes', limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerNoteResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersNotesIndex_15(partnerId, types, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerQuoteProductCommentResource} partnerQuoteProductCommentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersQuoteproductcommentsCreate(partnerId: string, partnerQuoteProductCommentResource: PartnerQuoteProductCommentResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerQuoteProductCommentResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersQuoteproductcommentsCreate(partnerId, partnerQuoteProductCommentResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} quoteProductCommentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersQuoteproductcommentsDelete(partnerId: string, quoteProductCommentId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersQuoteproductcommentsDelete(partnerId, quoteProductCommentId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersQuoteproductcommentsIndex(partnerId: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerQuoteProductCommentResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersQuoteproductcommentsIndex(partnerId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerQuoteProductPriceResource} partnerQuoteProductPriceResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersQuoteproductpricesCreate(partnerId: string, partnerQuoteProductPriceResource: PartnerQuoteProductPriceResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerQuoteProductPriceResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersQuoteproductpricesCreate(partnerId, partnerQuoteProductPriceResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} quoteProductPriceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersQuoteproductpricesDelete(partnerId: string, quoteProductPriceId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersQuoteproductpricesDelete(partnerId, quoteProductPriceId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersQuoteproductpricesIndex(partnerId: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerQuoteProductPriceResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersQuoteproductpricesIndex(partnerId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [partnerId] IDs of partners
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersQuotevendorsIndex(partnerId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerQuoteVendorResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersQuotevendorsIndex(partnerId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerRevenueRangeResource} partnerRevenueRangeResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersRevenuerangesCreate(partnerId: string, partnerRevenueRangeResource: PartnerRevenueRangeResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerRevenueRangeResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersRevenuerangesCreate(partnerId, partnerRevenueRangeResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerRevenueSegmentActionResource} partnerRevenueSegmentActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersRevenuesegmentsActionsCreate(partnerId: string, partnerRevenueSegmentActionResource: PartnerRevenueSegmentActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerRevenueSegmentActionResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersRevenuesegmentsActionsCreate(partnerId, partnerRevenueSegmentActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {PartnerRevenueSegmentResource} partnerRevenueSegmentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersRevenuesegmentsCreate(partnerRevenueSegmentResource: PartnerRevenueSegmentResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerRevenueSegmentResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersRevenuesegmentsCreate(partnerRevenueSegmentResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerRevenueSegmentResource} partnerRevenueSegmentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersRevenuesegmentsCreate_16(partnerId: string, partnerRevenueSegmentResource: PartnerRevenueSegmentResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerRevenueSegmentResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersRevenuesegmentsCreate_16(partnerId, partnerRevenueSegmentResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerRevenueSegmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersRevenuesegmentsDelete(partnerId: string, partnerRevenueSegmentId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersRevenuesegmentsDelete(partnerId, partnerRevenueSegmentId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersRevenuesegmentsIndex(partnerId: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerRevenueSegmentResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersRevenuesegmentsIndex(partnerId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSectorsIndex(partnerId: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SectorResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersSectorsIndex(partnerId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerSectorsResource} partnerSectorsResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSectorsUpdate(partnerId: string, partnerSectorsResource: PartnerSectorsResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerSectorsResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersSectorsUpdate(partnerId, partnerSectorsResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerUniqueSellingPointResource} partnerUniqueSellingPointResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSellingpointsCreate(partnerId: string, partnerUniqueSellingPointResource: PartnerUniqueSellingPointResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUniqueSellingPointResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersSellingpointsCreate(partnerId, partnerUniqueSellingPointResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} sellingPointId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSellingpointsDelete(partnerId: string, sellingPointId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersSellingpointsDelete(partnerId, sellingPointId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [id]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSellingpointsIndex(partnerId: string, id?: number, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUniqueSellingPointResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersSellingpointsIndex(partnerId, id, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} sellingPointId
         * @param {PartnerUniqueSellingPointResource} partnerUniqueSellingPointResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSellingpointsUpdate(partnerId: string, sellingPointId: string, partnerUniqueSellingPointResource: PartnerUniqueSellingPointResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUniqueSellingPointResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersSellingpointsUpdate(partnerId, sellingPointId, partnerUniqueSellingPointResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} agreementId
         * @param {PartnerSubscriptionAgreementActionResource} partnerSubscriptionAgreementActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionagreementsActionsCreate(partnerId: string, agreementId: string, partnerSubscriptionAgreementActionResource: PartnerSubscriptionAgreementActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerSubscriptionAgreementResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersSubscriptionagreementsActionsCreate(partnerId, agreementId, partnerSubscriptionAgreementActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerSubscriptionAgreementResource} partnerSubscriptionAgreementResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionagreementsCreate(partnerId: string, partnerSubscriptionAgreementResource: PartnerSubscriptionAgreementResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerSubscriptionAgreementResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersSubscriptionagreementsCreate(partnerId, partnerSubscriptionAgreementResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {boolean} [isAvailableForAgreement] List agreemenets that are available.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionagreementsIndex(partnerId: string, isAvailableForAgreement?: boolean, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerSubscriptionAgreementResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersSubscriptionagreementsIndex(partnerId, isAvailableForAgreement, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} agreementId
         * @param {PartnerSubscriptionAgreementResource} partnerSubscriptionAgreementResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionagreementsUpdate(partnerId: string, agreementId: string, partnerSubscriptionAgreementResource: PartnerSubscriptionAgreementResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerSubscriptionAgreementResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersSubscriptionagreementsUpdate(partnerId, agreementId, partnerSubscriptionAgreementResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} subscriptionId
         * @param {PartnerSubscriptionActionResource} partnerSubscriptionActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsActionsCreate(partnerId: string, subscriptionId: string, partnerSubscriptionActionResource: PartnerSubscriptionActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerSubscriptionActionResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersSubscriptionsActionsCreate(partnerId, subscriptionId, partnerSubscriptionActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerSubscriptionResource} partnerSubscriptionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsCreate(partnerId: string, partnerSubscriptionResource: PartnerSubscriptionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerSubscriptionResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersSubscriptionsCreate(partnerId, partnerSubscriptionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [isActive] Only include active subscriptions.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsIndex(partnerId: string, isActive?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerSubscriptionResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersSubscriptionsIndex(partnerId, isActive, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} subscriptionId
         * @param {PartnerSubscriptionPauseResource} partnerSubscriptionPauseResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsPausesCreate(partnerId: string, subscriptionId: string, partnerSubscriptionPauseResource: PartnerSubscriptionPauseResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerSubscriptionPauseResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersSubscriptionsPausesCreate(partnerId, subscriptionId, partnerSubscriptionPauseResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} subscriptionId
         * @param {string} pauseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsPausesDelete(partnerId: string, subscriptionId: string, pauseId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersSubscriptionsPausesDelete(partnerId, subscriptionId, pauseId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} subscriptionId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsPausesIndex(partnerId: string, subscriptionId: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerSubscriptionPauseResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersSubscriptionsPausesIndex(partnerId, subscriptionId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} subscriptionId
         * @param {PartnerSubscriptionPriceResource} partnerSubscriptionPriceResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsPricesCreate(partnerId: string, subscriptionId: string, partnerSubscriptionPriceResource: PartnerSubscriptionPriceResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerSubscriptionPriceResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersSubscriptionsPricesCreate(partnerId, subscriptionId, partnerSubscriptionPriceResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} subscriptionId
         * @param {string} subscriptionPriceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsPricesDelete(partnerId: string, subscriptionId: string, subscriptionPriceId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersSubscriptionsPricesDelete(partnerId, subscriptionId, subscriptionPriceId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} subscriptionId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsPricesIndex(partnerId: string, subscriptionId: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerSubscriptionPriceResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersSubscriptionsPricesIndex(partnerId, subscriptionId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} subscriptionId
         * @param {PartnerSubscriptionResource} partnerSubscriptionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsUpdate(partnerId: string, subscriptionId: string, partnerSubscriptionResource: PartnerSubscriptionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerSubscriptionResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersSubscriptionsUpdate(partnerId, subscriptionId, partnerSubscriptionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [geoCode] Filter by geo code.
         * @param {boolean} [isHidden] Filter hidden types.
         * @param {'' | 'lead_marketplace' | 'ascending' | 'descending'} [identifier] Filter by identifier.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptiontypesIndex(geoCode?: string, isHidden?: boolean, identifier?: '' | 'lead_marketplace' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerSubscriptionTypeResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersSubscriptiontypesIndex(geoCode, isHidden, identifier, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [geoCode] Filter by geo code.
         * @param {boolean} [isHidden] Filter hidden types.
         * @param {'' | 'lead_marketplace' | 'ascending' | 'descending'} [identifier] Filter by identifier.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptiontypesIndex_17(partnerId: string, geoCode?: string, isHidden?: boolean, identifier?: '' | 'lead_marketplace' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerSubscriptionTypeResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersSubscriptiontypesIndex_17(partnerId, geoCode, isHidden, identifier, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} [limit] Limit the number of suggest resources pr. page.
         * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
         * @param {string} [query] The search query to search by.
         * @param {string} [type] Type to search for.
         * @param {string} [partnerState] Partner state.
         * @param {number} [page] The page position in the result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSuggestIndex(limit?: number, geoCode?: string, query?: string, type?: string, partnerState?: string, page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerSuggestResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersSuggestIndex(limit, geoCode, query, type, partnerState, page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} [id]
         * @param {string} [identifier]
         * @param {number} [industryId]
         * @param {string} [industryIdentifier]
         * @param {string} [geoCode]
         * @param {string} [sort]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersTitlesIndex(id?: number, identifier?: string, industryId?: number, industryIdentifier?: string, geoCode?: string, sort?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerTitleResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersTitlesIndex(id, identifier, industryId, industryIdentifier, geoCode, sort, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersTransactionsIndex(partnerId: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerCouponTransactionResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersTransactionsIndex(partnerId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerResource} partnerResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUpdate(partnerId: string, partnerResource: PartnerResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersUpdate(partnerId, partnerResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerUserId
         * @param {PartnerUserActionResource} partnerUserActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersActionsCreate(partnerId: string, partnerUserId: string, partnerUserActionResource: PartnerUserActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersUsersActionsCreate(partnerId, partnerUserId, partnerUserActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} contentKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersContentsDelete(partnerUserId: string, contentKey: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersUsersContentsDelete(partnerUserId, contentKey, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerUserId
         * @param {string} contentKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersContentsDelete_18(partnerId: string, partnerUserId: string, contentKey: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersUsersContentsDelete_18(partnerId, partnerUserId, contentKey, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} contentKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersContentsGet(partnerUserId: string, contentKey: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerContentResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersUsersContentsGet(partnerUserId, contentKey, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerUserId
         * @param {string} contentKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersContentsGet_19(partnerId: string, partnerUserId: string, contentKey: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerContentResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersUsersContentsGet_19(partnerId, partnerUserId, contentKey, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} contentKey
         * @param {PartnerContentResource} partnerContentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersContentsUpdate(partnerUserId: string, contentKey: string, partnerContentResource: PartnerContentResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerContentResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersUsersContentsUpdate(partnerUserId, contentKey, partnerContentResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerUserId
         * @param {string} contentKey
         * @param {PartnerContentResource} partnerContentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersContentsUpdate_20(partnerId: string, partnerUserId: string, contentKey: string, partnerContentResource: PartnerContentResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerContentResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersUsersContentsUpdate_20(partnerId, partnerUserId, contentKey, partnerContentResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {PartnerUserResource} partnerUserResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersCreate(partnerUserResource: PartnerUserResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersUsersCreate(partnerUserResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerUserResource} partnerUserResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersCreate_21(partnerId: string, partnerUserResource: PartnerUserResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersUsersCreate_21(partnerId, partnerUserResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerUserId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersDelete(partnerId: string, partnerUserId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersUsersDelete(partnerId, partnerUserId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersGet(partnerUserId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersUsersGet(partnerUserId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerUserId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersGet_22(partnerId: string, partnerUserId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersUsersGet_22(partnerId, partnerUserId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [partnerUserId] Partner User Id.
         * @param {string} [partnerId] Partner id for the partner users to filter by.
         * @param {string} [sort]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersIndex(partnerUserId?: string, partnerId?: string, sort?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersUsersIndex(partnerUserId, partnerId, sort, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [partnerUserId] Partner User Id.
         * @param {string} [sort]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersIndex_23(partnerId: string, partnerUserId?: string, sort?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersUsersIndex_23(partnerId, partnerUserId, sort, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserResource} partnerUserResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersUpdate(partnerUserId: string, partnerUserResource: PartnerUserResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersUsersUpdate(partnerUserId, partnerUserResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerUserId
         * @param {PartnerUserResource} partnerUserResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersUpdate_24(partnerId: string, partnerUserId: string, partnerUserResource: PartnerUserResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerUserResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersUsersUpdate_24(partnerId, partnerUserId, partnerUserResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [partnerId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersVerificationsIndex(partnerId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerVerificationResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersVerificationsIndex(partnerId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersVerificationsIndex_25(partnerId: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerVerificationResult> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersVerificationsIndex_25(partnerId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerVerificationResource} partnerVerificationResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersVerificationsUpdate(partnerId: string, partnerVerificationResource: PartnerVerificationResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerVerificationResource> {
            const localVarFetchArgs = PartnersApiFetchParamCreator(configuration).partnersVerificationsUpdate(partnerId, partnerVerificationResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PartnersApi - factory interface
 * @export
 */
export const PartnersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersActionfeesIndex(partnerId: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersActionfeesIndex(partnerId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerActionResource} partnerActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersActionsCreate(partnerId: string, partnerActionResource: PartnerActionResource, options?: any) {
            return PartnersApiFp(configuration).partnersActionsCreate(partnerId, partnerActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [partnerId] Filter partners by partner ID
         * @param {string} [state] States for the partners.
         * @param {boolean} [isEnabled]
         * @param {string} [employeeId] Employee id of the partners to filter by.
         * @param {string} [geoCode] Geographic Location Code.
         * @param {string} [satisfaction] Partner Satisfaction Ratio.
         * @param {string} [punchesUseSpeed] Punches use speed.
         * @param {string} [refillOfferId] Filter the partners by their refill offers
         * @param {string} [industryId] Filter the partners by their industry ids
         * @param {string} [industry] Filter the partners by their industry identifiers
         * @param {string} [geoPoint] Location Point to sort partners by
         * @param {string} [geoRect] Location Points to define an area to filter partners by. Provide the bottom left (southwest) and top right (northeast) corners of the reactangle separated by commas, e.g. \&quot;52.19,12.42,52.85,14.22\&quot;.
         * @param {number} [geoDistance] Location filter by distance
         * @param {'id' | 'relevance' | 'created_at' | 'company_name' | 'state' | 'satisfaction' | 'employee_name' | 'punches_monthly' | 'punches_use_speed' | 'last_login' | 'last_lead_bought' | 'revenue_6months' | 'last_invoice' | 'avg_punch_price_6months' | 'avg_due_days_6months' | 'last_coupon_size' | 'punches_left' | 'punches_spent_monthly' | 'punches_spent_6months' | 'punches_bought_6months' | 'golden_ratio_6months' | 'refill' | 'rating' | 'distance' | 'last_phone_call' | 'canvas_status' | 'address' | 'tasks' | 'last_subscription_start_date' | 'last_published_quote' | 'published_quotes' | 'win_ratio' | 'unmatched' | 'unmatched_percent' | 'digital_leads' | 'has_active_contract' | 'deallocations' | 'overdue_amount' | 'active_contracts_count' | 'active_allocation_pauses_count' | 'active_contracts_amount' | 'next_contract_renewal_at' | 'active_business_units_amount' | 'active_business_units_mrr' | 'current_period_weighted_delivery_trend' | 'success_ratio' | 'publishing_ratio' | 'all_time_purchased_marketplace_leads_value_excl_vat_local_currency' | 'all_time_purchased_marketplace_leads_count' | 'lead_marketplace_partner_spend_excl_vat_local_currency' | 'ascending' | 'descending'} [sort] Sort Partner&#39;s by a given property.
         * @param {string} [canvasStatus] Canvas Status
         * @param {boolean} [isPublic] Only show partners that are visible to the public
         * @param {string} [packageType] Partner subscription package type
         * @param {string} [badgeId]
         * @param {boolean} [digitalLeads]
         * @param {boolean} [hasMarketingPackage]
         * @param {boolean} [hasActiveContract]
         * @param {boolean} [hasUnusedAllocations]
         * @param {boolean} [wantsExclusiveLeads]
         * @param {string} [partnerAssigneeClientConsultantEmployeeId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersAggregationsIndex(partnerId?: string, state?: string, isEnabled?: boolean, employeeId?: string, geoCode?: string, satisfaction?: string, punchesUseSpeed?: string, refillOfferId?: string, industryId?: string, industry?: string, geoPoint?: string, geoRect?: string, geoDistance?: number, sort?: 'id' | 'relevance' | 'created_at' | 'company_name' | 'state' | 'satisfaction' | 'employee_name' | 'punches_monthly' | 'punches_use_speed' | 'last_login' | 'last_lead_bought' | 'revenue_6months' | 'last_invoice' | 'avg_punch_price_6months' | 'avg_due_days_6months' | 'last_coupon_size' | 'punches_left' | 'punches_spent_monthly' | 'punches_spent_6months' | 'punches_bought_6months' | 'golden_ratio_6months' | 'refill' | 'rating' | 'distance' | 'last_phone_call' | 'canvas_status' | 'address' | 'tasks' | 'last_subscription_start_date' | 'last_published_quote' | 'published_quotes' | 'win_ratio' | 'unmatched' | 'unmatched_percent' | 'digital_leads' | 'has_active_contract' | 'deallocations' | 'overdue_amount' | 'active_contracts_count' | 'active_allocation_pauses_count' | 'active_contracts_amount' | 'next_contract_renewal_at' | 'active_business_units_amount' | 'active_business_units_mrr' | 'current_period_weighted_delivery_trend' | 'success_ratio' | 'publishing_ratio' | 'all_time_purchased_marketplace_leads_value_excl_vat_local_currency' | 'all_time_purchased_marketplace_leads_count' | 'lead_marketplace_partner_spend_excl_vat_local_currency' | 'ascending' | 'descending', canvasStatus?: string, isPublic?: boolean, packageType?: string, badgeId?: string, digitalLeads?: boolean, hasMarketingPackage?: boolean, hasActiveContract?: boolean, hasUnusedAllocations?: boolean, wantsExclusiveLeads?: boolean, partnerAssigneeClientConsultantEmployeeId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersAggregationsIndex(partnerId, state, isEnabled, employeeId, geoCode, satisfaction, punchesUseSpeed, refillOfferId, industryId, industry, geoPoint, geoRect, geoDistance, sort, canvasStatus, isPublic, packageType, badgeId, digitalLeads, hasMarketingPackage, hasActiveContract, hasUnusedAllocations, wantsExclusiveLeads, partnerAssigneeClientConsultantEmployeeId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerAllocationBoostResource} partnerAllocationBoostResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersAllocationboostsCreate(partnerId: string, partnerAllocationBoostResource: PartnerAllocationBoostResource, options?: any) {
            return PartnersApiFp(configuration).partnersAllocationboostsCreate(partnerId, partnerAllocationBoostResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [processAtGte] Get invoicings processed at after a date.
         * @param {string} [processAtLte] Get invoicings processed at after a date.
         * @param {string} [partnerId] Filter by zero or more partner IDs.
         * @param {'process_at' | 'ascending' | 'descending'} [sort] Sort the invoicings.
         * @param {string} [geoCode] Filter by geo codes.
         * @param {string} [employeeId] Filter by partner&#39;s employee.
         * @param {string} [revenueSegmentId] Filter by revenue segment ID.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersAllocationdeliveriesIndex(processAtGte?: string, processAtLte?: string, partnerId?: string, sort?: 'process_at' | 'ascending' | 'descending', geoCode?: string, employeeId?: string, revenueSegmentId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersAllocationdeliveriesIndex(processAtGte, processAtLte, partnerId, sort, geoCode, employeeId, revenueSegmentId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [processAtGte] Get invoicings processed at after a date.
         * @param {string} [processAtLte] Get invoicings processed at after a date.
         * @param {'process_at' | 'ascending' | 'descending'} [sort] Sort the invoicings.
         * @param {string} [geoCode] Filter by geo codes.
         * @param {string} [employeeId] Filter by partner&#39;s employee.
         * @param {string} [revenueSegmentId] Filter by revenue segment ID.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersAllocationdeliveriesIndex_1(partnerId: string, processAtGte?: string, processAtLte?: string, sort?: 'process_at' | 'ascending' | 'descending', geoCode?: string, employeeId?: string, revenueSegmentId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersAllocationdeliveriesIndex_1(partnerId, processAtGte, processAtLte, sort, geoCode, employeeId, revenueSegmentId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerAllocationPauseResource} partnerAllocationPauseResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersAllocationpausesCreate(partnerId: string, partnerAllocationPauseResource: PartnerAllocationPauseResource, options?: any) {
            return PartnersApiFp(configuration).partnersAllocationpausesCreate(partnerId, partnerAllocationPauseResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerAssigneeResource} partnerAssigneeResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersAssigneesCreate(partnerId: string, partnerAssigneeResource: PartnerAssigneeResource, options?: any) {
            return PartnersApiFp(configuration).partnersAssigneesCreate(partnerId, partnerAssigneeResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerAssigneeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersAssigneesDelete(partnerId: string, partnerAssigneeId: string, options?: any) {
            return PartnersApiFp(configuration).partnersAssigneesDelete(partnerId, partnerAssigneeId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {'all' | 'client_consultant' | 'account_manager'} [roles]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersAssigneesIndex(partnerId: string, roles?: 'all' | 'client_consultant' | 'account_manager', limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersAssigneesIndex(partnerId, roles, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerBankaccountResource} partnerBankaccountResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBankaccountsCreate(partnerId: string, partnerBankaccountResource: PartnerBankaccountResource, options?: any) {
            return PartnersApiFp(configuration).partnersBankaccountsCreate(partnerId, partnerBankaccountResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBankaccountsIndex(partnerId: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersBankaccountsIndex(partnerId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {BrowserPushSubscriptionResource} browserPushSubscriptionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBrowserpushsubscriptionsCreate(partnerId: string, browserPushSubscriptionResource: BrowserPushSubscriptionResource, options?: any) {
            return PartnersApiFp(configuration).partnersBrowserpushsubscriptionsCreate(partnerId, browserPushSubscriptionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerBusinessUnitContractResource} partnerBusinessUnitContractResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitcontractsCreate(partnerId: string, partnerBusinessUnitContractResource: PartnerBusinessUnitContractResource, options?: any) {
            return PartnersApiFp(configuration).partnersBusinessunitcontractsCreate(partnerId, partnerBusinessUnitContractResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitcontractsGet(partnerId: string, contractId: string, options?: any) {
            return PartnersApiFp(configuration).partnersBusinessunitcontractsGet(partnerId, contractId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitcontractsIndex(partnerId: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersBusinessunitcontractsIndex(partnerId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {LeadPartnerAllocationLimitResource} leadPartnerAllocationLimitResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsAllocationlimitsCreate(partnerId: string, partnerBusinessUnitId: string, leadPartnerAllocationLimitResource: LeadPartnerAllocationLimitResource, options?: any) {
            return PartnersApiFp(configuration).partnersBusinessunitsAllocationlimitsCreate(partnerId, partnerBusinessUnitId, leadPartnerAllocationLimitResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {string} limitId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsAllocationlimitsDelete(partnerId: string, partnerBusinessUnitId: string, limitId: string, options?: any) {
            return PartnersApiFp(configuration).partnersBusinessunitsAllocationlimitsDelete(partnerId, partnerBusinessUnitId, limitId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [effectiveAtGte]
         * @param {string} [effectiveAtLte]
         * @param {string} [partnerBusinessUnitId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsAllocationlimitsIndex(partnerId: string, effectiveAtGte?: string, effectiveAtLte?: string, partnerBusinessUnitId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersBusinessunitsAllocationlimitsIndex(partnerId, effectiveAtGte, effectiveAtLte, partnerBusinessUnitId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {string} limitId
         * @param {LeadPartnerAllocationLimitResource} leadPartnerAllocationLimitResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsAllocationlimitsUpdate(partnerId: string, partnerBusinessUnitId: string, limitId: string, leadPartnerAllocationLimitResource: LeadPartnerAllocationLimitResource, options?: any) {
            return PartnersApiFp(configuration).partnersBusinessunitsAllocationlimitsUpdate(partnerId, partnerBusinessUnitId, limitId, leadPartnerAllocationLimitResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {LeadAllocationQuotaResource} leadAllocationQuotaResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsAllocationquotasCreate(partnerId: string, partnerBusinessUnitId: string, leadAllocationQuotaResource: LeadAllocationQuotaResource, options?: any) {
            return PartnersApiFp(configuration).partnersBusinessunitsAllocationquotasCreate(partnerId, partnerBusinessUnitId, leadAllocationQuotaResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {string} quotaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsAllocationquotasDelete(partnerId: string, partnerBusinessUnitId: string, quotaId: string, options?: any) {
            return PartnersApiFp(configuration).partnersBusinessunitsAllocationquotasDelete(partnerId, partnerBusinessUnitId, quotaId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [effectiveAtGte]
         * @param {string} [effectiveAtLte]
         * @param {string} [partnerBusinessUnitId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsAllocationquotasIndex(partnerId: string, effectiveAtGte?: string, effectiveAtLte?: string, partnerBusinessUnitId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersBusinessunitsAllocationquotasIndex(partnerId, effectiveAtGte, effectiveAtLte, partnerBusinessUnitId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerBusinessUnitResource} partnerBusinessUnitResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsCreate(partnerId: string, partnerBusinessUnitResource: PartnerBusinessUnitResource, options?: any) {
            return PartnersApiFp(configuration).partnersBusinessunitsCreate(partnerId, partnerBusinessUnitResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessunitId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsDelete(partnerId: string, partnerBusinessunitId: string, options?: any) {
            return PartnersApiFp(configuration).partnersBusinessunitsDelete(partnerId, partnerBusinessunitId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsGet(partnerId: string, partnerBusinessUnitId: string, options?: any) {
            return PartnersApiFp(configuration).partnersBusinessunitsGet(partnerId, partnerBusinessUnitId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [id] Primary ID.
         * @param {string} [partnerId] Partner ID.
         * @param {string} [geoCode] E.g. &#39;dk&#39;.
         * @param {string} [segmentationCriteria] Which criteria to segment on, e.g. language.
         * @param {number} [segmentedForLeadId] List business units that are segmented for this lead.
         * @param {boolean} [isActive] List business units that are active or inactive.
         * @param {'id' | 'allocation_fulfillment_percentage' | 'allocation_fulfillment_since_start_percentage' | 'allocation_fulfillment_forecast_percentage' | 'pay_per_spot_allocation_fulfillment_percentage' | 'pay_per_spot_allocation_fulfillment_forecast_percentage' | 'normal_quota_spot_allocation_fulfillment_percentage' | 'normal_quota_allocation_fulfillment_forecast_percentage' | 'auto_allocation' | 'partner_rating' | 'partner_rating_count' | 'ascending' | 'descending'} [sort] Sort criterion
         * @param {string} [geoPoint] Central point to search from.
         * @param {number} [geoDistance] Range in KM to search from geo_point.
         * @param {string} [assignedEmployeeId] Filter by assigned employee ID.
         * @param {string} [sectorId] Filter by sector IDs.
         * @param {string} [industryId] Filter by industry IDs.
         * @param {string} [clientTypeId] Filter by client type IDs.
         * @param {string} [revenueAmount] Find only business units with a revenue range that includes this amount.
         * @param {boolean} [hasMarketingPackage] Filter business units based on whether or not they have a marketing package.
         * @param {boolean} [hasBusinessModel] Filter business units based on whether or not they have any business model.
         * @param {string} [subGeoRegionId] Filter business units by sub geo region IDs.
         * @param {boolean} [isEligibleForAllocation] Filter business units based on whether they are eligible for allocation.
         * @param {string} [primaryIndustryId] The business unit&#39;s primary industry, e.g. law or accounting.
         * @param {number} [minimumPartnerRating] Minimum request partner rating.
         * @param {'none' | 'partner_association_id' | 'partner_id'} [collapse] Collapse business units based on identical values in specified field.
         * @param {string} [segmentedForCertificationId]
         * @param {string} [segmentedForIndustryId]
         * @param {string} [segmentedForClientTypeId]
         * @param {boolean} [isLeadBudget]
         * @param {boolean} [hasUnusedAllocations]
         * @param {boolean} [hasPausedContract]
         * @param {boolean} [hasActiveAccess]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsIndex(id?: string, partnerId?: string, geoCode?: string, segmentationCriteria?: string, segmentedForLeadId?: number, isActive?: boolean, sort?: 'id' | 'allocation_fulfillment_percentage' | 'allocation_fulfillment_since_start_percentage' | 'allocation_fulfillment_forecast_percentage' | 'pay_per_spot_allocation_fulfillment_percentage' | 'pay_per_spot_allocation_fulfillment_forecast_percentage' | 'normal_quota_spot_allocation_fulfillment_percentage' | 'normal_quota_allocation_fulfillment_forecast_percentage' | 'auto_allocation' | 'partner_rating' | 'partner_rating_count' | 'ascending' | 'descending', geoPoint?: string, geoDistance?: number, assignedEmployeeId?: string, sectorId?: string, industryId?: string, clientTypeId?: string, revenueAmount?: string, hasMarketingPackage?: boolean, hasBusinessModel?: boolean, subGeoRegionId?: string, isEligibleForAllocation?: boolean, primaryIndustryId?: string, minimumPartnerRating?: number, collapse?: 'none' | 'partner_association_id' | 'partner_id', segmentedForCertificationId?: string, segmentedForIndustryId?: string, segmentedForClientTypeId?: string, isLeadBudget?: boolean, hasUnusedAllocations?: boolean, hasPausedContract?: boolean, hasActiveAccess?: boolean, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersBusinessunitsIndex(id, partnerId, geoCode, segmentationCriteria, segmentedForLeadId, isActive, sort, geoPoint, geoDistance, assignedEmployeeId, sectorId, industryId, clientTypeId, revenueAmount, hasMarketingPackage, hasBusinessModel, subGeoRegionId, isEligibleForAllocation, primaryIndustryId, minimumPartnerRating, collapse, segmentedForCertificationId, segmentedForIndustryId, segmentedForClientTypeId, isLeadBudget, hasUnusedAllocations, hasPausedContract, hasActiveAccess, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {boolean} [isActive] Filter active industries.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsIndustriesIndex(partnerId: string, partnerBusinessUnitId: string, isActive?: boolean, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersBusinessunitsIndustriesIndex(partnerId, partnerBusinessUnitId, isActive, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {PartnerBusinessUnitIndustriesResource} partnerBusinessUnitIndustriesResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsIndustriesUpdate(partnerId: string, partnerBusinessUnitId: string, partnerBusinessUnitIndustriesResource: PartnerBusinessUnitIndustriesResource, options?: any) {
            return PartnersApiFp(configuration).partnersBusinessunitsIndustriesUpdate(partnerId, partnerBusinessUnitId, partnerBusinessUnitIndustriesResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {PartnerBusinessUnitRevenueRangeResource} partnerBusinessUnitRevenueRangeResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsRevenuerangesCreate(partnerId: string, partnerBusinessUnitId: string, partnerBusinessUnitRevenueRangeResource: PartnerBusinessUnitRevenueRangeResource, options?: any) {
            return PartnersApiFp(configuration).partnersBusinessunitsRevenuerangesCreate(partnerId, partnerBusinessUnitId, partnerBusinessUnitRevenueRangeResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsSectorsIndex(partnerId: string, partnerBusinessUnitId: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersBusinessunitsSectorsIndex(partnerId, partnerBusinessUnitId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {PartnerSectorsResource} partnerSectorsResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsSectorsUpdate(partnerId: string, partnerBusinessUnitId: string, partnerSectorsResource: PartnerSectorsResource, options?: any) {
            return PartnersApiFp(configuration).partnersBusinessunitsSectorsUpdate(partnerId, partnerBusinessUnitId, partnerSectorsResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsSegmentationgeoregionsIndex(partnerId: string, partnerBusinessUnitId: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersBusinessunitsSegmentationgeoregionsIndex(partnerId, partnerBusinessUnitId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {PartnerBusinessUnitSegmentationGeoRegionsResource} partnerBusinessUnitSegmentationGeoRegionsResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsSegmentationgeoregionsUpdate(partnerId: string, partnerBusinessUnitId: string, partnerBusinessUnitSegmentationGeoRegionsResource: PartnerBusinessUnitSegmentationGeoRegionsResource, options?: any) {
            return PartnersApiFp(configuration).partnersBusinessunitsSegmentationgeoregionsUpdate(partnerId, partnerBusinessUnitId, partnerBusinessUnitSegmentationGeoRegionsResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsTypesIndex(partnerId: string, partnerBusinessUnitId: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersBusinessunitsTypesIndex(partnerId, partnerBusinessUnitId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {PartnerBusinessUnitTypesResource} partnerBusinessUnitTypesResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsTypesUpdate(partnerId: string, partnerBusinessUnitId: string, partnerBusinessUnitTypesResource: PartnerBusinessUnitTypesResource, options?: any) {
            return PartnersApiFp(configuration).partnersBusinessunitsTypesUpdate(partnerId, partnerBusinessUnitId, partnerBusinessUnitTypesResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerBusinessUnitId
         * @param {PartnerBusinessUnitResource} partnerBusinessUnitResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersBusinessunitsUpdate(partnerId: string, partnerBusinessUnitId: string, partnerBusinessUnitResource: PartnerBusinessUnitResource, options?: any) {
            return PartnersApiFp(configuration).partnersBusinessunitsUpdate(partnerId, partnerBusinessUnitId, partnerBusinessUnitResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCashierpaymentmethodsGet(partnerId: string, options?: any) {
            return PartnersApiFp(configuration).partnersCashierpaymentmethodsGet(partnerId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {boolean} [isActive] Only include active subscriptions.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCashiersubscriptionsIndex(partnerId: string, isActive?: boolean, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersCashiersubscriptionsIndex(partnerId, isActive, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [id] Category id @var int
         * @param {string} [identifier] Category identifier @var string
         * @param {string} [title] Category title. @var string
         * @param {string} [geoCode] Category geo_code. @var string
         * @param {number} [limit] Limit of categories per page. @var int
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCategoriesIndex(id?: string, identifier?: string, title?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersCategoriesIndex(id, identifier, title, geoCode, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {CertificationResource} certificationResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCertificationsCreate(partnerId: string, certificationResource: CertificationResource, options?: any) {
            return PartnersApiFp(configuration).partnersCertificationsCreate(partnerId, certificationResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} certificationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCertificationsDelete(partnerId: string, certificationId: string, options?: any) {
            return PartnersApiFp(configuration).partnersCertificationsDelete(partnerId, certificationId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} certificationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCertificationsGet(partnerId: string, certificationId: string, options?: any) {
            return PartnersApiFp(configuration).partnersCertificationsGet(partnerId, certificationId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [types] Partner type enum. @var array
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCertificationsIndex(partnerId: string, types?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersCertificationsIndex(partnerId, types, geoCode, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerCertificationsResource} partnerCertificationsResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCertificationsUpdate(partnerId: string, partnerCertificationsResource: PartnerCertificationsResource, options?: any) {
            return PartnersApiFp(configuration).partnersCertificationsUpdate(partnerId, partnerCertificationsResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contentKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContentsDelete(partnerId: string, contentKey: string, options?: any) {
            return PartnersApiFp(configuration).partnersContentsDelete(partnerId, contentKey, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contentKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContentsGet(partnerId: string, contentKey: string, options?: any) {
            return PartnersApiFp(configuration).partnersContentsGet(partnerId, contentKey, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contentKey
         * @param {PartnerContentResource} partnerContentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContentsUpdate(partnerId: string, contentKey: string, partnerContentResource: PartnerContentResource, options?: any) {
            return PartnersApiFp(configuration).partnersContentsUpdate(partnerId, contentKey, partnerContentResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerContractOfferGroupResource} partnerContractOfferGroupResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractoffergroupsCreate(partnerId: string, partnerContractOfferGroupResource: PartnerContractOfferGroupResource, options?: any) {
            return PartnersApiFp(configuration).partnersContractoffergroupsCreate(partnerId, partnerContractOfferGroupResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {boolean} [isArchived]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractoffergroupsIndex(partnerId: string, isArchived?: boolean, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersContractoffergroupsIndex(partnerId, isArchived, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contractId
         * @param {PartnerContractActionResource} partnerContractActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsActionsCreate(partnerId: string, contractId: string, partnerContractActionResource: PartnerContractActionResource, options?: any) {
            return PartnersApiFp(configuration).partnersContractsActionsCreate(partnerId, contractId, partnerContractActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerContractId
         * @param {string} boostId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsAllocationboostsDelete(partnerId: string, partnerContractId: string, boostId: string, options?: any) {
            return PartnersApiFp(configuration).partnersContractsAllocationboostsDelete(partnerId, partnerContractId, boostId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerContractId
         * @param {string} boostId
         * @param {DateSpanResource} dateSpanResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsAllocationboostsUpdate(partnerId: string, partnerContractId: string, boostId: string, dateSpanResource: DateSpanResource, options?: any) {
            return PartnersApiFp(configuration).partnersContractsAllocationboostsUpdate(partnerId, partnerContractId, boostId, dateSpanResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerContractId
         * @param {string} pauseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsAllocationpausesDelete(partnerId: string, partnerContractId: string, pauseId: string, options?: any) {
            return PartnersApiFp(configuration).partnersContractsAllocationpausesDelete(partnerId, partnerContractId, pauseId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerContractId
         * @param {string} pauseId
         * @param {DateSpanResource} dateSpanResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsAllocationpausesUpdate(partnerId: string, partnerContractId: string, pauseId: string, dateSpanResource: DateSpanResource, options?: any) {
            return PartnersApiFp(configuration).partnersContractsAllocationpausesUpdate(partnerId, partnerContractId, pauseId, dateSpanResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerContractResource} partnerContractResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsCreate(partnerId: string, partnerContractResource: PartnerContractResource, options?: any) {
            return PartnersApiFp(configuration).partnersContractsCreate(partnerId, partnerContractResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerContractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsDelete(partnerId: string, partnerContractId: string, options?: any) {
            return PartnersApiFp(configuration).partnersContractsDelete(partnerId, partnerContractId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsGet(partnerId: string, contractId: string, options?: any) {
            return PartnersApiFp(configuration).partnersContractsGet(partnerId, contractId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {boolean} [isAccepted]
         * @param {boolean} [isArchived]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsIndex(partnerId: string, isAccepted?: boolean, isArchived?: boolean, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersContractsIndex(partnerId, isAccepted, isArchived, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsPdfGet(partnerId: string, contractId: string, options?: any) {
            return PartnersApiFp(configuration).partnersContractsPdfGet(partnerId, contractId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contractId
         * @param {PartnerContractSignedPdfFileResource} partnerContractSignedPdfFileResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsSignedpdfCreate(partnerId: string, contractId: string, partnerContractSignedPdfFileResource: PartnerContractSignedPdfFileResource, options?: any) {
            return PartnersApiFp(configuration).partnersContractsSignedpdfCreate(partnerId, contractId, partnerContractSignedPdfFileResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsSignedpdfGet(partnerId: string, contractId: string, options?: any) {
            return PartnersApiFp(configuration).partnersContractsSignedpdfGet(partnerId, contractId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsSigningurlGet(partnerId: string, contractId: string, options?: any) {
            return PartnersApiFp(configuration).partnersContractsSigningurlGet(partnerId, contractId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerContractId
         * @param {PartnerContractResource} partnerContractResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersContractsUpdate(partnerId: string, partnerContractId: string, partnerContractResource: PartnerContractResource, options?: any) {
            return PartnersApiFp(configuration).partnersContractsUpdate(partnerId, partnerContractId, partnerContractResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} couponId
         * @param {PartnerCouponActionResource} partnerCouponActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCouponsActionsCreate(partnerId: string, couponId: string, partnerCouponActionResource: PartnerCouponActionResource, options?: any) {
            return PartnersApiFp(configuration).partnersCouponsActionsCreate(partnerId, couponId, partnerCouponActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerCouponResource} partnerCouponResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCouponsCreate(partnerId: string, partnerCouponResource: PartnerCouponResource, options?: any) {
            return PartnersApiFp(configuration).partnersCouponsCreate(partnerId, partnerCouponResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {boolean} [showExpired] Only show expired partner coupons.
         * @param {boolean} [showDeactivated] Only show deactivated partner coupons.
         * @param {boolean} [showEmpty] Should empty partner coupons be included in the result.
         * @param {'created_at' | 'expires_at' | 'deactivated_at' | 'punches_left' | 'ascending' | 'descending'} [sort] Sort the coupons by different values
         * @param {string} [type] Filter the coupons type
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCouponsIndex(partnerId: string, showExpired?: boolean, showDeactivated?: boolean, showEmpty?: boolean, sort?: 'created_at' | 'expires_at' | 'deactivated_at' | 'punches_left' | 'ascending' | 'descending', type?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersCouponsIndex(partnerId, showExpired, showDeactivated, showEmpty, sort, type, limit, page, query, options)(fetch, basePath);
        },
        /**
         * Create a Partner
         * @summary Create a Partner
         * @param {PartnerResource} partnerResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersCreate(partnerResource: PartnerResource, options?: any) {
            return PartnersApiFp(configuration).partnersCreate(partnerResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDelete(partnerId: string, options?: any) {
            return PartnersApiFp(configuration).partnersDelete(partnerId, options)(fetch, basePath);
        },
        /**
         *
         * @param {PartnerDeliverableResource} partnerDeliverableResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesCreate(partnerDeliverableResource: PartnerDeliverableResource, options?: any) {
            return PartnersApiFp(configuration).partnersDeliverablesCreate(partnerDeliverableResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerDeliverableResource} partnerDeliverableResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesCreate_2(partnerId: string, partnerDeliverableResource: PartnerDeliverableResource, options?: any) {
            return PartnersApiFp(configuration).partnersDeliverablesCreate_2(partnerId, partnerDeliverableResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerDeliverableId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesGet(partnerDeliverableId: string, options?: any) {
            return PartnersApiFp(configuration).partnersDeliverablesGet(partnerDeliverableId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerDeliverableId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesGet_3(partnerId: string, partnerDeliverableId: string, options?: any) {
            return PartnersApiFp(configuration).partnersDeliverablesGet_3(partnerId, partnerDeliverableId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [clientId] Client id for the clients to filter by
         * @param {string} [leadId] Lead id for the lead to filter by
         * @param {string} [partnerId] Partner id of the partners to filter by.
         * @param {string} [partnerDeliverableId] Partner deliverable id of the partners to filter by.
         * @param {string} [status] States for the partners.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesIndex(clientId?: string, leadId?: string, partnerId?: string, partnerDeliverableId?: string, status?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersDeliverablesIndex(clientId, leadId, partnerId, partnerDeliverableId, status, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [clientId] Client id for the clients to filter by
         * @param {string} [leadId] Lead id for the lead to filter by
         * @param {string} [partnerDeliverableId] Partner deliverable id of the partners to filter by.
         * @param {string} [status] States for the partners.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesIndex_4(partnerId: string, clientId?: string, leadId?: string, partnerDeliverableId?: string, status?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersDeliverablesIndex_4(partnerId, clientId, leadId, partnerDeliverableId, status, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerDeliverableId
         * @param {PartnerDeliverableLineResource} partnerDeliverableLineResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesCreate(partnerDeliverableId: string, partnerDeliverableLineResource: PartnerDeliverableLineResource, options?: any) {
            return PartnersApiFp(configuration).partnersDeliverablesLinesCreate(partnerDeliverableId, partnerDeliverableLineResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerDeliverableId
         * @param {PartnerDeliverableLineResource} partnerDeliverableLineResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesCreate_5(partnerId: string, partnerDeliverableId: string, partnerDeliverableLineResource: PartnerDeliverableLineResource, options?: any) {
            return PartnersApiFp(configuration).partnersDeliverablesLinesCreate_5(partnerId, partnerDeliverableId, partnerDeliverableLineResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerDeliverableId
         * @param {string} partnerDeliverableLineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesDelete(partnerDeliverableId: string, partnerDeliverableLineId: string, options?: any) {
            return PartnersApiFp(configuration).partnersDeliverablesLinesDelete(partnerDeliverableId, partnerDeliverableLineId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerDeliverableId
         * @param {string} partnerDeliverableLineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesDelete_6(partnerId: string, partnerDeliverableId: string, partnerDeliverableLineId: string, options?: any) {
            return PartnersApiFp(configuration).partnersDeliverablesLinesDelete_6(partnerId, partnerDeliverableId, partnerDeliverableLineId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerDeliverableId
         * @param {string} partnerDeliverableLineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesGet(partnerDeliverableId: string, partnerDeliverableLineId: string, options?: any) {
            return PartnersApiFp(configuration).partnersDeliverablesLinesGet(partnerDeliverableId, partnerDeliverableLineId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerDeliverableId
         * @param {string} partnerDeliverableLineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesGet_7(partnerId: string, partnerDeliverableId: string, partnerDeliverableLineId: string, options?: any) {
            return PartnersApiFp(configuration).partnersDeliverablesLinesGet_7(partnerId, partnerDeliverableId, partnerDeliverableLineId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerDeliverableId
         * @param {string} [clientId] Client id for the clients to filter by
         * @param {string} [leadId] Lead id for the lead to filter by
         * @param {string} [partnerId] Partner id of the partners to filter by.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesIndex(partnerDeliverableId: string, clientId?: string, leadId?: string, partnerId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersDeliverablesLinesIndex(partnerDeliverableId, clientId, leadId, partnerId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerDeliverableId
         * @param {string} [clientId] Client id for the clients to filter by
         * @param {string} [leadId] Lead id for the lead to filter by
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesIndex_8(partnerId: string, partnerDeliverableId: string, clientId?: string, leadId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersDeliverablesLinesIndex_8(partnerId, partnerDeliverableId, clientId, leadId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerDeliverableId
         * @param {string} partnerDeliverableLineId
         * @param {PartnerDeliverableLineResource} partnerDeliverableLineResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesUpdate(partnerDeliverableId: string, partnerDeliverableLineId: string, partnerDeliverableLineResource: PartnerDeliverableLineResource, options?: any) {
            return PartnersApiFp(configuration).partnersDeliverablesLinesUpdate(partnerDeliverableId, partnerDeliverableLineId, partnerDeliverableLineResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerDeliverableId
         * @param {string} partnerDeliverableLineId
         * @param {PartnerDeliverableLineResource} partnerDeliverableLineResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesLinesUpdate_9(partnerId: string, partnerDeliverableId: string, partnerDeliverableLineId: string, partnerDeliverableLineResource: PartnerDeliverableLineResource, options?: any) {
            return PartnersApiFp(configuration).partnersDeliverablesLinesUpdate_9(partnerId, partnerDeliverableId, partnerDeliverableLineId, partnerDeliverableLineResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerDeliverableId
         * @param {PartnerDeliverableResource} partnerDeliverableResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesUpdate(partnerDeliverableId: string, partnerDeliverableResource: PartnerDeliverableResource, options?: any) {
            return PartnersApiFp(configuration).partnersDeliverablesUpdate(partnerDeliverableId, partnerDeliverableResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerDeliverableId
         * @param {PartnerDeliverableResource} partnerDeliverableResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDeliverablesUpdate_10(partnerId: string, partnerDeliverableId: string, partnerDeliverableResource: PartnerDeliverableResource, options?: any) {
            return PartnersApiFp(configuration).partnersDeliverablesUpdate_10(partnerId, partnerDeliverableId, partnerDeliverableResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerDiscountResource} partnerDiscountResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDiscountsCreate(partnerId: string, partnerDiscountResource: PartnerDiscountResource, options?: any) {
            return PartnersApiFp(configuration).partnersDiscountsCreate(partnerId, partnerDiscountResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerPackageTypeDiscountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDiscountsDelete(partnerId: string, partnerPackageTypeDiscountId: string, options?: any) {
            return PartnersApiFp(configuration).partnersDiscountsDelete(partnerId, partnerPackageTypeDiscountId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [isActive] Only include active discounts.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersDiscountsIndex(partnerId: string, isActive?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersDiscountsIndex(partnerId, isActive, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerEmployeeNoteResource} partnerEmployeeNoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersEmployeenotesCreate(partnerId: string, partnerEmployeeNoteResource: PartnerEmployeeNoteResource, options?: any) {
            return PartnersApiFp(configuration).partnersEmployeenotesCreate(partnerId, partnerEmployeeNoteResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} employeeNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersEmployeenotesDelete(partnerId: string, employeeNoteId: string, options?: any) {
            return PartnersApiFp(configuration).partnersEmployeenotesDelete(partnerId, employeeNoteId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [leadId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersEmployeenotesIndex(partnerId: string, leadId?: number, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersEmployeenotesIndex(partnerId, leadId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerFeatureResource} partnerFeatureResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersFeaturesCreate(partnerId: string, partnerFeatureResource: PartnerFeatureResource, options?: any) {
            return PartnersApiFp(configuration).partnersFeaturesCreate(partnerId, partnerFeatureResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} featureIdentifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersFeaturesDelete(partnerId: string, featureIdentifier: string, options?: any) {
            return PartnersApiFp(configuration).partnersFeaturesDelete(partnerId, featureIdentifier, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerFeeResource} partnerFeeResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersFeesCreate(partnerId: string, partnerFeeResource: PartnerFeeResource, options?: any) {
            return PartnersApiFp(configuration).partnersFeesCreate(partnerId, partnerFeeResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersFeesDelete(partnerId: string, id: string, options?: any) {
            return PartnersApiFp(configuration).partnersFeesDelete(partnerId, id, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} [id]
         * @param {number} [partnerId] Partner ID. @var int
         * @param {string} [effectiveAt] effective from date.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersFeesIndex(id?: number, partnerId?: number, effectiveAt?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersFeesIndex(id, partnerId, effectiveAt, limit, page, query, options)(fetch, basePath);
        },
        /**
         * Get a single partner by ID
         * @summary Get a single partner by ID
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersGet(partnerId: string, options?: any) {
            return PartnersApiFp(configuration).partnersGet(partnerId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerImpressumResource} partnerImpressumResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersImpressumsCreate(partnerId: string, partnerImpressumResource: PartnerImpressumResource, options?: any) {
            return PartnersApiFp(configuration).partnersImpressumsCreate(partnerId, partnerImpressumResource, options)(fetch, basePath);
        },
        /**
         * List partners
         * @summary List partners
         * @param {string} [partnerId] Filter partners by partner ID
         * @param {string} [state] States for the partners.
         * @param {boolean} [isEnabled]
         * @param {string} [employeeId] Employee id of the partners to filter by.
         * @param {string} [geoCode] Geographic Location Code.
         * @param {string} [satisfaction] Partner Satisfaction Ratio.
         * @param {string} [punchesUseSpeed] Punches use speed.
         * @param {string} [refillOfferId] Filter the partners by their refill offers
         * @param {string} [industryId] Filter the partners by their industry ids
         * @param {string} [industry] Filter the partners by their industry identifiers
         * @param {string} [geoPoint] Location Point to sort partners by
         * @param {string} [geoRect] Location Points to define an area to filter partners by. Provide the bottom left (southwest) and top right (northeast) corners of the reactangle separated by commas, e.g. \&quot;52.19,12.42,52.85,14.22\&quot;.
         * @param {number} [geoDistance] Location filter by distance
         * @param {'id' | 'relevance' | 'created_at' | 'company_name' | 'state' | 'satisfaction' | 'employee_name' | 'punches_monthly' | 'punches_use_speed' | 'last_login' | 'last_lead_bought' | 'revenue_6months' | 'last_invoice' | 'avg_punch_price_6months' | 'avg_due_days_6months' | 'last_coupon_size' | 'punches_left' | 'punches_spent_monthly' | 'punches_spent_6months' | 'punches_bought_6months' | 'golden_ratio_6months' | 'refill' | 'rating' | 'distance' | 'last_phone_call' | 'canvas_status' | 'address' | 'tasks' | 'last_subscription_start_date' | 'last_published_quote' | 'published_quotes' | 'win_ratio' | 'unmatched' | 'unmatched_percent' | 'digital_leads' | 'has_active_contract' | 'deallocations' | 'overdue_amount' | 'active_contracts_count' | 'active_allocation_pauses_count' | 'active_contracts_amount' | 'next_contract_renewal_at' | 'active_business_units_amount' | 'active_business_units_mrr' | 'current_period_weighted_delivery_trend' | 'success_ratio' | 'publishing_ratio' | 'all_time_purchased_marketplace_leads_value_excl_vat_local_currency' | 'all_time_purchased_marketplace_leads_count' | 'lead_marketplace_partner_spend_excl_vat_local_currency' | 'ascending' | 'descending'} [sort] Sort Partner&#39;s by a given property.
         * @param {string} [canvasStatus] Canvas Status
         * @param {boolean} [isPublic] Only show partners that are visible to the public
         * @param {string} [packageType] Partner subscription package type
         * @param {string} [badgeId]
         * @param {boolean} [digitalLeads]
         * @param {boolean} [hasMarketingPackage]
         * @param {boolean} [hasActiveContract]
         * @param {boolean} [hasUnusedAllocations]
         * @param {boolean} [wantsExclusiveLeads]
         * @param {string} [partnerAssigneeClientConsultantEmployeeId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersIndex(partnerId?: string, state?: string, isEnabled?: boolean, employeeId?: string, geoCode?: string, satisfaction?: string, punchesUseSpeed?: string, refillOfferId?: string, industryId?: string, industry?: string, geoPoint?: string, geoRect?: string, geoDistance?: number, sort?: 'id' | 'relevance' | 'created_at' | 'company_name' | 'state' | 'satisfaction' | 'employee_name' | 'punches_monthly' | 'punches_use_speed' | 'last_login' | 'last_lead_bought' | 'revenue_6months' | 'last_invoice' | 'avg_punch_price_6months' | 'avg_due_days_6months' | 'last_coupon_size' | 'punches_left' | 'punches_spent_monthly' | 'punches_spent_6months' | 'punches_bought_6months' | 'golden_ratio_6months' | 'refill' | 'rating' | 'distance' | 'last_phone_call' | 'canvas_status' | 'address' | 'tasks' | 'last_subscription_start_date' | 'last_published_quote' | 'published_quotes' | 'win_ratio' | 'unmatched' | 'unmatched_percent' | 'digital_leads' | 'has_active_contract' | 'deallocations' | 'overdue_amount' | 'active_contracts_count' | 'active_allocation_pauses_count' | 'active_contracts_amount' | 'next_contract_renewal_at' | 'active_business_units_amount' | 'active_business_units_mrr' | 'current_period_weighted_delivery_trend' | 'success_ratio' | 'publishing_ratio' | 'all_time_purchased_marketplace_leads_value_excl_vat_local_currency' | 'all_time_purchased_marketplace_leads_count' | 'lead_marketplace_partner_spend_excl_vat_local_currency' | 'ascending' | 'descending', canvasStatus?: string, isPublic?: boolean, packageType?: string, badgeId?: string, digitalLeads?: boolean, hasMarketingPackage?: boolean, hasActiveContract?: boolean, hasUnusedAllocations?: boolean, wantsExclusiveLeads?: boolean, partnerAssigneeClientConsultantEmployeeId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersIndex(partnerId, state, isEnabled, employeeId, geoCode, satisfaction, punchesUseSpeed, refillOfferId, industryId, industry, geoPoint, geoRect, geoDistance, sort, canvasStatus, isPublic, packageType, badgeId, digitalLeads, hasMarketingPackage, hasActiveContract, hasUnusedAllocations, wantsExclusiveLeads, partnerAssigneeClientConsultantEmployeeId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerIndustryResource} partnerIndustryResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersIndustriesCreate(partnerId: string, partnerIndustryResource: PartnerIndustryResource, options?: any) {
            return PartnersApiFp(configuration).partnersIndustriesCreate(partnerId, partnerIndustryResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} industryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersIndustriesDelete(partnerId: string, industryId: string, options?: any) {
            return PartnersApiFp(configuration).partnersIndustriesDelete(partnerId, industryId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} industryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersIndustriesGet(partnerId: string, industryId: string, options?: any) {
            return PartnersApiFp(configuration).partnersIndustriesGet(partnerId, industryId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [geoCode]
         * @param {boolean} [isActive]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersIndustriesIndex(partnerId: string, geoCode?: string, isActive?: boolean, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersIndustriesIndex(partnerId, geoCode, isActive, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerIndustriesResource} partnerIndustriesResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersIndustriesUpdate(partnerId: string, partnerIndustriesResource: PartnerIndustriesResource, options?: any) {
            return PartnersApiFp(configuration).partnersIndustriesUpdate(partnerId, partnerIndustriesResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [processAtGte] Get invoicings processed at after a date.
         * @param {string} [processAtLte] Get invoicings processed at after a date.
         * @param {string} [partnerId] Filter by zero or more partner IDs.
         * @param {'lead_budget' | 'pay_per_spot'} [businessModel] Lead budget or pay-per-spot.
         * @param {'process_at' | 'ascending' | 'descending'} [sort] Sort the invoicings.
         * @param {string} [geoCode] Filter by geo codes.
         * @param {string} [employeeId] Filter by partner&#39;s employee.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersInvoicingsIndex(processAtGte?: string, processAtLte?: string, partnerId?: string, businessModel?: 'lead_budget' | 'pay_per_spot', sort?: 'process_at' | 'ascending' | 'descending', geoCode?: string, employeeId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersInvoicingsIndex(processAtGte, processAtLte, partnerId, businessModel, sort, geoCode, employeeId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [processAtGte] Get invoicings processed at after a date.
         * @param {string} [processAtLte] Get invoicings processed at after a date.
         * @param {'lead_budget' | 'pay_per_spot'} [businessModel] Lead budget or pay-per-spot.
         * @param {'process_at' | 'ascending' | 'descending'} [sort] Sort the invoicings.
         * @param {string} [geoCode] Filter by geo codes.
         * @param {string} [employeeId] Filter by partner&#39;s employee.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersInvoicingsIndex_11(partnerId: string, processAtGte?: string, processAtLte?: string, businessModel?: 'lead_budget' | 'pay_per_spot', sort?: 'process_at' | 'ascending' | 'descending', geoCode?: string, employeeId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersInvoicingsIndex_11(partnerId, processAtGte, processAtLte, businessModel, sort, geoCode, employeeId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {LeadTypeResource} leadTypeResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersLeadtypesCreate(partnerId: string, leadTypeResource: LeadTypeResource, options?: any) {
            return PartnersApiFp(configuration).partnersLeadtypesCreate(partnerId, leadTypeResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} leadTypeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersLeadtypesDelete(partnerId: string, leadTypeId: string, options?: any) {
            return PartnersApiFp(configuration).partnersLeadtypesDelete(partnerId, leadTypeId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} leadTypeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersLeadtypesGet(leadTypeId: string, options?: any) {
            return PartnersApiFp(configuration).partnersLeadtypesGet(leadTypeId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} leadTypeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersLeadtypesGet_12(partnerId: string, leadTypeId: string, options?: any) {
            return PartnersApiFp(configuration).partnersLeadtypesGet_12(partnerId, leadTypeId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} [partnerId] Partner ID. @var int
         * @param {string} [types] Partner type enum. @var array
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersLeadtypesIndex(partnerId?: number, types?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersLeadtypesIndex(partnerId, types, geoCode, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [types] Partner type enum. @var array
         * @param {string} [geoCode]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersLeadtypesIndex_13(partnerId: string, types?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersLeadtypesIndex_13(partnerId, types, geoCode, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerLeadTypesResource} partnerLeadTypesResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersLeadtypesUpdate(partnerId: string, partnerLeadTypesResource: PartnerLeadTypesResource, options?: any) {
            return PartnersApiFp(configuration).partnersLeadtypesUpdate(partnerId, partnerLeadTypesResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {PartnerNoteResource} partnerNoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersNotesCreate(partnerNoteResource: PartnerNoteResource, options?: any) {
            return PartnersApiFp(configuration).partnersNotesCreate(partnerNoteResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerNoteResource} partnerNoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersNotesCreate_14(partnerId: string, partnerNoteResource: PartnerNoteResource, options?: any) {
            return PartnersApiFp(configuration).partnersNotesCreate_14(partnerId, partnerNoteResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersNotesGet(partnerId: string, partnerNoteId: string, options?: any) {
            return PartnersApiFp(configuration).partnersNotesGet(partnerId, partnerNoteId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} [partnerId]
         * @param {'all' | 'satisfactions' | 'notes'} [types]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersNotesIndex(partnerId?: number, types?: 'all' | 'satisfactions' | 'notes', limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersNotesIndex(partnerId, types, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {'all' | 'satisfactions' | 'notes'} [types]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersNotesIndex_15(partnerId: string, types?: 'all' | 'satisfactions' | 'notes', limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersNotesIndex_15(partnerId, types, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerQuoteProductCommentResource} partnerQuoteProductCommentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersQuoteproductcommentsCreate(partnerId: string, partnerQuoteProductCommentResource: PartnerQuoteProductCommentResource, options?: any) {
            return PartnersApiFp(configuration).partnersQuoteproductcommentsCreate(partnerId, partnerQuoteProductCommentResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} quoteProductCommentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersQuoteproductcommentsDelete(partnerId: string, quoteProductCommentId: string, options?: any) {
            return PartnersApiFp(configuration).partnersQuoteproductcommentsDelete(partnerId, quoteProductCommentId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersQuoteproductcommentsIndex(partnerId: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersQuoteproductcommentsIndex(partnerId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerQuoteProductPriceResource} partnerQuoteProductPriceResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersQuoteproductpricesCreate(partnerId: string, partnerQuoteProductPriceResource: PartnerQuoteProductPriceResource, options?: any) {
            return PartnersApiFp(configuration).partnersQuoteproductpricesCreate(partnerId, partnerQuoteProductPriceResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} quoteProductPriceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersQuoteproductpricesDelete(partnerId: string, quoteProductPriceId: string, options?: any) {
            return PartnersApiFp(configuration).partnersQuoteproductpricesDelete(partnerId, quoteProductPriceId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersQuoteproductpricesIndex(partnerId: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersQuoteproductpricesIndex(partnerId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [partnerId] IDs of partners
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersQuotevendorsIndex(partnerId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersQuotevendorsIndex(partnerId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerRevenueRangeResource} partnerRevenueRangeResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersRevenuerangesCreate(partnerId: string, partnerRevenueRangeResource: PartnerRevenueRangeResource, options?: any) {
            return PartnersApiFp(configuration).partnersRevenuerangesCreate(partnerId, partnerRevenueRangeResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerRevenueSegmentActionResource} partnerRevenueSegmentActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersRevenuesegmentsActionsCreate(partnerId: string, partnerRevenueSegmentActionResource: PartnerRevenueSegmentActionResource, options?: any) {
            return PartnersApiFp(configuration).partnersRevenuesegmentsActionsCreate(partnerId, partnerRevenueSegmentActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {PartnerRevenueSegmentResource} partnerRevenueSegmentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersRevenuesegmentsCreate(partnerRevenueSegmentResource: PartnerRevenueSegmentResource, options?: any) {
            return PartnersApiFp(configuration).partnersRevenuesegmentsCreate(partnerRevenueSegmentResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerRevenueSegmentResource} partnerRevenueSegmentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersRevenuesegmentsCreate_16(partnerId: string, partnerRevenueSegmentResource: PartnerRevenueSegmentResource, options?: any) {
            return PartnersApiFp(configuration).partnersRevenuesegmentsCreate_16(partnerId, partnerRevenueSegmentResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerRevenueSegmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersRevenuesegmentsDelete(partnerId: string, partnerRevenueSegmentId: string, options?: any) {
            return PartnersApiFp(configuration).partnersRevenuesegmentsDelete(partnerId, partnerRevenueSegmentId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersRevenuesegmentsIndex(partnerId: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersRevenuesegmentsIndex(partnerId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSectorsIndex(partnerId: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersSectorsIndex(partnerId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerSectorsResource} partnerSectorsResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSectorsUpdate(partnerId: string, partnerSectorsResource: PartnerSectorsResource, options?: any) {
            return PartnersApiFp(configuration).partnersSectorsUpdate(partnerId, partnerSectorsResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerUniqueSellingPointResource} partnerUniqueSellingPointResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSellingpointsCreate(partnerId: string, partnerUniqueSellingPointResource: PartnerUniqueSellingPointResource, options?: any) {
            return PartnersApiFp(configuration).partnersSellingpointsCreate(partnerId, partnerUniqueSellingPointResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} sellingPointId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSellingpointsDelete(partnerId: string, sellingPointId: string, options?: any) {
            return PartnersApiFp(configuration).partnersSellingpointsDelete(partnerId, sellingPointId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [id]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSellingpointsIndex(partnerId: string, id?: number, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersSellingpointsIndex(partnerId, id, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} sellingPointId
         * @param {PartnerUniqueSellingPointResource} partnerUniqueSellingPointResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSellingpointsUpdate(partnerId: string, sellingPointId: string, partnerUniqueSellingPointResource: PartnerUniqueSellingPointResource, options?: any) {
            return PartnersApiFp(configuration).partnersSellingpointsUpdate(partnerId, sellingPointId, partnerUniqueSellingPointResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} agreementId
         * @param {PartnerSubscriptionAgreementActionResource} partnerSubscriptionAgreementActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionagreementsActionsCreate(partnerId: string, agreementId: string, partnerSubscriptionAgreementActionResource: PartnerSubscriptionAgreementActionResource, options?: any) {
            return PartnersApiFp(configuration).partnersSubscriptionagreementsActionsCreate(partnerId, agreementId, partnerSubscriptionAgreementActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerSubscriptionAgreementResource} partnerSubscriptionAgreementResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionagreementsCreate(partnerId: string, partnerSubscriptionAgreementResource: PartnerSubscriptionAgreementResource, options?: any) {
            return PartnersApiFp(configuration).partnersSubscriptionagreementsCreate(partnerId, partnerSubscriptionAgreementResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {boolean} [isAvailableForAgreement] List agreemenets that are available.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionagreementsIndex(partnerId: string, isAvailableForAgreement?: boolean, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersSubscriptionagreementsIndex(partnerId, isAvailableForAgreement, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} agreementId
         * @param {PartnerSubscriptionAgreementResource} partnerSubscriptionAgreementResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionagreementsUpdate(partnerId: string, agreementId: string, partnerSubscriptionAgreementResource: PartnerSubscriptionAgreementResource, options?: any) {
            return PartnersApiFp(configuration).partnersSubscriptionagreementsUpdate(partnerId, agreementId, partnerSubscriptionAgreementResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} subscriptionId
         * @param {PartnerSubscriptionActionResource} partnerSubscriptionActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsActionsCreate(partnerId: string, subscriptionId: string, partnerSubscriptionActionResource: PartnerSubscriptionActionResource, options?: any) {
            return PartnersApiFp(configuration).partnersSubscriptionsActionsCreate(partnerId, subscriptionId, partnerSubscriptionActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerSubscriptionResource} partnerSubscriptionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsCreate(partnerId: string, partnerSubscriptionResource: PartnerSubscriptionResource, options?: any) {
            return PartnersApiFp(configuration).partnersSubscriptionsCreate(partnerId, partnerSubscriptionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [isActive] Only include active subscriptions.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsIndex(partnerId: string, isActive?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersSubscriptionsIndex(partnerId, isActive, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} subscriptionId
         * @param {PartnerSubscriptionPauseResource} partnerSubscriptionPauseResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsPausesCreate(partnerId: string, subscriptionId: string, partnerSubscriptionPauseResource: PartnerSubscriptionPauseResource, options?: any) {
            return PartnersApiFp(configuration).partnersSubscriptionsPausesCreate(partnerId, subscriptionId, partnerSubscriptionPauseResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} subscriptionId
         * @param {string} pauseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsPausesDelete(partnerId: string, subscriptionId: string, pauseId: string, options?: any) {
            return PartnersApiFp(configuration).partnersSubscriptionsPausesDelete(partnerId, subscriptionId, pauseId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} subscriptionId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsPausesIndex(partnerId: string, subscriptionId: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersSubscriptionsPausesIndex(partnerId, subscriptionId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} subscriptionId
         * @param {PartnerSubscriptionPriceResource} partnerSubscriptionPriceResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsPricesCreate(partnerId: string, subscriptionId: string, partnerSubscriptionPriceResource: PartnerSubscriptionPriceResource, options?: any) {
            return PartnersApiFp(configuration).partnersSubscriptionsPricesCreate(partnerId, subscriptionId, partnerSubscriptionPriceResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} subscriptionId
         * @param {string} subscriptionPriceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsPricesDelete(partnerId: string, subscriptionId: string, subscriptionPriceId: string, options?: any) {
            return PartnersApiFp(configuration).partnersSubscriptionsPricesDelete(partnerId, subscriptionId, subscriptionPriceId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} subscriptionId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsPricesIndex(partnerId: string, subscriptionId: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersSubscriptionsPricesIndex(partnerId, subscriptionId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} subscriptionId
         * @param {PartnerSubscriptionResource} partnerSubscriptionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptionsUpdate(partnerId: string, subscriptionId: string, partnerSubscriptionResource: PartnerSubscriptionResource, options?: any) {
            return PartnersApiFp(configuration).partnersSubscriptionsUpdate(partnerId, subscriptionId, partnerSubscriptionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [geoCode] Filter by geo code.
         * @param {boolean} [isHidden] Filter hidden types.
         * @param {'' | 'lead_marketplace' | 'ascending' | 'descending'} [identifier] Filter by identifier.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptiontypesIndex(geoCode?: string, isHidden?: boolean, identifier?: '' | 'lead_marketplace' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersSubscriptiontypesIndex(geoCode, isHidden, identifier, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [geoCode] Filter by geo code.
         * @param {boolean} [isHidden] Filter hidden types.
         * @param {'' | 'lead_marketplace' | 'ascending' | 'descending'} [identifier] Filter by identifier.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSubscriptiontypesIndex_17(partnerId: string, geoCode?: string, isHidden?: boolean, identifier?: '' | 'lead_marketplace' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersSubscriptiontypesIndex_17(partnerId, geoCode, isHidden, identifier, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} [limit] Limit the number of suggest resources pr. page.
         * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
         * @param {string} [query] The search query to search by.
         * @param {string} [type] Type to search for.
         * @param {string} [partnerState] Partner state.
         * @param {number} [page] The page position in the result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersSuggestIndex(limit?: number, geoCode?: string, query?: string, type?: string, partnerState?: string, page?: number, options?: any) {
            return PartnersApiFp(configuration).partnersSuggestIndex(limit, geoCode, query, type, partnerState, page, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} [id]
         * @param {string} [identifier]
         * @param {number} [industryId]
         * @param {string} [industryIdentifier]
         * @param {string} [geoCode]
         * @param {string} [sort]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersTitlesIndex(id?: number, identifier?: string, industryId?: number, industryIdentifier?: string, geoCode?: string, sort?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersTitlesIndex(id, identifier, industryId, industryIdentifier, geoCode, sort, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersTransactionsIndex(partnerId: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersTransactionsIndex(partnerId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerResource} partnerResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUpdate(partnerId: string, partnerResource: PartnerResource, options?: any) {
            return PartnersApiFp(configuration).partnersUpdate(partnerId, partnerResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerUserId
         * @param {PartnerUserActionResource} partnerUserActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersActionsCreate(partnerId: string, partnerUserId: string, partnerUserActionResource: PartnerUserActionResource, options?: any) {
            return PartnersApiFp(configuration).partnersUsersActionsCreate(partnerId, partnerUserId, partnerUserActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} contentKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersContentsDelete(partnerUserId: string, contentKey: string, options?: any) {
            return PartnersApiFp(configuration).partnersUsersContentsDelete(partnerUserId, contentKey, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerUserId
         * @param {string} contentKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersContentsDelete_18(partnerId: string, partnerUserId: string, contentKey: string, options?: any) {
            return PartnersApiFp(configuration).partnersUsersContentsDelete_18(partnerId, partnerUserId, contentKey, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} contentKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersContentsGet(partnerUserId: string, contentKey: string, options?: any) {
            return PartnersApiFp(configuration).partnersUsersContentsGet(partnerUserId, contentKey, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerUserId
         * @param {string} contentKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersContentsGet_19(partnerId: string, partnerUserId: string, contentKey: string, options?: any) {
            return PartnersApiFp(configuration).partnersUsersContentsGet_19(partnerId, partnerUserId, contentKey, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {string} contentKey
         * @param {PartnerContentResource} partnerContentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersContentsUpdate(partnerUserId: string, contentKey: string, partnerContentResource: PartnerContentResource, options?: any) {
            return PartnersApiFp(configuration).partnersUsersContentsUpdate(partnerUserId, contentKey, partnerContentResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerUserId
         * @param {string} contentKey
         * @param {PartnerContentResource} partnerContentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersContentsUpdate_20(partnerId: string, partnerUserId: string, contentKey: string, partnerContentResource: PartnerContentResource, options?: any) {
            return PartnersApiFp(configuration).partnersUsersContentsUpdate_20(partnerId, partnerUserId, contentKey, partnerContentResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {PartnerUserResource} partnerUserResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersCreate(partnerUserResource: PartnerUserResource, options?: any) {
            return PartnersApiFp(configuration).partnersUsersCreate(partnerUserResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerUserResource} partnerUserResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersCreate_21(partnerId: string, partnerUserResource: PartnerUserResource, options?: any) {
            return PartnersApiFp(configuration).partnersUsersCreate_21(partnerId, partnerUserResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerUserId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersDelete(partnerId: string, partnerUserId: string, options?: any) {
            return PartnersApiFp(configuration).partnersUsersDelete(partnerId, partnerUserId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersGet(partnerUserId: string, options?: any) {
            return PartnersApiFp(configuration).partnersUsersGet(partnerUserId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerUserId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersGet_22(partnerId: string, partnerUserId: string, options?: any) {
            return PartnersApiFp(configuration).partnersUsersGet_22(partnerId, partnerUserId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [partnerUserId] Partner User Id.
         * @param {string} [partnerId] Partner id for the partner users to filter by.
         * @param {string} [sort]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersIndex(partnerUserId?: string, partnerId?: string, sort?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersUsersIndex(partnerUserId, partnerId, sort, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} [partnerUserId] Partner User Id.
         * @param {string} [sort]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersIndex_23(partnerId: string, partnerUserId?: string, sort?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersUsersIndex_23(partnerId, partnerUserId, sort, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerUserId
         * @param {PartnerUserResource} partnerUserResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersUpdate(partnerUserId: string, partnerUserResource: PartnerUserResource, options?: any) {
            return PartnersApiFp(configuration).partnersUsersUpdate(partnerUserId, partnerUserResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {string} partnerUserId
         * @param {PartnerUserResource} partnerUserResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersUsersUpdate_24(partnerId: string, partnerUserId: string, partnerUserResource: PartnerUserResource, options?: any) {
            return PartnersApiFp(configuration).partnersUsersUpdate_24(partnerId, partnerUserId, partnerUserResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [partnerId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersVerificationsIndex(partnerId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersVerificationsIndex(partnerId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersVerificationsIndex_25(partnerId: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersApiFp(configuration).partnersVerificationsIndex_25(partnerId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} partnerId
         * @param {PartnerVerificationResource} partnerVerificationResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersVerificationsUpdate(partnerId: string, partnerVerificationResource: PartnerVerificationResource, options?: any) {
            return PartnersApiFp(configuration).partnersVerificationsUpdate(partnerId, partnerVerificationResource, options)(fetch, basePath);
        },
    };
};

/**
 * PartnersApi - object-oriented interface
 * @export
 * @class PartnersApi
 * @extends {BaseAPI}
 */
export class PartnersApi extends BaseAPI {
    /**
     *
     * @param {string} partnerId
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersActionfeesIndex(partnerId: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersActionfeesIndex(partnerId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerActionResource} partnerActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersActionsCreate(partnerId: string, partnerActionResource: PartnerActionResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersActionsCreate(partnerId, partnerActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [partnerId] Filter partners by partner ID
     * @param {string} [state] States for the partners.
     * @param {boolean} [isEnabled]
     * @param {string} [employeeId] Employee id of the partners to filter by.
     * @param {string} [geoCode] Geographic Location Code.
     * @param {string} [satisfaction] Partner Satisfaction Ratio.
     * @param {string} [punchesUseSpeed] Punches use speed.
     * @param {string} [refillOfferId] Filter the partners by their refill offers
     * @param {string} [industryId] Filter the partners by their industry ids
     * @param {string} [industry] Filter the partners by their industry identifiers
     * @param {string} [geoPoint] Location Point to sort partners by
     * @param {string} [geoRect] Location Points to define an area to filter partners by. Provide the bottom left (southwest) and top right (northeast) corners of the reactangle separated by commas, e.g. \&quot;52.19,12.42,52.85,14.22\&quot;.
     * @param {number} [geoDistance] Location filter by distance
     * @param {'id' | 'relevance' | 'created_at' | 'company_name' | 'state' | 'satisfaction' | 'employee_name' | 'punches_monthly' | 'punches_use_speed' | 'last_login' | 'last_lead_bought' | 'revenue_6months' | 'last_invoice' | 'avg_punch_price_6months' | 'avg_due_days_6months' | 'last_coupon_size' | 'punches_left' | 'punches_spent_monthly' | 'punches_spent_6months' | 'punches_bought_6months' | 'golden_ratio_6months' | 'refill' | 'rating' | 'distance' | 'last_phone_call' | 'canvas_status' | 'address' | 'tasks' | 'last_subscription_start_date' | 'last_published_quote' | 'published_quotes' | 'win_ratio' | 'unmatched' | 'unmatched_percent' | 'digital_leads' | 'has_active_contract' | 'deallocations' | 'overdue_amount' | 'active_contracts_count' | 'active_allocation_pauses_count' | 'active_contracts_amount' | 'next_contract_renewal_at' | 'active_business_units_amount' | 'active_business_units_mrr' | 'current_period_weighted_delivery_trend' | 'success_ratio' | 'publishing_ratio' | 'all_time_purchased_marketplace_leads_value_excl_vat_local_currency' | 'all_time_purchased_marketplace_leads_count' | 'lead_marketplace_partner_spend_excl_vat_local_currency' | 'ascending' | 'descending'} [sort] Sort Partner&#39;s by a given property.
     * @param {string} [canvasStatus] Canvas Status
     * @param {boolean} [isPublic] Only show partners that are visible to the public
     * @param {string} [packageType] Partner subscription package type
     * @param {string} [badgeId]
     * @param {boolean} [digitalLeads]
     * @param {boolean} [hasMarketingPackage]
     * @param {boolean} [hasActiveContract]
     * @param {boolean} [hasUnusedAllocations]
     * @param {boolean} [wantsExclusiveLeads]
     * @param {string} [partnerAssigneeClientConsultantEmployeeId]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersAggregationsIndex(partnerId?: string, state?: string, isEnabled?: boolean, employeeId?: string, geoCode?: string, satisfaction?: string, punchesUseSpeed?: string, refillOfferId?: string, industryId?: string, industry?: string, geoPoint?: string, geoRect?: string, geoDistance?: number, sort?: 'id' | 'relevance' | 'created_at' | 'company_name' | 'state' | 'satisfaction' | 'employee_name' | 'punches_monthly' | 'punches_use_speed' | 'last_login' | 'last_lead_bought' | 'revenue_6months' | 'last_invoice' | 'avg_punch_price_6months' | 'avg_due_days_6months' | 'last_coupon_size' | 'punches_left' | 'punches_spent_monthly' | 'punches_spent_6months' | 'punches_bought_6months' | 'golden_ratio_6months' | 'refill' | 'rating' | 'distance' | 'last_phone_call' | 'canvas_status' | 'address' | 'tasks' | 'last_subscription_start_date' | 'last_published_quote' | 'published_quotes' | 'win_ratio' | 'unmatched' | 'unmatched_percent' | 'digital_leads' | 'has_active_contract' | 'deallocations' | 'overdue_amount' | 'active_contracts_count' | 'active_allocation_pauses_count' | 'active_contracts_amount' | 'next_contract_renewal_at' | 'active_business_units_amount' | 'active_business_units_mrr' | 'current_period_weighted_delivery_trend' | 'success_ratio' | 'publishing_ratio' | 'all_time_purchased_marketplace_leads_value_excl_vat_local_currency' | 'all_time_purchased_marketplace_leads_count' | 'lead_marketplace_partner_spend_excl_vat_local_currency' | 'ascending' | 'descending', canvasStatus?: string, isPublic?: boolean, packageType?: string, badgeId?: string, digitalLeads?: boolean, hasMarketingPackage?: boolean, hasActiveContract?: boolean, hasUnusedAllocations?: boolean, wantsExclusiveLeads?: boolean, partnerAssigneeClientConsultantEmployeeId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersAggregationsIndex(partnerId, state, isEnabled, employeeId, geoCode, satisfaction, punchesUseSpeed, refillOfferId, industryId, industry, geoPoint, geoRect, geoDistance, sort, canvasStatus, isPublic, packageType, badgeId, digitalLeads, hasMarketingPackage, hasActiveContract, hasUnusedAllocations, wantsExclusiveLeads, partnerAssigneeClientConsultantEmployeeId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerAllocationBoostResource} partnerAllocationBoostResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersAllocationboostsCreate(partnerId: string, partnerAllocationBoostResource: PartnerAllocationBoostResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersAllocationboostsCreate(partnerId, partnerAllocationBoostResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [processAtGte] Get invoicings processed at after a date.
     * @param {string} [processAtLte] Get invoicings processed at after a date.
     * @param {string} [partnerId] Filter by zero or more partner IDs.
     * @param {'process_at' | 'ascending' | 'descending'} [sort] Sort the invoicings.
     * @param {string} [geoCode] Filter by geo codes.
     * @param {string} [employeeId] Filter by partner&#39;s employee.
     * @param {string} [revenueSegmentId] Filter by revenue segment ID.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersAllocationdeliveriesIndex(processAtGte?: string, processAtLte?: string, partnerId?: string, sort?: 'process_at' | 'ascending' | 'descending', geoCode?: string, employeeId?: string, revenueSegmentId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersAllocationdeliveriesIndex(processAtGte, processAtLte, partnerId, sort, geoCode, employeeId, revenueSegmentId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} [processAtGte] Get invoicings processed at after a date.
     * @param {string} [processAtLte] Get invoicings processed at after a date.
     * @param {'process_at' | 'ascending' | 'descending'} [sort] Sort the invoicings.
     * @param {string} [geoCode] Filter by geo codes.
     * @param {string} [employeeId] Filter by partner&#39;s employee.
     * @param {string} [revenueSegmentId] Filter by revenue segment ID.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersAllocationdeliveriesIndex_1(partnerId: string, processAtGte?: string, processAtLte?: string, sort?: 'process_at' | 'ascending' | 'descending', geoCode?: string, employeeId?: string, revenueSegmentId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersAllocationdeliveriesIndex_1(partnerId, processAtGte, processAtLte, sort, geoCode, employeeId, revenueSegmentId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerAllocationPauseResource} partnerAllocationPauseResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersAllocationpausesCreate(partnerId: string, partnerAllocationPauseResource: PartnerAllocationPauseResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersAllocationpausesCreate(partnerId, partnerAllocationPauseResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerAssigneeResource} partnerAssigneeResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersAssigneesCreate(partnerId: string, partnerAssigneeResource: PartnerAssigneeResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersAssigneesCreate(partnerId, partnerAssigneeResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerAssigneeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersAssigneesDelete(partnerId: string, partnerAssigneeId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersAssigneesDelete(partnerId, partnerAssigneeId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {'all' | 'client_consultant' | 'account_manager'} [roles]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersAssigneesIndex(partnerId: string, roles?: 'all' | 'client_consultant' | 'account_manager', limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersAssigneesIndex(partnerId, roles, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerBankaccountResource} partnerBankaccountResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBankaccountsCreate(partnerId: string, partnerBankaccountResource: PartnerBankaccountResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersBankaccountsCreate(partnerId, partnerBankaccountResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBankaccountsIndex(partnerId: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersBankaccountsIndex(partnerId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {BrowserPushSubscriptionResource} browserPushSubscriptionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBrowserpushsubscriptionsCreate(partnerId: string, browserPushSubscriptionResource: BrowserPushSubscriptionResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersBrowserpushsubscriptionsCreate(partnerId, browserPushSubscriptionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerBusinessUnitContractResource} partnerBusinessUnitContractResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBusinessunitcontractsCreate(partnerId: string, partnerBusinessUnitContractResource: PartnerBusinessUnitContractResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersBusinessunitcontractsCreate(partnerId, partnerBusinessUnitContractResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} contractId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBusinessunitcontractsGet(partnerId: string, contractId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersBusinessunitcontractsGet(partnerId, contractId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBusinessunitcontractsIndex(partnerId: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersBusinessunitcontractsIndex(partnerId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerBusinessUnitId
     * @param {LeadPartnerAllocationLimitResource} leadPartnerAllocationLimitResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBusinessunitsAllocationlimitsCreate(partnerId: string, partnerBusinessUnitId: string, leadPartnerAllocationLimitResource: LeadPartnerAllocationLimitResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersBusinessunitsAllocationlimitsCreate(partnerId, partnerBusinessUnitId, leadPartnerAllocationLimitResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerBusinessUnitId
     * @param {string} limitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBusinessunitsAllocationlimitsDelete(partnerId: string, partnerBusinessUnitId: string, limitId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersBusinessunitsAllocationlimitsDelete(partnerId, partnerBusinessUnitId, limitId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} [effectiveAtGte]
     * @param {string} [effectiveAtLte]
     * @param {string} [partnerBusinessUnitId]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBusinessunitsAllocationlimitsIndex(partnerId: string, effectiveAtGte?: string, effectiveAtLte?: string, partnerBusinessUnitId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersBusinessunitsAllocationlimitsIndex(partnerId, effectiveAtGte, effectiveAtLte, partnerBusinessUnitId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerBusinessUnitId
     * @param {string} limitId
     * @param {LeadPartnerAllocationLimitResource} leadPartnerAllocationLimitResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBusinessunitsAllocationlimitsUpdate(partnerId: string, partnerBusinessUnitId: string, limitId: string, leadPartnerAllocationLimitResource: LeadPartnerAllocationLimitResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersBusinessunitsAllocationlimitsUpdate(partnerId, partnerBusinessUnitId, limitId, leadPartnerAllocationLimitResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerBusinessUnitId
     * @param {LeadAllocationQuotaResource} leadAllocationQuotaResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBusinessunitsAllocationquotasCreate(partnerId: string, partnerBusinessUnitId: string, leadAllocationQuotaResource: LeadAllocationQuotaResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersBusinessunitsAllocationquotasCreate(partnerId, partnerBusinessUnitId, leadAllocationQuotaResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerBusinessUnitId
     * @param {string} quotaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBusinessunitsAllocationquotasDelete(partnerId: string, partnerBusinessUnitId: string, quotaId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersBusinessunitsAllocationquotasDelete(partnerId, partnerBusinessUnitId, quotaId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} [effectiveAtGte]
     * @param {string} [effectiveAtLte]
     * @param {string} [partnerBusinessUnitId]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBusinessunitsAllocationquotasIndex(partnerId: string, effectiveAtGte?: string, effectiveAtLte?: string, partnerBusinessUnitId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersBusinessunitsAllocationquotasIndex(partnerId, effectiveAtGte, effectiveAtLte, partnerBusinessUnitId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerBusinessUnitResource} partnerBusinessUnitResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBusinessunitsCreate(partnerId: string, partnerBusinessUnitResource: PartnerBusinessUnitResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersBusinessunitsCreate(partnerId, partnerBusinessUnitResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerBusinessunitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBusinessunitsDelete(partnerId: string, partnerBusinessunitId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersBusinessunitsDelete(partnerId, partnerBusinessunitId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerBusinessUnitId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBusinessunitsGet(partnerId: string, partnerBusinessUnitId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersBusinessunitsGet(partnerId, partnerBusinessUnitId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [id] Primary ID.
     * @param {string} [partnerId] Partner ID.
     * @param {string} [geoCode] E.g. &#39;dk&#39;.
     * @param {string} [segmentationCriteria] Which criteria to segment on, e.g. language.
     * @param {number} [segmentedForLeadId] List business units that are segmented for this lead.
     * @param {boolean} [isActive] List business units that are active or inactive.
     * @param {'id' | 'allocation_fulfillment_percentage' | 'allocation_fulfillment_since_start_percentage' | 'allocation_fulfillment_forecast_percentage' | 'pay_per_spot_allocation_fulfillment_percentage' | 'pay_per_spot_allocation_fulfillment_forecast_percentage' | 'normal_quota_spot_allocation_fulfillment_percentage' | 'normal_quota_allocation_fulfillment_forecast_percentage' | 'auto_allocation' | 'partner_rating' | 'partner_rating_count' | 'ascending' | 'descending'} [sort] Sort criterion
     * @param {string} [geoPoint] Central point to search from.
     * @param {number} [geoDistance] Range in KM to search from geo_point.
     * @param {string} [assignedEmployeeId] Filter by assigned employee ID.
     * @param {string} [sectorId] Filter by sector IDs.
     * @param {string} [industryId] Filter by industry IDs.
     * @param {string} [clientTypeId] Filter by client type IDs.
     * @param {string} [revenueAmount] Find only business units with a revenue range that includes this amount.
     * @param {boolean} [hasMarketingPackage] Filter business units based on whether or not they have a marketing package.
     * @param {boolean} [hasBusinessModel] Filter business units based on whether or not they have any business model.
     * @param {string} [subGeoRegionId] Filter business units by sub geo region IDs.
     * @param {boolean} [isEligibleForAllocation] Filter business units based on whether they are eligible for allocation.
     * @param {string} [primaryIndustryId] The business unit&#39;s primary industry, e.g. law or accounting.
     * @param {number} [minimumPartnerRating] Minimum request partner rating.
     * @param {'none' | 'partner_association_id' | 'partner_id'} [collapse] Collapse business units based on identical values in specified field.
     * @param {string} [segmentedForCertificationId]
     * @param {string} [segmentedForIndustryId]
     * @param {string} [segmentedForClientTypeId]
     * @param {boolean} [isLeadBudget]
     * @param {boolean} [hasUnusedAllocations]
     * @param {boolean} [hasPausedContract]
     * @param {boolean} [hasActiveAccess]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBusinessunitsIndex(id?: string, partnerId?: string, geoCode?: string, segmentationCriteria?: string, segmentedForLeadId?: number, isActive?: boolean, sort?: 'id' | 'allocation_fulfillment_percentage' | 'allocation_fulfillment_since_start_percentage' | 'allocation_fulfillment_forecast_percentage' | 'pay_per_spot_allocation_fulfillment_percentage' | 'pay_per_spot_allocation_fulfillment_forecast_percentage' | 'normal_quota_spot_allocation_fulfillment_percentage' | 'normal_quota_allocation_fulfillment_forecast_percentage' | 'auto_allocation' | 'partner_rating' | 'partner_rating_count' | 'ascending' | 'descending', geoPoint?: string, geoDistance?: number, assignedEmployeeId?: string, sectorId?: string, industryId?: string, clientTypeId?: string, revenueAmount?: string, hasMarketingPackage?: boolean, hasBusinessModel?: boolean, subGeoRegionId?: string, isEligibleForAllocation?: boolean, primaryIndustryId?: string, minimumPartnerRating?: number, collapse?: 'none' | 'partner_association_id' | 'partner_id', segmentedForCertificationId?: string, segmentedForIndustryId?: string, segmentedForClientTypeId?: string, isLeadBudget?: boolean, hasUnusedAllocations?: boolean, hasPausedContract?: boolean, hasActiveAccess?: boolean, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersBusinessunitsIndex(id, partnerId, geoCode, segmentationCriteria, segmentedForLeadId, isActive, sort, geoPoint, geoDistance, assignedEmployeeId, sectorId, industryId, clientTypeId, revenueAmount, hasMarketingPackage, hasBusinessModel, subGeoRegionId, isEligibleForAllocation, primaryIndustryId, minimumPartnerRating, collapse, segmentedForCertificationId, segmentedForIndustryId, segmentedForClientTypeId, isLeadBudget, hasUnusedAllocations, hasPausedContract, hasActiveAccess, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerBusinessUnitId
     * @param {boolean} [isActive] Filter active industries.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBusinessunitsIndustriesIndex(partnerId: string, partnerBusinessUnitId: string, isActive?: boolean, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersBusinessunitsIndustriesIndex(partnerId, partnerBusinessUnitId, isActive, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerBusinessUnitId
     * @param {PartnerBusinessUnitIndustriesResource} partnerBusinessUnitIndustriesResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBusinessunitsIndustriesUpdate(partnerId: string, partnerBusinessUnitId: string, partnerBusinessUnitIndustriesResource: PartnerBusinessUnitIndustriesResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersBusinessunitsIndustriesUpdate(partnerId, partnerBusinessUnitId, partnerBusinessUnitIndustriesResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerBusinessUnitId
     * @param {PartnerBusinessUnitRevenueRangeResource} partnerBusinessUnitRevenueRangeResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBusinessunitsRevenuerangesCreate(partnerId: string, partnerBusinessUnitId: string, partnerBusinessUnitRevenueRangeResource: PartnerBusinessUnitRevenueRangeResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersBusinessunitsRevenuerangesCreate(partnerId, partnerBusinessUnitId, partnerBusinessUnitRevenueRangeResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerBusinessUnitId
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBusinessunitsSectorsIndex(partnerId: string, partnerBusinessUnitId: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersBusinessunitsSectorsIndex(partnerId, partnerBusinessUnitId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerBusinessUnitId
     * @param {PartnerSectorsResource} partnerSectorsResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBusinessunitsSectorsUpdate(partnerId: string, partnerBusinessUnitId: string, partnerSectorsResource: PartnerSectorsResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersBusinessunitsSectorsUpdate(partnerId, partnerBusinessUnitId, partnerSectorsResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerBusinessUnitId
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBusinessunitsSegmentationgeoregionsIndex(partnerId: string, partnerBusinessUnitId: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersBusinessunitsSegmentationgeoregionsIndex(partnerId, partnerBusinessUnitId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerBusinessUnitId
     * @param {PartnerBusinessUnitSegmentationGeoRegionsResource} partnerBusinessUnitSegmentationGeoRegionsResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBusinessunitsSegmentationgeoregionsUpdate(partnerId: string, partnerBusinessUnitId: string, partnerBusinessUnitSegmentationGeoRegionsResource: PartnerBusinessUnitSegmentationGeoRegionsResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersBusinessunitsSegmentationgeoregionsUpdate(partnerId, partnerBusinessUnitId, partnerBusinessUnitSegmentationGeoRegionsResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerBusinessUnitId
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBusinessunitsTypesIndex(partnerId: string, partnerBusinessUnitId: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersBusinessunitsTypesIndex(partnerId, partnerBusinessUnitId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerBusinessUnitId
     * @param {PartnerBusinessUnitTypesResource} partnerBusinessUnitTypesResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBusinessunitsTypesUpdate(partnerId: string, partnerBusinessUnitId: string, partnerBusinessUnitTypesResource: PartnerBusinessUnitTypesResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersBusinessunitsTypesUpdate(partnerId, partnerBusinessUnitId, partnerBusinessUnitTypesResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerBusinessUnitId
     * @param {PartnerBusinessUnitResource} partnerBusinessUnitResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersBusinessunitsUpdate(partnerId: string, partnerBusinessUnitId: string, partnerBusinessUnitResource: PartnerBusinessUnitResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersBusinessunitsUpdate(partnerId, partnerBusinessUnitId, partnerBusinessUnitResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersCashierpaymentmethodsGet(partnerId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersCashierpaymentmethodsGet(partnerId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {boolean} [isActive] Only include active subscriptions.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersCashiersubscriptionsIndex(partnerId: string, isActive?: boolean, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersCashiersubscriptionsIndex(partnerId, isActive, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [id] Category id @var int
     * @param {string} [identifier] Category identifier @var string
     * @param {string} [title] Category title. @var string
     * @param {string} [geoCode] Category geo_code. @var string
     * @param {number} [limit] Limit of categories per page. @var int
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersCategoriesIndex(id?: string, identifier?: string, title?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersCategoriesIndex(id, identifier, title, geoCode, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {CertificationResource} certificationResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersCertificationsCreate(partnerId: string, certificationResource: CertificationResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersCertificationsCreate(partnerId, certificationResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} certificationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersCertificationsDelete(partnerId: string, certificationId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersCertificationsDelete(partnerId, certificationId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} certificationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersCertificationsGet(partnerId: string, certificationId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersCertificationsGet(partnerId, certificationId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} [types] Partner type enum. @var array
     * @param {string} [geoCode]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersCertificationsIndex(partnerId: string, types?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersCertificationsIndex(partnerId, types, geoCode, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerCertificationsResource} partnerCertificationsResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersCertificationsUpdate(partnerId: string, partnerCertificationsResource: PartnerCertificationsResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersCertificationsUpdate(partnerId, partnerCertificationsResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} contentKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersContentsDelete(partnerId: string, contentKey: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersContentsDelete(partnerId, contentKey, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} contentKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersContentsGet(partnerId: string, contentKey: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersContentsGet(partnerId, contentKey, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} contentKey
     * @param {PartnerContentResource} partnerContentResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersContentsUpdate(partnerId: string, contentKey: string, partnerContentResource: PartnerContentResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersContentsUpdate(partnerId, contentKey, partnerContentResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerContractOfferGroupResource} partnerContractOfferGroupResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersContractoffergroupsCreate(partnerId: string, partnerContractOfferGroupResource: PartnerContractOfferGroupResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersContractoffergroupsCreate(partnerId, partnerContractOfferGroupResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {boolean} [isArchived]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersContractoffergroupsIndex(partnerId: string, isArchived?: boolean, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersContractoffergroupsIndex(partnerId, isArchived, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} contractId
     * @param {PartnerContractActionResource} partnerContractActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersContractsActionsCreate(partnerId: string, contractId: string, partnerContractActionResource: PartnerContractActionResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersContractsActionsCreate(partnerId, contractId, partnerContractActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerContractId
     * @param {string} boostId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersContractsAllocationboostsDelete(partnerId: string, partnerContractId: string, boostId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersContractsAllocationboostsDelete(partnerId, partnerContractId, boostId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerContractId
     * @param {string} boostId
     * @param {DateSpanResource} dateSpanResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersContractsAllocationboostsUpdate(partnerId: string, partnerContractId: string, boostId: string, dateSpanResource: DateSpanResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersContractsAllocationboostsUpdate(partnerId, partnerContractId, boostId, dateSpanResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerContractId
     * @param {string} pauseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersContractsAllocationpausesDelete(partnerId: string, partnerContractId: string, pauseId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersContractsAllocationpausesDelete(partnerId, partnerContractId, pauseId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerContractId
     * @param {string} pauseId
     * @param {DateSpanResource} dateSpanResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersContractsAllocationpausesUpdate(partnerId: string, partnerContractId: string, pauseId: string, dateSpanResource: DateSpanResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersContractsAllocationpausesUpdate(partnerId, partnerContractId, pauseId, dateSpanResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerContractResource} partnerContractResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersContractsCreate(partnerId: string, partnerContractResource: PartnerContractResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersContractsCreate(partnerId, partnerContractResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerContractId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersContractsDelete(partnerId: string, partnerContractId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersContractsDelete(partnerId, partnerContractId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} contractId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersContractsGet(partnerId: string, contractId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersContractsGet(partnerId, contractId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {boolean} [isAccepted]
     * @param {boolean} [isArchived]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersContractsIndex(partnerId: string, isAccepted?: boolean, isArchived?: boolean, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersContractsIndex(partnerId, isAccepted, isArchived, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} contractId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersContractsPdfGet(partnerId: string, contractId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersContractsPdfGet(partnerId, contractId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} contractId
     * @param {PartnerContractSignedPdfFileResource} partnerContractSignedPdfFileResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersContractsSignedpdfCreate(partnerId: string, contractId: string, partnerContractSignedPdfFileResource: PartnerContractSignedPdfFileResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersContractsSignedpdfCreate(partnerId, contractId, partnerContractSignedPdfFileResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} contractId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersContractsSignedpdfGet(partnerId: string, contractId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersContractsSignedpdfGet(partnerId, contractId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} contractId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersContractsSigningurlGet(partnerId: string, contractId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersContractsSigningurlGet(partnerId, contractId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerContractId
     * @param {PartnerContractResource} partnerContractResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersContractsUpdate(partnerId: string, partnerContractId: string, partnerContractResource: PartnerContractResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersContractsUpdate(partnerId, partnerContractId, partnerContractResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} couponId
     * @param {PartnerCouponActionResource} partnerCouponActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersCouponsActionsCreate(partnerId: string, couponId: string, partnerCouponActionResource: PartnerCouponActionResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersCouponsActionsCreate(partnerId, couponId, partnerCouponActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerCouponResource} partnerCouponResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersCouponsCreate(partnerId: string, partnerCouponResource: PartnerCouponResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersCouponsCreate(partnerId, partnerCouponResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {boolean} [showExpired] Only show expired partner coupons.
     * @param {boolean} [showDeactivated] Only show deactivated partner coupons.
     * @param {boolean} [showEmpty] Should empty partner coupons be included in the result.
     * @param {'created_at' | 'expires_at' | 'deactivated_at' | 'punches_left' | 'ascending' | 'descending'} [sort] Sort the coupons by different values
     * @param {string} [type] Filter the coupons type
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersCouponsIndex(partnerId: string, showExpired?: boolean, showDeactivated?: boolean, showEmpty?: boolean, sort?: 'created_at' | 'expires_at' | 'deactivated_at' | 'punches_left' | 'ascending' | 'descending', type?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersCouponsIndex(partnerId, showExpired, showDeactivated, showEmpty, sort, type, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     * Create a Partner
     * @summary Create a Partner
     * @param {PartnerResource} partnerResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersCreate(partnerResource: PartnerResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersCreate(partnerResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersDelete(partnerId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersDelete(partnerId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {PartnerDeliverableResource} partnerDeliverableResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersDeliverablesCreate(partnerDeliverableResource: PartnerDeliverableResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersDeliverablesCreate(partnerDeliverableResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerDeliverableResource} partnerDeliverableResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersDeliverablesCreate_2(partnerId: string, partnerDeliverableResource: PartnerDeliverableResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersDeliverablesCreate_2(partnerId, partnerDeliverableResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerDeliverableId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersDeliverablesGet(partnerDeliverableId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersDeliverablesGet(partnerDeliverableId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerDeliverableId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersDeliverablesGet_3(partnerId: string, partnerDeliverableId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersDeliverablesGet_3(partnerId, partnerDeliverableId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [clientId] Client id for the clients to filter by
     * @param {string} [leadId] Lead id for the lead to filter by
     * @param {string} [partnerId] Partner id of the partners to filter by.
     * @param {string} [partnerDeliverableId] Partner deliverable id of the partners to filter by.
     * @param {string} [status] States for the partners.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersDeliverablesIndex(clientId?: string, leadId?: string, partnerId?: string, partnerDeliverableId?: string, status?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersDeliverablesIndex(clientId, leadId, partnerId, partnerDeliverableId, status, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} [clientId] Client id for the clients to filter by
     * @param {string} [leadId] Lead id for the lead to filter by
     * @param {string} [partnerDeliverableId] Partner deliverable id of the partners to filter by.
     * @param {string} [status] States for the partners.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersDeliverablesIndex_4(partnerId: string, clientId?: string, leadId?: string, partnerDeliverableId?: string, status?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersDeliverablesIndex_4(partnerId, clientId, leadId, partnerDeliverableId, status, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerDeliverableId
     * @param {PartnerDeliverableLineResource} partnerDeliverableLineResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersDeliverablesLinesCreate(partnerDeliverableId: string, partnerDeliverableLineResource: PartnerDeliverableLineResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersDeliverablesLinesCreate(partnerDeliverableId, partnerDeliverableLineResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerDeliverableId
     * @param {PartnerDeliverableLineResource} partnerDeliverableLineResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersDeliverablesLinesCreate_5(partnerId: string, partnerDeliverableId: string, partnerDeliverableLineResource: PartnerDeliverableLineResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersDeliverablesLinesCreate_5(partnerId, partnerDeliverableId, partnerDeliverableLineResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerDeliverableId
     * @param {string} partnerDeliverableLineId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersDeliverablesLinesDelete(partnerDeliverableId: string, partnerDeliverableLineId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersDeliverablesLinesDelete(partnerDeliverableId, partnerDeliverableLineId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerDeliverableId
     * @param {string} partnerDeliverableLineId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersDeliverablesLinesDelete_6(partnerId: string, partnerDeliverableId: string, partnerDeliverableLineId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersDeliverablesLinesDelete_6(partnerId, partnerDeliverableId, partnerDeliverableLineId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerDeliverableId
     * @param {string} partnerDeliverableLineId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersDeliverablesLinesGet(partnerDeliverableId: string, partnerDeliverableLineId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersDeliverablesLinesGet(partnerDeliverableId, partnerDeliverableLineId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerDeliverableId
     * @param {string} partnerDeliverableLineId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersDeliverablesLinesGet_7(partnerId: string, partnerDeliverableId: string, partnerDeliverableLineId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersDeliverablesLinesGet_7(partnerId, partnerDeliverableId, partnerDeliverableLineId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerDeliverableId
     * @param {string} [clientId] Client id for the clients to filter by
     * @param {string} [leadId] Lead id for the lead to filter by
     * @param {string} [partnerId] Partner id of the partners to filter by.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersDeliverablesLinesIndex(partnerDeliverableId: string, clientId?: string, leadId?: string, partnerId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersDeliverablesLinesIndex(partnerDeliverableId, clientId, leadId, partnerId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerDeliverableId
     * @param {string} [clientId] Client id for the clients to filter by
     * @param {string} [leadId] Lead id for the lead to filter by
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersDeliverablesLinesIndex_8(partnerId: string, partnerDeliverableId: string, clientId?: string, leadId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersDeliverablesLinesIndex_8(partnerId, partnerDeliverableId, clientId, leadId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerDeliverableId
     * @param {string} partnerDeliverableLineId
     * @param {PartnerDeliverableLineResource} partnerDeliverableLineResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersDeliverablesLinesUpdate(partnerDeliverableId: string, partnerDeliverableLineId: string, partnerDeliverableLineResource: PartnerDeliverableLineResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersDeliverablesLinesUpdate(partnerDeliverableId, partnerDeliverableLineId, partnerDeliverableLineResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerDeliverableId
     * @param {string} partnerDeliverableLineId
     * @param {PartnerDeliverableLineResource} partnerDeliverableLineResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersDeliverablesLinesUpdate_9(partnerId: string, partnerDeliverableId: string, partnerDeliverableLineId: string, partnerDeliverableLineResource: PartnerDeliverableLineResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersDeliverablesLinesUpdate_9(partnerId, partnerDeliverableId, partnerDeliverableLineId, partnerDeliverableLineResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerDeliverableId
     * @param {PartnerDeliverableResource} partnerDeliverableResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersDeliverablesUpdate(partnerDeliverableId: string, partnerDeliverableResource: PartnerDeliverableResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersDeliverablesUpdate(partnerDeliverableId, partnerDeliverableResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerDeliverableId
     * @param {PartnerDeliverableResource} partnerDeliverableResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersDeliverablesUpdate_10(partnerId: string, partnerDeliverableId: string, partnerDeliverableResource: PartnerDeliverableResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersDeliverablesUpdate_10(partnerId, partnerDeliverableId, partnerDeliverableResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerDiscountResource} partnerDiscountResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersDiscountsCreate(partnerId: string, partnerDiscountResource: PartnerDiscountResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersDiscountsCreate(partnerId, partnerDiscountResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerPackageTypeDiscountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersDiscountsDelete(partnerId: string, partnerPackageTypeDiscountId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersDiscountsDelete(partnerId, partnerPackageTypeDiscountId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} [isActive] Only include active discounts.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersDiscountsIndex(partnerId: string, isActive?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersDiscountsIndex(partnerId, isActive, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerEmployeeNoteResource} partnerEmployeeNoteResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersEmployeenotesCreate(partnerId: string, partnerEmployeeNoteResource: PartnerEmployeeNoteResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersEmployeenotesCreate(partnerId, partnerEmployeeNoteResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} employeeNoteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersEmployeenotesDelete(partnerId: string, employeeNoteId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersEmployeenotesDelete(partnerId, employeeNoteId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {number} [leadId]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersEmployeenotesIndex(partnerId: string, leadId?: number, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersEmployeenotesIndex(partnerId, leadId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerFeatureResource} partnerFeatureResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersFeaturesCreate(partnerId: string, partnerFeatureResource: PartnerFeatureResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersFeaturesCreate(partnerId, partnerFeatureResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} featureIdentifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersFeaturesDelete(partnerId: string, featureIdentifier: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersFeaturesDelete(partnerId, featureIdentifier, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerFeeResource} partnerFeeResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersFeesCreate(partnerId: string, partnerFeeResource: PartnerFeeResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersFeesCreate(partnerId, partnerFeeResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersFeesDelete(partnerId: string, id: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersFeesDelete(partnerId, id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} [id]
     * @param {number} [partnerId] Partner ID. @var int
     * @param {string} [effectiveAt] effective from date.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersFeesIndex(id?: number, partnerId?: number, effectiveAt?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersFeesIndex(id, partnerId, effectiveAt, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     * Get a single partner by ID
     * @summary Get a single partner by ID
     * @param {string} partnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersGet(partnerId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersGet(partnerId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerImpressumResource} partnerImpressumResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersImpressumsCreate(partnerId: string, partnerImpressumResource: PartnerImpressumResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersImpressumsCreate(partnerId, partnerImpressumResource, options)(this.fetch, this.basePath);
    }

    /**
     * List partners
     * @summary List partners
     * @param {string} [partnerId] Filter partners by partner ID
     * @param {string} [state] States for the partners.
     * @param {boolean} [isEnabled]
     * @param {string} [employeeId] Employee id of the partners to filter by.
     * @param {string} [geoCode] Geographic Location Code.
     * @param {string} [satisfaction] Partner Satisfaction Ratio.
     * @param {string} [punchesUseSpeed] Punches use speed.
     * @param {string} [refillOfferId] Filter the partners by their refill offers
     * @param {string} [industryId] Filter the partners by their industry ids
     * @param {string} [industry] Filter the partners by their industry identifiers
     * @param {string} [geoPoint] Location Point to sort partners by
     * @param {string} [geoRect] Location Points to define an area to filter partners by. Provide the bottom left (southwest) and top right (northeast) corners of the reactangle separated by commas, e.g. \&quot;52.19,12.42,52.85,14.22\&quot;.
     * @param {number} [geoDistance] Location filter by distance
     * @param {'id' | 'relevance' | 'created_at' | 'company_name' | 'state' | 'satisfaction' | 'employee_name' | 'punches_monthly' | 'punches_use_speed' | 'last_login' | 'last_lead_bought' | 'revenue_6months' | 'last_invoice' | 'avg_punch_price_6months' | 'avg_due_days_6months' | 'last_coupon_size' | 'punches_left' | 'punches_spent_monthly' | 'punches_spent_6months' | 'punches_bought_6months' | 'golden_ratio_6months' | 'refill' | 'rating' | 'distance' | 'last_phone_call' | 'canvas_status' | 'address' | 'tasks' | 'last_subscription_start_date' | 'last_published_quote' | 'published_quotes' | 'win_ratio' | 'unmatched' | 'unmatched_percent' | 'digital_leads' | 'has_active_contract' | 'deallocations' | 'overdue_amount' | 'active_contracts_count' | 'active_allocation_pauses_count' | 'active_contracts_amount' | 'next_contract_renewal_at' | 'active_business_units_amount' | 'active_business_units_mrr' | 'current_period_weighted_delivery_trend' | 'success_ratio' | 'publishing_ratio' | 'all_time_purchased_marketplace_leads_value_excl_vat_local_currency' | 'all_time_purchased_marketplace_leads_count' | 'lead_marketplace_partner_spend_excl_vat_local_currency' | 'ascending' | 'descending'} [sort] Sort Partner&#39;s by a given property.
     * @param {string} [canvasStatus] Canvas Status
     * @param {boolean} [isPublic] Only show partners that are visible to the public
     * @param {string} [packageType] Partner subscription package type
     * @param {string} [badgeId]
     * @param {boolean} [digitalLeads]
     * @param {boolean} [hasMarketingPackage]
     * @param {boolean} [hasActiveContract]
     * @param {boolean} [hasUnusedAllocations]
     * @param {boolean} [wantsExclusiveLeads]
     * @param {string} [partnerAssigneeClientConsultantEmployeeId]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersIndex(partnerId?: string, state?: string, isEnabled?: boolean, employeeId?: string, geoCode?: string, satisfaction?: string, punchesUseSpeed?: string, refillOfferId?: string, industryId?: string, industry?: string, geoPoint?: string, geoRect?: string, geoDistance?: number, sort?: 'id' | 'relevance' | 'created_at' | 'company_name' | 'state' | 'satisfaction' | 'employee_name' | 'punches_monthly' | 'punches_use_speed' | 'last_login' | 'last_lead_bought' | 'revenue_6months' | 'last_invoice' | 'avg_punch_price_6months' | 'avg_due_days_6months' | 'last_coupon_size' | 'punches_left' | 'punches_spent_monthly' | 'punches_spent_6months' | 'punches_bought_6months' | 'golden_ratio_6months' | 'refill' | 'rating' | 'distance' | 'last_phone_call' | 'canvas_status' | 'address' | 'tasks' | 'last_subscription_start_date' | 'last_published_quote' | 'published_quotes' | 'win_ratio' | 'unmatched' | 'unmatched_percent' | 'digital_leads' | 'has_active_contract' | 'deallocations' | 'overdue_amount' | 'active_contracts_count' | 'active_allocation_pauses_count' | 'active_contracts_amount' | 'next_contract_renewal_at' | 'active_business_units_amount' | 'active_business_units_mrr' | 'current_period_weighted_delivery_trend' | 'success_ratio' | 'publishing_ratio' | 'all_time_purchased_marketplace_leads_value_excl_vat_local_currency' | 'all_time_purchased_marketplace_leads_count' | 'lead_marketplace_partner_spend_excl_vat_local_currency' | 'ascending' | 'descending', canvasStatus?: string, isPublic?: boolean, packageType?: string, badgeId?: string, digitalLeads?: boolean, hasMarketingPackage?: boolean, hasActiveContract?: boolean, hasUnusedAllocations?: boolean, wantsExclusiveLeads?: boolean, partnerAssigneeClientConsultantEmployeeId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersIndex(partnerId, state, isEnabled, employeeId, geoCode, satisfaction, punchesUseSpeed, refillOfferId, industryId, industry, geoPoint, geoRect, geoDistance, sort, canvasStatus, isPublic, packageType, badgeId, digitalLeads, hasMarketingPackage, hasActiveContract, hasUnusedAllocations, wantsExclusiveLeads, partnerAssigneeClientConsultantEmployeeId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerIndustryResource} partnerIndustryResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersIndustriesCreate(partnerId: string, partnerIndustryResource: PartnerIndustryResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersIndustriesCreate(partnerId, partnerIndustryResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} industryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersIndustriesDelete(partnerId: string, industryId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersIndustriesDelete(partnerId, industryId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} industryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersIndustriesGet(partnerId: string, industryId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersIndustriesGet(partnerId, industryId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} [geoCode]
     * @param {boolean} [isActive]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersIndustriesIndex(partnerId: string, geoCode?: string, isActive?: boolean, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersIndustriesIndex(partnerId, geoCode, isActive, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerIndustriesResource} partnerIndustriesResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersIndustriesUpdate(partnerId: string, partnerIndustriesResource: PartnerIndustriesResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersIndustriesUpdate(partnerId, partnerIndustriesResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [processAtGte] Get invoicings processed at after a date.
     * @param {string} [processAtLte] Get invoicings processed at after a date.
     * @param {string} [partnerId] Filter by zero or more partner IDs.
     * @param {'lead_budget' | 'pay_per_spot'} [businessModel] Lead budget or pay-per-spot.
     * @param {'process_at' | 'ascending' | 'descending'} [sort] Sort the invoicings.
     * @param {string} [geoCode] Filter by geo codes.
     * @param {string} [employeeId] Filter by partner&#39;s employee.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersInvoicingsIndex(processAtGte?: string, processAtLte?: string, partnerId?: string, businessModel?: 'lead_budget' | 'pay_per_spot', sort?: 'process_at' | 'ascending' | 'descending', geoCode?: string, employeeId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersInvoicingsIndex(processAtGte, processAtLte, partnerId, businessModel, sort, geoCode, employeeId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} [processAtGte] Get invoicings processed at after a date.
     * @param {string} [processAtLte] Get invoicings processed at after a date.
     * @param {'lead_budget' | 'pay_per_spot'} [businessModel] Lead budget or pay-per-spot.
     * @param {'process_at' | 'ascending' | 'descending'} [sort] Sort the invoicings.
     * @param {string} [geoCode] Filter by geo codes.
     * @param {string} [employeeId] Filter by partner&#39;s employee.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersInvoicingsIndex_11(partnerId: string, processAtGte?: string, processAtLte?: string, businessModel?: 'lead_budget' | 'pay_per_spot', sort?: 'process_at' | 'ascending' | 'descending', geoCode?: string, employeeId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersInvoicingsIndex_11(partnerId, processAtGte, processAtLte, businessModel, sort, geoCode, employeeId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {LeadTypeResource} leadTypeResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersLeadtypesCreate(partnerId: string, leadTypeResource: LeadTypeResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersLeadtypesCreate(partnerId, leadTypeResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} leadTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersLeadtypesDelete(partnerId: string, leadTypeId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersLeadtypesDelete(partnerId, leadTypeId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} leadTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersLeadtypesGet(leadTypeId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersLeadtypesGet(leadTypeId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} leadTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersLeadtypesGet_12(partnerId: string, leadTypeId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersLeadtypesGet_12(partnerId, leadTypeId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} [partnerId] Partner ID. @var int
     * @param {string} [types] Partner type enum. @var array
     * @param {string} [geoCode]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersLeadtypesIndex(partnerId?: number, types?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersLeadtypesIndex(partnerId, types, geoCode, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} [types] Partner type enum. @var array
     * @param {string} [geoCode]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersLeadtypesIndex_13(partnerId: string, types?: string, geoCode?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersLeadtypesIndex_13(partnerId, types, geoCode, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerLeadTypesResource} partnerLeadTypesResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersLeadtypesUpdate(partnerId: string, partnerLeadTypesResource: PartnerLeadTypesResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersLeadtypesUpdate(partnerId, partnerLeadTypesResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {PartnerNoteResource} partnerNoteResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersNotesCreate(partnerNoteResource: PartnerNoteResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersNotesCreate(partnerNoteResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerNoteResource} partnerNoteResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersNotesCreate_14(partnerId: string, partnerNoteResource: PartnerNoteResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersNotesCreate_14(partnerId, partnerNoteResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerNoteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersNotesGet(partnerId: string, partnerNoteId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersNotesGet(partnerId, partnerNoteId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} [partnerId]
     * @param {'all' | 'satisfactions' | 'notes'} [types]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersNotesIndex(partnerId?: number, types?: 'all' | 'satisfactions' | 'notes', limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersNotesIndex(partnerId, types, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {'all' | 'satisfactions' | 'notes'} [types]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersNotesIndex_15(partnerId: string, types?: 'all' | 'satisfactions' | 'notes', limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersNotesIndex_15(partnerId, types, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerQuoteProductCommentResource} partnerQuoteProductCommentResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersQuoteproductcommentsCreate(partnerId: string, partnerQuoteProductCommentResource: PartnerQuoteProductCommentResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersQuoteproductcommentsCreate(partnerId, partnerQuoteProductCommentResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} quoteProductCommentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersQuoteproductcommentsDelete(partnerId: string, quoteProductCommentId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersQuoteproductcommentsDelete(partnerId, quoteProductCommentId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersQuoteproductcommentsIndex(partnerId: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersQuoteproductcommentsIndex(partnerId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerQuoteProductPriceResource} partnerQuoteProductPriceResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersQuoteproductpricesCreate(partnerId: string, partnerQuoteProductPriceResource: PartnerQuoteProductPriceResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersQuoteproductpricesCreate(partnerId, partnerQuoteProductPriceResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} quoteProductPriceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersQuoteproductpricesDelete(partnerId: string, quoteProductPriceId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersQuoteproductpricesDelete(partnerId, quoteProductPriceId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersQuoteproductpricesIndex(partnerId: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersQuoteproductpricesIndex(partnerId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [partnerId] IDs of partners
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersQuotevendorsIndex(partnerId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersQuotevendorsIndex(partnerId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerRevenueRangeResource} partnerRevenueRangeResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersRevenuerangesCreate(partnerId: string, partnerRevenueRangeResource: PartnerRevenueRangeResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersRevenuerangesCreate(partnerId, partnerRevenueRangeResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerRevenueSegmentActionResource} partnerRevenueSegmentActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersRevenuesegmentsActionsCreate(partnerId: string, partnerRevenueSegmentActionResource: PartnerRevenueSegmentActionResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersRevenuesegmentsActionsCreate(partnerId, partnerRevenueSegmentActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {PartnerRevenueSegmentResource} partnerRevenueSegmentResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersRevenuesegmentsCreate(partnerRevenueSegmentResource: PartnerRevenueSegmentResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersRevenuesegmentsCreate(partnerRevenueSegmentResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerRevenueSegmentResource} partnerRevenueSegmentResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersRevenuesegmentsCreate_16(partnerId: string, partnerRevenueSegmentResource: PartnerRevenueSegmentResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersRevenuesegmentsCreate_16(partnerId, partnerRevenueSegmentResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerRevenueSegmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersRevenuesegmentsDelete(partnerId: string, partnerRevenueSegmentId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersRevenuesegmentsDelete(partnerId, partnerRevenueSegmentId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersRevenuesegmentsIndex(partnerId: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersRevenuesegmentsIndex(partnerId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersSectorsIndex(partnerId: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersSectorsIndex(partnerId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerSectorsResource} partnerSectorsResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersSectorsUpdate(partnerId: string, partnerSectorsResource: PartnerSectorsResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersSectorsUpdate(partnerId, partnerSectorsResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerUniqueSellingPointResource} partnerUniqueSellingPointResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersSellingpointsCreate(partnerId: string, partnerUniqueSellingPointResource: PartnerUniqueSellingPointResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersSellingpointsCreate(partnerId, partnerUniqueSellingPointResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} sellingPointId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersSellingpointsDelete(partnerId: string, sellingPointId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersSellingpointsDelete(partnerId, sellingPointId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {number} [id]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersSellingpointsIndex(partnerId: string, id?: number, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersSellingpointsIndex(partnerId, id, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} sellingPointId
     * @param {PartnerUniqueSellingPointResource} partnerUniqueSellingPointResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersSellingpointsUpdate(partnerId: string, sellingPointId: string, partnerUniqueSellingPointResource: PartnerUniqueSellingPointResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersSellingpointsUpdate(partnerId, sellingPointId, partnerUniqueSellingPointResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} agreementId
     * @param {PartnerSubscriptionAgreementActionResource} partnerSubscriptionAgreementActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersSubscriptionagreementsActionsCreate(partnerId: string, agreementId: string, partnerSubscriptionAgreementActionResource: PartnerSubscriptionAgreementActionResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersSubscriptionagreementsActionsCreate(partnerId, agreementId, partnerSubscriptionAgreementActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerSubscriptionAgreementResource} partnerSubscriptionAgreementResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersSubscriptionagreementsCreate(partnerId: string, partnerSubscriptionAgreementResource: PartnerSubscriptionAgreementResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersSubscriptionagreementsCreate(partnerId, partnerSubscriptionAgreementResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {boolean} [isAvailableForAgreement] List agreemenets that are available.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersSubscriptionagreementsIndex(partnerId: string, isAvailableForAgreement?: boolean, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersSubscriptionagreementsIndex(partnerId, isAvailableForAgreement, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} agreementId
     * @param {PartnerSubscriptionAgreementResource} partnerSubscriptionAgreementResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersSubscriptionagreementsUpdate(partnerId: string, agreementId: string, partnerSubscriptionAgreementResource: PartnerSubscriptionAgreementResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersSubscriptionagreementsUpdate(partnerId, agreementId, partnerSubscriptionAgreementResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} subscriptionId
     * @param {PartnerSubscriptionActionResource} partnerSubscriptionActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersSubscriptionsActionsCreate(partnerId: string, subscriptionId: string, partnerSubscriptionActionResource: PartnerSubscriptionActionResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersSubscriptionsActionsCreate(partnerId, subscriptionId, partnerSubscriptionActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerSubscriptionResource} partnerSubscriptionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersSubscriptionsCreate(partnerId: string, partnerSubscriptionResource: PartnerSubscriptionResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersSubscriptionsCreate(partnerId, partnerSubscriptionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} [isActive] Only include active subscriptions.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersSubscriptionsIndex(partnerId: string, isActive?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersSubscriptionsIndex(partnerId, isActive, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} subscriptionId
     * @param {PartnerSubscriptionPauseResource} partnerSubscriptionPauseResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersSubscriptionsPausesCreate(partnerId: string, subscriptionId: string, partnerSubscriptionPauseResource: PartnerSubscriptionPauseResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersSubscriptionsPausesCreate(partnerId, subscriptionId, partnerSubscriptionPauseResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} subscriptionId
     * @param {string} pauseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersSubscriptionsPausesDelete(partnerId: string, subscriptionId: string, pauseId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersSubscriptionsPausesDelete(partnerId, subscriptionId, pauseId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} subscriptionId
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersSubscriptionsPausesIndex(partnerId: string, subscriptionId: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersSubscriptionsPausesIndex(partnerId, subscriptionId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} subscriptionId
     * @param {PartnerSubscriptionPriceResource} partnerSubscriptionPriceResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersSubscriptionsPricesCreate(partnerId: string, subscriptionId: string, partnerSubscriptionPriceResource: PartnerSubscriptionPriceResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersSubscriptionsPricesCreate(partnerId, subscriptionId, partnerSubscriptionPriceResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} subscriptionId
     * @param {string} subscriptionPriceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersSubscriptionsPricesDelete(partnerId: string, subscriptionId: string, subscriptionPriceId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersSubscriptionsPricesDelete(partnerId, subscriptionId, subscriptionPriceId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} subscriptionId
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersSubscriptionsPricesIndex(partnerId: string, subscriptionId: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersSubscriptionsPricesIndex(partnerId, subscriptionId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} subscriptionId
     * @param {PartnerSubscriptionResource} partnerSubscriptionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersSubscriptionsUpdate(partnerId: string, subscriptionId: string, partnerSubscriptionResource: PartnerSubscriptionResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersSubscriptionsUpdate(partnerId, subscriptionId, partnerSubscriptionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [geoCode] Filter by geo code.
     * @param {boolean} [isHidden] Filter hidden types.
     * @param {'' | 'lead_marketplace' | 'ascending' | 'descending'} [identifier] Filter by identifier.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersSubscriptiontypesIndex(geoCode?: string, isHidden?: boolean, identifier?: '' | 'lead_marketplace' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersSubscriptiontypesIndex(geoCode, isHidden, identifier, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} [geoCode] Filter by geo code.
     * @param {boolean} [isHidden] Filter hidden types.
     * @param {'' | 'lead_marketplace' | 'ascending' | 'descending'} [identifier] Filter by identifier.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersSubscriptiontypesIndex_17(partnerId: string, geoCode?: string, isHidden?: boolean, identifier?: '' | 'lead_marketplace' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersSubscriptiontypesIndex_17(partnerId, geoCode, isHidden, identifier, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} [limit] Limit the number of suggest resources pr. page.
     * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
     * @param {string} [query] The search query to search by.
     * @param {string} [type] Type to search for.
     * @param {string} [partnerState] Partner state.
     * @param {number} [page] The page position in the result.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersSuggestIndex(limit?: number, geoCode?: string, query?: string, type?: string, partnerState?: string, page?: number, options?: any) {
        return PartnersApiFp(this.configuration).partnersSuggestIndex(limit, geoCode, query, type, partnerState, page, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} [id]
     * @param {string} [identifier]
     * @param {number} [industryId]
     * @param {string} [industryIdentifier]
     * @param {string} [geoCode]
     * @param {string} [sort]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersTitlesIndex(id?: number, identifier?: string, industryId?: number, industryIdentifier?: string, geoCode?: string, sort?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersTitlesIndex(id, identifier, industryId, industryIdentifier, geoCode, sort, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersTransactionsIndex(partnerId: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersTransactionsIndex(partnerId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerResource} partnerResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersUpdate(partnerId: string, partnerResource: PartnerResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersUpdate(partnerId, partnerResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerUserId
     * @param {PartnerUserActionResource} partnerUserActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersUsersActionsCreate(partnerId: string, partnerUserId: string, partnerUserActionResource: PartnerUserActionResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersUsersActionsCreate(partnerId, partnerUserId, partnerUserActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {string} contentKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersUsersContentsDelete(partnerUserId: string, contentKey: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersUsersContentsDelete(partnerUserId, contentKey, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerUserId
     * @param {string} contentKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersUsersContentsDelete_18(partnerId: string, partnerUserId: string, contentKey: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersUsersContentsDelete_18(partnerId, partnerUserId, contentKey, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {string} contentKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersUsersContentsGet(partnerUserId: string, contentKey: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersUsersContentsGet(partnerUserId, contentKey, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerUserId
     * @param {string} contentKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersUsersContentsGet_19(partnerId: string, partnerUserId: string, contentKey: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersUsersContentsGet_19(partnerId, partnerUserId, contentKey, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {string} contentKey
     * @param {PartnerContentResource} partnerContentResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersUsersContentsUpdate(partnerUserId: string, contentKey: string, partnerContentResource: PartnerContentResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersUsersContentsUpdate(partnerUserId, contentKey, partnerContentResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerUserId
     * @param {string} contentKey
     * @param {PartnerContentResource} partnerContentResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersUsersContentsUpdate_20(partnerId: string, partnerUserId: string, contentKey: string, partnerContentResource: PartnerContentResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersUsersContentsUpdate_20(partnerId, partnerUserId, contentKey, partnerContentResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {PartnerUserResource} partnerUserResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersUsersCreate(partnerUserResource: PartnerUserResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersUsersCreate(partnerUserResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerUserResource} partnerUserResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersUsersCreate_21(partnerId: string, partnerUserResource: PartnerUserResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersUsersCreate_21(partnerId, partnerUserResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerUserId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersUsersDelete(partnerId: string, partnerUserId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersUsersDelete(partnerId, partnerUserId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersUsersGet(partnerUserId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersUsersGet(partnerUserId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerUserId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersUsersGet_22(partnerId: string, partnerUserId: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersUsersGet_22(partnerId, partnerUserId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [partnerUserId] Partner User Id.
     * @param {string} [partnerId] Partner id for the partner users to filter by.
     * @param {string} [sort]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersUsersIndex(partnerUserId?: string, partnerId?: string, sort?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersUsersIndex(partnerUserId, partnerId, sort, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} [partnerUserId] Partner User Id.
     * @param {string} [sort]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersUsersIndex_23(partnerId: string, partnerUserId?: string, sort?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersUsersIndex_23(partnerId, partnerUserId, sort, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerUserId
     * @param {PartnerUserResource} partnerUserResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersUsersUpdate(partnerUserId: string, partnerUserResource: PartnerUserResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersUsersUpdate(partnerUserId, partnerUserResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {string} partnerUserId
     * @param {PartnerUserResource} partnerUserResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersUsersUpdate_24(partnerId: string, partnerUserId: string, partnerUserResource: PartnerUserResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersUsersUpdate_24(partnerId, partnerUserId, partnerUserResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [partnerId]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersVerificationsIndex(partnerId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersVerificationsIndex(partnerId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersVerificationsIndex_25(partnerId: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersApiFp(this.configuration).partnersVerificationsIndex_25(partnerId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} partnerId
     * @param {PartnerVerificationResource} partnerVerificationResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public partnersVerificationsUpdate(partnerId: string, partnerVerificationResource: PartnerVerificationResource, options?: any) {
        return PartnersApiFp(this.configuration).partnersVerificationsUpdate(partnerId, partnerVerificationResource, options)(this.fetch, this.basePath);
    }

}

/**
 * PartnersignupsApi - fetch parameter creator
 * @export
 */
export const PartnersignupsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} partnerSignUpId
         * @param {PartnerSignUpActionResource} partnerSignUpActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersignupsActionsCreate(partnerSignUpId: string, partnerSignUpActionResource: PartnerSignUpActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerSignUpId' is not null or undefined
            if (partnerSignUpId === null || partnerSignUpId === undefined) {
                throw new RequiredError('partnerSignUpId','Required parameter partnerSignUpId was null or undefined when calling partnersignupsActionsCreate.');
            }
            // verify required parameter 'partnerSignUpActionResource' is not null or undefined
            if (partnerSignUpActionResource === null || partnerSignUpActionResource === undefined) {
                throw new RequiredError('partnerSignUpActionResource','Required parameter partnerSignUpActionResource was null or undefined when calling partnersignupsActionsCreate.');
            }
            const localVarPath = `/partnersignups/{partner_sign_up_id}/actions`
                .replace(`{${"partner_sign_up_id"}}`, encodeURIComponent(String(partnerSignUpId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerSignUpActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerSignUpActionResource || {}) : (partnerSignUpActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {PartnerSignUpResource} partnerSignUpResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersignupsCreate(partnerSignUpResource: PartnerSignUpResource, options: any = {}): FetchArgs {
            // verify required parameter 'partnerSignUpResource' is not null or undefined
            if (partnerSignUpResource === null || partnerSignUpResource === undefined) {
                throw new RequiredError('partnerSignUpResource','Required parameter partnerSignUpResource was null or undefined when calling partnersignupsCreate.');
            }
            const localVarPath = `/partnersignups`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PartnerSignUpResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(partnerSignUpResource || {}) : (partnerSignUpResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {boolean} [isProcessed] Get signups based on being processed.
         * @param {string} [processedAtGte] Get signups processed at after a date.
         * @param {string} [processedAtLte] Get signups processed at before a date.
         * @param {string} [expiresAtGte] Get signups expired at after a date.
         * @param {string} [expiresAtLte] Get signups expired at before a date.
         * @param {string} [geoCode] Filter by geo codes.
         * @param {string} [industryId] Filter by industry.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersignupsIndex(isProcessed?: boolean, processedAtGte?: string, processedAtLte?: string, expiresAtGte?: string, expiresAtLte?: string, geoCode?: string, industryId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/partnersignups`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (isProcessed !== undefined) {
                localVarQueryParameter['is_processed'] = isProcessed;
            }

            if (processedAtGte !== undefined) {
                localVarQueryParameter['processed_at_gte'] = processedAtGte;
            }

            if (processedAtLte !== undefined) {
                localVarQueryParameter['processed_at_lte'] = processedAtLte;
            }

            if (expiresAtGte !== undefined) {
                localVarQueryParameter['expires_at_gte'] = expiresAtGte;
            }

            if (expiresAtLte !== undefined) {
                localVarQueryParameter['expires_at_lte'] = expiresAtLte;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (industryId !== undefined) {
                localVarQueryParameter['industry_id'] = industryId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnersignupsApi - functional programming interface
 * @export
 */
export const PartnersignupsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} partnerSignUpId
         * @param {PartnerSignUpActionResource} partnerSignUpActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersignupsActionsCreate(partnerSignUpId: string, partnerSignUpActionResource: PartnerSignUpActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerSignUpResource> {
            const localVarFetchArgs = PartnersignupsApiFetchParamCreator(configuration).partnersignupsActionsCreate(partnerSignUpId, partnerSignUpActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {PartnerSignUpResource} partnerSignUpResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersignupsCreate(partnerSignUpResource: PartnerSignUpResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerSignUpResource> {
            const localVarFetchArgs = PartnersignupsApiFetchParamCreator(configuration).partnersignupsCreate(partnerSignUpResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {boolean} [isProcessed] Get signups based on being processed.
         * @param {string} [processedAtGte] Get signups processed at after a date.
         * @param {string} [processedAtLte] Get signups processed at before a date.
         * @param {string} [expiresAtGte] Get signups expired at after a date.
         * @param {string} [expiresAtLte] Get signups expired at before a date.
         * @param {string} [geoCode] Filter by geo codes.
         * @param {string} [industryId] Filter by industry.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersignupsIndex(isProcessed?: boolean, processedAtGte?: string, processedAtLte?: string, expiresAtGte?: string, expiresAtLte?: string, geoCode?: string, industryId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerSignUpResult> {
            const localVarFetchArgs = PartnersignupsApiFetchParamCreator(configuration).partnersignupsIndex(isProcessed, processedAtGte, processedAtLte, expiresAtGte, expiresAtLte, geoCode, industryId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PartnersignupsApi - factory interface
 * @export
 */
export const PartnersignupsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} partnerSignUpId
         * @param {PartnerSignUpActionResource} partnerSignUpActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersignupsActionsCreate(partnerSignUpId: string, partnerSignUpActionResource: PartnerSignUpActionResource, options?: any) {
            return PartnersignupsApiFp(configuration).partnersignupsActionsCreate(partnerSignUpId, partnerSignUpActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {PartnerSignUpResource} partnerSignUpResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersignupsCreate(partnerSignUpResource: PartnerSignUpResource, options?: any) {
            return PartnersignupsApiFp(configuration).partnersignupsCreate(partnerSignUpResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {boolean} [isProcessed] Get signups based on being processed.
         * @param {string} [processedAtGte] Get signups processed at after a date.
         * @param {string} [processedAtLte] Get signups processed at before a date.
         * @param {string} [expiresAtGte] Get signups expired at after a date.
         * @param {string} [expiresAtLte] Get signups expired at before a date.
         * @param {string} [geoCode] Filter by geo codes.
         * @param {string} [industryId] Filter by industry.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnersignupsIndex(isProcessed?: boolean, processedAtGte?: string, processedAtLte?: string, expiresAtGte?: string, expiresAtLte?: string, geoCode?: string, industryId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PartnersignupsApiFp(configuration).partnersignupsIndex(isProcessed, processedAtGte, processedAtLte, expiresAtGte, expiresAtLte, geoCode, industryId, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * PartnersignupsApi - object-oriented interface
 * @export
 * @class PartnersignupsApi
 * @extends {BaseAPI}
 */
export class PartnersignupsApi extends BaseAPI {
    /**
     *
     * @param {string} partnerSignUpId
     * @param {PartnerSignUpActionResource} partnerSignUpActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersignupsApi
     */
    public partnersignupsActionsCreate(partnerSignUpId: string, partnerSignUpActionResource: PartnerSignUpActionResource, options?: any) {
        return PartnersignupsApiFp(this.configuration).partnersignupsActionsCreate(partnerSignUpId, partnerSignUpActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {PartnerSignUpResource} partnerSignUpResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersignupsApi
     */
    public partnersignupsCreate(partnerSignUpResource: PartnerSignUpResource, options?: any) {
        return PartnersignupsApiFp(this.configuration).partnersignupsCreate(partnerSignUpResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {boolean} [isProcessed] Get signups based on being processed.
     * @param {string} [processedAtGte] Get signups processed at after a date.
     * @param {string} [processedAtLte] Get signups processed at before a date.
     * @param {string} [expiresAtGte] Get signups expired at after a date.
     * @param {string} [expiresAtLte] Get signups expired at before a date.
     * @param {string} [geoCode] Filter by geo codes.
     * @param {string} [industryId] Filter by industry.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersignupsApi
     */
    public partnersignupsIndex(isProcessed?: boolean, processedAtGte?: string, processedAtLte?: string, expiresAtGte?: string, expiresAtLte?: string, geoCode?: string, industryId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PartnersignupsApiFp(this.configuration).partnersignupsIndex(isProcessed, processedAtGte, processedAtLte, expiresAtGte, expiresAtLte, geoCode, industryId, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * PaymentsApi - fetch parameter creator
 * @export
 */
export const PaymentsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {PaymentCardResource} paymentCardResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCardsCreate(paymentCardResource: PaymentCardResource, options: any = {}): FetchArgs {
            // verify required parameter 'paymentCardResource' is not null or undefined
            if (paymentCardResource === null || paymentCardResource === undefined) {
                throw new RequiredError('paymentCardResource','Required parameter paymentCardResource was null or undefined when calling paymentsCardsCreate.');
            }
            const localVarPath = `/payments/cards`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PaymentCardResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(paymentCardResource || {}) : (paymentCardResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a card for a given id.
         * @summary Delete a card for a given id.
         * @param {string} cardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCardsDelete(cardId: string, options: any = {}): FetchArgs {
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling paymentsCardsDelete.');
            }
            const localVarPath = `/payments/cards/{card_id}`
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a card for a given id.
         * @summary Delete a card for a given id.
         * @param {string} paymentId
         * @param {string} cardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCardsDelete_1(paymentId: string, cardId: string, options: any = {}): FetchArgs {
            // verify required parameter 'paymentId' is not null or undefined
            if (paymentId === null || paymentId === undefined) {
                throw new RequiredError('paymentId','Required parameter paymentId was null or undefined when calling paymentsCardsDelete_1.');
            }
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling paymentsCardsDelete_1.');
            }
            const localVarPath = `/payments/{payment_id}/cards/{card_id}`
                .replace(`{${"payment_id"}}`, encodeURIComponent(String(paymentId)))
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} paymentCardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCardsGet(paymentCardId: string, options: any = {}): FetchArgs {
            // verify required parameter 'paymentCardId' is not null or undefined
            if (paymentCardId === null || paymentCardId === undefined) {
                throw new RequiredError('paymentCardId','Required parameter paymentCardId was null or undefined when calling paymentsCardsGet.');
            }
            const localVarPath = `/payments/cards/{payment_card_id}`
                .replace(`{${"payment_card_id"}}`, encodeURIComponent(String(paymentCardId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} paymentId
         * @param {string} paymentCardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCardsGet_2(paymentId: string, paymentCardId: string, options: any = {}): FetchArgs {
            // verify required parameter 'paymentId' is not null or undefined
            if (paymentId === null || paymentId === undefined) {
                throw new RequiredError('paymentId','Required parameter paymentId was null or undefined when calling paymentsCardsGet_2.');
            }
            // verify required parameter 'paymentCardId' is not null or undefined
            if (paymentCardId === null || paymentCardId === undefined) {
                throw new RequiredError('paymentCardId','Required parameter paymentCardId was null or undefined when calling paymentsCardsGet_2.');
            }
            const localVarPath = `/payments/{payment_id}/cards/{payment_card_id}`
                .replace(`{${"payment_id"}}`, encodeURIComponent(String(paymentId)))
                .replace(`{${"payment_card_id"}}`, encodeURIComponent(String(paymentCardId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [paymentCardId] Payment Card Id
         * @param {string} [partnerId] Partner id for the payment cards to filter by.
         * @param {string} [partnerUserId] Partner user id for the payment cards to filter by.
         * @param {string} [clientId] Client id for the payment cards to filter by.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCardsIndex(paymentCardId?: string, partnerId?: string, partnerUserId?: string, clientId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/payments/cards`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (paymentCardId !== undefined) {
                localVarQueryParameter['payment_card_id'] = paymentCardId;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (partnerUserId !== undefined) {
                localVarQueryParameter['partner_user_id'] = partnerUserId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} paymentMethodId
         * @param {PaymentMethodActionResource} paymentMethodActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsMethodsActionsCreate(paymentMethodId: string, paymentMethodActionResource: PaymentMethodActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'paymentMethodId' is not null or undefined
            if (paymentMethodId === null || paymentMethodId === undefined) {
                throw new RequiredError('paymentMethodId','Required parameter paymentMethodId was null or undefined when calling paymentsMethodsActionsCreate.');
            }
            // verify required parameter 'paymentMethodActionResource' is not null or undefined
            if (paymentMethodActionResource === null || paymentMethodActionResource === undefined) {
                throw new RequiredError('paymentMethodActionResource','Required parameter paymentMethodActionResource was null or undefined when calling paymentsMethodsActionsCreate.');
            }
            const localVarPath = `/payments/methods/{payment_method_id}/actions`
                .replace(`{${"payment_method_id"}}`, encodeURIComponent(String(paymentMethodId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PaymentMethodActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(paymentMethodActionResource || {}) : (paymentMethodActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} paymentId
         * @param {string} paymentMethodId
         * @param {PaymentMethodActionResource} paymentMethodActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsMethodsActionsCreate_3(paymentId: string, paymentMethodId: string, paymentMethodActionResource: PaymentMethodActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'paymentId' is not null or undefined
            if (paymentId === null || paymentId === undefined) {
                throw new RequiredError('paymentId','Required parameter paymentId was null or undefined when calling paymentsMethodsActionsCreate_3.');
            }
            // verify required parameter 'paymentMethodId' is not null or undefined
            if (paymentMethodId === null || paymentMethodId === undefined) {
                throw new RequiredError('paymentMethodId','Required parameter paymentMethodId was null or undefined when calling paymentsMethodsActionsCreate_3.');
            }
            // verify required parameter 'paymentMethodActionResource' is not null or undefined
            if (paymentMethodActionResource === null || paymentMethodActionResource === undefined) {
                throw new RequiredError('paymentMethodActionResource','Required parameter paymentMethodActionResource was null or undefined when calling paymentsMethodsActionsCreate_3.');
            }
            const localVarPath = `/payments/{payment_id}/methods/{payment_method_id}/actions`
                .replace(`{${"payment_id"}}`, encodeURIComponent(String(paymentId)))
                .replace(`{${"payment_method_id"}}`, encodeURIComponent(String(paymentMethodId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PaymentMethodActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(paymentMethodActionResource || {}) : (paymentMethodActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {PaymentCardResource} paymentCardResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCardsCreate(paymentCardResource: PaymentCardResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentCardResource> {
            const localVarFetchArgs = PaymentsApiFetchParamCreator(configuration).paymentsCardsCreate(paymentCardResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Delete a card for a given id.
         * @summary Delete a card for a given id.
         * @param {string} cardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCardsDelete(cardId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PaymentsApiFetchParamCreator(configuration).paymentsCardsDelete(cardId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Delete a card for a given id.
         * @summary Delete a card for a given id.
         * @param {string} paymentId
         * @param {string} cardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCardsDelete_1(paymentId: string, cardId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PaymentsApiFetchParamCreator(configuration).paymentsCardsDelete_1(paymentId, cardId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} paymentCardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCardsGet(paymentCardId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentCardResource> {
            const localVarFetchArgs = PaymentsApiFetchParamCreator(configuration).paymentsCardsGet(paymentCardId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} paymentId
         * @param {string} paymentCardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCardsGet_2(paymentId: string, paymentCardId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentCardResource> {
            const localVarFetchArgs = PaymentsApiFetchParamCreator(configuration).paymentsCardsGet_2(paymentId, paymentCardId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [paymentCardId] Payment Card Id
         * @param {string} [partnerId] Partner id for the payment cards to filter by.
         * @param {string} [partnerUserId] Partner user id for the payment cards to filter by.
         * @param {string} [clientId] Client id for the payment cards to filter by.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCardsIndex(paymentCardId?: string, partnerId?: string, partnerUserId?: string, clientId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentCardResult> {
            const localVarFetchArgs = PaymentsApiFetchParamCreator(configuration).paymentsCardsIndex(paymentCardId, partnerId, partnerUserId, clientId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} paymentMethodId
         * @param {PaymentMethodActionResource} paymentMethodActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsMethodsActionsCreate(paymentMethodId: string, paymentMethodActionResource: PaymentMethodActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentMethodResource> {
            const localVarFetchArgs = PaymentsApiFetchParamCreator(configuration).paymentsMethodsActionsCreate(paymentMethodId, paymentMethodActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} paymentId
         * @param {string} paymentMethodId
         * @param {PaymentMethodActionResource} paymentMethodActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsMethodsActionsCreate_3(paymentId: string, paymentMethodId: string, paymentMethodActionResource: PaymentMethodActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentMethodResource> {
            const localVarFetchArgs = PaymentsApiFetchParamCreator(configuration).paymentsMethodsActionsCreate_3(paymentId, paymentMethodId, paymentMethodActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {PaymentCardResource} paymentCardResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCardsCreate(paymentCardResource: PaymentCardResource, options?: any) {
            return PaymentsApiFp(configuration).paymentsCardsCreate(paymentCardResource, options)(fetch, basePath);
        },
        /**
         * Delete a card for a given id.
         * @summary Delete a card for a given id.
         * @param {string} cardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCardsDelete(cardId: string, options?: any) {
            return PaymentsApiFp(configuration).paymentsCardsDelete(cardId, options)(fetch, basePath);
        },
        /**
         * Delete a card for a given id.
         * @summary Delete a card for a given id.
         * @param {string} paymentId
         * @param {string} cardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCardsDelete_1(paymentId: string, cardId: string, options?: any) {
            return PaymentsApiFp(configuration).paymentsCardsDelete_1(paymentId, cardId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} paymentCardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCardsGet(paymentCardId: string, options?: any) {
            return PaymentsApiFp(configuration).paymentsCardsGet(paymentCardId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} paymentId
         * @param {string} paymentCardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCardsGet_2(paymentId: string, paymentCardId: string, options?: any) {
            return PaymentsApiFp(configuration).paymentsCardsGet_2(paymentId, paymentCardId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [paymentCardId] Payment Card Id
         * @param {string} [partnerId] Partner id for the payment cards to filter by.
         * @param {string} [partnerUserId] Partner user id for the payment cards to filter by.
         * @param {string} [clientId] Client id for the payment cards to filter by.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCardsIndex(paymentCardId?: string, partnerId?: string, partnerUserId?: string, clientId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PaymentsApiFp(configuration).paymentsCardsIndex(paymentCardId, partnerId, partnerUserId, clientId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} paymentMethodId
         * @param {PaymentMethodActionResource} paymentMethodActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsMethodsActionsCreate(paymentMethodId: string, paymentMethodActionResource: PaymentMethodActionResource, options?: any) {
            return PaymentsApiFp(configuration).paymentsMethodsActionsCreate(paymentMethodId, paymentMethodActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} paymentId
         * @param {string} paymentMethodId
         * @param {PaymentMethodActionResource} paymentMethodActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsMethodsActionsCreate_3(paymentId: string, paymentMethodId: string, paymentMethodActionResource: PaymentMethodActionResource, options?: any) {
            return PaymentsApiFp(configuration).paymentsMethodsActionsCreate_3(paymentId, paymentMethodId, paymentMethodActionResource, options)(fetch, basePath);
        },
    };
};

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
    /**
     *
     * @param {PaymentCardResource} paymentCardResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsCardsCreate(paymentCardResource: PaymentCardResource, options?: any) {
        return PaymentsApiFp(this.configuration).paymentsCardsCreate(paymentCardResource, options)(this.fetch, this.basePath);
    }

    /**
     * Delete a card for a given id.
     * @summary Delete a card for a given id.
     * @param {string} cardId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsCardsDelete(cardId: string, options?: any) {
        return PaymentsApiFp(this.configuration).paymentsCardsDelete(cardId, options)(this.fetch, this.basePath);
    }

    /**
     * Delete a card for a given id.
     * @summary Delete a card for a given id.
     * @param {string} paymentId
     * @param {string} cardId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsCardsDelete_1(paymentId: string, cardId: string, options?: any) {
        return PaymentsApiFp(this.configuration).paymentsCardsDelete_1(paymentId, cardId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} paymentCardId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsCardsGet(paymentCardId: string, options?: any) {
        return PaymentsApiFp(this.configuration).paymentsCardsGet(paymentCardId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} paymentId
     * @param {string} paymentCardId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsCardsGet_2(paymentId: string, paymentCardId: string, options?: any) {
        return PaymentsApiFp(this.configuration).paymentsCardsGet_2(paymentId, paymentCardId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [paymentCardId] Payment Card Id
     * @param {string} [partnerId] Partner id for the payment cards to filter by.
     * @param {string} [partnerUserId] Partner user id for the payment cards to filter by.
     * @param {string} [clientId] Client id for the payment cards to filter by.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsCardsIndex(paymentCardId?: string, partnerId?: string, partnerUserId?: string, clientId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PaymentsApiFp(this.configuration).paymentsCardsIndex(paymentCardId, partnerId, partnerUserId, clientId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} paymentMethodId
     * @param {PaymentMethodActionResource} paymentMethodActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsMethodsActionsCreate(paymentMethodId: string, paymentMethodActionResource: PaymentMethodActionResource, options?: any) {
        return PaymentsApiFp(this.configuration).paymentsMethodsActionsCreate(paymentMethodId, paymentMethodActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} paymentId
     * @param {string} paymentMethodId
     * @param {PaymentMethodActionResource} paymentMethodActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsMethodsActionsCreate_3(paymentId: string, paymentMethodId: string, paymentMethodActionResource: PaymentMethodActionResource, options?: any) {
        return PaymentsApiFp(this.configuration).paymentsMethodsActionsCreate_3(paymentId, paymentMethodId, paymentMethodActionResource, options)(this.fetch, this.basePath);
    }

}

/**
 * PaymentschedulesApi - fetch parameter creator
 * @export
 */
export const PaymentschedulesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentschedulesIndex(limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/paymentschedules`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentschedulesApi - functional programming interface
 * @export
 */
export const PaymentschedulesApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentschedulesIndex(limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PartnerPaymentScheduleResult> {
            const localVarFetchArgs = PaymentschedulesApiFetchParamCreator(configuration).paymentschedulesIndex(limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PaymentschedulesApi - factory interface
 * @export
 */
export const PaymentschedulesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentschedulesIndex(limit?: number, page?: number, query?: string, options?: any) {
            return PaymentschedulesApiFp(configuration).paymentschedulesIndex(limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * PaymentschedulesApi - object-oriented interface
 * @export
 * @class PaymentschedulesApi
 * @extends {BaseAPI}
 */
export class PaymentschedulesApi extends BaseAPI {
    /**
     *
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentschedulesApi
     */
    public paymentschedulesIndex(limit?: number, page?: number, query?: string, options?: any) {
        return PaymentschedulesApiFp(this.configuration).paymentschedulesIndex(limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * PaymentsolutionsApi - fetch parameter creator
 * @export
 */
export const PaymentsolutionsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [partnerId] Partner id for the payment solutions to filter by.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsolutionsIndex(partnerId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/paymentsolutions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsolutionsApi - functional programming interface
 * @export
 */
export const PaymentsolutionsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [partnerId] Partner id for the payment solutions to filter by.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsolutionsIndex(partnerId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentSolutionResult> {
            const localVarFetchArgs = PaymentsolutionsApiFetchParamCreator(configuration).paymentsolutionsIndex(partnerId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PaymentsolutionsApi - factory interface
 * @export
 */
export const PaymentsolutionsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} [partnerId] Partner id for the payment solutions to filter by.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsolutionsIndex(partnerId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return PaymentsolutionsApiFp(configuration).paymentsolutionsIndex(partnerId, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * PaymentsolutionsApi - object-oriented interface
 * @export
 * @class PaymentsolutionsApi
 * @extends {BaseAPI}
 */
export class PaymentsolutionsApi extends BaseAPI {
    /**
     *
     * @param {string} [partnerId] Partner id for the payment solutions to filter by.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsolutionsApi
     */
    public paymentsolutionsIndex(partnerId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return PaymentsolutionsApiFp(this.configuration).paymentsolutionsIndex(partnerId, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * PhonesApi - fetch parameter creator
 * @export
 */
export const PhonesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} phoneId
         * @param {PhoneActionResource} phoneActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        phonesActionsCreate(phoneId: string, phoneActionResource: PhoneActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'phoneId' is not null or undefined
            if (phoneId === null || phoneId === undefined) {
                throw new RequiredError('phoneId','Required parameter phoneId was null or undefined when calling phonesActionsCreate.');
            }
            // verify required parameter 'phoneActionResource' is not null or undefined
            if (phoneActionResource === null || phoneActionResource === undefined) {
                throw new RequiredError('phoneActionResource','Required parameter phoneActionResource was null or undefined when calling phonesActionsCreate.');
            }
            const localVarPath = `/phones/{phone_id}/actions`
                .replace(`{${"phone_id"}}`, encodeURIComponent(String(phoneId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PhoneActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(phoneActionResource || {}) : (phoneActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PhonesApi - functional programming interface
 * @export
 */
export const PhonesApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} phoneId
         * @param {PhoneActionResource} phoneActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        phonesActionsCreate(phoneId: string, phoneActionResource: PhoneActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PhoneResource> {
            const localVarFetchArgs = PhonesApiFetchParamCreator(configuration).phonesActionsCreate(phoneId, phoneActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PhonesApi - factory interface
 * @export
 */
export const PhonesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} phoneId
         * @param {PhoneActionResource} phoneActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        phonesActionsCreate(phoneId: string, phoneActionResource: PhoneActionResource, options?: any) {
            return PhonesApiFp(configuration).phonesActionsCreate(phoneId, phoneActionResource, options)(fetch, basePath);
        },
    };
};

/**
 * PhonesApi - object-oriented interface
 * @export
 * @class PhonesApi
 * @extends {BaseAPI}
 */
export class PhonesApi extends BaseAPI {
    /**
     *
     * @param {string} phoneId
     * @param {PhoneActionResource} phoneActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhonesApi
     */
    public phonesActionsCreate(phoneId: string, phoneActionResource: PhoneActionResource, options?: any) {
        return PhonesApiFp(this.configuration).phonesActionsCreate(phoneId, phoneActionResource, options)(this.fetch, this.basePath);
    }

}

/**
 * PrepaymentsApi - fetch parameter creator
 * @export
 */
export const PrepaymentsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {PrepaymentResource} prepaymentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prepaymentsCreate(prepaymentResource: PrepaymentResource, options: any = {}): FetchArgs {
            // verify required parameter 'prepaymentResource' is not null or undefined
            if (prepaymentResource === null || prepaymentResource === undefined) {
                throw new RequiredError('prepaymentResource','Required parameter prepaymentResource was null or undefined when calling prepaymentsCreate.');
            }
            const localVarPath = `/prepayments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PrepaymentResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(prepaymentResource || {}) : (prepaymentResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrepaymentsApi - functional programming interface
 * @export
 */
export const PrepaymentsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {PrepaymentResource} prepaymentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prepaymentsCreate(prepaymentResource: PrepaymentResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PrepaymentResource> {
            const localVarFetchArgs = PrepaymentsApiFetchParamCreator(configuration).prepaymentsCreate(prepaymentResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PrepaymentsApi - factory interface
 * @export
 */
export const PrepaymentsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {PrepaymentResource} prepaymentResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prepaymentsCreate(prepaymentResource: PrepaymentResource, options?: any) {
            return PrepaymentsApiFp(configuration).prepaymentsCreate(prepaymentResource, options)(fetch, basePath);
        },
    };
};

/**
 * PrepaymentsApi - object-oriented interface
 * @export
 * @class PrepaymentsApi
 * @extends {BaseAPI}
 */
export class PrepaymentsApi extends BaseAPI {
    /**
     *
     * @param {PrepaymentResource} prepaymentResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrepaymentsApi
     */
    public prepaymentsCreate(prepaymentResource: PrepaymentResource, options?: any) {
        return PrepaymentsApiFp(this.configuration).prepaymentsCreate(prepaymentResource, options)(this.fetch, this.basePath);
    }

}

/**
 * ProductsApi - fetch parameter creator
 * @export
 */
export const ProductsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create product.
         * @summary Create product.
         * @param {ProductResource} productResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCreate(productResource: ProductResource, options: any = {}): FetchArgs {
            // verify required parameter 'productResource' is not null or undefined
            if (productResource === null || productResource === undefined) {
                throw new RequiredError('productResource','Required parameter productResource was null or undefined when calling productsCreate.');
            }
            const localVarPath = `/products`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(productResource || {}) : (productResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List products.
         * @summary List products.
         * @param {number} [productId] Product id of Products to filter by.
         * @param {number} [employeeId] Employee id of Products to filter by.
         * @param {string} [geoCode] Geo code of Products to filter by.
         * @param {boolean} [isArchived] Filter products by archived status.
         * @param {boolean} [isAutoQuotable] Filter products by auto-quote availability
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsIndex(productId?: number, employeeId?: number, geoCode?: string, isArchived?: boolean, isAutoQuotable?: boolean, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/products`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (productId !== undefined) {
                localVarQueryParameter['product_id'] = productId;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employee_id'] = employeeId;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (isArchived !== undefined) {
                localVarQueryParameter['is_archived'] = isArchived;
            }

            if (isAutoQuotable !== undefined) {
                localVarQueryParameter['is_auto_quotable'] = isAutoQuotable;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Create product.
         * @summary Create product.
         * @param {ProductResource} productResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCreate(productResource: ProductResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductResource> {
            const localVarFetchArgs = ProductsApiFetchParamCreator(configuration).productsCreate(productResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * List products.
         * @summary List products.
         * @param {number} [productId] Product id of Products to filter by.
         * @param {number} [employeeId] Employee id of Products to filter by.
         * @param {string} [geoCode] Geo code of Products to filter by.
         * @param {boolean} [isArchived] Filter products by archived status.
         * @param {boolean} [isAutoQuotable] Filter products by auto-quote availability
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsIndex(productId?: number, employeeId?: number, geoCode?: string, isArchived?: boolean, isAutoQuotable?: boolean, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductResult> {
            const localVarFetchArgs = ProductsApiFetchParamCreator(configuration).productsIndex(productId, employeeId, geoCode, isArchived, isAutoQuotable, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Create product.
         * @summary Create product.
         * @param {ProductResource} productResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCreate(productResource: ProductResource, options?: any) {
            return ProductsApiFp(configuration).productsCreate(productResource, options)(fetch, basePath);
        },
        /**
         * List products.
         * @summary List products.
         * @param {number} [productId] Product id of Products to filter by.
         * @param {number} [employeeId] Employee id of Products to filter by.
         * @param {string} [geoCode] Geo code of Products to filter by.
         * @param {boolean} [isArchived] Filter products by archived status.
         * @param {boolean} [isAutoQuotable] Filter products by auto-quote availability
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsIndex(productId?: number, employeeId?: number, geoCode?: string, isArchived?: boolean, isAutoQuotable?: boolean, limit?: number, page?: number, query?: string, options?: any) {
            return ProductsApiFp(configuration).productsIndex(productId, employeeId, geoCode, isArchived, isAutoQuotable, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * Create product.
     * @summary Create product.
     * @param {ProductResource} productResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsCreate(productResource: ProductResource, options?: any) {
        return ProductsApiFp(this.configuration).productsCreate(productResource, options)(this.fetch, this.basePath);
    }

    /**
     * List products.
     * @summary List products.
     * @param {number} [productId] Product id of Products to filter by.
     * @param {number} [employeeId] Employee id of Products to filter by.
     * @param {string} [geoCode] Geo code of Products to filter by.
     * @param {boolean} [isArchived] Filter products by archived status.
     * @param {boolean} [isAutoQuotable] Filter products by auto-quote availability
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsIndex(productId?: number, employeeId?: number, geoCode?: string, isArchived?: boolean, isAutoQuotable?: boolean, limit?: number, page?: number, query?: string, options?: any) {
        return ProductsApiFp(this.configuration).productsIndex(productId, employeeId, geoCode, isArchived, isAutoQuotable, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * ProjectsApi - fetch parameter creator
 * @export
 */
export const ProjectsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {ProjectResource} projectResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsCreate(projectResource: ProjectResource, options: any = {}): FetchArgs {
            // verify required parameter 'projectResource' is not null or undefined
            if (projectResource === null || projectResource === undefined) {
                throw new RequiredError('projectResource','Required parameter projectResource was null or undefined when calling projectsCreate.');
            }
            const localVarPath = `/projects`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProjectResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(projectResource || {}) : (projectResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsDelete(projectId: string, options: any = {}): FetchArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling projectsDelete.');
            }
            const localVarPath = `/projects/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {ProjectEventResource} projectEventResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsCreate(projectEventResource: ProjectEventResource, options: any = {}): FetchArgs {
            // verify required parameter 'projectEventResource' is not null or undefined
            if (projectEventResource === null || projectEventResource === undefined) {
                throw new RequiredError('projectEventResource','Required parameter projectEventResource was null or undefined when calling projectsEventsCreate.');
            }
            const localVarPath = `/projects/events`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProjectEventResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(projectEventResource || {}) : (projectEventResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {ProjectEventResource} projectEventResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsCreate_1(projectId: string, projectEventResource: ProjectEventResource, options: any = {}): FetchArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling projectsEventsCreate_1.');
            }
            // verify required parameter 'projectEventResource' is not null or undefined
            if (projectEventResource === null || projectEventResource === undefined) {
                throw new RequiredError('projectEventResource','Required parameter projectEventResource was null or undefined when calling projectsEventsCreate_1.');
            }
            const localVarPath = `/projects/{project_id}/events`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProjectEventResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(projectEventResource || {}) : (projectEventResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {string} projectEventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsDelete(projectId: string, projectEventId: string, options: any = {}): FetchArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling projectsEventsDelete.');
            }
            // verify required parameter 'projectEventId' is not null or undefined
            if (projectEventId === null || projectEventId === undefined) {
                throw new RequiredError('projectEventId','Required parameter projectEventId was null or undefined when calling projectsEventsDelete.');
            }
            const localVarPath = `/projects/{project_id}/events/{project_event_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"project_event_id"}}`, encodeURIComponent(String(projectEventId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectEventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsGet(projectEventId: string, options: any = {}): FetchArgs {
            // verify required parameter 'projectEventId' is not null or undefined
            if (projectEventId === null || projectEventId === undefined) {
                throw new RequiredError('projectEventId','Required parameter projectEventId was null or undefined when calling projectsEventsGet.');
            }
            const localVarPath = `/projects/events/{project_event_id}`
                .replace(`{${"project_event_id"}}`, encodeURIComponent(String(projectEventId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {string} projectEventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsGet_2(projectId: string, projectEventId: string, options: any = {}): FetchArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling projectsEventsGet_2.');
            }
            // verify required parameter 'projectEventId' is not null or undefined
            if (projectEventId === null || projectEventId === undefined) {
                throw new RequiredError('projectEventId','Required parameter projectEventId was null or undefined when calling projectsEventsGet_2.');
            }
            const localVarPath = `/projects/{project_id}/events/{project_event_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"project_event_id"}}`, encodeURIComponent(String(projectEventId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [projectEventId] Id for the Projects&#39;s events.
         * @param {string} [projectId] Project ID.
         * @param {string} [partnerId] Partner Id.
         * @param {string} [partnerUserId] Partner User Id.
         * @param {string} [scheduledFrom] Events scheduled from.
         * @param {string} [scheduledTo] Events scheduled to.
         * @param {'unknown' | 'meeting'} [type] Project Event Types.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsIndex(projectEventId?: string, projectId?: string, partnerId?: string, partnerUserId?: string, scheduledFrom?: string, scheduledTo?: string, type?: 'unknown' | 'meeting', limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/projects/events`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (projectEventId !== undefined) {
                localVarQueryParameter['project_event_id'] = projectEventId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (partnerUserId !== undefined) {
                localVarQueryParameter['partner_user_id'] = partnerUserId;
            }

            if (scheduledFrom !== undefined) {
                localVarQueryParameter['scheduled_from'] = scheduledFrom;
            }

            if (scheduledTo !== undefined) {
                localVarQueryParameter['scheduled_to'] = scheduledTo;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {string} [projectEventId] Id for the Projects&#39;s events.
         * @param {string} [partnerId] Partner Id.
         * @param {string} [partnerUserId] Partner User Id.
         * @param {string} [scheduledFrom] Events scheduled from.
         * @param {string} [scheduledTo] Events scheduled to.
         * @param {'unknown' | 'meeting'} [type] Project Event Types.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsIndex_3(projectId: string, projectEventId?: string, partnerId?: string, partnerUserId?: string, scheduledFrom?: string, scheduledTo?: string, type?: 'unknown' | 'meeting', limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling projectsEventsIndex_3.');
            }
            const localVarPath = `/projects/{project_id}/events`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (projectEventId !== undefined) {
                localVarQueryParameter['project_event_id'] = projectEventId;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (partnerUserId !== undefined) {
                localVarQueryParameter['partner_user_id'] = partnerUserId;
            }

            if (scheduledFrom !== undefined) {
                localVarQueryParameter['scheduled_from'] = scheduledFrom;
            }

            if (scheduledTo !== undefined) {
                localVarQueryParameter['scheduled_to'] = scheduledTo;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectEventId
         * @param {ProjectEventResource} projectEventResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsUpdate(projectEventId: string, projectEventResource: ProjectEventResource, options: any = {}): FetchArgs {
            // verify required parameter 'projectEventId' is not null or undefined
            if (projectEventId === null || projectEventId === undefined) {
                throw new RequiredError('projectEventId','Required parameter projectEventId was null or undefined when calling projectsEventsUpdate.');
            }
            // verify required parameter 'projectEventResource' is not null or undefined
            if (projectEventResource === null || projectEventResource === undefined) {
                throw new RequiredError('projectEventResource','Required parameter projectEventResource was null or undefined when calling projectsEventsUpdate.');
            }
            const localVarPath = `/projects/events/{project_event_id}`
                .replace(`{${"project_event_id"}}`, encodeURIComponent(String(projectEventId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProjectEventResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(projectEventResource || {}) : (projectEventResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {string} projectEventId
         * @param {ProjectEventResource} projectEventResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsUpdate_4(projectId: string, projectEventId: string, projectEventResource: ProjectEventResource, options: any = {}): FetchArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling projectsEventsUpdate_4.');
            }
            // verify required parameter 'projectEventId' is not null or undefined
            if (projectEventId === null || projectEventId === undefined) {
                throw new RequiredError('projectEventId','Required parameter projectEventId was null or undefined when calling projectsEventsUpdate_4.');
            }
            // verify required parameter 'projectEventResource' is not null or undefined
            if (projectEventResource === null || projectEventResource === undefined) {
                throw new RequiredError('projectEventResource','Required parameter projectEventResource was null or undefined when calling projectsEventsUpdate_4.');
            }
            const localVarPath = `/projects/{project_id}/events/{project_event_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"project_event_id"}}`, encodeURIComponent(String(projectEventId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProjectEventResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(projectEventResource || {}) : (projectEventResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsGet(projectId: string, options: any = {}): FetchArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling projectsGet.');
            }
            const localVarPath = `/projects/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [projectId] Project ID.
         * @param {string} [partnerId] Partner Id.
         * @param {string} [partnerUserId] Partner User Id.
         * @param {string} [leadId] Lead Id.
         * @param {string} [name] Project name in its native language.
         * @param {string} [geoCode] Project geo code origin.
         * @param {string} [status] Project Status.
         * @param {string} [progress] Project quote progress.
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort projects
         * @param {boolean} [isArchived] Filter projects that are archived.
         * @param {boolean} [isLeadValidated]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIndex(projectId?: string, partnerId?: string, partnerUserId?: string, leadId?: string, name?: string, geoCode?: string, status?: string, progress?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', isArchived?: boolean, isLeadValidated?: boolean, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/projects`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (partnerUserId !== undefined) {
                localVarQueryParameter['partner_user_id'] = partnerUserId;
            }

            if (leadId !== undefined) {
                localVarQueryParameter['lead_id'] = leadId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (progress !== undefined) {
                localVarQueryParameter['progress'] = progress;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (isArchived !== undefined) {
                localVarQueryParameter['is_archived'] = isArchived;
            }

            if (isLeadValidated !== undefined) {
                localVarQueryParameter['is_lead_validated'] = isLeadValidated;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {ProjectNoteResource} projectNoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesCreate(projectNoteResource: ProjectNoteResource, options: any = {}): FetchArgs {
            // verify required parameter 'projectNoteResource' is not null or undefined
            if (projectNoteResource === null || projectNoteResource === undefined) {
                throw new RequiredError('projectNoteResource','Required parameter projectNoteResource was null or undefined when calling projectsNotesCreate.');
            }
            const localVarPath = `/projects/notes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProjectNoteResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(projectNoteResource || {}) : (projectNoteResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {ProjectNoteResource} projectNoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesCreate_5(projectId: string, projectNoteResource: ProjectNoteResource, options: any = {}): FetchArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling projectsNotesCreate_5.');
            }
            // verify required parameter 'projectNoteResource' is not null or undefined
            if (projectNoteResource === null || projectNoteResource === undefined) {
                throw new RequiredError('projectNoteResource','Required parameter projectNoteResource was null or undefined when calling projectsNotesCreate_5.');
            }
            const localVarPath = `/projects/{project_id}/notes`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProjectNoteResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(projectNoteResource || {}) : (projectNoteResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {string} projectNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesDelete(projectId: string, projectNoteId: string, options: any = {}): FetchArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling projectsNotesDelete.');
            }
            // verify required parameter 'projectNoteId' is not null or undefined
            if (projectNoteId === null || projectNoteId === undefined) {
                throw new RequiredError('projectNoteId','Required parameter projectNoteId was null or undefined when calling projectsNotesDelete.');
            }
            const localVarPath = `/projects/{project_id}/notes/{project_note_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"project_note_id"}}`, encodeURIComponent(String(projectNoteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesGet(projectNoteId: string, options: any = {}): FetchArgs {
            // verify required parameter 'projectNoteId' is not null or undefined
            if (projectNoteId === null || projectNoteId === undefined) {
                throw new RequiredError('projectNoteId','Required parameter projectNoteId was null or undefined when calling projectsNotesGet.');
            }
            const localVarPath = `/projects/notes/{project_note_id}`
                .replace(`{${"project_note_id"}}`, encodeURIComponent(String(projectNoteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {string} projectNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesGet_6(projectId: string, projectNoteId: string, options: any = {}): FetchArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling projectsNotesGet_6.');
            }
            // verify required parameter 'projectNoteId' is not null or undefined
            if (projectNoteId === null || projectNoteId === undefined) {
                throw new RequiredError('projectNoteId','Required parameter projectNoteId was null or undefined when calling projectsNotesGet_6.');
            }
            const localVarPath = `/projects/{project_id}/notes/{project_note_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"project_note_id"}}`, encodeURIComponent(String(projectNoteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [projectNoteId] Id for the Project&#39;s notes.
         * @param {string} [projectId] Project ID.
         * @param {string} [partnerId] Partner Id.
         * @param {string} [partnerUserId] Partner User Id.
         * @param {string} [leadId] Lead Id.
         * @param {string} [name] Project name in its native language.
         * @param {string} [geoCode] Project geo code origin.
         * @param {string} [status] Project Status.
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort project notes
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesIndex(projectNoteId?: string, projectId?: string, partnerId?: string, partnerUserId?: string, leadId?: string, name?: string, geoCode?: string, status?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/projects/notes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (projectNoteId !== undefined) {
                localVarQueryParameter['project_note_id'] = projectNoteId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (partnerUserId !== undefined) {
                localVarQueryParameter['partner_user_id'] = partnerUserId;
            }

            if (leadId !== undefined) {
                localVarQueryParameter['lead_id'] = leadId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {string} [projectNoteId] Id for the Project&#39;s notes.
         * @param {string} [partnerId] Partner Id.
         * @param {string} [partnerUserId] Partner User Id.
         * @param {string} [leadId] Lead Id.
         * @param {string} [name] Project name in its native language.
         * @param {string} [geoCode] Project geo code origin.
         * @param {string} [status] Project Status.
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort project notes
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesIndex_7(projectId: string, projectNoteId?: string, partnerId?: string, partnerUserId?: string, leadId?: string, name?: string, geoCode?: string, status?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling projectsNotesIndex_7.');
            }
            const localVarPath = `/projects/{project_id}/notes`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (projectNoteId !== undefined) {
                localVarQueryParameter['project_note_id'] = projectNoteId;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (partnerUserId !== undefined) {
                localVarQueryParameter['partner_user_id'] = partnerUserId;
            }

            if (leadId !== undefined) {
                localVarQueryParameter['lead_id'] = leadId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectNoteId
         * @param {ProjectNoteResource} projectNoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesUpdate(projectNoteId: string, projectNoteResource: ProjectNoteResource, options: any = {}): FetchArgs {
            // verify required parameter 'projectNoteId' is not null or undefined
            if (projectNoteId === null || projectNoteId === undefined) {
                throw new RequiredError('projectNoteId','Required parameter projectNoteId was null or undefined when calling projectsNotesUpdate.');
            }
            // verify required parameter 'projectNoteResource' is not null or undefined
            if (projectNoteResource === null || projectNoteResource === undefined) {
                throw new RequiredError('projectNoteResource','Required parameter projectNoteResource was null or undefined when calling projectsNotesUpdate.');
            }
            const localVarPath = `/projects/notes/{project_note_id}`
                .replace(`{${"project_note_id"}}`, encodeURIComponent(String(projectNoteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProjectNoteResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(projectNoteResource || {}) : (projectNoteResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {string} projectNoteId
         * @param {ProjectNoteResource} projectNoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesUpdate_8(projectId: string, projectNoteId: string, projectNoteResource: ProjectNoteResource, options: any = {}): FetchArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling projectsNotesUpdate_8.');
            }
            // verify required parameter 'projectNoteId' is not null or undefined
            if (projectNoteId === null || projectNoteId === undefined) {
                throw new RequiredError('projectNoteId','Required parameter projectNoteId was null or undefined when calling projectsNotesUpdate_8.');
            }
            // verify required parameter 'projectNoteResource' is not null or undefined
            if (projectNoteResource === null || projectNoteResource === undefined) {
                throw new RequiredError('projectNoteResource','Required parameter projectNoteResource was null or undefined when calling projectsNotesUpdate_8.');
            }
            const localVarPath = `/projects/{project_id}/notes/{project_note_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"project_note_id"}}`, encodeURIComponent(String(projectNoteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProjectNoteResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(projectNoteResource || {}) : (projectNoteResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsStatusesGet(status: string, options: any = {}): FetchArgs {
            // verify required parameter 'status' is not null or undefined
            if (status === null || status === undefined) {
                throw new RequiredError('status','Required parameter status was null or undefined when calling projectsStatusesGet.');
            }
            const localVarPath = `/projects/{status}/statuses`
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [status]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsStatusesIndex(status?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/projects/statuses`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {ProjectResource} projectResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsUpdate(projectId: string, projectResource: ProjectResource, options: any = {}): FetchArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling projectsUpdate.');
            }
            // verify required parameter 'projectResource' is not null or undefined
            if (projectResource === null || projectResource === undefined) {
                throw new RequiredError('projectResource','Required parameter projectResource was null or undefined when calling projectsUpdate.');
            }
            const localVarPath = `/projects/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProjectResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(projectResource || {}) : (projectResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {ProjectResource} projectResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsCreate(projectResource: ProjectResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProjectResource> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).projectsCreate(projectResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsDelete(projectId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).projectsDelete(projectId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {ProjectEventResource} projectEventResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsCreate(projectEventResource: ProjectEventResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProjectNoteResource> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).projectsEventsCreate(projectEventResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {ProjectEventResource} projectEventResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsCreate_1(projectId: string, projectEventResource: ProjectEventResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProjectNoteResource> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).projectsEventsCreate_1(projectId, projectEventResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {string} projectEventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsDelete(projectId: string, projectEventId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).projectsEventsDelete(projectId, projectEventId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} projectEventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsGet(projectEventId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProjectNoteResource> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).projectsEventsGet(projectEventId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {string} projectEventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsGet_2(projectId: string, projectEventId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProjectNoteResource> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).projectsEventsGet_2(projectId, projectEventId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [projectEventId] Id for the Projects&#39;s events.
         * @param {string} [projectId] Project ID.
         * @param {string} [partnerId] Partner Id.
         * @param {string} [partnerUserId] Partner User Id.
         * @param {string} [scheduledFrom] Events scheduled from.
         * @param {string} [scheduledTo] Events scheduled to.
         * @param {'unknown' | 'meeting'} [type] Project Event Types.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsIndex(projectEventId?: string, projectId?: string, partnerId?: string, partnerUserId?: string, scheduledFrom?: string, scheduledTo?: string, type?: 'unknown' | 'meeting', limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProjectNoteResult> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).projectsEventsIndex(projectEventId, projectId, partnerId, partnerUserId, scheduledFrom, scheduledTo, type, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {string} [projectEventId] Id for the Projects&#39;s events.
         * @param {string} [partnerId] Partner Id.
         * @param {string} [partnerUserId] Partner User Id.
         * @param {string} [scheduledFrom] Events scheduled from.
         * @param {string} [scheduledTo] Events scheduled to.
         * @param {'unknown' | 'meeting'} [type] Project Event Types.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsIndex_3(projectId: string, projectEventId?: string, partnerId?: string, partnerUserId?: string, scheduledFrom?: string, scheduledTo?: string, type?: 'unknown' | 'meeting', limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProjectNoteResult> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).projectsEventsIndex_3(projectId, projectEventId, partnerId, partnerUserId, scheduledFrom, scheduledTo, type, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} projectEventId
         * @param {ProjectEventResource} projectEventResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsUpdate(projectEventId: string, projectEventResource: ProjectEventResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProjectNoteResource> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).projectsEventsUpdate(projectEventId, projectEventResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {string} projectEventId
         * @param {ProjectEventResource} projectEventResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsUpdate_4(projectId: string, projectEventId: string, projectEventResource: ProjectEventResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProjectNoteResource> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).projectsEventsUpdate_4(projectId, projectEventId, projectEventResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsGet(projectId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProjectResource> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).projectsGet(projectId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [projectId] Project ID.
         * @param {string} [partnerId] Partner Id.
         * @param {string} [partnerUserId] Partner User Id.
         * @param {string} [leadId] Lead Id.
         * @param {string} [name] Project name in its native language.
         * @param {string} [geoCode] Project geo code origin.
         * @param {string} [status] Project Status.
         * @param {string} [progress] Project quote progress.
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort projects
         * @param {boolean} [isArchived] Filter projects that are archived.
         * @param {boolean} [isLeadValidated]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIndex(projectId?: string, partnerId?: string, partnerUserId?: string, leadId?: string, name?: string, geoCode?: string, status?: string, progress?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', isArchived?: boolean, isLeadValidated?: boolean, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProjectResult> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).projectsIndex(projectId, partnerId, partnerUserId, leadId, name, geoCode, status, progress, sort, isArchived, isLeadValidated, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {ProjectNoteResource} projectNoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesCreate(projectNoteResource: ProjectNoteResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProjectNoteResource> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).projectsNotesCreate(projectNoteResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {ProjectNoteResource} projectNoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesCreate_5(projectId: string, projectNoteResource: ProjectNoteResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProjectNoteResource> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).projectsNotesCreate_5(projectId, projectNoteResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {string} projectNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesDelete(projectId: string, projectNoteId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).projectsNotesDelete(projectId, projectNoteId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} projectNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesGet(projectNoteId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProjectNoteResource> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).projectsNotesGet(projectNoteId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {string} projectNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesGet_6(projectId: string, projectNoteId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProjectNoteResource> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).projectsNotesGet_6(projectId, projectNoteId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [projectNoteId] Id for the Project&#39;s notes.
         * @param {string} [projectId] Project ID.
         * @param {string} [partnerId] Partner Id.
         * @param {string} [partnerUserId] Partner User Id.
         * @param {string} [leadId] Lead Id.
         * @param {string} [name] Project name in its native language.
         * @param {string} [geoCode] Project geo code origin.
         * @param {string} [status] Project Status.
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort project notes
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesIndex(projectNoteId?: string, projectId?: string, partnerId?: string, partnerUserId?: string, leadId?: string, name?: string, geoCode?: string, status?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProjectNoteResult> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).projectsNotesIndex(projectNoteId, projectId, partnerId, partnerUserId, leadId, name, geoCode, status, sort, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {string} [projectNoteId] Id for the Project&#39;s notes.
         * @param {string} [partnerId] Partner Id.
         * @param {string} [partnerUserId] Partner User Id.
         * @param {string} [leadId] Lead Id.
         * @param {string} [name] Project name in its native language.
         * @param {string} [geoCode] Project geo code origin.
         * @param {string} [status] Project Status.
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort project notes
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesIndex_7(projectId: string, projectNoteId?: string, partnerId?: string, partnerUserId?: string, leadId?: string, name?: string, geoCode?: string, status?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProjectNoteResult> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).projectsNotesIndex_7(projectId, projectNoteId, partnerId, partnerUserId, leadId, name, geoCode, status, sort, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} projectNoteId
         * @param {ProjectNoteResource} projectNoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesUpdate(projectNoteId: string, projectNoteResource: ProjectNoteResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProjectNoteResource> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).projectsNotesUpdate(projectNoteId, projectNoteResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {string} projectNoteId
         * @param {ProjectNoteResource} projectNoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesUpdate_8(projectId: string, projectNoteId: string, projectNoteResource: ProjectNoteResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProjectNoteResource> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).projectsNotesUpdate_8(projectId, projectNoteId, projectNoteResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsStatusesGet(status: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProjectStatusResource> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).projectsStatusesGet(status, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [status]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsStatusesIndex(status?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProjectStatusResult> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).projectsStatusesIndex(status, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {ProjectResource} projectResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsUpdate(projectId: string, projectResource: ProjectResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProjectResource> {
            const localVarFetchArgs = ProjectsApiFetchParamCreator(configuration).projectsUpdate(projectId, projectResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {ProjectResource} projectResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsCreate(projectResource: ProjectResource, options?: any) {
            return ProjectsApiFp(configuration).projectsCreate(projectResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsDelete(projectId: string, options?: any) {
            return ProjectsApiFp(configuration).projectsDelete(projectId, options)(fetch, basePath);
        },
        /**
         *
         * @param {ProjectEventResource} projectEventResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsCreate(projectEventResource: ProjectEventResource, options?: any) {
            return ProjectsApiFp(configuration).projectsEventsCreate(projectEventResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} projectId
         * @param {ProjectEventResource} projectEventResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsCreate_1(projectId: string, projectEventResource: ProjectEventResource, options?: any) {
            return ProjectsApiFp(configuration).projectsEventsCreate_1(projectId, projectEventResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} projectId
         * @param {string} projectEventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsDelete(projectId: string, projectEventId: string, options?: any) {
            return ProjectsApiFp(configuration).projectsEventsDelete(projectId, projectEventId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} projectEventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsGet(projectEventId: string, options?: any) {
            return ProjectsApiFp(configuration).projectsEventsGet(projectEventId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} projectId
         * @param {string} projectEventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsGet_2(projectId: string, projectEventId: string, options?: any) {
            return ProjectsApiFp(configuration).projectsEventsGet_2(projectId, projectEventId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [projectEventId] Id for the Projects&#39;s events.
         * @param {string} [projectId] Project ID.
         * @param {string} [partnerId] Partner Id.
         * @param {string} [partnerUserId] Partner User Id.
         * @param {string} [scheduledFrom] Events scheduled from.
         * @param {string} [scheduledTo] Events scheduled to.
         * @param {'unknown' | 'meeting'} [type] Project Event Types.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsIndex(projectEventId?: string, projectId?: string, partnerId?: string, partnerUserId?: string, scheduledFrom?: string, scheduledTo?: string, type?: 'unknown' | 'meeting', limit?: number, page?: number, query?: string, options?: any) {
            return ProjectsApiFp(configuration).projectsEventsIndex(projectEventId, projectId, partnerId, partnerUserId, scheduledFrom, scheduledTo, type, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} projectId
         * @param {string} [projectEventId] Id for the Projects&#39;s events.
         * @param {string} [partnerId] Partner Id.
         * @param {string} [partnerUserId] Partner User Id.
         * @param {string} [scheduledFrom] Events scheduled from.
         * @param {string} [scheduledTo] Events scheduled to.
         * @param {'unknown' | 'meeting'} [type] Project Event Types.
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsIndex_3(projectId: string, projectEventId?: string, partnerId?: string, partnerUserId?: string, scheduledFrom?: string, scheduledTo?: string, type?: 'unknown' | 'meeting', limit?: number, page?: number, query?: string, options?: any) {
            return ProjectsApiFp(configuration).projectsEventsIndex_3(projectId, projectEventId, partnerId, partnerUserId, scheduledFrom, scheduledTo, type, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} projectEventId
         * @param {ProjectEventResource} projectEventResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsUpdate(projectEventId: string, projectEventResource: ProjectEventResource, options?: any) {
            return ProjectsApiFp(configuration).projectsEventsUpdate(projectEventId, projectEventResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} projectId
         * @param {string} projectEventId
         * @param {ProjectEventResource} projectEventResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsEventsUpdate_4(projectId: string, projectEventId: string, projectEventResource: ProjectEventResource, options?: any) {
            return ProjectsApiFp(configuration).projectsEventsUpdate_4(projectId, projectEventId, projectEventResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsGet(projectId: string, options?: any) {
            return ProjectsApiFp(configuration).projectsGet(projectId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [projectId] Project ID.
         * @param {string} [partnerId] Partner Id.
         * @param {string} [partnerUserId] Partner User Id.
         * @param {string} [leadId] Lead Id.
         * @param {string} [name] Project name in its native language.
         * @param {string} [geoCode] Project geo code origin.
         * @param {string} [status] Project Status.
         * @param {string} [progress] Project quote progress.
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort projects
         * @param {boolean} [isArchived] Filter projects that are archived.
         * @param {boolean} [isLeadValidated]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIndex(projectId?: string, partnerId?: string, partnerUserId?: string, leadId?: string, name?: string, geoCode?: string, status?: string, progress?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', isArchived?: boolean, isLeadValidated?: boolean, limit?: number, page?: number, query?: string, options?: any) {
            return ProjectsApiFp(configuration).projectsIndex(projectId, partnerId, partnerUserId, leadId, name, geoCode, status, progress, sort, isArchived, isLeadValidated, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {ProjectNoteResource} projectNoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesCreate(projectNoteResource: ProjectNoteResource, options?: any) {
            return ProjectsApiFp(configuration).projectsNotesCreate(projectNoteResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} projectId
         * @param {ProjectNoteResource} projectNoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesCreate_5(projectId: string, projectNoteResource: ProjectNoteResource, options?: any) {
            return ProjectsApiFp(configuration).projectsNotesCreate_5(projectId, projectNoteResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} projectId
         * @param {string} projectNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesDelete(projectId: string, projectNoteId: string, options?: any) {
            return ProjectsApiFp(configuration).projectsNotesDelete(projectId, projectNoteId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} projectNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesGet(projectNoteId: string, options?: any) {
            return ProjectsApiFp(configuration).projectsNotesGet(projectNoteId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} projectId
         * @param {string} projectNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesGet_6(projectId: string, projectNoteId: string, options?: any) {
            return ProjectsApiFp(configuration).projectsNotesGet_6(projectId, projectNoteId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [projectNoteId] Id for the Project&#39;s notes.
         * @param {string} [projectId] Project ID.
         * @param {string} [partnerId] Partner Id.
         * @param {string} [partnerUserId] Partner User Id.
         * @param {string} [leadId] Lead Id.
         * @param {string} [name] Project name in its native language.
         * @param {string} [geoCode] Project geo code origin.
         * @param {string} [status] Project Status.
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort project notes
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesIndex(projectNoteId?: string, projectId?: string, partnerId?: string, partnerUserId?: string, leadId?: string, name?: string, geoCode?: string, status?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
            return ProjectsApiFp(configuration).projectsNotesIndex(projectNoteId, projectId, partnerId, partnerUserId, leadId, name, geoCode, status, sort, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} projectId
         * @param {string} [projectNoteId] Id for the Project&#39;s notes.
         * @param {string} [partnerId] Partner Id.
         * @param {string} [partnerUserId] Partner User Id.
         * @param {string} [leadId] Lead Id.
         * @param {string} [name] Project name in its native language.
         * @param {string} [geoCode] Project geo code origin.
         * @param {string} [status] Project Status.
         * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort project notes
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesIndex_7(projectId: string, projectNoteId?: string, partnerId?: string, partnerUserId?: string, leadId?: string, name?: string, geoCode?: string, status?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
            return ProjectsApiFp(configuration).projectsNotesIndex_7(projectId, projectNoteId, partnerId, partnerUserId, leadId, name, geoCode, status, sort, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} projectNoteId
         * @param {ProjectNoteResource} projectNoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesUpdate(projectNoteId: string, projectNoteResource: ProjectNoteResource, options?: any) {
            return ProjectsApiFp(configuration).projectsNotesUpdate(projectNoteId, projectNoteResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} projectId
         * @param {string} projectNoteId
         * @param {ProjectNoteResource} projectNoteResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsNotesUpdate_8(projectId: string, projectNoteId: string, projectNoteResource: ProjectNoteResource, options?: any) {
            return ProjectsApiFp(configuration).projectsNotesUpdate_8(projectId, projectNoteId, projectNoteResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsStatusesGet(status: string, options?: any) {
            return ProjectsApiFp(configuration).projectsStatusesGet(status, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [status]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsStatusesIndex(status?: string, limit?: number, page?: number, query?: string, options?: any) {
            return ProjectsApiFp(configuration).projectsStatusesIndex(status, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} projectId
         * @param {ProjectResource} projectResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsUpdate(projectId: string, projectResource: ProjectResource, options?: any) {
            return ProjectsApiFp(configuration).projectsUpdate(projectId, projectResource, options)(fetch, basePath);
        },
    };
};

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     *
     * @param {ProjectResource} projectResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsCreate(projectResource: ProjectResource, options?: any) {
        return ProjectsApiFp(this.configuration).projectsCreate(projectResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsDelete(projectId: string, options?: any) {
        return ProjectsApiFp(this.configuration).projectsDelete(projectId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {ProjectEventResource} projectEventResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsEventsCreate(projectEventResource: ProjectEventResource, options?: any) {
        return ProjectsApiFp(this.configuration).projectsEventsCreate(projectEventResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} projectId
     * @param {ProjectEventResource} projectEventResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsEventsCreate_1(projectId: string, projectEventResource: ProjectEventResource, options?: any) {
        return ProjectsApiFp(this.configuration).projectsEventsCreate_1(projectId, projectEventResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} projectId
     * @param {string} projectEventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsEventsDelete(projectId: string, projectEventId: string, options?: any) {
        return ProjectsApiFp(this.configuration).projectsEventsDelete(projectId, projectEventId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} projectEventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsEventsGet(projectEventId: string, options?: any) {
        return ProjectsApiFp(this.configuration).projectsEventsGet(projectEventId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} projectId
     * @param {string} projectEventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsEventsGet_2(projectId: string, projectEventId: string, options?: any) {
        return ProjectsApiFp(this.configuration).projectsEventsGet_2(projectId, projectEventId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [projectEventId] Id for the Projects&#39;s events.
     * @param {string} [projectId] Project ID.
     * @param {string} [partnerId] Partner Id.
     * @param {string} [partnerUserId] Partner User Id.
     * @param {string} [scheduledFrom] Events scheduled from.
     * @param {string} [scheduledTo] Events scheduled to.
     * @param {'unknown' | 'meeting'} [type] Project Event Types.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsEventsIndex(projectEventId?: string, projectId?: string, partnerId?: string, partnerUserId?: string, scheduledFrom?: string, scheduledTo?: string, type?: 'unknown' | 'meeting', limit?: number, page?: number, query?: string, options?: any) {
        return ProjectsApiFp(this.configuration).projectsEventsIndex(projectEventId, projectId, partnerId, partnerUserId, scheduledFrom, scheduledTo, type, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} projectId
     * @param {string} [projectEventId] Id for the Projects&#39;s events.
     * @param {string} [partnerId] Partner Id.
     * @param {string} [partnerUserId] Partner User Id.
     * @param {string} [scheduledFrom] Events scheduled from.
     * @param {string} [scheduledTo] Events scheduled to.
     * @param {'unknown' | 'meeting'} [type] Project Event Types.
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsEventsIndex_3(projectId: string, projectEventId?: string, partnerId?: string, partnerUserId?: string, scheduledFrom?: string, scheduledTo?: string, type?: 'unknown' | 'meeting', limit?: number, page?: number, query?: string, options?: any) {
        return ProjectsApiFp(this.configuration).projectsEventsIndex_3(projectId, projectEventId, partnerId, partnerUserId, scheduledFrom, scheduledTo, type, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} projectEventId
     * @param {ProjectEventResource} projectEventResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsEventsUpdate(projectEventId: string, projectEventResource: ProjectEventResource, options?: any) {
        return ProjectsApiFp(this.configuration).projectsEventsUpdate(projectEventId, projectEventResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} projectId
     * @param {string} projectEventId
     * @param {ProjectEventResource} projectEventResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsEventsUpdate_4(projectId: string, projectEventId: string, projectEventResource: ProjectEventResource, options?: any) {
        return ProjectsApiFp(this.configuration).projectsEventsUpdate_4(projectId, projectEventId, projectEventResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsGet(projectId: string, options?: any) {
        return ProjectsApiFp(this.configuration).projectsGet(projectId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [projectId] Project ID.
     * @param {string} [partnerId] Partner Id.
     * @param {string} [partnerUserId] Partner User Id.
     * @param {string} [leadId] Lead Id.
     * @param {string} [name] Project name in its native language.
     * @param {string} [geoCode] Project geo code origin.
     * @param {string} [status] Project Status.
     * @param {string} [progress] Project quote progress.
     * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort projects
     * @param {boolean} [isArchived] Filter projects that are archived.
     * @param {boolean} [isLeadValidated]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsIndex(projectId?: string, partnerId?: string, partnerUserId?: string, leadId?: string, name?: string, geoCode?: string, status?: string, progress?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', isArchived?: boolean, isLeadValidated?: boolean, limit?: number, page?: number, query?: string, options?: any) {
        return ProjectsApiFp(this.configuration).projectsIndex(projectId, partnerId, partnerUserId, leadId, name, geoCode, status, progress, sort, isArchived, isLeadValidated, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {ProjectNoteResource} projectNoteResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsNotesCreate(projectNoteResource: ProjectNoteResource, options?: any) {
        return ProjectsApiFp(this.configuration).projectsNotesCreate(projectNoteResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} projectId
     * @param {ProjectNoteResource} projectNoteResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsNotesCreate_5(projectId: string, projectNoteResource: ProjectNoteResource, options?: any) {
        return ProjectsApiFp(this.configuration).projectsNotesCreate_5(projectId, projectNoteResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} projectId
     * @param {string} projectNoteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsNotesDelete(projectId: string, projectNoteId: string, options?: any) {
        return ProjectsApiFp(this.configuration).projectsNotesDelete(projectId, projectNoteId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} projectNoteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsNotesGet(projectNoteId: string, options?: any) {
        return ProjectsApiFp(this.configuration).projectsNotesGet(projectNoteId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} projectId
     * @param {string} projectNoteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsNotesGet_6(projectId: string, projectNoteId: string, options?: any) {
        return ProjectsApiFp(this.configuration).projectsNotesGet_6(projectId, projectNoteId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [projectNoteId] Id for the Project&#39;s notes.
     * @param {string} [projectId] Project ID.
     * @param {string} [partnerId] Partner Id.
     * @param {string} [partnerUserId] Partner User Id.
     * @param {string} [leadId] Lead Id.
     * @param {string} [name] Project name in its native language.
     * @param {string} [geoCode] Project geo code origin.
     * @param {string} [status] Project Status.
     * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort project notes
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsNotesIndex(projectNoteId?: string, projectId?: string, partnerId?: string, partnerUserId?: string, leadId?: string, name?: string, geoCode?: string, status?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
        return ProjectsApiFp(this.configuration).projectsNotesIndex(projectNoteId, projectId, partnerId, partnerUserId, leadId, name, geoCode, status, sort, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} projectId
     * @param {string} [projectNoteId] Id for the Project&#39;s notes.
     * @param {string} [partnerId] Partner Id.
     * @param {string} [partnerUserId] Partner User Id.
     * @param {string} [leadId] Lead Id.
     * @param {string} [name] Project name in its native language.
     * @param {string} [geoCode] Project geo code origin.
     * @param {string} [status] Project Status.
     * @param {'created_at' | 'updated_at' | 'ascending' | 'descending'} [sort] Sort project notes
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsNotesIndex_7(projectId: string, projectNoteId?: string, partnerId?: string, partnerUserId?: string, leadId?: string, name?: string, geoCode?: string, status?: string, sort?: 'created_at' | 'updated_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
        return ProjectsApiFp(this.configuration).projectsNotesIndex_7(projectId, projectNoteId, partnerId, partnerUserId, leadId, name, geoCode, status, sort, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} projectNoteId
     * @param {ProjectNoteResource} projectNoteResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsNotesUpdate(projectNoteId: string, projectNoteResource: ProjectNoteResource, options?: any) {
        return ProjectsApiFp(this.configuration).projectsNotesUpdate(projectNoteId, projectNoteResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} projectId
     * @param {string} projectNoteId
     * @param {ProjectNoteResource} projectNoteResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsNotesUpdate_8(projectId: string, projectNoteId: string, projectNoteResource: ProjectNoteResource, options?: any) {
        return ProjectsApiFp(this.configuration).projectsNotesUpdate_8(projectId, projectNoteId, projectNoteResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsStatusesGet(status: string, options?: any) {
        return ProjectsApiFp(this.configuration).projectsStatusesGet(status, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [status]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsStatusesIndex(status?: string, limit?: number, page?: number, query?: string, options?: any) {
        return ProjectsApiFp(this.configuration).projectsStatusesIndex(status, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} projectId
     * @param {ProjectResource} projectResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsUpdate(projectId: string, projectResource: ProjectResource, options?: any) {
        return ProjectsApiFp(this.configuration).projectsUpdate(projectId, projectResource, options)(this.fetch, this.basePath);
    }

}

/**
 * PromptsApi - fetch parameter creator
 * @export
 */
export const PromptsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} promptId
         * @param {PromptActionResource} promptActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promptsActionsCreate(promptId: string, promptActionResource: PromptActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'promptId' is not null or undefined
            if (promptId === null || promptId === undefined) {
                throw new RequiredError('promptId','Required parameter promptId was null or undefined when calling promptsActionsCreate.');
            }
            // verify required parameter 'promptActionResource' is not null or undefined
            if (promptActionResource === null || promptActionResource === undefined) {
                throw new RequiredError('promptActionResource','Required parameter promptActionResource was null or undefined when calling promptsActionsCreate.');
            }
            const localVarPath = `/prompts/{prompt_id}/actions`
                .replace(`{${"prompt_id"}}`, encodeURIComponent(String(promptId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PromptActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(promptActionResource || {}) : (promptActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {boolean} [showExpired]
         * @param {number} [partnerId]
         * @param {number} [partnerUserId]
         * @param {'unknown' | 'request_satisfaction'} [type]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promptsIndex(showExpired?: boolean, partnerId?: number, partnerUserId?: number, type?: 'unknown' | 'request_satisfaction', limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/prompts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (showExpired !== undefined) {
                localVarQueryParameter['show_expired'] = showExpired;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (partnerUserId !== undefined) {
                localVarQueryParameter['partner_user_id'] = partnerUserId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromptsApi - functional programming interface
 * @export
 */
export const PromptsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} promptId
         * @param {PromptActionResource} promptActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promptsActionsCreate(promptId: string, promptActionResource: PromptActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PromptResource> {
            const localVarFetchArgs = PromptsApiFetchParamCreator(configuration).promptsActionsCreate(promptId, promptActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {boolean} [showExpired]
         * @param {number} [partnerId]
         * @param {number} [partnerUserId]
         * @param {'unknown' | 'request_satisfaction'} [type]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promptsIndex(showExpired?: boolean, partnerId?: number, partnerUserId?: number, type?: 'unknown' | 'request_satisfaction', limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PromptResult> {
            const localVarFetchArgs = PromptsApiFetchParamCreator(configuration).promptsIndex(showExpired, partnerId, partnerUserId, type, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PromptsApi - factory interface
 * @export
 */
export const PromptsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} promptId
         * @param {PromptActionResource} promptActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promptsActionsCreate(promptId: string, promptActionResource: PromptActionResource, options?: any) {
            return PromptsApiFp(configuration).promptsActionsCreate(promptId, promptActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {boolean} [showExpired]
         * @param {number} [partnerId]
         * @param {number} [partnerUserId]
         * @param {'unknown' | 'request_satisfaction'} [type]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promptsIndex(showExpired?: boolean, partnerId?: number, partnerUserId?: number, type?: 'unknown' | 'request_satisfaction', limit?: number, page?: number, query?: string, options?: any) {
            return PromptsApiFp(configuration).promptsIndex(showExpired, partnerId, partnerUserId, type, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * PromptsApi - object-oriented interface
 * @export
 * @class PromptsApi
 * @extends {BaseAPI}
 */
export class PromptsApi extends BaseAPI {
    /**
     *
     * @param {string} promptId
     * @param {PromptActionResource} promptActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromptsApi
     */
    public promptsActionsCreate(promptId: string, promptActionResource: PromptActionResource, options?: any) {
        return PromptsApiFp(this.configuration).promptsActionsCreate(promptId, promptActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {boolean} [showExpired]
     * @param {number} [partnerId]
     * @param {number} [partnerUserId]
     * @param {'unknown' | 'request_satisfaction'} [type]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromptsApi
     */
    public promptsIndex(showExpired?: boolean, partnerId?: number, partnerUserId?: number, type?: 'unknown' | 'request_satisfaction', limit?: number, page?: number, query?: string, options?: any) {
        return PromptsApiFp(this.configuration).promptsIndex(showExpired, partnerId, partnerUserId, type, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * RatingsApi - fetch parameter creator
 * @export
 */
export const RatingsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {RatingResource} ratingResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ratingsCreate(ratingResource: RatingResource, options: any = {}): FetchArgs {
            // verify required parameter 'ratingResource' is not null or undefined
            if (ratingResource === null || ratingResource === undefined) {
                throw new RequiredError('ratingResource','Required parameter ratingResource was null or undefined when calling ratingsCreate.');
            }
            const localVarPath = `/ratings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RatingResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(ratingResource || {}) : (ratingResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} ratingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ratingsGet(ratingId: string, options: any = {}): FetchArgs {
            // verify required parameter 'ratingId' is not null or undefined
            if (ratingId === null || ratingId === undefined) {
                throw new RequiredError('ratingId','Required parameter ratingId was null or undefined when calling ratingsGet.');
            }
            const localVarPath = `/ratings/{rating_id}`
                .replace(`{${"rating_id"}}`, encodeURIComponent(String(ratingId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [partnerId] Partner id for the ratings to be filtered by.
         * @param {string} [industryId] Partner industry id for the ratings to be filtered by.
         * @param {string} [ratingId] Rating ids.
         * @param {'created_at' | 'featured_at' | 'distance' | 'ascending' | 'descending'} [sort] Sort ratings by a given property.
         * @param {string} [geoCode] Geographic Location Code.
         * @param {string} [stars] List of stars to filter by
         * @param {boolean} [isPublic] Only show the public variables
         * @param {string} [geoPoint] Location Point to sort ratings by
         * @param {number} [geoDistance] Location filter by distance
         * @param {string} [distinct] Only show distinct ratings
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ratingsIndex(partnerId?: string, industryId?: string, ratingId?: string, sort?: 'created_at' | 'featured_at' | 'distance' | 'ascending' | 'descending', geoCode?: string, stars?: string, isPublic?: boolean, geoPoint?: string, geoDistance?: number, distinct?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/ratings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (industryId !== undefined) {
                localVarQueryParameter['industry_id'] = industryId;
            }

            if (ratingId !== undefined) {
                localVarQueryParameter['rating_id'] = ratingId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (stars !== undefined) {
                localVarQueryParameter['stars'] = stars;
            }

            if (isPublic !== undefined) {
                localVarQueryParameter['is_public'] = isPublic;
            }

            if (geoPoint !== undefined) {
                localVarQueryParameter['geo_point'] = geoPoint;
            }

            if (geoDistance !== undefined) {
                localVarQueryParameter['geo_distance'] = geoDistance;
            }

            if (distinct !== undefined) {
                localVarQueryParameter['distinct'] = distinct;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} ratingId
         * @param {RatingResource} ratingResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ratingsUpdate(ratingId: string, ratingResource: RatingResource, options: any = {}): FetchArgs {
            // verify required parameter 'ratingId' is not null or undefined
            if (ratingId === null || ratingId === undefined) {
                throw new RequiredError('ratingId','Required parameter ratingId was null or undefined when calling ratingsUpdate.');
            }
            // verify required parameter 'ratingResource' is not null or undefined
            if (ratingResource === null || ratingResource === undefined) {
                throw new RequiredError('ratingResource','Required parameter ratingResource was null or undefined when calling ratingsUpdate.');
            }
            const localVarPath = `/ratings/{rating_id}`
                .replace(`{${"rating_id"}}`, encodeURIComponent(String(ratingId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RatingResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(ratingResource || {}) : (ratingResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RatingsApi - functional programming interface
 * @export
 */
export const RatingsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {RatingResource} ratingResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ratingsCreate(ratingResource: RatingResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RatingResource> {
            const localVarFetchArgs = RatingsApiFetchParamCreator(configuration).ratingsCreate(ratingResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} ratingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ratingsGet(ratingId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RatingResource> {
            const localVarFetchArgs = RatingsApiFetchParamCreator(configuration).ratingsGet(ratingId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [partnerId] Partner id for the ratings to be filtered by.
         * @param {string} [industryId] Partner industry id for the ratings to be filtered by.
         * @param {string} [ratingId] Rating ids.
         * @param {'created_at' | 'featured_at' | 'distance' | 'ascending' | 'descending'} [sort] Sort ratings by a given property.
         * @param {string} [geoCode] Geographic Location Code.
         * @param {string} [stars] List of stars to filter by
         * @param {boolean} [isPublic] Only show the public variables
         * @param {string} [geoPoint] Location Point to sort ratings by
         * @param {number} [geoDistance] Location filter by distance
         * @param {string} [distinct] Only show distinct ratings
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ratingsIndex(partnerId?: string, industryId?: string, ratingId?: string, sort?: 'created_at' | 'featured_at' | 'distance' | 'ascending' | 'descending', geoCode?: string, stars?: string, isPublic?: boolean, geoPoint?: string, geoDistance?: number, distinct?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RatingResult> {
            const localVarFetchArgs = RatingsApiFetchParamCreator(configuration).ratingsIndex(partnerId, industryId, ratingId, sort, geoCode, stars, isPublic, geoPoint, geoDistance, distinct, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} ratingId
         * @param {RatingResource} ratingResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ratingsUpdate(ratingId: string, ratingResource: RatingResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RatingResource> {
            const localVarFetchArgs = RatingsApiFetchParamCreator(configuration).ratingsUpdate(ratingId, ratingResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * RatingsApi - factory interface
 * @export
 */
export const RatingsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {RatingResource} ratingResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ratingsCreate(ratingResource: RatingResource, options?: any) {
            return RatingsApiFp(configuration).ratingsCreate(ratingResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} ratingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ratingsGet(ratingId: string, options?: any) {
            return RatingsApiFp(configuration).ratingsGet(ratingId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [partnerId] Partner id for the ratings to be filtered by.
         * @param {string} [industryId] Partner industry id for the ratings to be filtered by.
         * @param {string} [ratingId] Rating ids.
         * @param {'created_at' | 'featured_at' | 'distance' | 'ascending' | 'descending'} [sort] Sort ratings by a given property.
         * @param {string} [geoCode] Geographic Location Code.
         * @param {string} [stars] List of stars to filter by
         * @param {boolean} [isPublic] Only show the public variables
         * @param {string} [geoPoint] Location Point to sort ratings by
         * @param {number} [geoDistance] Location filter by distance
         * @param {string} [distinct] Only show distinct ratings
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ratingsIndex(partnerId?: string, industryId?: string, ratingId?: string, sort?: 'created_at' | 'featured_at' | 'distance' | 'ascending' | 'descending', geoCode?: string, stars?: string, isPublic?: boolean, geoPoint?: string, geoDistance?: number, distinct?: string, limit?: number, page?: number, query?: string, options?: any) {
            return RatingsApiFp(configuration).ratingsIndex(partnerId, industryId, ratingId, sort, geoCode, stars, isPublic, geoPoint, geoDistance, distinct, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} ratingId
         * @param {RatingResource} ratingResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ratingsUpdate(ratingId: string, ratingResource: RatingResource, options?: any) {
            return RatingsApiFp(configuration).ratingsUpdate(ratingId, ratingResource, options)(fetch, basePath);
        },
    };
};

/**
 * RatingsApi - object-oriented interface
 * @export
 * @class RatingsApi
 * @extends {BaseAPI}
 */
export class RatingsApi extends BaseAPI {
    /**
     *
     * @param {RatingResource} ratingResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RatingsApi
     */
    public ratingsCreate(ratingResource: RatingResource, options?: any) {
        return RatingsApiFp(this.configuration).ratingsCreate(ratingResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} ratingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RatingsApi
     */
    public ratingsGet(ratingId: string, options?: any) {
        return RatingsApiFp(this.configuration).ratingsGet(ratingId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [partnerId] Partner id for the ratings to be filtered by.
     * @param {string} [industryId] Partner industry id for the ratings to be filtered by.
     * @param {string} [ratingId] Rating ids.
     * @param {'created_at' | 'featured_at' | 'distance' | 'ascending' | 'descending'} [sort] Sort ratings by a given property.
     * @param {string} [geoCode] Geographic Location Code.
     * @param {string} [stars] List of stars to filter by
     * @param {boolean} [isPublic] Only show the public variables
     * @param {string} [geoPoint] Location Point to sort ratings by
     * @param {number} [geoDistance] Location filter by distance
     * @param {string} [distinct] Only show distinct ratings
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RatingsApi
     */
    public ratingsIndex(partnerId?: string, industryId?: string, ratingId?: string, sort?: 'created_at' | 'featured_at' | 'distance' | 'ascending' | 'descending', geoCode?: string, stars?: string, isPublic?: boolean, geoPoint?: string, geoDistance?: number, distinct?: string, limit?: number, page?: number, query?: string, options?: any) {
        return RatingsApiFp(this.configuration).ratingsIndex(partnerId, industryId, ratingId, sort, geoCode, stars, isPublic, geoPoint, geoDistance, distinct, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} ratingId
     * @param {RatingResource} ratingResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RatingsApi
     */
    public ratingsUpdate(ratingId: string, ratingResource: RatingResource, options?: any) {
        return RatingsApiFp(this.configuration).ratingsUpdate(ratingId, ratingResource, options)(this.fetch, this.basePath);
    }

}

/**
 * RefundsApi - fetch parameter creator
 * @export
 */
export const RefundsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} refundId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundsGet(refundId: string, options: any = {}): FetchArgs {
            // verify required parameter 'refundId' is not null or undefined
            if (refundId === null || refundId === undefined) {
                throw new RequiredError('refundId','Required parameter refundId was null or undefined when calling refundsGet.');
            }
            const localVarPath = `/refunds/{refund_id}`
                .replace(`{${"refund_id"}}`, encodeURIComponent(String(refundId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [partnerId] Partner ID. @var int
         * @param {'created_at' | 'ascending' | 'descending'} [sort] Sort resource by. @var string
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundsIndex(partnerId?: string, sort?: 'created_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/refunds`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RefundsApi - functional programming interface
 * @export
 */
export const RefundsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} refundId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundsGet(refundId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RefundResource> {
            const localVarFetchArgs = RefundsApiFetchParamCreator(configuration).refundsGet(refundId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [partnerId] Partner ID. @var int
         * @param {'created_at' | 'ascending' | 'descending'} [sort] Sort resource by. @var string
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundsIndex(partnerId?: string, sort?: 'created_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RefundResult> {
            const localVarFetchArgs = RefundsApiFetchParamCreator(configuration).refundsIndex(partnerId, sort, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * RefundsApi - factory interface
 * @export
 */
export const RefundsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} refundId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundsGet(refundId: string, options?: any) {
            return RefundsApiFp(configuration).refundsGet(refundId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [partnerId] Partner ID. @var int
         * @param {'created_at' | 'ascending' | 'descending'} [sort] Sort resource by. @var string
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundsIndex(partnerId?: string, sort?: 'created_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
            return RefundsApiFp(configuration).refundsIndex(partnerId, sort, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * RefundsApi - object-oriented interface
 * @export
 * @class RefundsApi
 * @extends {BaseAPI}
 */
export class RefundsApi extends BaseAPI {
    /**
     *
     * @param {string} refundId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefundsApi
     */
    public refundsGet(refundId: string, options?: any) {
        return RefundsApiFp(this.configuration).refundsGet(refundId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [partnerId] Partner ID. @var int
     * @param {'created_at' | 'ascending' | 'descending'} [sort] Sort resource by. @var string
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefundsApi
     */
    public refundsIndex(partnerId?: string, sort?: 'created_at' | 'ascending' | 'descending', limit?: number, page?: number, query?: string, options?: any) {
        return RefundsApiFp(this.configuration).refundsIndex(partnerId, sort, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * RevenuesegmentsApi - fetch parameter creator
 * @export
 */
export const RevenuesegmentsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [geoCode] Filter by geo code.
         * @param {number} [primaryIndustryId] Filter by primary industry. @var int
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revenuesegmentsIndex(geoCode?: string, primaryIndustryId?: number, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/revenuesegments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (primaryIndustryId !== undefined) {
                localVarQueryParameter['primary_industry_id'] = primaryIndustryId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RevenuesegmentsApi - functional programming interface
 * @export
 */
export const RevenuesegmentsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [geoCode] Filter by geo code.
         * @param {number} [primaryIndustryId] Filter by primary industry. @var int
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revenuesegmentsIndex(geoCode?: string, primaryIndustryId?: number, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RevenueSegmentResult> {
            const localVarFetchArgs = RevenuesegmentsApiFetchParamCreator(configuration).revenuesegmentsIndex(geoCode, primaryIndustryId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * RevenuesegmentsApi - factory interface
 * @export
 */
export const RevenuesegmentsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} [geoCode] Filter by geo code.
         * @param {number} [primaryIndustryId] Filter by primary industry. @var int
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revenuesegmentsIndex(geoCode?: string, primaryIndustryId?: number, limit?: number, page?: number, query?: string, options?: any) {
            return RevenuesegmentsApiFp(configuration).revenuesegmentsIndex(geoCode, primaryIndustryId, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * RevenuesegmentsApi - object-oriented interface
 * @export
 * @class RevenuesegmentsApi
 * @extends {BaseAPI}
 */
export class RevenuesegmentsApi extends BaseAPI {
    /**
     *
     * @param {string} [geoCode] Filter by geo code.
     * @param {number} [primaryIndustryId] Filter by primary industry. @var int
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RevenuesegmentsApi
     */
    public revenuesegmentsIndex(geoCode?: string, primaryIndustryId?: number, limit?: number, page?: number, query?: string, options?: any) {
        return RevenuesegmentsApiFp(this.configuration).revenuesegmentsIndex(geoCode, primaryIndustryId, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * SearchApi - fetch parameter creator
 * @export
 */
export const SearchApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIndex(limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [limit] Limit the number of suggest resources pr. page.
         * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
         * @param {string} [query] The search query to search by.
         * @param {string} [type] Type to search for.
         * @param {string} [partnerState] Partner state.
         * @param {number} [page] The page position in the result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSuggestIndex(limit?: number, geoCode?: string, query?: string, type?: string, partnerState?: string, page?: number, options: any = {}): FetchArgs {
            const localVarPath = `/search/suggest`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (partnerState !== undefined) {
                localVarQueryParameter['partner_state'] = partnerState;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [limit] Limit the number of suggest resources pr. page.
         * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
         * @param {string} [query] The search query to search by.
         * @param {string} [type] Type to search for.
         * @param {string} [partnerState] Partner state.
         * @param {number} [page] The page position in the result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTypeaheadIndex(limit?: number, geoCode?: string, query?: string, type?: string, partnerState?: string, page?: number, options: any = {}): FetchArgs {
            const localVarPath = `/search/typeahead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (partnerState !== undefined) {
                localVarQueryParameter['partner_state'] = partnerState;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIndex(limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SearchResult> {
            const localVarFetchArgs = SearchApiFetchParamCreator(configuration).searchIndex(limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} [limit] Limit the number of suggest resources pr. page.
         * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
         * @param {string} [query] The search query to search by.
         * @param {string} [type] Type to search for.
         * @param {string} [partnerState] Partner state.
         * @param {number} [page] The page position in the result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSuggestIndex(limit?: number, geoCode?: string, query?: string, type?: string, partnerState?: string, page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SearchResult> {
            const localVarFetchArgs = SearchApiFetchParamCreator(configuration).searchSuggestIndex(limit, geoCode, query, type, partnerState, page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} [limit] Limit the number of suggest resources pr. page.
         * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
         * @param {string} [query] The search query to search by.
         * @param {string} [type] Type to search for.
         * @param {string} [partnerState] Partner state.
         * @param {number} [page] The page position in the result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTypeaheadIndex(limit?: number, geoCode?: string, query?: string, type?: string, partnerState?: string, page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TypeaheadResult> {
            const localVarFetchArgs = SearchApiFetchParamCreator(configuration).searchTypeaheadIndex(limit, geoCode, query, type, partnerState, page, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIndex(limit?: number, page?: number, query?: string, options?: any) {
            return SearchApiFp(configuration).searchIndex(limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} [limit] Limit the number of suggest resources pr. page.
         * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
         * @param {string} [query] The search query to search by.
         * @param {string} [type] Type to search for.
         * @param {string} [partnerState] Partner state.
         * @param {number} [page] The page position in the result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSuggestIndex(limit?: number, geoCode?: string, query?: string, type?: string, partnerState?: string, page?: number, options?: any) {
            return SearchApiFp(configuration).searchSuggestIndex(limit, geoCode, query, type, partnerState, page, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} [limit] Limit the number of suggest resources pr. page.
         * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
         * @param {string} [query] The search query to search by.
         * @param {string} [type] Type to search for.
         * @param {string} [partnerState] Partner state.
         * @param {number} [page] The page position in the result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTypeaheadIndex(limit?: number, geoCode?: string, query?: string, type?: string, partnerState?: string, page?: number, options?: any) {
            return SearchApiFp(configuration).searchTypeaheadIndex(limit, geoCode, query, type, partnerState, page, options)(fetch, basePath);
        },
    };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     *
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchIndex(limit?: number, page?: number, query?: string, options?: any) {
        return SearchApiFp(this.configuration).searchIndex(limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} [limit] Limit the number of suggest resources pr. page.
     * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
     * @param {string} [query] The search query to search by.
     * @param {string} [type] Type to search for.
     * @param {string} [partnerState] Partner state.
     * @param {number} [page] The page position in the result.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchSuggestIndex(limit?: number, geoCode?: string, query?: string, type?: string, partnerState?: string, page?: number, options?: any) {
        return SearchApiFp(this.configuration).searchSuggestIndex(limit, geoCode, query, type, partnerState, page, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} [limit] Limit the number of suggest resources pr. page.
     * @param {string} [geoCode] Geo Location Code ( ISO 3166 ).
     * @param {string} [query] The search query to search by.
     * @param {string} [type] Type to search for.
     * @param {string} [partnerState] Partner state.
     * @param {number} [page] The page position in the result.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchTypeaheadIndex(limit?: number, geoCode?: string, query?: string, type?: string, partnerState?: string, page?: number, options?: any) {
        return SearchApiFp(this.configuration).searchTypeaheadIndex(limit, geoCode, query, type, partnerState, page, options)(this.fetch, this.basePath);
    }

}

/**
 * SectorsApi - fetch parameter creator
 * @export
 */
export const SectorsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [geoCode]
         * @param {string} [sort]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sectorsIndex(geoCode?: string, sort?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/sectors`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SectorsApi - functional programming interface
 * @export
 */
export const SectorsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [geoCode]
         * @param {string} [sort]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sectorsIndex(geoCode?: string, sort?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SectorResult> {
            const localVarFetchArgs = SectorsApiFetchParamCreator(configuration).sectorsIndex(geoCode, sort, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SectorsApi - factory interface
 * @export
 */
export const SectorsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} [geoCode]
         * @param {string} [sort]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sectorsIndex(geoCode?: string, sort?: string, limit?: number, page?: number, query?: string, options?: any) {
            return SectorsApiFp(configuration).sectorsIndex(geoCode, sort, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * SectorsApi - object-oriented interface
 * @export
 * @class SectorsApi
 * @extends {BaseAPI}
 */
export class SectorsApi extends BaseAPI {
    /**
     *
     * @param {string} [geoCode]
     * @param {string} [sort]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectorsApi
     */
    public sectorsIndex(geoCode?: string, sort?: string, limit?: number, page?: number, query?: string, options?: any) {
        return SectorsApiFp(this.configuration).sectorsIndex(geoCode, sort, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * SoftconversionsApi - fetch parameter creator
 * @export
 */
export const SoftconversionsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {SoftConversionResource} softConversionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        softconversionsCreate(softConversionResource: SoftConversionResource, options: any = {}): FetchArgs {
            // verify required parameter 'softConversionResource' is not null or undefined
            if (softConversionResource === null || softConversionResource === undefined) {
                throw new RequiredError('softConversionResource','Required parameter softConversionResource was null or undefined when calling softconversionsCreate.');
            }
            const localVarPath = `/softconversions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SoftConversionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(softConversionResource || {}) : (softConversionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SoftconversionsApi - functional programming interface
 * @export
 */
export const SoftconversionsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {SoftConversionResource} softConversionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        softconversionsCreate(softConversionResource: SoftConversionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SoftConversionResource> {
            const localVarFetchArgs = SoftconversionsApiFetchParamCreator(configuration).softconversionsCreate(softConversionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SoftconversionsApi - factory interface
 * @export
 */
export const SoftconversionsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {SoftConversionResource} softConversionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        softconversionsCreate(softConversionResource: SoftConversionResource, options?: any) {
            return SoftconversionsApiFp(configuration).softconversionsCreate(softConversionResource, options)(fetch, basePath);
        },
    };
};

/**
 * SoftconversionsApi - object-oriented interface
 * @export
 * @class SoftconversionsApi
 * @extends {BaseAPI}
 */
export class SoftconversionsApi extends BaseAPI {
    /**
     *
     * @param {SoftConversionResource} softConversionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoftconversionsApi
     */
    public softconversionsCreate(softConversionResource: SoftConversionResource, options?: any) {
        return SoftconversionsApiFp(this.configuration).softconversionsCreate(softConversionResource, options)(this.fetch, this.basePath);
    }

}

/**
 * TasksApi - fetch parameter creator
 * @export
 */
export const TasksApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {TaskResource} taskResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksCreate(taskResource: TaskResource, options: any = {}): FetchArgs {
            // verify required parameter 'taskResource' is not null or undefined
            if (taskResource === null || taskResource === undefined) {
                throw new RequiredError('taskResource','Required parameter taskResource was null or undefined when calling tasksCreate.');
            }
            const localVarPath = `/tasks`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TaskResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(taskResource || {}) : (taskResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksDelete(taskId: string, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling tasksDelete.');
            }
            const localVarPath = `/tasks/{task_id}`
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksGet(taskId: string, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling tasksGet.');
            }
            const localVarPath = `/tasks/{task_id}`
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [taskId] Task id&#39;s to filter by.
         * @param {string} [geoCode]
         * @param {string} [employeeId]
         * @param {string} [completedByEmployeeId] Filter by tasks that are completed by a given employee_id.
         * @param {string} [assignedEmployeeId] Filter by tasks that are assigned to a given employee_id.
         * @param {string} [partnerId] Query tasks associated to a given partner.
         * @param {string} [leadId] Query tasks associated to a given lead.
         * @param {string} [status] Status for the tasks to be filtered by.
         * @param {number} [limit] Number of resources to return.
         * @param {boolean} [includeDone]
         * @param {'unknown' | 'csm' | 'pgm' | 'psm'} [process] Process type
         * @param {string} [dueBefore] Due before date
         * @param {'created_at' | 'deadline_at' | 'completed_at' | 'ascending' | 'descending'} [sort]
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksIndex(taskId?: string, geoCode?: string, employeeId?: string, completedByEmployeeId?: string, assignedEmployeeId?: string, partnerId?: string, leadId?: string, status?: string, limit?: number, includeDone?: boolean, process?: 'unknown' | 'csm' | 'pgm' | 'psm', dueBefore?: string, sort?: 'created_at' | 'deadline_at' | 'completed_at' | 'ascending' | 'descending', page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/tasks`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (taskId !== undefined) {
                localVarQueryParameter['task_id'] = taskId;
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employee_id'] = employeeId;
            }

            if (completedByEmployeeId !== undefined) {
                localVarQueryParameter['completed_by_employee_id'] = completedByEmployeeId;
            }

            if (assignedEmployeeId !== undefined) {
                localVarQueryParameter['assigned_employee_id'] = assignedEmployeeId;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (leadId !== undefined) {
                localVarQueryParameter['lead_id'] = leadId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (includeDone !== undefined) {
                localVarQueryParameter['include_done'] = includeDone;
            }

            if (process !== undefined) {
                localVarQueryParameter['process'] = process;
            }

            if (dueBefore !== undefined) {
                localVarQueryParameter['due_before'] = dueBefore;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} priorityId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksPrioritiesGet(priorityId: string, options: any = {}): FetchArgs {
            // verify required parameter 'priorityId' is not null or undefined
            if (priorityId === null || priorityId === undefined) {
                throw new RequiredError('priorityId','Required parameter priorityId was null or undefined when calling tasksPrioritiesGet.');
            }
            const localVarPath = `/tasks/priorities/{priority_id}`
                .replace(`{${"priority_id"}}`, encodeURIComponent(String(priorityId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} taskId
         * @param {string} priorityId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksPrioritiesGet_1(taskId: string, priorityId: string, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling tasksPrioritiesGet_1.');
            }
            // verify required parameter 'priorityId' is not null or undefined
            if (priorityId === null || priorityId === undefined) {
                throw new RequiredError('priorityId','Required parameter priorityId was null or undefined when calling tasksPrioritiesGet_1.');
            }
            const localVarPath = `/tasks/{task_id}/priorities/{priority_id}`
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)))
                .replace(`{${"priority_id"}}`, encodeURIComponent(String(priorityId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [priorityId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksPrioritiesIndex(priorityId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/tasks/priorities`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (priorityId !== undefined) {
                localVarQueryParameter['priority_id'] = priorityId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} statusId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksStatusesGet(statusId: string, options: any = {}): FetchArgs {
            // verify required parameter 'statusId' is not null or undefined
            if (statusId === null || statusId === undefined) {
                throw new RequiredError('statusId','Required parameter statusId was null or undefined when calling tasksStatusesGet.');
            }
            const localVarPath = `/tasks/statuses/{status_id}`
                .replace(`{${"status_id"}}`, encodeURIComponent(String(statusId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} taskId
         * @param {string} statusId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksStatusesGet_2(taskId: string, statusId: string, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling tasksStatusesGet_2.');
            }
            // verify required parameter 'statusId' is not null or undefined
            if (statusId === null || statusId === undefined) {
                throw new RequiredError('statusId','Required parameter statusId was null or undefined when calling tasksStatusesGet_2.');
            }
            const localVarPath = `/tasks/{task_id}/statuses/{status_id}`
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)))
                .replace(`{${"status_id"}}`, encodeURIComponent(String(statusId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [statusId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksStatusesIndex(statusId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/tasks/statuses`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (statusId !== undefined) {
                localVarQueryParameter['status_id'] = statusId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} taskId
         * @param {TaskableResource} taskableResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksTaskablesCreate(taskId: string, taskableResource: TaskableResource, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling tasksTaskablesCreate.');
            }
            // verify required parameter 'taskableResource' is not null or undefined
            if (taskableResource === null || taskableResource === undefined) {
                throw new RequiredError('taskableResource','Required parameter taskableResource was null or undefined when calling tasksTaskablesCreate.');
            }
            const localVarPath = `/tasks/{task_id}/taskables`
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TaskableResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(taskableResource || {}) : (taskableResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} taskId
         * @param {string} taskableId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksTaskablesDelete(taskId: string, taskableId: string, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling tasksTaskablesDelete.');
            }
            // verify required parameter 'taskableId' is not null or undefined
            if (taskableId === null || taskableId === undefined) {
                throw new RequiredError('taskableId','Required parameter taskableId was null or undefined when calling tasksTaskablesDelete.');
            }
            const localVarPath = `/tasks/{task_id}/taskables/{taskable_id}`
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)))
                .replace(`{${"taskable_id"}}`, encodeURIComponent(String(taskableId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} taskId
         * @param {string} taskableId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksTaskablesGet(taskId: string, taskableId: string, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling tasksTaskablesGet.');
            }
            // verify required parameter 'taskableId' is not null or undefined
            if (taskableId === null || taskableId === undefined) {
                throw new RequiredError('taskableId','Required parameter taskableId was null or undefined when calling tasksTaskablesGet.');
            }
            const localVarPath = `/tasks/{task_id}/taskables/{taskable_id}`
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)))
                .replace(`{${"taskable_id"}}`, encodeURIComponent(String(taskableId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} taskId
         * @param {string} [taskableId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksTaskablesIndex(taskId: string, taskableId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling tasksTaskablesIndex.');
            }
            const localVarPath = `/tasks/{task_id}/taskables`
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (taskableId !== undefined) {
                localVarQueryParameter['taskable_id'] = taskableId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} taskId
         * @param {TaskResource} taskResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksUpdate(taskId: string, taskResource: TaskResource, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling tasksUpdate.');
            }
            // verify required parameter 'taskResource' is not null or undefined
            if (taskResource === null || taskResource === undefined) {
                throw new RequiredError('taskResource','Required parameter taskResource was null or undefined when calling tasksUpdate.');
            }
            const localVarPath = `/tasks/{task_id}`
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TaskResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(taskResource || {}) : (taskResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TasksApi - functional programming interface
 * @export
 */
export const TasksApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {TaskResource} taskResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksCreate(taskResource: TaskResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TaskResource> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).tasksCreate(taskResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksDelete(taskId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).tasksDelete(taskId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksGet(taskId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TaskResource> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).tasksGet(taskId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [taskId] Task id&#39;s to filter by.
         * @param {string} [geoCode]
         * @param {string} [employeeId]
         * @param {string} [completedByEmployeeId] Filter by tasks that are completed by a given employee_id.
         * @param {string} [assignedEmployeeId] Filter by tasks that are assigned to a given employee_id.
         * @param {string} [partnerId] Query tasks associated to a given partner.
         * @param {string} [leadId] Query tasks associated to a given lead.
         * @param {string} [status] Status for the tasks to be filtered by.
         * @param {number} [limit] Number of resources to return.
         * @param {boolean} [includeDone]
         * @param {'unknown' | 'csm' | 'pgm' | 'psm'} [process] Process type
         * @param {string} [dueBefore] Due before date
         * @param {'created_at' | 'deadline_at' | 'completed_at' | 'ascending' | 'descending'} [sort]
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksIndex(taskId?: string, geoCode?: string, employeeId?: string, completedByEmployeeId?: string, assignedEmployeeId?: string, partnerId?: string, leadId?: string, status?: string, limit?: number, includeDone?: boolean, process?: 'unknown' | 'csm' | 'pgm' | 'psm', dueBefore?: string, sort?: 'created_at' | 'deadline_at' | 'completed_at' | 'ascending' | 'descending', page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TaskResult> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).tasksIndex(taskId, geoCode, employeeId, completedByEmployeeId, assignedEmployeeId, partnerId, leadId, status, limit, includeDone, process, dueBefore, sort, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} priorityId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksPrioritiesGet(priorityId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TaskPriorityResource> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).tasksPrioritiesGet(priorityId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} taskId
         * @param {string} priorityId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksPrioritiesGet_1(taskId: string, priorityId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TaskPriorityResource> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).tasksPrioritiesGet_1(taskId, priorityId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [priorityId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksPrioritiesIndex(priorityId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TaskPriorityResult> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).tasksPrioritiesIndex(priorityId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} statusId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksStatusesGet(statusId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TaskStatusResource> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).tasksStatusesGet(statusId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} taskId
         * @param {string} statusId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksStatusesGet_2(taskId: string, statusId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TaskStatusResource> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).tasksStatusesGet_2(taskId, statusId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [statusId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksStatusesIndex(statusId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TaskStatusResult> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).tasksStatusesIndex(statusId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} taskId
         * @param {TaskableResource} taskableResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksTaskablesCreate(taskId: string, taskableResource: TaskableResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TaskableResource> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).tasksTaskablesCreate(taskId, taskableResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} taskId
         * @param {string} taskableId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksTaskablesDelete(taskId: string, taskableId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).tasksTaskablesDelete(taskId, taskableId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} taskId
         * @param {string} taskableId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksTaskablesGet(taskId: string, taskableId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TaskableResource> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).tasksTaskablesGet(taskId, taskableId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} taskId
         * @param {string} [taskableId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksTaskablesIndex(taskId: string, taskableId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TaskableResult> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).tasksTaskablesIndex(taskId, taskableId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} taskId
         * @param {TaskResource} taskResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksUpdate(taskId: string, taskResource: TaskResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TaskResource> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).tasksUpdate(taskId, taskResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TasksApi - factory interface
 * @export
 */
export const TasksApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {TaskResource} taskResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksCreate(taskResource: TaskResource, options?: any) {
            return TasksApiFp(configuration).tasksCreate(taskResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksDelete(taskId: string, options?: any) {
            return TasksApiFp(configuration).tasksDelete(taskId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksGet(taskId: string, options?: any) {
            return TasksApiFp(configuration).tasksGet(taskId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [taskId] Task id&#39;s to filter by.
         * @param {string} [geoCode]
         * @param {string} [employeeId]
         * @param {string} [completedByEmployeeId] Filter by tasks that are completed by a given employee_id.
         * @param {string} [assignedEmployeeId] Filter by tasks that are assigned to a given employee_id.
         * @param {string} [partnerId] Query tasks associated to a given partner.
         * @param {string} [leadId] Query tasks associated to a given lead.
         * @param {string} [status] Status for the tasks to be filtered by.
         * @param {number} [limit] Number of resources to return.
         * @param {boolean} [includeDone]
         * @param {'unknown' | 'csm' | 'pgm' | 'psm'} [process] Process type
         * @param {string} [dueBefore] Due before date
         * @param {'created_at' | 'deadline_at' | 'completed_at' | 'ascending' | 'descending'} [sort]
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksIndex(taskId?: string, geoCode?: string, employeeId?: string, completedByEmployeeId?: string, assignedEmployeeId?: string, partnerId?: string, leadId?: string, status?: string, limit?: number, includeDone?: boolean, process?: 'unknown' | 'csm' | 'pgm' | 'psm', dueBefore?: string, sort?: 'created_at' | 'deadline_at' | 'completed_at' | 'ascending' | 'descending', page?: number, query?: string, options?: any) {
            return TasksApiFp(configuration).tasksIndex(taskId, geoCode, employeeId, completedByEmployeeId, assignedEmployeeId, partnerId, leadId, status, limit, includeDone, process, dueBefore, sort, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} priorityId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksPrioritiesGet(priorityId: string, options?: any) {
            return TasksApiFp(configuration).tasksPrioritiesGet(priorityId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} taskId
         * @param {string} priorityId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksPrioritiesGet_1(taskId: string, priorityId: string, options?: any) {
            return TasksApiFp(configuration).tasksPrioritiesGet_1(taskId, priorityId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [priorityId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksPrioritiesIndex(priorityId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return TasksApiFp(configuration).tasksPrioritiesIndex(priorityId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} statusId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksStatusesGet(statusId: string, options?: any) {
            return TasksApiFp(configuration).tasksStatusesGet(statusId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} taskId
         * @param {string} statusId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksStatusesGet_2(taskId: string, statusId: string, options?: any) {
            return TasksApiFp(configuration).tasksStatusesGet_2(taskId, statusId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [statusId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksStatusesIndex(statusId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return TasksApiFp(configuration).tasksStatusesIndex(statusId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} taskId
         * @param {TaskableResource} taskableResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksTaskablesCreate(taskId: string, taskableResource: TaskableResource, options?: any) {
            return TasksApiFp(configuration).tasksTaskablesCreate(taskId, taskableResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} taskId
         * @param {string} taskableId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksTaskablesDelete(taskId: string, taskableId: string, options?: any) {
            return TasksApiFp(configuration).tasksTaskablesDelete(taskId, taskableId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} taskId
         * @param {string} taskableId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksTaskablesGet(taskId: string, taskableId: string, options?: any) {
            return TasksApiFp(configuration).tasksTaskablesGet(taskId, taskableId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} taskId
         * @param {string} [taskableId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksTaskablesIndex(taskId: string, taskableId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return TasksApiFp(configuration).tasksTaskablesIndex(taskId, taskableId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} taskId
         * @param {TaskResource} taskResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksUpdate(taskId: string, taskResource: TaskResource, options?: any) {
            return TasksApiFp(configuration).tasksUpdate(taskId, taskResource, options)(fetch, basePath);
        },
    };
};

/**
 * TasksApi - object-oriented interface
 * @export
 * @class TasksApi
 * @extends {BaseAPI}
 */
export class TasksApi extends BaseAPI {
    /**
     *
     * @param {TaskResource} taskResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public tasksCreate(taskResource: TaskResource, options?: any) {
        return TasksApiFp(this.configuration).tasksCreate(taskResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public tasksDelete(taskId: string, options?: any) {
        return TasksApiFp(this.configuration).tasksDelete(taskId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public tasksGet(taskId: string, options?: any) {
        return TasksApiFp(this.configuration).tasksGet(taskId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [taskId] Task id&#39;s to filter by.
     * @param {string} [geoCode]
     * @param {string} [employeeId]
     * @param {string} [completedByEmployeeId] Filter by tasks that are completed by a given employee_id.
     * @param {string} [assignedEmployeeId] Filter by tasks that are assigned to a given employee_id.
     * @param {string} [partnerId] Query tasks associated to a given partner.
     * @param {string} [leadId] Query tasks associated to a given lead.
     * @param {string} [status] Status for the tasks to be filtered by.
     * @param {number} [limit] Number of resources to return.
     * @param {boolean} [includeDone]
     * @param {'unknown' | 'csm' | 'pgm' | 'psm'} [process] Process type
     * @param {string} [dueBefore] Due before date
     * @param {'created_at' | 'deadline_at' | 'completed_at' | 'ascending' | 'descending'} [sort]
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public tasksIndex(taskId?: string, geoCode?: string, employeeId?: string, completedByEmployeeId?: string, assignedEmployeeId?: string, partnerId?: string, leadId?: string, status?: string, limit?: number, includeDone?: boolean, process?: 'unknown' | 'csm' | 'pgm' | 'psm', dueBefore?: string, sort?: 'created_at' | 'deadline_at' | 'completed_at' | 'ascending' | 'descending', page?: number, query?: string, options?: any) {
        return TasksApiFp(this.configuration).tasksIndex(taskId, geoCode, employeeId, completedByEmployeeId, assignedEmployeeId, partnerId, leadId, status, limit, includeDone, process, dueBefore, sort, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} priorityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public tasksPrioritiesGet(priorityId: string, options?: any) {
        return TasksApiFp(this.configuration).tasksPrioritiesGet(priorityId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} taskId
     * @param {string} priorityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public tasksPrioritiesGet_1(taskId: string, priorityId: string, options?: any) {
        return TasksApiFp(this.configuration).tasksPrioritiesGet_1(taskId, priorityId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [priorityId]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public tasksPrioritiesIndex(priorityId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return TasksApiFp(this.configuration).tasksPrioritiesIndex(priorityId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} statusId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public tasksStatusesGet(statusId: string, options?: any) {
        return TasksApiFp(this.configuration).tasksStatusesGet(statusId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} taskId
     * @param {string} statusId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public tasksStatusesGet_2(taskId: string, statusId: string, options?: any) {
        return TasksApiFp(this.configuration).tasksStatusesGet_2(taskId, statusId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [statusId]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public tasksStatusesIndex(statusId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return TasksApiFp(this.configuration).tasksStatusesIndex(statusId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} taskId
     * @param {TaskableResource} taskableResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public tasksTaskablesCreate(taskId: string, taskableResource: TaskableResource, options?: any) {
        return TasksApiFp(this.configuration).tasksTaskablesCreate(taskId, taskableResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} taskId
     * @param {string} taskableId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public tasksTaskablesDelete(taskId: string, taskableId: string, options?: any) {
        return TasksApiFp(this.configuration).tasksTaskablesDelete(taskId, taskableId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} taskId
     * @param {string} taskableId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public tasksTaskablesGet(taskId: string, taskableId: string, options?: any) {
        return TasksApiFp(this.configuration).tasksTaskablesGet(taskId, taskableId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} taskId
     * @param {string} [taskableId]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public tasksTaskablesIndex(taskId: string, taskableId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return TasksApiFp(this.configuration).tasksTaskablesIndex(taskId, taskableId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} taskId
     * @param {TaskResource} taskResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public tasksUpdate(taskId: string, taskResource: TaskResource, options?: any) {
        return TasksApiFp(this.configuration).tasksUpdate(taskId, taskResource, options)(this.fetch, this.basePath);
    }

}

/**
 * TermsApi - fetch parameter creator
 * @export
 */
export const TermsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} [acceptorId]
         * @param {'partner' | 'partner_user' | 'client'} [acceptorType]
         * @param {string} [termsId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        termsAcceptedIndex(acceptorId?: number, acceptorType?: 'partner' | 'partner_user' | 'client', termsId?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/terms/accepted`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (acceptorId !== undefined) {
                localVarQueryParameter['acceptor_id'] = acceptorId;
            }

            if (acceptorType !== undefined) {
                localVarQueryParameter['acceptor_type'] = acceptorType;
            }

            if (termsId !== undefined) {
                localVarQueryParameter['terms_id'] = termsId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} termsId
         * @param {TermsActionResource} termsActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        termsActionsCreate(termsId: string, termsActionResource: TermsActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'termsId' is not null or undefined
            if (termsId === null || termsId === undefined) {
                throw new RequiredError('termsId','Required parameter termsId was null or undefined when calling termsActionsCreate.');
            }
            // verify required parameter 'termsActionResource' is not null or undefined
            if (termsActionResource === null || termsActionResource === undefined) {
                throw new RequiredError('termsActionResource','Required parameter termsActionResource was null or undefined when calling termsActionsCreate.');
            }
            const localVarPath = `/terms/{terms_id}/actions`
                .replace(`{${"terms_id"}}`, encodeURIComponent(String(termsId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TermsActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(termsActionResource || {}) : (termsActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} termsId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        termsGet(termsId: string, options: any = {}): FetchArgs {
            // verify required parameter 'termsId' is not null or undefined
            if (termsId === null || termsId === undefined) {
                throw new RequiredError('termsId','Required parameter termsId was null or undefined when calling termsGet.');
            }
            const localVarPath = `/terms/{terms_id}`
                .replace(`{${"terms_id"}}`, encodeURIComponent(String(termsId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [geoCode]
         * @param {string} [type]
         * @param {string} [identifier]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        termsIndex(geoCode?: string, type?: string, identifier?: string, limit?: number, page?: number, query?: string, options: any = {}): FetchArgs {
            const localVarPath = `/terms`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            if (geoCode !== undefined) {
                localVarQueryParameter['geo_code'] = geoCode;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (identifier !== undefined) {
                localVarQueryParameter['identifier'] = identifier;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TermsApi - functional programming interface
 * @export
 */
export const TermsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} [acceptorId]
         * @param {'partner' | 'partner_user' | 'client'} [acceptorType]
         * @param {string} [termsId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        termsAcceptedIndex(acceptorId?: number, acceptorType?: 'partner' | 'partner_user' | 'client', termsId?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TermsResult> {
            const localVarFetchArgs = TermsApiFetchParamCreator(configuration).termsAcceptedIndex(acceptorId, acceptorType, termsId, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} termsId
         * @param {TermsActionResource} termsActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        termsActionsCreate(termsId: string, termsActionResource: TermsActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TermsActionResource> {
            const localVarFetchArgs = TermsApiFetchParamCreator(configuration).termsActionsCreate(termsId, termsActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} termsId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        termsGet(termsId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TermsResource> {
            const localVarFetchArgs = TermsApiFetchParamCreator(configuration).termsGet(termsId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [geoCode]
         * @param {string} [type]
         * @param {string} [identifier]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        termsIndex(geoCode?: string, type?: string, identifier?: string, limit?: number, page?: number, query?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TermsResult> {
            const localVarFetchArgs = TermsApiFetchParamCreator(configuration).termsIndex(geoCode, type, identifier, limit, page, query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TermsApi - factory interface
 * @export
 */
export const TermsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {number} [acceptorId]
         * @param {'partner' | 'partner_user' | 'client'} [acceptorType]
         * @param {string} [termsId]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        termsAcceptedIndex(acceptorId?: number, acceptorType?: 'partner' | 'partner_user' | 'client', termsId?: string, limit?: number, page?: number, query?: string, options?: any) {
            return TermsApiFp(configuration).termsAcceptedIndex(acceptorId, acceptorType, termsId, limit, page, query, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} termsId
         * @param {TermsActionResource} termsActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        termsActionsCreate(termsId: string, termsActionResource: TermsActionResource, options?: any) {
            return TermsApiFp(configuration).termsActionsCreate(termsId, termsActionResource, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} termsId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        termsGet(termsId: string, options?: any) {
            return TermsApiFp(configuration).termsGet(termsId, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [geoCode]
         * @param {string} [type]
         * @param {string} [identifier]
         * @param {number} [limit] The number of resources to be returned.
         * @param {number} [page] The page position in the result.
         * @param {string} [query] The search wildcard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        termsIndex(geoCode?: string, type?: string, identifier?: string, limit?: number, page?: number, query?: string, options?: any) {
            return TermsApiFp(configuration).termsIndex(geoCode, type, identifier, limit, page, query, options)(fetch, basePath);
        },
    };
};

/**
 * TermsApi - object-oriented interface
 * @export
 * @class TermsApi
 * @extends {BaseAPI}
 */
export class TermsApi extends BaseAPI {
    /**
     *
     * @param {number} [acceptorId]
     * @param {'partner' | 'partner_user' | 'client'} [acceptorType]
     * @param {string} [termsId]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermsApi
     */
    public termsAcceptedIndex(acceptorId?: number, acceptorType?: 'partner' | 'partner_user' | 'client', termsId?: string, limit?: number, page?: number, query?: string, options?: any) {
        return TermsApiFp(this.configuration).termsAcceptedIndex(acceptorId, acceptorType, termsId, limit, page, query, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} termsId
     * @param {TermsActionResource} termsActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermsApi
     */
    public termsActionsCreate(termsId: string, termsActionResource: TermsActionResource, options?: any) {
        return TermsApiFp(this.configuration).termsActionsCreate(termsId, termsActionResource, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} termsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermsApi
     */
    public termsGet(termsId: string, options?: any) {
        return TermsApiFp(this.configuration).termsGet(termsId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [geoCode]
     * @param {string} [type]
     * @param {string} [identifier]
     * @param {number} [limit] The number of resources to be returned.
     * @param {number} [page] The page position in the result.
     * @param {string} [query] The search wildcard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermsApi
     */
    public termsIndex(geoCode?: string, type?: string, identifier?: string, limit?: number, page?: number, query?: string, options?: any) {
        return TermsApiFp(this.configuration).termsIndex(geoCode, type, identifier, limit, page, query, options)(this.fetch, this.basePath);
    }

}

/**
 * VapidApi - fetch parameter creator
 * @export
 */
export const VapidApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vapidGet(options: any = {}): FetchArgs {
            const localVarPath = `/vapid`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VapidApi - functional programming interface
 * @export
 */
export const VapidApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vapidGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VapidResource> {
            const localVarFetchArgs = VapidApiFetchParamCreator(configuration).vapidGet(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * VapidApi - factory interface
 * @export
 */
export const VapidApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vapidGet(options?: any) {
            return VapidApiFp(configuration).vapidGet(options)(fetch, basePath);
        },
    };
};

/**
 * VapidApi - object-oriented interface
 * @export
 * @class VapidApi
 * @extends {BaseAPI}
 */
export class VapidApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VapidApi
     */
    public vapidGet(options?: any) {
        return VapidApiFp(this.configuration).vapidGet(options)(this.fetch, this.basePath);
    }

}

/**
 * VouchersApi - fetch parameter creator
 * @export
 */
export const VouchersApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} voucherId
         * @param {VoucherActionResource} voucherActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vouchersActionsCreate(voucherId: string, voucherActionResource: VoucherActionResource, options: any = {}): FetchArgs {
            // verify required parameter 'voucherId' is not null or undefined
            if (voucherId === null || voucherId === undefined) {
                throw new RequiredError('voucherId','Required parameter voucherId was null or undefined when calling vouchersActionsCreate.');
            }
            // verify required parameter 'voucherActionResource' is not null or undefined
            if (voucherActionResource === null || voucherActionResource === undefined) {
                throw new RequiredError('voucherActionResource','Required parameter voucherActionResource was null or undefined when calling vouchersActionsCreate.');
            }
            const localVarPath = `/vouchers/{voucher_id}/actions`
                .replace(`{${"voucher_id"}}`, encodeURIComponent(String(voucherId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("token")
					: configuration.apiKey;
                localVarQueryParameter["token"] = localVarApiKeyValue;
            }

            // authentication login required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"VoucherActionResource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(voucherActionResource || {}) : (voucherActionResource || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VouchersApi - functional programming interface
 * @export
 */
export const VouchersApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} voucherId
         * @param {VoucherActionResource} voucherActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vouchersActionsCreate(voucherId: string, voucherActionResource: VoucherActionResource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VoucherActionResource> {
            const localVarFetchArgs = VouchersApiFetchParamCreator(configuration).vouchersActionsCreate(voucherId, voucherActionResource, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * VouchersApi - factory interface
 * @export
 */
export const VouchersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} voucherId
         * @param {VoucherActionResource} voucherActionResource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vouchersActionsCreate(voucherId: string, voucherActionResource: VoucherActionResource, options?: any) {
            return VouchersApiFp(configuration).vouchersActionsCreate(voucherId, voucherActionResource, options)(fetch, basePath);
        },
    };
};

/**
 * VouchersApi - object-oriented interface
 * @export
 * @class VouchersApi
 * @extends {BaseAPI}
 */
export class VouchersApi extends BaseAPI {
    /**
     *
     * @param {string} voucherId
     * @param {VoucherActionResource} voucherActionResource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public vouchersActionsCreate(voucherId: string, voucherActionResource: VoucherActionResource, options?: any) {
        return VouchersApiFp(this.configuration).vouchersActionsCreate(voucherId, voucherActionResource, options)(this.fetch, this.basePath);
    }

}

